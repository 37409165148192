import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';

import { omit } from 'lodash-es';

import { AppService } from '../../../app.service';
import { TODataService } from '../../services/to.data.service';
import { DefaultCurrencyPipe } from '../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { IdCardValidator, CreditCardValidator, CreditCardExpirationValidator } from '../../services/validators';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix, MatLabel } from '@angular/material/form-field';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-to-payment-dialog',
    templateUrl: './to-payment-dialog.component.html',
    styleUrls: ['./to-payment-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, NgSwitch, NgSwitchCase, NgIf, MatFormField, MatInput, MatSuffix, MatLabel, IdCardValidator, CreditCardValidator, CreditCardExpirationValidator, MatCheckbox, MatDialogActions, MatButton, TranslateModule, DefaultCurrencyPipe]
})

export class ToPaymentDialogComponent {
    ui: any = { updatePaymentInfo: true }
    $storage: any;
    ccinfo: any;
    enableSaveInWallet: boolean;
    requireIdPhoneTrans: boolean = false;

    constructor(
        public appService: AppService,
        public dataService: TODataService,
        public dialogRef: MatDialogRef<ToPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.$storage = this.dataService.$storage;
        this.ccinfo = this.sanitizePayment(this.data.ccinfo);
        this.ccinfo.updatePaymentInfo = false;
        this.enableSaveInWallet = this.data.enableSaveInWallet;
    }

    sanitizePayment(payment) {
        const omitProperties = ['brand', 'brandName', 'cvv', 'expiration', 'expMonth', 'expYear', 'pan', 'idCard'];
        const sanitizedPayment = payment?.paymentMethod == 'creditCard' ? omit(payment, omitProperties) : payment;

        return sanitizedPayment;
    }

    apply(form) {
        if (!form.valid) return;
        this.dialogRef.close({
            ccinfo: this.ccinfo,
            updatePaymentInfo: this.ccinfo.updatePaymentInfo
        });
    }

    cancel() {
        this.dialogRef.close();
    }
}
