import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { TranslateModule } from '@ngx-translate/core';
import { EventsListComponent } from '../events-list/events-list.component';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-events',
    templateUrl: './app-events.component.html',
    styleUrls: ['./app-events.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, EventsListComponent, TranslateModule]
})
export class AppEventsComponent implements OnInit {

    constructor(
        public appService: AppService,
        private bookService: BookService,
    ) { }

    ngOnInit() {
        this.bookService.startWithoutSite();
    }

}
