import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookService } from '../../_core/book.service';
import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { EntityService } from '../../_core/entity.service';
import { ORGANIZATION_BUCKET_TYPES } from '../../_core/OrganizationBucket';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { TabitbookStartComponent } from '../tabitbook-start/tabitbook-start.component';
import { NgIf } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-tabitbook-from-sites',
    templateUrl: './tabitbook-from-sites.component.html',
    styleUrls: ['./tabitbook-from-sites.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, NgIf, TabitbookStartComponent, TranslateModule]
})
export class TabitbookFromSitesComponent implements OnInit, OnDestroy {

    @BlockUI() blockUI: NgBlockUI;

    loadingMetaData: boolean = true;
    bookingServiceStarted: boolean = false;

    wantedTime: moment.Moment = null;
    editorToSet: string = 'date';

    orgsSubscription: Subscription = null;
    coreSubscription: Subscription = null;

    constructor(
        public bookService: BookService,
        public appService: AppService,
        public organizationsService: OrganizationsService,
        public entityService: EntityService,
        private route: ActivatedRoute,
		private ngZone: NgZone
        ) {}

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        this.appService.loadingMetaData.subscribe(loading => {
            this.loadingMetaData = loading;
            if (!loading && !this.bookingServiceStarted) {
                this.bookService.startWithoutSite();
                this.bookingServiceStarted = true;
            }
        });

        this.route.queryParams.subscribe(params => {
            if (params.editor) this.editorToSet = params.editor;
        });

        this.orgsSubscription = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.booking].subscribe((organizations: any[]) => {
            this.bookService.crossOrgSearchResult = organizations;
        }, err => {
            console.error('Booking Organizations Subscription Error', err);
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/home/dashboard'])
                    })
                });
        }
    }

    ngOnDestroy() {
        this.orgsSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
    }

    getSitesForBooking() {
        if (this.isSelectedDateInThePast(this.bookService.getCrossOrgsSearchTime())) return this.appService.mainMessage({
            dialogTitle: 'please_note',
            dialogType: 'error',
            dialogText: 'WEB_CONTAINER.book_a_table.select_future_date',
            hideSecondaryButton: true
        });

        // Make sure all form fields marked as "changed" after clicking "search"
        Object.keys(this.bookService.$storage.bookFormChanged).forEach(editor => this.bookService.$storage.bookFormChanged[editor] = true);

        this.bookService.crossOrgSearchDetails = {
            booking: {
                timestamp: this.bookService.getCrossOrgsSearchTime().toISOString(),
                seats_count: this.bookService.getCrossOrgsSearchSeatCount().toString()
            }
        };

        this.appService.redirect(['/book/results']);

    }

    private isSelectedDateInThePast(date: moment.Moment): boolean {
        return moment().isAfter(moment(date));
    }

}
