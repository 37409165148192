import { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';

import { AppService } from '../../../app.service';
import { TODataService } from '../../services/to.data.service';

import { get } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-to-table-dialog',
    templateUrl: './to-table-dialog.component.html',
    styleUrls: ['./to-table-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconButton, MatDialogClose, MatIcon, FormsModule, MatDialogTitle, MatDialogContent, MatFormField, MatInput, MatDialogActions, MatButton, TranslateModule]
})
export class ToTableDialogComponent implements OnInit{
    $storage: any;
    formModel: any = {}
    eatinCaption:string = '';
    continueScreenReaderText: string = '';
    preventClose: boolean = /service=eatin/.test(window.location.href) && !window['cordova']; // Needed to prevent closure of the table dialog in case eatin was selected

    constructor(
        public dialogRef: MatDialogRef<ToTableDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appService: AppService,
        public dataService: TODataService,
    ) {
        this.$storage = this.dataService.$storage;
        this.eatinCaption = this.appService.isOriginLanguage ? (this.$storage?.site?.settings?.eatinCaption || 'TABLE_NO') : get(this.$storage?.site?.translations, this.appService.localeId + '.eatinCaption', (this.$storage?.site?.settings?.eatinCaption || 'TABLE_NO')); 
    }

    ngOnInit(): void {
        this.continueScreenReaderText = this.appService.translate('ACCESS.press_to_approve_and_continue_to_menu');
    }

    apply(form) {
        if (!form.valid) return;
        this.dialogRef.close(this.formModel.table);
    }
}
