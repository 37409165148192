import { Component, NgZone, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { each, groupBy } from 'lodash-es';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { UserReviewsService } from '../../_core/user.reviews.service';
import { StorageService } from '../../_core/storage.service';
import { OrderTrackerService } from '../../_core/order-tracker.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { HistoryItemComponent } from '../../components/history-item/history-item.component';
import { TrackedOrderItemComponent } from '../../components/tracked-order-item/tracked-order-item.component';
import { MatList } from '@angular/material/list';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-my-history',
    templateUrl: './my-history.component.html',
    styleUrls: ['./my-history.component.scss'],
    host: {
        'class': 'host-default',
    },
    animations: [
        trigger('removeLoadHistoryButton', [
            transition(':leave', [
                style({ opacity: 1 }),
                animate('150ms 0s linear', style({ opacity: 0 })),
            ])
        ]),
    ],
    standalone: true,
    imports: [BlockUIModule, MatIconButton, MatIcon, WidgetOpenerComponent, NgIf, MatTabGroup, MatTab, MatList, NgFor, TrackedOrderItemComponent, HistoryItemComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, SlicePipe, OrderModule, TranslateModule]
})
export class MyHistoryComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    parametersObservable: any;
    sites: any = [];
    groupedSites;
    groupedSitesIncludeTrackedOrder: any = [];
    allHistoryResults: boolean = false;
    showFullHistoryButton: boolean = false;
    descTitle: any = this.getSearchHistoryDescription();

    public trackedOrders: any = [];
    public ordersFromLocalStorage: any = [];

	constructor(
		public appService: AppService,
		private entityService: EntityService,
        private userReviewsService: UserReviewsService,
        private storageService: StorageService,
        private orderTrackerService: OrderTrackerService,
        public ngZone: NgZone,
    ) {}

    ngOnInit() {
        this.showFullHistoryButton = this.checkIfNeedToShowFullHistoryButton();
        if (this.appService.isAuthUser()) {
            this.groupedSitesIncludeTrackedOrder=[];
			this.getHistory();
            this.takeDataFromLocalStorage();
        };

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/profile/my-profile'])
                    });
            });
        }
    }

    getHistory() {
        this.blockUI.start();
        this.entityService.getUserHistory().then(response => {
            let responseOrders = response.slice(0, 100);
            return responseOrders;
        }).then(responseOrders =>  {
            if (!this.appService.user.loyaltyCustomer.CustomerId) return;

            // We're using getUserReviews() obserable here because we want to fetch the most up-to-date data of reviews every time we go into this screen.
            this.userReviewsService.getUserReviews(this.appService.user.phone).subscribe(reviews => {
                this.sites = this.entityService.checkIfHistoryItemValidForReview(reviews, responseOrders);

                let groupedSites = groupBy(responseOrders, o => { return o.organization });
                this.groupedSites = [];
                each(groupedSites, (val: any, key) => {
                    this.groupedSites.push({ name: val[0].name, sites: val });
                    if (!this.groupedSitesIncludeTrackedOrder.includes(val[0].name))
                    {
                        this.groupedSitesIncludeTrackedOrder.push(val[0].name);
                    }
                });
            })
        })
        .catch().then(() => {
            this.blockUI.stop();
        });
    }

    loadFullHistory() {
        this.storageService.setItem('historyLoadType', 'full');
        this.showFullHistoryButton = false;
        this.sites = [];
        this.getHistory();
    }

    private checkIfNeedToShowFullHistoryButton(): boolean {
        let historyLoadType = this.storageService.getItem('historyLoadType');
        if ((!historyLoadType || historyLoadType != 'full')) {
            return true;
        };
        return false;
    }

    private getSearchHistoryDescription() {
        let descTitle: any;
        descTitle = this.appService.skin ?
                    this.appService.skin
                    :
                    this.appService.translate('tabit');
        return { descTitle };
    }

    private takeDataFromLocalStorage() {

        // console.log('=== DASHBOARD === taking orgs from local storage (if any)');
        this.ordersFromLocalStorage = JSON.parse(`${this.appService.validateLocalStorageData('state__customer_orders')}`);
        if (this.ordersFromLocalStorage && this.ordersFromLocalStorage.length) this.getOrRemoveOrdersFromLocalStorage(this.ordersFromLocalStorage);

    }

    private getOrRemoveOrdersFromLocalStorage(orders: any[]) {
        orders.forEach(order => {
            this.orderTrackerService.getOrderById(order.id, order.organization).subscribe(updatedOrder => {
                if ((
                    !updatedOrder ||
                    updatedOrder && updatedOrder.deliveryInfo && updatedOrder.deliveryInfo.ETA && moment().diff(updatedOrder.deliveryInfo.ETA, 'hours') > 2) ||
                    updatedOrder && updatedOrder.created && moment().diff(updatedOrder.created, 'hours') > 4
                ) {
                    this.removeOrder(order);
                } else {
                    this.trackedOrders.push(order);
                    if (!this.groupedSitesIncludeTrackedOrder.includes(order.orgName))
                    {
                        this.groupedSitesIncludeTrackedOrder.push(order.orgName);
                    }
                }

            }, error => {
                console.error('Error in getting your order:', error, order);
                if (error && error.status == 404) {
                    this.removeOrder(order);
                }
            });
        }, err => {
            console.error('Error getting orders', err);
        });
    }

    private removeOrder(order: any) {
        let ordersToSave = this.ordersFromLocalStorage.filter(orderFromStorage => orderFromStorage.id !== order.id);
        this.storageService.setItem('state__customer_orders', JSON.stringify(ordersToSave || []));
    }
}


