import { Injectable } from '@angular/core';
import moment from 'moment';

import { AppService } from '../../app.service';
import { TODataService } from './to.data.service';

declare const $: any;

@Injectable({
    providedIn: 'root'
})

export class TOThrottlingService {

    constructor(
        private appSerivce: AppService,
        private dataService: TODataService,
    ) { }

    checkIfSlotThrottled(slot) {
        let isThrottled = false;

        const throttling = this.dataService.$storage?.throttling;
        if (!throttling) return false;

        const slotDate = moment(slot).format('YYYY-MM-DD');
        const slotTime = moment(slot).format('HH:mm');

        const testThrottling = {
            '2024-03-24': [
                {
                    from: '12:30',
                    to: '18:00'
                },
                {
                    from: '19:00',
                    to: '21:00'
                }
            ],
            '2024-02-27': [
                {
                    from: '08:00',
                    to: '18:00'
                },
                {
                    from: '19:00',
                    to: '21:00'
                }
            ]
        }
        const relevantThrottlingSlots = throttling[slotDate];
        if (!relevantThrottlingSlots?.length) return false;

        relevantThrottlingSlots.some(relevantSlot => {
            const slotFromMoment = moment(relevantSlot.from, 'HH:mm', true); // Parse in strict mode
            const slotToMoment = moment(relevantSlot.to, 'HH:mm', true).subtract(1, 'second');; // Parse in strict mode
            const slotTimeMoment = moment(slotTime, 'HH:mm', true); // Parse in strict mode

            const isSameOrAfterFrom = slotTimeMoment.isSameOrAfter(slotFromMoment);
            const isSameOrBeforeTo = slotTimeMoment.isSameOrBefore(slotToMoment);

            if (isSameOrAfterFrom && isSameOrBeforeTo) {
                isThrottled = true;
                return true; // Break out of the loop
            }
            return false;
        });

        return isThrottled;
    }

    isDisableASAP(members, supplyArgs) {
        let disableASAP = false;

        const asapThrottled = this.checkIfSlotThrottled(supplyArgs.ETA);
        if (asapThrottled) disableASAP = asapThrottled;
        if (disableASAP) return disableASAP;

        if (!members.length || members?.length <= 1) return disableASAP;

        const hasASAP = members.find(member => members.indexOf(member) == 0 && !member.date);
        if (hasASAP) {
            const firstSlotThrottled = members[1].throttled;
            if (firstSlotThrottled) disableASAP = true;
        }

        return disableASAP;
    }

    getFirstNonThrottledMember(members) {
        const isFirstThrottled = !!members[0].throttled;
        if (!isFirstThrottled) return members[0];

        const firstNonThrottledMember = members.find(member => !member.throttled);

        return firstNonThrottledMember;
    }

    checkFutureOrderThrottledSlots(slot, reffDate) {
        const dateObject = new Date();
        const slotDate = moment(reffDate).format('YYYY-MM-DD');
        const parsedTime = moment(slot.text, 'HH:mm');

        const [year, month, day] = slotDate.split('-').map(Number);

        dateObject.setFullYear(year);
        dateObject.setMonth(month - 1); // Month is 0-indexed in JavaScript
        dateObject.setDate(day);
        dateObject.setHours(parsedTime.hour());
        dateObject.setMinutes(parsedTime.minute());
        dateObject.setSeconds(0);

        return this.checkIfSlotThrottled(dateObject);
    }

    get selectedDateFrom() {
        return moment().startOf('day').format('YYYY-MM-DD');
    }

    get selectedDateTo() {
        return moment().add(30, 'days').format('YYYY-MM-DD');
    }

    getThrottlingSlots(siteId?): Promise<any> {
        const url = `/online-shopper/throttling?fromDate=${this.selectedDateFrom}&toDate=${this.selectedDateTo}`;
        return this.dataService.get(url, null, true, siteId)
        .catch(error => {
            console.error('Error in getThrottlingSlots:', error);
            return Promise.resolve(null);
        });
    }
}
