import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../app.service';
import { WebContainerService } from '../web-container.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogsService } from '../../_core/dialogs.service';

import { get } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { WebHeaderSearchComponent } from '../web-header/web-header-search/web-header-search.component';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'web-header-tabs',
    templateUrl: './web-header-tabs.component.html',
    styleUrls: ['./web-header-tabs.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, RouterLink, AccessibleDirective, RouterLinkActive, WebHeaderSearchComponent, TranslateModule]
})
export class WebHeaderTabsComponent implements OnInit {

    @Input() webMobileTabsSidenavOpened: boolean;
    @Output() mobileTabsSidenavChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public domain: any;
    public webHomeServicesDomainConfig: any;
    public webHomeSectionsDomainConfig: any;

    constructor(
        public appService: AppService,
        public webContainerService: WebContainerService,
        public configurationsService: ConfigurationsService,
        public dialogsService: DialogsService,
    ) { }

    toggleSidenav() {
        this.webMobileTabsSidenavOpened = false;
        this.mobileTabsSidenavChange.emit(this.webMobileTabsSidenavOpened);
    }

    ngOnInit() {
        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            this.domain = domain;
            this.webHomeServicesDomainConfig = this.appService.getServicesConfig(domain);
            this.webHomeSectionsDomainConfig = this.appService.getWebHomeSectionsConfig(domain);
        });
    }

    businessInviteRedirect() {
        if (this.appService.appConfig.locale.toLocaleLowerCase() === 'he-il') {
            window.open('https://restaurants.tabitisrael.co.il/?utm_source=tabitisrael_website&utm_medium=upper_button');
        } else {
            window.open('https://www.tabit.cloud');
        }
    }

    getMediaPath() {
        let path = this.appService.getValidBaseUrl(get(this.domain, 'webHeaderLogoPath'));
        if (!path) {
            path = this.appService.base('assets/images/tabit_white_yellow_ribbon.svg');
        }
        return path;
    }

}
