import { Component, Input, OnInit } from '@angular/core';
import { DefaultCurrencyPipe, AMPMConverterPipe } from '../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'header-tracker',
    templateUrl: './header-tracker.component.html',
    styleUrls: ['./header-tracker.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, DatePipe, TranslateModule, DefaultCurrencyPipe, AMPMConverterPipe]
})
export class HeaderTrackerComponent implements OnInit {

  @Input() order: any;

  public totalAmount: number;

  constructor() {
  }
  ngOnInit() {
    this.totalAmount = this.getTotalAmount();
  }

  private getTotalAmount() {
    let totalAmount = 0;
    let cashback = this.order?.paymentSummary?.cashback > 0 ? this.order.paymentSummary.cashback : 0;
    if (this.order?.totals?.totalAmount) {
      totalAmount = this.order.totals.totalAmount ?
        ((cashback > 0 ?
          ((this.order.totals.totalAmount + cashback) / 100)
          :
          (this.order?.paymentSummary?.totalAuxAmount ?
            (this.order.totals.totalAmount + this.order.paymentSummary.totalAuxAmount) / 100
            :
            this.order.totals.totalAmount / 100
          )
        ))
        : 0;
    }
    return totalAmount;
  }

}
