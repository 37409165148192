export const orderingStrings_heIL = {
    "TO-ADD": "הוספה",
    "TO-UPDATE": "עדכון",
    "JUST-CHECKING": "רק בודקים",
    "ADD": "הוסף",
    "UPDATE": "עדכן",
    "ORDER-TOTAL": "סיכום הזמנה",
    "CASH": "מזומן",
    "PAYUP": "המשך לתשלום",
    "THANK_YOU": "תודה רבה!",
    "AMOUNT_TO_PAY": "סכום לתשלום",
    "TIP": "טיפ",
    "PAYED": "שולם",
    "CONTINUE": "המשך",
    "TOTAL_IN_TIP": "סה\"כ כולל טיפ",
    "PARTIAL_PAY_IN_TIP": "תשלום חלקי כולל טיפ",
    "CLEAR": "נקה",
    "CLEAR_SELECTION": "נקה בחירה",
    "APPROVE": "אישור",
    "PAY": "תשלום",
    "CANCEL": "ביטול",
    "NO_THANKS": "לא, תודה",
    "REMAINED_PAY": "נותר לתשלום",
    "TABLE_BILL": "חשבון לשולחן",
    "WAITER": "מלצר",
    ";": "לתשלום",
    "SAVE_CARD_INFO": "שמור פרטי תשלום",
    "TOTAL": "סה\"כ",
    "CARD_HOLDER_NAME": "שם בעל הכרטיס",
    "CARD_NUMBER": "מס' כרטיס",
    "SIGNIN": "התחברות",
    "SIGNIN_INFO": "הזן פרטי הזדהות",
    "VALIDATING_CREDENTIALS": "בודק נתונים...",
    "WELCOME_BACK": "ברוכים השבים",
    "BACK_TO_MAIN_SCREEN": "בחזרה למסך הראשי",
    "SELECT_EXISTING_CARD": "בחירה מכרטיס קיים",
    "ADD_NEW_CARD": "הוסף כרטיס חדש",
    "SAVE_CARD_AS_DEFAULT": "שמירת כרטיס כברירת מחדל",
    "ORDER_TOTAL": "סה\"כ הזמנה",
    "ORDER_TIP": "טיפ",
    "PAID": "שולם",
    "PERFORMING_PAYMENT": "מבצע תשלום...\nנא לא לסגור את החלון",
    "FINALIZING_ORDER": "עובדים על זה...\nבבקשה לא לסגור את העמוד",
    "ENTER_ORDER_N": "הזינו קוד הזמנה",
    "REQUIERED": "חובה למילוי",
    "REQUIERED_CHECK": "חובה לסמן",
    "PASSWORDS_DONT_MATCH": "סיסמאות לא תואמות",
    "PASSWORDS_MIN_LENGTH": "סיסמא חייבת להיות בת 6 תווים לפחות",
    "INVALID_CREDIT_NUMBER": "מס' כרטיס אינו חוקי",
    "INVALID_CVV": "CVV אינו חוקי",
    "INVALID_CARD_EXPIRATION": "תאריך תפוגה אינו חוקי",
    "INVALID_EMAIL": "דוא\"ל אינו תקין",
    "EMAIL_FOR_INVOCE": "EMAIL לשליחת חשבונית",
    "CARD_EXPIRATION": "תאריך תפוגה",
    "CARD_HOLDER_CELL": "טלפון של בעל הכרטיס",
    "CARD_HOLDER_MAIL": "מייל של בעל הכרטיס",
    "Q_SHARING_BILL": "לתשלום חלקי",
    "Q_TIP_AMOUNT": "כמה טיפ להוסיף?",
    "Q_HOW_MUCH_PAY": "כמה ברצונך  לשלם?",
    "Q_HOW_MUCH_SPLIT": "בין כמה אנשים לפצל את החשבון?",
    "Q_REGISTARED": "נרשמת בעבר? התחברות",
    "Q_NEW_REGISTARED": "לקוח חדש? הרשמה",
    "Q_INVOICE_BY_MAIL": "רוצה לקבל חשבונית במייל?",
    "Q_REMOVE_CARD": "למחוק כרטיס זה?",
    "Q_REMOVE_PAYMENT": "למחוק תשלום זה?",
    "SIGNIN_REGISTER": "הרשמה / התחברות",
    "END": "סיום",
    "ENTER_TIP": "הקלד טיפ",
    "ENTER_AMOUNT": "הקלד סכום",
    "SPLIT_AMOUNT": "חלוקת הסכום",
    "YOUR_AMOUNT_TO_PAY": "הסכום שלך לתשלום?",
    "PRESS_HERE": "לחץ כאן",
    "OTHER": "סכום אחר",
    "SENDING_INVOICE": "שולח חשבונית...",
    "CHALLENGE_CODE": "קוד אימות",
    "ENTER_CHALLENGE_CODE": "הקלד את קוד האימות",
    "REMEMBER_ME_ON_PHONE": "זכור אותי במכשיר זה",
    "BILL_AMOUNT": "סכום החשבון",
    "PAY_WITH_ONOTHER_CARD": "שלם עם כרטיס אחר",
    "PAYMENT_METHOD": "אמצעי תשלום",
    "SELECT_PAYMENT_METHOD": "בחירת אמצעי תשלום",
    "CREDIT_CARD": "כרטיס אשראי",
    "CREDIT": "אשראי",
    "creditCard": "אשראי",
    "CIBUS": "סיבוס",
    "Cibus": "סיבוס",
    "TENBIS": "תן ביס",
    "10bis": "תן ביס",
    "Credit": "כרטיס אשראי",
    "Ten Bis": "תן ביס",
    "cash": "מזומן",
    "EDIT_ACCOUNT_SETTINGS": "שינוי הגדרות חשבון",
    "MY_ACCOUNT": "החשבון שלי",
    "SIGN_OUT": "התנתקות",
    "MY_ACCOUNT_DETAILS": "פרטי החשבון שלי",
    "EDIT_PAYMENT_INFO": "ערוך אמצעי תשלום",
    "DELETE_CARD": "מחק כרטיס",
    "SET_DEFAULT_CARD": "קבע כבררת מחדל",
    "YES_DELETE": "כן, מחק",
    "OFFER_REMARKS": "הערות למנה",
    "ENTER_OFFER_REMARKS": "הערות למנה",
    "DELETE_PAYMENT": "מחק תשלום",
    "CONFIRM_CELL": "Confirm Phone",
    "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your mobile phone",
    "USE_ENTERED_PHONE": "Send to:",
    "ENTER_ALTERNATE_PHONE": "Use other:",
    "EMAIL_NOT_FOUND": "Email לא נמצא במערכת",
    "GRATUITY": "טיפ",
    "WITHOUT_GRATUITY": "ללא טיפ",
    "ORDER_SCHEDULING": "מתי מתאים לך לקבל את ההזמנה?",
    "ORDER_SCHEDULING_TA": "מתי מתאים לך לקבל את ההזמנה?",
    "ORDER_SCHEDULING_DELIVERY": "מתי מתאים לך לקבל את ההזמנה?",
    "THROTTLING_DISCLAIMER": "המסעדה עמוסה כרגע ומקבלת רק הזמנות דחויות",
    "SELECT_SERVICE_TYPE": "סוג השרות",
    "CLOSE": "סגור",
    "YES": "כן",
    "NO": "לא",
    "REMOVE": "הסר",
    "SEND_ORDER": "שלח הזמנה",
    "TRACK_YOUR_ORDER": "למעקב אחר ההזמנה שלך",
    "CONTINUE_ORDERING": "המשך להזמין",
    "ADD_ITEM": "הוספת פריט",
    "SELECT_ITEM": "בחרו פריט",
    "UPDATE_ITEM": "עדכן פריט",
    "TOPPINGS": "תוספות למנה",
    "DECISIONS": "החלטות",
    "ITEM_CHANGES": "שינויים במנה",
    "SELECTION_GROUP_SELECT": "(בחרו {{count}} פריטים)",
    "SELECTION_GROUP_SELECT_SINGLE": "(בחרו פריט אחד)",
    "SELECTION_GROUP_SELECT_MIN": "(בחרו לפחות {{count}} פריטים)",
    "SELECTION_GROUP_SELECT_SINGLE_MIN": "(בחרו לפחות פריט אחד)",
    "GROUP_MOD_SELECT_ERROR": "בחרו לפחות {{count}} תוספות מ: {{group}}",
    "GROUP_MOD_SELECT_SINGLE_ERROR": "בחרו לפחות תוספת אחת מ: {{group}}",
    "GROUP_SELECT_ERROR": "בחרו לפחות {{count}} פריטים מ: {{group}}",
    "GROUP_SELECT_SINGLE_ERROR": "בחרו לפחות פריט אחד מ: {{group}}",
    "SELECTION_GROUP_OPTIONAL_MAX": "אופציונלי, בחרו עד {{count}} פריטים",
    "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "אופציונלי, בחרו עד פריט אחד",
    "SELECTION_GROUP_DIALOG_ERROR": "אירעה שגיאה בטעינת הפריט, יש לנסות שנית או לפנות למסעדה",
    "CHECKOUT": "לתשלום",
    "Q_REMOVE_OFFER_FROM_BASKET": "להסיר את הפריט?",
    "ENTER_DELIVERY_ADDRESS": "כתובת למשלוח",
    "ENTER_DELIVERY_ADDRESS_ALT": "הי, רק לבדוק אם מגיעים אליך…",
    "SELECT_ADDRESS": "בחירת בכתובת",
    "DELETE_ADDRESS_VALIDATE": "למחוק את הכתובת?",
    "BRANCH": "מסעדה",
    "ADDRESS": "כתובת",
    "PHONE": "טל",
    "ENTRANCE": "כניסה",
    "FLOOR": "קומה",
    "apartment": "דירה",
    "ENTER_STREET_INCLUDING_NUMBER": "הזינו כתובת מלאה",
    "MISSING_STREET_NUMBER": "חסר מספר בית",
    "START_ORDER": "המשך להזמנה",
    "SELECT_CITY": "בחרו עיר",
    "SELECT_DELIVERY_CITY": "בחרו עיר למשלוח",
    "SELECT_BRANCH": "בחרו מסעדה",
    "ORDER_MENU": "תפריט",
    "takeaway": "איסוף עצמי",
    "eatin": "הזמן ושב",
    "delivery": "משלוח",
    "seated": "ישיבה במסעדה",
    "RESET_ORDER": "התחלת הזמנה מחדש",
    "ORDER_FROM": "הזמנה מסניף",
    "ORDER_TO": "משלוח לכתובת",
    "CONTACT_INFO": "פרטים אישיים",
    "CONTACT_ALCOHOL_DECLARATION": "אני מעל גיל {{age}} וככל שידרש, אציג תעודה מזהה לאימות לצורך קניית אלכוהול",
    "PAYMENT_INFO": "פרטי אשראי",
    "FIRST_NAME": "שם פרטי",
    "LAST_NAME": "שם משפחה",
    "CELL": "סלולרי",
    "EMAIL": "Email",
    "REVIEW_YOUR_ORDER": "פרטי ההזמנה",
    "ADD_ITEMS": "הוסף פריטים",
    "UPDATE_ITEMS": "עדכון פריטים",
    "ORDER_SENT_SUCCESS": "ההזמנה נשלחה בהצלחה",
    "ORDER_NEED_ATTENDANCE": "ההזמנה נשלחה עם שגיאה",
    "CALL_RESTAURANT_TO_COMPLETE_ORDER": "אנא התקשרו למסעדה",
    "ORDER_NUMBER": "מספר הזמנה",
    "START_NEW_ORDER": "התחלת הזמנה חדשה",
    "ORDER_ITEMS": "פרטי ההזמנה",
    "ORDER_WILL_BE_SENT_TO": "הזמנה תישלח לכתובת",
    "ESTIMATED_DELIVERY_TIME": "זמן הגעה כ",
    "ESTIMATED_PREPARATION_TIME": "זמן הכנה כ",
    "ESTIMATED_SUPPLY_TIME": "זמן אספקה עד",
    "MINUTES": "דקות",
    "MINUTES_SHORT": "דק'",
    "PREPARATION_TIME": "זמן הכנה",
    "ORDER_TYPE": "סוג הזמנה",
    "PLEASE_SELECT": "אנא בחר",
    "PLACEMENT": "אפשרויות",
    "NUMBER_OF_PEOPLE": "מס' סועדים",
    "READ_AND_APPROVE_CONTRACT": "קראתי ואני מסכים",
    "READ_AND_APPROVE_CONTRACT_C": "לתנאי השימוש",
    "READ_AND_APPROVE_CONTRACT_D": "למדניות הפרטיות",
    "READ_CONTRACT": "קרא את פרטי השימוש...",
    "PLEASE_APPROVE_CONTRACT": "אנא אשר את תנאי השימוש",
    "USAGE_CONRACT": "תנאי השימוש",
    "PRIVACY_CONRACT": "מדיניות הפרטיות",
    "ADDRESS_REMARKS": "הערות לשליח",
    "LOADING_MENU": "טוען תפריט...",
    "MOD_WITH": "תוספות",
    "MOD_WITHOUT": "ללא",
    "PASSWORD": "סיסמא",
    "CONFIRM_PASSWORD": "הזן סיסמא בשנית",
    "FORGOT_PASSWORD": "שכחתי סיסמא...",
    "RESTORE_PASSWORD": "שחזר סיסמא",
    "INCLUDE_SILVERWARE": "הוספת סכו\"ם חד פעמי",
    "CURBSIDE_PICKUP": "הזמנה לרכב",
    "CURBSIDE_PICKUP_DESC": "תארו בבקשה מה דגם הרכב, הצבע ומספר הרישוי",
    "AUTHENTICATING": "מאמת פרטים",
    "SIGNED_IN_AS": "מחובר בשם",
    "PAY_WITH": "שלם באמצעות",
    "PAY_WITH_SAVED_PMS": "אמצעי תשלום שמורים",
    "SELECT_ALTERNATE_PYMENT": "בחרו באמצעי תשלום אחר",
    "SIGHN_IN_EMAIL": "Email להזדהות",
    "SIGHN_IN_PASSWORD": "סיסמא להזדהות",
    "UPDATE_PAYMENT_INFO": "עדכן אמצעי תשלום",
    "SAVE_ACCOUNT_PAYMENT_INFO": "שמירת אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
    "UPDATE_ACCOUNT": "עדכן חשבון",
    "ADD_PAYMENT_INFO": "הוספת אמצעי תשלום",
    "UPDATING_ACCOUNT": "מעדכן את חשבונך...",
    "UPDATE_SIGNIN_INFO": "עדכן פרטי הזדהות",
    "PASS_MIN_LENGTH_ERROR": "סיסמא חייבת להכיל לפחות 6 תווים",
    "OLD_PASSWORD": "סיסמא ישנה",
    "NEW_PASSWORD": "סיסמא חדשה",
    "BRANCH_TIME_0": "שעות פעילות השירות {{t0}}",
    "BRANCH_TIME_1": "שעות פעילות השירות {{t0}} ו {{t1}}",
    "BRANCH_TIME_CONFIRM_0": "אנחנו סגורים כרגע. נחזור לפעילות בין השעות {{t0}}",
    "BRANCH_TIME_CONFIRM_1": "אנחנו סגורים כרגע. נחזור לפעילות בין השעות {{t0}} ו {{t1}}",
    "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "השירות כבר לא זמין. נחזור לפעילות בין השעות {{t0}}",
    "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "השירות כבר לא זמין. נחזור לפעילות בין השעות {{t0}} ו {{t1}}",
    "CONFIRM": "אשר",
    "ORDER_SUCCESS": "אישור הזמנה",
    "MIN-ORDER-PRICE": "סכום הזמנה מינימלי",
    "DELIVERY-PRICE": "דמי משלוח",
    "TOTAL-ITEMS": "סה\"כ פריטים",
    "TOTAL-TO-PAY": "סה\"כ לתשלום",
    "WITHOUT": "ללא",
    "CONTINUE_TO_PAYMENTINFO": "המשך לפרטי תשלום",
    "PAYMENT": "תשלום",
    "BRANCHES": "סניפים",
    "SERVER_ERROR": "שגיאת שרת",
    "ERROR": "שגיאה",
    "PAYMENT_ERROR": "שגיאת תשלום",
    "PARTIAL_PAYMENT_ERROR": "שגיאת תשלום",
    "NAME": "שם",
    "REGISTERING": "מבצע הרשמה",
    "REGISTER": "הרשמה",
    "RESET_PASSWORD": "אפס סיסמא",
    "RESET_PASSWORD_INFO": "הזן ססמא חדשה ולחץ על אפס",
    "ORDER_HISTORY": "היסטוריית הזמנות",
    "OFFERS_HISTORY": "ההזמנות האחרונות שלי",
    "SELECT_ORDER_METHOD": "נא לבחור שירות",
    "ORDER_METHOD": "צורת הזמנה",
    "ORDER_FIXED": "חשבון",
    "SET_QUANTITY": "הזן כמות",
    "CELL_PATTERN": "סלולרי צריך להתחיל ב 05",
    "CELL_MINLENGTH": "סלולרי צריך להכיל 10 תווים",
    "VIEW_MENU": "צפייה בתפריט",
    "ORDER_SERVICE_CLOSED": "שרות ההזמנות סגור",
    "ESTIMATED_TIME": "זמן משוער",
    "SET_ORDER_DELAY": "מתי מתאים לך לקבל את ההזמנה?",
    "DELAY_TO": "דחה לשעה",
    "SET_ORDER_DELAY_TO_HOUR": "דחה ביצוע הזמנה לשעה",
    "ASAP": "הכי מוקדם שאפשר",
    "ENTER_CVV": "CVV",
    "ENTER_CVV_MESSAGE": "CVV לכרטיס: {{card}}",
    "ENTER_IDCARD": "תעודת זהות",
    "ENTER_IDCARD_MESSAGE": "תעודת זהות לכרטיס: {{card}}",
    "PRMARY_PAYMENT_METHOD": "ברירת מחדל",
    "ADD_PAYMENT": "הוספת תשלום",
    "SPLIT_PAYMENT": "פיצול תשלום",
    "WALLET_PAYMENT": "ארנק, {{type}}",
    "SPLIT_PAYMENTS_DIFF_ERROR": "תשלומים לא מסתכמים ל {{total}}, קיים הפרש של {{diff}}",
    "BACK_TO_SITE": "בחזרה לאתר",
    "BACK_TO_APP": "בחזרה לאפליקציה",
    "FREE_DELIVERY_FROM": "משלוח חינם ממחיר",
    "ADD_REMAINNING_AMOUNT": "השלם תשלום לסכום ההזמנה",
    "PAYMENTS_EXCEED_TOTAL_BY": "סה\"כ התשלומים גדול מסכום ההזמנה ב",
    "PAYMENT_AMOUNT": "סכום התשלום",
    "ENTER_FIRST_PAYMENT_AMOUNT": "הזן סכום לתשלום הראשון",
    "EXCESS_PAYMENT": "יתרת תשלום",
    "CLICK_TO_REMOVE_EXCESS_AMOUNT": "הקש להורדת הסכום העודף",
    "CANCEL_SPLIT_PAYMENT": "בטל חלוקת תשלום",
    "APPLY_PAYMENTS": "אשר תשלומים",
    "SINGLE_PAYER": "משלם יחיד",
    "MULTIPLE_PAYERS": "מספר משלמים",
    "SPLIT_ORDER_BETWEEN_PAYERS": "חלק בין מספר משלמים",
    "CALL_RESTAURANT": "התקשר למסעדה",
    "SELECT_TABLE": "לאן להגיש את האוכל",
    "TABLE_NO": "מספר שולחן",
    "TABLE_NO_ORDER": "מספר שולחן {{no}}",
    "ENTER_ORDER_NO": "הזן מספר הזמנה",
    "ADD_GRATUITY_TO_ORDER": "טיפ",
    "ADD_GRATUITY_TO_COURIER": "רוצה להוסיף טיפ?",
    "ORDER_TOTAL_WITHOUT_GRATUITY": "סה\"כ לתשלום ללא טיפ",
    "ORDER_TOTAL_WITH_GRATUITY": "סה\"כ תשלום כולל טיפ: {{total}}",
    "GRATUITY_AMOUNT": "סכום הטיפ",
    "GRATUITY_PERCENT": "אחוז טיפ",
    "ENTER_GRATUITY_AMOUNT": "הזן סכום טיפ",
    "CONTINUE_TO_GRATUITY": "המשך להזנת טיפ",
    "REFRESH_ORDER": "רענן הזמנה",
    "MONTH": "חודש",
    "YEAR": "שנה",
    "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "הזן סכום לתשלום, לא כולל טיפ",
    "ENTER_AMOUNT_TO_PAY": "סכום לתשלום",
    "FULL_PAYMENT": "תשלום מלא",
    "PARTIAL_PAYMENT": "תשלום חלקי",
    "AMOUNT_EXEEDS_BALANCE_DUE": "הסכום גדול מיתרת התשלום",
    "MAX_TO_USE": "לרשותך {{amount}} {{type}} למימוש",
    "EXCEEDED_CASH_AMOUNT": "הסכום המירבי לתשלום מזומן הוא {{amount}} ש”ח",
    "POINTS": "הטבת צבירה",
    "CANNOT_PAY_TIP": "לא ניתן להשאיר טיפ עם אמצעי תשלום זה",
    "MY_WALLET": "הארנק שלי:",
    "OTHER_TENDERTYPES": "אחר:",
    "NEW_PAYMENT": "תשלום חדש",
    "REMAINDER_NOT_EXISTS": "לא נותרה יתרה לתשלום, יש לשנות את סכומי התשלומים",
    "BACK": "חזור",
    "PASS_ORDER": "חולקים חשבון?",
    "PASS_ORDER_TITLE": "העבר לחבר",
    "PASS_ORDER_BY_PHONE_MESSAGE": "להעברת הקישור בהודעת טקסט יש להכניס מספר טלפון",
    "PASS_ORDER_BY_PHONE_CAPTION": "מספר סלולרי",
    "PASS_ORDER_BY_PHONE_SEND": "שלח הזמנה",
    "PASS_ORDER_BY_PHONE_QR_MESSAGE": "או לסרוק את הברקוד",
    "PASS_ORDER_SENT_SUCCESS": "ההזמנה נשלחה בהצלחה",
    "CANNOT_SEND_ORDER_BY_SMS": "אנו מתנצלים, אך כרגע לא ניתן לשלוח את הקישור.",
    "WORK_HOURS": "שעות פעילות",
    "RESTAURANT_WEBSITE": "אתר המסעדה",
    "SELECT_ONE": "בחרו אחד",
    "DELIVERY_ADDRESS": "כתובת למשלוח",
    "DELIVERY_DETAILS": "פרטי משלוח",
    "ADDRESS_DETAILS": "פרטי כתובת",
    "BRANCH_DETAILS": "פרטי מסעדה",
    "ADDITIONAL_OPTIONS": "אפשרויות נוספות",
    "MINUTES_VAL": "{{m}} דקות",
    "ADDITIONAL_OFFERS": "תוספות ורטבים",
    "SELECT_ADDITIONAL_OFFERS": "בחרו תוספות ורטבים",
    "CONTINUE_TO_CONTACTINFO": "המשך לפרטי קשר",
    "CC_CUSTOMER_NAME": "שם",
    "CC_CUSTOMER_ID": "ת.ז / ח.פ",
    "ID_CARD": "תעודת זהות",
    "ORDERER_NAME": "שם המזמין",
    "ORDERER_PHONE": "טלפון",
    "ORDER_NO": "מספר הזמנה",
    "ORDER_TAGS": {
        "BREAKFAST": "בוקר",
        "LUNCH": "צהריים",
        "DINNER": "ערב",
        "WORK": "עבודה"
    },
    "ENTER_LOCALITY": "שם עיר/ישוב",
    "LOCALITY_TITLE": "שם עיר/ישוב",
    "STREET_TITLE": "שם רחוב",
    "HOUSE_TITLE": "מס' בית",
    "TITLE": "{{name}} משלוחים - TabitOrder",
    "TITLE_1": "{{name}} משלוחים",
    "TITLE_2": "{{name}} - TabitOrder",
    "PAYMENT_IN_PROGRESS": "מבצע תשלום...\nנא לא לסגור את החלון",
    "CREATING_ORDER": "שולח עסקה...",
    "LOADING_ORDER": "עסקה בטעינה...",
    "INVALID_AMOUNT": "סכום לתשלום איננו תקין",
    "INVALID_AMOUNT_RANGE": "סכום חוקי בין {{min}} ל {{max}}",
    "INVALID_AMOUNT_MIN": "סכום מינימלי {{min}}",
    "INVALID_AMOUNT_MAX": "סכום מקסימלי {{max}}",
    "BACK_TO_MENU": "בחזרה לתפריט",
    "ORDER_NOTES": "הערות הזמנה",
    "Q_SAVEPM_1": "מעוניינים לקצר את זמן ההזמנה?",
    "Q_SAVEPM_2": "ניתן לשמור את אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
    "Q_SAVEPM_3": "תשלום ושמירה",
    "Q_SAVEPM_4": "תשלום ללא שמירה",
    "DISH_CALCULATOR": "מחשבון מנות",
    "MAKE_ADDITIONAL_PAYMENT": "המשך לתשלום נוסף",
    "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "תשלום נוסף במכשיר זה",
    "DINER": "סועד",
    "GENERAL_DINER": "מרכז השולחן",
    "ADDRESS_WITHOUT_HOUSE": "כתובת ללא מס' בית",
    "TAX_AMOUNT": "מיסים",
    "ADDRESS_TYPE": "סוג כתובת",
    "VALID_UNTILL": "בתוקף עד",
    "COMMENT": "הערה",
    "LEAVE_ORDER_OUTSIDE": "השאירו את המשלוח מחוץ לדלת",
    "LEAVE_ORDER_OUTSIDE_CASH": "אז בואו נשמור מרחק 😊- יתאפשרו תשלומים דיגיטליים בלבד",
    "LEAVE_ORDER_OUTSIDE_COMMENT": "השאירו מחוץ לדלת",
    "arr_join": " ו",
    "SIGN_OUT_MESSAGE": {
        "TITLE": "שנייה לפני שנפרדים",
        "TEXT": "רק מוודאים שבאמת רצית להתנתק",
        "PRIMARY_BUTTON": "כן",
        "SECONDARY_BUTTON": "לא, אני רוצה להמשיך"
    },
    "MESSAGES": {
        "PLACE-MAP-MARKER": "מקמו את הסמן קרוב ככל האפשר למיקומכם כדי שנוכל למצוא אתכם מהר יותר",
        "MAX_ORDER_OFFERS_CONT": "כבר אין מקום בסל…\nלא ניתן להזמין יותר מ-{{max}} פריטים בהזמנה",
        "MAX_CAT_OFFERS_CONT": "לא ניתן להזמין יותר מ-{{max}} פריטים מתפריט {{target}}",
        "MAX_SAME_DISH_COUNT": "לא ניתן להזמין יותר מ-{{max}} {{target}}",
        "SIGNIN-EMAIL-EX": "הזן את כתובת המייל איתה נרשמת בעבר",
        "SIGNIN-EMAIL-EX-HELP": "קוד אימות ישלח לטלפון הנייד המשויך לכתובת המייל",
        "SIGNIN-CHALLENGE-EX": "קוד אימות נשלח לטלפון",
        "SIGNIN-CHALLENGE-EX-HELP": "לא קיבלת את הקוד? <b>שלחו שוב</b>",
        "SAVE_INFO": "שמור פרטי קשר ותשלום",
        "SAVE_INFO_END": "הרשמו להזמנה קלה ומהירה בעתיד",
        "SAVE_INFO_PAYMENT": "שמור פרטי תשלום",
        "SIGNIN_CHALLENGE": "קוד תשובה נשלח אליך באמצעות SMS, אנא הזן אותו ולחץ על התחבר",
        "PAYMENT_CHALLENGE": "שלחנו קוד אימות ל-\n{{phone}}",
        "PAYMENT_ENTER_PHONE": "רק אימות זריז של הטלפון ונמשיך",
        "PAYMENT_OTP": "שלחנו לך קוד אימות ל\n {{phone}}",
        "ROOM_CHARGE_CHALLENGE": "קוד אימות נשלח למספר המסתיים בספרות {{number}}",
        "TRANSACTION_SUCCESS": "תשלום בוצע בהצלחה",
        "ORDER_CLOSED": "ההזמנה שולמה במלואה",
        "INVALID_ORDER": "הזמנה לא נמצאה",
        "PAYMENT_FAILED": "התשלום נבשל",
        "INVALID_PIN": "הקןד שהוזן אינו תואם",
        "SIGN_FOR_PAYMENT_CONFIRM": "לאישור תשלום חתום כאן",
        "SIGNATURE_MANDATORY": "על מנת שנוכל לאשר את אמצעי התשלום, חובה לחתום!",
        "WAITER_NOTIFIED": "הצוות שלנו קיבל אות קריאה ויגעו אליך בקרוב!",
        "HAPPY": "נשמח לראותכם שוב",
        "INVOICE_SENT": "חשבונית נשלחה אליך במייל",
        "ITEM_ADDED_TO_BASKET": "פריט הוסף לסל",
        "NO_BASKET_ITEMS": "אין פריטים בסל",
        "CONFIRM_ORDER_FROM_BRANCH": "בחרת להזמין מסניף: {{branch}}",
        "FINDING_THE_BRANCH_NEAREST_TO_YOU": "מאתר את הסניף הקרוב לביתכם",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "לא נמצא סניף שמשרת את הכתובת: {{address}}",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "כרגע אנחנו שולחים לכתובת זו הזמנות עתידיות בלבד",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "כרגע אנחנו שולחים לכתובת זו הזמנות מיידיות בלבד",
        "NO_BRANCH_SERVING_ADDRESS": "המסעדה לא מבצעת משלוחים לכתובת זאת",
        "CONFIRM_RESET_ORDER": "להתחיל את ההזמנה מחדש?",
        "DO_YOU_APPROVE_CONTRACT": "יש אישור לתנאי השימוש?",
        "DO_YOU_APPROVE_CONTRACT_TITLE": "שנייה לפני שממשיכים",
        "CONDITIONS_PRIMARY_BUTTON_TEXT": "כן",
        "CONDITIONS_SECONDARY_BUTTON_TEXT": "יציאה",
        "RESTORE_PASSWORD_1": "אנא הזן את ה Email בו השתמשת להזדהות.",
        "SIGNIN_SUCCESS": "ברוכים השבים {{name}}, התחברת בהצלחה למערכת",
        "FORGOTPASS_SUCCESS": "הוראות התחברות נשלחו לכתובת ה Email שהזנתם",
        "ACCOUNT_UPDATE_SUCCESS": "חשבונך עודכן בהצלחה!",
        "TITLE_HUNGRY": "מצטערים לבאס",
        "BRANCH_DISABLED_NOW": "השירות סגור כעת.\n {{t}}.",
        "BRANCH_DISABLED_NOW_ERROR": "השירות כבר לא זמין. נחזור לפעילות בין השעות {{t}}.",
        "BRANCH_DISABLED_NOW_DELAYED_ENABLED": "השרות סגור כעת. ניתן לבצע הזמנה ליום אחר .",
        "BRANCH_CLOSED_TODAY": "שירות זה אינו פעיל היום",
        "BRANCH_CLOSED_NOW": "השירות יפתח בשעה {{t}}, אך אל דאגה,\nניתן לבצע הזמנה מוקדמת אשר תיכנס להכנה כשהשירות יפתח.",
        "MIN-ORDER-PRICE-TITLE": "כמעט סיימנו...",
        "MIN-ORDER-PRICE-BUTTON": "חזרה לתפריט",
        "MIN-ORDER-PRICE": "נותרו {{t}} כדי להגיע למינימום הזמנה.\nהוסיפו עוד פריטים כדי להמשיך",
        "SERVER_ERROR": "אירעה שגיאה בעיבוד הבקשה,\nאנא צרו קשר עם המסעדה",
        "PAYMENT_ERROR": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים.",
        "PAYMENT_ERROR_TITLE": "שגיאת תשלום",
        "REGISTER_SUCCESS": "נרשמת בהצלחה",
        "PASSWORD_RESET_SUCCESS": "סיסמא אופסה בהצלחה",
        "CUSTOMER_ENTITYALREADYEXISTS": "לקוח עם האימייל הנתון קיים כבר במערכת",
        "PHONE_VERIFICATION_FAILED": "נראה שהקוד אינו נכון או שפג תוקפו. אנא נסו שוב.",
        "PHONE_VERIFICATION_FAILED_TITLE": "רגע...",
        "TOO_MANY_CODE_REQUESTS": "חשבונך נחסם באופן זמני מפני שהגעת למספר הניסיונות המרבי המותר.\nיש לנסות שוב מאוחר יותר.",
        "VOUCHER_ALREADY_USED": "נראה שהקופון כבר מומש",
        "SERVICE_UNAVAILABLE": "שירות זה אינו פעיל כעת, אנא נסו שוב מאוחר יותר",
        "SERVICE_UNAVAILABLE_THROTTLING": "המסעדה בתפוסה מלאה של הזמנות כרגע, אבל אפשר להתחיל מחדש ולבחור מועד אחר.",
        "SERVICE_UNAVAILABLE_THROTTLING_WITH_PHONE": "המסעדה בתפוסה מלאה כרגע.\nמוזמנים ליצור קשר עם המסעדה בטלפון ({{phone}})",
        "CLP_PAYMENT_CANCELLED_TITLE": "התשלום בוטל",
        "CLP_PAYMENT_CANCELLED": "מתנצלים, המסעדה לא מקבלת כרגע את סוג הכרטיס הזה. נסו לשלם בכרטיס אחר",
        "IG_CANT_SELECT_MORE_THEN": "נא לבחור עד {{max}} פריטים",
        "MOD_CANT_SELECT_MORE_THEN": "יש לבחור לא יותר מ {{max}} תוספות!",
        "MOD_PLEASE_SELECT_AT_LEAST": "יש לבחור לפחות {{min}} תוספות!",
        "MOD_PLEASE_SELECT_AT_LEAST_ONE": "יש לבחור לפחות תוספת אחת!",
        "CANNOT_PAY_TRAINING_MODE": "מערכת במצב תרגול. לא ניתן לסיים את העסקה. אנא התקשרו למסעדה\n {{phone}}",
        "ARE_YOU_SURE": "האם אתה בטוח?",
        "DISABLED_REGION_TITLE": "שירות המשלוחים לא זמין",
        "DISABLED_REGION_MESSAGE": "אנחנו לא מגיעים כרגע לכתובת זו.\nאנא נסו שוב מאוחר יותר",
        "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "כרגע אנחנו שולחים לכתובת זו הזמנות מיידיות בלבד",
        "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "כרגע אנחנו שולחים לכתובת זו הזמנות עתידיות בלבד",
        "ENABLED_REGION_TIME_MESSAGE": "נחזור לפעילות בין השעות {{t}}",
        "ENABLED_REGION_DAY_MESSAGE": "נחזור לפעילות {{d}}, בין השעות {{t}}",
        "OUT_OF_REACH": "מצטערים לבאס",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "אחד או יותר מהתשלומים שהזנת נכשלו.אנא התקשרו למסעדה להשלמת ההזמנה.\n {{phone}}",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "אחד או יותר מהתשלומים שהזנת נכשלו. נא לפנות למלצר/ית להמשך טיפול",
        "ORDER_CHANGED_AFTER_HANDOFF": "שימו לב!\nההזמנה כבר נשלחה למסעדה, השינויים החדשים לא נשמרו.\nיש להתקשר למסעדה לביצוע שינוי: {{phone}}",
        "SELECTTABLE_INFO": "אנא הזן את מספר השולחן אליו אתה רוצה להזמין",
        "ENTERORDER_INFO": "אנא הזן את מספר ההזמנה עליה אתה רוצה לשלם.",
        "INVALID_TABLE": "השולחן לא קיים",
        "ORDER_RELOAD_NEEDED": "נא לשים לב! הסכום לתשלום השתנה!",
        "ORDER_HELD_BY_SERVER": "אנו מתנצלים, אך לא ניתן לשלם ברגע זה. נא לנסות שוב בעוד כמה דקות",
        "SESSION_VALIDATION_FAILED": "אירעה שגיאה בעיבוד הבקשה, יש לרענן את העמוד ולנסות שוב",
        "PAY_ORDER_CHANGED": "ההזמנה השתנתה! אנא עבור על ההזמנה.",
        "PAYMENT_ACCEPTED_SUCCESSFULLY": "התשלום בוצע בהצלחה",
        "PAYMENT_ACCEPTED": "התשלום בוצע בהצלחה",
        "ITEM_OUTOF_STOCK": "המנה שבחרת לא נמצאת במלאי כרגע, אבל יש עוד המון אפשרויות!",
        "ITEM_OUTOF_STOCK_TITLE": "אוי, נגמר",
        "ITEM_OUTOF_STOCK_BUTTON": "חזרה לתפריט",
        "OUT_OF_STOCK": "המנות הבאות אזלו מהמלאי במהלך ההזמנה:\n {{items}}\n נשמח להציע לך אפשרויות אחרות מהתפריט",
        "GRATUITY_CASH_DISABLE": "לא ניתן לשלם במזומן כאשר קיים טיפ",
        "ENABLE_GRATUITY_CREDIT_ONLY": "ניתן לשלם באשראי בלבד כאשר קיים טיפ",
        "ORDER_ITEMS_UNAVALABLE": "לצערנו הפריטים הבאים לא זמינים כרגע להזמנה:",
        "ORDER_TIMEOUT": "תוקף ההזמנה פג! יש להתחיל את ההזמנה מחדש",
        "CONFIRM_ADDRESS": "הכתובת שהוזנה היא:\n<b>{{address}}</b>\nהאם להמשיך?",
        "CONFIRM_ADDRESS_SITE": "המשלוח יגיע מסניף: {{a}}\nלכתובת: {{b}}",
        "PROBLEM_IN_PAYMENT_INPUT": "ארעה שגיאה בקליטת פרטי התשלום\nנא להזין את פרטי התשלום מחדש\nבמידה והבעיה ממשיכה, נא לפנות למסעדה בטלפון: \n {{phone}}",
        "MEAL_CALC_FACTOR_DESC": "ההנחה מחושבת על כל פריט בהתאם למשקלו היחסי בהזמנה",
        "MEAL_CALC_ACTION_DESC": "יש לסמן את המנות לתשלום",
        "MEAL_CALC_TOTAL_DESC": "סה\"כ המנות אינו יכול להיות גדול מהסכום שנותר לתשלום",
        "EX_PAYMENT_ERROR": "ארעה שגיאה בהפניה לספק התשלום",
        "EX_PAYMENT_AUTHRIZATION_ERROR": "ארעה שגיאה באישור תשלום מספק",
        "LOCALITY_WO_STREETS": "לישוב {{locality}} לא מוגדרים שמות רחובות\nהאם ברצונך לבחור בישוב זה כיעד למשלוח?",
        "SERVICE_END_WARN": "השירות יסגר בעוד {{t}} דקות. כדאי לסיים את ההזמנה לפני כן",
        "JUST_SO_YOU_KNOW": "חשוב לנו לעדכן",
        "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "אישור",
        "SUMMARY_SENT_BY_MAIL": "סיכום ההזמנה נשלח למייל שלך",
        "CONTINUE_WITHOUT_GRATUITY": "להמשיך ללא טיפ?",
        "LOYALTY_BENEFIT_TIMEOUT_ERROR": "היי, עבר קצת זמן...\nנצטרך לממש ההטבות שלך מחדש",
        "LOYALTY_BENEFIT_HANDOFF_ERROR": "לא ניתן לשלם עם הכרטיס שמסתיים בספרות {{num}} בהזמנה זו.\nלמידע נוסף יש לפנות למסעדה",
        "LOYALTY_BENEFIT_CONFLICT_ERROR": "אופס..\nנראה שיש בעיה או שההטבה כבר מומשה.\nאנא נסו שוב",
        "CARD_VALIDATION_MISMATCH": "Card validation mismatch",
        "CARD_VALIDATION_ACTION_NEEDED": "נדרשת פעולה נוספת להשלמת הפעולה",
        "ORDER_ITEMS_VALIDATION_ERROR": "כרגע לא ניתן להזמין כמה מהפריטים שנבחרו.\n.אנא צרו קשר עם המסעדה להמשך ההזמנה",
        "SIGNIN_SISABLED": "Sign in/Registration is temporarily disabled. you can still place orders as usual by entering your contact info.",
        "GRATUITY_NOT_ALLOWED_FOR_PM": "במסעדה זו לא ניתן להוסיף טיפ באמצעי תשלום זה.\nיש לבחור אמצעי תשלום אחר או לבטל את תוספת הטיפ.",
    },
    "EX_PAYMENT": {
        "dialog_title": "אישור עסקה",
        "description": "עמוד זה משמש לאישור העסקה עבור תשלום באתר. התשלום יבוצע בפועל רק לאחר השלמת התהליך בדף בית העסק. יש לאשר תחילה כי כל הפרטים בדף זה נכונים ולמלא את פרטי האשראי כנדרש.",
        "popup-title": "העסקה אושרה לביצוע",
        "form-title": "פרטי כרטיס אשראי",
        "popup-description": "עכשיו נעבור לדף בית העסק",
        "submit-btn": "המשך",
        "clear": "איפוס",
        "credit_card_number": "מספר כרטיס",
        "expiration": "תוקף כרטיס",
        "cancel": "ביטול",
        "currency": "מטבע:",
        "transaction_details": "פרטי העסקה",
        "total_amount": "סכום",
        "month": "חודש",
        "year": "שנה",
        "click_here": "לחצו כאן למעבר"
    },
    "SERVER_MESSAGES": {
        "OrderLockedByAnotherUser": "Payment failed. Check is handled by another user",
        "INVALID_USER_ORPASS": "שם משתמש או סיסמא אינם נכונים",
        "UPDATE_ACCOUNT_ERROR": "לא הצלחנו לעדכן את חשבונך",
        "EMAIL_NOT_FOUND": "Email לא נמצא במערכת",
        "EMAIL_IN_USE": "Email קיים במערכת! אנה בחרו ב Email אחר",
        "INVALID_ORDER_ID": "נראה שההזמנה כבר סגורה",
        "FIREBASE_DOWN": "השרות לא זמין",
        "SHVA_OFFLINE": "נראה שיש בעיה עם חיובי אשראי כרגע.\nאנחנו ממליצים לשלם באמצעי תשלום אחר, או אם הרעב לא ממש מציק, לנסות שוב בעוד מספר דקות.",
        "PAYMENT_QUOTA_EXCEEDED": "אין מספיק יתרה בכרטיס ({{v}})",
        "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "הסכום לתשלום חורג מהסכום המאושר לחיוב בחדר. יש לבחור באמצעי תשלום אחר או לפנות לקבלה",
        "SMS_PHONE_ERROR": "לא ניתן לשלוח הודעת סמס למספר שהוזן {{phone}}.\nיש ליצור קשר עם המסעדה",
        "CREDIT_GUARD_EX_ERROR": "אירעה שגיאה בעיבוד הבקשה, אנא נסו שנית."
    },
    "CREDIT_GUARD": {
        "ERROR_CODE_001": "הכרטיס חסום, יש לפנות לחברת האשראי (קוד 001).",
        "ERROR_CODE_002": "הכרטיס גנוב, יש לפנות לחברת האשראי (קוד 002).",
        "ERROR_CODE_003": "אירעה שגיאה, יש לפנות לחברת האשראי (קוד 003).",
        "ERROR_CODE_004": "התקבל סירוב מחברת האשראי, העסקה לא אושרה (קוד 004).",
        "ERROR_CODE_006": "תעודת זהות או שלוש ספרות בגב הכרטיס שגוים, יש לנסות שנית (קוד 006).",
        "ERROR_CODE_010": "העסקה לא מאושרת לביצוע, נדרש אימות מוגבר. יש לפנות לחברת האשראי (קוד 010).",
        "ERROR_CODE_033": "מספר הכרטיס שהוזן שגוי, יש לנסות שנית (קוד 033).",
        "ERROR_CODE_036": "הכרטיס אינו בתוקף (קוד 036).",
        "ERROR_CODE_039": "מספר הכרטיס שהוזן שגוי, יש לנסות שנית (קוד 039).",
        "ERROR_CODE_173": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 173).",
        "ERROR_CODE_200": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 200).",
        "ERROR_CODE_316": "ממשק חיצוני נכשל. יש לפנות לחברת האשראי (קוד 316).",
        "ERROR_CODE_358": "פרטים רגישים לא נמצאו בבסיס הנתונים (קוד 358).",
        "ERROR_CODE_401": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 401).",
        "ERROR_CODE_405": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 405).",
        "ERROR_CODE_414": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 414).",
        "ERROR_CODE_520": "אין הרשאה לביצוע עסקה, יש לפנות לחברת האשראי (קוד 520).",
        "ERROR_CODE_599": "שירות הטוקן של מנפיק האשראי אינו זמין, יש לפנות לחברת האשראי (קוד 599).",
        "ERROR_CODE_695": "כרטיס אשראי מסוג זה אינו נתמך, יש לשלם בכרטיס אחר (קוד 695).",
        "ERROR_CODE_900": "עסקה נחסמה עקב אי התחייבות לעסקה בטוחה (קוד 900).",
    },
    "_LEUMIPAY": {
        "payment_error": "התשלום ב PAY. נכשל!\nאנא נסו שוב",
        "min_order_amount_error": "לתשלום ב PAY סכום פריטי ההזמנה חייב להיות מעל 50 ש\"ח",
        "contrat": "לתקנון PAY..."
    },
    "SLIP": {
        "OTH": "OTH",
        "ITEM_DISCOUNT": "הנחת פריט",
        "TICKET_DISCOUNT": "הנחת חשבון",
        "TICKET_OTH": "הכל עלינו",
        "CHANGE": "טיפ\\עודף"
    },
    "GROUP_VALIDATION": {
        "SELECT_ONE": "בחרו פריט אחד",
        "SELECT_SOME": "בחרו {{min}} פריטים",
        "SELECT_ATLEAST_ONE": "בחרו לפחות פריט אחד",
        "SELECT_ATLEAST_SOME": "בחרו לפחות {{min}} פריטים",
        "SELECT_UPTO_ONE": "בחרו עד פריט אחד",
        "SELECT_UPTO_SOME": "בחרו עד {{max}} פריטים",
        "SELECT_BETWEEN": "בחרו בין {{min}} ל {{max}} פריטים"
    },
    "ACCESS": {
        "ACCESSIBILITY": "תפריט נגישות",
        "ACCESSIBILITY_IN_PROGRESS": "האתר נמצא בתהליך הנגשה",
        "CLEAR_ACCESSIBILITY_FEATURES": "נקה אפשרויות נגישות",
        "monochrom": "מונוכרום",
        "light-contrast": "ניגודיות בהירה",
        "dark-contrast": "ניגודיות כהה",
        "enlarge-font": "הגדלת גופן",
        "reduce-font": "הקטנת גופן",
        "readable-font": "גופן קריא",
        "big-white-curser": "סמן גדול לבן",
        "big-black-curser": "סמן גדול שחור",
        "enlarge-frame": "הגדלה",
        "highlight-links": "הדגשת קישורים",
        "highlight-headers": "הדגשת כותרות",
        "image-info": "תיאור לתמונות"
    },
    "ADDRESS_TYPES": {
        "apartment": "דירה",
        "office": "משרד",
        "house": "בית פרטי"
    },
    "OFFER_BADGES": {
        "new": "חדש",
        "sale": "מבצע"
    },
    "_WEIGHT": {
        "units": "יחידה",
        "price_per_weight": "מחיר ל{{unit}} = {{price}}",
        "item_weight": "משקל פריט",
        "kg": "ק\"ג (kg)",
        "gr": "גרם (gr)",
        "lb": "ליברה (lb)",
        "oz": "לאונקיה (oz)",
        "l": "ליטר (l)",
        "ml": "מיליגרם (ml)",
        "GRAM": "גרם"
    },
    "_BENEFITS": {
        "BirthdayBenefit": "הטבת יום הולדת",
        "AnniversaryBenefit": "הטבת יום נישואים",
        "JoinBenefit": "הטבת הצטרפות",
        "RenewBenefit": "הטבת חידוש",
        "Vouchers": "שובר",
        "General": "הטבה",
        "Other": "הטבה",
        "PointsBenefit": "צבירת נקודות"
    },
    "_LOYALTY": {
        "cancel_benefits": "בטל הנחות",
        "card_balance": "כרטיס נטען",
        "connect": "בדקו לי",
        "continue": "המשך",
        "expires": "בתוקף עד",
        "good_to_see_you_title": "כיף לראות אותך",
        "member_not_found_title": "לא מצאנו אותך",
        "comps_extra_text": "הפיצוי התווסף להזמנה וניתן לממש אותו.",
        "member_not_found": "לא מצאנו אותך ברשימת החברים שלנו",
        "member_not_found_PRIMARY_BUTTON_TEXT": "אישור",
        "member_expired": "החברות אינה בתוקף, לא ניתן לממש הטבות",
        "of": "מ-",
        "points_balance": "יתרת {{points}}",
        "points_balance_header": "יתרת נקודות",
        "points_balance_cap": "יש לך {{x}} {{points}} למימוש מידי",
        "basket_compensation": "מתוכן {{x}} ש״ח פיצוי כספי",
        "punch_card_offer": "נותר {{from}} מ {{to}}",
        "review_benefits": "סיכום הנחות",
        "review_points": "מימוש {{points}}",
        "signout": "התנתק",
        "skip": "דלג",
        "cancel": "ביטול",
        "no_benefits_redeemed": "בעסקה זו לא מומשו הטבות!",
        "redeemed_benefits_title": "בעסקה זו ימומשו ההטבות הבאות",
        "total_benefits": "סה\"כ הנחות",
        "voucher_gtreeting": "היי, יש לך שובר?",
        "voucher_inline_gtreeting": "יש לך שובר?",
        "voucher_gtreeting_info": "רוצה לבדוק הטבות?\nאולי מגיע לך משהו שווה",
        "voucher_placeholder": "מספר שובר",
        "voucher_member_greeting": "ההטבות שלי",
        "voucher_benefits_title": "ההטבות שלי",
        "voucher_signin_error": "לא נמצא שובר עם מספר זה",
        "club_gtreeting": "בואו נבדוק אם יש לך אצלנו הטבות או חברות במועדון",
        "club_inline_gtreeting": "הקלידו את קוד הקופון או מס' חבר המועדון",
        "club_gtreeting_info": "רוצה לבדוק הטבות?\nאולי מגיע לך משהו שווה",
        "club_placeholder": "מספר טלפון/קוד קופון",
        "member_greeting": "היי {{name}}",
        "club_benefits_title": "ההטבות שלי",
        "club_signin_error": "לא נמצא חבר מועדון או שובר עם מספר זה",
        "signin_error_register": "לא מצאנו את המספר שלך ברשימת החברים שלנו<br/><br/><a target=_blank href=xxx>להצטרפות למועדון</a>",
        "select_club": "בחרו מועדון",
        "benefits_title": "ההטבות שלי",
        "no_benefits_title": "נראה שאין לך הטבות זמינות כרגע",
        "register": "הצטרפות",
        "to_promotion_disclaimer": "ההנחה הסופית תחושב לפני התשלום",
        "auth_title": "נדרש קוד אימות",
        "auth_sub_title": "קוד אימות נשלח לטלפון",
        "auth_code": "קוד אימות",
        "auth_resend": "שלחו לי שוב",
        "benefit_types": "תקף ב{{val}} בלבד",
        "max_redeemable_points": "מקסימום למימוש",
        "my_benefits": "ההטבות שלי",
        "connect_info": "מספר טלפון או מספר כרטיס",
        "greeting": "אהלן {{name}}!",
        "greeting_voucher": "אהלן!",
        "balance": "יתרה",
        "hey_greeting": "היי {{name}}!",
        "hey_greeting_pointsStore": "היי {{name}}, יש לך {{points}} {{pointName}}",
        "hey": "היי!",
        "sum_to_use": "בכמה להשתמש?",
        "select_benefits_title": "היי {{name}}\nמה מתחשק לך היום?",
        "select_benefits": "עריכת הטבות",
        "select_benefits_max": "ניתן לממש עד {{val}} הטבות בהזמנה",
        "benefits_max_in_this_order": "מזמינים אותך ליהנות מ-<b>{{val}}</b> הטבות לבחירתך",
        "benefits_max_in_this_order_single": "מזמינים אותך ליהנות מההטבה הבאה:",
        "select_benefits_desc": "יש לוודא שהפריטים הכלולים בהטבות נמצאים בהזמנה.\nההטבות ימומשו לפני התשלום",
        "selected_not_redeemed": "לא מומשו התנאים לקבלת ההטבות הבאות:",
        "selected_not_redeemed_desc": "יש לוודא את תכולת ההזמנה או לבחור הטבה אחרת",
        "CLUB_BENEFITS_ON_NEXT_STAGE": "הטבות המועדון שלך יחושבו בשלב הבא",
        "GETTING_BENEFITS": "מחשב הטבות מועדון...",
        "POINTS_COMPS_PAY": "פיצוי כספי",
        "POINTS_AND_COMPS_PAY": "ופיצוי כספי",
        "POINTS_COMPS": "לרשותך פיצוי כספי בסך {{amount}} {{currency}} למימוש 🎁",
        "POINTS_WAITING": "לרשותך {{amount}} {{caption}} למימוש 🎁",
        "POINTS_AND_COMPS": "לרשותך {{points}} נקודות למימוש (מתוכן {{amount}} {{currency}} פיצוי כספי) 🎁",
        "voucher_entered": "שובר הוזן",
        "registration_terms": "ההרשמה היא גם אישור לקבל מדי פעם הודעות SMS על הטבות ומבצעים משתלמים. אפשר להתנתק מרשימת התפוצה בכל רגע",
        "regCostMessage": "ההצטרפות למועדון כרוכה בתשלום של {{currency}}{{price}} שיתווספו לסל הפריטים בהזמנה שלך",
        "confirmDeleteOfferJoin": "לבטל את ההצטרפות למועדון?",
        "confirmDeleteOfferRenewal": "לבטל את חידוש המועדון?",
        "confirmDeleteOfferJoinDescription": "ביטול ההצטרפות למועדון ימנע את קבלת ההטבות",
        "confirmDeleteOfferRenewalDescription": "ביטול חידוש המועדון ימנע את קבלת ההטבות",
        "removeOfferJoinButton": "ביטול ההצטרפות",
        "removeOfferRenewalButton": "ביטול החידוש",
        "keepOfferJoinButton": "להמשיך מאותו מקום",
        "keepOfferRenewalButton": "להמשיך מאותו מקום",
        "regTitle": "חברי מועדון נהנים יותר!",
        "regDetailsDescription": "לא מצאנו את מספר הטלפון שלך {{phone}} ברשימת החברים שלנו. זה הזמן להצטרף ולהנות מהטבות!",
        "regCancel": "ביטול",
        "continueReg": "הצטרפות למועדון",
        "regRenewMessage": "חידוש המועדון כרוך בתשלום של {{currency}}{{price}}, שיתווספו לסל הפריטים בהזמנה שלך",
        "regPromptTitle": "לא מצאנו את המספר {{phone}} במועדון שלנו.\nזה הזמן להצטרף!",
        "regPromptPrice": "ההצטרפות למועדון עולה {{currency}}{{price}}",
        "regUpdateNessage": "אנא ודא שהפרטים שהוזנו תקינים",
        "registerButton": "הצטרפות",
        "continueWithoutRenewing": "המשך ללא חידוש",
        "continueWithoutReg": "המשך ללא הצטרפות",
        "renewMembership": "חידוש מועדון",
        "phoneNumber": "מספר נייד",
        "textForConfirmSms": "הנני מאשר/ת למועדון לשלוח לי חומרים פרסומיים מעת לעת באמצעות מסרונים. אוכל להסיר את עצמי מרשימת התפוצה בכל עת.",
        "textForConfirmClubRules": "הנני מאשר/ת את תקנון המועדון",
        "regRenewPromptPrice": "חידוש המועדון כרוך בתשלום של {{currency}}{{price}}",
        "regRenewTitle": "הזמן טס כשטעים!",
        "regRenewBody": "הגיע הזמן לחדש את החברות במועדון שלנו",
        "regSoonExpiredRenewBody": "תוקף החברות במועדון פג ב-{{date}}.\nכדי להמשיך ולהנות מהטבות המועדון, ניתן לחדש את החברות כבר עכשיו!",
        "regSoonExpiredSignOut": "תוקף החברות במועדון פג ב-{{date}}.",
        "termsContinue": "קדימה להטבות",
        "termsBody": "נראה שטרם השלמת את ההרשמה למועדון \n כמה פרטים אחרונים וההטבות אצלך :)",
        "termsFirstContinue": "השלמת פרטים",
        "termsTitle": "היי {{name}}",
        "first_name": "שם פרטי",
        "last_name": "שם משפחה",
        "email": "כתובת אימייל",
        "zehut": "תעודת זהות",
        "phone": "מספר נייד",
        "anniversary": "יום נישואין",
        "birth_date": "תאריך לידה",
        "regErrorTitle": "משהו השתבש",
        "regError": "לא ניתן להשלים את תהליך ההצטרפות, יש לפנות למסעדה",
    },
    "_GIFTCARD": {
        "title": "יש לך כרטיס נטען או כרטיס מתנה למימוש?",
        "gift_card": "כרטיס מתנה",
        "select_card": "בחרו כרטיס",
        "please_select_card": "אנא בחרו כרטיס",
        "edit_card": "עדכון כרטיס",
        "add_card": "אישור",
        "number": "מספר כרטיס",
        "amount": "סכום לתשלום",
        "balance": "יתרה בכרטיס",
        "max": "מקסימום למימוש בהזמנה זו",
        "cvv": "CVV",
        "cvv_required": "חובה למלא CVV",
        "invalid_cvv": "מספר ה CVV שהוזן שגוי",
        "inuse_error": "לא ניתן לממש את אותו הכרטיס יותר מפעם אחת בהזמנה",
        "balance_error": "לא קיימת יתרה בכרטיס",
        "tip_not_allowed": "לא ניתן לשלם טיפ עם כרטיס זה",
        "notfound_error": "פרטי הכרטיס שגויים או שהכרטיס אינו קיים במערכת",
        "delete_prompt": "להסיר את הכרטיס מההזמנה?",
        "no_order_amount": "התשלומים כבר מכסים את ההזמנה, אין צורך בתשלום נוסף 😎",
        "reset_split_payments_prompt": "הוספה או עדכון הכרטיס יאתחל את פיצול התשלומים! להמשיך?"
    },
    "_DELAYED": {
        "title": "תזמון הזמנה",
        "service_caption": "ליום אחר",
        "supply_time": "מועד אספקה",
        "select_date": "נא לבחור תאריך אספקה",
        "select_slot": "נא לבחור שעת אספקה",
        "service_today": "הזמנה להיום",
        "service_future": "הזמנה עתידית",
        "for_hour": "לשעה {{val}}",
        "for_date": "יום {{val}}",
        "between": "בין השעות",
        "at_hour": "בשעה",
        "select_slot_prompt": "נא לבחור שעת אספקה",
        "service_disabled_today": "השרות אינו פעיל היום!"
    },
    "_OFFER_HISTORY": {
        "title": "איך להכין את המנה?",
        "description": "בעבר בחרת",
        "continue": "אבחרו שינויים מחדש",
        "select": "בחירת מנה"
    },
    "_EXTERNAL_DELIVERY": {
        "est_title": "Delivery Details",
        "est_apply": "Continue",
        "est_cancel": "Cancel",
        "delivery_time": "Estimated delivery time",
        "delivery_price": "Delivery price",
        "estimate_fail_message": "Delivery estimate failed",
        "validation_fail_message": "Delivery validation failed",
        "OUT_OF_DELIVERY_SERVICE_TIME": "Delivery is unavailable at this time",
        "ADDRESS_OUTSIDE_OF_DELIVERY_AREA": "We are sorry, delivery is not available for your address: {{address}}",
        "INVALID_PHONE": "Phone Number is invalid, please provide a valid phone number",
        "PHONE_NO_PSTN": "Phone Number is invalid, please provide a valid phone number",
        "ALCOHOL_NOT_DELIVERABLE": "Restaurant does not allow alcohol delivery. Remove alcohol from cart before proceeding to payment",
        "UNKNOWN_ERROR": "An error occured, please contact the restaurant"
    },
    "_EXTRA_INFO": {
        "delivery_ETA": "זמן הגעה עד",
        "takeaway_ETA": "זמן הכנה עד",
        "TAKEAWAY_FROM": "מאיפה אוספים?",
        "min_order_price": "מינימום למשלוח",
        "free_delivery_from": "משלוח חינם החל מ-",
        "delivery_fee": "דמי משלוח",
        "up_to": "כ",
        "asap_takeaway_ETA": "זמן הכנה",
        "asap_delivery_ETA": "זמן הגעה",
        "future_delivery_ETA": "תזמון הזמנה",
        "delayed_delivery_ETA": "מועד משוער",
    },
    "SIGN_IN_REGISTER": "התחברות/הרשמה",
    "SIGN_IN_REGISTER_TABIT": "התחברות ל-Tabit",
    "SIGN_UP_TABIT": "הרשמה ל-Tabit",
    "BASKET": {
        "INTERIM_AMOUNT": "סכום ביניים",
        "TOTAL_ITEMS": "סכום פריטים",
        "FREE_DELIVERY_FROM_PREFIX": "המשלוח חינם מעל",
        "FREE_DELIVERY_FROM_SUFFIX": "!",
        "MINIMUM_ORDER": "מינימום להזמנה {{price}}",
        "TO_FREE_DELIVERY": "עוד {{amount}}",
        "FREE_DELIVERY": "המשלוח בחינם!",
        "CONGRATULATIONS": "ברכות, ",
        "DELIVERY_ON_US": "המשלוח עלינו!",
        "TAKEAWAY_FROM": "איסוף מ",
        "DELIVERY_TO": "משלוח ל",
        "EDIT_ORDER": "עדכון הזמנה",
        "DOES_NOT_INCLUDE_DELIVERY_FEE": "(לא כולל דמי משלוח)",
        "DOES_NOT_INCLUDE_DELIVERY_FEE_AND_LOYALTY_ITEM": "(לא כולל דמי משלוח ו{{item}})"
    },
    "BOTTOM_SHEET": {
        "pay_with_point": "תשלום בנקודות",
        "regular_payment": "תשלום רגיל",
    },
    "MENU_MESSAGES": "הודעות",
    "YOUR_DELIVERY_ORDER_TO": "הזמנתך ל",
    "YOUR_TA_ORDER_FROM": "הזמנתך מ",
    "AMOUNT_LEFT": "נותרו {{amount}}",
    "ORDER_MINIMUM": "למינימום הזמנה",
    "REMINDER_AMOUNT": "נותרו {{amount}} לתשלום",
    "MY_ORDER": "ההזמנה שלי",
    "ORDER_SENT_SUCCESSFULLY": "הזמנתך נשלחה בהצלחה!",
    "ORDER_SENT_INFO_TA": "הזמנת איסוף עצמי מספר {{number}}, מ{{site}} ({{address}})",
    "ORDER_SENT_INFO_DELIVERY": "הזמנת משלוח מספר {{number}}, לכתובת {{address}}",
    "DYNAMIC_ORDER_SUCCESS_MESSAGE": "הזמנת {{service}} מספר {{number}} התקבלה בהצלחה!",
    "DYNAMIC_ORDER_FAIL_MESSAGE": "הזמנת {{service}} מספר {{number}} נשלחה למסעדה עם שגיאת תשלום",
    "EATIN_SUCCESS_MESSAGE": "הזמנה מספר {{number}} נשלחה בהצלחה!",
    "ORDER_WILL_BE_READY_AT": "תהיה מוכנה עד {{time}}.",
    "FUTURE_ORDER_WILL_BE_READY_AT": "תהיה מוכנה עד {{time}}",
    "FUTURE_ORDER_WILL_BE_READY": "תוזמנה -{{time}}.",
    "ORDER_WILL_BE_DELIVERED_AT": "תגיע עד {{time}}.",
    "FUTURE_ORDER_WILL_BE_DELIVERED_AT": "תהיה מוכנה עד {{time}}",
    "ASAP_FUTURE_ORDER_WILL_BE_DELIVERED_AT": "תוזמנה -{{time}}.",
    "ENJOY_YOUR_MEAL": "בתאבון!",
    "SLIDE_TO_PAY": "החליקו לתשלום",
    "JUST_PAY": "אישור תשלום",
    "NATIVE_PAYMENT": {
        "error_dialog_title": "לא ניתן לעבד את בקשתך כרגע.",
        "error_dialog_description": "בחר אמצעי תשלום אחר",
        "error_dialog_no_amount_description": "כדי לשלם עם {{type}} Pay יש ללחוץ על ה-X בשורת התשלום ולנסות שוב",
    },
};
