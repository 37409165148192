import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { StorageService } from './storage.service';

interface Language {
    value: string;
    viewValue: string;
    shortValue: string;
    direction: 'rtl' | 'ltr';
    activeModules?: ('tabitOrder' | 'tabit' | 'rsv' | 'giftCard')[];
}

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    public languages: Language[] = [];

    public lastUsedLangForMainApp: string;

    public usedLangs = ['he-IL', 'en-US', 'en-AU', 'ar-IL', 'ru-RU'];

    constructor(
        public appService: AppService,
        private translateService: TranslateService,
        private storageService: StorageService,
    ) {
        this.setForeignLang();
        this.lastUsedLangForMainApp = this.storageService.getItem('lastUsedMainAppLang');
    }

    public changeUsedLanguage(lang?: string, isMainApp = false) {
        // If no language is provided, use the last used language or the app's default locale
        if (!lang) {
            if (!this.lastUsedLangForMainApp) {
                this.setLastUsedLangForMainApp(this.appService.appConfig.locale);
            }
            lang = this.appService.appConfig.locale;
        }

        // If the requested language is not supported, exit the function
        if (!this.usedLangs.includes(lang)) return;

        // Save the last used language
        if(!isMainApp) this.storageService.setItem('lastUsedLang', lang);
        if (!this.lastUsedLangForMainApp) {
            this.setLastUsedLangForMainApp(lang);
        }

        // Update the current language in the application
        this.appService.locale.next(lang);
        this.translateService.use(lang);
        this.appService.updateAccessibilityMenuLanguage();

        // Update the translations based on the selected language
        if (this.appService?.domainStrings?.[lang]) {
            this.appService.updateTranslations(this.appService.domainStrings[lang]);
        }

        // Determine the text direction based on the selected language
        let direction = this.languages.find(language => language.value === lang)?.direction || '';
        if (!direction) direction = this.languages.find(language => language.value === this.appService.localeId)?.direction || 'ltr';
        this.appService.setDirection(direction);

        // Determine if the current language is the app's original language
        this.appService.isOriginLanguage = (this.appService.appConfig?.locale == this.appService.localeId);

        // Update the HTML 'lang' attribute according to the current language
        document.documentElement.setAttribute('lang', (this.languages.find(language => language.value === lang)?.shortValue || 'en'));
    }

    public setInitialLang() {
        const lastUsedLang = this.getLastUsedLang();
        this.changeUsedLanguage(lastUsedLang);
        return lastUsedLang;
    }

    public setAvailTranslations(availTranslations, defaultTranslation?) {
        this.setForeignLang();
        this.languages = this.languages.filter(lang => availTranslations.includes(lang.value) || lang.value === this.appService.appConfig.locale);
        const lastUsedLang = this.storageService.getItem('lastUsedLang');
        if (lastUsedLang) {
            if (availTranslations && availTranslations.includes(lastUsedLang)) {
                const usedLangs = ['he-IL', 'en-US', 'ar-IL', 'ru-RU'];
                if (usedLangs.includes(lastUsedLang)) {
                    this.changeUsedLanguage(lastUsedLang);
                    return lastUsedLang;
                }
            }
            if (defaultTranslation) return this.changeUsedLanguage(defaultTranslation);
            this.changeUsedLanguage(this.appService.appConfig.locale);
        }
    }

    public setToggledLang(activeModules) {
        const lastUsedLang = this.storageService.getItem('lastUsedLang');
        const filteredLanguages = this.getFilteredLanguages(activeModules, false);
        const toggledLang = filteredLanguages.filter(language => language.value !== lastUsedLang);

        const newLang = toggledLang.length ? toggledLang[0].value : this.appService.appConfig.locale;
        this.changeUsedLanguage(newLang);
    }

    public hasLanguageChanged() {
        const lastUsedLang = this.storageService.getItem('lastUsedLang');
        return lastUsedLang !== this.appService.appConfig.locale;
    }

    public setToDefaultLang() {
        this.storageService.setItem('lastUsedLang', this.appService.appConfig.locale);
    }

    public getLastUsedLang(): string {
        const lastUsedLang = this.storageService.getItem('lastUsedLang');
        if (this.usedLangs.includes(lastUsedLang)) {
            return lastUsedLang;
        }

        return this.appService.appConfig.locale;
    }

    public setLastUsedLangForMainApp(lang) {
        this.storageService.setItem('lastUsedMainAppLang', lang);
        this.lastUsedLangForMainApp = lang;
    }

    public reloadLang() {
        this.translateService.reloadLang(this.appService.localeId).subscribe();
    }

    public setForeignLang() {
        const DEFAULT_LOCALE = 'en-US';
        const AUSTRALIAN_LOCALE = 'en-AU';
        const { locale } = this.appService.appConfig;

        this.languages = [
            {value: 'he-IL', viewValue: 'עברית', shortValue: 'he', direction: 'rtl', activeModules: ['tabitOrder', 'tabit', 'rsv', 'giftCard']},
            {value: 'ar-IL', viewValue: 'عربى', shortValue: 'ar', direction: 'rtl', activeModules: ['tabitOrder', 'giftCard']},
            {value: 'ru-RU', viewValue: 'Pусский', shortValue: 'ru', direction: 'ltr', activeModules: ['tabitOrder', 'giftCard']},
        ];

        const foreignLanguage: { value: string, viewValue: string, shortValue: string, direction: 'rtl' | 'ltr', activeModules: any } = {
            value: locale === AUSTRALIAN_LOCALE ? locale : DEFAULT_LOCALE,
            viewValue: 'English',
            shortValue: 'en',
            direction: 'ltr',
            activeModules: ['tabitOrder', 'tabit', 'rsv', 'giftCard']
        };
        this.languages.push(foreignLanguage);
    }

    getFilteredLanguages(module, reconfigureLangs = false) {
        if (reconfigureLangs) this.setForeignLang();
        return this.languages.filter(language => {
            return !!language.activeModules.find(option => option === module);
        });
    }
}
