import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { MatIcon } from '@angular/material/icon';
import { Dir } from '@angular/cdk/bidi';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    standalone: true,
    imports: [NgIf, Dir, NgFor, MatIcon, AsyncPipe]
})
export class RatingComponent implements OnInit {

    @Input() site;
    @Input() oneStar: boolean = false;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
    }

}
