import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country } from '../model/country.model';

@Component({
    selector: 'app-intl-tel-country-dialog',
    templateUrl: './intl-tel-country-dialog.component.html',
    styleUrls: ['./intl-tel-country-dialog.component.scss']
})
export class IntlTelCountryDialogComponent implements OnInit {

    preferredCountriesInDropDown: Array<Country> = [];
    allCountries: Array<Country> = [];
    searchPlaceholder: string;
    enableSearch = false;
    searchCriteria: string;

    constructor(
        public dialogRef: MatDialogRef<IntlTelCountryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void { }

    onCountrySelect(country: Country): void {
        this.dialogRef.close(country);
    }
    cancel() {
        this.dialogRef.close();
    }

}
