import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { OccasionsService } from '../../_core/occasions.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { TagsService } from '../../_core/tags.service';

import moment from 'moment';

@Component({
  selector: 'events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit, OnDestroy {

    private eventsSubscription: Subscription;
    private scrollSubscription: Subscription;
    private searchQuerySubscription: Subscription;
    private tagsSubscription: Subscription;

    public siteEvents: any = [];
    public filteredEvents: any = [];

    public scrollDirection: string = 'up';
    public searchQuery: string = '';

    public loading: boolean = true;
    public scrollIsReachingTop: boolean = false;
    public scrollIsReachingBottom: boolean = false;
    public showScrollUp: boolean = false;
    public noMoreResults: boolean = false;

    public scrollSubject: Subject<void> = new Subject()
    public searchQuerySubject: Subject<string> = new Subject();

    public selectedDateId: any = 5;
    public selectedTagId: any = 0;
    public occasionTags: any;
    private selectedDateFrom: any;
    private selectedDateTo: any;
    
    public tagsFilterValues: any[];

    public dateFilterValues: any = [
        { id: 1, value: 'events_for_today'},
        { id: 2, value: 'tomorrow'},
        { id: 3, value: 'during_the_week'},
        { id: 4, value: 'next_week'},
        { id: 5, value: 'upcoming_month'},
    ];

    @Output() scrollReachedBottom = new EventEmitter<void>();

    constructor(
        public appService: AppService,
        public occasionsService: OccasionsService,
        public organizationsService: OrganizationsService,
        private tagsService: TagsService,
    ) { }

    ngOnInit() {
        this.tagsSubscription = this.tagsService.tagsData$.subscribe(tags => {
            this.tagsFilterValues = tags?.filter(tag => tag.type === 'occasions');
        });
        this.getOccasions();
        this.filterInput(this.selectedDateId, 'date');
        this.searchQuerySubscription = this.searchQuerySubject.pipe(debounceTime(400)).subscribe(() => {
            if (this.searchQuery == '') {
                this.getOccasions();
            } else this.search();
        });
        this.scrollSubscription = this.scrollSubject.pipe(debounceTime(500)).subscribe(() => this.scrollReachedBottom.emit());
    }

    ngOnDestroy() {
        this.searchQuerySubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.scrollSubscription.unsubscribe();
        this.tagsSubscription.unsubscribe();
    }

    handleScroll(event) {
        this.organizationsService.searchScreenScrollPosition = event.scrollTop;
        this.scrollIsReachingTop = event.isReachingTop;
        this.scrollIsReachingBottom = event.isReachingBottom;
        let direction = event.isReachingTop || event.direction == 'up' ? 'up' : 'down';
        if (direction != this.scrollDirection) this.scrollDirection = direction;

        if (event.isReachingBottom && event.direction === 'down') {
            this.scrollSubject.next();
        }

        this.showScrollUp = event.direction === 'up' && event.isReachingTop ? false : true;
    }

    scrollToTop(domId: string) {
        this.appService.scrollToTop(domId);
    };

    search() {
        this.filteredEvents = this.siteEvents.filter(event =>
            event.occasion_details.title.includes(this.searchQuery) || event.organization_name.includes(this.searchQuery)
        );
        if (this.selectedTagId) {
            this.filteredEvents = this.filteredEvents.filter(event => {
                if (event.occasion_details.publish_occasion.tags) {
                    return event.occasion_details.publish_occasion.tags.includes(this.selectedTagId);
                }
            });
        };

        this.noMoreResults = !this.filteredEvents.length ? true: false;
        this.loading = false;
    };

    clearSearch() {
        this.searchQuery = '';
        if (this.selectedDateId) {
            this.filterInput(this.selectedDateId, 'date')
        } else {
            this.getOccasions();
        }
    }

    getOccasions(timeFrom?: any, timeTo?: any) {
        if (this.eventsSubscription) this.eventsSubscription.unsubscribe();
        if (!timeFrom || !timeTo) { //Only when we choose events only for today
            timeFrom = moment().startOf('day');
            timeTo = moment().endOf('day');
        }
        this.loading = true;
        this.siteEvents = [];
        this.eventsSubscription = this.occasionsService.getOccasions(timeFrom, timeTo).subscribe(occasions => {
            this.siteEvents = occasions;
            this.filteredEvents = occasions;
            this.search();
        });
    }

    filterInput(value: any, type) {
        if (type == 'date') {
            switch (value) {
                // Today
                case 1: {
                    this.filteredSearch();

                    break;
                }
                // Tomorrow
                case 2: {
                    this.selectedDateFrom = moment().startOf('day').add(1, 'days');
                    this.selectedDateTo = moment().endOf('day').add(1, 'days');
                    this.filteredSearch(this.selectedDateFrom, this.selectedDateTo);

                    break;
                }
                // During the week
                case 3: {
                    this.selectedDateFrom = moment().startOf('day');
                    this.selectedDateTo = moment().endOf('week');
                    this.filteredSearch(this.selectedDateFrom, this.selectedDateTo);

                    break;
                }
                // Next week
                case 4: {
                    this.selectedDateFrom = moment().startOf('week').add(1, 'week');
                    this.selectedDateTo = moment().startOf('week').add(2, 'week').subtract(1, 'second');
                    this.filteredSearch(this.selectedDateFrom, this.selectedDateTo);

                    break;
                }
                // Upcoming month
                case 5: {
                    this.selectedDateFrom = moment().startOf('day');
                    this.selectedDateTo = moment().add(30, 'days');
                    this.filteredSearch(this.selectedDateFrom, this.selectedDateTo);

                    break;
                }
            }
        }
        if (type == 'tag') {
            this.search();
        }
    }

    filteredSearch(timeFrom?, timeTo?) {
        if (!timeFrom || !timeTo) { //Only for the init or when we choose events only for today
            this.getOccasions();
        } else {
            this.getOccasions(timeFrom, timeTo);
        }
    }
}
