import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isEmpty, filter, includes, remove, find, uniqBy, flatMap, sortBy, cloneDeep } from 'lodash-es';

import { AppService } from '../app.service';
import { MarketplaceService } from '../_core/marketplace.service';
import { OrganizationsService } from '../_core/organizations.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MarketplaceCardComponent } from '../components/marketplace-card/marketplace-card.component';
import { SelectedTagComponent } from './selected-tag/selected-tag.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgxDetectScrollDirective } from '../_core/directives';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton, MatIconButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss'],
    standalone: true,
    imports: [NgIf, MatFabButton, MatIcon, NgxDetectScrollDirective, MatIconButton, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, MatCheckbox, SelectedTagComponent, FormsModule, MarketplaceCardComponent, MatProgressSpinner, TranslateModule]
})
export class MarketplaceComponent implements OnInit {
    @Output() scrollReachedBottom = new EventEmitter<void>();
    filteredTags = [];

    isTitleSticky = false;
    currentStickyTagId : string;
    lastScrollDirection: string = 'down';
    orgTagsTitleHeights: any;

    public scrollDirection: string = 'up';
    public searchQuery: string = '';

    public loading: boolean = true;
    public scrollIsReachingTop: boolean = false;
    public scrollIsReachingBottom: boolean = false;
    public showScrollUp: boolean = false;
    public noMoreResults: boolean = false;
    public showMoreText: boolean = false;

    public scrollSubject: Subject<void> = new Subject()
    public searchQuerySubject: Subject<string> = new Subject();

    public filteredOrgs = [];
    public matchingOrgs = [];
    public tags = [];
    private sortedOrgs = [];
    private allOrgs = []; // orgs before sorting them by tags

    private scrollSubscription: Subscription;
    private searchQuerySubscription: Subscription;
    private marketplaceSubscription: Subscription;

    constructor(
        public appService: AppService,
        public marketplaceService: MarketplaceService,
        public organizationsService: OrganizationsService,
    ) { }

    ngOnInit() {
        this.marketplaceSubscription = this.marketplaceService.getMarketplaceOrganizations()
            .subscribe(orgs => this.setDataForMarketplace(orgs));

        this.searchQuerySubscription = this.searchQuerySubject.pipe(debounceTime(400)).subscribe(() => this.search());
        this.scrollSubscription = this.scrollSubject.pipe(debounceTime(500)).subscribe(() => this.scrollReachedBottom.emit());
    }

    ngOnDestroy() {
        this.searchQuerySubscription.unsubscribe();
        this.scrollSubscription.unsubscribe();
        this.marketplaceSubscription.unsubscribe();
    }

    public handleScroll(event) {
        this.setFilterAndTagTitleSticky(event);
        this.organizationsService.searchScreenScrollPosition = event.scrollTop;
        this.scrollIsReachingTop = event.isReachingTop;
        this.scrollIsReachingBottom = event.isReachingBottom;
        let direction = event.isReachingTop || event.direction == 'up' ? 'up' : 'down';
        if (direction != this.scrollDirection) this.scrollDirection = direction;

        if (event.isReachingBottom && event.direction === 'down') {
            this.scrollSubject.next();
        }

        this.showScrollUp = event.direction === 'up' && event.isReachingTop ? false : true;

    }

    setFilterAndTagTitleSticky(event) {
        this.currentStickyTagId = '';
        const scrollPosition = event.scrollTop || 0;
        // Adjust the value (e.g., 100) as needed to control when the header becomes sticky.
        const topScreenOffset = this.appService.isDesktop() ? 170 : this.appService.isApp ? this.appService.platformService.ANDROID ? 10 : 50 : 100;
        this.isTitleSticky = event.scrollTop < 10 ? false : scrollPosition >= topScreenOffset;

        let sectionHeights = this.filteredOrgs.map((org, index) => {
            const element = document.getElementById(`marketplace_title_${org.tagId}`);
            return element ? this.appService.isDesktop() ? element.offsetTop - 125 : this.appService.isApp ? this.appService.platformService.ANDROID ? element.offsetTop - 180 : element.offsetTop - 220 : element.offsetTop - 120 : 0;
        });

        if(isEmpty(this.matchingOrgs)) {
            if (!this.orgTagsTitleHeights?.length) this.orgTagsTitleHeights = cloneDeep(sectionHeights);
            if (event.direction == 'up') {
                sectionHeights = this.orgTagsTitleHeights;
            }
    
            for (let i = sectionHeights.length - 1; i >= 0; i--) {
                if (scrollPosition >= sectionHeights[i]) {
                    this.currentStickyTagId = this.filteredOrgs?.[i]?.tagId;
                    this.lastScrollDirection = event.direction;
                    break;
                }
            }
        }
    }

    public scrollToTop(domId: string) {
        this.appService.scrollToTop(domId);
    };

    public filterByTag(tagsArray?: any): void {
        this.orgTagsTitleHeights = [];
        if (!tagsArray) {
            tagsArray = this.filteredTags;
        }

        if (!tagsArray.length) {
            this.filteredOrgs = this.sortedOrgs;
        } else {
            this.filteredOrgs = this.sortedOrgs.filter(org =>
                tagsArray.some(tag => org.tagId === tag._id)
            );
        }
        if (this.searchQuery) {
            this.search();
        }
    }

    onCheckboxChange(event: any, tag: string): void {
        if (event.checked) {
            this.filteredTags.push(tag);
        } else {
            const index = this.filteredTags.indexOf(tag);
            if (index > -1) {
                this.filteredTags.splice(index, 1);
            }
        }
        this.filterByTag();
    }

    onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            const checkboxElement = (event.target as HTMLElement).querySelector('mat-checkbox input') as HTMLInputElement;
            if (checkboxElement) {
                checkboxElement.click();
            }
        }
    }

    isChecked(tag: string): boolean {
        return this.filteredTags.includes(tag);
    }

    public clearSearch(): void {
        this.searchQuery = '';
        this.filteredOrgs = this.sortedOrgs;
        this.matchingOrgs = [];
        this.noMoreResults = !this.filteredOrgs?.length;
        if (this.filteredTags?.length) {
            this.filterByTag();
        }
    }

    public getTagLabel(tag) {
        return this.marketplaceService.getTagLabel(tag);
    }

    public getTagById(tagId) {
        const tag = find(this.tags, { _id: tagId });
        return this.marketplaceService.getTagLabel(tag);
    }

    public removeTag(tag: any): void {
        if (tag && this.filteredTags?.length) {
            remove(this.filteredTags, { _id: tag._id });
            this.filterByTag();
        }

        if (this.searchQuery) {
            this.search();
        }
    }

    private setDataForMarketplace(orgs) {
        this.allOrgs = orgs;
        this.tags = sortBy(uniqBy(flatMap(orgs, 'tags'), '_id'), 'index');

        this.sortedOrgs = this.marketplaceService.sortAndOrderOrganizationsByTags(orgs, this.tags);
        this.filteredOrgs = this.sortedOrgs;
        this.loading = false;
    }

    private search(): void {
        this.searchQuery = this.searchQuery.trim().toLowerCase();
    
        if (!this.searchQuery) {
            this.clearSearch();
        } else {
            if (this.filteredTags?.length && this.filteredOrgs?.length) {
                this.matchingOrgs = this.filteredOrgs.reduce((acc, item) => {
                    const matchingOrgsForItem = filter(item.organizations, org => {
                        return filterByQuery(org, this.searchQuery)
                    });
                    return acc.concat(matchingOrgsForItem);
                }, []);
            } else {
                this.matchingOrgs = filter(this.allOrgs, org => {
                    return filterByQuery(org, this.searchQuery)
                });
            }

            this.matchingOrgs = uniqBy(this.matchingOrgs, 'RestaurantName'); // Remove duplicates
            this.noMoreResults = this.matchingOrgs.length === 0;
            this.loading = false;
        }

        function filterByQuery(org, query) {
            return (
                includes(org.RestaurantName?.toLowerCase(), query) ||
                includes(org.BusinessAddress?.toLowerCase(), query) ||
                org.aliases.some(alias => {
                    return includes(alias?.toLowerCase(), query)
                })
            )
        }
    }

}