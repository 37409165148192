import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppService } from '../app.service';
import { LocationService } from './location.service';
import { environment } from '../../environments/environment';

import { assignIn, cloneDeep, each, merge, find } from 'lodash-es';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class EntityUtilsService {

    appConfig: any = environment.appConfig;

	constructor(
        public ngZone: NgZone,
        public dialog: MatDialog,
		public appService: AppService,
		public locationService: LocationService,
	) { }

	public prepareUser(_user) {
        // 2020-02-23: [ ! ] Important Note
        // The prepareUser function used to override and remove the loyaltyCustomer object.
        // So now, the utilsService.prepareUser checks if the input has loyaltyCustomer, and if not - we maintain the original appService.user.loyaltyCustomer.

        let user:any = assignIn({
			wallet: null,
			rankScore: 200,
            avatar: this.appService.base('assets/images/avatar_icon.svg'),
            loyaltyCustomer: this.appService.user ? this.appService.user.loyaltyCustomer : null // Maintaining the original loyaltyCustomer if the _user doesn't have a am updated loyaltyCustomer data.
		}, _user);
		this.calculateUserRank(user);

        user.rankImage = this.appService.base('assets/images/ranks/default.png');

		return user;
	}

	public calculateUserRank(user) {
		let ranks = cloneDeep(this.appService.ranks);
		let wasRank = false;
		let userScore = user.rankScore || 0;
		each(ranks, rank => {
			if (rank.score < userScore) {
				rank.was = true;
			} else {
				rank.diff = rank.score - userScore;
				if (!wasRank) {
					wasRank = true;
					rank.is = true;
					user.rankName = rank.name;
					user.rankImage = rank.image;
				} else {
					rank.will = true;
				}
			}
		});
		user.ranks = ranks;
	}

    public prepareCategories(categories) {
        let map = {};
        each(categories, cat => {
            if (cat.icon && cat.icon.url) {
                cat.image = cat.icon.url;
            } else {
                cat.image = this.appService.images.default_cat_image;
            }
            map[cat._id] = cat;
        });
        this.appService.categories = categories;
        this.appService.categoriesMap = map;
        return categories;
    }

    public prepareAllergies(members) {
        each(members, cat => {
            cat.image = this.appService.images.default_allergy_image;
            if (cat.icon && cat.icon.url) {
                cat.image = cat.icon.url;
            }
        });
        this.appService.allergies = members;
        return members;
    }

    // The only client side organization validation for now:
    private validateSite(siteData: any): void {

        if (!siteData.seo || !siteData.seo[this.appService.appConfig.locale.toLocaleLowerCase()] || !siteData.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier) {
            throw new Error('Beware! a site did not pass validation, missing urlIdentifier');
        }
    }

	public prepareSite(site: any, fullSite = false, oldSite?: any) {

        let siteData = Object.assign({}, oldSite || {}, site);

        try {
            this.validateSite(siteData);
        } catch (err) {
            console.error(err, siteData);
            return null;
        }

        // Update basic properties:
        if (!siteData.nearby) siteData.nearby = oldSite?.nearby || false;
        if (oldSite?.googlePlaceDetails) siteData.googlePlaceDetails = merge({}, oldSite.googlePlaceDetails, site.googlePlaceDetails || {});
        if (oldSite?.servicesInDetails) siteData.servicesInDetails = merge({}, oldSite.servicesInDetails, site.servicesInDetails || {});

        if (site.image) {
            // 2019-08-25 - Temporary work-around for the Google API issue
            // 2020-04-02 - Nati fixes to that work-around (allow non google images, even if google images are blocked...)
            if (/googleapis/.test(site.image)) {
                if (this.appService.appConfig.blockGoogleImages) {
                    siteData.image = this.appService.images.default_site_image;
                } else {
                    siteData.image = site.image.replace(/key=(.+)$/, 'key='+this.appConfig.googleKey);
                    siteData.image = siteData.image.replace('maxheight=230', 'maxheight=64');
                }
            }
        } else {
            siteData.image = this.appService.images.default_site_image;
        }

        if (site.largeImage) {
            // 2019-08-25 - Temporary work-around for the Google API issue
            // 2020-04-05 - Nati fixes to that work-around (allow non google images, even if google images are blocked...)
            if (/googleapis/.test(site.largeImage)) {
                if (this.appService.appConfig.blockGoogleImages) {
                    siteData.largeImage = this.appService.images.default_site_image;
                } else {
                    siteData.largeImage = site.largeImage.replace(/key=(.+)$/, 'key=' + this.appConfig.googleKey);
                }
            }
        } else {
            siteData.largeImage = this.appService.images.default_site_image;
        }

        // 2020-02-09 - Deprecated due to high google costs
        // if (site.googlePlaceDetails && site.googlePlaceDetails.photos && site.googlePlaceDetails.photos.length > 5) {
        //     siteData.googlePlaceDetails.photos = site.googlePlaceDetails.photos.slice(0,5).map(image => ({
        //         ...image,
        //         url: (
        //             'https://maps.googleapis.com/maps/api/place/photo?maxheight=230&photoreference=' + image.photo_reference +
        //             '&key=' + this.appConfig.googleKey
        //         ),
        //     }));
        // }

        if (!siteData.tags) siteData.tags = [];

        // TEMP Solution for Map Boundaries Search - Since it doesn't return the distance field
        if (!siteData.distance && oldSite && oldSite.distance) siteData.distance = oldSite.distance;

        this.fixSiteVariables(siteData, fullSite);

		siteData.isFavorite = this.appService.account.favorites.indexOf(site._id) != -1;

		if (siteData.googlePlaceDetails) {
			// Display price level as currency signs
			if (siteData.googlePlaceDetails.price_level) siteData.googlePlaceDetails.price_label = new Array(siteData.googlePlaceDetails.price_level).fill(this.appService.currency).join('');
            // Format the Google Rating
            if (siteData.googlePlaceDetails.rating) {
                siteData.googlePlaceDetails.rating = parseFloat(siteData.googlePlaceDetails.rating).toFixed(1);
            }
            // Add star rating
			if (siteData.googlePlaceDetails.rating) {
                siteData.googlePlaceDetails.rating_stars = this.getRatingStars(siteData.googlePlaceDetails.rating);
            }
        }

        return siteData;
	}

	public getRatingStars(rating) {
		let starsCount = 5;
		let starsArray = new Array(rating > starsCount ? starsCount : Math.floor(rating));
		// Fill "full" stars
		starsArray.fill('star');
		// Add half star
		if (starsArray.length < starsCount && rating % 10) starsArray.push('star_half');
		// Add "empty" stars
		while (starsArray.length < starsCount) starsArray.push('star_border');

		return starsArray;
	};

    onlyExistingCategories (siteCategories) {
        return siteCategories.filter(siteCategory => this.appService.categoriesMap[siteCategory._id]);
    }

    fixSiteVariables(site, fixWorkHours = false) {
        if (site.timeSlots) {
            if (site.timeSlots.weekly) {
                if (fixWorkHours) {
                    let days = [];
                    let defaultDay = site.timeSlots.weekly.default;
                    defaultDay.active = true;
                    each(this.appService.daysMap, day => {
                        let dataDay = site.timeSlots.weekly[day];
                        if (dataDay.active && dataDay.default) dataDay = defaultDay;
                        days.push({
                            day: day,
                            active: dataDay.active,
                            timeSlots: dataDay.timeSlots
                        });
                    });
                    site.workHours = days;
                }
                let slot = this.appService.isTimeslotsActive(site.timeSlots.weekly);
				site.activeSlot = slot || {};
				if (!site.activeSlot.slotFound && site.tdHours && site.services.order) {
					site.tdSlot = this.isTimeslotsActive(site.tdHours);
				}
            } else {
                delete site.timeSlots;
            }
        }

		if (site.distance) {
			site.formattedDistance = this.locationService.formattedDistance(site.distance);
		}
    }

	isTimeslotsActive(timeslot) {
		var md = moment();
		var d = md.day();

		var dayM = 24 * 60;
		var startThreshhold = 5 * 60;
		var cmd = getMinutes(md);
		if (cmd < startThreshhold) {
			d -= 1;
			if (d < 0) d = 6;
			cmd += dayM;
		}

		let day: any = find(timeslot.days, { day: d });
		if (!day.active) return null;
		if (day.type == "allday") return {};
		if (day.type == "default") day = find(timeslot.days, { day: -1 });

		day.tslots = [];
		let slotFound;
		let mOffset = 120;
		for (var i = 0; i < day.slots.length; i++) {
			var slot = day.slots[i];

			slot.from = moment(slot.from);
			slot.to = moment(slot.to);

			slot.tfrom = slot.from.format("HH:mm");
			slot.tto = slot.to.format("HH:mm");

			slot.mfrom = getMinutes(slot.from) - mOffset;
			if (slot.from.date() == 2) slot.mfrom += dayM;
			slot.mto = getMinutes(slot.to);
			if (slot.to.date() == 2) slot.mto += dayM;

			day.tslots.push(slot.tfrom + " - " + slot.tto);

			if (cmd > slot.mfrom && cmd < slot.mto) {
				slotFound = slot;
				break;
			}
		}
		return slotFound;
		function getMinutes(m) {
			return (m.hours() * 60) + m.minutes();
		}
    };

    siteCanBook(site) {
        if (!site?.reservation?.method) return false;
        if (site?.reservation.method == 'phone') {
            if (site.external) {
                return false;
            } else {
                return true;
            }
        }
        if (['onlineBooking', 'url'].indexOf(site.reservation.method) < 0) return false;
        if (site?.reservation?.method === 'onlineBooking') {
            if (site.bookingData?.future_reservation?.enabled) {
                return true; // TODO: Also check with some TGM RSV config (Will always exists, after bridge)
            } else {
                return false;
            }
        }
        if (!site.reservation?.methodValue) return false;
        return true;
    }


    public getURLParams(_location?) {
        var urlParams: any = {};
        if (!_location) _location = location;
        var sSearch = _location.search.substr(1).split("&");
        each(sSearch, function (ss) {
            var _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

    public getCustomURLParams(_location?, customLocationSearch?) {
        const urlParams: any = {};
        if (!_location && !customLocationSearch) _location = location;
        let sSearch = (customLocationSearch || _location.search).substr(1);
        sSearch = sSearch.split("&");
        each(sSearch, function (ss) {
            const _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

}
