import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { each } from 'lodash-es';

import { AppService } from '../../../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tabitpay-expay-dialog',
    templateUrl: './tabitpay-expay-dialog.component.html',
    styleUrls: ['./tabitpay-expay-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class TabitpayExpayDialogComponent {

    trustedIframeUrl: SafeResourceUrl;

    constructor(
        private sanitizer: DomSanitizer,
        public appService: AppService,
        public dialogRef: MatDialogRef<TabitpayExpayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.redirectURL);
    }

    // listen for external iframe messages
    @HostListener('window:message', ['$event']) onMessage(event) {
        if (event.data?.iframeEvent == 'sameHostIframeLoaded') {
            try {
                // get everything after the ? in the url
                const locationSearch = event.data?.fullUrl.match(/\?(.*)/g)[0]
                this.onFrameLoaded(null, locationSearch);
            } catch (error) {};
        }
    }

    onFrameLoaded(ev, customUrl?) {
        try {
            const params = customUrl ? this.getCustomURLParams(null, customUrl) : this.getCustomURLParams(ev.currentTarget?.contentWindow?.location);
            if (params) {
                switch (this.data.provider) {
                    case "CreditGuard": ;
                    case "Meshulam":
                        switch (params.r) {
                            case "success":
                                this.dialogRef.close({ mode: "success" });
                                break;
                            case "error":
                                this.dialogRef.close({ mode: "error" });
                                break;
                            case "cancel":
                                this.dialogRef.close({ mode: "cancel" });
                                break;
                        }
                        break;
                    default:
                        this.dialogRef.close(params);
                }
            }
        } catch (e) {}
    }

    getCustomURLParams(_location?, customLocationSearch?) {
        const urlParams: any = {};
        if (!_location && !customLocationSearch) _location = location;
        let sSearch = (customLocationSearch || _location.search).substr(1);
        sSearch = sSearch.split("&");
        each(sSearch, function (ss) {
            const _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

}
