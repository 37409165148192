import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatRipple } from '@angular/material/core';

import { TabitpayService } from '../tabit-pay.service';
import { AppService } from '../../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { TabitpayTicketSeatedComponent } from '../tabitpay-ticket-seated/tabitpay-ticket-seated.component';

@Component({
    selector: 'tabitpay-ticket',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './tabitpay-ticket.component.html',
    styleUrls: ['./tabitpay-ticket.component.scss'],
    standalone: true,
    imports: [TabitpayTicketSeatedComponent, NgIf, MatButton, AsyncPipe, TranslateModule]
})
export class TabitpayTicketComponent implements OnInit, OnDestroy {
    @Input() options;
    @Output() onContinue = new EventEmitter<any>();

    @ViewChild('ticketRipple', { read: MatRipple, static: false })   ticketRipple: MatRipple;

    public $storage: any;

    private routeQueryParamsSubscription: Subscription;

    private showDialog: boolean = true;

    constructor(
        public tabitPayService: TabitpayService,
        public appService: AppService,
        private route: ActivatedRoute,
        private ngZone: NgZone,
    ) {
        this.$storage = this.tabitPayService.$storage;
    }

    ngOnInit() {
        this.routeQueryParamsSubscription = this.route.queryParams.subscribe(params => {
            if (params.tr == 'success' && this.showDialog) {
                this.showDialog = false;

                const dialogText = this.appService.translate("MESSAGES.PAYMENT_ACCEPTED");
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    return this.appService.mainMessage({
                        dialogType: 'success',
                        dialogTitle: 'THANK_YOU',
                        dialogText,
                        primaryButtonText: 'APPROVE',
                        hideSecondaryButton: true
                    }).catch(err => {});
                });
            }
        });
    }

	_continue(mode, ev?) {
		//this.launchRipple();
		this.onContinue.emit(mode);
	}

	// --------------------------------------------------------------------->

    launchRipple() {
        try {
            const rippleRef = this.ticketRipple.launch(10, 10, {
                persistent: true,
                color: 'rgba(41, 205, 255, 0.52)',
                animation: { enterDuration: 1000, exitDuration: 1500 }
            });
            rippleRef.fadeOut();
        } catch (e) {}
	}

    addItemsToOrder() {
        this.appService.redirect(['/tabit-order'], {
            queryParams: {
                site: this.$storage.organization,
                oid: this.$storage.orderId,
                service: this.$storage.site?.tabitpay?.addItems_addItemMenu || 'takeaway',
                step: 'menu'
            },
        });
    }

    get canAddItems() {
        return this.$storage.enableAddItems && !this.$storage.order.checks && !this.$storage.order.closed;
    }

    ngOnDestroy(): void {
        this.routeQueryParamsSubscription.unsubscribe();
    }


}
