import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { OrganizationsService } from '../../_core/organizations.service';
import { AppService } from '../../app.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { TagsService } from '../../_core/tags.service';
import { trigger } from '@angular/animations';

import { filter } from 'lodash-es';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { FavoriteFabComponent } from '../favorite-fab/favorite-fab.component';
import { NgIf, NgFor } from '@angular/common';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';

@Component({
    selector: 'site-card',
    templateUrl: './site-card.component.html',
    styleUrls: ['./site-card.component.scss'],
    animations: [
        trigger('siteCardAnimation', [
        /*
        transition(':enter', [
            // 2019-09-28 - Unfortunately due to iOS 13 WKWebView bugs - we cannot use the width animation here (as it causes the whole "strip" of cards scroll to go "wacko"...)
            //style({ opacity: 0, width: 0, transform: 'scale(0.7)' }),
            //animate('0.2s 0s ease-in', style({ width: '280px' })),
            style({ opacity: 0, transform: 'scale(0.7)' }),
            animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
            animate('0.2s 0s ease-in', style({ transform: 'scale(1)' }))
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)' }),
            animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
            animate('0.3s 0s ease-in', style({ opacity: 0, transform: 'scale(0.5)' })),
            animate('0.1s 0s ease-in', style({ width: 0 }))
        ])
        */
        ])
    ],
    standalone: true,
    imports: [AccessibleDirective, NgIf, NgFor, FavoriteFabComponent, TranslateModule, SafeStylePipe]
})
export class SiteCardComponent implements OnInit, OnDestroy {

    private _siteId: string;
    public site: any;
    private locationSubscription: Subscription;
    public showDistance: boolean = false;

    public unit: string = this.appService.getUnitByLocale();

    @Input() index: number;

    @Input()
    set siteId(siteId: string) {
        this._siteId = siteId;
        this.site = this.organizationsService.getOrganization(siteId);
    }
    get siteId(): string { return this._siteId }

    constructor(
        public appService: AppService,
        public locationService: LocationService,
        private organizationsService: OrganizationsService,
        private tagsService: TagsService,
    ) { }

    ngOnInit() {
        // Layout Config
        // 20-06-2021 - Decided to remove the support of leumiPay in Tabit Israel
        // this.showPepperPay = get(this.appService, `appConfig.layout.pepperPay.${this.appService.isApp ? 'app' : 'web'}_enabled`);;

        this.locationSubscription = combineLatest([
            this.locationService.actualLocationAvailable,
            this.locationService.location,
        ]).subscribe(([actualLocationAvailable, location]: [boolean, LocationLabeled]) => {
            this.showDistance = actualLocationAvailable && location.actual;
        });
    }

    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
    }

    getSiteTags() {
        if (!this.site || !this.site.tags || !this.site.tags.length) return [];
        return filter(this.site.tags.map((tagId: string) => this.tagsService.getTagName(tagId)));
    }

}
