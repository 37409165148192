import { Component, Inject, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
//import { SignaturePad } from 'angular2-signaturepad';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { LottieComponent } from 'ngx-lottie';
import { IdCardValidator } from '../../components/validators';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-message-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, MatIconButton, MatDialogClose, MatIcon, NgSwitch, NgSwitchCase, MatDialogContent, MatFormField, MatLabel, MatInput, IdCardValidator, MatError, NgSwitchDefault, LottieComponent, NgFor, MarkdownComponent, MatDialogActions, MatButton, TranslateModule]
})
export class MessageDialogComponent implements OnInit {
	mode: string;
    value: string;
    closeButtonActive: boolean = true;
    triedToSubmit: boolean = false;

    // @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;

    // public signaturePadOptions: Object = {
    //     'minWidth': 5,
    //     'canvasWidth': 300,
    //     'canvasHeight': 250
    // };

	constructor(
		public dialogRef: MatDialogRef<MessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.mode = this.data.mode || 'confirm';
        this.value = this.data.value;
        if (this.data.hasOwnProperty('closeButtonActive')) this.closeButtonActive = this.data.closeButtonActive;
    }

    ngOnInit() { }

    clear() {
        delete this.value;
        //this.signaturePad.clear();
    }

    // drawComplete() {
    //     this.value = this.signaturePad.toDataURL();
    // }

    confirmPrompt(form) {
        this.triedToSubmit = true;
        if (this.data.template == 'textInput' && !this.value?.length) return;
        if (form.valid) {
            this.dialogRef.close(this.value);
        }
    }

    get hasError() {
        if (this.data.template !== 'textInput') return false;
        return this.triedToSubmit && !this.value?.length;
    }
}
