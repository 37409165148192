import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../../app.service';
import { TOUtilsService } from './to.utils.service';
import { padStart } from 'lodash-es';
import * as moment from 'moment';

@Pipe({
    name: 'toMoney',
    standalone: true,
})
export class ToMoneyPipe implements PipeTransform {
    constructor(
        public appService: AppService,
        private utilsService: TOUtilsService
    ) { }

    transform(val: string | number, digitsInfo?: string) {
        return this.utilsService.getParsedMoney(val, digitsInfo);
    }
}

@Pipe({
    name: 'currencyInitials',
    standalone: true,
})
export class ToCurrencyInitialsPipe implements PipeTransform {
    constructor(
        private appService: AppService
    ) { }

    transform(val: string | number, minus?: boolean, onlyCurrency?: boolean) {
        let currencyInitials = this.appService.appConfig.locale === 'he-IL' ? '₪' : '$';
        if (onlyCurrency) return currencyInitials;
        if (minus && Number(val) > 0) {
            currencyInitials += ' -';
        }
        return val + ' ' + currencyInitials;
    }
}

@Pipe({
    name: 'orderDate',
    standalone: true
})
export class OrderDatePipe implements PipeTransform {
    constructor(private appService: AppService) { }
    transform(orderDate: Date): string {
        let localeId = this.appService.localeId;
        if (localeId) {
            localeId = localeId.substring(0, 2);
            moment.locale(localeId);
        }

        const OrderedTxt = this.appService.translate('HISTORY.ordered');
        const OrderedInTxt = this.appService.translate('HISTORY.ordered-in');
        const OrderedOnTxt = this.appService.translate('HISTORY.ordered-on');
        const weeksAgoTxt = this.appService.translate('HISTORY.weeks-ago');
        const pastWeekTxt = this.appService.translate('HISTORY.past-week');
        

        const now = moment();
        const orderMoment = moment(orderDate);
        const weeksDiff = now.diff(orderMoment, 'weeks');
        const monthsDiff = now.diff(orderMoment, 'months');

        if (weeksDiff < 4) {
            if (weeksDiff < 1) return `${pastWeekTxt}`;
            return `${OrderedTxt} ${weeksDiff} ${weeksAgoTxt}`;
        } else if (monthsDiff >= 1 && monthsDiff <= 6) {
            return `${OrderedInTxt}${orderMoment.format('MMMM')}`;
        } else {
            return `${OrderedInTxt}${orderMoment.format('DD MMM YYYY')}`;
        }
    }
};

@Pipe({
    name: 'toTel',
    standalone: true,
})
export class ToPhonePipe implements PipeTransform {
    constructor(
        private utilsService: TOUtilsService
    ) { }

    transform(val: string, digitsInfo?: string) {
        return val ? this.utilsService.formatPhoneNumber(val, true) : "";
    }
}

@Pipe({
    name: 'trimText',
    standalone: true
})
export class TrimTextPipe implements PipeTransform {
    constructor() { }

    transform(val: string) {
        return typeof val === 'string' ? val.trim() : val;
    }
}

@Pipe({
    name: 'toCard',
    standalone: true,
})
export class ToCardPipe {
    constructor(
        private appService: AppService
    ) { }

    transform(val: string) {
        if (val && val.length > 4) {
            this.appService.directionSubject.subscribe(direction => {
                let arr: any[] = val.match(new RegExp('.{1,4}$|.{1,4}', 'g'));
                if (direction === 'rtl') arr = arr.reverse();
                return arr.join(" ");
            })
        }
        return val;
    }
}

//------------------------------------------------------------------------->
//------------------------------------------------------------------------->
//------------------------------------------------------------------------->
//------------------------------------------------------------------------->
//------------------------------------------------------------------------->
//------------------------------------------------------------------------->


@Pipe({
    name: 'tmpTranslate',
    pure: false,
    standalone: true
})
export class TranslatePipe implements PipeTransform {
    constructor(private appService: AppService) { }
    transform(value: any, strOverrides?: any): any {
        let ret = this.appService.translate(value, null, strOverrides);
        return ret || value;
    }

}

@Pipe({
    name: 'toPaymentExp',
    standalone: true,
})
export class ToPaymentExpiration {
    constructor() { }

    transform(val: any) {
        const m = val.expMonth;
        const y = val.expYear;
        if (m && y) {
            return padStart(m, 2, '0') + '/' + y.toString().substr(2, 2);
        } else {
            return '';
        }
    }
}


@Pipe({
    name: 'filterBy',
    pure: false,
    standalone: true
})
// https://github.com/VadimDez/ngx-filter-pipe

export class FilterPipe implements PipeTransform {

    static isFoundOnWalking(value, key) {
        let walker = value;
        let found = false;
        if (!walker) return found;
        do {
            if (walker.hasOwnProperty(key) || Object.getOwnPropertyDescriptor(walker, key)) {
                found = true;
                break;
            }
        } while (walker = Object.getPrototypeOf(walker));

        return found;
    }

    static isNumber(value) {
        return !isNaN(parseInt(value, 10)) && isFinite(value);
    }

	/**
	 * Checks function's value if type is function otherwise same value
	 */
    static getValue(value: any): any {
        return typeof value === 'function' ? value() : value;
    }

    private filterByString(filter) {
        if (filter) {
            filter = filter.toLowerCase();
        }
        return value => !filter || (value ? ('' + value).toLowerCase().indexOf(filter) !== -1 : false);
    }

    private filterByBoolean(filter) {
        return value => Boolean(value) === filter;
    }

    private filterByObject(filter) {
        return value => {
            for (const key in filter) {

                // loose boolean comparison
                if (filter[key]?.looseTypeCheck === true && typeof filter[key]?.searchTerm == 'boolean') {
                    return filter[key]?.searchTerm ? value[key] : !value[key];
                }

                if (key === '$or') {
                    if (!this.filterByOr(filter.$or)(FilterPipe.getValue(value))) {
                        return false;
                    }
                    continue;
                }

                if (!FilterPipe.isFoundOnWalking(value, key)) {
                    return false;
                }

                if (!this.isMatching(filter[key], FilterPipe.getValue(value[key]))) {
                    return false;
                }
            }

            return true;
        };
    }

    private isMatching(filter, val) {
        switch (typeof filter) {
            case 'boolean':
                return this.filterByBoolean(filter)(val);
            case 'string':
                return this.filterByString(filter)(val);
            case 'object':
                return this.filterByObject(filter)(val);
        }
        return this.filterDefault(filter)(val);
    }

	/**
	 * Filter value by $or
	 */
    private filterByOr(filter: any[]): (value: any) => boolean {
        return (value: any) => {
            const length = filter.length;

            const arrayComparison = (i) => value.indexOf(filter[i]) !== -1;
            const otherComparison = (i) => value === filter[i];
            const comparison = Array.isArray(value) ? arrayComparison : otherComparison;

            for (let i = 0; i < length; i++) {
                if (comparison(i)) {
                    return true;
                }
            }

            return false;
        };
    }

	/**
	 * Default filterDefault function
	 */
    private filterDefault(filter: any): (value: any) => boolean {
        return (value: any) => filter === undefined || filter == value;
    }

    transform(array: any[], filter: any): any {
        if (!array) {
            return array;
        }

        switch (typeof filter) {
            case 'boolean':
                return array.filter(this.filterByBoolean(filter));
            case 'string':
                if (FilterPipe.isNumber(filter)) {
                    return array.filter(this.filterDefault(filter));
                }
                return array.filter(this.filterByString(filter));
            case 'object':
                return array.filter(this.filterByObject(filter));
            case 'function':
                return array.filter(filter);
        }
        return array.filter(this.filterDefault(filter));
    }
}
