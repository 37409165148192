import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { WebArticle } from './web-article';

import { get } from 'lodash-es';
import { WebFooterComponent } from '../web-footer/web-footer.component';
import { NgStyle, NgIf } from '@angular/common';


@Component({
    selector: 'app-web-article',
    templateUrl: './web-article.component.html',
    styleUrls: ['./web-article.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf, RouterLink, WebFooterComponent]
})
export class WebArticleComponent implements OnInit {

    constructor(
        public appService: AppService,
        private route: ActivatedRoute,
        public webArticle: WebArticle
    ) { }

    articleId: string;
    article: any = {};

    getArticleId = () => {
        this.articleId = get(this.route, 'snapshot.params.articleId', 'מסעדות-חורף');
        this.article = this.webArticle.articles[this.articleId] || this.webArticle.articles['מסעדות-חורף'];
    }

    externalRouting = (route) => {
        window.open(route);
    }

    ngOnInit() {
        this.getArticleId();

        this.route.params.subscribe((params = {}) => {
            this.getArticleId();
            document.querySelector('.web-article-container').scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
    }
}
