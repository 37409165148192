import { Component, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../_core/SiteListsBase';
import { AppService } from '../app.service';
import { LocationService } from '../_core/location.service';
import { EntityService } from '../_core/entity.service';
import { OrganizationsService } from '../_core/organizations.service';
import { TabitpayService } from '../order/tabit-pay/tabit-pay.service';

import { ORGANIZATION_BUCKET_TYPES, SearchDetails } from '../_core/OrganizationBucket';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SiteItemComponent } from '../components/site-item/site-item.component';
import { MatList } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';
import { SitesSearchToolsComponent } from '../components/sites-search-tools/sites-search-tools.component';

declare const $: any; // For the scroll we are using jQuery

@Component({
    selector: 'app-sites-search-pay',
    templateUrl: './sites-search-pay.component.html',
    styleUrls: ['./sites-search-pay.component.scss'],
    standalone: true,
    imports: [SitesSearchToolsComponent, NgIf, MatList, NgFor, SiteItemComponent, MatProgressSpinner, TranslateModule]
})
export class SitesSearchPayComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.tabitpay) implements OnInit {

    private mainLoadingSubscription: Subscription;
    private coreSubscription: Subscription;

    private sitesLoaded: boolean = false;

    constructor(
        protected appService: AppService,
        private locationService: LocationService,
        private entityService: EntityService,
        private orderService: TabitpayService,
        protected organizationsService: OrganizationsService,
        protected liveAnnouncer: LiveAnnouncer,
    ) { super(); }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) {
                this.entityService.subscribeToCoreData()
                .then(() => this.subscribeToOrgs());
            }
        });

        this.subscribeToOrgs();
    }

    subscribeToOrgs() {
        if (this.sitesLoaded) return;

        let paySearchType = ORGANIZATION_BUCKET_TYPES.tabitpay;

        this.initialize();

        this.mainLoadingSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location
        ]).subscribe(([locationInitialized, newLocation]) => {
            if (!locationInitialized || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails(null, 'tabitpay');

            if (this.orgs?.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, paySearchType)) {

                this.searching = false;
                this.sitesLoaded = true;

                this.manageScroll();

            } else {

                this.newSearch(nextSearchDetails, () => {
                    this.manageScroll();
                });

            }

        });
    }

    ngOnDestroy() {
        this.coreSubscription.unsubscribe();
        this.mainLoadingSubscription.unsubscribe();
        this.cleanUp();

    }

    clearSearchText() {
        super.clearSearchText('tabitpay');
    }

    searchChanged(newSearchDetails: SearchDetails) {
        // console.log(' === SITES/SEARCH/PAY === search changed to:', newSearchDetails);
        let searchDetails: SearchDetails = { ...newSearchDetails, skip: 0, service: 'tabitpay' };
        this.newSearch(searchDetails);
    }

    siteClick(site: any, ev: any) {
        this.organizationsService.searchScreenNeedsScroll = true;
       // Note:
       // At this point, the sites-search-pay means the user clicked "Manual Entry Code" and then started searching for a specific site.
       // So we must always redirect to the manual entry screen (even when in Cordova).
       // However, this might change in the future, and that's why the code above is left commented out.
        // this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: site._id } });
        console.debug('Tabit Pay > pay/search-sites > siteClick: ', site._id);
        this.orderService.redirect(site._id);
    }

}
