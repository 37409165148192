<div class="order-tracker-container wl-app-primary-background-color">
    <order-tracker-header *ngIf="secondaryStep != orderStepsEnum.ORDER_LOADED && secondaryStep != orderStepsEnum.ORDER_ERROR" [user]="user" [org]="org" [order]="order"></order-tracker-header>
    <mat-tab-group [class.hide-headers]="(orderTrackerService.displayMode | async) === 'summary' || secondaryStep === orderStepsEnum.ORDER_ERROR" [(selectedIndex)]="primaryStep" dynamicHeight mat-align-tabs="center" animationDuration="1000ms">
        <mat-tab label="{{'order_tracker.status' | translate}}">
            <header-tracker *ngIf="order" [order]="order"></header-tracker>
            <mat-tab-group class="wiz-tabs hide-headers" [disableRipple]="true" [(selectedIndex)]="secondaryStep">
                <!-- LANDING PAGE - LOADING ORDER -->
                <mat-tab label="Step 0">
                    <div class="order-tracker-section">
                        <div class="loading-order-spacer"></div>
                        <div class="animation-container">
                            <ng-lottie class="order-animation loading" [options]="{path: orderAnimationPaths['donut-loader'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <h3 class="bold">{{ 'order_tracker.order_is_being_loaded' | translate }}</h3>
                    </div>
                </mat-tab>

                <!-- DELIVERY/TA CONFIRMED -->
                <mat-tab label="Step 1">
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_accepted' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_1.gif')">
                            <ng-lottie class="order-animation got-order" [options]="{path: orderAnimationPaths['got-your-order'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                    </div>
                </mat-tab>

                <!-- DELIVERY/TA BEING PREPARED -->
                <mat-tab label="Step 2">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_being_prepared' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_2.gif')">
                            <ng-lottie class="order-animation preparing" [options]="{path: orderAnimationPaths['cooking-food'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <!-- Ask if add a [disabled]="buttonDisabled" property -->
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                        <div class="explanation" *ngIf="showImHereExplanation && service === 'takeaway' && configuration?.imHereButtonActive">
                            <span>{{ 'order_tracker.im_here_explanation' | translate }}</span>
                        </div>
                        <button *ngIf="service === 'takeaway' && configuration?.imHereButtonActive" mat-button class="primary-button" (click)="_redirectToNotesInput(2)">
                            {{ 'order_tracker.' + imHereCaption | translate }}
                        </button>
                    </div>
                </mat-tab>

                <!-- DELIVERY/TA IN PROGRESS -->
                <mat-tab label="Step 3">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_in_progress' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_3.gif')">
                            <ng-lottie class="order-animation in-progress" [options]="{path: orderAnimationPaths['chef-cooking'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                        <div class="explanation" *ngIf="showImHereExplanation && service === 'takeaway' && configuration?.imHereButtonActive">
                            <span>{{ 'order_tracker.im_here_explanation' | translate }}</span>
                        </div>
                        <button *ngIf="service === 'takeaway' && configuration?.imHereButtonActive" mat-button class="primary-button" (click)="_redirectToNotesInput(3)">
                            {{ 'order_tracker.' + imHereCaption | translate }}
                        </button>
                    </div>
                </mat-tab>

                <!-- TA READY -->
                <mat-tab label="Step 4">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_ready' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_5.gif')">
                            <ng-lottie class="order-animation ready" [options]="{path: orderAnimationPaths['food-prepared'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <!-- <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span> -->
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                        <div class="explanation" *ngIf="showImHereExplanation && service === 'takeaway' && configuration?.imHereButtonActive">
                            <span>{{ 'order_tracker.im_here_explanation' | translate }}</span>
                        </div>
                        <button *ngIf="service === 'takeaway' && configuration?.imHereButtonActive" mat-button class="primary-button" (click)="_redirectToNotesInput(4)">
                            {{ 'order_tracker.' + imHereCaption | translate }}
                        </button>
                    </div>
                </mat-tab>

                <!-- TA TAKEN -->
                <mat-tab label="Step 5">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_taken' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_6.gif')">
                            <ng-lottie class="order-animation taken" [options]="{path: orderAnimationPaths['food-takeaway-order'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <button [@buttonAnimation]="configuration?.reviewButtonActive && !orderReviewd" *ngIf="configuration?.reviewButtonActive && !orderReviewd" mat-button class="primary-button" (click)="_redirectToUserReview()">
                            {{'order_tracker.add_review' | translate}}
                        </button>
                    </div>
                </mat-tab>

                <!-- ALMOST READY FOR DELIVERY -->
                <mat-tab label="Step 6">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_ready_for_delivery' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_4.gif')">
                            <ng-lottie class="order-animation ready" [options]="{path: orderAnimationPaths['food-prepared'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                    </div>
                </mat-tab>

                <!-- ON IT'S WAY -->
                <mat-tab label="Step 7">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_taken_on_its_way' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_5.gif')">
                            <ng-lottie class="order-animation on-its-way" [options]="{path: orderAnimationPaths['delivery-guy'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <div class="explanation" *ngIf="order?.deliveryInfo?.ETA">
                            <span *ngIf="service === 'takeaway'">{{ 'order_tracker.ETA_takeaway' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                            <span *ngIf="service === 'delivery'">{{ 'order_tracker.ETA_delivery' | translate }} {{order.deliveryInfo.ETA | date: 'HH:mm' | AMPMConverter}}</span>
                        </div>
                    </div>
                </mat-tab>

                <!-- DELIVERED AND WANT TO REVIEW? -->
                <mat-tab label="Step 8">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <h1>{{ 'order_tracker.hey' | translate }} {{ user?.name }}!</h1>
                        <h3>{{ 'order_tracker.actual_status' | translate }} - {{ 'order_tracker.order_delivery_delivered' | translate }}</h3>
                        <div class="animation-container">
                            <img class="animation-border" [src]="appService.base('assets/images/tracker_6.gif')">
                            <ng-lottie class="order-animation" [options]="{path: orderAnimationPaths['order-delivered'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <button [@buttonAnimation]="configuration?.reviewButtonActive && !orderReviewd" *ngIf="configuration?.reviewButtonActive && !orderReviewd" mat-button class="primary-button" (click)="_redirectToUserReview()">
                            {{'order_tracker.add_review' | translate}}
                        </button>
                    </div>
                </mat-tab>

                <!-- I'M HERE-EXTRA NOTES -->
                <mat-tab label="Step 9">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div [ngClass]="appService.skin ? 'order-tracker-section textarea-transparent' : 'order-tracker-section'" >
                        <div *ngIf="!configuration?.carInformationActive" class="text-area-container">
                            <h1 class="text-area-title">{{ configuration?.findInformationText || 'order_tracker.how_can_we_find_you' | translate }}</h1>
                            <textarea maxlength="250" [(ngModel)]='mainNote'></textarea>
                        </div>
                        <div *ngIf="configuration?.carInformationActive" class="text-area-container">
                            <h1 class="text-area-title">{{ configuration?.carInformationText || 'order_tracker.car_information' | translate }}</h1>
                            <textarea class="smaller" maxlength="250" [(ngModel)]='mainNote'></textarea>
                            <h1 class="text-area-title additional">{{ configuration?.findInformationText || 'order_tracker.how_can_we_find_you' | translate }}</h1>
                            <textarea class="smaller" maxlength="250" [(ngModel)]='locationDetailsNote'></textarea>
                        </div>
                        <button mat-button class="primary-button send" (click)="submitNotes()">
                            {{'order_tracker.send' | translate}}
                        </button>

                        <button type="button" class="btn btn-lg btn-link" (click)="returnToStepBeforeNotes()">
                            &lsaquo; {{'back' | translate}}
                        </button>
                    </div>
                </mat-tab>

                <!-- THUMBS UP -->
                <mat-tab label="Step 10">
                    <!-- <order-tracker-header [user]="user" [org]="org" [order]="order"></order-tracker-header> -->
                    <div class="order-tracker-section">
                        <div class="animation-container">
                            <ng-lottie *ngIf="secondaryStep == orderStepsEnum.ORDER_THUMBS_UP" class="order-animation" [options]="{path: orderAnimationPaths['thumb-up'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <h3 class="bold">{{ 'order_tracker.instructions_accepted' | translate }}</h3>
                        <button mat-button class="primary-button" (click)="returnToStepBeforeNotes()">
                            {{'order_tracker.ok' | translate}}
                        </button>
                    </div>
                </mat-tab>

                <!-- ERROR -->
                <mat-tab label="Step 11">
                    <div class="order-tracker-section">
                        <div class="loading-order-spacer"></div>
                        <h1 class="top-error-text">{{ 'order_tracker.order_not_here' | translate }}</h1>
                        <h3 class="bottom-error-text">{{ 'order_tracker.dont_cry_over_spilled_juice' | translate }}</h3>
                        <div class="animation-container">
                            <ng-lottie class="order-animation error" [options]="{path: orderAnimationPaths['order-declined'], autoplay: true, loop: true}"></ng-lottie>
                        </div>
                        <button *ngIf="showHomePageRedirect" mat-button class="primary-button smaller" (click)="_redirectToHomePage()">
                            {{'order_tracker.can_we_offer_you_something_else' | translate}}
                        </button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <mat-tab label="{{'summary' | translate}}">
            <header-tracker *ngIf="order" [order]="order"></header-tracker>
            <order-summary *ngIf="order" [order]="order" [org]="org"></order-summary>
        </mat-tab>
        <mat-tab *ngIf="showMapIndication | async" label="{{'site_details.tab_label_map' | translate}}">
            <div class="map">
                <google-map width="100%" [center]="{lat: coordinates.lat, lng: coordinates.lng}" [zoom]="17" [options]="{
                    zoomControl: true,
                    rotateControl: true,
                    mapTypeControl: true,
                    gestureHandling: 'cooperative',
                    streetViewControl: true
                    }">
                    <map-marker [position]="{lat: coordinates.lat, lng: coordinates.lng}" [options]="getMarkerOptions()">
                    </map-marker>
                </google-map>
            </div>
        </mat-tab>
    </mat-tab-group>

    <site-meta-data [orgId]="orgId" [service]="service"></site-meta-data>

    <div class="spacer_btm"></div>

</div>