import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { TabitbookSelectionBarComponent } from '../tabitbook-selection-bar/tabitbook-selection-bar.component';
import { NgClass } from '@angular/common';
import { LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'app-tabitbook-end',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './tabitbook-end.component.html',
    styleUrls: ['./tabitbook-end.component.scss'],
    standalone: true,
    imports: [LottieComponent, NgClass, TabitbookSelectionBarComponent, MatButton, TranslateModule]
})
export class TabitbookEndComponent implements OnInit {

    @Input() options;

    $storage: any;

    title: string = 'TGM.SUCCESS_TITLE';

    checkmarkAnimation = this.appService.getAnimationPath('checkmark');

    constructor(
        public appService: AppService,
        public bookService: BookService,
        private organizationsService: OrganizationsService,
    ) {
        this.$storage = this.bookService.$storage;
    }

    ngOnInit() {
        if (this.$storage.reservation && this.$storage.reservation.standby_reservation) {
            if (this.$storage.reservation.pending_approval) this.title = 'TGM.SUCCESS_TITLE_PENDING_APPROVAL';
            else this.title = 'TGM.SUCCESS_TITLE_STANDBY';
        }
    }

    close() {
        let site = this.organizationsService.getOrganization(this.$storage.organization);
        if (!site) return console.error('No site found at loaded organizations:', this.$storage.organization);
        this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

}
