import { Component, OnInit, Input } from '@angular/core';

import { AppService } from '../../../app.service';
import { DefaultCurrencyPipe, FilterPipe, WeightPipe } from '../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { LottieComponent } from 'ngx-lottie';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';

@Component({
    selector: 'tabitpay-ticket-seated',
    templateUrl: './tabitpay-ticket-seated.component.html',
    styleUrls: ['./tabitpay-ticket-seated.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, LottieComponent, DecimalPipe, TranslateModule, DefaultCurrencyPipe, FilterPipe, WeightPipe]
})
export class TabitpayTicketSeatedComponent implements OnInit {
    @Input() $storage;

    processAnimation = this.appService.getAnimationPath('process');

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() { }

}
