export const appStrings = {
    'he-IL': {
        "number_of_restaurants_found_screen_reader": "ּמִסְפָּר מִסְעְַדוֹת שְֵׁנִִמְצְאו,",
        "number_of_addresses_found_screen_reader": "מִסְפָּר כְּתוֹבוֹת שְֵׁנִִמְצְאו,",
        "number_of_dishes_found_screen_reader": "מִסְפָּר מָנוֹת שְֵׁנִִמְצְאו,",
        "localeCaption": "EN",
        "share_this": "היי, הנה משהו שמצאתי בטאביט. רוצה לנסות?",
        "hours": "שעות",
        "days": "ימים",
        "tabit": "טאביט",
        "minutes": "דקות",
        "footer_data": "הזמנת מקום | הזמנת שולחן | טאביט | Tabit",
        "terms_of_service": "תנאי השימוש",
        "privacy_notice": "מדיניות הפרטיות",
        "under_construction_message": "העמוד הזה עדיין בהכנה",
        "summary": "סיכום",
        "service_selection": "בחירת סוג שירות",
        "future_service_desc": "יש לבחור את השירות העתידי הרצוי",
        "choose_navigation_option": "בחירת אופצית ניווט",
        "choose_option": "בחירת אפשרות",
        "choose_hotel": "באיזה מלון אתם שוהים?",
        "_and": "ו",
        "KEYPAD_DIALOG_WARNING": "ניתן להזין עד {{maxVal}} {{currency}}",
        "rsv_management": {
            "guests": "אורחים",
            "disclaimer": "לכל בקשה או בירור בקשר להזמנתך יש ליצור קשר ישירות עם",
            "description": {
                "future_reservation_created": "שלום {{name}},\nההזמנה שלכם נשמרה אצלנו בהצלחה.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
                "future_reservation_standby_created": "שלום {{name}},\nשימו לב, הוספנו אתכם לרשימת המתנה. ניצור אתכם קשר במידה ויתפנה מקום.",
                "future_reservation_standby_created_pending_approval": "שלום {{name}},\nשימו לב, הזמנתכם טרם אושרה. ניצור אתכם קשר בהקדם על מנת לאשר את הזמנתכם.",
                "future_reservation_reminded": "שלום {{name}},\nאנא אשרו את הזמנתכם.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
                "future_reservation_reminded_free_text": "אנא אשרו את הזמנתכם.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
                "future_reservation_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!",
                "future_reservation_checked_in": "ההגעה שלכם נקלטה. נודיע לכם כשהשולחן יהיה מוכן.",
                "future_reservation_deleted": "ההזמנה שלכם בוטלה.",
                "future_reservation_verified": "אישרתם את הגעתכם. תודה!",
                "future_reservation_will_be_late": "תודה על העדכון שתגיעו באיחור",
                "future_reservation_undo_late": "תודה על העדכון",
                "future_reservation_cc_deposit": "שלום {{name}},\nההזמנה שלכם נשמרה אצלנו בהצלחה.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!\nשימו לב, תחויבו בסכום הפקדון במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה.",
                "future_reservation_cc_deposit_reminded": "שלום {{name}},\nאנא אשרו את הזמנתכם.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!\nשימו לב, תחויבו בסכום הפקדון במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה.",
                "future_reservation_cc_deposit_notified": "שלום {{name}},\nהשולחן שלכם מוכן. אתם מוזמנים להכנס!\nשימו לב, תחויבו בסכום הפקדון במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה.",
                "future_reservation_cc_deposit_checked_in": "שלום {{name}},\nההגעה שלכם נקלטה. נודיע לכם כשהשולחן יהיה מוכן.\nשימו לב, תחויבו בסכום הפקדון במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה.",
                "future_reservation_advanced_payment": "שלום {{name}},\nההזמנה שלכם נשמרה אצלנו בהצלחה!\nהשולחן ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!\nשימו לב, במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה,\nלא תוכלו לקבל זיכוי על הסכום ששולם מראש.",
                "future_reservation_advanced_payment_reminded": "שלום {{name}},\nאנא אשרו את הזמנתכם.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!\nשימו לב, במידה ותבטלו פחות מ {{time_limit_cancel}} משעת ההזמנה,\nלא תוכלו לקבל זיכוי על הסכום ששולם מראש.",
                "walked_in_created": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
                "walked_in_saved": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
                "walked_in_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!",
                "walked_in_checked_in": "ההגעה שלכם נקלטה. נודיע לכם כשהשולחן יהיה מוכן.",
                "called_in_created": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
                "called_in_saved": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
                "called_in_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!"
            },
            "buttons": {
                "confirm_label": "אישור הגעה",
                "cancel_label": "ביטול הזמנה",
                "will_be_late_label": "אנו נאחר",
                "undo_late_label": "נגיע בזמן",
                "fill_health_statement": "מילוי הצהרת בריאות",
                "modify_label": "עדכון הזמנה"
            },
            "cancel_reservation_dialog": {
                "title": "ביטול הזמנה",
                "body": "האם אתם בטוחים שברצונכם לבטל את ההזמנה?",
                "confirm": "ביטול הזמנה",
                "cancel": "חזרה"
            },
            "failure_reason": {
                "reservation_not_found": "לא נמצאה הזמנה",
                "reservation_in_progress": "האורחים כבר בשולחן. תהנו!",
                "organization_not_found": "לא נמצא אירגון",
                "feedback_module_disabled": "מצטערים, מערכת המשובים מושבתת עבור המסעדה הזאת"
            },
            "place_in_queue": "מיקומכם בתור",
            "preferences": "העדפות",
            "preference_exception": "חוץ מ{{preference}}",
            "social": {
                "share": {
                    "whatsapp": {
                        "button_label": "שלח לחבר/ה",
                        "app_message": "היי, הזמנתי לנו מקום ב",
                        "web_message": "היי,\nהזמנתי לנו מקום ב{{organization}}\n{{link}}"
                    },
                    "share": "שתף"
                }
            },
            "notifications": {
                "email": {
                    "unsubscribe": {
                        "success": "הוסרתם בהצלחה מקבלת עדכונים במייל להזמנה זאת",
                        "error": "חלה שגיאה לא צפויה בהסרה מהעדכונים במייל. אנא נסו שנית מאוחר יותר או צרו קשר עם המסעדה."
                    }
                }
            },
            "call_customer": {
                "request": "יש לי עדכון, תחזרו אליי",
                "request_sent": "נחזור אליך בהקדם",
                "request_cancel": "(לחצו לביטול הבקשה)"
            },
            "deposit_payment": {
                "qr_code_button": "הצגת קוד QR",
                "qr_code_description": "יש להציג את קוד ה-QR עם הגעתך למסעדה",
                "cancel_reservation_dialog": {
                    "body": "ביטול בתוך פרק זמן הקצר מ-{{time_limit_cancel}} ממועד ההזמנה יחוייב בעלות המקדמה. בבקשה צרו קשר עם המסעדה בכל נושא הקשור בזה. \n האם לבטל את ההזמנה?"
                },
                "refund_failed_dialog": {
                    "title": "לא ניתן לבטל את ההזמנה",
                    "body": "אירעה שגיאה בתהליך ביטול ההזמנה - אנא פנו למסעדה"
                },
                "description": {
                    "cancel_after_time_limit": " הזמנתכם בוטלה והמקדמה לא זוכתה. לכל בקשה בנושא זה יש לפנות למסעדה",
                    "cancel_before_time_limit": "הזמנתך בוטלה ותשלום המקדמה זוכה",
                    "no_voucher_balance": "ההזמנה בוטלה.\nחשוב לשים לב: המקדמה כבר מומשה ולכן לא בוצע עליה זיכוי. לפרטים נוספים אנא פנו למסעדה."
                }
            },
            "cc_deposit": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "ביטול בטווח של פחות מ-{{time_limit_cancel}} ממועד ההזמנה יחויב בעלות הפקדון.\nלכל שאלה בנושא פנו למסעדה.\nהאם לבטל את ההזמנה?"
                    },
                    "prevent": {
                        "title": "לא ניתן לבטל את ההזמנה",
                        "body": "נותרו פחות מ-{{time_limit_cancel}} למועד ההזמנה.\nלביצוע ביטול - פנו למסעדה",
                        "primary_button": "התקשרו למסעדה",
                        "secondary_button": "חזור"
                    }
                },
                "credit_details_dialog": {
                    "form_title": "כדי לאמת את אמצעי התשלום, נתפוס את מסגרת האשראי על סך שקל אחד. היא תשתחרר עד סוף היום",
                    "submit_button": "לאישור"
                }
            },
            "advanced_payment": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "ביטול בטווח של פחות מ-{{time_limit_cancel}} ממועד ההזמנה יחייב בעלות המקדמה את כרטיס האשראי שהוזן.\nלכל שאלה בנושא פנו למסעדה.\nהאם לבטל את ההזמנה?"
                    },
                    "prevent": {
                        "title": "לא ניתן לבטל את ההזמנה",
                        "body": "נותרו פחות מ-{{time_limit_cancel}} למועד ההזמנה.\nלביצוע ביטול - פנו למסעדה",
                        "primary_button": "התקשרו למסעדה",
                        "secondary_button": "חזור"
                    },
                    "charged": {
                        "body": "ביטול בטווח של פחות מ-{{time_limit_cancel}} ממועד ההזמנה לא מזכה אתכם בעלות המקדמה ששולמה מראש.\nלכל שאלה בנושא פנו למסעדה.\nהאם לבטל את ההזמנה?",
                        "in_time_cancellation_body": "ביטול בטווח של יותר מ-{{time_limit_cancel}} מזכה אתכם אוטומטית בעלות המקדמה ששולמה מראש.\nלכל שאלה בנושא פנו למסעדה.\nהאם לבטל את ההזמנה?"
                    }
                }
            },
            "hour": "שעה",
            "two_hours": "שעתיים"
        },
        "splash_screen": {
            "failure_dialog": {
                "title": "משהו לא עבד",
                "content": "המערכת נתקלה בבעיה לא צפויה",
                "ok_button": "נסה שוב"
            }
        },
        "booking": {
            "site_name": "שם אתר",
            "site_address": "כתובת אתר",
            "disclaimer_description": "לחיצה על הכפתור תאשר את ",
            "disclaimer_description_deposit_payment": "ע\"י לחיצה על \"אישור ותשלום\" אני מאשר/ת את ",
            "disclaimer_description_connection": " ו",
            "terms_of_service": "תנאי השימוש",
            "privacy_notice": "מדיניות הפרטיות",
            "button_label": {
                "walked_in": "מגיעים בקרוב",
                "future_reservation": "הזמנת מקום"
            },
            "search": {
                "person": "אורח אחד",
                "people": "{{value}} אורחים",
                "larger_party": "{{value}}+ אורחים",
                "minutes": "{{value}} דקות",
                "no_results": "לא מצאנו בדיוק את מה שחיפשתם,\nאנא בחרו מועד אחר או התקשרו למסעדה",
                "no_alternatives": "לא מצאנו בדיוק את מה שחיפשתם,\nאנא בחרו מועד אחר או התקשרו למסעדה",
                "no_waiting_list": "אין המתנה, אתם מוזמנים להגיע",
                "waiting_list_full": "מתנצלים, רשימת ההמתנה שלנו מלאה",
                "results_info": "לא מצאנו בדיוק את מה שחיפשתם - אבל הנה כמה אפשרויות אחרות",
                "call_restaurant_large_party": "היי! נראה שאתם קבוצה ממש גדולה - אנא צרו עמנו קשר בטלפון ונשמח לשמור לכם מקום",
                "call_restaurant_error": "לצערנו המסעדה בתפוסה מלאה - אנא התקשרו אלינו",
                "booking_window_error": "מתנצלים, כרגע לא ניתן להירשם לתור",
                "today": "היום",
                "first_available": "ללא העדפה",
                "choose_preference": "בחרו העדפה *",
                "inside": "פנים",
                "outside": "חוץ",
                "vip": "VIP",
                "bar": "בר",
                "gallery": "גלריה",
                "balcony": "מרפסת",
                "smoking": "מעשנים",
                "private_room": "חדר פרטי",
                "patio": "פטיו",
                "tv_view": "פונה למסך",
                "stage": "במה",
                "with_stroller": "עם עגלה",
                "with_power": "עם חשמל",
                "standby_reservation_description": "אני בכל זאת רוצה את המועד שביקשתי, אנא הוסיפו אותי לרשימת ההמתנה",
                "alternative_organizations_description": "תמיד אפשר לחפש גם במסעדות האחרות שלנו",
                "meta_description": "להזמנת מקום ב{{name}} בחרו תאריך, שעה וכמות אורחים.",
                "back_to_reservation": "חזרה להזמנה",
                "field_label": {
                    "seats_count": "מספר אורחים",
                    "date": "תאריך",
                    "time": "שעה",
                    "preference": "איזור",
                    "arriving_within": "הגעה תוך"
                },
                "button_label": {
                    "search": "הזמנת מקום",
                    "search_walked_in": "הוספה לתור",
                    "search_modify": "המשך",
                    "standby_reservation": "הוסף אותי לרשימת ההמתנה",
                    "phone": "התקשרו אלינו"
                },
                "today_blocked": "שימו לב, לא ניתן להזמין מקומות להיום",
                "reservation_in_edit_current_title": "פרטי ההזמנה הנוכחית",
                "reservation_in_edit_modified_details": "מה תרצו לעדכן?"
            },
            "details": {
                "description": "להשלמת התהליך אנא מלאו את הפרטים הבאים.",
                "description_pending_approval": "הזמנה זו מותנת באישור המסעדה. להשלמת התהליך אנא מלאו את הפרטים הבאים.",
                "description_standby": "להוספה לרשימת המתנה אנא מלאו את הפרטים הבאים.",
                "description_modify_warning": "שימו לב! אחרי העדכון, ההזמנה המקורית לא תישמר.\nההזמנה המעודכנת תעבור לרשימת המתנה עד לאישור מחדש על ידי המסעדה.",
                "description_modify_cc_deposit_warning": "שימו לב! עדכון ההזמנה יסיר את פיקדון האשראי שניתן עבור ההזמנה המקורית.\nבמסך הבא תתבקשו להזין פיקדון אשראי חדש.",
                "description_modify_advanced_payment_warning": "שימוב לב! עדכון ההזמנה יסיר את פרטי האשראי שניתנו עבור תשלום מראש על ההזמנה המקורית.\nבמסך הבא תתבקשו להזין את פרטי האשראי מחדש.",
                "future_reservation_info": "שימו לב, השולחן נשמר עבורכם ל-{{value}} דקות הקרובות",
                "future_standby_reservation_info": "אנא מלאו את הפרטים הבאים ואנו נשלח לכם הודעה כאשר יתפנה מקום.",
                "called_in_info": "הוספנו אתכם לתור.\nאנא הגיעו למסעדה ב-{{minutes}} דקות הקרובות.",
                "timer": "לרשותכם עוד {{minutes}} דקות",
                "button_label": {
                    "confirm": "שמירה",
                    "confirm_and_pay": "אישור ותשלום",
                    "proceed_to_deposit": "המשך",
                    "cancel": "ביטול",
                    "back_to_search": "חזרה לחיפוש",
                    "continue": "המשך",
                },
                "field_label": {
                    "first_name": "שם פרטי",
                    "last_name": "שם משפחה",
                    "full_name": "שם מלא",
                    "phone": "טלפון",
                    "email": "אימייל",
                    "notes": "הערות",
                    "tags": "הערות",
                    "search": "חיפוש"
                },
                "field_error": {
                    "phone": "מספר שגוי"
                },
                "countries_dropdown": {
                    "find_country": "חיפוש"
                }
            },
            "modal": {
                "confirmation": {
                    "time_limit_title": "שימו לב",
                    "time_limit_body": "ההזמנה שלכם תהיה מוגבלת בזמן עד השעה {{reserved_until}} ({{duration}} בלבד).\nהאם תרצו להמשיך?",
                    "queue_addition_title": "הוספה לתור",
                    "queue_addition_body": "האם תרצו שנוסיף אתכם לתור?",
                    "still_here": "עדיין פה?"
                },
                "alert": {
                    "unsuccessful_attempt_to_reserve_title": "אופס...",
                    "unsuccessful_attempt_to_reserve_body": "נראה שאין לכם כרגע חיבור לאינטרנט.\nאנא התחברו ונסו שנית.",
                    "unsuccessful_attempt_to_reserve_body_invalid_cc": "בדקו את פרטי כרטיס האשראי",
                    "reservation_not_found_title": "אופס...",
                    "reservation_not_found_body": "עברו מעל {{minutes}} דקות מאז ששמרנו עבורכם את ההזמנה ולכן תוקף ההזמנה שלכם פג. אנא הזינו אותה מחדש\n\nלסיוע נוסף, ניתן ליצור קשר עם המסעדה",
                    "fill_mandatory_fields_title": "שדות חובה",
                    "fill_mandatory_fields_body": "אנא מלא את כל שדות החובה",
                    "online_booking_blocked_title": "הזמנה מקוונת אינה פעילה",
                    "online_booking_blocked_body": "לצערנו הזמנה מקוונת אינה פעילה למסעדה זו",
                    "preference_required_title": "לא נבחר איזור",
                    "preference_required_body": "אנא בחרו איזור על מנת להמשיך",
                    "illegal_group_size_title": "שימו לב!",
                    "illegal_group_size_body": "כמות הסועדים שבחרת גדולה מדי עבור התאריך והשעה שנבחרו.",
                    "customer_additional_reservation_blocked_title": "לא ניתן לבצע הזמנה נוספת",
                    "customer_additional_reservation_blocked_body": "נראה שכבר הזמנתם מקום אצלנו בטווח של פחות מ-{{hours}} שעות.\nעל מנת לבצע הזמנה חדשה, אנא בטלו את ההזמנה הקודמת או התקשרו אלינו.",
                    "cc_deposit_transaction_failed": "פעולה נכשלה",
                    "back_to_create_reservation_button": "חזרה להזמנת מקום",
                    "call_restaurant_button": "התקשרו למסעדה",
                    "modify_not_allowed_title": "התכניות השתנו?",
                    "modify_not_allowed_body": "לצערנו מועד ההזמנה קרוב מדי וכבר לא ניתן לעדכן אונליין"
                },
                "confirm": "אישור",
                "cancel": "ביטול",
                "retry": "נסה שוב",
                "continue": "המשך",
                "back": "חזרה"
            },
            "user_abandon_warning": "שימו לב - אתם עוזבים את העמוד והשינויים שביצעתם לא ישמרו",
            "online_booking_blocked_description": "בשלב זה לא ניתן לבצע הזמנות מקוונות. אנא צרו עמנו קשר בטלפון ע\"י לחיצה על כפתור הטלפון המופיע למטה."
        },
        "reservation_details": {
            "date_label": "יום ",
            "time_label": "בשעה",
            "arriving_within_label": "מגיעים בעוד",
            "people": "{{value}}"
        },
        "feedback": {
            "feedback_form": "טופס משוב",
            "possible_answers": "תשובות אפשריות",
            "mandatory_questions": "שאלת חובה",
            "guest": "אורח",
            "description": "שלום {{name}},\nנשמח אם תענו על השאלות הבאות",
            "conclusion": {
                "title": "תודה על המשוב!",
                "satisfied_customer_sub_title": "נשמח לראותכם שוב בקרוב",
                "unsatisfied_customer_sub_title": "מתנצלים על אי-הנעימות.\nפנייתך הועברה למנהל המסעדה, ונמשיך לעשות כמיטב יכולתנו לשפר את חוויתכם."
            },
            "button_label": {
                "confirm": "שלח משוב",
                "poor": "לא טוב",
                "fair": "כמעט טוב",
                "good": "טוב",
                "very_good": "טוב מאוד",
                "excellent": "מצוין"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "תשובה חסרה",
                    "fill_mandatory_fields_body": "אנא ענו על כל השאלות"
                },
                "confirm": "אישור"
            },
            "unsubscribe": {
                "link": "אני לא רוצה לקבל יותר סקרים מ{{organization}}",
                "success": "הסרנו אתכם בהצלחה מקבלת סקרים בעתיד.",
                "error": "חלה שגיאה לא צפויה בהסרה מקבלת סקרים. אנא נסו שנית מאוחר יותר או צרו קשר עם המסעדה."
            }
        },
        "deposit": {
            "description": "על מנת לסיים את תהליך שמירת ההזמנה - אנא מלאו את פרטי האשראי לצורך פיקדון.",
            "card_number": "מספר כרטיס",
            "cvv": "CVV",
            "year": "שנה",
            "month": "חודש",
            "id_number": "ת.ז.",
            "zip_code": "מיקוד",
            "date_passed": "התאריך עבר",
            "invalid_cc_number": "מספר כרטיס שגוי",
            "invalid_id_number": "מספר ת.ז. שגוי",
            "button_label": {
                "save": "שמירה",
                "confirm_and_continue": "לאישור והמשך",
                "cancel": "ביטול הזמנה",
                "continue": "המשך"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "שדות חובה",
                    "fill_mandatory_fields_body": "אנא מלא את כל שדות החובה",
                    "submit_error_title": "הפקדון לא הצליח",
                    "submit_error_body_general": "בדקו את הפרטים בטופס ונסו שנית",
                    "submit_error_body_invalid_cc": "בדקו את פרטי כרטיס האשראי ונסו שנית"
                },
                "confirm": "אישור"
            },
            "amount_details": {
                "title": "סכום לפיקדון",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },
        "cc_deposit": {
            "description": "ההזמנה מותנית במילוי פרטי אשראי לצורך פיקדון על סך {{currency_symbol}}{{amount_per_guest}} לסועד.\nניתן לבטל את ההזמנה עד {{time_limit_cancel}} מראש.\nלהשלמת ההזמנה מלאו את הפרטים הבאים",
        },
        "deposit_payment": {
            "description": "ההזמנה מותנית בתשלום מקדמה בסך {{currency_symbol}}{{amount_per_guest}} לסועד.\nניתן לבטל את ההזמנה עד {{time_limit_cancel}} מראש ולקבל זיכוי מלא על התשלום.\nלהשלמת ההזמנה המשיכו בתשלום.",
            "illegal_amount": "הסכום לתשלום אינו תקין"
        },
        "advanced_payment": {
            "description": "ההזמנה מותנית בתשלום מראש בסך {{currency_symbol}}{{amount_per_guest}} לסועד.\nניתן לבטל את ההזמנה עד {{time_limit_cancel}} לפני הגעתכם ולקבל זיכוי מלא על התשלום.\n להשלמת ההזמנה, מלאו את הפרטים הבאים",
            "amount_details": {
                "title": "סכום לתשלום מראש",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },
        "health_statement": {
            "title": "הצהרת בריאות",
            "customer_thanks": "תודה {{first_name}} {{last_name}}",
            "health_statement_saved": "הצהרת הבריאות נשמרה בהצלחה",
            "health_statement_not_found": "הצהרת בריאות לא נמצאה",
            "share_health_statement_form_button": "שתף הצהרת בריאות עם מוזמנים נוספים",
            "share_health_statement_form_text": "היי,\nהזמנתי לנו מקום ב{{organization}}.\nהתבקשנו למלא הצהרת בריאות:\n{{link}}",
            "no_fever_statement": "אני מצהיר/ה כי ערכתי היום בדיקה למדידת חום גוף, בה נמצא כי חום גופי אינו עולה על 38 מעלות צלזיוס",
            "no_cough_statement": "אני מצהיר/ה כי איני משתעל/ת וכן כי אין לי קשיים בנשימה למעט שיעול או קושי בנשימה הנובע ממצב כרוני כגון אסטמה או אלרגיה אחרת",
            "coronavirus_law": "לפי סעיף 3א(1) לתקנות שעת חירום - נגיף הקורונה"
        },
        "smart_banner": {
            "title": "למה ככה בדפדפן?",
            "body": "באפליקציה יותר כיף :)"
        },
        "server_time_client_diff_dialog": {
            "title": "שימו לב",
            "content": "השעה במכשירכם שונה משעת המסעדה ב-{{time_diff}}.\nכל ערכי הזמן יוצגו לפי שעת המסעדה.",
            "ok_button": "אישור"
        },
        "site-links": {
            "address_screen_reader": "כתוֹבֵת מִסְעְַדְָה:",
            "from_screen_reader": "פַּּּתוּח מֵשַָעָה",
            "up_until_screen_reader": ",עַד שַָעָה",
            "phone_number_screen_reader": "מִסְפָּר טֵלֵפון:",
            "to_call_screen_reader": "לחַץ כַּאן לֵחיוּג",
            "to_navigate_screen_reader": "לֵחַץ כַּאן להוֹרָאות הָגָעָה ונִיוְּוּט",
            "show_work_hours_screen_reader": "לֵחַץ כַּאן לֵשְעות פְּּּעִילוּת של המִסְעְַדְָה",
            "to_web_site_screen_reader": "לֵחַץ כַּאן לֵמַעָבַר לֵאֳַתָר המִסְעְַָדְָָה"
        },
        "dialog_screen_reader": {
            "default": "תְיבַת דוּ שׂיח נִיפְתְּחָה",
            "before_you_pay_suggestion": "תיבַת דוּ שׂיח נִיפְתְּחָה: הָצָעַת מָנוֹת לפנֵי תָשלוּם",
            "order_scheduling": "תיבַת דוּ שׂיח נִיפְתְּחָה: תיאוּם תָאָרִיך וְזְמַן הָהָזְמָנָה שְל מִישְׁלוֹחַ אוֹ אִיסוּף עַצמִי",
        },
        "forms_screen_reader": {
            "sign-up": {
                "pop_up_announcement": "נִפְתָח חָלוֹן הִתְחַבְרוּת לְמָעָרֵכֵת טָאבִּיט, נָא הָזֵן מִסְפָּר טֵלֵפון לְאִימוּת",
                "missing_fields": "הָשדָוֹת הָבָאִים הִינַם חוֹבָה וְאֵינַם מוּלְאוּ אוֹ מוּלְאוּ בְצוּרָה שְגוּיָה:",
                "phoneControl": "מִסְפָּר טֵלֵפון:",
            },
            "to-desktop-sign-up": {
                "missing_fields": "הָשדָוֹת הָבָאִים הִינַם חוֹבָה וְאֵינַם מוּלְאוּ אוֹ מוּלְאוּ בְצוּרָה שְגוּיָה:",
                "cPhone": "מִסְפָּר טֵלֵפון:",
                "email": "כתוֹבֵת אימייל",
                "firstName": "שֵם פְרָטִי",
                "lastName": "שֵם מִשְׁפָחָה",
                "apartment": "מִסְפָּר דירה",
                "floor": "מִסְפָּר קומה",
                "address": "כתוֹבֵת מלאה",
                "terms": "ׁאִישׁוּר שֶׁל מְדִינִיוּת הָפְּּרָטִיוּת וְתְנׁאֵי הָשִׁימוּש"
            },
            "customer_details": {
                "missing_fields": "הָשדָוֹת הָבָאִים הִינַם חוֹבָה וְאֵינַם מוּלְאוּ אוֹ מוּלְאוּ בְצוּרָה שְגוּיָה:",
                "email": "כתוֹבֵת אִימֵיְל",
                "first_name": "שֵם פְרָטִי",
                "last_name": "שֵם מִשְׁפָחָה",
                "notes": "הְֶעֶֶָרוֹת",
                "phone": "מִסְפָּר טֵלֵפוֹן:",
            },
            "loyalty_sign_up": {
                "missing_fields": "הָשדָוֹת הָבָאִים הִינַם חוֹבָה וְאֵינַם מוּלְאוּ אוֹ מוּלְאוּ בְצוּרָה שְגוּיָה:",
                "firstName": "שֵם פְרָטִי",
                "lastName": "שֵם מִשְׁפָחָה",
                "email": "כתוֹבֵת אִימֵיְל",
                "mobile": "מִסְפָּר טֵלֵפוֹן",
                "zehut": "מִסְפָּר תְעוּדַת זֶהוּת",
                "membershipPrice": "ׁאִישׁוּר תוֹסֶפֶת תָשלוּם עָל הָהַרשָׁמָה",
                "clubTerms": "ׁאִישׁוּר תְנָאִים שֶׁל הָמוֹעָדוֹן",
            }
        },
        "social-share": {
            "share_label": "שיתוף הזמנה",
            "calendar_label": "הוספה ליומן"
        },
        "social-share_screen_reader": {
            "share": "לֵחַץ לְשִׁיתוּף",
            "add_to_my_calender": "לֵחַץ לֵהוֹסׁפׁה לְלֵוּחָ הָשָנָה",
        },
        "contact-details": {
            "location_label": "ניווט",
            "phone_label": "טלפון",
            "menu_label": "תפריט",
            "choose_menu": "בחירת תפריט",
            "no_available_menu": "אין תפריט זמין",
            "menu_error_dialog_title": "אירוע שגיאה",
            "menu_error_dialog_body": "אנא נסו שנית מאוחר יותר"
        },
        "contact-details_screen_reader": {
            "location": "לֵחַץ לְנִיוְּוּט לָמִסְעְַדְָה",
            "phone": "לֵחַץ לֵחיוּג לָמִסְעְַדְָה",
        },
        "TO-DELETE": "למחוק",
        "TO-EDIT": "לערוך",
        "TO-ADD": "הוספה",
        "TO-UPDATE": "עדכון",
        "CANCEL": "ביטול",
        "NO_THANKS": "לא, תודה",
        "CLEAR_SELECTION": "נקה בחירה",
        "ADD": "הוסף",
        "UPDATE": "עדכן",
        "DEFAULT_FORMATION": "רגיל",
        "ORDER-TOTAL": "סיכום הזמנה",
        "REMOVE": "הסר",
        "CASH": "מזומן",
        "THANK_YOU": "תודה רבה!",
        "TIP": "טיפ",
        "PAYED": "שולם",
        "CONTINUE": "המשך",
        "CART_SUBTOTAL": "סל המשך",
        "TOTAL_IN_TIP": "סה\"כ כולל טיפ",
        "PARTIAL_PAY_IN_TIP": "תשלום חלקי כולל טיפ",
        "CLEAR": "נקה",
        "CONFIRM_SIGN_OUT": "כן, התנתק",
        "APPROVE": "אישור",
        "REMAINED_PAY": "נותר לתשלום",
        "REMAINED_PAY_CASH": "מזומן נותר לתשלום",
        "TO_PAY": "לתשלום",
        "SAVE_CARD_INFO": "שמור פרטי תשלום",
        "TOTAL": "סה\"כ",
        "CARD_HOLDER_NAME": "שם בעל הכרטיס",
        "CARD_NUMBER": "מס' כרטיס",
        "SIGNIN": "התחבר",
        "SIGNIN_INFO": "הזן פרטי הזדהות",
        "VALIDATING_CREDENTIALS": "בודק נתונים...",
        "WELCOME_BACK": "ברוכים השבים",
        "SELECT_EXISTING_CARD": "בחרו מכרטיס קיים",
        "ADD_NEW_CARD": "הוסף כרטיס חדש",
        "SAVE_CARD_AS_DEFAULT": "שמור כרטיס כברירת מחדל",
        "ORDER_TOTAL": "סה\"כ הזמנה",
        "ORDER_VAT_TOTAL": "(כולל מע\"מ {{total}} {{currency}})",
        "FOREIGN_CURRENCY_TOTAL": "ערך ב{{symbol}}",
        "ORDER_TIP": "טיפ",
        "PAID": "שולם",
        "ENTER_ORDER_N": "הזן קוד הזמנה",
        "TO_HOMEPAGE": "לעמוד הבית",
        "VIEW_MORE": "פריטים נוספים",
        "VIEW_LESS": "הסתר פריטים",
        "REQUIERED": "חובה למילוי",
        "REQUIERED_CHECK": "חובה לסמן",
        "EDIT": "עריכה",
        "PASSWORDS_DONT_MATCH": "סיסמאות לא תואמות",
        "PASSWORDS_MIN_LENGTH": "סיסמא חייבת להיות בת 6 תווים לפחות",
        "INVALID_CREDIT_NUMBER": "מס' כרטיס אינו חוקי",
        "INVALID_CVV": "CVV אינו חוקי",
        "INVALID_CARD_EXPIRATION": "תאריך תפוגה אינו חוקי",
        "INVALID_EMAIL": "דוא\"ל אינו תקין",
        "EMAIL_FOR_INVOCE": "EMAIL לשליחת חשבונית",
        "CARD_EXPIRATION": "תאריך תפוגה",
        "CARD_HOLDER_CELL": "טלפון של בעל הכרטיס",
        "ROOM_OWNER_CELL": "לאיזה מספר לשלוח את קוד האימות?",
        "CARD_HOLDER_MAIL": "מייל של בעל הכרטיס",
        "Q_SHARING_BILL": "לתשלום חלקי",
        "Q_TIP_AMOUNT": "כמה טיפ להוסיף?",
        "Q_HOW_MUCH_PAY": "כמה ברצונך  לשלם?",
        "Q_HOW_MUCH_SPLIT": "בין כמה אנשים לפצל את החשבון?",
        "Q_REGISTARED": "נרשמת בעבר? התחבר",
        "Q_NEW_REGISTARED": "לקוח חדש? הרשם",
        "Q_INVOICE_BY_MAIL": "רוצה לקבל חשבונית במייל?",
        "Q_REMOVE_CARD": "למחוק את הכרטיס מהארנק?",
        "Q_REMOVE_PAYMENT": "האם אתה בטוח שברצונך למחוק תשלום זה?",
        "SIGNIN_REGISTER": "הרשם / התחבר",
        "END": "סיום",
        "ENTER_TIP": "הקלד טיפ",
        "ENTER_AMOUNT": "הקלד סכום",
        "SPLIT_AMOUNT": "חלוקת הסכום",
        "YOUR_AMOUNT_TO_PAY": "הסכום שלך לתשלום?",
        "PRESS_HERE": "לחץ כאן",
        "OTHER": "אחר",
        "SENDING_INVOICE": "שולח חשבונית...",
        "CHALLENGE_CODE": "קוד אימות",
        "ENTER_CHALLENGE_CODE": "הקלד את קוד האימות",
        "REMEMBER_ME_ON_PHONE": "זכור אותי במכשיר זה",
        "BILL_AMOUNT": "סכום החשבון",
        "PAY_WITH_ONOTHER_CARD": "שלם עם כרטיס אחר",
        "PAYMENT_METHOD": "אמצעי תשלום",
        "SELECT_PAYMENT_METHOD": "בחירת אמצעי תשלום",
        "CREDIT_CARD": "כרטיס אשראי",
        "CREDIT": "אשראי",
        "creditCard": "אשראי",
        "CreditGuard": "אשראי",
        "creditGuard": "אשראי",
        "CIBUS": "סיבוס",
        "Cibus": "סיבוס",
        "CIBUS_CARD": "כרטיס סיבוס",
        "CIBUS_CARD_NUMBER": "מספר הכרטיס",
        "TENBIS": "תן ביס",
        "10bis": "תן ביס",
        "10bis_card": "כרטיס תן ביס",
        "10bis_card_number": "מספר הכרטיס",
        "loyaltypm_card_number": "מספר הכרטיס",
        "EDIT_ACCOUNT_SETTINGS": "שינוי הגדרות חשבון",
        "MY_ACCOUNT": "החשבון שלי",
        "SIGN_OUT": "התנתק",
        "CLUB_SIGN_OUT": "הסרת חבר מועדון מההזמנה",
        "VOUCHER_SIGH_OUT": "הסרת מימוש הקופון מההזמנה",
        "COMPS_SIGH_OUT": "הסרת הפיצוי מההזמנה",
        "EXPIRED_SOON_SIGN_OUT": "ביטול החידוש",
        "EXPIRED_SOON_APPROVE": "להמשיך מאותו המקום",
        "REMOVE_VOUCHER": "כן, הסר",
        "REMOVE_COMPS": "כן, הסר",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION": "בטוח שברצונך להסיר את חבר המועדון מההזמנה?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_TEXT": "התנתקות מהמועדון תבטל את הזכאות להטבות",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_VOUCHER": "בטוח שברצונך להסיר את הקופון מההזמנה?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_COMPS": "בטוח שברצונך להסיר את הפיצוי מההזמנה?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_EXPIRED_SOON": "לבטל את חידוש המועדון?",
        "MY_ACCOUNT_DETAILS": "פרטי החשבון שלי",
        "EDIT_PAYMENT_INFO": "ערוך אמצעי תשלום",
        "DELETE_CARD": "מחק כרטיס",
        "SET_DEFAULT_CARD": "קבע כבררת מחדל",
        "YES_DELETE": "כן, מחק",
        "OFFER_REMARKS": "הערות למנה",
        "OFFER_DONE": "סיימתי, תודה",
        "ENTER_OFFER_REMARKS": "הזן הערות למנה",
        "DELETE_PAYMENT": "מחק תשלום",
        "CONFIRM_CELL": "Confirm Phone",
        "CONFIRM_CELL_MESSAGE": "הודעת אישור תשלח אליך לטלפון הנייד",
        "USE_ENTERED_PHONE": "שלח ל:",
        "ENTER_ALTERNATE_PHONE": "השתמש באחר:",
        "EMAIL_NOT_FOUND": "Email לא נמצא במערכת",
        "GRATUITY": "טיפ",
        "WITHOUT_GRATUITY": "ללא טיפ",
        "ORDER_SCHEDULING": "מתי מתאים לך לקבל את ההזמנה?",
        "SELECT_SERVICE_TYPE": "סוג השרות",
        "CLOSE": "סגור",
        "CLOSE_DIALOG_BOX_SCREEN_READER": "סְגוֹר חָלְוֹן דִיאָלוֹג",
        "YES": "כן",
        "NO": "לא",
        "SEND_ORDER": "שלח הזמנה",
        "TRACK_YOUR_ORDER": "למעקב אחר ההזמנה שלך",
        "YOUR_ORDERS": "ההזמנות שלי",
        "ORDER_CREATION_TIME": "שעת הזמנה",
        "CONTINUE_ORDERING": "המשך להזמין",
        "ADD_ITEM": "הוסף פריט",
        "SELECT_ITEM": "בחרו פריט",
        "UPDATE_ITEM": "עדכן פריט",
        "TOPPINGS": "תוספות למנה",
        "DECISIONS": "החלטות",
        "ITEM_CHANGES": "שינויים במנה",
        "SELECTION_GROUP_SELECT": "(בחרו {{count}} פריטים)",
        "SELECTION_GROUP_SELECT_SINGLE": "(בחרו פריט אחד)",
        "SELECTION_GROUP_SELECT_MIN": "(בחרו לפחות {{count}} פריטים)",
        "SELECTION_GROUP_SELECT_SINGLE_MIN": "(בחרו לפחות פריט אחד)",
        "GROUP_MOD_SELECT_ERROR": "בחרו לפחות {{count}} תוספות מ: {{group}}",
        "GROUP_MOD_SELECT_SINGLE_ERROR": "בחרו לפחות אחד מ: {{group}}",
        "GROUP_SELECT_ERROR": "בחרו לפחות {{count}} פריטים מ: {{group}}",
        "GROUP_SELECT_SINGLE_ERROR": "בחרו לפחות פריט אחד מ: {{group}}",
        "SELECTION_GROUP_OPTIONAL_MAX": "אופציונלי, בחרו עד {{count}} פריטים",
        "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "אופציונלי, בחרו עד פריט אחד",
        "CHECKOUT": "המשך לתשלום",
        "Q_REMOVE_OFFER_FROM_BASKET": "האם אתה בטוח שאתה רוצה להסיר את הפריט?",
        "ENTER_DELIVERY_ADDRESS": "הזן כתובת למשלוח",
        "SELECT_ADDRESS": "בחרו כתובת",
        "BRANCH": "מסעדה",
        "CHOOSE_BRANCH": "לבחירת המסעדה",
        "ADDRESS": "כתובת",
        "PHONE": "טל",
        "ENTRANCE": "כניסה",
        "FLOOR": "קומה",
        "ENTER_STREET_INCLUDING_NUMBER": "נא להקליד כתובת מלאה כולל מספר בית",
        "MISSING_STREET_NUMBER": "איך בדיוק נגיע אליך?",
        "START_ORDER": "המשך להזמנה",
        "MAKE_AN_ORDER": "בצע הזמנה",
        "SELECT_CITY": "בחרו עיר",
        "SELECT_DELIVERY_CITY": "בחרו עיר למשלוח",
        "SELECT_BRANCH": "בחרו סניף",
        "ORDER_MENU": "תפריט",
        "eatin": "הזמן ושב",
        "ADDITIONAL_DETAILS": "פרטים נוספים",
        "RESET_ORDER": "התחלת הזמנה מחדש",
        "ORDER_FROM": "הזמנה מסניף",
        "ORDER_TO": "משלוח לכתובת",
        "CONTACT_INFO": "פרטים אישיים",
        "CONTACT_ALCOHOL_DECLARATION": "אני מעל גיל {{age}} וככל שידרש, אציג תעודה מזהה לאימות לצורך קניית אלכוהול",
        "PAYMENT_INFO": "פרטי אשראי",
        "PAYMENT_INFO_MISSING": "חסרים לנו כמה פרטים לתשלום בכרטיס {{cardLastDigits}}",
        "FIRST_NAME": "שם פרטי",
        "LAST_NAME": "שם משפחה",
        "CELL": "סלולרי",
        "EMAIL": "Email",
        "REVIEW_YOUR_ORDER": "פרטי ההזמנה",
        "REVIEW_YOUR_ORDER_BUTTON": "לצפייה בחשבון",
        "OKKAMI_APP_LINK": "חזרה לאפליקציית OKKAMI",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "לתשומת ליבך - אחד או יותר מאמצעי התשלום שהוזנו אינם תקינים",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "יש ליצור קשר עם המסעדה בטלפון {{phone}}",
        "EXTERNAL_DELIVERY_FAILURE": "ההזמנה נשלחה בהצלחה",
        "EXTERNAL_DELIVERY_FAILURE_CALL_REST": "יש ליצור קשר עם המסעדה בטלפון {{phone}} לצורת תיאום המשלוח",
        "ADD_ITEMS": "הוסף פריטים",
        "UPDATE_ITEMS": "עדכן פריטים",
        "ORDER_SENT_SUCCESS": "ההזמנה נשלחה בהצלחה",
        "ORDER_NEED_ATTENDANCE": "ההזמנה נשלחה עם שגיאה",
        "CALL_RESTAURANT_TO_COMPLETE_ORDER": "אנא התקשר למסעדה",
        "ORDER_NUMBER": "מספר הזמנה",
        "START_NEW_ORDER": "התחל הזמנה חדשה",
        "ORDER_ITEMS": "פרטי ההזמנה",
        "ORDER_WILL_BE_SENT_TO": "הזמנה תישלח לכתובת",
        "ESTIMATED_DELIVERY_TIME": "זמן הגעה כ",
        "ESTIMATED_PREPARATION_TIME": "זמן הכנה כ",
        "ESTIMATED_SUPPLY_TIME": "זמן אספקה",
        "MINUTES": "דקות",
        "PREPARATION_TIME": "זמן הכנה",
        "ORDER_TYPE": "סוג הזמנה",
        "PLEASE_SELECT": "אנא בחר",
        "PLACEMENT": "אפשרויות",
        "NUMBER_OF_PEOPLE": "מס' סועדים",
        "READ_AND_APPROVE_CONTRACT": "קראתי ואני מסכים",
        "READ_AND_APPROVE_CONTRACT_C": "לתנאי השימוש",
        "READ_CONTRACT": "קרא את פרטי השימוש...",
        "PLEASE_APPROVE_CONTRACT": "אנא אשר את תנאי השימוש",
        "USAGE_CONRACT": "תנאי השימוש",
        "ADDRESS_REMARKS": "הערות לשליח",
        "LOADING_MENU": "טוען תפריט...",
        "MOD_WITH": "תוספות",
        "MOD_WITHOUT": "ללא",
        "PASSWORD": "סיסמא",
        "CONFIRM_PASSWORD": "הזן סיסמא בשנית",
        "FORGOT_PASSWORD": "שכחתי סיסמא...",
        "RESTORE_PASSWORD": "שחזר סיסמא",
        "INCLUDE_SILVERWARE": "הוסף סכו\"ם חד פעמי",
        "LEAVE_DELIVERY": "Leave delivery at door",
        "CURBSIDE_PICKUP": "הזמנה לרכב",
        "CURBSIDE_PICKUP_DESC": "תארו בבקשה מה דגם הרכב, הצבע ומספר הרישוי",
        "AUTHENTICATING": "מאמת פרטים",
        "SIGNED_IN_AS": "מחובר בשם",
        "PAY_WITH_SAVED_PMS": "אמצעי תשלום שמורים",
        "SIGHN_IN_EMAIL": "Email להזדהות",
        "SIGHN_IN_PASSWORD": "סיסמא להזדהות",
        "UPDATE_PAYMENT_INFO": "עדכן אמצעי תשלום",
        "UPDATE_ACCOUNT": "עדכן חשבון",
        "ADD_PAYMENT_INFO": "הוספת אמצעי תשלום",
        "UPDATING_ACCOUNT": "מעדכן את חשבונך...",
        "UPDATE_SIGNIN_INFO": "עדכן פרטי הזדהות",
        "PASS_MIN_LENGTH_ERROR": "סיסמא חייבת להכיל לפחות 6 תווים",
        "OLD_PASSWORD": "סיסמא ישנה",
        "NEW_PASSWORD": "סיסמא חדשה",
        "BRANCH_TIME_CONFIRM_0": "אנחנו סגורים כרגע. נחזור לפעילות בין השעות {{t0}}",
        "BRANCH_TIME_CONFIRM_1": "אנחנו סגורים כרגע. נחזור לפעילות בין השעות {{t0}} ו {{t1}}",
        "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "השירות כבר לא זמין. נחזור לפעילות בין השעות {{t0}}",
        "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "השירות כבר לא זמין. נחזור לפעילות בין השעות {{t0}} ו {{t1}}",
        "BRANCH_TIME_0": "שעות פעילות השירות {{t0}}",
        "BRANCH_TIME_1": "שעות פעילות השירות {{t0}} ו {{t1}}",
        "CONFIRM": "אשר",
        "ORDER_SUCCESS": "אישור הזמנה",
        "MIN-ORDER-PRICE": "סכום הזמנה מינימלי",
        "DELIVERY-PRICE": "דמי משלוח",
        "TOTAL-ITEMS": "סה\"כ פריטים",
        "TOTAL-TO-PAY": "סה\"כ לתשלום",
        "MENU_ITEM_SEARCH": "חיפוש מנה",
        "MENU_ITEM_SEARCH_NO_RESULTS": "לא מצאנו תוצאות עבור \"{{searchValue}}\" 🧐",
        "MENU_ITEM_SEARCH_OTHER": "תרצו לנסות משהו אחר?",
        "MENU_ITEM_SEARCH_CLEAN": "ניקוי חיפוש",
        "PROMOTION_MIN_ORDER_PRICE": "* בקניה מעל {{currency}}{{minOrderPrice}}",
        "WITHOUT": "ללא",
        "CONTINUE_TO_PAYMENTINFO": "המשך לפרטי תשלום",
        "PAYMENT": "תשלום",
        "BRANCHES": "סניפים",
        "SERVER_ERROR": "שגיאת שרת",
        "ERROR": "שגיאה",
        "PAYMENT_ERROR": "שגיאת תשלום",
        "PARTIAL_PAYMENT_ERROR": "שגיאת תשלום",
        "NAME": "שם",
        "REGISTERING": "מבצע הרשמה",
        "REGISTER": "הירשם",
        "RESET_PASSWORD": "אפס סיסמא",
        "RESET_PASSWORD_INFO": "הזן ססמא חדשה ולחץ על אפס",
        "ORDER_HISTORY": "היסטוריית הזמנות",
        "SELECT_ORDER_METHOD": "בחרו צורת משלוח",
        "ORDER_METHOD": "צורת הזמנה",
        "ORDER_FIXED": "חשבון",
        "SET_QUANTITY": "הזן כמות",
        "CELL_PATTERN": "סלולרי צריך להתחיל ב 05",
        "CELL_MINLENGTH": "סלולרי צריך להכיל 10 תווים",
        "VIEW_MENU": "צפייה בתפריט",
        "ORDER_SERVICE_CLOSED": "שרות ההזמנות סגור",
        "SET_ORDER_DELAY": "דחה ביצוע הזמנה...",
        "DELAY_TO": "דחה לשעה",
        "SET_ORDER_DELAY_TO_HOUR": "דחה ביצוע הזמנה לשעה",
        "ASAP": "הכי מוקדם שאפשר",
        "PRMARY_PAYMENT_METHOD": "ברירת מחדל",
        "ADD_PAYMENT": "הוספת תשלום",
        "SPLIT_PAYMENT": "פצל תשלום",
        "SPLIT_PAYMENTS_DIFF_ERROR": "תשלומים לא מסתכמים ל {{total}}, קיים הפרש של {{diff}}",
        "BACK_TO_SITE": "בחזרה לאתר",
        "BACK_TO_APP": "בחזרה לאפליקציה",
        "FREE_DELIVERY_FROM": "משלוח חינם ממחיר",
        "ADD_REMAINNING_AMOUNT": "השלם תשלום לסכום ההזמנה",
        "PAYMENTS_EXCEED_TOTAL_BY": "סה\"כ התשלומים גדול מסכום ההזמנה ב",
        "PAYMENT_AMOUNT": "סכום התשלום",
        "ENTER_FIRST_PAYMENT_AMOUNT": "הזן סכום לתשלום הראשון",
        "EXCESS_PAYMENT": "יתרת תשלום",
        "CLICK_TO_REMOVE_EXCESS_AMOUNT": "הקש להורדת הסכום העודף",
        "CANCEL_SPLIT_PAYMENT": "בטל חלוקת תשלום",
        "APPLY_PAYMENTS": "אשר תשלומים",
        "SINGLE_PAYER": "משלם יחיד",
        "MULTIPLE_PAYERS": "מספר משלמים",
        "SPLIT_ORDER_BETWEEN_PAYERS": "חלק בין מספר משלמים",
        "CALL_RESTAURANT": "התקשר למסעדה",
        "SELECT_TABLE": "בחרו שולחן",
        "TABLE_NO": "מספר שולחן",
        "CHHOSE_ROOM_NO": "מהו מספר החדר שלכם?",
        "ROOM_NO": "מספר חדר",
        "TABLE_NO_ORDER": "מספר שולחן {{no}}",
        "ENTER_ORDER_NO": "הזן מספר הזמנה",
        "ADD_GRATUITY_TO_COURIER": "הוסף טיפ לשליח",
        "ORDER_TOTAL_WITHOUT_GRATUITY": "סה\"כ לתשלום ללא טיפ",
        "ORDER_TOTAL_WITH_GRATUITY": "סה\"כ תשלום כולל טיפ: {{total}}",
        "GRATUITY_PERCENT": "אחוז טיפ",
        "ENTER_GRATUITY_AMOUNT": "הזן סכום טיפ",
        "CONTINUE_TO_GRATUITY": "המשך להזנת טיפ",
        "REFRESH_ORDER": "רענן הזמנה",
        "MONTH": "חודש",
        "YEAR": "שנה",
        "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "הזן סכום לתשלום, לא כולל טיפ",
        "ENTER_AMOUNT_TO_PAY": "הזן סכום לתשלום",
        "FULL_PAYMENT": "תשלום מלא",
        "PARTIAL_PAYMENT": "תשלום חלקי",
        "AMOUNT_EXEEDS_BALANCE_DUE": "הסכום גדול מיתרת התשלום",
        "BACK": "חזור",
        "WORK_HOURS": "שעות פעילות",
        "RESTAURANT_WEBSITE": "אתר המסעדה",
        "SELECT_ONE": "בחרו אחד",
        "DELIVERY_ADDRESS": "כתובת למשלוח",
        "DELIVERY_DETAILS": "פרטי משלוח",
        "ADDRESS_DETAILS": "פרטי כתובת",
        "BRANCH_DETAILS": "פרטי מסעדה",
        "ADDITIONAL_OPTIONS": "אפשרויות נוספות",
        "MINUTES_VAL": "{{m}} דקות",
        "ADDITIONAL_OFFERS": "תוספות ורטבים",
        "SELECT_ADDITIONAL_OFFERS": "בחרו תוספות ורטבים",
        "CONTINUE_TO_CONTACTINFO": "המשך לפרטי קשר",
        "CC_CUSTOMER_NAME": "שם",
        "CC_CUSTOMER_ID": "ת.ז / ח.פ",
        "ORDERER_NAME": "שם המזמין",
        "ORDERER_PHONE": "טלפון",
        "ORDER_NO": "מספר הזמנה",
        "ORDER_TAGS": {
            "BREAKFAST": "בוקר",
            "LUNCH": "צהריים",
            "DINNER": "ערב",
            "WORK": "עבודה"
        },
        "ENTER_LOCALITY": "הזן שם עיר/ישוב",
        "LOCALITY_TITLE": "שם עיר/ישוב",
        "STREET_TITLE": "שם רחוב",
        "HOUSE_TITLE": "מס' בית",
        "TITLE": "{{name}} משלוחים - TabitOrder",
        "TITLE_1": "{{name}} משלוחים",
        "TITLE_2": "{{name}} - TabitOrder",
        "PAYMENT_IN_PROGRESS": "מבצע תשלום...\nנא לא לסגור את החלון",
        "CREATING_ORDER": "שולח עסקה...",
        "LOADING_ORDER": "עסקה בטעינה...",
        "INVALID_AMOUNT": "סכום לתשלום איננו תקין",
        "INVALID_AMOUNT_RANGE": "סכום חוקי בין {{min}} ל {{max}}",
        "BACK_TO_MENU": "בחזרה לתפריט",
        "ORDER_NOTES": "הערות הזמנה",
        "Q_SAVEPM_1": "מעוניינים לקצר את זמן ההזמנה?",
        "Q_SAVEPM_2": "ניתן לשמור את אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
        "Q_SAVEPM_3": "תשלום ושמירה",
        "Q_SAVEPM_4": "תשלום ללא שמירה",
        "DISH_CALCULATOR": "מחשבון מנות",
        "MAKE_ADDITIONAL_PAYMENT": "המשך לתשלום נוסף",
        "DINER": "סועד",
        "GENERAL_DINER": "מרכז השולחן",
        "ADDRESS_WITHOUT_HOUSE": "כתובת ללא מס' בית",
        "TAX_AMOUNT": "מיסים",
        "VAT_AMOUNT": "מע\"מ",
        "INCLUSIVE_TAXES": "מיסים כוללים",
        "ADDITIONAL_FEES": "עמלה",
        "ADDITIONAL_SURCHARGERS": "עמלות נוספות",
        "ADDRESS_TYPE": "סוג כתובת",
        "VALID_UNTILL": "בתוקף עד",
        "ADDRESS_DESKTOP_HEADER": "היי, רק לוודא שאנחנו מגיעים אליך...",
        "ADDRESS_DESKTOP_ANNOUNCEMENT_SCREEN_READER": "נִפְתַח חָלוֹן לְחִיפּוּשׂ כְּתוֹבֶת, נָא הָזֶן כְּתוֹבֶת מֵלְאָה: עִיר, שֵׁם רְחוֹב וְֹמִסְפַר בָית",
        "RESTAURANT_DESKTOP_SEARCH_ANNOUNCEMENT_SCREEN_READER": "נִפְתַח חָלוֹן לְחִיפּוּשׂ מְסַעֲדָה, נָא הָזֶן: שֵׁם מְסַעֲדָה אוֹ כְּתוֹבֶת",
        "SITES_DESKTOP_HEADER": "בחירת מסעדה לאיסוף",
        "SITES_DESKTOP_MENU_HEADER": "בחרו סניף לצפייה בתפריט",
        "FUTURE_ORDER_DIALOG_HEADER": "מתי נתראה?",
        "FUTURE_ORDER_POP_ANNOUNCEMENT_SCREEN_READER": "נִפְתַח חָלוֹן לְתיאוּם הַזְמָנָה עָתִידִית לְאִיסוּף: נָא בְּחַר אֵת הָתָאָרִיך בָּטוּר הָיְמִינִי, ואֵת הָשָעָה בָּטוּר הָשְׂמָאלִי",
        "CHAIN_DIALOG_HEADER": "בחירת מסעדה",
        "FUTURE_DELIVERY_ORDER_DIALOG_HEADER": "מתי מתאים לך שנגיע?",
        "WHERE_CAN_WE_FIND_YOU": "היכן נוכל למצוא אותך?",
        "share_message": "היי, הנה {{name}} - מסעדה שמצאתי בטאביט. רוצה לנסות?",
        "share_message_without_site": "היי, הנה מסעדה שמצאתי בטאביט. רוצה לנסות?",
        "from_you": "ממך",
        "general_time_to": "עד",
        "restaurants": "מסעדות",
        "restaurants_subtitle": "מצאו את המסעדות האהובות עליכם",
        "order": "משלוח",
        "order_subtitle": "לקחת או משלוח",
        "order_action": "משלוח/TA",
        "book": "הזמנת מקום",
        "short_code": "תשלום מהיר",
        "payment_code": "הקוד לתשלום",
        "payment_code_expiration": "תקף ל - {{minutes}} דקות",
        "payment_code_expiration_till": "(עד {{time}})",
        "short_code_flow": {
            "steps": {
                "first": "מבצעים הזמנה",
                "second": "מציגים את הקוד",
                "third": "התשלום יחייב את הכרטיס הנטען"
            }
        },
        "balance": "יתרת כרטיס נטען",
        "quickLoad": "לטעינה מהירה",
        "book_subtitle": "שימרו שולחן במסעדה האהובה עליכם",
        "book_action": "הזמנת מקום",
        "pay": "תשלום",
        "pay_subtitle": "שלמו כאן בקלות עם הנייד שלכם",
        "pay_action": "תשלום בנייד",
        "quickpay_action": "תשלום מהיר",
        "default": "ברירת מחדל",
        "save": "שמירה",
        "okay": "המשך",
        "OK": "אישור",
        "close": "סגור",
        "back": "חזור",
        "continue": "המשך",
        "register": "הרשם/י",
        "registration": "הרשמה",
        "reorder_action": "הזמן שוב!",
        "location_select": "בחירת אזור",
        "my_location": "מיקום נוכחי",
        "change_location": "שינוי",
        "my_location_unavailable": "מיקום לא זמין",
        "more": "עוד",
        "km": "ק״מ",
        "mile": "מייל",
        "at": "ל",
        "until": "עד",
        "far_away": "רחוק מאוד",
        "when_to_book": "מתי תרצו לשבת",
        "choose_restaurant": "בחרו מקום",
        "book_tables_title": "שולחנות פנויים",
        "book_tables_subtitle": "לחצו על אחת המסעדות על מנת לתפוס שולחן מייד",
        "book_tables_standby_title": "רשימת המתנה",
        "book_tables_standby_subtitle": "לחצו על אחת המסעדות על מנת להתווסף לרשימת ההמתנה",
        "booking_description": "רק תגידו מתי ונמצא עבורכם שולחן!",
        "website": "אתר הבית",
        "i_approve_the": "קראתי ואישרתי את",
        "privacy_policy": "מדיניות הפרטיות",
        "club_terms": "תקנון המועדון",
        "terms_and_conditions": "תנאי שימוש",
        "approve_commercials": "בעת מילוי טופס הצטרפות זה הנני מאשר/ת למועדון לשלוח לי חומרים פרסומיים מעת לעת באמצעות מסרונים.",
        "approve_sms": "קבלת מסרים שיווקים באמצעות מסרונים",
        "approve_mail": "קבלת מסרים שיווקים באמצעות דואר אלקטרוני",
        "account_removal": "הסרת חשבון",
        "remove_account": "הסרת חשבון",
        "and": "ו",
        "around_you": "סביב מיקומך הנוכחי",
        "today": "היום",
        "redirect_to_device_settings": "מעבר להגדרות המכשיר",
        "next_time": "פעם אחרת",
        "redirect_to_profile": "עבור לפרופיל",
        "understood": "הבנתי",
        "delivery_or_takeway": "משלוח או איסוף עצמי?",
        "order_type_title": "משלוח או לקחת?",
        "order_type_description": "אנא בחרו את העדפתכם.",
        "delivery": "משלוח",
        "takeaway": "איסוף עצמי",
        "takeaway_or_delivery_screen_reader": "ַבְּחִירַת אִיסוּף עַצְמִי אוֹ מִשְלוֹח",
        "seated": "ישיבה",
        "house": "בית",
        "office": "משרד",
        "apartment": "דירה",
        "entrance": "כניסה",
        "floor": "קומה",
        "no_addresses": "המתינו",
        "address_type": "סוג כתובת",
        "please_select_type": "נא לבחור סוג",
        "address_not_found": "לא נמצאה כתובת",
        "address_title": "כתובת למשלוח",
        "address_description": "אנא בחרו את כתובתכם.",
        "please_type_address_short": "הוספת כתובת חדשה",
        "please_type_address_long": "הקלידו מקום, עיר או כתובת מלאה",
        "please_type_delivery_address_short": "הוספת כתובת למשלוח",
        "please_type_delivery_address_long": "הקלידו כתובת מלאה כולל מספר בית",
        "please_type_address_full": "הקלידו כתובת מלאה",
        "new_address": "כתובת חדשה",
        "saved_address": "כתובות שנבחרו בעבר",
        "address_placeholder": "רחוב ומספר, עיר",
        "business_invite_restaurant_owner": "Tabit",
        "business_invite_restaurant_owner_screen_reader": "לְאָתָר הָחֶבְרָה, טָאבִּיט",
        "business_invite_join_us": "לאתר החברה",
        "business_invite_restaurant_owner_seo": "יש לך מסעדה?",
        "business_invite_join_us_seo": "זה בשבילך",
        "error_title": "רגע...",
        "error_general": "ארעה שגיאה לא ידועה. אנא נסו שוב.",
        "error_invalid_cards_for_auto_attach": "ארעה שגיאה, אנא צרו קשר עם המסעדה",
        "error_invalid_sign_in_credentials": "נראה שהאימייל או הסיסמא אינם נכונים או לא שייכים למספר הטלפון שהזנת. אנא נסו שוב.",
        "error_invalid_touchid": "לא הצלחנו לאמת את הזהות שלך בעזרת הפנים או טביעת האצבע. אנא הכנסו עם מספר הטלפון הנייד.",
        "error_invalid_sign_in_email_already_exists": "אימייל זה קיים במערכת. עליך להכניס אימייל אחר.",
        "error_invalid_load_sites": "ארעה שגיאה בטעינת המסעדות... אנא נסו שוב.",
        "error_client_credentials": "ארעה שגיאה... אנא נסו שוב.",
        "error_cg_wallet_credentials": "פרטי הכרטיס שגויים... אנא נסו שוב.",
        "error_sign_up": "ארעה שגיאה ברישום המשתמש... אנא נסו שוב.",
        "error_sign_up_user_already_exists": "נראה שמשתמש עם אותו אימייל כבר קיים. אנא נסו להכנס בעזרת שם המשתמש והסיסמא שלכם.",
        "error_reset_password_missing_email": "אנא מלאו את כתובת הדואר האלקטרוני שלכם על מנת לחדש את הסיסמא.",
        "invalid_email_entered": "כתובת הדואר אלקטרוני שהוזנה אינה חוקית",
        "error_reset_password": "נראה שהאימייל שלכם אינו תקין או שאינו קיים במערכת. אנא נסו שוב או הרשמו מחדש.",
        "error_full_address_required": "יש להקליד כתובת מלאה כולל מספר בית",
        "error_invalid_address": "הכתובת שנבחרה לא תקינה, יש לבחור כתובת אחרת",
        "confirmation_title": "אישור",
        "confirmation_password_reset": "אימייל עם הוראות כיצד לחדש את הסיסמא נשלח לתיבה שלכם.",
        "main": "ראשי",
        "login": "התחבר",
        "logout": "התנתק",
        "name": "שם",
        "first_name": "שם פרטי",
        "last_name": "משפחה",
        "phone": "טלפון",
        "birthDate": "תאריך לידה",
        "anniversary": "תאריך יום נישואים",
        "send": "שלח",
        "sending": "שליחה",
        "enter_code": "קוד אימות",
        "verification_code": "קוד אימות",
        "verification_code_message": "קוד האימות החד פעמי שלכם: {{pincode}}",
        "confirm_code": "אישור",
        "did_not_arrive_resend": "שלחו לי שוב",
        "resent": "שלחנו שוב...",
        "email": "דוא״ל",
        "password": "סיסמא",
        "forgot_your_password": "שכחתי את הסיסמא",
        "continue_with_email": "הכנס עם דוא״ל",
        "or_continue_with": "או המשך עם",
        "sign_in": "כניסה",
        "sign_up": "לקוח חדש? הרשם/י",
        "facebook": "Facebook",
        "google": "Google",
        "search": "חיפוש",
        "restaurants_search": "חיפוש מסעדות",
        "occasions_search": "חיפוש אירועים",
        "searching": "מחפש",
        "categories": "קטגוריות",
        "tags": "תגיות",
        "ratings": "דירוג",
        "price": "מחיר",
        "services": "שירותים",
        "auth_touch": "זיהוי פנים / אצבע",
        "style": "סגנון",
        "accessibility": "נגישות",
        "mood": "מצב רוח",
        "kosher": "כשר",
        "collective": "הרכב",
        "events": "אירועים",
        "specials": "מאפיינים מיוחדים",
        "suitable": "מתאים ל",
        "by_date": "לפי תאריך",
        "by_restaurant": "לפי מסעדה",
        "restaurant_type": "סוג מסעדה",
        "tabit_restaurants": "מסעדות Tabit",
        "about_the_restaurant": "על המסעדה",
        "articles": "מאמרים",
        "regulations": "תקנון",
        "paid": "שולם",
        "on_the_house": "על חשבון הבית",
        "to_main_page": "לעמוד הראשי",
        "input_pay_amount": "הזן סכום לתשלום",
        "choose_payment": "בחרו אמצעי תשלום",
        "show_code_to_staff": "הצג את הקוד לצוות",
        "code_valid_time_1": "תוקף הקוד 5 דקות. החיוב יתבצע רק",
        "code_valid_time_2": "לאחר אישור הקוד ע”י הצוות.",
        "user_reviews": "חוות דעת",
        "user_review_hey": "הי",
        "user_review_end": "סיום",
        "user_review_thanks": "תודה",
        "user_review_thanks_for_sharing": "תודה ששיתפת אותנו",
        "user_review_waiting_to_hear_from_you": "מחכים לשמוע ממך",
        "user_review_what_did_you_like": "מה עשה לך את זה?",
        "user_review_comment": "נשמח לשמוע מה יש לך לומר",
        "user_review_positive_thank_you_description": "כבר מחכים לפגישה הבאה שלנו!",
        "user_review_negative_thank_you_description": "מקווים לראותך שוב ולהצליח יותר",
        "user_review_add_comment": "ספרו לנו עוד",
        "user_review_food": "אוכל",
        "user_review_service": "שירות",
        "user_review_package": "אריזה",
        "user_review_times": "זמנים",
        "user_review_order_process": "תהליך הזמנה",
        "user_review_atmosphere": "אווירה",
        "user_review_perfect": "מושלם",
        "user_review_ok": "אחלה",
        "user_review_plausible": "סביר",
        "user_review_expected_more": "ציפיתי ליותר",
        "user_review_bad": "לא משהו",
        "user_review_add_a_review": "הוסיפו חוות דעת",
        "user_review_how_was_it": "איך היה?",
        "user_review_sorry_to_hear": "מצטערים לשמוע...",
        "user_review_what_to_improve": "נשמח לדעת במה להשתפר",
        "in_progress_message": "אתם באמצע...",
        "deletion_will_remove_all_payments": "פעולה זו תגרום למחיקת כל התשלומים",
        "sorry": "סליחה",
        "contact_us": "צור קשר",
        "were_sorry": "מצטערים",
        "notifications": {
            "got_all_notifications": "נראה שכבר התעדכנת",
            "promise_to_inform": "מבטיחים לצבוע את הפעמון בצהוב כשיהיה חדש",
            "unsupported_browser_title": "מבהירים את המצב",
            "unsupported_browser_confirm": "הבנתי",
            "unsupported_browser": "לשיפור הצפייה, מומלץ לשנות את הגדרות התצוגה למצב בהיר, או לא להשתמש בדפדפן של סמסונג"
        },
        "order_tracker": {
            "hey": "היי",
            "ETA": "צפי",
            "FEES": "עמלות",
            "CASH_DISCOUNT": "הנחת מזומן",
            "track_order": "מעקב הזמנה",
            "my_order": "ההזמנה שלי",
            "ETA_takeaway": "ההזמנה תהיה מוכנה עד",
            "ETA_delivery": "המשלוח יגיע עד",
            "actual_status": "סטטוס נוכחי",
            "status": "סטטוס",
            "order": "הזמנה",
            "order_a": "הזמנת",
            "order_is_being_loaded": "רק שניה...",
            "order_accepted": "קיבלנו את הזמנתך",
            "order_being_prepared": "מכינים את ההזמנה",
            "order_in_progress": "אנחנו על זה",
            "takeaway": "איסוף עצמי",
            "delivery": "משלוח",
            "arrived": "הגעתי",
            "track": "מעקב",
            "send": "שלח",
            "ok": "אישור",
            "order_ready": "הזמנתך מוכנה",
            "order_ready_for_delivery": "מתארגנים ליציאה",
            "order_taken": "ההזמנה נלקחה",
            "order_taken_on_its_way": "השליח בדרך אליך",
            "order_delivery_delivered": "בתאבון! ההזמנה נמסרה",
            "add_review": "הוסיפו חוות דעת",
            "your_order_not_found": "הזמנתך לא נמצאה",
            "hey_im_here": "הי, אני כאן!",
            "im_here_explanation": "כשתגיעו, נשמח לדעת -",
            "how_can_we_find_you": "איך נוכל למצוא אותך?",
            "car_information": "פרטי רכב",
            "instructions_accepted": "תודה על העדכון :)",
            "update_notes": "עדכן הערות",
            "order_not_here": "ההזמנה שלך כבר לא פה...",
            "dont_cry_over_spilled_juice": "אבל לא בוכים על מיץ שנשפך",
            "can_we_offer_you_something_else": "אפשר להציע לך עוד משהו?",
            "im_here_confirmation_title": "אישור הגעה",
            "im_here_confirmation_message": "שנודיע למסעדה שהגעת?",
            "im_here_confirmation_hurry_message": "זה היה מהיר, בטוח שהגעת?",
            "im_here_confirm": "כן",
            "im_here_cancel": "עוד לא",
            "disclaimer_with_phone": "לכל בקשה או בירור בנוגע להזמנתך יש ליצור קשר ישירות עם {{name}} על ידי לחיצה על 📞",
            "disclaimer_without_phone": "לכל בקשה או בירור בנוגע להזמנתך יש ליצור קשר ישירות עם {{name}}"
        },
        "to_disclaimer": "לכל בקשה או בירור בנוגע להזמנתך יש ליצור קשר ישירות עם {{name}}",
        "by_phone": "בטלפון: ",
        "my_account": "האיזור שלי",
        "my_account_description": "באפשרותכם לערוך כאן את פרטיכם האישיים.",
        "personal_information": "פרטים אישיים",
        "mobile": "טלפון נייד",
        "cell": "טלפון נייד",
        "change_password": "החלף סיסמא",
        "old_password": "סיסמא ישנה",
        "new_password": "סיסמא חדשה",
        "my_wallet": "אמצעי תשלום",
        "my_wallet_info": "הגדרת אמצעי תשלום",
        "my_addresses": "כתובות",
        "my_addresses_info": "כתובות שמורות לצורך משלוחים",
        "credit_no": "כרטיס #",
        "credit_card_number": "מספר כרטיס",
        "credit_type": "סוג כרטיס",
        "credit_type_regular": "כרטיס אשראי",
        "credit_type_cbus": "סי-בוס",
        "credit_type_10bis": "תן-ביס",
        "expiration_date": "תאריך תפוגה",
        "expiration_month": "חודש",
        "expiration_year": "תוקף",
        "cvv": "3 ספרות בגב הכרטיס",
        "comments": "הערות",
        "my_preferences": "העדפות",
        "my_preferences_info": "מטבחים אהובים ורגישויות",
        "my_preferences_selected_description": "הקטגוריות וההעדפות שלכם",
        "my_preferences_description": "על מנת לעדכן את הקטגוריות או הרגישויות סמנו אותן ברשימות הבאות",
        "my_preferred_categories": "קטגוריות מועדפות",
        "my_preferred_dishes": "מנות מועדפות",
        "my_preferred_tags": "תגיות מועדפות",
        "my_preferred_allergies": "רגישויות",
        "my_history": "היסטוריית הזמנות",
        "my_history_info": "הזמנות פתוחות והיסטוריה",
        "my_history_description": "כל הביקורים וההזמנות שלכם מופיעים ברשימה הבאה",
        "search_history": "טעינת היסטוריה",
        "search_history_description": "היי, במידה וביקרת בעבר במסעדות {{descTitle}} נוכל לחפש ולטעון את היסטורית ההזמנות שלך",
        "my_benefits": "ההטבות שלי",
        "benefits_and_promotions": "מבצעים והטבות",
        "more_benefits": "הטבות נוספות",
        "my_benefits_info": "למידע על כלל ההטבות והנקודות שלך",
        "my_benefits_description": "כל ההטבות והנקודות שלך מופיעים ברשימה הבאה",
        "immediate_realization": "למימוש מיידי",
        "immediate_topup": "לטעינה מהירה לחצו כאן",
        "prePaid_realization": "בכרטיס נטען",
        "benefit_details": "פרטי הטבה",
        "valid_until": "בתוקף עד",
        "benefits_club": "מועדון הטבות",
        "brithday_benefit": "הטבת יום הולדת",
        "join_benefit": "הטבת הצטרפות",
        "renew_benefit": "הטבת חידוש",
        "anniversary_benefit": "הטבת יום נישואין",
        "general_benefit": "הטבה כללית",
        "voucher_benefit": "שובר",
        "punch_card": "כרטיסיית הטבות",
        "points_benefit": "הטבת צבירה",
        "my_favorites": "מועדפים",
        "my_recent": "לאחרונה",
        "your_recent_orders": "אז מה היה לנו לאחרונה?",
        "no_recents": "לא נמצאו הזמנות קודמות",
        "no_favorites": "אין מועדפים עדיין",
        "no_deliveries": "באסה... נראה שאין משלוחים כרגע למיקומכם",
        "future_reservations": "הזמנות מקום",
        "future_reservation": "הזמנת מקום",
        "future_reservations_and_discounts": "הזמנות עתידיות ושוברי הנחה",
        "club_benefits": "הטבות מועדון",
        "club_points": "נקודות מועדון",
        "marketplace_title": "גיפט קארד למסעדות של Tabit",
        "your_points_worth": "הנקודות שלך שוות כסף וזמינות למימוש",
        "pre_paid_available": "הכרטיס הנטען זמין למימוש מיידי",
        "pre_paid_top_up": "טעינת כרטיס",
        "realize_points": "מימוש נקודות",
        "your_tracked_order": "למעקב אחר הזמנתך",
        "quickpay": "תשלום מהיר",
        "issues-feedback-link": "נשמח לשמוע את דעתכם",
        "latest_orders_title": "בא לי להזמין שוב!",
        "latest_orders_subtitle": "ההזמנות והביקורים האחרונים שלכם",
        "sites_near_me_title": "המסעדות הקרובות אליכם",
        "sites_near_me_subtitle": "כל המסעדות הכי שוות - ממש פה!",
        "redirect_to_my_history": "לרשימת המלאה של כל ההזמנות האחרונות",
        "HOUR": "שעה",
        "DATE": "תאריך",
        "add_pm": "הוספת אמצעי תשלום",
        "scan_pm": "סריקת כרטיס",
        "add_address": "שמור כתובת",
        "my_recent_addresses": "הכתובות האחרונות שלי",
        "my_default_addresses": "כתובת מועדפת",
        "please_confirm": "רגע...",
        "please_note": "רגע...",
        "just_a_moment": "רגע...",
        "dont_worry": "אל דאגה",
        "please_enter_value": "אנא הזן ערך",
        "please_enter_location_value": "יש להזין תיאור מיקום",
        "are_you_sure": "האם אתה בטוח?",
        "delete_account": "להסיר את החשבון?",
        "accept": "אישור",
        "Cancel": "ביטול",
        "update_account": "עדכון",
        "your rank": "הדרוג שלכם",
        "you_have": "יש לך",
        "accumulated": "צברת",
        "points": "הטבת צבירה",
        "prepaid_points": "נקודות ששולמו מראש",
        "recent_actions": "פעולות אחרונות",
        "view_bill": "חשבון",
        "goto-rest-page": "עבור לעמוד המסעדה",
        "photos": "תמונות",
        "openning_houres": "שעות פתיחה",
        "open_now": "פתוח כעת",
        "closed_now": "סגור כעת",
        "will_be_open_at": "יפתח בשעה",
        "new_in_tabit": "חדש ב-TABIT",
        "rank": "דרוג",
        "near_me": "קרוב אלי",
        "extra_services": "שירותים נוספים",
        "near_me_pay_more_title": "אולי התבלבלנו? מסעדות בסביבה",
        "kitchens": "מטבחים",
        "cuisine": "מטבחים",
        "slide_down_to_close": "החלק למטה על מנת לסגור",
        "Open": "פתוח כעת",
        "Open Now": "פתוח כעת",
        "Closed": "סגור כעת",
        "Closed Now": "סגור כעת",
        "profile": "פרופיל אישי",
        "update_details": "השלמת פרטים",
        "reload_app": "טען עמוד מחדש",
        "version": "גרסא",
        "closed_for_day": "סגור להיום",
        "missing_site_description": "חסר תאור למסעדה. מסמלץ...",
        "show_more_sites": "הצג מסעדות נוספות",
        "show_more": "הצג עוד",
        "all_sites": "כל המסעדות",
        "to_all_sites_title": "מחפשים משהו ספציפי?",
        "to_all_sites": "לכל המסעדות",
        "enter_order_no": "הזן קוד הזמנה",
        "ORDER_DATE": "תאריך הזמנה",
        "ORDER_TIME": "זמן הזמנה",
        "preparing-food": "ברגע שיתווספו מנות להזמנה ניתן יהיה לראות אותם כאן",
        "ADD_GRATUITY_TO_ORDER": "טיפ",
        "AMOUNT_TO_PAY": "סכום לתשלום",
        "PAYUP": "המשך לתשלום",
        "TABLE_BILL": "חשבון לשולחן",
        "WAITER": "מלצר",
        "PAY_WITH": "שלם באמצעות",
        "PAY_WITH_WALLET": "שלם באמצעות הארנק שלך",
        "SELECT_ALTERNATE_PYMENT": "בחרו באמצעי תשלום אחר",
        "PAY": "שלם",
        "clear": "נקה",
        "please_enter_signature": "לאישור תשלום חתום כאן",
        "notes": "הערות",
        "id_card": "תעודת זהות",
        "next": "המשך",
        "press here": "לחץ כאן",
        "day": "יום",
        "month": "חודש",
        "year": "שנה",
        "preference": "העדפה",
        "single_payer": "משלם יחיד",
        "partial_payment": "תשלום חלקי",
        "split_payment_by": "חלוקת חשבון לפי",
        "other": "אחר",
        "gratuity": "טיפ",
        "order_balance": "שארית תשלום",
        "without_gratuity": "ללא טיפ",
        "select_items": "בחר פריטים",
        "pay_by_items": "תשלום לפי פריט",
        "pay_by_items_title": "אני רוצה לשלם על...",
        "area": "איזור",
        "GRATUITY_AMOUNT": "סכום הטיפ",
        "ENTER_CVV": "הזן CVV",
        "PLEASE_ENTER_CVV": "אנא הזן CVV",
        "ENTER_CVV_MESSAGE": "הזן CVV לכרטיס: {{card}}",
        "SAVE_ACCOUNT_PAYMENT_INFO": "שמירת אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
        "CAN_TD": "הזמנת משלוח",
        "otcSeated": "דלפק",
        "expiration": "תוקף",
        "PASS_ORDER": "חולקים חשבון?",
        "PASS_ORDER_TITLE": "העבר לחבר",
        "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "תשלום נוסף במכשיר זה",
        "PASS_ORDER_BY_PHONE_MESSAGE": "להעברת הקישור בהודעת טקסט יש להכניס מספר טלפון",
        "PASS_ORDER_BY_PHONE_CAPTION": "מספר סלולרי",
        "PASS_ORDER_BY_PHONE_SEND": "שלח הזמנה",
        "PASS_ORDER_BY_PHONE_QR_MESSAGE": "או לסרוק את הברקוד",
        "PASS_ORDER_SENT_SUCCESS": "ההזמנה נשלחה בהצלחה",
        "CANNOT_SEND_ORDER_BY_SMS": "אנו מתנצלים, אך כרגע לא ניתן לשלוח את הקישור.",
        "CONTINUE_WITHOUT_GRATUITY": "המשך ללא טיפ",
        "PERFORMING_PAYMENT": "מבצע תשלום...",
        "FINALIZING_ORDER": "עובדים על זה...\nבבקשה לא לסגור את העמוד",
        "PLEASE_WAIT": "אנא המתן...",
        "WERE_ON_IT": "אנחנו על זה...",
        "LOREM_SMALL": "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף",
        "reserve_a_place_at": "הזמנת מקום ב",
        "full-payment": "תשלום מלא",
        "goto-tip": "הוספת טיפ",
        "partial-payment": "תשלום חלקי",
        "goto-payment": "תשלום",
        "add-items": "הוספת פריטים",
        "add-to-order": "הוספה להזמנה",
        "send-order": "שליחת הזמנה",
        "add-to-order-with-amount": "הוספה להזמנה {{amount}}",
        "back-to-order": "חזרה להזמנה",
        "ID_CARD": "תעודת זהות של בעל הכרטיס",
        "ENTER_IDCARD": "הזן תעודת זהות",
        "ENTER_IDCARD_MESSAGE": "הזן תעודת זהות לכרטיס: {{card}}",
        "got_it": "הבנתי",
        "confirm_exit": "לצאת? בטוח?",
        "resart_order_confirm": "המנות שבחרת לא ישמרו",
        "events_and_benefits": "אירועים והטבות",
        "marketplace_main_title": "גיפט קארד למסעדות של Tabit",
        "date": "תאריך",
        "to_restaurant": "למסעדה",
        "sites_list": "רשימת סניפים",
        "sites_nearby": "מסעדות קרובות",
        "not_available": "לא זמין",
        "not_available_now": "לא זמין כעת",
        "not_available_to_this_address": "לא זמין לכתובת זו",
        "available_from": "החל מ- ",
        "preorder": "הזמנה מוקדמת",
        "future_order": "הזמנה עתידית",
        "availability": "זמינות",
        "only_available": "זמין כעת",
        "future_order_standby": "הזמנה ברשימת המתנה",
        "future_order_pending": "הזמנה ממתינה לאישור",
        "order_details": "פרטי הזמנה",
        "order_in_progress": "להתחיל מחדש את ההזמנה?",
        "order_end": "האם להתחיל הזמנה חדשה?",
        "continue_order": "המשך בהזמנה",
        "payment_in_progress": "תשלום",
        "payment_order": "המשך בתשלום",
        "cancel_order": "כן, לצאת",
        "confirm_cancel_order": "כן, להתחיל מחדש",
        "confirm_continue_order": "להמשיך מאותו מקום",
        "payment_screen": "תשלום באמצעות סריקת ברקוד",
        "short-code_screen": "תשלום מהיר בקופה",
        "extended_results": "תוצאות נוספות",
        "book_a_place": "הזמן מקום",
        "app_smart_banner_title": "למה ככה בדפדפן?",
        "app_smart_banner_body": "באפליקציה יותר כיף :)",
        "try_again": "נסו שוב",
        "wait_on": "המתינו",
        "change_language": " החלפת שפה",
        "pepper_pay": {
            "15_bonus": "15 ₪ הטבה",
            "pepper_bonus": "הטבת PAY",
            "pepper_pay_payers": "למשלמים באפליקציית PAY!",
            "pepper_pay_realization": "למימוש ההטבה שלמו ב- Pay בעמוד התשלום",
            "takeout_delivery_above_amount_tip_not_included": "תקף בתשלום מעל 50 ₪ (לא כולל דמי משלוח) בהזמנת משלוח / איסוף עצמי",
            "look_for_pay_mark": "חפשו מסעדות עם הסימון PAY (בכפוף ל"
        },
        "BENEFIT_TYPE": {
            "points": "הטבת צבירה",
            "Other": "הטבה",
            "BirthdayBenefit": "הטבת יום הולדת",
            "AnniversaryBenefit": "הטבת יום נישואין",
            "JoinBenefit": "הטבת הצטרפות",
            "PointsBenefit": "הטבת צבירה",
            "RenewBenefit": "הטבת חידוש",
            "Vouchers": "שובר",
            "Benefit": "הטבה",
            "PunchCard": "כרטיסיית הטבות",
            "PointsStore": "חנות הטבות",
            "PointsStoreBasket": "מימוש נקודות",
        },
        "ROUTE": {
            "book-a-table": "הזמן-מקום",
            "deliveries": "משלוחים",
            "restaurants": "מסעדות",
            "new-at-tabit": "מסעדות-חדשות-טאביט",
            "nearest-restaurants": "מסעדות-קרובות-אלי",
            "events": "אירועים",
            "reservation": "הזמנת-מקום",
        },
        "AVS": {
            "billing_address": "כתובת לחיוב",
            "state": "מדינה",
            "select_state": "בחרו מדינה",
            "city": "עיר",
            "address": "כתובת",
            "zip_code": "מיקוד",
        },
        "TGM": {
            "FIND_ME_APLACE": "מצא לי מקום",
            "FIND_ME_ARESTAURANT": "מצא לי מסעדה",
            "DINERS": "אורחים",
            "Day": "יום",
            "DATE": "תאריך",
            "TIME": "שעה",
            "BOOK_TITLE": "פרטי הזמנה",
            "ONE_DINER": "אורח אחד",
            "DETAILS_TITLE": "להשלמת התהליך אנא מלאו את הפרטים הבאים.",
            "SUCCESS_TITLE": "ההזמנה התקבלה בהצלחה",
            "SUCCESS_TITLE_STANDBY": "שימו לב, הוספנו אתכם לרשימת המתנה. ניצור אתכם קשר במידה ויתפנה מקום.",
            "SUCCESS_TITLE_PENDING_APPROVAL": "שימו לב, הזמנתכם טרם אושרה. ניצור אתכם קשר בהקדם על מנת לאשר את הזמנתכם.",
            "DEPOSIT_TITLE": "על מנת לסיים את תהליך שמירת ההזמנה - אנא מלאו את פרטי האשראי לצורך פיקדון.",
            "LICENSE_AGREEMENT": "ע\"י לחיצה על \"שמור\" הנכם מסכימים לתנאי השימוש ומדיניות הפרטיות. לפרטים לחצו כאן ⟩",
            "SAVE": "שמירה",
            "standby": "רשימת המתנה",
            "instant_booking": "אישור מיידי",
            "pending_approval": "מותנה באישור",
            "form_description_standby": "להוספה לרשימת המתנה אנא מלאו את הפרטים הבאים.",
            "form_description_pending_approval": "הזמנה זו מותנת באישור המסעדה. להשלמת התהליך אנא מלאו את הפרטים הבאים.",
            "standby_list": "רשימת המתנה",
            "missing_cc_details": "ממתין לפרטי אשראי",
            "missing_deposit_payment": "ממתין לתשלום מקדמה",
        },
        "ORDER_SITES_BY": {
            "tabit": "דרוג Tabit",
            "distance": "מרחק",
            "name": "שם"
        },
        "SERVICES": {
            "order": "הזמנה מקוונת",
            "book": "הזמנת מקום",
            "quickpay": "תשלום מהיר"
        },
        "SERVICE_BUTTON": {
            "home": "ראשי",
            "order": "משלוח/TA",
            "book": "הזמנת מקום",
            "delivery": "משלוח",
            "takeaway": "איסוף עצמי",
            "pay": "סריקת QR",
            "pay_generic": "תשלום",
            "profile": "פרופיל",
            "future_order": "הזמנה עתידית",
            "eatin": "הזמן ושב",
            "waitingList": "רשימת המתנה",
        },
        "MESSAGES": {
            "EXTRA_PAGE_ERROR": "יש לבחור כדי להמשיך",
            "GENERIC_GET_CUSTOMER_ERROR": "אירעה שגיאה בעת ההזדהות, יש לנסות שוב או לפנות למסעדה",
            "LOADING_INITIAL_SITES": "מחפשים את החוויה הבאה שלך",
            "EMAIL_MISSING": "היי, כיף שהצטרפת :)\n כדי להינות מכל התכנים נשאר רק להוסיף את כתובת המייל לפרופיל שלך.",
            "EMAIL_STILL_MISSING": "בשביל להשלים את ההרשמה נשאר להוסיף כתובת מייל",
            "LOADING_SITES": "רגע... יש עוד",
            "NO_MORE_RESULTS": "זהו... אין יותר תוצאות",
            "NO_ORGS_RESULTS": "לא הצלחנו לאתר תוצאות מתאימות",
            "NO_ORGS_RESULTS_FOR_THIS_ADDRESS": "לא נמצאו מסעדות עבור כתובת זו",
            "GENERAL_ERROR": "אירעה שגיאה בלתי צפויה - אנא נסו שנית",
            "NO_INTERNET": "נראה שאין לכם חיבור לאינטרנט",
            "CONFIRM_SIGNOUT": "להתנתק מהחשבון?",
            "sign_in_by_phone_message": "היי! כיף לראות אותך :)",
            "sign_in_by_phone_description": "רק אימות זריז של הטלפון ונמשיך",
            "enter_code_message": "שלחנו לך קוד אימות ל- ",
            "sign_in_by_email_message": "היי, אנא הכנסו עם אימייל וסיסמא",
            "wl_sign_up_title": "הרשמה למועדון",
            "sign_up_message": "עוד טיפה וסיימנו",
            "saved_successfully": "נשמר בהצלחה",
            "deleted_successfully": "נמחק בהצלחה",
            "error_getting_bill": "ארעה שגיאה בהבאת החשבון",
            "service_not_available_for_site": "השירות אינו פעיל כרגע במסעדה זו.",
            "Q_REMOVE_CARD": "למחוק את הכרטיס מהארנק?",
            "NO_PMS_FOUND": "לא נמצאו אמצעי תשלום בחשבונך",
            "NO_SITE_PMS_FOUND": "לא נמצאו אמצעי תשלום למסעדה",
            "NO_RESTS_FOUND": "לא נמצאו מסעדות לחיפוש המבוקש",
            "NO_PMS_FOUND_ADD": "לא נמצאו אמצעי תשלום מתאימים בחשבונך. לחץ על הוסף אמצעי תשלום על מנת לאפשר את השירות",
            "SIGNIN-EMAIL-EX": "הזן את כתובת המייל איתה נרשמת בעבר",
            "SIGNIN-EMAIL-EX-HELP": "קוד אימות ישלח לטלפון הנייד המשויך לכתובת המייל",
            "SIGNIN-CHALLENGE-EX": "קוד אימות נשלח לטלפון",
            "SIGNIN-CHALLENGE-EX-HELP": "לא קיבלת את הקוד? <b>שלחו שוב</b>",
            "SAVE_INFO": "שמור פרטי קשר ותשלום",
            "SAVE_INFO_END": "הרשמו להזמנה קלה ומהירה בעתיד",
            "SAVE_INFO_PAYMENT": "שמור פרטי תשלום",
            "SIGNIN_CHALLENGE": "קוד תשובה נשלח אליך באמצעות SMS, אנא הזן אותו ולחץ על התחבר",
            "PAYMENT_CHALLENGE": "קוד אימות נשלח ל\n{{phone}}\nבאמצעות SMS",
            "PAYMENT_ENTER_PHONE": "רק אימות זריז של הטלפון ונמשיך",
            "PAYMENT_OTP": "שלחנו לך קוד אימות ל\n {{phone}}",
            "PAYMENT_CHALLENGE_RETRY": "הקוד שהזנת שגוי!\nקוד אימות נוסף נשלח באמצעות SMS",
            "SIGNIN_VALIDATION": {
                "incorrect-phone-number": "טעות בהקלדת המספר",
            },
            "TRANSACTION_SUCCESS": "תשלום בוצע בהצלחה",
            "ORDER_CLOSED": "ההזמנה שולמה במלואה",
            "INVALID_ORDER": "הזמנה לא נמצאה",
            "PAYMENT_FAILED": "התשלום נכשל",
            "INVALID_PIN": "הקוד שהוזן אינו תואם",
            "SIGN_FOR_PAYMENT_CONFIRM": "לאישור תשלום חתום כאן",
            "SIGNATURE_MANDATORY": "על מנת שנוכל לאשר את אמצעי התשלום, חובה לחתום!",
            "WAITER_NOTIFIED": "הצוות שלנו קיבל אות קריאה ויגעו אליך בקרוב!",
            "HAPPY": "נשמח לראותכם שוב",
            "Cancel": "Cancel",
            "INVOICE_SENT": "חשבונית נשלחה אליך במייל",
            "ITEM_ADDED_TO_BASKET": "פריט הוסף לסל",
            "NO_BASKET_ITEMS": "אין פריטים בסל",
            "CONFIRM_ORDER_FROM_BRANCH": "בחרת להזמין מסניף: {{branch}}",
            "FINDING_THE_BRANCH_NEAREST_TO_YOU": "מאתר את הסניף הקרוב לביתכם",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "לא נמצא סניף שמשרת את הכתובת: {{address}}",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "כרגע אנחנו שולחים לכתובת זו הזמנות עתידיות בלבד",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "כרגע אנחנו שולחים לכתובת זו הזמנות מיידיות בלבד",
            "NO_BRANCH_SERVING_ADDRESS": "המסעדה לא מבצעת משלוחים לכתובת זאת",
            "CONFIRM_RESET_ORDER": "האם אתה בטוח שברצונך להתחיל את ההזמנה מחדש?",
            "DO_YOU_APPROVE_CONTRACT": "יש אישור לתנאי השימוש?",
            "DO_YOU_APPROVE_CONTRACT_TITLE": "שנייה לפני שממשיכים",
            "CONDITIONS_PRIMARY_BUTTON_TEXT": "כן",
            "CONDITIONS_SECONDARY_BUTTON_TEXT": "יציאה",
            "RESTORE_PASSWORD_1": "אנא הזן את ה Email בו השתמשת להזדהות.",
            "SIGNIN_SUCCESS": "ברוכים השבים {{name}}, התחברת בהצלחה למערכת",
            "FORGOTPASS_SUCCESS": "הוראות התחברות נשלחו לכתובת ה Email שהזנתם",
            "ACCOUNT_UPDATE_SUCCESS": "חשבונך עודכן בהצלחה!",
            "TITLE_HUNGRY": "מצטערים לבאס",
            "BRANCH_DISABLED_NOW": "Service is closed right now. {{t}}.",
            "BRANCH_DISABLED_NOW_ERROR": "But this service has closed. It's available between {{t}}.",
            "BRANCH_CLOSED_TODAY": "שירות זה אינו פעיל היום",
            "BRANCH_CLOSED_NOW": "השירות יפתח בשעה {{t}}, אך אל דאגה,\nניתן לבצע הזמנה מוקדמת אשר תיכנס להכנה כשהשירות יפתח.",
            "MIN-ORDER-PRICE": "מינימום הזמנה למשלוח הוא {{t}}\n(לא כולל דמי משלוח).\nיש להוסיף פריטים להזמנה",
            "SELECT-LOYALTY-SERVICE": "על מנת להתקדם בהזמנה, יש להזין את כרטיס המועדון",
            "SERVER_ERROR": "אירעה שגיאה בעיבוד הבקשה,\nאנא צרו קשר עם המסעדה",
            "PAYMENT_ERROR": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים.",
            "PAYMENT_ERROR_TITLE": "שגיאת תשלום",
            "PAYMENT_LOCKED": "ההזמנה ננעלה ע\"י משתמש אחר. אנא נסו שוב",
            "REGISTER_SUCCESS": "נרשמת בהצלחה",
            "PASSWORD_RESET_SUCCESS": "סיסמא אופסה בהצלחה",
            "CUSTOMER_ENTITYALREADYEXISTS": "לקוח עם האימייל הנתון קיים כבר במערכת",
            "PHONE_VERIFICATION_FAILED": "נראה שהקוד אינו נכון או שפג תוקפו. אנא נסו שוב.",
            "PHONE_VERIFICATION_FAILED_TITLE": "רגע...",
            "TOO_MANY_CODE_REQUESTS": "חשבונך נחסם באופן זמני מפני שהגעת למספר הניסיונות המרבי המותר.\nיש לנסות שוב מאוחר יותר.",
            "VOUCHER_ALREADY_USED": "נראה שהקופון כבר מומש",
            "SERVICE_UNAVAILABLE": "שירות זה אינו פעיל כעת, אנא נסו שוב מאוחר יותר",
            "MOD_CANT_SELECT_MORE_THEN": "יש לבחור לא יותר מ {{max}} תוספות!",
            "MOD_PLEASE_SELECT_AT_LEAST": "יש לבחור לפחות {{min}} תוספות!",
            "MOD_PLEASE_SELECT_AT_LEAST_ONE": "יש לבחור לפחות תוספת אחת!",
            "CANNOT_PAY_TRAINING_MODE": "מערכת במצב תרגול. לא ניתן לסיים את העסקה. אנא התקשרו למסעדה\n {{phone}}",
            "ORDER_CANNOT_BE_PROCESSED": "ארעה שגיאה בביצוע התשלום, ניתן ליצור קשר טלפוני עם המסעדה.\n (שגיאה: {{code}})",
            "TIMEZONE_CHANGED": "נראה שיש בעיה בשעון של המכשיר שלך. כדי להבטיח חוויית שימוש מיטבית, יש להתחיל את ההזמנה מחדש. תודה על ההבנה!",
            "ARE_YOU_SURE": "האם אתה בטוח?",
            "DISABLED_REGION_TITLE": "שירות המשלוחים לא זמין",
            "DISABLED_REGION_MESSAGE": "אנחנו לא מגיעים כרגע לכתובת זו.\nאנא נסו שוב מאוחר יותר",
            "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "כרגע אנחנו שולחים לכתובת זו הזמנות מיידיות בלבד",
            "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "כרגע אנחנו שולחים לכתובת זו הזמנות עתידיות בלבד",
            "ENABLED_REGION_TIME_MESSAGE": "נחזור לפעילות בין השעות {{t}}",
            "ENABLED_REGION_DAY_MESSAGE": "נחזור לפעילות {{d}}, בין השעות {{t}}",
            "OUT_OF_REACH": "מצטערים לבאס",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "אחד או יותר מהתשלומים שהזנת נכשלו.אנא התקשרו למסעדה להשלמת ההזמנה.\n {{phone}}",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "אחד או יותר מהתשלומים שהזנת נכשלו. נא לפנות למלצר/ית להמשך טיפול",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "לתשומת ליבך - אחד או יותר מאמצעי התשלום שהוזנו אינם תקינים",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "יש ליצור קשר עם המסעדה בטלפון {{phone}}",
            "SELECTTABLE_INFO": "אנא הזן את מספר השולחן אליו אתה רוצה להזמין",
            "ENTERORDER_INFO": "אנא הזן את מספר ההזמנה עליה אתה רוצה לשלם.",
            "INVALID_TABLE": "השולחן לא קיים",
            "ORDER_RELOAD_NEEDED": "נא לשים לב! הסכום לתשלום השתנה!",
            "ORDER_NEWPAYMENT_NEEDED": "לתשומת ליבך, היתרה לתשלום השתנתה. יש להזין סכום חדש",
            "ORDER_HELD_BY_SERVER": "אנו מתנצלים, אך לא ניתן לשלם ברגע זה. נא לנסות שוב בעוד כמה דקות",
            "SESSION_VALIDATION_FAILED": "אירעה שגיאה בעיבוד הבקשה, יש לרענן את העמוד ולנסות שוב",
            "CREDIT_CARD_3DS_DISCLAIMER": "יתכן שתידרשו להזין קוד אימות שישלח אליכם מחברת האשראי במסרון או באימייל.",
            "PAY_ORDER_CHANGED": "ההזמנה השתנתה! אנא עבור על ההזמנה.",
            "PAYMENT_ACCEPTED_SUCCESSFULLY": "התשלום בוצע בהצלחה",
            "PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT": "תשלום של {{amount}} התקבל בהצלחה",
            "PAYMENT_INTENT_EXPIRED": "תוקף התשלום פג!",
            "CARD_EXPIRED": "פג תוקף",
            "PAYMENT_DELETED_SUCCESSFULLY": "התשלום נמחק בהצלחה",
            "PAYMENT_ACCEPTED": "התשלום בוצע בהצלחה",
            "OUT_OF_STOCK": "המנות הבאות אזלו מהמלאי במהלך ההזמנה:\n {{items}}\n נשמח להציע לך אפשרויות אחרות מהתפריט",
            "GRATUITY_CASH_DISABLE": "לא ניתן לשלם במזומן כאשר קיים טיפ",
            "ORDER_ITEMS_UNAVALABLE": "לצערנו הפריטים הבאים לא זמינים כרגע להזמנה:",
            "ORDER_TIMEOUT": "יש להתחיל את ההזמנה מחדש",
            "ORDER_TIMEOUT_TITLE": "הזמן עבר",
            "ORDER_DELIVERY_ERROR_TITLE": "משהו השתבש",
            "ORDER_DELIVERY_ERROR_MESSAGE": "הכתובת למשלוח שהזנת לא נשמרה ונאלץ להתחיל מחדש את ההזמנה. מצטערים על אי הנוחות",
            "LOCAL_TIME_HAS_BEEN_CHANGED": "בוצע שינוי בשעון המקומי במכשירך",
            "ORDER_TIMEOUT_LEVEL1": "אבל אל דאגה יש לכם עוד 5 דק להשלים את ההזמנה",
            "ORDER_TIMEOUT_LEVEL1_TITLE": "התעדכנו",
            "BASKET_ERRORS_TITLE": "אוי נגמר",
            "BASKET_ERRORS": "הפריטים הבאים אזלו מהמלאי: {{errors}} עדכנו בבקשה את הזמנתכם",
            "BASKET_ERRORS_BUTTON": "עדכן",
            "PROMOTION_DISABLED_TITLE": "אוי נגמר",
            "PROMOTION_DISABLED": "המבצע לא זמין: {{errors}} הסל שלך התעדכן בהתאם",
            "PROMOTION_DISABLED_BUTTON": "עדכן",
            "CONFIRM_ADDRESS": "הכתובת שהוזנה היא:\n<b>{{address}}</b>\nהאם להמשיך?",
            "PROBLEM_IN_PAYMENT_INPUT": "ארעה שגיאה בקליטת פרטי התשלום\nנא להזין את פרטי התשלום מחדש\nבמידה והבעיה ממשיכה, נא לפנות למסעדה בטלפון: \n {{phone}}",
            "MEAL_CALC_FACTOR_DESC": "ההנחה מחושבת על כל פריט בהתאם למשקלו היחסי בהזמנה",
            "MEAL_CALC_ACTION_DESC": "יש לסמן את המנות לתשלום",
            "MEAL_CALC_TOTAL_DESC": "סה\"כ המנות אינו יכול להיות גדול מהסכום שנותר לתשלום",
            "EX_PAYMENT_ERROR": "ארעה שגיאה בהפניה לספק התשלום",
            "EX_PAYMENT_AUTHRIZATION_ERROR": "ארעה שגיאה באישור תשלום מספק",
            "LOCALITY_WO_STREETS": "לישוב {{locality}} לא מוגדרים שמות רחובות\nהאם ברצונך לבחור בישוב זה כיעד למשלוח?",
            "SERVICE_END_WARN": "שימו לב! שירות ההזמנות ייסגר בעוד {{t}} דקות. יש לסיים את ביצוע ההזמנה בזמן שנותר.",
            "JUST_SO_YOU_KNOW": "חשוב לנו לעדכן",
            "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "אישור",
            "QR_HELPER": "יש לסרוק את ה- QR",
            "INVALID_QR": "ארעה שגיאה בסריקת הQR. \n במידה וברצונך לשלם אנא הזן קוד ידני",
            "SITE_NOT_FOUND": "המסעדה המבוקשת לא נמצאה",
            "TABIT_PAY_MANUAL_ENTER": "להזנת קוד ידני ובחירת מסעדה",
            "TABIT_PAY_MANUAL_ENTER_FOR_SITE": "לתשלום עם קוד ידני",
            "PLEASE_ENABLE_CAMERA": "על מנת לסרוק QR אנא אפשר גישה למצלמה",
            "PAY_INFO": "על מנת להמשיך בתהליך התשלום יש לבחור מסעדה",
            "ENTER_ORDER_NO_INFO": "לצוות המסעדה יש קוד בשבילך",
            "AREA_NOT_SELECTED": "לא נבחר איזור",
            "PLEASE_SELECT_AREA": "אנא בחרו איזור על מנת להמשיך",
            "PLEASE_ENABLE_LOCATION": "כדי שנוכל להמליץ לך על מסעדות בהתאם למיקומך, יש להפעיל שירותי מיקום",
            "DESCRIBE_LOCATION": "תיאור המיקום",
            "USER_LOCATION_ERROR": "יש להפעיל שירותי מיקום לחוויה טובה יותר",
            "PLEASE_ENABLE_NOTIFICATIONS": "המון דברים קורים פה 🙂 התראות יאפשרו לנו לשתף אותך בכל הטוב הזה, מבטיחים לא לחפור!",
            "ENABLE_LOCATION": "נא הפעילו שירותי מיקום",
            "ENABLE_NOTIFICATIONS": "נא הפעילו התראות",
            "CONTINUE_WITHOUT_GRATUITY": "להמשיך ללא טיפ?",
            "CALL_RESTAURANT": "התקשר למסעדה",
            "NO_LOCATION": " שרותי המיקום לא פעילים!",
            "ORDER_REQUIERES_IDCARD": "סכום התשלום גבוה מידי, אנא הקטן את הסכום או פנה למלצר",
            "ERROR_CROSS_ORGS_TABLE_SEARCH": "נראה שאין לכם חיבור לאינטרנט",
            "CROSS_ORGS_TABLE_NOT_FOUND": "לא נמצאו שלחנות פנויים במסעדות פתוחות בזמן המבוקש, אנא נסו להזמין במועד אחר",
            "GRATUITY_NOT_ALLOWED_FOR_PM": "במסעדה זו לא ניתן להוסיף טיפ ב{{pm}}.\nיש לבחור אמצעי תשלום אחר או לבטל את תוספת הטיפ.",
            "GRATUITY_PA_WARNING": "במסעדה זו מקבלים טיפ ב{{pms}} בלבד",
            "FOR_EXTENDED_RESULTS_PRESS_HERE": "לתוצאות נוספות לחצו כאן",
            "EXTENDED_RESULTS_BUTTON_ADDITIONAL_INFO": "בגלל שמערכת היחסים הזו חשובה לנו, אנחנו לא מסתירים כלום. יש כאן עוד מסעדות שעושות משלוחים עם חברות אחרות. אם בא לך.",
            "THE_SERVICE_BEGIN_AT": "השירות ייפתח בשעה",
            "SERVICE_PREORDER_DONT_WORRY": "אך אל דאגה, ניתן לבצע הזמנה מוקדמת אשר תיכנס להכנה כשהשירות ייפתח.",
            "SERVICE_DOWN": "השירות אינו זמין כעת, אנא נסה שנית",
            "SUMMARY_SENT_BY_MAIL": "סיכום ההזמנה נשלח לכתובת האימייל שלך",
            "ENABLE_GRATUITY_CREDIT_ONLY": "ניתן לשלם באשראי בלבד כאשר קיים טיפ",
            "ITEM_OUTOF_STOCK": "המנה שבחרת לא נמצאת במלאי כרגע, אבל יש עוד המון אפשרויות!",
            "ITEM_OUTOF_STOCK_TITLE": "אוי, נגמר",
            "ITEM_OUTOF_STOCK_BUTTON": "חזרה לתפריט",
            "MAX_CAT_OFFERS_CONT": "לא ניתן להזמין יותר מ-{{max}} פריטים מתפריט {{target}}",
            "MAX_ORDER_OFFERS_CONT": "כבר אין מקום בסל…\nלא ניתן להזמין יותר מ-{{max}} פריטים בהזמנה",
            "MAX_SAME_DISH_COUNT": "לא ניתן להזמין יותר מ-{{max}} {{target}}",
            "ORDER_CHANGED_AFTER_HANDOFF": "שימו לב!\nההזמנה כבר נשלחה למסעדה, השינויים החדשים לא נשמרו.\nיש להתקשר למסעדה לביצוע שינוי: {{phone}}",
            "PLACE-MAP-MARKER": "מקמו את הסמן קרוב ככל האפשר למיקומכם כדי שנוכל למצוא אתכם מהר יותר",
            "LOYALTY_BENEFIT_TIMEOUT_ERROR": "היי, עבר קצת זמן...\nנצטרך לממש ההטבות שלך מחדש",
            "LOYALTY_BENEFIT_HANDOFF_ERROR": "לא ניתן לשלם עם הכרטיס שמסתיים בספרות {{num}} בהזמנה זו.\nלמידע נוסף יש לפנות למסעדה",
            "LOYALTY_BENEFIT_CONFLICT_ERROR": "אופס..\nנראה שיש בעיה או שההטבה כבר מומשה.\nאנא נסו שוב",
            "CARD_VALIDATION_MISMATCH": "Card validation mismatch",
            "CARD_VALIDATION_ACTION_NEEDED": "Needed additional action",
            "ORDER_ITEMS_VALIDATION_ERROR": "כרגע לא ניתן להזמין כמה מהפריטים שנבחרו.\n.אנא צרו קשר עם המסעדה להמשך ההזמנה",
            "SIGNIN_SISABLED": "Sign in/Registration is temporarily disabled. you can still place orders as usual by entering your contact info.",
            "CC_PAYMENT_OVERFLOW": "לא ניתן לשלם יותר מ{{amount}} ש״ח באותו הכרטיס. להמשך התשלום במסופון פיזי יש לפנות לצוות.",
            "HOTEL_NO_GUEST_WITH_PHONE": "לא נמצא מספר טלפון המקושר לחדר",
            "HOTEL_ROOM_NOT_FOUND": "חדר לא נמצא",
            "HOTEL_NOT_ELIGIBLE": "אין הרשאת חיוב",
            "HOTEL_REPORT_TO_DESK": "יש לפנות לקבלה",
            "HOTEL_NO_GUEST_FOUND": "לא נמצאו אורחים",
            "SELECT_MAP_LOCATION": "בחירת מיקום על מפה",
        },
        "EX_PAYMENT": {
            "dialog_title": "אישור עסקה",
            "wallet_title": "אישור כרטיס",
            "wallet-popup-title": "תהליך שמירת הכרטיס עבר בהצלחה",
            "description": "עמוד זה משמש לאישור העסקה עבור תשלום באתר. התשלום יבוצע בפועל רק לאחר השלמת התהליך בדף בית העסק. יש לאשר תחילה כי כל הפרטים בדף זה נכונים ולמלא את פרטי האשראי כנדרש.",
            "popup-title": "העסקה אושרה לביצוע",
            "form-title": "פרטי כרטיס אשראי",
            "popup-description": "התשלום בפועל יבוצע לאחר השלמת הפעולה",
            "submit-btn": "המשך",
            "clear": "איפוס",
            "credit_card_number": "מספר כרטיס",
            "expiration": "תוקף כרטיס",
            "cancel": "ביטול",
            "currency": "מטבע:",
            "transaction_details": "פרטי העסקה",
            "transaction_acknowledge_ord": "בלחיצה על הכפתור ״המשך״, אני מאשר/ת את פרטי העסקה לחיוב ומצהיר/ה כי מדיניות ביטול העסקה ידועה לי ותואמת את הוראות חוק שירותי תשלום.",
            "transaction_acknowledge_gcs": "בלחיצה על הכפתור ״לתשלום״, אני מאשר/ת את פרטי העסקה לחיוב ומצהיר/ה כי מדיניות ביטול העסקה ידועה לי ותואמת את הוראות חוק שירותי תשלום.",
            "transaction_acknowledge_rsv": "בלחיצה על הכפתור ״לאישור״, אני מאשר/ת את פרטי העסקה לחיוב ומצהיר/ה כי מדיניות ביטול העסקה ידועה לי ותואמת את הוראות חוק שירותי תשלום.",
            "total_amount": "סכום",
            "month": "חודש",
            "year": "שנה",
            "click_here": "לחצו כאן למעבר",
            "save":"שמירה",
        },
        "expayTitle": "פרטי הכרטיס נקלטו בהצלחה",
        "NATIVE_PAYMENT": {
            "error_dialog_title": "לא ניתן לעבד את בקשתך כרגע.",
            "error_dialog_description": "בחר אמצעי תשלום אחר",
            "error_dialog_no_amount_description": "כדי לשלם עם {{type}} Pay יש ללחוץ על ה-X בשורת התשלום ולנסות שוב",
        },
        "SERVER_MESSAGES": {
            "ORDER_CLOSED_PAYED": "ההזמנה לא נמצאה, ייתכן שכבר שולמה ונסגרה.",
            "OrderLockedByAnotherUser": "תשלום נכשל. תפוס על ידי משתמש אחר",
            "INVALID_USER_ORPASS": "שם משתמש או סיסמא אינם נכונים",
            "UPDATE_ACCOUNT_ERROR": "לא הצלחנו לעדכן את חשבונך",
            "EMAIL_NOT_FOUND": "Email לא נמצא במערכת",
            "EMAIL_IN_USE": "Email קיים במערכת! אנה בחרו ב Email אחר",
            "INVALID_ORDER_ID": "העסקה המבוקשת לא נמצאה",
            "INVALID_QR": "העסקה המבוקשת לא נמצאה",
            "FIREBASE_DOWN": "השירות לא זמין",
            "SHVA_OFFLINE": "נראה שיש בעיה עם חיובי אשראי כרגע.\nאנחנו ממליצים לשלם באמצעי תשלום אחר, או אם הרעב לא ממש מציק, לנסות שוב בעוד מספר דקות.",
            "PAYMENT_QUOTA_EXCEEDED": "אין מספיק יתרה בכרטיס ({{v}})",
            "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "הסכום לתשלום חורג מהסכום המאושר לחיוב בחדר. יש לבחור באמצעי תשלום אחר או לפנות לקבלה",
            "CREDIT_GUARD_EX_ERROR": "אירעה שגיאה בעיבוד הבקשה, אנא נסו שנית.",
            "EXTERNAL_PROVIDER_ERROR": "התשלום ב{{provider}} נכשל!",
            "SMS_PHONE_ERROR": "לא ניתן לשלוח הודעת סמס למספר שהוזן {{phone}}.\nיש ליצור קשר עם המסעדה",
            "MISSING_CHAIN": "רשת לא נמצאה",
            "CHAIN_DISABLED": "רשת לא פעילה",
            "NO_SITES_IN_CHAIN": "לא נמצאו אתרים ברשת",
            "NO_ACTIVE_SITES": "לא נמצאו אתרים פעילים"
        },
        "CREDIT_GUARD": {
            "ERROR_CODE_001": "הכרטיס חסום, יש לפנות לחברת האשראי (קוד 001).",
            "ERROR_CODE_002": "הכרטיס גנוב, יש לפנות לחברת האשראי (קוד 002).",
            "ERROR_CODE_003": "אירעה שגיאה, יש לפנות לחברת האשראי (קוד 003).",
            "ERROR_CODE_004": "התקבל סירוב מחברת האשראי, העסקה לא אושרה (קוד 004).",
            "ERROR_CODE_006": "תעודת זהות או שלוש ספרות בגב הכרטיס שגוים, יש לנסות שנית (קוד 006).",
            "ERROR_CODE_010": "העסקה לא מאושרת לביצוע, נדרש אימות מוגבר. יש לפנות לחברת האשראי (קוד 010).",
            "ERROR_CODE_033": "מספר הכרטיס שהוזן שגוי, יש לנסות שנית (קוד 033).",
            "ERROR_CODE_036": "הכרטיס אינו בתוקף (קוד 036).",
            "ERROR_CODE_039": "מספר הכרטיס שהוזן שגוי, יש לנסות שנית (קוד 039).",
            "ERROR_CODE_173": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 173).",
            "ERROR_CODE_200": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 200).",
            "ERROR_CODE_316": "ממשק חיצוני נכשל. יש לפנות לחברת האשראי (קוד 316).",
            "ERROR_CODE_358": "פרטים רגישים לא נמצאו בבסיס הנתונים (קוד 358).",
            "ERROR_CODE_401": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 401).",
            "ERROR_CODE_405": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 405).",
            "ERROR_CODE_414": "לא הצלחנו להעביר את התשלום, ממליצים לוודא שכל הפרטים נכונים (קוד 414).",
            "ERROR_CODE_520": "אין הרשאה לביצוע עסקה, יש לפנות לחברת האשראי (קוד 520).",
            "ERROR_CODE_599": "שירות הטוקן של מנפיק האשראי אינו זמין, יש לפנות לחברת האשראי (קוד 599).",
            "ERROR_CODE_695": "כרטיס אשראי מסוג זה אינו נתמך, יש לשלם בכרטיס אחר (קוד 695).",
            "ERROR_CODE_900": "עסקה נחסמה עקב אי התחייבות לעסקה בטוחה (קוד 900).",
        },
        "PMS": {
            "Stripe": "Stripe",
            "Braintree": "Braintree",
            "Vantiv": "Vantiv",
            "HeartLand": "HeartLand",
            "CardPoint": "CardPoint",
            "AuthorizeNet": "AuthorizeNet",
            "reminder": "הסכום שנותר לתשלום",
            "remainder": "הסכום שנותר לתשלום",
            "remainder_wallet_option": "יתרה",
            "prepaidBalance": "כרטיס נטען",
            "points": "הטבת צבירה",
            "creditCard": "אשראי",
            "Cibus": "סיבוס",
            "10bis": "תן ביס",
            "CreditGuard": "אשראי",
            "creditGuard": "אשראי",
            "cash": "מזומן",
            "TavHazahav": "תו הזהב",
            "cheque": "המחאה",
            "payLeumi": "פיי לאומי",
            "meshulam": "Bit",
            "giftCard": "כרטיס נטען",
            "ChargeAccount": "הקפה",
            "ChargeAccountPaymentProvider": "הקפה",
            "OperaPaymentProvider": "חיוב חדר"
        },
        "RANKS": {
            "0": "סועד",
            "1": "קבוע",
            "2": "אורח כבוד",
            "3": "פודי",
            "4": "אלוף ההזמנות",
            "5": "VIP",
            "6": "חי בשביל לאכול!",
            "7": "מבקר מסעדות"
        },
        "ALLERGIES": {
            "gluten": "גלוטן",
            "eggs": "ביצים",
            "peanuts": "בוטנים",
            "nuts": "אגוזים",
            "sesame": "שומשום",
            "lactose": "לקטוז",
            "spicy": "חריף",
            "fish": "דגים"
        },
        "PO": {
            "TABLE_BILL": "חשבון לשולחן",
            "WAITER": "מלצר",
            "ORDER_NO": "מספר הזמנה",
            "ORDERER_NAME": "שם המזמין",
            "ORDERER_PHONE": "טלפון",
            "ORDER_TO": "משלוח לכתובת",
            "OTH": "OTH",
            "ITEM_DISCOUNT": "הנחת פריט",
            "TICKET_DISCOUNT": "הנחת חשבון",
            "TICKET_OTH": "הכל עלינו",
            "CHANGE": "טיפ\\עודף",
            "WITHOUT": "ללא",
            "ORDER_TOTAL": "סה\"כ הזמנה",
            "ORDER_TIP": "טיפ",
            "PAID": "שולם",
            "REMAINED_PAY": "נותר לתשלום"
        },
        "DAYS": {
            "sunday": "ראשון",
            "monday": "שני",
            "tuesday": "שלישי",
            "wednesday": "רביעי",
            "thursday": "חמישי",
            "friday": "שישי",
            "saturday": "שבת"
        },
        "VALIDATIONS": {
            "CREDIT_CARD": "מס' כרטיס אינו חוקי",
            "CREDIT_CARD_EXPIRATION": "תוקף לא חוקי",
            "INVALID_ID_NUNBER": "תעודת הזהות אינה תקינה"
        },
        "occasions": {
            "occasions": "מועדים",
            "occasion": "מועד",
            "event": "אירוע",
            "online_event": "הודעה",
            "holiday": "חג",
            "all_day": "כל היום",
            "start": "התחלה",
            "end": "סיום",
            "time_from": "מ",
            "time_until": "עד",
            "events": "אירועים",
            "hang_out_type": "סוג בילוי",
            "from_day": "מיום",
            "every_week": "כל שבוע",
            "every_two_weeks": "פעם בשבועיים",
            "every_month": "פעם בחודש",
            "on_days": "בימים",
            "on_day": "ביום",
            "starts_soon": "מתחיל בקרוב",
            "now": "עכשיו",
            "until": "עד",
            "more_info": "עוד",
            "confirm": "סגור",
            "today_all_day": "היום, כל היום",
            "dates": {
                "events_for_today": "עכשיו - אירועים של היום",
                "tomorrow": "מחר",
                "during_the_week": "בהמשך השבוע",
                "next_week": "שבוע הבא",
                "upcoming_month": "בחודש הקרוב"
            },
            "tags": {
                "everything": "הכל"
            }
        },
        "marketplace": {
            "results_page_title": "Tabit Gift It",
            "results_page_description": "רוצים לפנק אנשים שאתם אוהבים בארוחה טעימה?</br> הגעתם למקום הנכון!",
            "no_results_found": "לא נמצאו תוצאות",
            "button_text": "הזמנת גיפט קארד",
            "search_placeholder": "חיפוש מסעדה",
            "more_information": "מידע נוסף",
            "search_input": "חיפוש לפי שם עסק/עיר",
        },
        "site_details": {
            "tab_label_overview": "כללי",
            "tab_label_reviews": "חוות דעת",
            "tab_label_map": "מפה",
            "tab_label_history": "ההזמנות שלי",
            "tab_label_menu": "תפריטים"
        },
        "menus": {
            "morning menu": "תפריט בוקר",
            "noon menu": "תפריט צהריים",
            "evening menu": "תפריט ערב",
            "night menu": "תפריט לילה"
        },
        "_CODES": {
            "409012": "לא ניתן לחרוג מהיתרה לתשלום",
            "409023": "התשלום נכשל. יש לנסות שוב או להחליף אמצעי תשלום",
            "409028": "קיימים מסמכים שלא הופקו",
            "409030": "סיסמה קצרה מידי",
            "409036": "אנו מטפלים בתשלום קודם, אנא נסו שוב",
            "409039": "קיימים פריטים שלא הושלמו",
            "409040": "הפריט PARAM אזל במלאי",
            "409073": "נראה שהתשלום התמהמה. אנא נסו שוב.",
            "409091": "אופס.. נראה שיש בעיה. יש ליצור קשר עם המסעדה להשלמת ההזמנה (טלפון)",
            "409092": "אופס.. נראה שיש בעיה. יש ליצור קשר עם המסעדה להשלמת ההזמנה (טלפון)",
            "409093": "נראה שהתשלום התמהמה. אנא נסו שוב.",
            "409098": "שירות התשלומים אינו פעיל. אנא נסו שוב מאוחר יותר או החליפו אמצעי תשלום",
            "409110": "שליחת הSMS למספר [PARAM1] נכשלה, אנא נסו שנית",
            "409136": "ההזמנה נוצרה ונשלחה למסעדה",
            "409806": "מותג האשראי אינו נתמך במסעדה זו",
            "409808": "אמצעי התשלום פג תוקף",
            "409809": "ממליצים לוודא שכל הפרטים נכונים",
            "409810": "ממליצים לוודא שכל הפרטים נכונים"
        },
        "areas_search_title": "אזורים",
        "areas": {
            "haifa": "חיפה והסביבה",
            "north": "צפון",
            "center": "מרכז",
            "tel_aviv": "תל אביב-יפו והסביבה",
            "jerusalem": "ירושלים והסביבה",
            "south": "דרום",
            "dallas": "Dallas",
            "fort_worth": "Fort Worth",
            "irving_grapevine": "Irving & Grapevine",
            "burleson": "Burleson",
            "plano": "Plano",
            "frisco": "Frisco",
            "mckinney": "McKinney",
            "sydney": "Sydney",
        },
        // Needed for dynamic language change
        "Haifa area": "חיפה והסביבה",
        "North": "צפון",
        "Center": "מרכז",
        "Tel Aviv-Jaffa area": "תל אביב-יפו והסביבה",
        "Jerusalem area": "ירושלים והסביבה",
        "SLIP": {
            "OTH": "OTH",
            "ITEM_DISCOUNT": "הנחת פריט",
            "TICKET_DISCOUNT": "הנחת חשבון",
            "TICKET_OTH": "הכל עלינו",
            "CHANGE": "טיפ\\עודף"
        },
        "GROUP_VALIDATION": {
            "SELECT_ONE": "בחרו פריט אחד",
            "SELECT_SOME": "בחרו {{min}} פריטים",
            "SELECT_ATLEAST_ONE": "בחרו לפחות פריט אחד",
            "SELECT_ATLEAST_SOME": "בחרו לפחות {{min}} פריטים",
            "SELECT_UPTO_ONE": "בחרו עד פריט אחד",
            "SELECT_UPTO_SOME": "בחרו עד {{max}} פריטים",
            "SELECT_BETWEEN": "בחרו בין {{min}} ל {{max}} פריטים",
            "GROUP_EXCEPTION": {
                "TITLE": "היי, התעדכנו",
                "BODY": "מאז הפעם האחרונה שהזמנת עשינו כמה שינויים במנה, יש לעדכן את ההזמנה החדשה בהתאם",
            },
        },
        "ACCESS": {
            "service_selection": "בחירת סוג שירות: יש לבחור את השירות הרצוי",
            "tabit_logo": "טאביט לוגו",
            "call": "להתקשר",
            "gift-card-redeem": "עמוד מימוש כרטיס מתנה",
            "payment-dialog-open": "חלון לתשלום",
            "login-successfully": "ההתחברות הושלמה בהצלחה!",
            "contact-details": "פרטי יצירת קשר למסעדה",
            "site_service": "שירותי מסעדה",
            "address": "כתובת",
            "distance": "מרחק",
            "minus_circle": "מינוס",
            "plus_circle": "פלוס",
            "accessibility_statement": "הצהרת נגישות. האתר תומך בקורא מסך מסוג אנ וי די איי NVDA",
            "selected": "פריט נבחר",
            "deselected": "פריט הוסר",
            "ITEM_ADDED_PLUS": "פריט זהה נוסף",
            "unselected": "לא נבחר",
            "same-page-link": "לינק פנימי בתפריט",
            "BASKET": "סל פריטים",
            "right-arrow-nav": "חץ ניווט ימין",
            "left-arrow-nav": "חץ ניווט שמאל",
            "restaurant": "מסעדת",
            "ACCESSIBILITY_DECLARATION": "הצהרת נגישות",
            "ACCESSIBILITY": "תפריט נגישות",
            "ACCESSIBILITY_STATEMENT": "הצהרת נגישות",
            "ACCESSIBILITY_SITE": "מעבר לאתר נגיש",
            "ACCESSIBILITY_IN_PROGRESS": "האתר נמצא בתהליך הנגשה",
            "CLEAR_ACCESSIBILITY_FEATURES": "נקה אפשרויות נגישות",
            "monochrom": "מונוכרום",
            "light-contrast": "ניגודיות בהירה",
            "dark-contrast": "ניגודיות כהה",
            "enlarge-font": "הגדלת גופן",
            "reduce-font": "הקטנת גופן",
            "readable-font": "גופן קריא",
            "big-white-curser": "סמן גדול לבן",
            "big-black-curser": "סמן גדול שחור",
            "enlarge-frame": "הגדלה",
            "highlight-links": "הדגשת קישורים",
            "highlight-headers": "הדגשת כותרות",
            "image-info": "תיאור לתמונות",
            "site-main-image": "תמונת מסעדה ראשית",
            "back-button": "לְחָץ לְחָזָרָה לָעָמוּד הָקודֵם",
            "enter_amount": "סכום המתנה ({{min_amount}} עד {{max_amount}})",
            "delete": "מחק",
            "view-menu": "צפייה בתפריט",
            "add": "הוספה",
            "remove": "הסרה",
            "edit": "עריכה",
            "day": "בחר יום",
            "time": "בחר שעה",
            "guests": "בחר מספר אורחים",
            "language_screen_reader": "בְחָר שָׂפָה",
            "future-reservation-time": "מתי מגיעים?",
            "close": "סגור",
            "close_dialog": "סְגוֹר דִיאׁלוֹג",
            "delete_content": "מְחַק תוכֵן תָא",
            "out_of_stock": ":חָסֵר כָּרְגַע בָמְלָאיִ",
            "press_to_approve_and_continue_to_menu": "לְחַץ לְאִישׁוּר וְהַמשֵׁך לְעָמוּד הָתַפריט",
            "service_buttons_takeaway": "אִיסוּף עַצְמִי",
            "service_buttons_delivery": "מִשְׁלוֹחַ",
            "service_buttons_delay": "הַזְמָנָה לְיום אָחֵר",
            "service_buttons_eatin": "הַזְמֵן וָשְב",
            "service_buttons_future_takeaway": "אִיסוּף עַצְמִי בְהַזְמָנָה עָתִידִית",
            "service_buttons_future_delivery": "מִשְׁלוֹחַ הַזְמָנָה עָתִידִית",
            "item_added": "פָּרִיט אְחַד הִיתוָסֵף לָסַל",
            "selection_confirmed": "הָבְחִירָה אוּשְׁרׁה",
            "contrast-button": "ניגודיות",
            "invalid-form": "לֹא כל שְׂדוֹת הַחוֹבָה מֻלְּאוּ, נָא מָלֵא אֶת הַשָּׂדוֹת הַנִּדְרָשִׁים",
            "remove-payment_line": "הסרת שורת תשלום",
        },
        "ADDRESS_TYPES": {
            "apartment": "דירה",
            "office": "משרד",
            "house": "בית פרטי"
        },
        "OFFER_BADGES": {
            "new": "חדש",
            "sale": "מבצע"
        },
        "WEB_HEADER": {
            "table_booking": "הזמנת מקום",
            "order_delivery": "משלוח / TA",
            "restaurants": "מסעדות"
        },
        "_LEUMIPAY": {
            "payment_error": "התשלום ב PAY. נכשל!\nאנא נסו שוב",
            "min_order_amount_error": "לתשלום ב PAY סכום פריטי ההזמנה חייב להיות מעל 50 ש\"ח",
            "contrat": "לתקנון PAY..."
        },
        "_LOYALTY": {
            "id_number_already_exist": "מספר זהות זה כבר נמצא בשימוש, אנא הקלידו מספר זהות אחר.",
            "registration_error_title": "התחברות למועדון",
            "order_types": "תקף ב{{types}} בלבד",
            "cancel_benefits": "בטל הנחות",
            "card_balance": "כרטיס נטען",
            "money_compensation": "פיצוי כספי",
            "connect": "התחברות",
            "connect_to_tabit": "התחברות ל-Tabit",
            "connect_info": "מספר טלפון או מספר כרטיס",
            "expires": "בתוקף עד",
            "greeting": "אהלן {{name}}!",
            "greeting_voucher": "אהלן!",
            "hey": "היי!",
            "member_not_found_title": "לא מצאנו אותך",
            "general_error_title": "רגע…",
            "general_error": "אירעה שגיאה בחישוב ההטבות, יש לנסות שוב או לפנות למסעדה",
            "renew_is_forbidden_title": "החברות שלך במועדון הסתיימה",
            "renew_is_forbidden_text": "כדי להמשיך ליהנות מהטבות המועדון, יש לחדש את החברות. לפרטים נוספים, אנא פנו למסעדה.",
            "comps_extra_text": "הפיצוי התווסף להזמנה וניתן לממש אותו.",
            "good_to_see_you_title": "כיף לראות אותך",
            "member_not_found": "מס' הטלפון שלך לא מופיע ברשימת חברי המועדון שלנו",
            "member_not_found_PRIMARY_BUTTON_TEXT": "אישור",
            "customer_mustComplete_the_registration": "על מנת להוסיף את חבר המועדון להזמנה, יש תחילה למלא את טופס ההרשמה שנשלח בהודעת סמס או באפליקציה שלנו",
            "of": "מ-",
            "points_balance": "יתרת נקודות",
            "points_balance_cap": "יש לך {{x}} נקודות למימוש מידי",
            "punch_card_offer": "נותר {{from}} מ {{to}}",
            "review_benefits": "סיכום הנחות",
            "review_points": "מימוש נקודות",
            "use_points": "הטבת צבירה",
            "signout": "התנתק",
            "skip": "דלג",
            "no-benefits": "לא מומשו הטבות",
            "title": "חברי מועדון {{name}}?",
            "title_voucher": "היי, יש לך שובר?",
            "title_club": "היי, יש לך מועדון או שובר?",
            "voucher": "שובר",
            "max_redeemable_points": "מקסימום נקודות למימוש",
            "my_benefits": "ההטבות שלי",
            "benefits_use": "ימומש בעסקה זו",
            "redeem_points_prompt_1": "הנקודות שלך שוות כסף!",
            "redeem_points_prompt_2": "מקס. נקודות למימוש: {{points}}",
            "redeem_points_after": "חסכת {{points}} בעזרת מימוש נקודות",
            "voucher_gtreeting": "היי, יש לך שובר?",
            "voucher_inline_gtreeting": "יש לך שובר?",
            "voucher_benefits_title": "ההטבות שלי",
            "voucher_placeholder": "מספר שובר",
            "club_gtreeting": "בואו נבדוק אם יש לך אצלנו הטבות או חברות במועדון",
            "club_inline_gtreeting": "הקלידו את קוד הקופון או מס' חבר המועדון",
            "club_inline_gtreeting_voucher_only": "יש לך שובר?",
            "club_benefits_title": "ההטבות שלי",
            "club_placeholder": "טלפון/ מספר שובר",
            "select_club": "בחרו מועדון",
            "benefits_title": "ההטבות שלי",
            "no_benefits_title": "נראה שאין לך הטבות זמינות כרגע",
            "register": "רוצה להצטרף למועדון? להרשמה",
            "to_promotion_disclaimer": "ההנחה הסופית תחושב לפני התשלום",
            "benefit_types": "תקף ב{{val}} בלבד",
            "auth_title": "נדרש קוד אימות",
            "auth_sub_title": "קוד אימות נשלח לטלפון",
            "auth_code": "קוד אימות",
            "auth_resend": "שלחו לי שוב",
            "for_your_disposal": "לרשותך",
            "gift-card": "יש לך כרטיס נטען או כרטיס מתנה?",
            "add-gift-card": "הזנת כרטיס נוסף",
            "wallet-item-needed": "עוד דבר קטן",
            "add-wallet-item": "לפני שממשיכים, צריך להזין כרטיס אשראי",
            "move-to-my-wallet": "מעבר לארנק שלי",
            "registration_terms": "ההרשמה היא גם אישור לקבל מדי פעם הודעות SMS על הטבות ומבצעים משתלמים. אפשר להתנתק מרשימת התפוצה בכל רגע",
            "regCostMessage": "ההצטרפות למועדון כרוכה בתשלום של {{currency}}{{price}} שיתווספו לסל הפריטים בהזמנה שלך",
            "confirmDeleteOfferJoin": "לבטל את ההצטרפות למועדון?",
            "confirmDeleteOfferRenewal": "לבטל את חידוש המועדון?",
            "confirmDeleteOfferJoinDescription": "ביטול ההצטרפות למועדון ימנע את קבלת ההטבות",
            "confirmDeleteOfferRenewalDescription": "ביטול חידוש המועדון ימנע את קבלת ההטבות",
            "removeOfferJoinButton": "ביטול ההצטרפות",
            "removeOfferRenewalButton": "ביטול החידוש",
            "keepOfferJoinButton": "להמשיך מאותו מקום",
            "keepOfferRenewalButton": "להמשיך מאותו מקום",
            "confirmExpiredSoonDeleteOffer": "לבטל את חידוש המועדון?",
            "removeExpiredSoonOfferButton": "ביטול החידוש",
            "keepExpiredSoonOfferButton": "להמשיך מאותו מקום",
            "regTitle": "חברי מועדון נהנים יותר!",
            "regCancel": "ביטול",
            "regDetailsDescription": "לא מצאנו את מספר הטלפון שלך {{phone}} ברשימת החברים שלנו. זה הזמן להצטרף ולהנות מהטבות!\n",
            "continueReg": "הצטרפות למועדון",
            "regRenewMessage": "חידוש המועדון עולה {{currency}}{{price}}, שיתווספו לסל הפריטים בהזמנה שלך",
            "regPromptTitle": "לא מצאנו את המספר {{phone}} במועדון שלנו.\nזה הזמן להצטרף!",
            "regPromptPrice": "ההצטרפות למועדון עולה {{currency}}{{price}}",
            "regRenewPromptPrice": "חידוש המועדון עולה {{currency}}{{price}}",
            "regRenewTitle": "הזמן טס כשטעים!",
            "regRenewBody": "הגיע הזמן לחדש את החברות במועדון שלנו\n",
            "regSoonExpiredRenewBody": "תוקף החברות במועדון פג ב-{{date}}.\nכדי להמשיך ולהנות מהטבות המועדון, ניתן לחדש את החברות כבר עכשיו!",
            "regSoonExpiredSignOut": "תוקף החברות במועדון פג ב-{{date}}.",
            "regUpdateNessage": "אנא ודא שהפרטים שהוזנו תקינים",
            "registerButton": "הצטרפות",
            "continueWithoutRenewing": "המשך ללא חידוש",
            "continueWithoutReg": "המשך ללא הצטרפות",
            "renewMembership": "חידוש מועדון",
            "phoneNumber": "מספר נייד",
            "textForConfirmSms": "הנני מאשר/ת למועדון לשלוח לי חומרים פרסומיים מעת לעת באמצעות מסרונים. אוכל להסיר את עצמי מרשימת התפוצה בכל עת.",
            "textForConfirmSmsScreenReader": "הִנֵנִי מְאָשֵר אוֹ מְאָשְרֵת לָמוֹעָדוֹן לִשְלוֹחַ לִי חוֹמָרִים פִּרְסוּמִיים מעֵת לָעֵת בֵאְמְצָעוּת מִסְרוֹנִים, וְאוֹכַל לְהָסִיר אְת עַצמִי מְרֵשִימַת הַתפוּצָה בְכוֹל עְת.",
            "textForConfirmClubRules": "הנני מאשר/ת את תקנון המועדון",
            "textForConfirmClubRulesScreenReader": "הנֵנִי מְאָשֵר אוֹ מְאָשְרֵת אְת תָקָנוֹן הָמוֹעָדוֹן",
            "termsContinue": "קדימה להטבות",
            "termsFirstContinue": "השלמת פרטים",
            "termsBody": "נראה שטרם השלמת את ההרשמה למועדון \n כמה פרטים אחרונים וההטבות אצלך :)",
            "termsTitle": "היי {{name}}",
            "first_name": "שם פרטי",
            "last_name": "שם משפחה",
            "email": "אימייל",
            "zehut": "תעודת זהות",
            "phone": "נייד",
            "anniversary": "תאריך נישואין",
            "birth_date": "תאריך לידה",
            "regErrorTitle": "משהו השתבש",
            "email_not_unique": "אימייל זה כבר נמצא בשימוש, אנא הקלידו כתובת אימייל אחרת",
            "regError": "לא ניתן להשלים את תהליך ההצטרפות, יש לפנות למסעדה",
            "no_registration_items": "לא נמצא פריט חידוש/הצטרפות מפורסם בתפריט",
            "continueErrorTitle": " הי ",
            "continueErrorText": "כדי ליהנות מהטבות המועדון יש להוסיף פריטים לסל",
            "understood": "הבנתי",
            "minimum_order_required_title": "...רגע",
            "minimum_order_required_body": "אחרי חישוב ההטבות, הסכום לתשלום הוא {{currency}}{{total}} והוא נמוך מהמינימום להזמנה -  {{currency}}{{amount}}",
            "minimum_order_required_button": "חזרה לתפריט",
            "get_benefits_401_error": "פג תוקף ההתחברות שלך. לצורך המשך שימוש במערכת, יש להתחבר מחדש למועדון הלקוחות",
        },
        "validation_errors": {
            "required": "שדה חובה",
            "max_length": " מגבלת תווים 256",
            "invalid_email": "כתובת אימייל לא תקינה",
            "invalid_phone": "מספר טלפון לא תקין",
            "load_range": "אנא הזינו סכום בתוך הטווח",
            "no_decimal": "יש להזין סכום שלם",
            "only_5_digits": "השדה מוגבל ל 5 תווים",
            "min_2_digits": "השדה מחייב לפחות 2 תווים",
            "only_numbers": "השדה מוגבל למספרים בלבד",
            "only_letters": "השדה מוגבל לאותיות בלבד",
            "invalid_zip_code": "מיקוד לא תקין",
        },
        "_WEIGHT": {
            "units": "יחידה",
            "price_per_weight": "מחיר ל{{unit}} = {{price}}",
            "item_weight": "משקל פריט",
            "kg": "ק\"ג (kg)",
            "gr": "גרם (gr)",
            "lb": "ליברה (lb)",
            "oz": "לאונקיה (oz)",
            "l": "ליטר (l)",
            "ml": "מיליגרם (ml)",
            "GRAM": "גרם"
        },
        "_GIFTCARD": {
            "title": "יש לך כרטיס נטען או כרטיס מתנה למימוש?",
            "gift_card": "כרטיס מתנה",
            "select_card": "בחרו כרטיס",
            "please_select_card": "אנא בחרו כרטיס",
            "edit_card": "עדכון כרטיס",
            "add_card": "אישור",
            "number": "מספר כרטיס",
            "amount": "סכום לתשלום",
            "balance": "יתרה בכרטיס",
            "max": "מקסימום למימוש בהזמנה זו",
            "cvv": "CVV",
            "cvv_required": "חובה למלא CVV",
            "invalid_cvv": "מספר ה CVV שהוזן שגוי",
            "inuse_error": "לא ניתן לממש את אותו הכרטיס יותר מפעם אחת בהזמנה",
            "balance_error": "לא קיימת יתרה בכרטיס",
            "tip_not_allowed": "לא ניתן לשלם טיפ עם כרטיס זה",
            "notfound_error": "פרטי הכרטיס שגויים או שהכרטיס אינו קיים במערכת",
            "delete_prompt": "להסיר את הכרטיס מההזמנה?",
            "no_order_amount": "התשלומים כבר מכסים את ההזמנה, אין צורך בתשלום נוסף 😎",
            "reset_split_payments_prompt": "הוספה או עדכון הכרטיס יאתחל את פיצול התשלומים! להמשיך?",
            "please_finish_payment": "לחצו ״תשלום״ לסיום",
            "otp_required": "קוד אימות נשלח למספר הטלפון",
            "otp_required_alt": "קוד אימות נשלח למספר הטלפון",
            "otp_required_alt_num": "{{v}}-****-***",
            "otp_alt": "קוד אימות",
            "invalid_otp": "קוד האימות שהוזן שגוי",
            "resend_otp": "שלחו לי שוב",
            "cannotPayOnOnline": "לא ניתן לשלם בכרטיס זה ברכישה אונליין, אלא במסעדה בלבד",
        },
        "_DELAYED": {
            "title": "תזמון הזמנה",
            "service_caption": "ליום אחר",
            "supply_time": "מועד אספקה",
            "select_date": "נא לבחור תאריך אספקה",
            "select_slot": "נא לבחור שעת אספקה",
            "service_today": "הזמנה להיום",
            "service_future": "הזמנה עתידית",
            "for_hour": "לשעה {{val}}",
            "for_date": "יום {{val}}",
            "between": "בין השעות",
            "at_hour": "בשעה",
            "select_slot_prompt": "נא לבחור שעת אספקה",
            "service_disabled_today": "השרות אינו פעיל היום!"
        },
        "CHECKOUT_STEP": {
            "extra_offers": "הצעות נוספות",
            "contact": "הפרטים שלי",
            "gratuity": "טיפ",
            "pay": "תשלום",
            "summary": "סיכום",
        },
        "WEB_CONTAINER": {
            "start_logo": "טאביט - עמוד ראשי",
            "language_select": "החלף שפת אתר לאנגלית",
            "home": "ראשי",
            "search_now": "חפש",
            "search": "חיפוש מסעדות, מטבחים, סגנונות או טעמים",
            "find_table": "מצאו לי שולחן",
            "show_more": "הצג עוד",
            "service": "שירות",
            "back_to_regular_results": "חזרה לתוצאות רגילות",
            "booking_bar": {
                "day": "יום",
                "day_screen_reader": "בְּחִירַת יוֹם",
                "choose_day": "באיזה יום תרצו לבלות?",
                "hour": "שעה",
                "hour_screen_reader": "בְּחִירַת שָעָה",
                "choose_hour": "באיזו שעה?",
                "guests": "אורחים",
                "guests_screen_reader": "בְּחִירַת מִסְפָר אוֹרְחִים ",
                "choose_guests": "כמה תהיו?",
                "address": "כתובת",
                "category": "קטגוריה",
                "all_categories": "כל הקטגוריות",
                "area": "איזור",
                "all_areas": "כל האיזורים",
                "tags": "תגיות",
                "tags_screen_reader": "בְּחִירַת תָגִיּוֹת, כְּגוֹן סִגְנוֹן מִסְעָדָה, סוּג אוֹכֵל וְמְאָפְיְנִים נוֹסָפִים",
                "clear_tags": "נקה תגיות",
                "rating": "דירוג",
                "rating_screen_reader": "בְּחִירַת דירוג",
                "all_ratings": "כל הדירוגים",
                "price": "מחיר",
                "price_screen_reader": "בְּחִירַת טְוַח מְחִיר",
                "all_prices": "כל המחירים"
            },
            "book_a_table": {
                "tab_title": "הזמנת מקום",
                "main_title": "כשמתגעגעים,\nהאוכל יותר טעים",
                "main_subtitle": "רק תגידו מתי, אנחנו כבר נדאג לכל השאר",
                "new_restaurants_in_tabit": "מסעדות חדשות ב-Tabit",
                "new_restaurants_in_tabit_screen_reader": "מִסְעָדוֹת חָדָשׁוֹת בְטָאבִּיט",
                "kitchens": "מטבחים",
                "cuisine": "מטבחים",
                "restaurants_by_areas": "מסעדות לפי איזורים",
                "near_me": "קרוב אלי",
                "show_more": "הצג עוד",
                "results_page_title": "הזמנת מקום",
                "results_page_description": "בחרו את התאריך, השעה וכמות האורחים ולחצו מצאו לי שולחן",
                "no_results_found": "נראה שאין כרגע מקום פנוי באף אחת מהמסעדות. אנא בחרו תאריך או שעה אחרים ונחפש עבורכם שוב.",
                "select_future_date": "המועד שנבחר עבר, אנא בחרו שוב"
            },
            "deliveries": {
                "tab_title": "משלוחים ו-TA",
                "tab_title_screen_reader": "מִשְלוֹחִים וְאִיסוּף עַצְמִי",
                "main_title": "מה בא לך לאכול?",
                "main_subtitle": "רק תגידו לאן, אנחנו כבר נדאג לכל השאר",
                "results_page_title": "משלוחים ו-TA",
                "results_page_description": "",
                "no_results_found": "נראה שאין כרגע מסעדות שעושות משלוחים בשעה זו לכתובת שבחרתם"
            },
            "restaurants": {
                "tab_title": "מסעדות",
                "main_title": "לגלות, לטעום, לבלות",
                "main_subtitle": "המקומות השווים, הנחשבים והכי לוהטים היום",
                "results_page_title": "מסעדות",
                "results_page_description": "",
                "no_results_found": "באסה... אין תוצאות לחיפוש שלך"
            },
            "marketplace": {
                "tab_title": "Tabit Gift It",
                "main_title": "Tabit Gift It",
                "home_page_section_title": "גיפט קארד למסעדות הכי שוות!",
            },
            "web_article": {
                "bottom_content_button": "לכתבה הבאה >>"
            }
        },
        "NOTIFICATIONS_WIDGET": {
            "messages": "הודעות",
            "clear_all": "נקה הכל"
        },
        "DASHBOARD": {
            "we_have_missed_you!": "הזמנת שולחן זה פה!",
            "when_we_would_see_you?": "רק תגידו מתי, השאר עלינו",
            "all_the_restaurants_click_away": "רק תגידו מתי, השאר עלינו",
            "BUTTONS": {
                "delivery": "משלוח",
                "takeaway": "איסוף עצמי",
                "book": "הזמנת מקום"
            }
        },
        "WEB_HOME": {
            "H1_TITLE": "הזמנת מקום זה פה!",
            "H2_TITLE": "רק תגידו מתי, באיזו מסעדה והשאר עלינו",
        },
        "WEB_BOOK": {
            "H1_TITLE": "הזמנת שולחן",
            "H2_TITLE": "חיפוש שולחן במסעדות טאביט",
        },
        "KFC": {
            "title": "KFC {{country}}",
            "main_sub_title": "העוף הטעים בעולם",
            "sec_sub_title": "תכינו ת'אצבעות!"
        },
        "SHIOU": {
            "title": "שיואו! יש לך טאצ' לסושי",
            "sub_title": "הדרך הקצרה ביותר מהדמיון אל הלשון, בתאבון!"
        },
        "BIGA": {
            "title": "BIGA",
            "sub_title": "Bakery & kitchen"
        },
        "FOODBA": {
            "title": "Foodba",
            "sub_title": "אפליקציית המשלוחים והבילוי של באר שבע",
            "seo_title": "Foodba | באר שבע",
        },
        "MAISON": {
            "title": "MAISON KAYSER",
            "sub_title": "ARTISAN BOULANGER, PÂTISSIER, CAFÉ & RESTAURANT"
        },
        "GRECO": {
            "title": "GRECO",
            "sub_title": "חגיגה יוונית אמיתית"
        },
        "USER_POINTS": {
            "btw_you_have": "אגב, יש לך כבר",
            "btw_you_dont_have": "אגב, אין לכם עדיין נקודות",
            "for_each_order_more_bonus": "על כל הזמנת שולחן תקבלו עוד 10",
            "for_each_order_bonus": "על כל הזמנת שולחן תקבלו 10"
        },
        "GIFT_CARD_SHOP": {
            "tracker": {
                "to_from": "פרטים ראשוניים",
                "when_where": "אפשרויות שליחה",
                "checkout": "תשלום"
            },
            "card_recipient": {
                "title": "למי מיועדת המתנה"
            },
            "user_wallet": {
                "title": "שיטת תשלום",
                "other": "תשלום באמצעי אחר",
            },
            "choose_amount": {
                "current_balance": "יתרה נוכחית",
                "you_pay": "טוענים",
                "you_get": "מקבלים",
            },
            "create_card": {
                "title": "פרטי המתנה",
                "to": "עבור מי המתנה?",
                "from": "ממי המתנה?",
                "first_name": "שם פרטי",
                "last_name": "שם משפחה",
                "send_to_friend": "שלח את המתנה לחבר",
                "where_to_send_the_gift": "לאן לשלוח את המתנה?",
                "your_message": "ברכה",
                "your_message_placeholder": "כמה מילים (אופציונלי)",
                "enter_amount": "סכום המתנה ({{min_amount}} - {{max_amount}})",
                "enter_amount_error": "יש להזין סכום בהתאם לטווח",
                "send_to_phone": "לאן לשלוח את המתנה?",
                "toPhone": "לטלפון",
                "toEmail": "לאימייל",
                "required_load_sum": "בכמה להטעין?",
                "billing_address": "כתובת לחיוב",
                "state": "מדינה",
                "select_state": "בחרו מדינה",
                "city": "עיר",
                "address": "כתובת",
                "zip_code": "מיקוד",
                "legalText": "תו קנייה זה בתוקף לחמש שנים, לפי סעיף 14(ח) לחוק הגנת הצרכן", // Only for US/IL
                "legalTextProcess": "עסקה זו מקנה תו קנייה, בתוקף לחמש שנים, לפי סעיף 14(ח) לחוק הגנת הצרכן", // Only for US/IL
                "expirationDate": "This card expires 3 years after supply", // Only AU
                "bonus_title": "נהנים מבונוס של {{percent}}%!"
            },
            "delivery_options": {
                "best_send_to": "אפשרויות שליחה",
                "toPhone": "טלפון",
                "toEmail": "אימייל",
                "phone": "מספר טלפון",
                "email": "כתובת אימייל"
            },
            "checkout": {
                "title": "לסיכום",
                "gift_card_total": "סיכום",
                "gift_card": "כרטיס מתנה ",
                "send_to": "שלח אל",
                "to": "מקבל המתנה",
                "from": "שולח המתנה",
                "message": "ברכה",
                "send_receipt_to": "לאן לשלוח את אישור התשלום?",
                "send_all_receipts_to": "לאן לשלוח את הכרטיס ואישור התשלום?",
                "myPhone": "לטלפון שלי",
                "myEmail": "לאימייל שלי",
                "receiptContactPhone": "טלפון",
                "receiptContactEmail": "אימייל",
                "Email": "אימייל",
                "total": "סה\"כ לתשלום",
                "approve_notifications": "Notify me When the card is viewd by the recipient.",
                "approve_terms": "קראתי ואישרתי את",
                "approve_terms_and": "ואת",
                "approve_terms_link_1": "תנאי שימוש",
                "approve_terms_link_2": " מדיניות פרטיות"
            },
            "buttons_texts": {
                "pay": "לתשלום {{currency}}{{amount}}",
                "next": "המשך",
                "go_back": "חזרה",
                "you_get": "מקבלים {{currency}}{{amount}}",
                "website": "אתר המסעדה",
                "recipient_type_me": "לעצמי",
                "recipient_type_not_me": "לאדם אחר"
            },
            "validation_errors": {
                "required": "שדה חובה",
                "max_length": " מגבלת תווים 256",
                "invalid_email": "כתובת אימייל לא תקינה",
                "invalid_phone": "מספר טלפון לא תקין",
                "load_range": "אנא הזינו סכום בתוך הטווח",
                "no_decimal": "יש להזין סכום שלם",
                "only_5_digits": "השדה מוגבל ל 5 תווים",
                "min_2_digits": "השדה מחייב לפחות 2 תווים",
                "only_numbers": "השדה מוגבל למספרים בלבד",
                "only_letters": "השדה מוגבל לאותיות בלבד",
                "invalid_zip_code": "מיקוד לא תקין",
            },
            "modals": {
                "please_wait": "רק רגע...",
                "success": {
                    "title": "תודה!",
                    "body": "המתנה שלך בדרך",
                    "primary_button": "סגור",
                    "secondary_button": "אני רוצה עוד מתנה",
                    "top_up": {
                        "body": "הטעינה הושלמה",
                        "secondary_button": "טעינה נוספת",
                    }
                },
                "error": {
                    "title": "משהו השתבש",
                    "body": "התשלום נכשל",
                    "body_general_error": "אירעה שגיאה",
                    "body_error_occurred": "אירעה שגיאה\nיש ליצור קשר עם המסעדה בטלפון",
                    "body_validation_mismatch": "אירעה שגיאה\nיש לבדוק את פרטי התשלום",
                    "body_card_declined": "אירעה שגיאה\nיש לבדוק את פרטי התשלום",
                    "shop_inactive_error": "השירות לא פעיל כרגע",
                    "card_inactive_error": "שגיאה ביצירת הכרטיס, אנא צרו קשר עם המסעדה",
                    "primary_button": "סגור",
                    "secondary_button": "נסה שנית"
                },
                "pay": {
                    "pay_with_cibus": "תשלום באמצעות סיבוס",
                    "card_number": "מספר כרטיס"
                }
            },
            "redeem_card": {
                "title": "יתרה נוכחית:",
                "show_qr": "לתשלום יש להציג את ה-QR במסעדה",
                "code": "מספר כרטיס",
                "cvv": "CVV",
                "recipient_title": "היי  ",
                "recipient_text": "קיבלת {{amount}}",
                "recipient_from_text": "מ{{sender}}"
            },
            "general": {
                "gift_card_purchase": "כרטיס מתנה לחבר"
            }
        },
        "FOOTER": {
            "area_link_prefix": "מסעדות ב",
            "other_links": "קישורים נוספים",
            "contact": "יצירת קשר",
            "join_us": "הצטרפו אלינו",
            "to_tabit_site": "לאתר טאביט",
            "contact_us": "צור קשר",
            "privacy_policy": "מדיניות הפרטיות",
            "club_terms": "תקנון המועדון",
            "terms_of_use": "תנאי שימוש",
            "career": "דרושים",
        },
        "loyalty-terms-dialog": {
            "title": "יש לנו מועדון לקוחות חדש!",
            "descriptionMain": "הכנו בשבילך הטבות ממש שוות",
            "descriptionSecond": "שני דברים קטנים בכדי שתוכל להינות מההטבות:",
            "validation": "בטוח/ה שרוצה לוותר על הטבות המועדון?",
            "added_to_your_cart": "<b>איזה כיף!</b></br>עדכנו את ההטבה והיא מופיעה בסל שלך</br>",
            "ok": "אישור",
            "remove_reward": "להסיר את ההטבה מההזמנה?",
            "remove_reward_btn_yes": "כן, להסיר",
            "remove_reward_btn_no": "לא",
        },
        "loyalty-rewards-dialog": {
            'auto-rewards-title': 'הוספנו להזמנה שלך גם את ההטבות האלה:',
            'my-rewards': 'ההטבות שלך',
            'all-my-rewards': 'קחו אותי להטבות',
            'all-my-rewards-single': 'קחו אותי להטבה',
            'club-rewards-will-be-calculated-before-payment': 'בחרו את ההטבות שתרצו להוסיף להזמנה זו',
            'back-to-order': 'חזרה להזמנה',
            'done-thanks': 'סיימתי, תודה',
            "select_between_range": "הטבות שנבחרו {{quantity}} מתוך {{max}}",
            "loyalty_reward_terms_dialog_title": "יש לך את זה!",
            "loyalty_reward_terms_dialog": "ההטבה הופעלה ותעודכן אוטומטית במעבר לתשלום.",
            "loyalty_reward_terms_dialog_ok": "תפריט",
            "loyalty_reward_terms_dialog_cancel": "ביטול",
            "not-available": "ההטבה לא זמינה כרגע",
        },
        "pickup-order-dialog": {
            "Branch_pickup_order": "הזמנת איסוף מ",
            "address": "בכתובת",
        },
        "SIGN-UP-DATE-ERROR": "התאריך שהוזן שגוי",
        "general_credit_card": {
            "form": {
                "card_number": "מספר כרטיס:",
                "expiration": "תוקף:",
                "cvv": "CVV:",
                "amount_to_pay": "PAY"
            },
            "errors": {
                "invalid_exp": "אנא מלאו את כל השדות",
                "invalid_cvv": "CVV שגוי",
            }
        },
        "REMOVE_ACCOUNT": {
            "disclaimer_1": "השלמת התהליך מותנית באימות דו שלבי באמצעות קוד זיהוי שישלח בSMS.",
            "disclaimer_2": "המחיקה תתבצע באופן מיידי",
            "disclaimer_3": "הטבות ופיצויים המשוייכים למספר הטלפון שלך ישמרו גם לאחר הסרת החשבון מהאפליקציה וניתן יהיה לממש אותן בערוצים השונים."
        },
        "tou_offer": {
            "required": "חובה",
            "additional_options": "אפשרויות נוספות",
            "group_guiding_text": {
                "before_selection": {
                    "choose_at_least": "בחרו לפחות {{min}}",
                    "choose_at_least_singular": "בחרו אחד",
                    "select_at_least": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_at_least_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_at_least_no_max": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_at_least_no_max_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_between_range": "בחרו בין {{min}} ל {{max}} פריטים",
                    "select_between_range_with_defaults": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_between_range_with_defaults_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_minimum_items": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_minimum_items_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_quantity": "בחרו {{min}}",
                    "select_quantity_singular": "בחרו אחד",
                    "select_quantity_singular_without_default": "בחרו אחד",
                    "select_up_to": "ניתן לבחור עד {{max}} פריטים",
                    "select_up_to_singular": "ניתן לבחור עד פריט אחד",
                    "select_up_to_without_defaults": "ניתן לבחור עד {{max}} פריטים",
                },
                "during_selection": {
                    "choose_at_least": "בחרו לפחות {{min}}",
                    "choose_at_least_singular": "בחרו לפחות אחד",
                    "select_at_least": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_at_least_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_at_least_no_max": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_at_least_no_max_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_between_range": "נבחרו {{quantity}} מתוך {{max}} פריטים",
                    "select_between_range_with_defaults": "נבחרו {{quantity}} מתוך {{max}} פריטים",
                    "select_between_range_with_defaults_singular": "נבחרו {{quantity}} מתוך {{max}} פריטים",
                    "select_minimum_items": "המנה צריכה להכיל לפחות עוד {{min}} פריטים",
                    "select_minimum_items_singular": "המנה צריכה להכיל לפחות עוד פריט אחד",
                    "select_quantity": "{{quantity}} נבחרו",
                    "select_quantity_singular": "נבחר אחד",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "נבחרו {{quantity}} מתוך {{max}} פריטים",
                    "select_up_to_singular": "פריט אחד נבחר",
                    "select_up_to_without_defaults": "ניתן לבחור עד {{max}} פריטים",
                },
                "after_selection": {
                    "choose_at_least": "{{quantity}} פריטים נבחרו",
                    "choose_at_least_singular": "פריט אחד נבחר",
                    "select_at_least": "",
                    "select_at_least_singular": "",
                    "select_at_least_no_max": "",
                    "select_at_least_no_max_singular": "",
                    "select_between_range": "{{quantity}} פריטים נבחרו",
                    "select_between_range_with_defaults": "נבחרו {{quantity}} פריטים",
                    "select_between_range_with_defaults_singular": "פריט אחד נבחר",
                    "select_minimum_items": "",
                    "select_minimum_items_singular": "",
                    "select_quantity": "{{quantity}} נבחרו",
                    "select_quantity_singular": "נבחר אחד",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "{{quantity}} פריטים נבחרו",
                    "select_up_to_singular": "פריט אחד נבחר",
                    "select_up_to_without_defaults": "{{quantity}} פריטים נבחרו",
                }
            },
        },
        "new_restaurant_page": "דף מסעדה",
    },
    'en-US': {
        "number_of_restaurants_found_screen_reader": "number of restaurants found,",
        "number_of_addresses_found_screen_reader": "number of addresses found:",
        "number_of_dishes_found_screen_reader": "number of addresses found:",
        "localeCaption": "עב",
        "share_this": "Hi, here's something I found on Tabit. Check it out!",
        "hours": "Hours",
        "days": "Days",
        "tabit": "Tabit",
        "minutes": "Minutes",
        "footer_data": "Book a place | Order a table | Tabit",
        "terms_of_service": "Terms of Service",
        "privacy_notice": "Privacy Policy",
        "under_construction_message": "This page is still on the grill",
        "summary": "Summary",
        "service_selection": "Service selection",
        "future_service_desc": "Please select future service",
        "choose_navigation_option": "Select navigation option",
        "choose_option": "Select option",
        "choose_hotel": "Select hotel",
        "_and": "and ",
        "KEYPAD_DIALOG_WARNING": "Up to {{currency}} {{maxVal}} can be entered",
        "rsv_management": {
            "guests": "Guests",
            "disclaimer": "For any request regarding your order, please contact directly",
            "description": {
                "future_reservation_created": "Hey {{name}},\nYour reservation has been booked successfully.",
                "future_reservation_reminded": "Hey {{name}},\nPlease confirm your reservation.",
                "future_reservation_reminded_free_text": "Please confirm your reservation.",
                "future_reservation_notified": "Your table is ready. Come on in.",
                "future_reservation_checked_in": "Your are now checked-in. We will notify you as soon as your table is ready.",
                "future_reservation_deleted": "Your reservation has been canceled.",
                "future_reservation_verified": "Your reservation has been verified.",
                "future_reservation_standby_created": "Hey {{name}},\nYou have been added to our waiting list. We will get back to you if a table becomes available.",
                "future_reservation_standby_created_pending_approval": "Hey {{name}},\nYour request has been received, but NOT yet approved. We will get back to you as soon as possible for confirmation.",
                "future_reservation_will_be_late": "Thank you for letting us know that you will be late.",
                "future_reservation_undo_late": "Thank you for the update.",
                "future_reservation_cc_deposit": "Hey {{name}},\nYour reservation has been booked successfully.\nPlease note, the deposit fee will be charged if you cancel less than {{time_limit_cancel}} before the reservation.",
                "future_reservation_cc_deposit_reminded": "Dear {{name}},\nPlease confirm your reservation.\nPlease note, the deposit fee will be charged if you cancel less than {{time_limit_cancel}} before the reservation.",
                "future_reservation_cc_deposit_notified": "Hey {{name}},\nYour table is ready. Come on in.\nPlease note, the deposit fee will be charged if you cancel less than {{time_limit_cancel}} before the reservation.",
                "future_reservation_cc_deposit_checked_in": "Hey {{name}},\nYour are now checked-in. We will notify you as soon as your table is ready.\nPlease note, the deposit fee will be charged if you cancel less than {{time_limit_cancel}} before the reservation.",
                "future_reservation_advanced_payment": "Hey {{name}},\nYour reservation has been booked!\nPlease note, if you cancel less than {{time_limit_cancel}} before the reservation,\nthe advanced payment will be non-refundable.",
                "future_reservation_advanced_payment_reminded": "Hey {{name}},\nPlease confirm your reservation.\nPlease note, if you cancel less than {{time_limit_cancel}} before the reservation,\nthe advanced payment will be non-refundable.",
                "walked_in_saved": "You are now in line and we will notify you as soon as your table is ready.",
                "walked_in_created": "You are now in line and we will notify you as soon as your table is ready.",
                "walked_in_notified": "Your table is ready. You are invited to come in.",
                "walked_in_checked_in": "You are now checked in. We will notify you as soon as your table is ready",
                "called_in_saved": "You are now in line and we will notify you as soon as your table is ready.",
                "called_in_created": "You are now in line and we will notify you as soon as your table is ready.",
                "called_in_notified": "Your table is ready. Come on in."
            },
            "buttons": {
                "confirm_label": "Confirm Reservation",
                "cancel_label": "Cancel Reservation",
                "will_be_late_label": "We’ll be Late",
                "undo_late_label": "Arriving on time",
                "fill_health_statement": "Fill Out Health Statement",
                "modify_label": "Modify Reservation"
            },
            "cancel_reservation_dialog": {
                "title": "Reservation Cancellation",
                "body": "Are you sure you wish to cancel the reservation?",
                "confirm": "Cancel Reservation",
                "cancel": "Back"
            },
            "failure_reason": {
                "reservation_not_found": "Reservation not found",
                "reservation_in_progress": "The reservation has been seated. Enjoy!",
                "organization_not_found": "Organization not found",
                "feedback_module_disabled": "Sorry, but feedback is disabled for this restaurant"
            },
            "place_in_queue": "Queue Position",
            "preferences": "Preferences",
            "preference_exception": "Except {{preference}}",
            "social": {
                "share": {
                    "whatsapp": {
                        "button_label": "Send to a Friend",
                        "app_message": "I've ordered us a place at ",
                        "web_message": "Hey,\nCheck out our reservation at {{organization}}\n{{link}}"
                    },
                    "share": "Share"
                }
            },
            "notifications": {
                "email": {
                    "unsubscribe": {
                        "success": "You will no longer receive email notifications for this reservation.",
                        "error": "There was an unexpected error stopping email notifications. Please try again later or contact the restaurant."
                    }
                }
            },
            "call_customer": {
                "request": "I have an update, please call me back",
                "request_sent": "We'll call you back soon",
                "request_cancel": "(Cancel request)"
            },
            "deposit_payment": {
                "qr_code_button": "Show QR Code",
                "qr_code_description": "Please present this QR code when you arrive at the restaurant",
                "cancel_reservation_dialog": {
                    "body": "Cancellation within a period of less than {{time_limit_cancel}} from the time of the reservation, the deposit payment will be considered as a cancellation fee.\nPlease call the restaurant for any questions.\nAre you sure you wish to cancel the reservation?"
                },
                "refund_failed_dialog": {
                    "title": "Reservation cannot be canceled",
                    "body": "We are unable to cancel the reservation. Please contact the restaurant"
                },
                "description": {
                    "cancel_after_time_limit": "Your reservation has been canceled and your deposit wasn't refunded. For any request regarding this issue please contact the restaurant",
                    "cancel_before_time_limit": "Your reservation has been canceled and your deposit was refunded",
                    "no_voucher_balance": "Reservation is canceled. Your deposit has already been redeemed, therefore no refund was issued. For more information please contact the restaurant."
                }
            },
            "cc_deposit": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "If the reservation is canceled less than {{time_limit_cancel}} in advance, the deposit will be charged.\nDo you want to cancel the reservation?"
                    },
                    "prevent": {
                        "title": "Reservation Cannot be Canceled",
                        "body": "There are less than {{time_limit_cancel}} left before the reservation.\nTo cancel the reservation, please call the restaurant",
                        "primary_button": "Call restaurant",
                        "secondary_button": "Back"
                    }
                },
                "credit_details_dialog": {
                    "form_title": "To verify your card, we will pre-authorize a 1 NIS charge, which will be released by the end of the day",
                    "submit_button": "Continue"
                }
            },
            "advanced_payment": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "Unfortunately, if the reservation is canceled less than {{time_limit_cancel}} ahead of time, the advanced payment will be charged.\nWould you like to cancel the reservation?"
                    },
                    "prevent": {
                        "title": "Reservation Cannot Be Canceled",
                        "body": "There are less than {{time_limit_cancel}} left before the reservation.\nTo cancel the reservation, please call the restaurant",
                        "primary_button": "Call restaurant",
                        "secondary_button": "Back"
                    },
                    "charged": {
                        "body": "The advanced payment will not be refunded if the reservation is canceled less than {{time_limit_cancel}} ahead of time.\nWould you like to cancel the reservation?",
                        "in_time_cancellation_body": "The advanced payment will be automatically refunded if the reservation is canceled more than {{time_limit_cancel}} ahead of time.\nWould you like to cancel the reservation?"
                    }
                }
            },
            "hour": "Hour",
            "two_hours": "2 Hours"
        },
        "splash_screen": {
            "failure_dialog": {
                "title": "Something went wrong",
                "content": "An unexpected error occurred",
                "ok_button": "Try again"
            }
        },
        "booking": {
            "site_name": "Site Name",
            "site_address": "Site Address",
            "disclaimer_description": "By continuing I agree to the ",
            "disclaimer_description_deposit_payment": "By clicking \"Confirm & Pay\" I agree to the ",
            "disclaimer_description_connection": " and ",
            "terms_of_service": "Terms Of Service",
            "privacy_notice": "Privacy Policy",
            "button_label": {
                "walked_in": "Arriving Soon",
                "future_reservation": "Reserve a Table"
            },
            "search": {
                "person": "{{value}} Guest",
                "people": "{{value}} Guests",
                "larger_party": "{{value}}+ Guests",
                "minutes": "{{value}} Minutes",
                "no_results": "Hmm... It appears we don't have any available table at this time.\nPlease choose an alternative date or call the restaurant.",
                "no_alternatives": "Hmm... It appears we don't have any available table at this time.",
                "no_waiting_list": "No waiting list, you are welcome to arrive",
                "waiting_list_full": "Sorry, our waiting list is full",
                "results_info": "No exact match, showing closest results:",
                "call_restaurant_large_party": "For large party reservations please call the restaurant",
                "call_restaurant_error": "An Error Has Occurred - Please Call the Restaurant",
                "booking_window_error": "We’re sorry, the online waiting list isn’t available right now",
                "today": "Today",
                "first_available": "No Preference",
                "choose_preference": "Choose Area *",
                "inside": "Inside",
                "outside": "Outside",
                "vip": "VIP",
                "bar": "Bar",
                "gallery": "Gallery",
                "balcony": "Balcony",
                "smoking": "Smoking",
                "private_room": "Private Room",
                "patio": "Patio",
                "tv_view": "TV View",
                "stage": "Stage",
                "with_stroller": "With Stroller",
                "with_power": "With Power",
                "standby_reservation_description": "I don't like any of the suggested options, please add me to the standby list",
                "alternative_organizations_description": "Search a table at our additional restaurants",
                "meta_description": "To reserve a table at {{name}} choose a date, time, and number of guests.",
                "back_to_reservation": "Back to Reservation",
                "field_label": {
                    "seats_count": "Party Size",
                    "date": "Date",
                    "time": "Time",
                    "preference": "Preference",
                    "arriving_within": "Arriving Within"
                },
                "button_label": {
                    "search": "Reserve a table",
                    "search_walked_in": "Add Me to the Waiting List",
                    "search_modify": "Continue",
                    "standby_reservation": "Add Me to the Standby List",
                    "phone": "Call Us"
                },
                "today_blocked": "Same-day reservations are currently unavailable",
                "reservation_in_edit_current_title": "Your Current Reservation",
                "reservation_in_edit_modified_details": "Modify Your Reservation"
            },
            "details": {
                "description": "To complete the reservation, please fill in the following details.",
                "description_pending_approval": "The reservation requires confirmation by the restaurant. To complete the reservation, please fill in the following details.",
                "description_standby": "In order to be added to our waiting list, please fill in the following details.",
                "description_modify_warning": "Please note! Once modified, your reservation will require the restaurant’s approval and the original reservation will be deleted.",
                "description_modify_cc_deposit_warning": "Please note! Once modified, the existing deposit for your reservation will be deleted and you will be prompted to make a new one.",
                "description_modify_advanced_payment_warning": "Please note! Once modified, your saved credit card details will be deleted and you will be prompted to enter new credit card details.",
                "future_reservation_info": "The table is currently reserved for you for the next {{value}} minutes",
                "future_standby_reservation_info": "Leave us your contact information and we'll get back to you as soon as a table becomes available",
                "called_in_info": "You've been added to the waiting list\nPlease come to the restaurant in the next {{minutes}} minutes",
                "timer": "You have {{minutes}} minutes to complete the details",
                "button_label": {
                    "confirm": "Reserve",
                    "confirm_and_pay": "Confirm & Pay",
                    "proceed_to_deposit": "Next",
                    "cancel": "Cancel",
                    "back_to_search": "Back to Search",
                    "continue": "Continue",
                },
                "field_label": {
                    "first_name": "First Name",
                    "last_name": "Last Name",
                    "full_name": "Full Name",
                    "phone": "Phone",
                    "email": "Email",
                    "notes": "Notes",
                    "tags": "Notes",
                    "search": "Search"
                },
                "field_error": {
                    "phone": "Invalid Number"
                },
                "countries_dropdown": {
                    "find_country": "Search"
                }
            },
            "modal": {
                "confirmation": {
                    "time_limit_title": "Attention",
                    "time_limit_body": "Please note that your reservation will be limited until {{reserved_until}} ({{duration}}).\nWould you like to proceed?",
                    "queue_addition_title": "Waiting Line",
                    "queue_addition_body": "Would you like to be added to the waiting line?",
                    "still_here": "Still here?"
                },
                "alert": {
                    "unsuccessful_attempt_to_reserve_title": "Attention",
                    "unsuccessful_attempt_to_reserve_body": "An Unexpected Error Occurred",
                    "unsuccessful_attempt_to_reserve_body_invalid_cc": "Check the credit card details",
                    "reservation_not_found_title": "Oops...",
                    "reservation_not_found_body": "More than {{minutes}} minutes have passed since your reservation was created and it has expired. Please create a new reservation\n\nFor more assistance, please call the restaurant",
                    "fill_mandatory_fields_title": "Required Fields",
                    "fill_mandatory_fields_body": "Please fill in all required fields",
                    "online_booking_blocked_title": "Online Reservations Unavailable",
                    "online_booking_blocked_body": "We're sorry, but online reservations are currently unavailable.",
                    "preference_required_title": "Missing Preference",
                    "preference_required_body": "Please select a preference to continue",
                    "illegal_group_size_title": "Attention!",
                    "illegal_group_size_body": "The number of diners is too large for the selected date / time.",
                    "customer_additional_reservation_blocked_title": "Can’t book additional reservation",
                    "customer_additional_reservation_blocked_body": "It seems that you have already booked a table at our restaurant within less than {{hours}} hours.\nIn order to reserve another table, please cancel your previous reservation or call us.",
                    "cc_deposit_transaction_failed": "Action Failed",
                    "back_to_create_reservation_button": "Create New Reservation",
                    "call_restaurant_button": "Call Restaurant",
                    "modify_not_allowed_title": "Change of Plans?",
                    "modify_not_allowed_body": "Life happens, but unfortunately it's too close to the reservation to modify it online"
                },
                "confirm": "Okay",
                "cancel": "Cancel",
                "retry": "Retry",
                "continue": "Continue",
                "back": "Back"
            },
            "user_abandon_warning": "You are about to leave the page. The changes you made will be lost",
            "online_booking_blocked_description": "Online reservations are currently unavailable. Please call the restaurant."
        },
        "reservation_details": {
            "date_label": "On ",
            "time_label": "At",
            "arriving_within_label": "Arriving Within",
            "people": "{{value}}"
        },
        "feedback": {
            "feedback_form": "Feedback Form",
            "possible_answers": "Possible Answers",
            "mandatory_questions": "Mandatory Questions",
            "guest": "Guest",
            "description": "Hello {{name}},\nWe would appreciate your feedback",
            "conclusion": {
                "title": "Thank you for your feedback!",
                "satisfied_customer_sub_title": "We're looking forward to seeing you again soon",
                "unsatisfied_customer_sub_title": "Sorry for the inconvenience.\nYour feedback has been sent to the restaurant manager, and we will continue to do our best to improve your experience."
            },
            "button_label": {
                "confirm": "Send Feedback",
                "poor": "Poor",
                "fair": "Fair",
                "good": "Good",
                "very_good": "Very Good",
                "excellent": "Excellent"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Answer Missing",
                    "fill_mandatory_fields_body": "Please answer all the questions"
                },
                "confirm": "Okay"
            },
            "unsubscribe": {
                "link": " Unsubscribe from {{organization}} surveys",
                "success": "You've successfully unsubscribed from future surveys.",
                "error": "There was an unexpected error unsubscribing from future surveys. Please try again later or contact the restaurant."
            }
        },
        "deposit": {
            "description": "Please fill in your credit card details as a deposit.",
            "card_number": "Card Number",
            "cvv": "CVV",
            "year": "Year",
            "month": "Month",
            "id_number": "ID Number",
            "zip_code": "ZIP Code",
            "date_passed": "Invalid Date",
            "invalid_cc_number": "Invalid Card Number",
            "invalid_id_number": "Invalid ID Number",
            "button_label": {
                "save": "Save",
                "confirm_and_continue": "Confirm & Continue",
                "cancel": "Cancel Reservation",
                "continue": "Continue",
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Required Fields",
                    "fill_mandatory_fields_body": "Please fill in all required fields",
                    "submit_error_title": "Deposit unsuccessful",
                    "submit_error_body_general": "Check the form details and try again",
                    "submit_error_body_invalid_cc": "Check the credit card details and try again"
                },
                "confirm": "Okay"
            },
            "amount_details": {
                "title": "Deposit",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{seats_count}} X {{currency_symbol}}{{amount_per_guest}}"
            },
        },

        "cc_deposit": {
            "description": "A credit card deposit of {{currency_symbol}}{{amount_per_guest}} per guest is required to confirm the reservation.\nThe reservation can be canceled free of charge up to {{time_limit_cancel}} prior to the time of reservation.\nTo complete the reservation, please fill in the following details",
        },

        "deposit_payment": {
            "description": "The reservation is conditioned on a deposit of {{currency_symbol}}{{amount_per_guest}} per guest.\nThe booking can be canceled free of charge up to {{time_limit_cancel}} prior the time of reservation and in this case the deposit payment is fully refundable.\nTo complete the process proceed to payment.",
            "illegal_amount": "Illegal Amount"
        },

        "advanced_payment": {
            "description": "Your reservation requires an advanced payment of {{currency_symbol}}{{amount_per_guest}} per guest.\nIf you cancel your reservation up to {{time_limit_cancel}} ahead of time, you will be refunded the full advanced payment.\nTo complete your reservation, please fill in the following details",
            "amount_details": {
                "title": "Advanced payment amount",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },

        "health_statement": {
            "title": "Health Statement",
            "customer_thanks": "Thank you {{first_name}} {{last_name}}",
            "health_statement_saved": "The health statement has been saved",
            "health_statement_not_found": "Health Statement Not Found",
            "share_health_statement_form_button": "Share Health Statement with the Other Guests",
            "share_health_statement_form_text": "Hey,\nI booked us a table at {{organization}}\nWe need to fill a health statement:\n{{link}}",
            "no_fever_statement": "I declare that I have taken a body temperature today, and it was found below 38 degrees celsius",
            "no_cough_statement": "I declare that I do not cough and that I have no breathing difficulties except difficulty in breathing due to a chronic condition such as asthma or other allergy",
            "coronavirus_law": "Per regulation 3a(1) for Emergency Situation - Corona Virus"
        },

        "smart_banner": {
            "title": " Hi!",
            "body": "Want to download the app?"
        },

        "server_time_client_diff_dialog": {
            "title": "Warning",
            "content": "Your device's clock is off from the restaurant clock by {{time_diff}}\nAll time values will be displayed according to the restaurant clock.",
            "ok_button": "Okay"
        },

        "site-links": {
            "address_screen_reader": "restaurant found:",
            "from_screen_reader": "open from",
            "up_until_screen_reader": "up until",
            "phone_number_screen_reader": "phone number:",
            "to_call_screen_reader": "for calling, press here",
            "to_navigate_screen_reader": "for navigation directions, press here",
            "show_work_hours_screen_reader": "for restaurant working hours, press here",
            "to_web_site_screen_reader": "for the restaurant website, press here",
        },

        "dialog_screen_reader": {
            "default": "dialog opened",
            "before_you_pay_suggestion": "dialog opened: dish suggestions before payment",
            "order_scheduling": "dialog opened: schedule delivery or take away time has opened",
        },

        "forms_screen_reader": {
            "sign-up": {
                "pop_up_announcement": "A sign in pop up has appeared, please fill in your phone number for verification",
                "missing_fields": "the following mandatory fields are missing or not filled correctly:",
                "phoneControl": "your mobile phone number",
            },

            "to-desktop-sign-up": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "cPhone": "your mobile phone number",
                "email": "your email address",
                "firstName": "your name",
                "lastName": "your surname",
                "apartment": "your apartment number",
                "floor": "your floor number",
                "address": "your full address",
                "terms": "acknowledgement of the privacy policy and tems of use"
            },

            "customer_details": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "email": "your email address",
                "first_name": "your name",
                "last_name": "your surname",
                "notes": "notes",
                "phone": "your mobile phone number",
            },

            "loyalty_sign_up": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "firstName": "your name",
                "lastName": "your surname",
                "email": "your email address",
                "mobile": "your mobile phone number",
                "zehut": "your i.d number",
                "membershipPrice": "recognition of sign up added cost",
                "clubTerms": "acceptance of club's terms and conditions",
            }
        },

        "social-share": {
            "share": "press to share",
            "add_to_my_calender": "press to add to calander",
            "share_label": "Share",
            "calendar_label": "Add to Calendar"
        },

        "contact-details": {
            "location": "press for navigation directions",
            "phone": "press for calling the restaurant",
            "location_label": "Navigate",
            "phone_label": "Call",
            "menu_label": "Menu",
            "choose_menu": "Select Menu",
            "no_available_menu": "No Menu Available",
            "menu_error_dialog_title": "Something Went Wrong",
            "menu_error_dialog_body": "Please try again later"
        },

        "TO-DELETE": "To delete",
        "TO-EDIT": "To edit",
        "TO-ADD": "Add",
        "TO-UPDATE": "To update",
        "CANCEL": "Cancel",
        "NO_THANKS": "No, thanks",
        "CLEAR_SELECTION": "Clear selection",
        "ADD": "Add",
        "UPDATE": "Update",
        "CASH": "Cash",
        "THANK_YOU": "Thank you",
        "TIP": "Tip",
        "PAYED": "Paid",
        "CONTINUE": "Continue",
        "CART_SUBTOTAL": "Cart subtotal",
        "TOTAL_IN_TIP": "You pay",
        "PARTIAL_PAY_IN_TIP": "Partial payment",
        "CLEAR": "Clear",
        "APPROVE": "Approve",
        "REMAINED_PAY": "Balance Due",
        "REMAINED_PAY_CASH": "Balance Due - Cash",
        "TO_PAY": "Proceed to payment",
        "SAVE_CARD_INFO": "Save card details",
        "TOTAL": "Total",
        "CARD_HOLDER_NAME": "Card Holder Name",
        "CARD_NUMBER": "Card Number",
        "SIGNIN": "Sign in",
        "SIGNIN_INFO": "Enter sign-in details",
        "VALIDATING_CREDENTIALS": "Validating...",
        "WELCOME_BACK": "Welcome back",
        "SELECT_EXISTING_CARD": "Select card",
        "ADD_NEW_CARD": "Save card for future use",
        "SAVE_CARD_AS_DEFAULT": "Save as default card",
        "ORDER_TOTAL": "Order total",
        "ORDER_VAT_TOTAL": "(Include VAT {{currency}} {{total}})",
        "FOREIGN_CURRENCY_TOTAL": "Estimated in {{symbol}}",
        "ORDER_TIP": "Tip",
        "PAID": "Paid",
        "ENTER_ORDER_N": "Enter order code",
        "TO_HOMEPAGE": "Homepage",
        "VIEW_MORE": "View more",
        "VIEW_LESS": "View less",
        "REQUIERED": "Required",
        "REQUIERED_CHECK": "Required",
        "EDIT": "Edit",
        "PASSWORDS_DONT_MATCH": "Passwords do not match",
        "PASSWORDS_MIN_LENGTH": "Passwords must be at least 6 characters long",
        "INVALID_CREDIT_NUMBER": "Invalid credit number",
        "INVALID_CVV": "Invalid CVV",
        "INVALID_CARD_EXPIRATION": "Invalid expiration date",
        "INVALID_EMAIL": "Invalid Email",
        "EMAIL_FOR_INVOCE": "email for invoice",
        "CARD_EXPIRATION": "Expiration date",
        "CARD_HOLDER_CELL": "Card holder cellphone",
        "ROOM_OWNER_CELL": "Send the confirmation code to: ",
        "CARD_HOLDER_MAIL": "Card holder email",
        "Q_SHARING_BILL": "For partial payment",
        "Q_TIP_AMOUNT": "Please set tip",
        "Q_HOW_MUCH_PAY": "Set amount?",
        "Q_HOW_MUCH_SPLIT": "Split between",
        "Q_REGISTARED": "Already registered?",
        "Q_NEW_REGISTARED": "New user? Register...",
        "Q_INVOICE_BY_MAIL": "Receive invoice by email?",
        "Q_REMOVE_CARD": "Are you sure you want to delete this card?",
        "Q_REMOVE_PAYMENT": "Are you sure you want to delete this payment?",
        "SIGNIN_REGISTER": "Sign in / Register",
        "END": "Finish",
        "ENTER_TIP": "Tip",
        "ENTER_AMOUNT": "Custom amount",
        "SPLIT_AMOUNT": "Split",
        "YOUR_AMOUNT_TO_PAY": "You pay",
        "PRESS_HERE": "Tap Here",
        "OTHER": "Other",
        "SENDING_INVOICE": "Sending invoice...",
        "CHALLENGE_CODE": "Confirmation code",
        "ENTER_CHALLENGE_CODE": "Enter confirmation code",
        "REMEMBER_ME_ON_PHONE": "Remember me on this device",
        "BILL_AMOUNT": "Check",
        "PAY_WITH_ONOTHER_CARD": "Pay with another card",
        "PAYMENT_METHOD": "Payment Method",
        "SELECT_PAYMENT_METHOD": "Choose Payment Method",
        "CREDIT_CARD": "Credit Card",
        "CREDIT": "Credit",
        "creditCard": "Credit",
        "CreditGuard": "CreditGuard",
        "CIBUS": "Cibus",
        "Cibus": "Cibus",
        "CIBUS_CARD": "Cibus card",
        "CIBUS_CARD_NUMBER": "Cibus card number",
        "TENBIS": "10bis",
        "10bis": "10bis",
        "10bis_card": "10bis card",
        "10bis_card_number": "10bis card number",
        "loyaltypm_card_number": "Card number",
        "EDIT_ACCOUNT_SETTINGS": "Edit account settings...",
        "MY_ACCOUNT": "My Account",
        "SIGN_OUT": "Sign Out",
        "CLUB_SIGN_OUT": "Log out of Loyalty account",
        "VOUCHER_SIGH_OUT": "Remove coupon from this order",
        "COMPS_SIGH_OUT": "Remove comps from this order",
        "EXPIRED_SOON_SIGN_OUT": "Cancel renewal",
        "EXPIRED_SOON_APPROVE": "Renew my membership",
        "REMOVE_VOUCHER": "Yes, remove coupon",
        "REMOVE_COMPS": "Yes, remove member",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION": "Remove Loyalty member from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_TEXT": "Signing out will cancel your rewards eligibility",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_VOUCHER": "Remove coupon from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_COMPS": "Remove Loyalty member from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_EXPIRED_SOON": "Cancel membership renewal?",
        "MY_ACCOUNT_DETAILS": "My account",
        "EDIT_PAYMENT_INFO": "Edit payment options",
        "DELETE_CARD": "Delete card",
        "SET_DEFAULT_CARD": "Set as default",
        "YES_DELETE": "Yes, delete it",
        "OFFER_REMARKS": "Item Remarks",
        "OFFER_DONE": "I'm done, thanks!",
        "ENTER_OFFER_REMARKS": "Add notes",
        "DELETE_PAYMENT": "Delete Payment",
        "CONFIRM_CELL": "Confirm Phone",
        "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your cellphone",
        "USE_ENTERED_PHONE": "Send to:",
        "ORDER-TOTAL": "Order total",
        "WITHOUT_GRATUITY": "Without gratuity",
        "ORDER_SCHEDULING": "When do you prefer to get your order?",
        "SELECT_SERVICE_TYPE": "Service type",
        "ENTER_ALTERNATE_PHONE": "Use other:",
        "CLOSE_DIALOG_BOX_SCREEN_READER": "close dialog window",
        "CLOSE": "Close",
        "YES": "Yes",
        "NO": "No",
        "SEND_ORDER": "Send order",
        "TRACK_YOUR_ORDER": "Track your order",
        "YOUR_ORDERS": "My orders",
        "ORDER_CREATION_TIME": "Creation time",
        "CONTINUE_ORDERING": "Continue ordering",
        "ADD_ITEM": "Add item",
        "SELECT_ITEM": "Select item",
        "UPDATE_ITEM": "Update item",
        "TOPPINGS": "Toppings",
        "DECISIONS": "Decisions",
        "ITEM_CHANGES": "Item changes",
        "SELECTION_GROUP_SELECT": "(Select {{count}} items)",
        "SELECTION_GROUP_SELECT_SINGLE": "(Select one item)",
        "SELECTION_GROUP_SELECT_MIN": "(Select at least {{count}} items)",
        "SELECTION_GROUP_SELECT_SINGLE_MIN": "(Select at least one item)",
        "GROUP_MOD_SELECT_ERROR": "Select at least {{count}} additional items from: {{group}}",
        "GROUP_MOD_SELECT_SINGLE_ERROR": "Select at least one from: {{group}}",
        "GROUP_SELECT_ERROR": "Select at least {{count}} items from: {{group}}",
        "GROUP_SELECT_SINGLE_ERROR": "Select at least one item from: {{group}}",
        "SELECTION_GROUP_OPTIONAL_MAX": "Optional, select up to {{count}} items",
        "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "Optional, select up to one item",
        "CHECKOUT": "Checkout",
        "Q_REMOVE_OFFER_FROM_BASKET": "Remove this item?",
        "ENTER_DELIVERY_ADDRESS": "Enter delivery address",
        "SELECT_ADDRESS": "Select address",
        "BRANCH": "Branch",
        "CHOOSE_BRANCH": "Choose restaurant",
        "ADDRESS": "Address",
        "PHONE": "Phone",
        "ENTRANCE": "Entrance",
        "FLOOR": "Floor",
        "ENTER_STREET_INCLUDING_NUMBER": "Please enter full address, inc. number",
        "MISSING_STREET_NUMBER": "How can we find you?",
        "START_ORDER": "Start order",
        "MAKE_AN_ORDER": "Proceed to order",
        "SELECT_CITY": "Select city",
        "SELECT_DELIVERY_CITY": "Select delivery city",
        "SELECT_BRANCH": "Select branch",
        "ORDER_MENU": "Menu",
        "eatin": "Eat in",
        "ADDITIONAL_DETAILS": "Additional details​",
        "RESET_ORDER": "Reset order",
        "ORDER_FROM": "Order from",
        "ORDER_TO": "Order to",
        "CONTACT_INFO": "Contact info",
        "CONTACT_ALCOHOL_DECLARATION": "I am {{age}}+ years of age and will provide identification to verify alcohol purchase",
        "PAYMENT_INFO": "Payment info",
        "PAYMENT_INFO_MISSING": "We are missing some payment details for card {{cardLastDigits}}",
        "FIRST_NAME": "First name",
        "LAST_NAME": "Last name",
        "CELL": "Cell",
        "EMAIL": "Email",
        "REVIEW_YOUR_ORDER": "Review your order",
        "REVIEW_YOUR_ORDER_BUTTON": "View Receipt",
        "OKKAMI_APP_LINK": "Go Back to OKKAMI App",
        "ADD_ITEMS": "Add items",
        "UPDATE_ITEMS": "Update items",
        "ORDER_SENT_SUCCESS": "Order sent successfully",
        "ORDER_NEED_ATTENDANCE": "Order sent with an error",
        "CALL_RESTAURANT_TO_COMPLETE_ORDER": "Please call the restaurant to complete your order",
        "ORDER_NUMBER": "Order number",
        "START_NEW_ORDER": "Begin a new order",
        "ORDER_ITEMS": "Order items",
        "ORDER_WILL_BE_SENT_TO": "Order will be sent to",
        "ESTIMATED_DELIVERY_TIME": "Estimated delivery time",
        "ESTIMATED_PREPARATION_TIME": "Estimated preparation time",
        "ESTIMATED_SUPPLY_TIME": "Estimated supply time",
        "MINUTES": "Minutes",
        "PREPARATION_TIME": "Preparation time",
        "ORDER_TYPE": "Order type",
        "PLEASE_SELECT": "Please select",
        "PLACEMENT": "Placement",
        "NUMBER_OF_PEOPLE": "Number of diners",
        "READ_AND_APPROVE_CONTRACT": "I've read and accept the terms & conditions",
        "READ_AND_APPROVE_CONTRACT_C": "To the terms & conditions",
        "READ_CONTRACT": "Read terms & conditions..",
        "PLEASE_APPROVE_CONTRACT": "Please approve terms",
        "USAGE_CONRACT": "Terms & condition",
        "ADDRESS_REMARKS": "Address remarks",
        "LOADING_MENU": "Loading menu...",
        "MOD_WITH": "Add-Ons",
        "MOD_WITHOUT": "Without",
        "PASSWORD": "Password",
        "CONFIRM_PASSWORD": "Confirm password",
        "FORGOT_PASSWORD": "Forgot password...",
        "RESTORE_PASSWORD": "Restore password",
        "INCLUDE_SILVERWARE": "Include Silverware",
        "LEAVE_DELIVERY": "Leave delivery at door",
        "CURBSIDE_PICKUP": "Curbside pickup",
        "CURBSIDE_PICKUP_DESC": "Enter car details: Model, Color, License number...",
        "CURBSIDE_PICKUP_ESC": "Enter car details: Model, Color, License number...",
        "AUTHENTICATING": "Authenticating",
        "SIGNED_IN_AS": "Signed in as",
        "CONFIRM_SIGN_OUT": "Yes, sign out",
        "PAY_WITH_SAVED_PMS": "Saved payment options",
        "SIGHN_IN_EMAIL": "Sign-in Email",
        "SIGHN_IN_PASSWORD": "Sing-in password",
        "UPDATE_PAYMENT_INFO": "Update payment info",
        "UPDATE_ACCOUNT": "Update account",
        "ADD_PAYMENT_INFO": "Add payment info",
        "UPDATING_ACCOUNT": "Updating account...",
        "UPDATE_SIGNIN_INFO": "Update sign-in info",
        "PASS_MIN_LENGTH_ERROR": "Password must contain at least 6 characters",
        "OLD_PASSWORD": "Old password",
        "NEW_PASSWORD": "New password",
        "BRANCH_TIME_CONFIRM_0": "The restaurant is currently closed. This service is available between {{t0}}",
        "BRANCH_TIME_CONFIRM_1": "The restaurant is currently closed. This service is available between {{t0}} - {{t1}}",
        "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "This service has closed. It's available between {{t0}}",
        "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "This service has closed. It's available between {{t0}} - {{t1}}",
        "BRANCH_TIME_0": "Order service is active between {{t0}}",
        "BRANCH_TIME_1": "Order service is active between {{t0}} - {{t1}}",
        "CONFIRM": "Confirm",
        "ORDER_SUCCESS": "Order success",
        "MIN-ORDER-PRICE": "Minimum order price",
        "DELIVERY-PRICE": "Delivery price",
        "TOTAL-ITEMS": "Total items",
        "TOTAL-TO-PAY": "Total to pay",
        "ADDITIONAL_FEES": "Additional Fees",
        "ADDITIONAL_SURCHARGERS": "Additional Surcharges",
        "MENU_ITEM_SEARCH": "Search Menu",
        "MENU_ITEM_SEARCH_NO_RESULTS": "No results for \"{{searchValue}}\" 🧐",
        "MENU_ITEM_SEARCH_OTHER": "Try another search?",
        "MENU_ITEM_SEARCH_CLEAN": "Reset results",
        "PROMOTION_MIN_ORDER_PRICE": "* For orders over {{currency}}{{minOrderPrice}}",
        "WITHOUT": "Without",
        "CONTINUE_TO_PAYMENTINFO": "Continue to payment info",
        "PAYMENT": "Payment",
        "BRANCHES": "Branches",
        "SERVER_ERROR": "Server error",
        "ERROR": "Error",
        "PAYMENT_ERROR": "Payment error",
        "PARTIAL_PAYMENT_ERROR": "Partial payment error",
        "NAME": "Name",
        "REGISTERING": "Registering",
        "REGISTER": "Register",
        "RESET_PASSWORD": "Reset password",
        "RESET_PASSWORD_INFO": "Enter new password and select Reset",
        "ORDER_HISTORY": "Order History",
        "SELECT_ORDER_METHOD": "Select order method",
        "ORDER_METHOD": "Order method",
        "ORDER_FIXED": "Bill",
        "SET_QUANTITY": "Set quantity",
        "CELL_PATTERN": "Cellphone number must begin with 05",
        "CELL_MINLENGTH": "Minimum of 10 digits for cellphone number",
        "VIEW_MENU": "View menu",
        "ORDER_SERVICE_CLOSED": "Order service closed",
        "SET_ORDER_DELAY": "Set order delay... ",
        "DELAY_TO": "Hour delay",
        "SET_ORDER_DELAY_TO_HOUR": "Delay order by an hour",
        "SAVE_ACCOUNT_PAYMENT_INFO": "Save your card in our digital wallet for quicker payments on upcoming orders",
        "ASAP": "ASAP",
        "PRMARY_PAYMENT_METHOD": "Default method",
        "ADD_PAYMENT": "Add payment",
        "SPLIT_PAYMENT": "Split payment",
        "SPLIT_PAYMENTS_DIFF_ERROR": "Payments don't balance at {{total}}. {{diff}} differ occurs",
        "BACK_TO_SITE": "Back to the website",
        "BACK_TO_APP": "Back to the App",
        "FREE_DELIVERY_FROM": "Free delivery from",
        "ADD_REMAINNING_AMOUNT": "Add remaining amount",
        "PAYMENTS_EXCEED_TOTAL_BY": "Payments exceed total by",
        "PAYMENT_AMOUNT": "Payment amount",
        "ENTER_FIRST_PAYMENT_AMOUNT": "Enter first payment amount",
        "EXCESS_PAYMENT": "Excess payment",
        "CLICK_TO_REMOVE_EXCESS_AMOUNT": "Click to remove excess amount",
        "CANCEL_SPLIT_PAYMENT": "Cancel payments split",
        "APPLY_PAYMENTS": "Approve payments",
        "SINGLE_PAYER": "Single payer",
        "MULTIPLE_PAYERS": "Number of payers",
        "SPLIT_ORDER_BETWEEN_PAYERS": "Split order between payers",
        "CALL_RESTAURANT": "Call restaurant",
        "SELECT_TABLE": "Select table",
        "TABLE_NO_ORDER": "Table number {{no}}",
        "CHHOSE_ROOM_NO": "What is your room number?",
        "ROOM_NO": "Room number",
        "ENTER_ORDER_NO": "Enter order number",
        "ORDER_DATE": "Order date",
        "ORDER_TIME": "Order time",
        "ADD_GRATUITY_TO_COURIER": "Add Tip",
        "ORDER_TOTAL_WITHOUT_GRATUITY": "Total (without gratuity)",
        "ORDER_TOTAL_WITH_GRATUITY": "Total (inc. gratuity): {{total}}",
        "GRATUITY_PERCENT": "gratuity percent",
        "ENTER_GRATUITY_AMOUNT": "Enter gratuity amount",
        "CONTINUE_TO_GRATUITY": "Continue to gratuity",
        "REFRESH_ORDER": "Refresh order",
        "MONTH": "Month",
        "YEAR": "Year",
        "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "Enter amount to pay, without gratuity",
        "ENTER_AMOUNT_TO_PAY": "Enter amount to pay",
        "FULL_PAYMENT": "Full payment",
        "PARTIAL_PAYMENT": "Partial payment",
        "AMOUNT_EXEEDS_BALANCE_DUE": "Amount exceeds balance due",
        "BACK": "Back",
        "WORK_HOURS": "Business Hours",
        "RESTAURANT_WEBSITE": "Restaurant Website",
        "SELECT_ONE": "Select one",
        "DELIVERY_ADDRESS": "Delivery address",
        "DELIVERY_DETAILS": "Delivery details",
        "ADDRESS_DETAILS": "Address details",
        "BRANCH_DETAILS": "Restaurant details",
        "ADDITIONAL_OPTIONS": "Additional options",
        "MINUTES_VAL": "{{m}} minutes",
        "ADDITIONAL_OFFERS": "Additional offers",
        "SELECT_ADDITIONAL_OFFERS": "Choose additional offers",
        "CONTINUE_TO_CONTACTINFO": "Continue to contact info",
        "CC_CUSTOMER_NAME": "Name",
        "CC_CUSTOMER_ID": "ID / BN Number",
        "ORDERER_NAME": "Orderer name",
        "ORDERER_PHONE": "Phone",
        "ORDER_NO": "Order number",
        "REMOVE": "Remove",
        "apartment": "Apartment",
        "takeaway": "Takeout",
        "delivery": "Delivery",
        "PAY_WITH": "Pay with",
        "SELECT_ALTERNATE_PYMENT": "Select another payment method",
        "ENTER_CVV": "Enter CVV",
        "PLEASE_ENTER_CVV": "Please enter CVV",
        "ENTER_CVV_MESSAGE": "Enter CVV for Card: {{card}}",
        "ENTER_IDCARD": "Enter ID Number",
        "ENTER_IDCARD_MESSAGE": "Enter ID for Card: {{card}}",
        "ADD_GRATUITY_TO_ORDER": "Add Tip",
        "GRATUITY_AMOUNT": "Tip Amount",
        "PASS_ORDER": "Sharing the bill?",
        "PASS_ORDER_TITLE": "Share with a friend",
        "PASS_ORDER_BY_PHONE_MESSAGE": "To receive a link by SMS text, please enter the phone number",
        "PASS_ORDER_BY_PHONE_CAPTION": "Cellphone number",
        "PASS_ORDER_BY_PHONE_SEND": "Send order",
        "PASS_ORDER_BY_PHONE_QR_MESSAGE": "OR scan the QR code",
        "PASS_ORDER_SENT_SUCCESS": "Order sent successfully",
        "CANNOT_SEND_ORDER_BY_SMS": "We apologize, but the order cannot be sent at the moment",
        "ID_CARD": "ID Number",
        "ORDER_TAGS": {
            "BREAKFAST": "Breakfast",
            "LUNCH": "Lunch",
            "DINNER": "Dinner",
            "WORK": "Business"
        },
        "ENTER_LOCALITY": "Enter Location",
        "TITLE": "{{name}} - TabitOrder",
        "TITLE_1": "{{name}} TabitPay",
        "TITLE_2": "{{name}} - TabitOrder",
        "PAYMENT_IN_PROGRESS": "Loading...",
        "CREATING_ORDER": "Loading...",
        "LOADING_ORDER": "Loading Order...",
        "INVALID_AMOUNT": "Invalid Amount",
        "INVALID_AMOUNT_RANGE": "Valid amount between {{min}} to {{max}}",
        "BACK_TO_MENU": "Back to menu",
        "ORDER_NOTES": "Order Notes",
        "Q_SAVEPM_1": "Interested in a quicker order process?",
        "Q_SAVEPM_2": "You can save your payment method in Tabit's digital wallet for quicker and faster payments",
        "Q_SAVEPM_3": "Pay and Save",
        "Q_SAVEPM_4": "Pay without saving",
        "DISH_CALCULATOR": "Dish Calculator",
        "MAKE_ADDITIONAL_PAYMENT": "Make additional payment",
        "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Make additional payment on this device",
        "DINER": "Diner",
        "GENERAL_DINER": "Center of Table",
        "ADDRESS_WITHOUT_HOUSE": "Address without house no",
        "TAX_AMOUNT": "Tax",
        "VAT_AMOUNT": "VAT",
        "INCLUSIVE_TAXES": "Inclusive Tax",
        "ADDRESS_TYPE": "Address Type",
        "VALID_UNTILL": "Valid Until",
        "share_message": "Hi, here's {{name}}, a restaurant I found on Tabit. Check it out!",
        "share_message_without_site": "Hi, here's a restaurant I found on Tabit. Check it out!",
        "ADDRESS_DESKTOP_HEADER": "Just checking if we deliver to your area",
        "ADDRESS_DESKTOP_ANNOUNCEMENT_SCREEN_READER": "An address search window has opened, please write your city, street name and house number",
        "RESTAURANT_DESKTOP_SEARCH_ANNOUNCEMENT_SCREEN_READER": "An restaurant search window has opened, please write your city, street or restaurant name",
        "SITES_DESKTOP_HEADER": "Choose restaurant for takeout",
        "SITES_DESKTOP_MENU_HEADER": "Choose restaurant to view the menu",
        "CHAIN_DIALOG_HEADER": "Choose a restaurant",
        "FUTURE_DELIVERY_ORDER_DIALOG_HEADER": "Choose delivery time",
        "WHERE_CAN_WE_FIND_YOU": "Where can we find you?",
        "FUTURE_ORDER_DIALOG_HEADER": "When will we see you?",
        "FUTURE_ORDER_POP_ANNOUNCEMENT_SCREEN_READER": "Future order scheduler screen opened: please select the date in the left column, and the time in the right column",
        "SLIP": {
            "OTH": "OTH",
            "ITEM_DISCOUNT": "Item discount",
            "TICKET_DISCOUNT": "Ticket discount",
            "TICKET_OTH": "OTH",
            "CHANGE": "Change"
        },
        "GROUP_VALIDATION": {
            "SELECT_ONE": "Please select 1",
            "SELECT_SOME": "Please select {{min}}",
            "SELECT_ATLEAST_ONE": "Please select at least 1",
            "SELECT_ATLEAST_SOME": "Please select at least {{min}}",
            "SELECT_UPTO_ONE": "Please select up to one",
            "SELECT_UPTO_SOME": "Please select up to {{max}}",
            "SELECT_BETWEEN": "Please select between {{min}} and {{max}}",
            "GROUP_EXCEPTION": {
                "TITLE": "Update your order",
                "BODY": "Parts of this item have changed since you last ordered. Please update your order according to the new details",
            },
        },
        "ACCESS": {
            "service_selection": "Service selection: Please select service",
            "tabit_logo": "Tabit logo",
            "call": "call",
            "gift-card-redeem": "עמוד מימוש כרטיס מתנה",
            "payment-dialog-open": "payment dialog open",
            "login-successfully": "The login was completed successfully!",
            "contact-details": "restaurant contact details",
            "site_service": "restaurant services",
            "address": "address",
            "distance": "Distance",
            "minus_circle": "minus",
            "plus_circle": "plus",
            "accessibility_statement": "Accessibility statement. The site supports NVDA screen reader.",
            "deselected": "item removed",
            "unselected": "unselected",
            "selected": "item selected",
            "ITEM_ADDED_PLUS": "item added",
            "same-page-link": "same page link",
            "BASKET": "basket",
            "right-arrow-nav": "right arrow navigation",
            "left-arrow-nav": "left arrow navigation",
            "restaurant": "restaurant",
            "ACCESSIBILITY_DECLARATION": "Accessibility declaration",
            "ACCESSIBILITY": "Accessibility",
            "ACCESSIBILITY_STATEMENT": "Accessibility Statement",
            "ACCESSIBILITY_SITE": "Go to the Accessible Site",
            "ACCESSIBILITY_IN_PROGRESS": "",
            "CLEAR_ACCESSIBILITY_FEATURES": "Clear accessibility features",
            "monochrom": "Monochrome",
            "light-contrast": "Light contrast",
            "dark-contrast": "Dark contrast",
            "enlarge-font": "Enlarge font",
            "reduce-font": "Reduce font",
            "readable-font": "Readable font",
            "big-white-curser": "Big black cursor",
            "big-black-curser": "Big white cursor",
            "enlarge-frame": "Enlarge",
            "highlight-links": "Highlight links",
            "highlight-headers": "Highlight headings",
            "image-info": "Image info",
            "back-button": "return to previous page",
            "enter_amount": "Gift Card Amount ({{min_amount}} to {{max_amount}})",
            "delete": "delete",
            "view-menu": "view menu",
            "add": "add",
            "remove": "remove",
            "edit": "edit",
            "day": "choose a day",
            "time": "choose a time",
            "guests": "select number of guests",
            "site-main-image": "Main site image",
            "language_screen_reader": "select language",
            "future-reservation-time": "when are you arriving?",
            "close": "close",
            "close_dialog": "close dialog",
            "delete_content": "delete filled in content",
            "out_of_stock": ":currently out of stock",
            "press_to_approve_and_continue_to_menu": "press to approve and continue to the menu page",
            "service_buttons_takeaway": "take await",
            "service_buttons_delivery": "delivery",
            "service_buttons_delay": "future date order",
            "service_buttons_eatin": "eat in order",
            "service_buttons_future_takeaway": "future take await",
            "service_buttons_future_delivery": "future delivery",
            "item_added": "one item has been added to your basket",
            "selection": "selection confirmed",
            "contrast-button": "contrast",
            "invalid-form": "Not all mandatory fields have been filled in, please fill in the required fields",
            "remove-payment_line": "remove payment line",
        },
        "_WEIGHT": {
            "units": "Unit",
            "price_per_weight": "Price per {{unit}} = {{price}}",
            "item_weight": "Item weight",
            "kg": "Kg (kg)",
            "gr": "Gram (gr)",
            "lb": "lb (lb)",
            "oz": "Oz (oz)",
            "l": "L (l)",
            "ml": "ml (ml)",
            "GRAM": "Gram"
        },
        "_DELAYED": {
            "service_caption": "For another day",
            "title": "Order timing",
            "method": "Delivery timing",
            "for_today": "For today",
            "for_other_day": "For another day",
            "service_today": "For today",
            "service_future": "Future order",
            "for_hour": "For {{val}} hour",
            "for_date": "For {{val}} day",
            "between": "between",
            "at_hour": "at",
            "select_date": "Select date",
            "select_slot": "Select hour",
            "select_slot_prompt": "Select supply date",
            "supply_time": "Supply time"
        },
        "CHECKOUT_STEP": {
            "extra_offers": "Extra offers",
            "contact": "My details",
            "gratuity": "Tip",
            "pay": "Pay",
            "summary": "Summary",
        },
        "ADDRESS_TYPES": {
            "apartment": "Apartment",
            "office": "Office",
            "house": "House"
        },
        "WEB_HEADER": {
            "table_booking": "Reserve a table",
            "order_delivery": "Takeout / Delivery",
            "restaurants": "Restaurants"
        },
        "date": "Date",
        "day": "Day",
        "month": "Month",
        "year": "Year",
        "from_you": "from you",
        "restaurants": "Restaurants",
        "restaurants_subtitle": "Find your favorite restaurant",
        "order": "Order",
        "seated": "Seated",
        "order_subtitle": "Takeout or Delivery",
        "order_action": "Order",
        "order_balance": "Current balance",
        "book": "Book",
        "book_subtitle": "Reserve a table",
        "book_tables_standby_subtitle": "Select a restaurant to be added to the waiting list",
        "book_tables_standby_title": "Waiting list",
        "book_tables_subtitle": "Select a restaurant to be seated immediately",
        "book_tables_title": "Available tables",
        "booking_description": "Just say when and we'll find a table for you!",
        "book_action": "Book",
        "pay": "Pay",
        "pay_subtitle": "Pay here with your cellphone",
        "pay_action": "Pay",
        "default": "Default",
        "save": "Save",
        "okay": "Okay",
        "OK": "Okay",
        "close": "Close",
        "back": "Back",
        "continue": "Continue",
        "register": "Register",
        "registration": "Register",
        "reorder_action": "Re-order NOW!",
        "redirect_to_device_settings": "Redirect to phone settings",
        "next_time": "Next time",
        "redirect_to_profile": "Redirect to profile",

        "location_select": "Location select",
        "my_location": "My Location",
        "my_location_unavailable": "Actual location unavailable",
        "change_location": "Change",

        "website": "Website",
        "privacy_policy": "Privacy Policy",
        "club_terms": "Loyalty Terms",
        "terms_and_conditions": "Terms and Conditions",
        "account_removal": "Account Removal",
        "remove_account": "Remove Account",

        "km": "km",
        "mile": "miles",
        "more": "More",
        "far_away": "Far Away",
        "around_you": "Around you",
        "And": "And",
        "and": "and",
        "until": "until",

        "delivery_or_takeway": "Delivery or Takeout?",
        "order_type_description": "Please select your preference.",
        "order_type_title": "Delivery or Takeout?",
        "otcSeated": "Counter",
        "other": "Other",
        "partial_payment": "Partial payment",
        "partial-payment": "Partial payment",
        "goto-payment": "Payment",
        "add-items": "Add Items",
        "add-to-order": "Add to order",
        "send-order": "Send order",
        "add-to-order-with-amount": "Add to order {{amount}}",
        "back-to-order": "Back to order",

        "address_title": "Address",
        "add_address": "Add address",
        "my_recent_addresses": "My recent addresses",
        "my_default_addresses": "My default address",
        "please_confirm": "Please confirm...",
        "please_note": "Please note",
        "just_a_moment": "Just a moment",
        "dont_worry": "Don't worry",
        "please_enter_signature": "To approve payment, please sign here",
        "please_enter_value": "Please enter amount",
        "please_enter_location_value": "Please describe your location",
        "are_you_sure": "Please confirm",
        "delete_account": "Remove your account?",
        "accept": "Yes",
        "Cancel": "Cancel",
        "update_account": "Update details",
        "your rank": "Your rank",
        "you_have": "You have",
        "accumulated": "accumulated",
        "points": "Points",
        "prepaid_points": "Pre-paid points",
        "recent_actions": "Recent actions",
        "view_bill": "View bill",
        "goto-rest-page": "Go to restaurant page",
        "photos": "Photos",
        "openning_houres": "Openning hours",
        "open_now": "Open",
        "closed_now": "Closed",
        "will_be_open_at": "Will open at",
        "rank": "Rank",
        "slide_down_to_close": "Swipe down to close",
        "Open": "Open",
        "Open Now": "Open Now",
        "Closed": "Closed",
        "Closed Now": "Closed Now",
        "profile": "Profile",
        "update_details": "Update details",
        "reload_app": "Reload app",
        "closed_for_day": "Closed for today",
        "missing_site_description": "Missing restaurant description. Generating...",
        "show_more_sites": "Show more restaurants",
        "show_more": "Show more",

        "house": "House",
        "office": "Office",
        "entrance": "Entrance",
        "floor": "Floor",
        "no_addresses": "No addresses",

        "address_description": "Please select your address.",
        "new_address": "New Address",
        "saved_address": "Saved Address",
        "address_placeholder": "Street, City, State",
        "new_in_tabit": "New at Tabit",
        "address_type": "Address type",
        "please_select_type": "Please select a type",
        "address_not_found": "Address not found",
        "please_type_address_short": "Add a new address",
        "please_type_address_long": "Type a place, city, or a full address",
        "please_type_delivery_address_short": "Add delivery address",
        "please_type_delivery_address_long": "Type a full address, including number",
        "please_type_address_full": "Type a full address",

        "error_title": "Hmm...",
        "error_general": "An unknown error occurred. Please try again.",
        "error_invalid_cards_for_auto_attach": "An error occurred. Please get in touch with the restaurant.",
        "error_invalid_sign_in_credentials": "It seems your email or password is wrong. Please try again.",
        "error_invalid_touchid": "We could not verify your Face/Touch ID. Please log in using your cellphone number.",
        "error_invalid_load_sites": "An error occurred while loading the data. Please try again.",
        "error_invalid_sign_in_email_already_exists": "Email already exists, please enter another one.",
        "error_client_credentials": "An error occured. Please try again.",
        "error_cg_wallet_credentials": "Invalid card details. Please try again",
        "error_sign_up": "An error occured while Sign Up. Please try again.",
        "error_sign_up_user_already_exists": "A user with this email already exists. Please try to log in with this email and password.",
        "error_reset_password_missing_email": "Please enter your email first.",
        "error_reset_password": "Email is incorrect or not connected to a user. Please try again or Sign Up.",
        "error_full_address_required": "Please provide your full address, including house number",
        "error_invalid_address": "The selected address is not valid, please select another address",

        "confirmation_title": "Yay!",
        "confirmation_password_reset": "An email with instructions for how to reset your password has been successfully submitted.",

        "main": "Main",
        "login": "Sign In",
        "logout": "Sign Out",
        "email": "Email",
        "password": "Password",
        "forgot_your_password": "Forgot your password?",
        "birthDate": "Birthday",
        "anniversary": "Anniversary Date",
        "continue_with_email": "Continue with email",
        "or_continue_with": "Or continue with",
        "facebook": "Facebook",
        "google": "Google",
        "search": "Search",
        "restaurants_search": "Restaurant search",
        "occasions_search": "Occasion search",
        "categories": "Categories",
        "services": "Services",
        "name": "Name",
        "phone": "Phone",
        "send": "Send",
        "sending": "Sending",
        "enter_code": "Enter code",
        "verification_code": "Verification code",
        "verification_code_message": "This is your verification code {{pincode}}.",
        "confirm_code": "Submit",
        "did_not_arrive_resend": "Resend Code",
        "resent": "Sent again...",
        "sign_in": "Sign in",
        "sign_up": "New customer? Sign up!",
        "searching": "Searching",
        "tags": "Cuisine",
        "ratings": "Ratings",
        "price": "Price",
        "auth_touch": "Face/Finger recognition",
        "style": "Style",
        "accessibility": "Accessibility",
        "mood": "Mood",
        "kosher": "Kosher",
        "collective": "Collective",
        "events": "Events",
        "specials": "Specials",
        "suitable": "Suitable",
        "by_date": "By date",
        "by_restaurant": "By restaurant",
        "restaurant_type": "Restaurant type",
        "tabit_restaurants": "Tabit restaurants",
        "about_the_restaurant": "About the restaurant",
        "articles": "Articles",
        "paid": "Paid",
        "single_payer": "Single payer",
        "to_main_page": "To the homepage",
        "on_the_house": "On the house",
        "regulations": "Regulations",
        "input_pay_amount": "Enter amount to pay",
        "choose_payment": "Select payment method",
        "full-payment": "Full payment",
        "goto-tip": "Add Tip",
        "split_payment_by": "Split payment by",
        "show_code_to_staff": "Show code to the staff",
        "code_valid_time_1": "Code valid for 5 minutes. Charge will be processed only",
        "code_valid_time_2": "after the staff approves the code.",

        "my_account": "Account",
        "my_account_description": "You may edit your personal information or change your password.",
        "personal_information": "Personal Information",
        "first_name": "First Name",
        "last_name": "Last Name",
        "mobile": "Mobile",
        "cell": "Cell",
        "change_password": "Change Password",
        "old_password": "Old Password",
        "new_password": "New Password",
        "next": "Next",
        "notes": "Notes",
        "my_wallet": "Wallet",
        "my_wallet_info": "Payment method settings",
        "my_addresses": "Addresses",
        "my_addresses_info": "Saved addresses for delivery",

        "clear": "Clear",
        "credit_no": "Credit #",
        "credit_card_number": "Credit Card Number",
        "credit_type": "Credit Type",
        "credit_type_regular": "Credit Card",
        "credit_type_cbus": "Cbus",
        "credit_type_10bis": "10bis",
        "expiration_date": "Expiration Date",
        "expiration_month": "Month",
        "expiration_year": "Year",
        "cvv": "CVV",
        "id_card": "ID Number",
        "scan_pm": "Scan card",
        "add_pm": "Add payment method",
        "HOUR": "Hour",
        "DATE": "Date",
        "PAY": "Pay",
        "CAN_TD": "Order a delivery",
        "expiration": "Expiration",
        "comments": "Comments",
        "PERFORMING_PAYMENT": "Performing payment...",
        "FINALIZING_ORDER": "We're working on it...\nPlease don't close the page",
        "PLEASE_WAIT": "Please wait...",
        "WERE_ON_IT": "We're on it...",
        "choose_restaurant": "Choose a place",

        "my_preferences": "Preferences",
        "my_preferences_description": "Please choose your preferred restaurant categories and dishes",
        "my_preferred_categories": "My Preferred Categories",
        "my_preferred_tags": "My Preferred Tags",
        "my_preferred_dishes": "My Preferred Dishes",
        "my_preferences_info": "Favorite kitchens and allergies",
        "my_preferences_selected_description": "Your preferences",
        "my_preferred_allergies": "Allergies",

        "my_history": "Order History",
        "my_history_info": "Opened and closed orders",
        "my_history_description": "All your visits and orders appear on this list",
        "my_recent": "Lately",
        "search_history": "Search history",
        "search_history_description": "Hey, in case you've visited {{descTitle}} restaurants, we can search and load your order history",

        "my_benefits": "Benefits",
        "benefits_and_promotions": "Discounts & Promotions",
        "benefits_use": "Used benefits",
        "more_benefits": "Discounts",
        "my_benefits_info": "To all your benefits info",
        "my_benefits_description": "All your points and benefits are on this list",
        "immediate_realization": "For immediate redemption",
        "immediate_topup": "For immediate top up press here",
        "prePaid_realization": "In pre-paid card",
        "benefit_details": "Benefit details",
        "valid_until": "Valid until",
        "benefits_club": "Benefits club",
        "brithday_benefit": "Brithday Benefit",
        "join_benefit": "Join Benefit",
        "renew_benefit": "Renew Benefit",
        "anniversary_benefit": "Anniversary Benefit",
        "general_benefit": "Benefit",
        "general_time_to": "Until",
        "voucher_benefit": "Voucher",
        "punch_card": "Punch card",
        "points_benefit": "Points Reward",
        "got_it": "Got it",
        "at": "At",

        "my_favorites": "Favorites",
        "your_recent_orders": "Your recent orders",
        "no_recents": "No recent reservations found",
        "no_favorites": "No favorites yet",
        "no_deliveries": "It seems there are no deliveries to your region",
        "future_reservations": "Future reservation",
        "future_reservation": "Future reservation",
        "future_reservations_and_discounts": "Future reservation and discount vouchers",
        "club_benefits": "Club benefits",
        "club_points": "Club points",
        "marketplace_title": "Gift Card Marketplace",
        "your_points_worth": "Your points worth money and available for use",
        "pre_paid_available": "Pre-paid card available for immediate use",
        "pre_paid_top_up": "Top up balance",
        "realize_points": "Redeem points",
        "your_tracked_order": "Your tracked order",
        "order_in_progress": "Order in progress",
        "order_end": "Start a new order?",
        "future_order_pending": "Order pending approval",
        "order_details": "Order details",
        "future_order_standby": "Order on standby",
        "continue_order": "Resume order​",
        "payment_in_progress": "Payment in progress",
        "payment_order": "Continue payment",
        "cancel_order": "Yes, exit",
        "confirm_cancel_order": "Yes, exit",
        "confirm_continue_order": "Resume order​",
        "extended_results": "Extended results",
        "book_a_place": "Reserve a table",
        "enter_order_no": "Enter order number",

        "quickpay": "Quick Pay",
        "quickpay_action": "Quick Pay",
        "issues-feedback-link": "Feedback",

        "version": "Version",

        "all_sites": "All restaurants",
        "to_all_sites_title": "Looking for something specific?",
        "to_all_sites": "To all restaurants",
        "to_restaurant": "To the restaurant",
        "sites_list": "Restaurant list",
        "sites_nearby": "Nearby restaurants",

        "not_available": "Not available",
        "not_available_now": "Not available now",
        "not_available_to_this_address": "Not available for this address",
        "available_from": "Available from ",
        "preorder": "Pre-order",
        "preference": "Preference",
        "future_order": "Future order",
        "availability": "Availability",
        "only_available": "Only available",
        "preparing-food": "As soon as items are added to the order you will see them here",
        "press here": "Press here",

        "near_me": "Near me",
        "extra_services": "Extra services",
        "near_me_pay_more_title": "Restaurants Nearby",
        "kitchens": "Cuisine",
        "cuisine": "Cuisine",

        "area": "Area",
        "latest_orders_title": "Want to order again?",
        "latest_orders_subtitle": "Your latest orders and visits are here below",
        "sites_near_me_title": "Restaurants Nearby",
        "sites_near_me_subtitle": "All the most relevant restaurants can be found right here!",
        "redirect_to_my_history": "View all my latest orders",
        "reserve_a_place_at": "Reserve a table at",
        "confirm_exit": "Exit?",
        "resart_order_confirm": "Exit?",
        "events_and_benefits": "Events and benefits",
        "marketplace_main_title": "Gift Card Marketplace",

        "gratuity": "Tip",
        "AMOUNT_TO_PAY": "Amount to pay",
        "PAYUP": "Continue to payment",
        "TABLE_BILL": "Table bill",
        "WAITER": "Server",
        "PAY_WITH_WALLET": "Pay with your wallet",
        "CONTINUE_WITHOUT_GRATUITY": "Continue without a tip",
        "i_approve_the": "I approve the",
        "approve_commercials": "By filling out this registration form, I authorize the club to send me advertising materials from time to time via SMS text messages.",
        "approve_sms": "SMS marketing notifications",
        "approve_mail": "Email marketing notifications",
        "when_to_book": "When to reserve a table",
        "without_gratuity": "No tip",
        "select_items": "Select Items",
        "pay_by_items": "Pay By Item",
        "pay_by_items_title": "I'm paying for...",

        "app_smart_banner_title": "",
        "app_smart_banner_body": "Download the app",
        "contact_us": "Contact us",

        "business_invite_restaurant_owner": "Tabit",
        "business_invite_restaurant_owner_screen_reader": "tabit, to company website,",

        "business_invite_join_us": "Company website ",

        "business_invite_restaurant_owner_seo": "Restaurant owner?",
        "business_invite_join_us_seo": " ",

        "today": "Today",
        "understood": "Understood",
        "try_again": "Try again",
        "wait_on": "Wait",
        "change_language": "Change Language",

        "pepper_pay": {
            "15_bonus": "$15 Benefit",
            "pepper_bonus": "PAY Benefit",
            "pepper_pay_payers": "For PAY application payers!",
            "pepper_pay_realization": "To redeem the benefit pay with PAY on the payment page",
            "takeout_delivery_above_amount_tip_not_included": "Valid for fees over $50 (not including tip) in delivery or takeout orders",
            "look_for_pay_mark": "Look for restaurants marked with PAY (Subject to"
        },
        "VALIDATIONS": {
            "CREDIT_CARD": "Credit card number is invalid",
            "CREDIT_CARD_EXPIRATION": "Expiration date is invalid",
            "INVALID_ID_NUNBER": "ID number is invalid"
        },
        "DAYS": {
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday"
        },
        "PMS": {
            "Stripe": "Stripe",
            "Braintree": "Braintree",
            "Vantiv": "Vantiv",
            "HeartLand": "HeartLand",
            "CardPoint": "CardPoint",
            "AuthorizeNet": "AuthorizeNet",
            "reminder": "Reminder",
            "remainder": "Remainder",
            "remainder_wallet_option": "Remainder",
            "prepaidBalance": "Prepaid balance",
            "points": "Points",
            "creditCard": "Credit",
            "Cibus": "Cibus",
            "10bis": "10bis",
            "CreditGuard": "Credit",
            "cash": "Cash",
            "TavHazahav": "Tav Hazahav",
            "cheque": "Cheque",
            "payLeumi": "Pay Leumi",
            "meshulam": "Bit",
            "giftCard": "Gift Card",
            "ChargeAccount": "Charge Account",
            "ChargeAccountPaymentProvider": "Charge Account",
            "OperaPaymentProvider": "Room Charge",
        },
        "site_details": {
            "tab_label_overview": "Overview",
            "tab_label_reviews": "Reviews",
            "tab_label_map": "Map",
            "tab_label_history": "History",
            "tab_label_menu": "Menus"
        },
        "menus": {
            "evening menu": "Evening menu",
            "morning menu": "Morning menu",
            "night menu": "Night menu",
            "noon menu": "Noon menu"
        },

        "occasions": {
            "occasions": "Occasions",
            "occasion": "Occasion",
            "event": "Event",
            "events": "Events",
            "online_event": "Message",
            "holiday": "Holiday",
            "all_day": "All day",
            "start": "Start",
            "end": "End",
            "time_from": "From",
            "time_until": "Until",
            "hang_out_type": "Type",
            "from_day": "From",
            "every_week": "Every week",
            "every_two_weeks": "Once in two weeks",
            "every_month": "Once a month",
            "on_days": "on",
            "on_day": "on",
            "starts_soon": "Soon",
            "now": "Now",
            "until": "Until",
            "more_info": "More",
            "confirm": "Close",
            "today_all_day": "Today, all day",

            "dates": {
                "events_for_today": "NOW - Today’s events",
                "tomorrow": "Tomorrow",
                "during_the_week": "During the week",
                "next_week": "Next week",
                "upcoming_month": "Upcoming month"
            },

            "tags": {
                "everything": "Everything"
            }
        },
        "marketplace": {
            "results_page_title": "Tabit Gift It",
            "results_page_description": "Give the gift of good food with a gift card!",
            "no_results_found": "No results found",
            "button_text": "Get a gift card",
            "search_placeholder": "Search restaurant",
            "more_information": "More information",
            "search_input": "Search by business/city"
        },
        "RANKS": {
            "0": "Diner",
            "1": "Regular",
            "2": "Guest of honor",
            "3": "Foodie",
            "4": "Order champion",
            "5": "VIP",
            "6": "Living to eat!",
            "7": "Food critic"
        },
        "SERVER_MESSAGES": {
            "ORDER_CLOSED_PAYED": "Order not found. It may have been paid and closed",
            "CHAIN_DISABLED": "Chain disabled",
            "EMAIL_IN_USE": "Email already in use. Please enter another one",
            "EMAIL_NOT_FOUND": "Email not found",
            "FIREBASE_DOWN": "Service unavailable",
            "SHVA_OFFLINE": "Credit card payment currently unavailable.\nPlease use another payment method or try again later",
            "PAYMENT_QUOTA_EXCEEDED": "Insufficient funds ({{v}})",
            "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "The amount is higher than the credit limit set for the room. Please go to the reception or pay with another payment method.",
            "CREDIT_GUARD_EX_ERROR": "There was an error processing the request. Please try again.",
            "EXTERNAL_PROVIDER_ERROR": "{{provider}} Payment Failed",
            "SMS_PHONE_ERROR": "It is not possible to send a text message to the provided number {{phone}}.\nPlease contact the restaurant.",
            "INVALID_ORDER_ID": "Requested transaction not found",
            "INVALID_QR": "Requested transaction not found",
            "INVALID_USER_ORPASS": "Username or Password invalid",
            "MISSING_CHAIN": "Chain not found",
            "NO_ACTIVE_SITES": "No active restaurants",
            "NO_SITES_IN_CHAIN": "No restaurants in chain",
            "OrderLockedByAnotherUser": "Payment failed. Check is handled by another user",
            "UPDATE_ACCOUNT_ERROR": "Account update error"
        },
        "CREDIT_GUARD": {
            "ERROR_CODE_001": "The card is blocked, contact the credit card company (code 001).",
            "ERROR_CODE_002": "The card is stolen, contact the credit card company (code 002).",
            "ERROR_CODE_003": "An error occurred, please contact the credit company (code 003).",
            "ERROR_CODE_004": "A decline was received from the credit company, the transaction was not approved (code 004).",
            "ERROR_CODE_006": "CVV or ID number are wrong, please try again (code 006).",
            "ERROR_CODE_010": "The transaction is not approved for execution, enhanced verification is required. Please contact the credit card company (code 010).",
            "ERROR_CODE_033": "The card number entered is incorrect, please try again (code 033).",
            "ERROR_CODE_036": "Card is expired (code 036).",
            "ERROR_CODE_039": "The card number entered is incorrect, please try again (code 039).",
            "ERROR_CODE_173": "We were unable to process the payment, please make sure all the details are correct (code 173)",
            "ERROR_CODE_200": "We were unable to process the payment, please make sure all the details are correct (code 200)",
            "ERROR_CODE_316": "An external interface failure. Please contact the credit card company (code 316).",
            "ERROR_CODE_358": "Sensitive details were not found in the database (code 358).",
            "ERROR_CODE_401": "We were unable to process the payment, please make sure all the details are correct (code 401)",
            "ERROR_CODE_405": "We were unable to process the payment, please make sure all the details are correct (code 405)",
            "ERROR_CODE_414": "We were unable to process the payment, please make sure all the details are correct (code 414)",
            "ERROR_CODE_520": "There is no authorization to perform the transaction, please contact the credit company (code 520).",
            "ERROR_CODE_599": "The credit issuer's token service is not available, please contact the credit company (code 599).",
            "ERROR_CODE_695": "This type of credit card is not supported, please pay with another card (code 695).",
            "ERROR_CODE_900": "A transaction was blocked due to non-commitment to a secure transaction (code 900).",
        },
        "SERVICES": {
            "book": "Reserve a table",
            "order": "Online order",
            "quickpay": "Quick pay"
        },
        "PO": {
            "CHANGE": "Tip\\Change",
            "ITEM_DISCOUNT": "Item discount",
            "ORDER_NO": "Order number",
            "ORDER_TIP": "Tip",
            "ORDER_TO": "Order address",
            "ORDER_TOTAL": "Order total",
            "ORDERER_NAME": "Orderer name",
            "ORDERER_PHONE": "Phone",
            "OTH": "On the house",
            "PAID": "Paid",
            "REMAINED_PAY": "Remaining",
            "TABLE_BILL": "Table bill",
            "TICKET_DISCOUNT": "Bill discount",
            "TICKET_OTH": "On the house",
            "WAITER": "Server",
            "WITHOUT": "Without"
        },
        "ORDER_SITES_BY": {
            "distance": "Distance",
            "name": "Name",
            "tabit": "Tabit ranking"
        },
        "NOTIFICATIONS_WIDGET": {
            "clear_all": "Clear all",
            "messages": "Messages"
        },
        "_CODES": {
            "409012": "OverTenderNotAllowed",
            "409023": "PaymentFailed",
            "409028": "OrderPaymentDocumentsNotIssued",
            "409030": "PasswordIsTooShort",
            "409036": "AnotherPaymentInProcess",
            "409039": "CourseHasIncompleteItem",
            "409040": "ItemInventoryOutOfStock",
            "409073": "PaymentTimedOut",
            "409091": "ProcessOnlineOrderFailed",
            "409092": "PayForOnlineOrderFailed",
            "409093": "PaymentProviderTimeout",
            "409098": "PaymentServiceUnavailable",
            "409110": "SendSmsMessageFailed",
            "409136": "OnlineOrderAlreadyHandedOff",
            "409806": "CreditCardNotSupported",
            "409808": "CreditCardExpired",
            "409809": "CreditCardCheckDigit",
            "409810": "CreditCardCVVError"
        },
        "areas_search_title": "Areas",
        "areas": {
            "haifa": "Haifa area",
            "north": "North",
            "center": "Center",
            "tel_aviv": "Tel Aviv-Jaffa area",
            "jerusalem": "Jerusalem area",
            "south": "South",
            "dallas": "Dallas",
            "fort_worth": "Fort Worth",
            "irving_grapevine": "Irving & Grapevine",
            "burleson": "Burleson",
            "plano": "Plano",
            "frisco": "Frisco",
            "mckinney": "McKinney",
            "sydney": "Sydney",
        },
        // Needed for dynamic language change
        "חיפה והסביבה": "Haifa",
        "צפון": "North",
        "מרכז": "Center",
        "תל אביב-יפו והסביבה": "Tel Aviv-Jaffa",
        "ירושלים והסביבה": "Jerusalem",
        "BENEFIT_TYPE": {
            "points": "Points",
            "Other": "Benefit",
            "BirthdayBenefit": "Birthday Reward",
            "AnniversaryBenefit": "Anniversary benefit",
            "JoinBenefit": "Welcome Reward",
            "PointsBenefit": "Points Reward",
            "RenewBenefit": "Renewal Reward",
            "Vouchers": "Voucher",
            "Benefit": "Reward",
            "PunchCard": "Punch Card",
            "PointsStore": "Rewards Store",
            "PointsStoreBasket": "Points Redemption",
        },
        "ROUTE": {
            "book-a-table": "book-a-table",
            "deliveries": "deliveries",
            "restaurants": "restaurants",
            "new-at-tabit": "new-at-tabit",
            "nearest-restaurants": "nearest-restaurants",
            "events": "events",
            "reservation": "online-reservations",
        },
        "AVS": {
            "billing_address": "Billing Address",
            "state": "State",
            "select_state": "Select State",
            "city": "City",
            "address": "Address",
            "zip_code": "Zip Code",
        },
        "TGM": {
            "FIND_ME_APLACE": "Find me a place",
            "FIND_ME_ARESTAURANT": "Find me a restaurant",
            "DINERS": "Guests",
            "Day": "Day",
            "DATE": "Date",
            "TIME": "Time",
            "BOOK_TITLE": "Booking details",
            "ONE_DINER": "One diner",
            "DETAILS_TITLE": "Fill in the details to complete the process.",
            "SUCCESS_TITLE": "Order accepted",
            "SUCCESS_TITLE_STANDBY": "Please note, we've added you to the waiting list.\nWe will contact you as soom as a table becomes available",
            "SUCCESS_TITLE_PENDING_APPROVAL": "Please note, your order has not yet been approved. We will contact you as soon as possible to confirm your order.",
            "DEPOSIT_TITLE": "To complete the booking process please enter your credit card details for deposit.",
            "LICENSE_AGREEMENT": "By pressing 'Save' you are agreeing our Terms of Use and Privacy Policy. For details press here ⟩",
            "SAVE": "Save",
            "standby": "Waiting list",
            "instant_booking": "Immediate approval",
            "pending_approval": "Approval required",
            "form_description_standby": "To be added to the waiting list, please fill in the following information.",
            "form_description_pending_approval": "This reservation is subject to restaurant approval. Please fill in the following information to complete the process.",
            "standby_list": "Waiting list",
            "missing_cc_details": "Missing CC details",
            "missing_deposit_payment": "Missing deposit payment",
        },
        "WEB_CONTAINER": {
            "start_logo": "tabit - main page",
            "language_select": "Switch site language to Hebrew",
            "home": "Main",
            "search_now": "Search",
            "search": "Search for a restaurant, cuisine, or style",
            "find_table": "Find a table",
            "show_more": "Show more",
            "service": "Service",
            "back_to_regular_results": "Back to Results",
            "booking_bar": {
                "day": "Day",
                "day_screen_reader": "Day selection",
                "hour": "Hour",
                "hour_screen_reader": "Hour selection",
                "guests": "Guests",
                "guests_screen_reader": "Guests number selection",
                "address": "Address",
                "category": "Categories",
                "area": "Areas",
                "choose_day": "What Day?",
                "choose_hour": "What Hour?",
                "choose_guests": "How Many Guests?",
                "all_categories": "All Categories",
                "all_areas": "All Areas",
                "tags": "Tags",
                "tags_screen_reader": "Tags selection, such as restaurant style, food type and other features",
                "clear_tags": "Clear Tags",
                "rating": "Rating",
                "rating_screen_reader": "Rating selection",
                "all_ratings": "All Ratings",
                "price": "Price",
                "price_screen_reader": "Price range selection",
                "all_prices": "All Prices"
            },
            "book_a_table": {
                "tab_title": "Reserve a table",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "new_restaurants_in_tabit": "New Restaurants at Tabit",
                "new_restaurants_in_tabit_screen_reader": "New Restaurants At Tabit",
                "kitchens": "Top Cuisines",
                "restaurants_by_areas": "Featured Areas",
                "show_more": "Show More",
                "near_me": "Near Me",
                "no_results_found": "No results. Please choose another time or date and we'll search again",
                "results_page_description": "Select date, hour, and diners and select 'Find Me a Table'",
                "results_page_title": "Reserve a table",
                "select_future_date": "The selected time has passed, please select again"
            },
            "deliveries": {
                "tab_title": "Deliveries & Takeout",
                "tab_title_screen_reader": "Deliveries and take out",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "results_page_title": "Deliveries & Takeout",
                "no_results_found": "No results"
            },
            "restaurants": {
                "tab_title": "Restaurants",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "results_page_title": "Restaurants",
                "no_results_found": "No results found"
            },
            "marketplace": {
                "tab_title": "Tabit Gift It",
                "main_title": "Tabit Gift It",
                "home_page_section_title": "Give the gift of good food",
            },
            "web_article": {
                "bottom_content_button": "Next Article >>"
            }
        },
        "MESSAGES": {
            "EXTRA_PAGE_ERROR": "Selection is required to continue",
            "GENERIC_GET_CUSTOMER_ERROR": "An error occurred during the login process. Please try again or contact the restaurant for assistance.",
            "GENERAL_ERROR": "General Error",
            "NO_INTERNET": "Please check your internet connection",
            "ENABLE_LOCATION": "Enable location",
            "ENABLE_NOTIFICATIONS": "Enable notifications",
            "LOADING_INITIAL_SITES": "Looking for your next experience",
            "EMAIL_MISSING": "Hi, we're happy you joined, to enjoy all of the content you must add your email to your profile.",
            "EMAIL_STILL_MISSING": "Only the email left to finish the sign up process",
            "LOADING_SITES": "Wait, there's more...",
            "NO_MORE_RESULTS": "No more results...",
            "NO_ORGS_RESULTS": "No restaurants found",
            "NO_ORGS_RESULTS_FOR_THIS_ADDRESS": "No available restaurants for this address",
            "CONFIRM_SIGNOUT": "Do you want to sign out?",
            "sign_in_by_phone_message": "Hey, we're glad to see you!",
            "sign_in_by_phone_description": "Let’s verify your phone number and we’re good to go",
            "enter_code_message": "We have sent a verification code to ",
            "sign_in_by_email_message": "Hey, please sign in with email and password",
            "wl_sign_up_title": "Sign up for club",
            "sign_up_message": "Just a bit more information and we’re done",
            "saved_successfully": "Saved successfully",
            "deleted_successfully": "Deleted successfully",
            "error_getting_bill": "Error getting your bill",
            "error_full_address_required": "Error, full address required",
            "error_invalid_sign_in_email_already_exists": "Email already exists, please enter another one.",
            "invalid_email_entered": "Invalid email provided",
            "service_not_available_for_site": "This service is currently unavailabale for this restaurant",
            "Q_REMOVE_CARD": "Delete this card?",
            "NO_PMS_FOUND": "No payment methods found for your account",
            "NO_SITE_PMS_FOUND": "No payment methods found for this restaurant",
            "NO_RESTS_FOUND": "No restaurants found for this search",
            "NO_PMS_FOUND_ADD": "No proper payment methods found for your account. Select 'Add payment method' to enable this service.",
            "SIGNIN-EMAIL-EX": "Enter email address",
            "SIGNIN-EMAIL-EX-HELP": "Enter the email address you previously registered with",
            "SIGNIN-CHALLENGE-EX": "A verification code has been sent to your cellphone",
            "SIGNIN-CHALLENGE-EX-HELP": "Haven't got a code? <b>Send again</b>",
            "SAVE_INFO": "Save contact and payment information",
            "SAVE_INFO_END": "Sign up for quick and easy orders in the future",
            "SAVE_INFO_PAYMENT": "Save payment information",
            "SIGNIN_CHALLENGE": "A verification code was sent to you via SMS tex, please enter it and select Login",
            "PAYMENT_CHALLENGE": "Verification code sent to\n{{phone}}\nvia SMS text",
            "PAYMENT_CHALLENGE_RETRY": "The code you entered is incorrect\nAnother verification code was sent via SMS text",
            "PAYMENT_ENTER_PHONE": "Let's verify your phone number and we're good to go",
            "PAYMENT_OTP": "We have sent a verification code to {{phone}}",
            "SIGNIN_VALIDATION": {
                "incorrect-phone-number": "Incorrect phone number",
            },
            "TRANSACTION_SUCCESS": "Paid succesfully",
            "ORDER_CLOSED": "The order was fully paid",
            "INVALID_ORDER": "Order not found",
            "PAYMENT_FAILED": "Payment failed",
            "INVALID_PIN": "The code is invalid",
            "SIGN_FOR_PAYMENT_CONFIRM": "Sign for payment confirmation",
            "SIGNATURE_MANDATORY": "Sign in mandatory for payment confirmation",
            "WAITER_NOTIFIED": "A member of our staff has been informed and will be with you shortly",
            "HAPPY": "We'd love to see you again!",
            "INVOICE_SENT": "An invoice was sent to your email",
            "ITEM_ADDED_TO_BASKET": "Item added to basket",
            "NO_BASKET_ITEMS": "No items in basket",
            "CONFIRM_ORDER_FROM_BRANCH": "You chose to order from: {{branch}}",
            "FINDING_THE_BRANCH_NEAREST_TO_YOU": "Finding the nearest branch to you",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "We're sorry, no branch found serving this address: {{address}}",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "Deliveries to this address are currently available for future orders only",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "Delivery to this address for future orders is currently unavailable",
            "NO_BRANCH_SERVING_ADDRESS": "The restaurant does not deliver to this address",
            "CONFIRM_RESET_ORDER": "Restart order?",
            "DO_YOU_APPROVE_CONTRACT": "Do you accept the Terms and Conditions?",
            "DO_YOU_APPROVE_CONTRACT_TITLE": "Before we go on",
            "CONDITIONS_PRIMARY_BUTTON_TEXT": "Yes",
            "CONDITIONS_SECONDARY_BUTTON_TEXT": "Exit",
            "RESTORE_PASSWORD_1": "Please enter the email connected to the account",
            "SIGNIN_SUCCESS": "Welcome back {{name}}, you have succesfully logged in to the system",
            "FORGOTPASS_SUCCESS": "Login instructions were sent to the email address you entered",
            "ACCOUNT_UPDATE_SUCCESS": "Account updated succesfully!",
            "TITLE_HUNGRY": "We know you’re hungry",
            "BRANCH_DISABLED_NOW": "This service is currently unavailable.\n {{t}}.",
            "BRANCH_DISABLED_NOW_ERROR": "This service is currently unavailable. It will be available at {{t}}.",
            "BRANCH_CLOSED_TODAY": "Branch is closed today.",
            "BRANCH_CLOSED_NOW": "This service opens at {{t}}, but don't worry,\nyou can make a pre-order that will be prepared\nonce the branch opens.",
            "SELECT-LOYALTY-SERVICE": "Please sign in to your loyalty account to continue with the order",
            "MIN-ORDER-PRICE": "Minimum delivery price is {{t}}\n(not including delivery cost).\nPlease add items to the order.",
            "SERVER_ERROR": "There was an error processing your request,\n Please contact the restaurant",
            "PAYMENT_ERROR": "We were unable to process the payment, please make sure all the details are correct.",
            "PAYMENT_ERROR_TITLE": "Payment Error",
            "PAYMENT_LOCKED": "The order was locked by another user. Please try again.",
            "REGISTER_SUCCESS": "Registration successful",
            "PASSWORD_RESET_SUCCESS": "Password reset successful",
            "CUSTOMER_ENTITYALREADYEXISTS": "This email already exists in the system",
            "PHONE_VERIFICATION_FAILED": "The code appears to be incorrect or expired. Please try again.",
            "PHONE_VERIFICATION_FAILED_TITLE": "Hmm...",
            "TOO_MANY_CODE_REQUESTS": "Your account has been temporarily blocked due to too many failed attempts.\nPlease try again later.",
            "VOUCHER_ALREADY_USED": "Looks like the coupon has already been redeemed",
            "SERVICE_UNAVAILABLE": "Ordering service currently unavailable, please try again later",
            "MOD_CANT_SELECT_MORE_THEN": "You can select no more than {{max}} extras",
            "MOD_PLEASE_SELECT_AT_LEAST": "You must select at least {{min}} extras",
            "MOD_PLEASE_SELECT_AT_LEAST_ONE": "At least one extra must be selected",
            "CANNOT_PAY_TRAINING_MODE": "System in training mode. Payment cannot be completed. Please call the restaurant\n {{phone}}",
            "ORDER_CANNOT_BE_PROCESSED": "We were unable to process the payment. Please contact the restaurant.\n Error: {{code}}",
            "TIMEZONE_CHANGED": "It seems there's an issue with your device's clock. To ensure the best experience, please restart your order. Thank you for understanding!",
            "ARE_YOU_SURE": "Are you sure?",
            "Cancel": "Cancel",
            "DISABLED_REGION_MESSAGE": "We don’t currently deliver to your address",
            "OUT_OF_REACH": "You’re out of reach",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "One or more of the payments you entered failed. Please call the restaurant to complete your order.\n {{phone}}",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "One or more of the payments you entered failed. Please contact the server for help",
            "SELECTTABLE_INFO": "Please enter the table number you would like to order for",
            "ENTERORDER_INFO": "Please enter the order number you would like to pay for",
            "INVALID_TABLE": "Table does not exist",
            "ORDER_RELOAD_NEEDED": "Please note, payment balance changed!",
            "ORDER_NEWPAYMENT_NEEDED": "Please note that the payment balance has changed. A new amount must be entered",
            "ORDER_HELD_BY_SERVER": "We apologize, but payment cannot be processed right now. Please try again in a few minutes",
            "SESSION_VALIDATION_FAILED": "There was an error processing the request, please refresh the page and try again",
            "CREDIT_CARD_3DS_DISCLAIMER": "You may receive a verification code by SMS text or email. Please enter it if prompted.",
            "ORDER_ITEMS_VALIDATION_ERROR": "Certain items you've selected cannot be ordered.\nPlease contact the restaurant to continue with your order.",
            "PAY_ORDER_CHANGED": "Your order was changed. Please review it",
            "PAYMENT_ACCEPTED_SUCCESSFULLY": "Payment successful",
            "PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT": "Payment in the amount of {{amount}} processed successfully",
            "PAYMENT_INTENT_EXPIRED": "Payment expired",
            "CARD_EXPIRED": "Expired",
            "PAYMENT_DELETED_SUCCESSFULLY": "Payment deleted successfully",
            "PAYMENT_ACCEPTED": "Payment successful",
            "OUT_OF_STOCK": "The following items are no longer in stock:\n {{items}}\n Please update your order",
            "GRATUITY_CASH_DISABLE": "Cash cannot be accepted when there is gratuity",
            "ORDER_ITEMS_UNAVALABLE": "Unfortunately, the following items are currently unavailable for ordering:",
            "ORDER_TIMEOUT": "Your order has timed out, please start again",
            "ORDER_TIMEOUT_TITLE": "Timeout",
            "ORDER_DELIVERY_ERROR_TITLE": "Something Went Wrong",
            "ORDER_DELIVERY_ERROR_MESSAGE": "We lost track of your address and unfortunately need you to restart your order. Sorry for the inconvenience",
            "LOCAL_TIME_HAS_BEEN_CHANGED": "The local time on your device has been changed",
            "ORDER_TIMEOUT_LEVEL1": "Don’t worry, you still have 5 more minutes to complete your order",
            "ORDER_TIMEOUT_LEVEL1_TITLE": "Something has changed",
            "BASKET_ERRORS_TITLE": "Sorry",
            "BASKET_ERRORS": "The following items are no longer available: {{errors}} Please update your order",
            "BASKET_ERRORS_BUTTON": "Update",
            "PROMOTION_DISABLED_TITLE": "Sorry",
            "PROMOTION_DISABLED": "This promotion: {{errors}} is no longer available your basket has been updated",
            "PROMOTION_DISABLED_BUTTON": "Update",
            "CONFIRM_ADDRESS": "The address entered is:\n<b>{{address}}</b>\nContinue?",
            "PROBLEM_IN_PAYMENT_INPUT": "There was an error in receiving payment details\nPlease enter payment details again\nIf the problem continues, please contact the restaurant by phone: \n {{phone}}",
            "MEAL_CALC_FACTOR_DESC": "The discount is being calculated for each item by its relative weight in the order",
            "MEAL_CALC_ACTION_DESC": "The dishes to pay must be marked",
            "MEAL_CALC_TOTAL_DESC": "The total portion cannot be greater than the amount remained to pay",
            "EX_PAYMENT_ERROR": "There was an error regarding your payment provider",
            "EX_PAYMENT_AUTHRIZATION_ERROR": "There was a payment confirmation error",
            "LOCALITY_WO_STREETS": "For {{locality}} there are no street names defined\ndo you want to choose this place as delivery order?",
            "SERVICE_END_WARN": "Attention! Order service will be closed in {{t}} minutes. Please complete your order in time.",
            "JUST_SO_YOU_KNOW": "Just so you know",
            "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "OK",
            "QR_HELPER": "Please scan the QR code",
            "INVALID_QR": "There was an error scanning the QR code.\nIf you wish to pay - please use manual code",
            "SITE_NOT_FOUND": "The desired restaurant",
            "TABIT_PAY_MANUAL_ENTER": "For manual code entry and restaurant selection",
            "TABIT_PAY_MANUAL_ENTER_FOR_SITE": "Manual payment",
            "PLEASE_ENABLE_CAMERA": "Please allow camera access in order to scan hte QR code",
            "PAY_INFO": "To continue the payment process, select a restaurant",
            "ENTER_ORDER_NO_INFO": "Ask your server for the code",
            "AREA_NOT_SELECTED": "No area selected",
            "PLEASE_SELECT_AREA": "Please select an area to continue",
            "PLEASE_ENABLE_LOCATION": "Please turn on location services so we can recommend restaurants based on your location.",
            "DESCRIBE_LOCATION": "Describe your location",
            "USER_LOCATION_ERROR": "Please enable location services for a better experience",
            "PLEASE_ENABLE_NOTIFICATIONS": "Please turn on notifications\nthis way you'll stay up-to-date 🙂",
            "CONTINUE_WITHOUT_GRATUITY": "Continue without a tip?",
            "CALL_RESTAURANT": "Call the restaurant",
            "NO_LOCATION": " Location services unavailable",
            "ORDER_REQUIERES_IDCARD": "The payment amount is too high, please reduce the amount or contact the server",
            "ERROR_CROSS_ORGS_TABLE_SEARCH": "It seems that you don't have internet connection... please try again later",
            "CROSS_ORGS_TABLE_NOT_FOUND": "No available tables at the opened restaurants at the desired time, please try to reserve at another time",
            "GRATUITY_NOT_ALLOWED_FOR_PM": "Tip cannot be added with {{pm}} at this restaurant.\nPlease select another payment method or cancel the tip.",
            "GRATUITY_PA_WARNING": "This restaurant accepts tips through {{pms}} only",
            "FOR_EXTENDED_RESULTS_PRESS_HERE": "Additional Results",
            "EXTENDED_RESULTS_BUTTON_ADDITIONAL_INFO": "Because this relationship is important to us, we don't hide anything. There are other restaurants here that make deliveries with other companies, if you would like.",
            "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Additional payment with the same device",
            "THE_SERVICE_BEGIN_AT": "The service will open at",
            "SERVICE_PREORDER_DONT_WORRY": "But don't worry, you can place a pre-order which will be ready when the service opens.",
            "SERVICE_DOWN": "Service is down, please try again later",
            "SUMMARY_SENT_BY_MAIL": "Order summary has been sent to your email",
            "ENABLE_GRATUITY_CREDIT_ONLY": "Can only pay with credit when tip added",
            "CC_PAYMENT_OVERFLOW": "Payments on one card cannot exceed NIS {{amount}}. Please contact your server to pay.",
            "HOTEL_NO_GUEST_WITH_PHONE": "No phone number related to room",
            "HOTEL_ROOM_NOT_FOUND": "Room not found",
            "HOTEL_NOT_ELIGIBLE": "Room charge restricted",
            "HOTEL_REPORT_TO_DESK": "Please go to reception",
            "HOTEL_NO_GUEST_FOUND": "No guests found",
            "SELECT_MAP_LOCATION": "Select location on map",
        },
        "EX_PAYMENT": {
            "dialog_title": "Payment Verification",
            "wallet_title": "Card Verification",
            "wallet-popup-title": "Card saved successfully",
            "description": "This page is used to verify the payment method used on the site. Actual payment will take place on the business' site once the verification process is complete. Please verify that all of the details on this page are correct and fill out the required credit card details.",
            "popup-title": "Payment Verification Complete",
            "form-title": "Credit Card Details",
            "popup-description": "Complete the transaction on the business' site",
            "submit-btn": "Continue",
            "clear": "Clear",
            "credit_card_number": "Credit Card Number",
            "expiration": "Expiration",
            "cancel": "Cancel",
            "currency": "Currency:",
            "transaction_details": "Transaction Details",
            "transaction_acknowledge_ord": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_gcs": "By clicking on the “Pay” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_rsv": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "total_amount": "Total Amount",
            "month": "Month",
            "year": "Year",
            "click_here": "Click here",
            "save":"Save",
        },
        "expayTitle": "Card details approved",
        "SERVICE_BUTTON": {
            "home": "Main",
            "order": "Delivery/TO",
            "book": "Reserve",
            "pay": "QR scan",
            "pay_generic": "Pay",
            "profile": "Profile",
            "delivery": "Delivery",
            "takeaway": "Takeout",
            "future_order": "Future Order",
            "eatin": "Eat in",
            "waitingList": "Join waitlist",
        },
        "DASHBOARD": {
            "we_have_missed_you!": "Hi, we missed you!",
            "when_we_would_see_you?": " ",
            "all_the_restaurants_click_away": "Your favorite restaurants - one click away",
            "BUTTONS": {
                "delivery": "Delivery",
                "takeaway": "Takeout",
                "book": "Reserve a table"
            }
        },
        "WEB_HOME": {
            "H1_TITLE": "Reserve a table!",
            "H2_TITLE": "Just say when and which restaurant, and the rest is on us",
        },
        "WEB_BOOK": {
            "H1_TITLE": "Reserve a table!",
            "H2_TITLE": "Search for a table at Tabit restaurants",
        },
        "ALLERGIES": {
            "gluten": "Gluten",
            "eggs": "Eggs",
            "peanuts": "Peanuts",
            "nuts": "Nuts",
            "sesame": "Sesame",
            "lactose": "Lactose",
            "spicy": "Spicy",
            "fish": "Fish"
        },
        "_LOYALTY": {
            "id_number_already_exist": "The ID number already exist in the system, please use another ID number.",
            "registration_error_title": "Connect to Club",
            "order_types": "Only available for {{types}}",
            "cancel_benefits": "Cancel promo code",
            "card_balance": "Card balance",
            "money_compensation": "Comps",
            "connect": "Apply",
            "connect_to_tabit": "Log in to Tabit",
            "connect_info": "Phone or card number",
            "expires": "Expires on",
            "greeting": "Hello {{name}}!",
            "greeting_voucher": "Hello!",
            "hey": "Hi!",
            "member_not_found_title": "We couldn’t find you",
            "general_error_title": "Just a moment",
            "general_error": "An error occurred while calculating your benefits. Please try again or contact the restaurant directly",
            "renew_is_forbidden_title": "Your Membership Has Expired",
            "renew_is_forbidden_text": "To enjoy the Loyalty program rewards, you must renew your membership. For more details please contact the restaurant.",
            "comps_extra_text": "The comps have been added to the order and can be redeemed.",
            "good_to_see_you_title": "Good to see you",
            "member_not_found": "This number isn't connected to a membership",
            "member_not_found_PRIMARY_BUTTON_TEXT": "OK",
            "customer_mustComplete_the_registration": "In order to add the club member to the order, please fill out the registration form sent by SMS or in our app.",
            "of": "Of ",
            "points_balance": "Points balance",
            "punch_card_offer": "From {{from}} to {{to}}",
            "review_benefits": "Total benefits",
            "review_points": "Redeem points",
            "use_points": "Points",
            "signout": "Sign out",
            "skip": "Skip",
            "no-benefits": "Benefits weren't redeemed",
            "title": "Club members {{name}}?",
            "title_voucher": "Hi, do you have a voucher?",
            "title_club": "Hi, do you have a promo code or loyalty membership?",
            "voucher": "Voucher",
            "max_redeemable_points": "Maximum points to redeem",
            "my_benefits": "My promotions",
            "benefits_use": "Used promotions",
            "redeem_points_prompt_1": "Your points are worth money!",
            "redeem_points_prompt_2": "Max. points to redeem: {{points}}",
            "redeem_points_after": "{{points}} saved by redeeming points",

            "voucher_gtreeting": "Hi, do you have a promo code?",
            "voucher_inline_gtreeting": "Enter promo code",
            "voucher_benefits_title": "My promotions",

            "club_gtreeting": "Let’s find any codes or memberships you may have",
            "club_inline_gtreeting": "Enter promo code or loyalty membership",
            "club_inline_gtreeting_voucher_only": "Enter promo code",
            "club_benefits_title": "My promotions",
            "select_club": "Select a club",

            "benefits_title": "My benefits",
            "no_benefits_title": "No available benefits",
            "register": "Join Our Loyalty Program",
            "to_promotion_disclaimer": "Discount will be applied in the next step",
            "benefit_types": "Available for {{val}} only",
            "auth_title": "Confirmation code required",
            "auth_sub_title": "Confirmation code was sent to your cellphone",
            "auth_code": "Enter code",
            "auth_resend": "Resend code",
            "for_your_disposal": "At your disposal",
            "gift-card": "Got a gift card?",
            "add-gift-card": "Add another card",

            "registration_terms": "By registering you agree to receive SMS texts about deals and offers.\nYou can cancel your registration at any time.",
            "regCostMessage": "Club Membership costs {{currency}}{{price}}. This will be added to your cart",
            "confirmDeleteOfferJoin": "There’s a fee to become a Loyalty Member.",
            "confirmDeleteOfferRenewal": "Cancel membership renewal?",
            "confirmDeleteOfferJoinDescription": "Removing it will prevent the receipt of benefits, would you like to remove it?",
            "confirmDeleteOfferRenewalDescription": "This will prevent you from receiving Loyalty benefits",
            "removeOfferJoinButton": "Yes, remove",
            "removeOfferRenewalButton": "Cancel membership renewal",
            "keepOfferJoinButton": "No, I want to join",
            "keepOfferRenewalButton": "Renew my membership",
            "confirmExpiredSoonDeleteOffer": "Cancel membership renewal?",
            "removeExpiredSoonOfferButton": "Cancel renewal",
            "keepExpiredSoonOfferButton": "Renew my membership",
            "regTitle": "Club Members have more fun!",
            "regDetailsDescription": "We couldn’t find your number {{phone}} in our system. Now’s the time to join!",
            "regCancel": "Cancel",
            "continueReg": "Join",
            "regRenewMessage": "Club Renewal costs {{currency}}{{price}}. This will be added to your cart",
            "regRenewPromptPrice": "Membership renewal costs {{currency}}{{price}}",
            "regRenewTitle": "Time flies!",
            "regRenewBody": "It’s time to renew your club membership",
            "regSoonExpiredRenewBody": "Your membership expires on {{date}}.\You can pay membership renewal fee to continue and enjoy the rewards included in this loyalty program in the future.",
            "regSoonExpiredSignOut": "Your membership expires on {{date}}.",
            "regPromptTitle": "We couldn’t find your number {{phone}} in our system.\nNow’s the time to join!",
            "regPromptPrice": "It costs {{currency}}{{price}} to become a Club Member",
            "regUpdateNessage": "Please verify the details",
            "registerButton": "Join",
            "continueWithoutRenewing": "Continue without Renewal",
            "continueWithoutReg": "Continue without Joining",
            "renewMembership": "Membership Renewal",
            "phoneNumber": "Phone Number",
            "textForConfirmSms": "By registering you agree to receive SMS texts about deals and offers. You can cancel your registration at any time",
            "textForConfirmSmsScreenReader": "By registering you agree to receive SMS texts about deals and offers. You can cancel your registration at any time",
            "textForConfirmClubRules": "I agree to the Club Terms and Conditions",
            "termsContinue": "Continue",
            "termsFirstContinue": "Continue",
            "termsBody": "It seems that you have not yet completed the registration for the club \n A few last details and we are good to go :)",
            "termsTitle": "Hi {{name}}",
            "first_name": "First name",
            "last_name": "Last name",
            "email": "Email address",
            "zehut": "ID number",
            "phone": "Phone number",
            "anniversary": "Anniversary",
            "birth_date": "Date of birth",
            "regErrorTitle": "Something went wrong",
            "email_not_unique": "This email is already in use, please enter a different email address",
            "no_registration_items": "No renewal or joining items found in the published menu.",
            "regError": "Unable to complete the join process, please contact the restaurant.",
            "continueErrorTitle": " Hey ",
            "continueErrorText": "Please add items to the cart, to enjoy the club's benefits",
            "understood": "Okey",
            "minimum_order_required_title": "Just a moment",
            "minimum_order_required_body": "After adding your rewards, your order total comes to {{total}}{{currency}} and doesn't meet the minimum {{amount}}{{currency}} required",
            "minimum_order_required_button": "Back to Menu",
            "get_benefits_401_error": "Your session has expired. Please log in again to the Loyalty club to continue using the system."
        },
        "validation_errors": {
            "required": "Required Field",
            "max_length": "Character limit is 256",
            "invalid_email": "Invalid email",
            "invalid_phone": "Invalid phone",
            "load_range": "Enter an amount within the range",
            "no_decimal": "Please enter a valid amount",
            "only_5_digits": "Enter 5 digits only",
            "min_2_digits": "Must contain at least 2 characters",
            "only_numbers": "Enter numbers only",
            "only_letters": "Enter letters only",
            "invalid_zip_code": "Invalid zip code",
        },
        "_GIFTCARD": {
            "title": "Have a prepaid card or gift card?",
            "gift_card": "Gift Card",
            "select_card": "Select a Card",
            "please_select_card": "Please select a card",
            "edit_card": "Edit gift card",
            "add_card": "Pay with Gift Card",
            "number": "Card/Phone number",
            "amount": "Amount to pay",
            "balance": "Card balance",
            "max": "Max amount",
            "cvv": "CVV",
            "cvv_required": "CVV required",
            "cvv_alt": "CVV required",
            "invalid_cvv": "Invalid CVV number",
            "inuse_error": "The same card cannot be used more than once in the same order",
            "balance_error": "Card has no balance",
            "tip_not_allowed": "This card cannot be used to pay tip",
            "notfound_error": "Card not found!",
            "delete_prompt": "Are you sure?",
            "no_order_amount": "Order is fully covered, no need to add another payment",
            "reset_split_payments_prompt": "Adding or updating a gift card will reset split payments. Continue?",
            "please_finish_payment": "Press 'Pay' to finish the order",
            "wallet-item-needed": "One more thing",
            "add-wallet-item": "Before we continue, please add a credit card",
            "move-to-my-wallet": "To my wallet",
            "otp_required": "Confirmation code required",
            "otp_required_alt": "Confirmation code was sent to",
            "otp_required_alt_num": "*******{{v}}",
            "otp_alt": "Confirmation code",
            "invalid_otp": "Invalid Confirmation code",
            "resend_otp": "Resend confirmation code",
            "cannotPayOnOnline": "This type of Card is not available to be used online, but you may be able to use it at the restaurant",
        },
        "order_tracker": {
            "hey": "Hey",
            "ETA": "Estimated",
            "FEES": "Fees",
            "CASH_DISCOUNT": "Cash Discount",
            "ETA_takeaway": "The order will be ready by",
            "ETA_delivery": "The delivery will arrive by",
            "track_order": "Track Order",
            "my_order": "My order",
            "order_status": "Actual status",
            "actual_status": "Order status",
            "status": "Status",
            "order": "Order",
            "order_a": "Order",
            "order_is_being_loaded": "Wait for it...",
            "order_accepted": "We're reviewing your order",
            "order_being_prepared": "Our chef is working on it",
            "order_in_progress": "Working on it",
            "takeaway": "Takeout",
            "delivery": "Delivery",
            "arrived": "Arrived",
            "track": "Track",
            "send": "Send",
            "ok": "OK",
            "order_ready": "Your order is ready!",
            "order_ready_for_delivery": "Ready for Delivery",
            "order_taken": "Order Taken",
            "order_taken_on_its_way": "On Its Way!",
            "order_delivery_delivered": "Delivered",
            "add_review": "Add Review",
            "your_order_not_found": "Could not find your order",
            "hey_im_here": "Hey, I am here!",
            "im_here_explanation": "We would be glad to know when you're here -",
            "how_can_we_find_you": "How can we find you?",
            "car_information": "Car information",
            "instructions_accepted": "Thanks for the update :)",
            "update_notes": "Update notes",
            "order_not_here": "Your order is not here anymore...",
            "dont_cry_over_spilled_juice": "But don't cry over spilled juice",
            "can_we_offer_you_something_else": "Can we offer you something else?",
            "im_here_confirmation_title": "Arrival confirmation",
            "im_here_confirmation_message": "Should we inform the restaurant you arrived?",
            "im_here_confirmation_hurry_message": "That was fast, here already?",
            "im_here_confirm": "Yes",
            "im_here_cancel": "Not yet",
            "disclaimer_with_phone": "For any request regarding your order, please contact {{ name }} directly by clicking on the 📞",
            "disclaimer_without_phone": "For any request regarding your order, please contact {{ name }} directly"
        },
        "to_disclaimer": "For any questions regarding your order, please contact {{ name }} directly",
        "by_phone": "by phone: ",
        "user_reviews": "User review",
        "user_review_hey": "Hey",
        "user_review_end": "Exit",
        "user_review_thanks": "Thanks",
        "user_review_thanks_for_sharing": "Thanks for sharing ",
        "user_review_waiting_to_hear_from_you": "Waiting to hear from you",
        "user_review_what_did_you_like": "What did you like?",
        "user_review_comment": "We would like to hear what you have to say",
        "user_review_positive_thank_you_description": "Looking forward to your next visit!",
        "user_review_negative_thank_you_description": "Hope you enjoy it next time!",
        "user_review_add_comment": "Tell us more",
        "user_review_food": "Food",
        "user_review_service": "Service",
        "user_review_package": "Packing",
        "user_review_times": "Times",
        "user_review_order_process": "Order process",
        "user_review_atmosphere": "Atmosphere",
        "user_review_perfect": "Perfect",
        "user_review_ok": "Very Good",
        "user_review_plausible": "Fine",
        "user_review_expected_more": "So So",
        "user_review_bad": "Could be better",
        "user_review_add_a_review": "Add a review",
        "user_review_how_was_it": "How was it?",
        "user_review_sorry_to_hear": "Sorry to hear...",
        "user_review_what_to_improve": "We would like to know how to improve",
        "in_progress_message": "Your are in the middle...",
        "deletion_will_remove_all_payments": "This will delete all payments",
        "sorry": "Sorry",
        "were_sorry": "We're sorry",

        "notifications": {
            "got_all_notifications": "It seems you are up-to date",
            "promise_to_inform": "The bell will turn yellow when an update arrives",
            "unsupported_browser_title": "Keep It Light",
            "unsupported_browser_confirm": "I understand",
            "unsupported_browser": "For best viewing experience, set display settings to light mode, or avoid using Samsung's browser."
        },

        "USER_POINTS": {
            "btw_you_have": "By the way, you already have",
            "btw_you_dont_have": "By the way, you don't have points",
            "for_each_order_more_bonus": "For each ordered table you would get 10 more",
            "for_each_order_bonus": "For each ordered table you would get 10"
        },
        "KFC": {
            "title": "KFC {{country}}",
            "main_sub_title": "The world's favorite chicken",
            "sec_sub_title": "Prepare your fingers!"
        },
        "SHIOU": {
            "title": "Shiuou! You have a touch for sushi",
            "sub_title": "Shortest way from imagination to taste!"
        },
        "GRECO": {
            "title": "GRECO",
            "sub_title": "Real Greek party"
        },
        "GIFT_CARD_SHOP": {
            "tracker": {
                "to_from": "Basic details",
                "when_where": "Sending methods",
                "checkout": "Checkout",
            },
            "card_recipient": {
                "title": "Send Gift Card to...?"
            },
            "user_wallet": {
                "title": "Choose a payment method",
                "other": "Another payment method",
            },
            "choose_amount": {
                "current_balance": "Current balance",
                "you_pay": "Pay",
                "you_get": "Get",
            },
            "create_card": {
                "title": "Card Details",
                "to": "To",
                "from": "From",
                "first_name": "First Name",
                "last_name": "Last Name",
                "send_to_friend": "Send Gift Card to a friend",
                "where_to_send_the_gift": "Where should we send the gift card?",
                "your_message": "Message (Optional)",
                "your_message_placeholder": "Type a few words",
                "enter_amount": "Gift Card Amount ({{min_amount}} - {{max_amount}})",
                "enter_amount_error": "Please enter an amount within the range",
                "send_to_phone": "Send Gift Card to",
                "toPhone": "Phone",
                "toEmail": "Email",
                "required_load_sum": "How much do you want to charge?",
                "billing_address": "Billing Address",
                "state": "State",
                "select_state": "State",
                "city": "City",
                "address": "Address",
                "zip_code": "Zip Code",
                "legalText": "This voucher is valid for 5 years from date of purchase, as required by Consumer Protection Law 14(h)", // Only for IL/USs
                "legalTextProcess": "This voucher is valid for 5 years from date of purchase, as required by Consumer Protection Law 14(h)", // Only for IL/US
                "expirationDate": "This card expires 3 years after supply", // Only for AU
                "bonus_title": "Enjoy a {{percent}}% bonus!"
            },
            "delivery_options": {
                "best_send_to": "Sending methods",
                "toPhone": "Phone",
                "toEmail": "Email",
                "phone": "Phone number",
                "email": "Email address"
            },
            "checkout": {
                "title": "Summary",
                "gift_card_total": "Total",
                "gift_card": "Gift Card",
                "send_to": "Send to",
                "to": "Recipient",
                "from": "From",
                "message": "Message",
                "send_receipt_to": "Where should we send your receipt?",
                "send_all_receipts_to": "Send gift card and receipt to",
                "myPhone": "My Phone",
                "myEmail": "My Email",
                "receiptContactPhone": "Phone",
                "receiptContactEmail": "Email",
                "Email": "Email",
                "total": "Total:",
                "approve_notifications": "Notify me When the card is viewd by the recipient.",
                "approve_terms": "I have read and approved the",
                "approve_terms_and": "and",
                "approve_terms_link_1": "Terms of Service",
                "approve_terms_link_2": "Privacy Notice",
            },
            "buttons_texts": {
                "pay": "Pay {{currency}}{{amount}}",
                "next": "Next",
                "go_back": "Go Back",
                "you_get": "You get {{currency}}{{amount}}",
                "website": "Website",
                "recipient_type_me": "Myself",
                "recipient_type_not_me": "Friend"
            },
            "validation_errors": {
                "required": "Required Field",
                "max_length": "Character limit is 256",
                "invalid_email": "Invalid email",
                "invalid_phone": "Invalid phone",
                "load_range": "Enter an amount within the range",
                "no_decimal": "Please enter a valid amount",
                "only_5_digits": "Enter 5 digits only",
                "min_2_digits": "must contain at least 2 characters",
                "only_numbers": "Enter numbers only",
                "only_letters": "Enter letters only",
                "invalid_zip_code": "Invalid zip code",
            },
            "modals": {
                "please_wait": "Please wait...",
                "success": {
                    "title": "Thank you!",
                    "body": "Your gift card is on its way",
                    "primary_button": "Close",
                    "secondary_button": "I want another card",
                    "top_up": {
                        "body": "Top up completed",
                        "secondary_button": "Top up again",
                    }
                },
                "error": {
                    "title": "Something's Wrong",
                    "body": "Unable to process payment",
                    "body_general_error": "An error occurred",
                    "body_error_occurred": "An error occurred\nPlease contact the restaurant {{phone}}",
                    "body_validation_mismatch": "An error occured\nCard declined, address validation mismatch",
                    "body_card_declined": "An error occured\nCard declined",
                    "shop_inactive_error": "Oops! Looks like this service is down at the moment",
                    "card_inactive_error": "Error in creating card, please contact the restaurant",
                    "primary_button": "Close",
                    "secondary_button": "Try again",
                },
                "pay": {
                    "pay_with_cibus": "Pay with Cibus",
                    "card_number": "Card Number"
                }
            },
            "redeem_card": {
                "title": "Your gift card balance:",
                "show_qr": "Show QR code at the restaurant",
                "code": "Code",
                "cvv": "CVV",
                "recipient_title": "Hey ",
                "recipient_text": "You received {{amount}}",
                "recipient_from_text": "from {{sender}}",
            },
            "general": {
                "gift_card_purchase": "Gift card for a friend"
            }
        },
        "BIGA": {
            "title": "BIGA",
            "sub_title": "Bakery & kitchen"
        },
        "FOODBA": {
            "title": "Foodba",
            "sub_title": "Be'er Sheba's delivery application",
            "seo_title": "Be'er Sheba | Foodba",
        },
        "FOOTER": {
            "area_link_prefix": " ",
            "other_links": "Featured areas",
            "contact": "Contact",
            "join_us": "Join Us",
            "to_tabit_site": "To Tabit Site",
            "contact_us": "Contact Us",
            "privacy_policy": "Privacy Policy",
            "club_terms": "Loyalty Terms",
            "terms_of_use": "Terms Of Use",
            "career": "Career",
        },
        "loyalty-terms-dialog": {
            "title": "We have a new customer club",
            "descriptionMain": "We have amazing benefits for you!",
            "descriptionSecond": "In order to continue, we need a few more things",
            "validation": "Do you want to give up the benefit of joining?",
            "added_to_your_cart": "This reward has been applied to the item(s) in your cart. Enjoy!",
            "ok": "OK",
            "remove_reward": "Remove reward?",
            "remove_reward_btn_yes": "Yes, Remove",
            "remove_reward_btn_no": "No",
        },
        "loyalty-rewards-dialog": {
            'auto-rewards-title': 'We\'ve added these rewards to your order:',
            'my-rewards': 'Your Rewards',
            'all-my-rewards': 'Select rewards',
            'all-my-rewards-single': 'Claim this reward',
            'club-rewards-will-be-calculated-before-payment': 'Ready to redeem? Select the rewards you would like to add to your order below',
            'back-to-order': 'Back to Order',
            'done-thanks': 'Done, Thanks',
            "select_between_range": "{{quantity}} out of {{max}} rewards selected",
            "loyalty_reward_terms_dialog_title": "You got it!",
            "loyalty_reward_terms_dialog": "Your reward is active and will be applied at checkout.",
            "loyalty_reward_terms_dialog_ok": "Go to menu",
            "loyalty_reward_terms_dialog_cancel": "Cancel",
            "not-available": "Reward is not available at the moment",
        },
        "pickup-order-dialog": {
            "Branch_pickup_order": "Branch pickup order",
            "address": "address",
        },
        "SIGN-UP-DATE-ERROR": "Date format is incorrect",
        "general_credit_card": {
            "form": {
                "card_number": "CARD NUMBER:",
                "expiration": "CARD EXPIRATION:",
                "cvv": "CARD CVV:",
                "amount_to_pay": "PAY"
            },
            "errors": {
                "invalid_exp": "Please fill in all required fields",
                "invalid_cvv": "Invalid CVV",
            }
        },
        "REMOVE_ACCOUNT": {
            "disclaimer_1": "The completion of this process requires a two-step authentication process through a verification code that will be sent by SMS text.",
            "disclaimer_2": "The account will be removed immediately.",
            "disclaimer_3": "Benefits and comps associated with your phone number will be saved even after the account is removed from the app and you will be able to use them on other channels.",
        },
        "tou_offer": {
            "required": "Required",
            "additional_options": "Additional options",
            "group_guiding_text": {
                "before_selection": {
                    "choose_at_least": "Select at least {{min}} items",
                    "choose_at_least_singular": "Select at least one item",
                    "select_at_least": "Select at least {{min}} more items",
                    "select_at_least_singular": "Select at least one more item",
                    "select_at_least_no_max": "Order must contain at least {{min}} more items",
                    "select_at_least_no_max_singular": "Order must contain at least one more item",
                    "select_between_range": "Select between {{min}} and {{max}} items",
                    "select_between_range_with_defaults": "Order must contain at least {{min}} more items",
                    "select_between_range_with_defaults_singular": "Order must contain at least one more item",
                    "select_minimum_items": "Order must contain at least {{min}} more items",
                    "select_minimum_items_singular": "Order must contain at least one more item",
                    "select_quantity": "Select {{min}}",
                    "select_quantity_singular": "Select one",
                    "select_quantity_singular_without_default": "Select one",
                    "select_up_to": "Select up to {{max}} items",
                    "select_up_to_singular": "Select up to one item",
                    "select_up_to_without_defaults": "Select up to {{max}} items",
                },
                "during_selection": {
                    "choose_at_least": "Select at least {{min}} items",
                    "choose_at_least_singular": "Select at least one more item",
                    "select_at_least": "Select at least {{min}} more items",
                    "select_at_least_singular": "Select at least one more item",
                    "select_at_least_no_max": "Order must contain at least {{min}} more items",
                    "select_at_least_no_max_singular": "Order must contain at least one more item",
                    "select_between_range": "{{quantity}} out of {{max}} selected",
                    "select_between_range_with_defaults": "{{quantity}} out of {{max}} selected",
                    "select_between_range_with_defaults_singular": "{{quantity}} out of {{max}} selected",
                    "select_minimum_items": "Order must contain at least {{min}} more items",
                    "select_minimum_items_singular": "Order must contain at least one more item",
                    "select_quantity": "{{quantity}} selected",
                    "select_quantity_singular": "One selected",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "{{quantity}} out of {{max}} selected",
                    "select_up_to_singular": "One item selected",
                    "select_up_to_without_defaults": "Select up to {{max}} items",
                },
                "after_selection": {
                    "choose_at_least": "{{quantity}} items selected",
                    "choose_at_least_singular": "One item selected",
                    "select_at_least": "",
                    "select_at_least_singular": "",
                    "select_at_least_no_max": "",
                    "select_at_least_no_max_singular": "",
                    "select_between_range": "Max of {{max}} reached",
                    "select_between_range_with_defaults": "{{quantity}} selected",
                    "select_between_range_with_defaults_singular": "One selected",
                    "select_minimum_items": "",
                    "select_minimum_items_singular": "",
                    "select_quantity": "{{min}} selected",
                    "select_quantity_singular": "One selected",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "Max of {{max}} reached",
                    "select_up_to_singular": "Max of one reached",
                    "select_up_to_without_defaults": "{{quantity}} selected",
                }
            },
        },
        "new_restaurant_page": "Restaurant page",
    },
    "en-AU": {
        "number_of_restaurants_found_screen_reader": "number of restaurants found,",
        "number_of_addresses_found_screen_reader": "number of addresses found:",
        "number_of_dishes_found_screen_reader": "number of addresses found:",
        "localeCaption": "עב",
        "share_this": "Hi, here's something I found on Tabit. Check it out!",
        "hours": "Hours",
        "days": "Days",
        "tabit": "Tabit",
        "minutes": "Minutes",
        "footer_data": "Book a place | Order a table | Tabit",
        "terms_of_service": "Terms of Service",
        "privacy_notice": "Privacy Policy",
        "under_construction_message": "This page is still on the grill",
        "summary": "Summary",
        "service_selection": "Service selection",
        "future_service_desc": "Please select future service",
        "choose_navigation_option": "Select navigation option",
        "choose_option": "Select option",
        "choose_hotel": "Select hotel",
        "_and": "and ",
        "KEYPAD_DIALOG_WARNING": "Up to {{currency}} {{maxVal}} can be entered",
        "rsv_management": {
            "guests": "Guests",
            "disclaimer": "For any request regarding your order, please contact directly",
            "description": {
                "future_reservation_created": "Hey {{name}},\nYour reservation has been booked successfully.",
                "future_reservation_reminded": "Hey {{name}},\nPlease confirm your reservation.",
                "future_reservation_reminded_free_text": "Please confirm your reservation.",
                "future_reservation_notified": "Your table is ready. Come on in.",
                "future_reservation_checked_in": "Your are now checked-in. We will notify you as soon as your table is ready.",
                "future_reservation_deleted": "Your reservation has been cancelled.",
                "future_reservation_verified": "Your reservation has been verified.",
                "future_reservation_standby_created": "Hey {{name}},\nYou have been added to our waiting list. We will get back to you if a table becomes available.",
                "future_reservation_standby_created_pending_approval": "Hey {{name}},\nYour request has been received, but NOT yet approved. We will get back to you as soon as possible for confirmation.",
                "future_reservation_will_be_late": "Thank you for letting us know that you will be late.",
                "future_reservation_undo_late": "Thank you for the update.",
                "future_reservation_cc_deposit": "Hey {{name}},\nYour reservation has been booked successfully.\nIf you need to cancel, please do so at least {{time_limit_cancel}} before your reservation to avoid a no-show fee.",
                "future_reservation_cc_deposit_reminded": "Dear {{name}},\nPlease confirm your reservation.\nIf you need to cancel, please do so at least {{time_limit_cancel}} before your reservation to avoid a no-show fee.",
                "future_reservation_cc_deposit_notified": "Hey {{name}},\nYour table is ready. Come on in.\nIf you need to cancel, please do so at least {{time_limit_cancel}} before your reservation to avoid a no-show fee.",
                "future_reservation_cc_deposit_checked_in": "Hey {{name}},\nYour are now checked-in. We will notify you as soon as your table is ready.\nIf you need to cancel, please do so at least {{time_limit_cancel}} before your reservation to avoid a no-show fee.",
                "future_reservation_advanced_payment": "Hey {{name}},\nYour reservation has been booked!\nPlease note, if you cancel less than {{time_limit_cancel}} before the reservation,\nthe advanced payment will be non-refundable.",
                "future_reservation_advanced_payment_reminded": "Hey {{name}},\nPlease confirm your reservation.\nPlease note, if you cancel less than {{time_limit_cancel}} before the reservation,\nthe advanced payment will be non-refundable.",
                "walked_in_saved": "You are now in line and we will notify you as soon as your table is ready.",
                "walked_in_created": "You are now in line and we will notify you as soon as your table is ready.",
                "walked_in_notified": "Your table is ready. You are invited to come in.",
                "walked_in_checked_in": "You are now checked in. We will notify you as soon as your table is ready",
                "called_in_saved": "You are now in line and we will notify you as soon as your table is ready.",
                "called_in_created": "You are now in line and we will notify you as soon as your table is ready.",
                "called_in_notified": "Your table is ready. Come on in."
            },
            "buttons": {
                "confirm_label": "Confirm Reservation",
                "cancel_label": "Cancel Reservation",
                "will_be_late_label": "We’ll be Late",
                "undo_late_label": "Arriving on time",
                "fill_health_statement": "Fill Out Health Statement",
                "modify_label": "Modify Reservation"
            },
            "cancel_reservation_dialog": {
                "title": "Reservation Cancellation",
                "body": "Are you sure you wish to cancel the reservation?",
                "confirm": "Cancel Reservation",
                "cancel": "Back"
            },
            "failure_reason": {
                "reservation_not_found": "Reservation not found",
                "reservation_in_progress": "The reservation has been seated. Enjoy!",
                "organization_not_found": "Organization not found",
                "feedback_module_disabled": "Sorry, but feedback is disabled for this restaurant"
            },
            "place_in_queue": "Queue Position",
            "preferences": "Preferences",
            "preference_exception": "Except {{preference}}",
            "social": {
                "share": {
                    "whatsapp": {
                        "button_label": "Send to a Friend",
                        "app_message": "I've ordered us a place at ",
                        "web_message": "Hey,\nCheck out our reservation at {{organization}}\n{{link}}"
                    },
                    "share": "Share"
                }
            },
            "notifications": {
                "email": {
                    "unsubscribe": {
                        "success": "You will no longer receive email notifications for this reservation.",
                        "error": "There was an unexpected error stopping email notifications. Please try again later or contact the restaurant."
                    }
                }
            },
            "call_customer": {
                "request": "I have an update, please call me back",
                "request_sent": "We'll call you back soon",
                "request_cancel": "(Cancel request)"
            },
            "deposit_payment": {
                "qr_code_button": "Show QR Code",
                "qr_code_description": "Please present this QR code when you arrive at the restaurant",
                "cancel_reservation_dialog": {
                    "body": "Cancellation within a period of less than {{time_limit_cancel}} from the time of the reservation, the deposit payment will be considered as a cancellation fee.\nPlease call the restaurant for any questions.\nAre you sure you wish to cancel the reservation?"
                },
                "refund_failed_dialog": {
                    "title": "Reservation cannot be cancelled",
                    "body": "We are unable to cancel the reservation. Please contact the restaurant"
                },
                "description": {
                    "cancel_after_time_limit": "Your reservation has been cancelled and your deposit wasn't refunded. For any request regarding this issue please contact the restaurant",
                    "cancel_before_time_limit": "Your reservation has been cancelled and your deposit was refunded",
                    "no_voucher_balance": "Reservation is cancelled. Your deposit has already been redeemed, therefore no refund was issued. For more information please contact the restaurant."
                }
            },
            "cc_deposit": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "Your reservation is within the next {{time_limit_cancel}} and a no-show fee applies.\nWould you like to cancel your reservation?"
                    },
                    "prevent": {
                        "title": "Reservation Cannot be Cancelled",
                        "body": "There are less than {{time_limit_cancel}} left before the reservation.\nTo cancel the reservation, please call the restaurant",
                        "primary_button": "Call restaurant",
                        "secondary_button": "Back"
                    }
                },
                "credit_details_dialog": {
                    "form_title": "To verify your card, we will pre-authorize a 1 NIS charge, which will be released by the end of the day",
                    "submit_button": "Continue"
                }
            },
            "advanced_payment": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "Unfortunately, if the reservation is canceled less than {{time_limit_cancel}} ahead of time, the advanced payment will be charged.\nWould you like to cancel the reservation?"
                    },
                    "prevent": {
                        "title": "Reservation Cannot Be Canceled",
                        "body": "There are less than {{time_limit_cancel}} left before the reservation.\nTo cancel the reservation, please call the restaurant",
                        "primary_button": "Call restaurant",
                        "secondary_button": "Back"
                    },
                    "charged": {
                        "body": "The advanced payment will not be refunded if the reservation is canceled less than {{time_limit_cancel}} ahead of time.\nWould you like to cancel the reservation?",
                        "in_time_cancellation_body": "The advanced payment will be automatically refunded if the reservation is canceled more than {{time_limit_cancel}} ahead of time.\nWould you like to cancel the reservation?"
                    }
                }
            },
            "hour": "Hour",
            "two_hours": "2 Hours"
        },
        "splash_screen": {
            "failure_dialog": {
                "title": "Something went wrong",
                "content": "An unexpected error occurred",
                "ok_button": "Try again"
            }
        },
        "booking": {
            "site_name": "Site Name",
            "site_address": "Site Address",
            "disclaimer_description": "By continuing I agree to the ",
            "disclaimer_description_deposit_payment": "By clicking \"Confirm & Pay\" I agree to the ",
            "disclaimer_description_connection": " and ",
            "terms_of_service": "Terms Of Service",
            "privacy_notice": "Privacy Policy",
            "button_label": {
                "walked_in": "Arriving Soon",
                "future_reservation": "Reserve a Table"
            },
            "search": {
                "person": "{{value}} Guest",
                "people": "{{value}} Guests",
                "larger_party": "{{value}}+ Guests",
                "minutes": "{{value}} Minutes",
                "no_results": "Hmm... It appears we don't have any available table at this time.\nPlease choose an alternative date or call the restaurant.",
                "no_alternatives": "Hmm... It appears we don't have any available table at this time.",
                "no_waiting_list": "No waiting list, you are welcome to arrive",
                "waiting_list_full": "Sorry, our waiting list is full",
                "results_info": "No exact match, showing closest results:",
                "call_restaurant_large_party": "For large party reservations please call the restaurant",
                "call_restaurant_error": "An Error Has Occurred - Please Call the Restaurant",
                "booking_window_error": "We’re sorry, the online waiting list isn’t available right now",
                "today": "Today",
                "first_available": "No Preference",
                "choose_preference": "Choose Area *",
                "inside": "Inside",
                "outside": "Outside",
                "vip": "VIP",
                "bar": "Bar",
                "gallery": "Gallery",
                "balcony": "Balcony",
                "smoking": "Smoking",
                "private_room": "Private Room",
                "patio": "Patio",
                "tv_view": "TV View",
                "stage": "Stage",
                "with_stroller": "With Stroller",
                "with_power": "With Power",
                "standby_reservation_description": "I don't like any of the suggested options, please add me to the standby list",
                "alternative_organizations_description": "Search a table at our additional restaurants",
                "meta_description": "To reserve a table at {{name}} choose a date, time, and number of guests.",
                "back_to_reservation": "Back to Reservation",
                "field_label": {
                    "seats_count": "Party Size",
                    "date": "Date",
                    "time": "Time",
                    "preference": "Preference",
                    "arriving_within": "Arriving Within"
                },
                "button_label": {
                    "search": "Reserve a table",
                    "search_walked_in": "Add Me to the Waiting List",
                    "search_modify": "Continue",
                    "standby_reservation": "Add Me to the Standby List",
                    "phone": "Call Us"
                },
                "today_blocked": "Same-day reservations are currently unavailable",
                "reservation_in_edit_current_title": "Your Current Reservation",
                "reservation_in_edit_modified_details": "Modify Your Reservation"
            },
            "details": {
                "description": "To complete the reservation, please fill in the following details.",
                "description_pending_approval": "The reservation requires confirmation by the restaurant. To complete the reservation, please fill in the following details.",
                "description_standby": "In order to be added to our waiting list, please fill in the following details.",
                "description_modify_warning": "Please note! Once modified, your reservation will require the restaurant’s approval and the original reservation will be deleted.",
                "description_modify_cc_deposit_warning": "Please note! Once modified, the existing deposit for your reservation will be deleted and you will be prompted to make a new one.",
                "description_modify_advanced_payment_warning": "Please note! Once modified, your saved credit card details will be deleted and you will be prompted to enter new credit card details.",
                "future_reservation_info": "The table is currently reserved for you for the next {{value}} minutes",
                "future_standby_reservation_info": "Leave us your contact information and we'll get back to you as soon as a table becomes available",
                "called_in_info": "You've been added to the waiting list\nPlease come to the restaurant in the next {{minutes}} minutes",
                "timer": "You have {{minutes}} minutes to complete the details",
                "button_label": {
                    "confirm": "Reserve",
                    "confirm_and_pay": "Confirm & Pay",
                    "proceed_to_deposit": "Next",
                    "cancel": "Cancel",
                    "back_to_search": "Back to Search",
                    "continue": "Continue",
                },
                "field_label": {
                    "first_name": "First Name",
                    "last_name": "Last Name",
                    "full_name": "Full Name",
                    "phone": "Phone",
                    "email": "Email",
                    "notes": "Notes",
                    "tags": "Notes",
                    "search": "Search"
                },
                "field_error": {
                    "phone": "Invalid Number"
                },
                "countries_dropdown": {
                    "find_country": "Search"
                }
            },
            "modal": {
                "confirmation": {
                    "time_limit_title": "Attention",
                    "time_limit_body": "Please note that your reservation will be limited until {{reserved_until}} ({{duration}}).\nWould you like to proceed?",
                    "queue_addition_title": "Waiting Line",
                    "queue_addition_body": "Would you like to be added to the waiting line?",
                    "still_here": "Still here?"
                },
                "alert": {
                    "unsuccessful_attempt_to_reserve_title": "Attention",
                    "unsuccessful_attempt_to_reserve_body": "An Unexpected Error Occurred",
                    "unsuccessful_attempt_to_reserve_body_invalid_cc": "Check the credit card details",
                    "reservation_not_found_title": "Oops...",
                    "reservation_not_found_body": "More than {{minutes}} minutes have passed since your reservation was created and it has expired. Please create a new reservation\n\nFor more assistance, please call the restaurant",
                    "fill_mandatory_fields_title": "Required Fields",
                    "fill_mandatory_fields_body": "Please fill in all required fields",
                    "online_booking_blocked_title": "Online Reservations Unavailable",
                    "online_booking_blocked_body": "We're sorry, but online reservations are currently unavailable.",
                    "preference_required_title": "Missing Preference",
                    "preference_required_body": "Please select a preference to continue",
                    "illegal_group_size_title": "Attention!",
                    "illegal_group_size_body": "The number of diners is too large for the selected date / time.",
                    "customer_additional_reservation_blocked_title": "Can’t book additional reservation",
                    "customer_additional_reservation_blocked_body": "It seems that you have already booked a table at our restaurant within less than {{hours}} hours.\nIn order to reserve another table, please cancel your previous reservation or call us.",
                    "cc_deposit_transaction_failed": "Action Failed",
                    "back_to_create_reservation_button": "Create New Reservation",
                    "call_restaurant_button": "Call Restaurant",
                    "modify_not_allowed_title": "Modify Unavailable",
                    "modify_not_allowed_body": "The reservation is too close and modifying it is unavailable"
                },
                "confirm": "Okay",
                "cancel": "Cancel",
                "retry": "Retry",
                "continue": "Continue",
                "back": "Back"
            },
            "user_abandon_warning": "You are about to leave the page. The changes you made will be lost",
            "online_booking_blocked_description": "Online reservations are currently unavailable. Please call the restaurant."
        },
        "reservation_details": {
            "date_label": "On ",
            "time_label": "At",
            "arriving_within_label": "Arriving Within",
            "people": "{{value}}"
        },
        "feedback": {
            "feedback_form": "Feedback Form",
            "possible_answers": "Possible Answers",
            "mandatory_questions": "Mandatory Questions",
            "guest": "Guest",
            "description": "Hello {{name}},\nWe would appreciate your feedback",
            "conclusion": {
                "title": "Thank you for your feedback!",
                "satisfied_customer_sub_title": "We're looking forward to seeing you again soon",
                "unsatisfied_customer_sub_title": "Sorry for the inconvenience.\nYour feedback has been sent to the restaurant manager, and we will continue to do our best to improve your experience."
            },
            "button_label": {
                "confirm": "Send Feedback",
                "poor": "Poor",
                "fair": "Fair",
                "good": "Good",
                "very_good": "Very Good",
                "excellent": "Excellent"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Answer Missing",
                    "fill_mandatory_fields_body": "Please answer all the questions"
                },
                "confirm": "Okay"
            },
            "unsubscribe": {
                "link": " Unsubscribe from {{organization}} surveys",
                "success": "You've successfully unsubscribed from future surveys.",
                "error": "There was an unexpected error unsubscribing from future surveys. Please try again later or contact the restaurant."
            }
        },
        "deposit": {
            "description": "Please fill in your credit card details as a deposit.",
            "card_number": "Card Number",
            "cvv": "CVV",
            "year": "Year",
            "month": "Month",
            "id_number": "ID Number",
            "zip_code": "ZIP Code",
            "date_passed": "Invalid Date",
            "invalid_cc_number": "Invalid Card Number",
            "invalid_id_number": "Invalid ID Number",
            "button_label": {
                "save": "Save",
                "confirm_and_continue": "Confirm & Continue",
                "cancel": "Cancel Reservation",
                "continue": "Continue",
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Required Fields",
                    "fill_mandatory_fields_body": "Please fill in all required fields",
                    "submit_error_title": "Deposit unsuccessful",
                    "submit_error_body_general": "Check the form details and try again",
                    "submit_error_body_invalid_cc": "Check the credit card details and try again"
                },
                "confirm": "Okay"
            },
            "amount_details": {
                "title": "No-Show Fee",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{seats_count}} X {{currency_symbol}}{{amount_per_guest}}"
            }
        },

        "cc_deposit": {
            "description": "Your credit card details are required to complete your reservation.\nNo-shows or cancellations within {{time_limit_cancel}} before the reservation will be charged {{currency_symbol}}{{amount_per_guest}} per guest. All credit card information will be processed securely.\nPlease fill in the following details",
        },

        "deposit_payment": {
            "description": "The reservation is conditioned on a deposit of {{currency_symbol}}{{amount_per_guest}} per guest.\nThe booking can be cancelled free of charge up to {{time_limit_cancel}} prior the time of reservation and in this case the deposit payment is fully refundable.\nTo complete the process proceed to payment.",
            "illegal_amount": "Illegal Amount"
        },

        "advanced_payment": {
            "description": "Your reservation requires an advanced payment of {{currency_symbol}}{{amount_per_guest}} per guest.\nIf you cancel your reservation up to {{time_limit_cancel}} ahead of time, you will be refunded the full advanced payment.\nTo complete your reservation, please fill in the following details",
            "amount_details": {
                "title": "Advanced payment amount",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },

        "health_statement": {
            "title": "Health Statement",
            "customer_thanks": "Thank you {{first_name}} {{last_name}}",
            "health_statement_saved": "The health statement has been saved",
            "health_statement_not_found": "Health Statement Not Found",
            "share_health_statement_form_button": "Share Health Statement with the Other Guests",
            "share_health_statement_form_text": "Hey,\nI booked us a table at {{organization}}\nWe need to fill a health statement:\n{{link}}",
            "no_fever_statement": "I declare that I have taken a body temperature today, and it was found below 38 degrees celsius",
            "no_cough_statement": "I declare that I do not cough and that I have no breathing difficulties except difficulty in breathing due to a chronic condition such as asthma or other allergy",
            "coronavirus_law": "Per regulation 3a(1) for Emergency Situation - Corona Virus"
        },

        "smart_banner": {
            "title": " Hi!",
            "body": "Want to download the app?"
        },

        "server_time_client_diff_dialog": {
            "title": "Warning",
            "content": "Your device's clock is off from the restaurant clock by {{time_diff}}\nAll time values will be displayed according to the restaurant clock.",
            "ok_button": "Okay"
        },

        "site-links": {
            "address_screen_reader": "restaurant found:",
            "from_screen_reader": "open from",
            "up_until_screen_reader": "up until",
            "phone_number_screen_reader": "phone number:",
            "to_call_screen_reader": "for calling, press here",
            "to_navigate_screen_reader": "for navigation directions, press here",
            "show_work_hours_screen_reader": "for restaurant working hours, press here",
            "to_web_site_screen_reader": "for the restaurant website, press here",
        },

        "dialog_screen_reader": {
            "default": "dialog opened",
            "before_you_pay_suggestion": "dialog opened: dish suggestions before payment",
            "order_scheduling": "dialog opened: schedule delivery or take away time has opened",
        },

        "forms_screen_reader": {
            "sign-up": {
                "pop_up_announcement": "A sign in pop up has appeared, please fill in your phone number for verification",
                "missing_fields": "the following mandatory fields are missing or not filled correctly:",
                "phoneControl": "your mobile phone number",
            },

            "to-desktop-sign-up": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "cPhone": "your mobile phone number",
                "email": "your email address",
                "firstName": "your name",
                "lastName": "your surname",
                "apartment": "your apartment number",
                "floor": "your floor number",
                "address": "your full address",
                "terms": "acknowledgement of the privacy policy and tems of use"
            },

            "customer_details": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "email": "your email address",
                "first_name": "your name",
                "last_name": "your surname",
                "notes": "notes",
                "phone": "your mobile phone number",
            },

            "loyalty_sign_up": {
                "missing_fields": "the following mandatory fields are missing or not filled correctly: ",
                "firstName": "your name",
                "lastName": "your surname",
                "email": "your email address",
                "mobile": "your mobile phone number",
                "zehut": "your i.d number",
                "membershipPrice": "recognition of sign up added cost",
                "clubTerms": "acceptance of club's terms and conditions",
            }
        },

        "social-share": {
            "share": "press to share",
            "add_to_my_calender": "press to add to calander",
            "share_label": "Share",
            "calendar_label": "Add to Calendar"
        },

        "contact-details": {
            "location": "press for navigation directions",
            "phone": "press for calling the restaurant",
            "location_label": "Navigate",
            "phone_label": "Call",
            "menu_label": "Menu",
            "choose_menu": "Select Menu",
            "no_available_menu": "No Menu Available",
            "menu_error_dialog_title": "Something Went Wrong",
            "menu_error_dialog_body": "Please try again later"
        },

        "TO-DELETE": "To delete",
        "TO-EDIT": "To edit",
        "TO-ADD": "Add",
        "TO-UPDATE": "To update",
        "CANCEL": "Cancel",
        "NO_THANKS": "No, thanks",
        "CLEAR_SELECTION": "Clear selection",
        "ADD": "Add",
        "UPDATE": "Update",
        "CASH": "Cash",
        "THANK_YOU": "Thank you",
        "TIP": "Tip",
        "PAYED": "Paid",
        "CONTINUE": "Continue",
        "CART_SUBTOTAL": "Cart subtotal",
        "TOTAL_IN_TIP": "You pay",
        "PARTIAL_PAY_IN_TIP": "Partial payment",
        "CLEAR": "Clear",
        "APPROVE": "Approve",
        "REMAINED_PAY": "Balance Due",
        "REMAINED_PAY_CASH": "Balance Due - Cash",
        "TO_PAY": "Proceed to payment",
        "SAVE_CARD_INFO": "Save card details",
        "TOTAL": "Total",
        "CARD_HOLDER_NAME": "Card Holder Name",
        "CARD_NUMBER": "Card Number",
        "SIGNIN": "Sign in",
        "SIGNIN_INFO": "Enter sign-in details",
        "VALIDATING_CREDENTIALS": "Validating...",
        "WELCOME_BACK": "Welcome back",
        "SELECT_EXISTING_CARD": "Select card",
        "ADD_NEW_CARD": "Save card for future use",
        "SAVE_CARD_AS_DEFAULT": "Save as default card",
        "ORDER_TOTAL": "Order total",
        "ORDER_VAT_TOTAL": "(Include VAT {{currency}} {{total}})",
        "ORDER_TIP": "Tip",
        "PAID": "Paid",
        "ENTER_ORDER_N": "Enter order code",
        "TO_HOMEPAGE": "Homepage",
        "VIEW_MORE": "View more",
        "VIEW_LESS": "View less",
        "REQUIERED": "Required",
        "REQUIERED_CHECK": "Required",
        "EDIT": "Edit",
        "PASSWORDS_DONT_MATCH": "Passwords do not match",
        "PASSWORDS_MIN_LENGTH": "Passwords must be at least 6 characters long",
        "INVALID_CREDIT_NUMBER": "Invalid credit number",
        "INVALID_CVV": "Invalid CVV",
        "INVALID_CARD_EXPIRATION": "Invalid expiration date",
        "INVALID_EMAIL": "Invalid Email",
        "EMAIL_FOR_INVOCE": "email for invoice",
        "CARD_EXPIRATION": "Expiration date",
        "CARD_HOLDER_CELL": "Card holder cellphone",
        "ROOM_OWNER_CELL": "Room owner phone number",
        "CARD_HOLDER_MAIL": "Card holder email",
        "Q_SHARING_BILL": "For partial payment",
        "Q_TIP_AMOUNT": "Please set tip",
        "Q_HOW_MUCH_PAY": "Set amount?",
        "Q_HOW_MUCH_SPLIT": "Split between",
        "Q_REGISTARED": "Already registered?",
        "Q_NEW_REGISTARED": "New user? Register...",
        "Q_INVOICE_BY_MAIL": "Receive invoice by email?",
        "Q_REMOVE_CARD": "Are you sure you want to delete this card?",
        "Q_REMOVE_PAYMENT": "Are you sure you want to delete this payment?",
        "SIGNIN_REGISTER": "Sign in / Register",
        "END": "Finish",
        "ENTER_TIP": "Tip",
        "ENTER_AMOUNT": "Custom amount",
        "SPLIT_AMOUNT": "Split",
        "YOUR_AMOUNT_TO_PAY": "You pay",
        "PRESS_HERE": "Tap Here",
        "OTHER": "Other",
        "SENDING_INVOICE": "Sending invoice...",
        "CHALLENGE_CODE": "Confirmation code",
        "ENTER_CHALLENGE_CODE": "Enter confirmation code",
        "REMEMBER_ME_ON_PHONE": "Remember me on this device",
        "BILL_AMOUNT": "Check",
        "PAY_WITH_ONOTHER_CARD": "Pay with another card",
        "PAYMENT_METHOD": "Payment Method",
        "SELECT_PAYMENT_METHOD": "Choose Payment Method",
        "CREDIT_CARD": "Credit Card",
        "CREDIT": "Credit",
        "creditCard": "Credit",
        "CreditGuard": "CreditGuard",
        "CIBUS": "Cibus",
        "Cibus": "Cibus",
        "CIBUS_CARD": "Cibus card",
        "CIBUS_CARD_NUMBER": "Cibus card number",
        "TENBIS": "10bis",
        "10bis": "10bis",
        "10bis_card": "10bis card",
        "10bis_card_number": "10bis card number",
        "loyaltypm_card_number": "Card number",
        "EDIT_ACCOUNT_SETTINGS": "Edit account settings...",
        "MY_ACCOUNT": "My Account",
        "SIGN_OUT": "Sign Out",
        "CLUB_SIGN_OUT": "Remove Loyalty member from this order",
        "VOUCHER_SIGH_OUT": "Remove coupon from this order",
        "COMPS_SIGH_OUT": "Remove comps from this order",
        "EXPIRED_SOON_SIGN_OUT": "Cancel renewal",
        "EXPIRED_SOON_APPROVE": "Renew my membership",
        "REMOVE_VOUCHER": "Yes, remove coupon",
        "REMOVE_COMPS": "Yes, remove member",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION": "Remove Loyalty member from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_TEXT": "Signing out will cancel your rewards eligibility",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_VOUCHER": "Remove coupon from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_COMPS": "Remove Loyalty member from this order?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_EXPIRED_SOON": "Cancel membership renewal?",
        "MY_ACCOUNT_DETAILS": "My account",
        "EDIT_PAYMENT_INFO": "Edit payment options",
        "DELETE_CARD": "Delete card",
        "SET_DEFAULT_CARD": "Set as default",
        "YES_DELETE": "Yes, delete it",
        "OFFER_REMARKS": "Item Remarks",
        "OFFER_DONE": "I'm done, thanks!",
        "ENTER_OFFER_REMARKS": "Enter Item Remarks",
        "DELETE_PAYMENT": "Delete Payment",
        "CONFIRM_CELL": "Confirm Phone",
        "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your cellphone",
        "USE_ENTERED_PHONE": "Send to:",
        "ORDER-TOTAL": "Order total",
        "WITHOUT_GRATUITY": "Without gratuity",
        "ORDER_SCHEDULING": "When do you prefer to get your order?",
        "SELECT_SERVICE_TYPE": "Service type",
        "ENTER_ALTERNATE_PHONE": "Use other:",
        "CLOSE_DIALOG_BOX_SCREEN_READER": "close dialog window",
        "CLOSE": "Close",
        "YES": "Yes",
        "NO": "No",
        "SEND_ORDER": "Send order",
        "TRACK_YOUR_ORDER": "Track your order",
        "YOUR_ORDERS": "My orders",
        "ORDER_CREATION_TIME": "Creation time",
        "CONTINUE_ORDERING": "Continue ordering",
        "ADD_ITEM": "Add item",
        "SELECT_ITEM": "Select item",
        "UPDATE_ITEM": "Update item",
        "TOPPINGS": "Toppings",
        "DECISIONS": "Decisions",
        "ITEM_CHANGES": "Item changes",
        "SELECTION_GROUP_SELECT": "(Select {{count}} items)",
        "SELECTION_GROUP_SELECT_SINGLE": "(Select one item)",
        "SELECTION_GROUP_SELECT_MIN": "(Select at least {{count}} items)",
        "SELECTION_GROUP_SELECT_SINGLE_MIN": "(Select at least one item)",
        "GROUP_MOD_SELECT_ERROR": "Select at least {{count}} additional items from: {{group}}",
        "GROUP_MOD_SELECT_SINGLE_ERROR": "Select at least one from: {{group}}",
        "GROUP_SELECT_ERROR": "Select at least {{count}} items from: {{group}}",
        "GROUP_SELECT_SINGLE_ERROR": "Select at least one item from: {{group}}",
        "SELECTION_GROUP_OPTIONAL_MAX": "Optional, select up to {{count}} items",
        "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "Optional, select up to one item",
        "CHECKOUT": "Checkout",
        "Q_REMOVE_OFFER_FROM_BASKET": "Remove this item?",
        "ENTER_DELIVERY_ADDRESS": "Enter delivery address",
        "SELECT_ADDRESS": "Select address",
        "BRANCH": "Branch",
        "CHOOSE_BRANCH": "Choose restaurant",
        "ADDRESS": "Address",
        "PHONE": "Phone",
        "ENTRANCE": "Entrance",
        "FLOOR": "Floor",
        "ENTER_STREET_INCLUDING_NUMBER": "Please enter full address, inc. number",
        "MISSING_STREET_NUMBER": "How can we find you?",
        "START_ORDER": "Start order",
        "MAKE_AN_ORDER": "Proceed to order",
        "SELECT_CITY": "Select city",
        "SELECT_DELIVERY_CITY": "Select delivery city",
        "SELECT_BRANCH": "Select branch",
        "ORDER_MENU": "Menu",
        "eatin": "Eat in",
        "ADDITIONAL_DETAILS": "Additional details​",
        "RESET_ORDER": "Reset order",
        "ORDER_FROM": "Order from",
        "ORDER_TO": "Order to",
        "CONTACT_INFO": "Contact info",
        "CONTACT_ALCOHOL_DECLARATION": "I am {{age}}+ years of age and will provide identification to verify alcohol purchase",
        "PAYMENT_INFO": "Payment info",
        "PAYMENT_INFO_MISSING": "We are missing some payment details for card {{cardLastDigits}}",
        "FIRST_NAME": "First name",
        "LAST_NAME": "Last name",
        "CELL": "Cell",
        "EMAIL": "Email",
        "REVIEW_YOUR_ORDER": "Review your order",
        "REVIEW_YOUR_ORDER_BUTTON": "View Receipt",
        "OKKAMI_APP_LINK": "Go Back to OKKAMI App",
        "ADD_ITEMS": "Add items",
        "UPDATE_ITEMS": "Update items",
        "ORDER_SENT_SUCCESS": "Order sent successfully",
        "ORDER_NEED_ATTENDANCE": "Order sent with an error",
        "CALL_RESTAURANT_TO_COMPLETE_ORDER": "Please call the restaurant to complete your order",
        "ORDER_NUMBER": "Order number",
        "START_NEW_ORDER": "Begin a new order",
        "ORDER_ITEMS": "Order items",
        "ORDER_WILL_BE_SENT_TO": "Order will be sent to",
        "ESTIMATED_DELIVERY_TIME": "Estimated delivery time",
        "ESTIMATED_PREPARATION_TIME": "Estimated preparation time",
        "ESTIMATED_SUPPLY_TIME": "Estimated supply time",
        "MINUTES": "Minutes",
        "PREPARATION_TIME": "Preparation time",
        "ORDER_TYPE": "Order type",
        "PLEASE_SELECT": "Please select",
        "PLACEMENT": "Placement",
        "NUMBER_OF_PEOPLE": "Number of diners",
        "READ_AND_APPROVE_CONTRACT": "I've read and accept the terms & conditions",
        "READ_AND_APPROVE_CONTRACT_C": "To the terms & conditions",
        "READ_CONTRACT": "Read terms & conditions..",
        "PLEASE_APPROVE_CONTRACT": "Please approve terms",
        "USAGE_CONRACT": "Terms & condition",
        "ADDRESS_REMARKS": "Address remarks",
        "LOADING_MENU": "Loading menu...",
        "MOD_WITH": "Add-Ons",
        "MOD_WITHOUT": "Without",
        "PASSWORD": "Password",
        "CONFIRM_PASSWORD": "Confirm password",
        "FORGOT_PASSWORD": "Forgot password...",
        "RESTORE_PASSWORD": "Restore password",
        "INCLUDE_SILVERWARE": "Include Silverware",
        "LEAVE_DELIVERY": "Leave Delivery at the Door",
        "CURBSIDE_PICKUP": "Curbside pickup",
        "CURBSIDE_PICKUP_DESC": "Enter car details: Model, Color, License number...",
        "CURBSIDE_PICKUP_ESC": "Enter car details: Model, Color, License number...",
        "AUTHENTICATING": "Authenticating",
        "SIGNED_IN_AS": "Signed in as",
        "CONFIRM_SIGN_OUT": "Yes, sign out",
        "PAY_WITH_SAVED_PMS": "Saved payment options",
        "SIGHN_IN_EMAIL": "Sign-in Email",
        "SIGHN_IN_PASSWORD": "Sing-in password",
        "UPDATE_PAYMENT_INFO": "Update payment info",
        "UPDATE_ACCOUNT": "Update account",
        "ADD_PAYMENT_INFO": "Add payment info",
        "UPDATING_ACCOUNT": "Updating account...",
        "UPDATE_SIGNIN_INFO": "Update sign-in info",
        "PASS_MIN_LENGTH_ERROR": "Password must contain at least 6 characters",
        "OLD_PASSWORD": "Old password",
        "NEW_PASSWORD": "New password",
        "BRANCH_TIME_CONFIRM_0": "The restaurant is currently closed. This service is available between {{t0}}",
        "BRANCH_TIME_CONFIRM_1": "The restaurant is currently closed. This service is available between {{t0}} - {{t1}}",
        "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "This service has closed. It's available between {{t0}}",
        "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "This service has closed. It's available between {{t0}} - {{t1}}",
        "BRANCH_TIME_0": "Order service is active between {{t0}}",
        "BRANCH_TIME_1": "Order service is active between {{t0}} - {{t1}}",
        "CONFIRM": "Confirm",
        "ORDER_SUCCESS": "Order success",
        "MIN-ORDER-PRICE": "Minimum order price",
        "DELIVERY-PRICE": "Delivery price",
        "TOTAL-ITEMS": "Total items",
        "TOTAL-TO-PAY": "Total to pay",
        "ADDITIONAL_FEES": "Additional Fees",
        "ADDITIONAL_SURCHARGERS": "Additional Surcharges",
        "MENU_ITEM_SEARCH": "Search Menu",
        "MENU_ITEM_SEARCH_NO_RESULTS": "No results for \"{{searchValue}}\" 🧐",
        "MENU_ITEM_SEARCH_OTHER": "Try another search?",
        "MENU_ITEM_SEARCH_CLEAN": "Reset results",
        "PROMOTION_MIN_ORDER_PRICE": "* For orders over {{currency}}{{minOrderPrice}}",
        "WITHOUT": "Without",
        "CONTINUE_TO_PAYMENTINFO": "Continue to payment info",
        "PAYMENT": "Payment",
        "BRANCHES": "Branches",
        "SERVER_ERROR": "Server error",
        "ERROR": "Error",
        "PAYMENT_ERROR": "Payment error",
        "PARTIAL_PAYMENT_ERROR": "Partial payment error",
        "NAME": "Name",
        "REGISTERING": "Registering",
        "REGISTER": "Register",
        "RESET_PASSWORD": "Reset password",
        "RESET_PASSWORD_INFO": "Enter new password and select Reset",
        "ORDER_HISTORY": "Order History",
        "SELECT_ORDER_METHOD": "Select order method",
        "ORDER_METHOD": "Order method",
        "ORDER_FIXED": "Bill",
        "SET_QUANTITY": "Set quantity",
        "CELL_PATTERN": "Cellphone number must begin with 05",
        "CELL_MINLENGTH": "Minimum of 10 digits for cellphone number",
        "VIEW_MENU": "View menu",
        "ORDER_SERVICE_CLOSED": "Order service closed",
        "SET_ORDER_DELAY": "Set order delay... ",
        "DELAY_TO": "Hour delay",
        "SET_ORDER_DELAY_TO_HOUR": "Delay order by an hour",
        "SAVE_ACCOUNT_PAYMENT_INFO": "Save your card in our digital wallet for quicker payments on upcoming orders",
        "ASAP": "ASAP",
        "PRMARY_PAYMENT_METHOD": "Default method",
        "ADD_PAYMENT": "Add payment",
        "SPLIT_PAYMENT": "Split payment",
        "SPLIT_PAYMENTS_DIFF_ERROR": "Payments don't balance at {{total}}. {{diff}} differ occurs",
        "BACK_TO_SITE": "Back to the website",
        "BACK_TO_APP": "Back to the App",
        "FREE_DELIVERY_FROM": "Free delivery from",
        "ADD_REMAINNING_AMOUNT": "Add remaining amount",
        "PAYMENTS_EXCEED_TOTAL_BY": "Payments exceed total by",
        "PAYMENT_AMOUNT": "Payment amount",
        "ENTER_FIRST_PAYMENT_AMOUNT": "Enter first payment amount",
        "EXCESS_PAYMENT": "Excess payment",
        "CLICK_TO_REMOVE_EXCESS_AMOUNT": "Click to remove excess amount",
        "CANCEL_SPLIT_PAYMENT": "Cancel payments split",
        "APPLY_PAYMENTS": "Approve payments",
        "SINGLE_PAYER": "Single payer",
        "MULTIPLE_PAYERS": "Number of payers",
        "SPLIT_ORDER_BETWEEN_PAYERS": "Split order between payers",
        "CALL_RESTAURANT": "Call restaurant",
        "SELECT_TABLE": "Select table",
        "TABLE_NO_ORDER": "Table number {{no}}",
        "CHHOSE_ROOM_NO": "What is your room number?",
        "ROOM_NO": "Room number",
        "ENTER_ORDER_NO": "Enter order number",
        "ORDER_DATE": "Order date",
        "ORDER_TIME": "Order time",
        "ADD_GRATUITY_TO_COURIER": "Add tip",
        "ORDER_TOTAL_WITHOUT_GRATUITY": "Total (without gratuity)",
        "ORDER_TOTAL_WITH_GRATUITY": "Total (inc. gratuity): {{total}}",
        "GRATUITY_PERCENT": "gratuity percent",
        "ENTER_GRATUITY_AMOUNT": "Enter gratuity amount",
        "CONTINUE_TO_GRATUITY": "Continue to gratuity",
        "REFRESH_ORDER": "Refresh order",
        "MONTH": "Month",
        "YEAR": "Year",
        "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "Enter amount to pay, without gratuity",
        "ENTER_AMOUNT_TO_PAY": "Enter amount to pay",
        "FULL_PAYMENT": "Full payment",
        "PARTIAL_PAYMENT": "Partial payment",
        "AMOUNT_EXEEDS_BALANCE_DUE": "Amount exceeds balance due",
        "BACK": "Back",
        "WORK_HOURS": "Working hours",
        "SELECT_ONE": "Select one",
        "DELIVERY_ADDRESS": "Delivery address",
        "DELIVERY_DETAILS": "Delivery details",
        "ADDRESS_DETAILS": "Address details",
        "BRANCH_DETAILS": "Restaurant details",
        "ADDITIONAL_OPTIONS": "Additional options",
        "MINUTES_VAL": "{{m}} minutes",
        "ADDITIONAL_OFFERS": "Additional offers",
        "SELECT_ADDITIONAL_OFFERS": "Choose additional offers",
        "CONTINUE_TO_CONTACTINFO": "Continue to contact info",
        "CC_CUSTOMER_NAME": "Name",
        "CC_CUSTOMER_ID": "ID / BN Number",
        "ORDERER_NAME": "Orderer name",
        "ORDERER_PHONE": "Phone",
        "ORDER_NO": "Order number",
        "REMOVE": "Remove",
        "apartment": "Apartment",
        "takeaway": "Takeout",
        "delivery": "Delivery",
        "PAY_WITH": "Pay with",
        "SELECT_ALTERNATE_PYMENT": "Select another payment method",
        "ENTER_CVV": "Enter CVV",
        "PLEASE_ENTER_CVV": "Please enter CVV",
        "ENTER_CVV_MESSAGE": "Enter CVV for Card: {{card}}",
        "ENTER_IDCARD": "Enter ID Number",
        "ENTER_IDCARD_MESSAGE": "Enter ID for Card: {{card}}",
        "ADD_GRATUITY_TO_ORDER": "Add tip",
        "GRATUITY_AMOUNT": "Tip Amount",
        "PASS_ORDER": "Sharing the bill?",
        "PASS_ORDER_TITLE": "Share with a friend",
        "PASS_ORDER_BY_PHONE_MESSAGE": "To receive a link by SMS text, please enter the phone number",
        "PASS_ORDER_BY_PHONE_CAPTION": "Cellphone number",
        "PASS_ORDER_BY_PHONE_SEND": "Send order",
        "PASS_ORDER_BY_PHONE_QR_MESSAGE": "OR scan the QR code",
        "PASS_ORDER_SENT_SUCCESS": "Order sent successfully",
        "CANNOT_SEND_ORDER_BY_SMS": "We apologize, but the order cannot be sent at the moment",
        "ID_CARD": "ID Number",
        "ORDER_TAGS": {
            "BREAKFAST": "Breakfast",
            "LUNCH": "Lunch",
            "DINNER": "Dinner",
            "WORK": "Business"
        },
        "ENTER_LOCALITY": "Enter Location",
        "TITLE": "{{name}} - TabitOrder",
        "TITLE_1": "{{name}} TabitPay",
        "TITLE_2": "{{name}} - TabitOrder",
        "PAYMENT_IN_PROGRESS": "Loading...",
        "CREATING_ORDER": "Loading...",
        "LOADING_ORDER": "Loading Order...",
        "INVALID_AMOUNT": "Invalid Amount",
        "INVALID_AMOUNT_RANGE": "Valid amount between {{min}} to {{max}}",
        "BACK_TO_MENU": "Back to menu",
        "ORDER_NOTES": "Order Notes",
        "Q_SAVEPM_1": "Interested in a quicker order process?",
        "Q_SAVEPM_2": "You can save your payment method in Tabit's digital wallet for quicker and faster payments",
        "Q_SAVEPM_3": "Pay and Save",
        "Q_SAVEPM_4": "Pay without saving",
        "DISH_CALCULATOR": "Dish Calculator",
        "MAKE_ADDITIONAL_PAYMENT": "Make additional payment",
        "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Make additional payment on this device",
        "DINER": "Diner",
        "GENERAL_DINER": "Center of Table",
        "ADDRESS_WITHOUT_HOUSE": "Address without house no",
        "TAX_AMOUNT": "Tax",
        "VAT_AMOUNT": "VAT",
        "INCLUSIVE_TAXES": "Inclusive Tax",
        "ADDRESS_TYPE": "Address Type",
        "VALID_UNTILL": "Valid Until",
        "share_message": "Hi, here's {{name}}, a restaurant I found on Tabit. Check it out!",
        "share_message_without_site": "Hi, here's a restaurant I found on Tabit. Check it out!",
        "ADDRESS_DESKTOP_HEADER": "Just checking if we deliver to your area",
        "ADDRESS_DESKTOP_ANNOUNCEMENT_SCREEN_READER": "An address search window has opened, please write your city, street name and house number",
        "RESTAURANT_DESKTOP_SEARCH_ANNOUNCEMENT_SCREEN_READER": "An restaurant search window has opened, please write your city, street or restaurant name",
        "SITES_DESKTOP_HEADER": "Choose restaurant for takeout",
        "SITES_DESKTOP_MENU_HEADER": "Choose restaurant to view the menu",
        "CHAIN_DIALOG_HEADER": "Choose a restaurant",
        "FUTURE_DELIVERY_ORDER_DIALOG_HEADER": "Choose delivery time",
        "WHERE_CAN_WE_FIND_YOU": "Where can we find you?",
        "FUTURE_ORDER_DIALOG_HEADER": "When will we see you?",
        "FUTURE_ORDER_POP_ANNOUNCEMENT_SCREEN_READER": "Future order scheduler screen opened: please select the date in the left column, and the time in the right column",
        "SLIP": {
            "OTH": "OTH",
            "ITEM_DISCOUNT": "Item discount",
            "TICKET_DISCOUNT": "Ticket discount",
            "TICKET_OTH": "OTH",
            "CHANGE": "Change"
        },
        "GROUP_VALIDATION": {
            "SELECT_ONE": "Please select 1",
            "SELECT_SOME": "Please select {{min}}",
            "SELECT_ATLEAST_ONE": "Please select at least 1",
            "SELECT_ATLEAST_SOME": "Please select at least {{min}}",
            "SELECT_UPTO_ONE": "Please select up to one",
            "SELECT_UPTO_SOME": "Please select up to {{max}}",
            "SELECT_BETWEEN": "Please select between {{min}} and {{max}}",
            "GROUP_EXCEPTION": {
                "TITLE": "Update your order",
                "BODY": "Parts of this item have changed since you last ordered. Please update your order according to the new details",
            },
        },
        "ACCESS": {
            "service_selection": "Service selection: Please select service",
            "tabit_logo": "Tabit logo",
            "call": "call",
            "gift-card-redeem": "עמוד מימוש כרטיס מתנה",
            "payment-dialog-open": "payment dialog open",
            "login-successfully": "The login was completed successfully!",
            "contact-details": "restaurant contact details",
            "site_service": "restaurant services",
            "address": "address",
            "distance": "Distance",
            "minus_circle": "minus",
            "plus_circle": "plus",
            "accessibility_statement": "Accessibility statement. The site supports NVDA screen reader.",
            "deselected": "item removed",
            "unselected": "unselected",
            "selected": "item selected",
            "ITEM_ADDED_PLUS": "item added",
            "same-page-link": "same page link",
            "BASKET": "basket",
            "right-arrow-nav": "right arrow navigation",
            "left-arrow-nav": "left arrow navigation",
            "restaurant": "restaurant",
            "ACCESSIBILITY_DECLARATION": "Accessibility declaration",
            "ACCESSIBILITY": "Accessibility",
            "ACCESSIBILITY_STATEMENT": "Accessibility Statement",
            "ACCESSIBILITY_SITE": "Go to the Accessible Site",
            "ACCESSIBILITY_IN_PROGRESS": "",
            "CLEAR_ACCESSIBILITY_FEATURES": "Clear accessibility features",
            "monochrom": "Monochrome",
            "light-contrast": "Light contrast",
            "dark-contrast": "Dark contrast",
            "enlarge-font": "Enlarge font",
            "reduce-font": "Reduce font",
            "readable-font": "Readable font",
            "big-white-curser": "Big black cursor",
            "big-black-curser": "Big white cursor",
            "enlarge-frame": "Enlarge",
            "highlight-links": "Highlight links",
            "highlight-headers": "Highlight headings",
            "image-info": "Image info",
            "back-button": "return to previous page",
            "enter_amount": "Gift Card Amount ({{min_amount}} to {{max_amount}})",
            "delete": "delete",
            "view-menu": "view menu",
            "add": "add",
            "remove": "remove",
            "edit": "edit",
            "day": "choose a day",
            "time": "choose a time",
            "guests": "select number of guests",
            "site-main-image": "Main site image",
            "language_screen_reader": "select language",
            "future-reservation-time": "when are you arriving?",
            "close": "close",
            "close_dialog": "close dialog",
            "delete_content": "delete filled in content",
            "out_of_stock": ":currently out of stock",
            "press_to_approve_and_continue_to_menu": "press to approve and continue to the menu page",
            "service_buttons_takeaway": "take await",
            "service_buttons_delivery": "delivery",
            "service_buttons_delay": "future date order",
            "service_buttons_eatin": "eat in order",
            "service_buttons_future_takeaway": "future take await",
            "service_buttons_future_delivery": "future delivery",
            "item_added": "one item has been added to your basket",
            "selection": "selection confirmed",
            "contrast-button": "contrast",
            "invalid-form": "Not all mandatory fields have been filled in, please fill in the required fields",
            "remove-payment_line": "remove payment line",
        },
        "_WEIGHT": {
            "units": "Unit",
            "price_per_weight": "Price per {{unit}} = {{price}}",
            "item_weight": "Item weight",
            "kg": "Kg (kg)",
            "gr": "Gram (gr)",
            "lb": "lb (lb)",
            "oz": "Oz (oz)",
            "l": "L (l)",
            "ml": "ml (ml)",
            "GRAM": "Gram"
        },
        "_DELAYED": {
            "service_caption": "For another day",
            "title": "Order timing",
            "method": "Delivery timing",
            "for_today": "For today",
            "for_other_day": "For another day",
            "service_today": "For today",
            "service_future": "Future order",
            "for_hour": "For {{val}} hour",
            "for_date": "For {{val}} day",
            "between": "between",
            "at_hour": "at",
            "select_date": "Select date",
            "select_slot": "Select hour",
            "select_slot_prompt": "Select supply date",
            "supply_time": "Supply time"
        },
        "CHECKOUT_STEP": {
            "extra_offers": "Extra offers",
            "contact": "My details",
            "gratuity": "Tip",
            "pay": "Pay",
            "summary": "Summary",
        },
        "ADDRESS_TYPES": {
            "apartment": "Apartment",
            "office": "Office",
            "house": "House"
        },
        "WEB_HEADER": {
            "table_booking": "Reserve a table",
            "order_delivery": "Takeout / Delivery",
            "restaurants": "Restaurants"
        },
        "date": "Date",
        "day": "Day",
        "month": "Month",
        "year": "Year",
        "from_you": "from you",
        "restaurants": "Restaurants",
        "restaurants_subtitle": "Find your favorite restaurant",
        "order": "Order",
        "seated": "Seated",
        "order_subtitle": "Takeout or Delivery",
        "order_action": "Order",
        "order_balance": "Current balance",
        "book": "Book",
        "book_subtitle": "Reserve a table",
        "book_tables_standby_subtitle": "Select a restaurant to be added to the waiting list",
        "book_tables_standby_title": "Waiting list",
        "book_tables_subtitle": "Select a restaurant to be seated immediately",
        "book_tables_title": "Available tables",
        "booking_description": "Just say when and we'll find a table for you!",
        "book_action": "Book",
        "pay": "Pay",
        "pay_subtitle": "Pay here with your cellphone",
        "pay_action": "Pay",
        "default": "Default",
        "save": "Save",
        "okay": "Okay",
        "OK": "Okay",
        "close": "Close",
        "back": "Back",
        "continue": "Continue",
        "register": "Register",
        "registration": "Register",
        "reorder_action": "Re-order NOW!",
        "redirect_to_device_settings": "Redirect to phone settings",
        "next_time": "Next time",
        "redirect_to_profile": "Redirect to profile",

        "location_select": "Location select",
        "my_location": "My Location",
        "my_location_unavailable": "Actual location unavailable",
        "change_location": "Change",

        "website": "Website",
        "privacy_policy": "Privacy Policy",
        "club_terms": "Loyalty Terms",
        "terms_and_conditions": "Terms and Conditions",
        "account_removal": "Account Removal",
        "remove_account": "Remove Account",

        "km": "km",
        "mile": "miles",
        "more": "More",
        "far_away": "Far Away",
        "around_you": "Around you",
        "And": "And",
        "and": "and",
        "until": "until",

        "delivery_or_takeway": "Delivery or Takeout?",
        "order_type_description": "Please select your preference.",
        "order_type_title": "Delivery or Takeout?",
        "otcSeated": "Counter",
        "other": "Other",
        "partial_payment": "Partial payment",
        "partial-payment": "Partial payment",
        "payment-short": "Payment",
        "add-items": "Add Items",
        "add-to-order": "Add to order",
        "send-order": "Send order",
        "add-to-order-with-amount": "Add to order {{amount}}",
        "back-to-order": "Back to order",
        "address_title": "Address",
        "add_address": "Add address",
        "my_recent_addresses": "My recent addresses",
        "my_default_addresses": "My default address",
        "please_confirm": "Please confirm...",
        "please_note": "Please note",
        "just_a_moment": "Just a moment",
        "dont_worry": "Don't worry",
        "please_enter_signature": "To approve payment, please sign here",
        "please_enter_value": "Please enter amount",
        "please_enter_location_value": "Please describe your location",
        "are_you_sure": "Please confirm",
        "Cancel": "Cancel",
        "update_account": "Update details",
        "your rank": "Your rank",
        "you_have": "You have",
        "accumulated": "accumulated",
        "points": "Points",
        "prepaid_points": "Pre-paid points",
        "recent_actions": "Recent actions",
        "view_bill": "View bill",
        "goto-rest-page": "Go to restaurant page",
        "photos": "Photos",
        "openning_houres": "Openning hours",
        "open_now": "Open",
        "closed_now": "Closed",
        "will_be_open_at": "Will open at",
        "rank": "Rank",
        "slide_down_to_close": "Swipe down to close",
        "Open": "Open",
        "Open Now": "Open Now",
        "Closed": "Closed",
        "Closed Now": "Closed Now",
        "profile": "Profile",
        "update_details": "Update details",
        "reload_app": "Reload app",
        "closed_for_day": "Closed for today",
        "missing_site_description": "Missing restaurant description. Generating...",
        "show_more_sites": "Show more restaurants",
        "show_more": "Show more",

        "house": "House",
        "office": "Office",
        "entrance": "Entrance",
        "floor": "Floor",
        "no_addresses": "No addresses",

        "address_description": "Please select your address.",
        "new_address": "New Address",
        "saved_address": "Saved Address",
        "address_placeholder": "Street, City, State",
        "new_in_tabit": "New at Tabit",
        "address_type": "Address type",
        "please_select_type": "Please select a type",
        "address_not_found": "Address not found",
        "please_type_address_short": "Add a new address",
        "please_type_address_long": "Type a place, city, or a full address",
        "please_type_delivery_address_short": "Add delivery address",
        "please_type_delivery_address_long": "Type a full address, including number",
        "please_type_address_full": "Type a full address",

        "error_title": "Hmm...",
        "error_general": "An unknown error occurred. Please try again.",
        "error_invalid_cards_for_auto_attach": "An error occurred. Please get in touch with the restaurant.",
        "error_invalid_sign_in_credentials": "It seems your email or password is wrong. Please try again.",
        "error_invalid_touchid": "We could not verify your Face/Touch ID. Please log in using your cellphone number.",
        "error_invalid_load_sites": "An error occurred while loading the data. Please try again.",
        "error_invalid_sign_in_email_already_exists": "Email already exists, please enter another one.",
        "error_client_credentials": "An error occured. Please try again.",
        "error_cg_wallet_credentials": "Invalid card details. Please try again",
        "error_sign_up": "An error occured while Sign Up. Please try again.",
        "error_sign_up_user_already_exists": "A user with this email already exists. Please try to log in with this email and password.",
        "error_reset_password_missing_email": "Please enter your email first.",
        "error_reset_password": "Email is incorrect or not connected to a user. Please try again or Sign Up.",
        "error_full_address_required": "Please provide your full address, including house number",
        "error_invalid_address": "The selected address is not valid, please select another address",

        "confirmation_title": "Yay!",
        "confirmation_password_reset": "An email with instructions for how to reset your password has been successfully submitted.",

        "main": "Main",
        "login": "Sign In",
        "logout": "Sign Out",
        "email": "Email",
        "password": "Password",
        "forgot_your_password": "Forgot your password?",
        "birthDate": "Birthday",
        "anniversary": "Anniversary Date",
        "continue_with_email": "Continue with email",
        "or_continue_with": "Or continue with",
        "facebook": "Facebook",
        "google": "Google",
        "search": "Search",
        "restaurants_search": "Restaurant search",
        "occasions_search": "Occasion search",
        "categories": "Categories",
        "services": "Services",
        "name": "Name",
        "phone": "Phone",
        "send": "Send",
        "sending": "Sending",
        "enter_code": "Enter code",
        "verification_code": "Verification code",
        "verification_code_message": "This is your verification code {{pincode}}.",
        "confirm_code": "Submit",
        "did_not_arrive_resend": "Resend Code",
        "resent": "Sent again...",
        "sign_in": "Sign in",
        "sign_up": "New customer? Sign up!",
        "searching": "Searching",
        "tags": "Cuisine",
        "ratings": "Ratings",
        "price": "Price",
        "auth_touch": "Face/Finger recognition",
        "style": "Style",
        "accessibility": "Accessibility",
        "mood": "Mood",
        "kosher": "Kosher",
        "collective": "Collective",
        "events": "Events",
        "specials": "Specials",
        "suitable": "Suitable",
        "by_date": "By date",
        "by_restaurant": "By restaurant",
        "restaurant_type": "Restaurant type",
        "tabit_restaurants": "Tabit restaurants",
        "about_the_restaurant": "About the restaurant",
        "articles": "Articles",
        "paid": "Paid",
        "single_payer": "Single payer",
        "to_main_page": "To the homepage",
        "on_the_house": "On the house",
        "regulations": "Regulations",
        "input_pay_amount": "Enter amount to pay",
        "choose_payment": "Select payment method",
        "full-payment": "Full payment",
        "split_payment_by": "Split payment by",
        "show_code_to_staff": "Show code to the staff",
        "code_valid_time_1": "Code valid for 5 minutes. Charge will be processed only",
        "code_valid_time_2": "after the staff approves the code.",

        "my_account": "Account",
        "my_account_description": "You may edit your personal information or change your password.",
        "personal_information": "Personal Information",
        "first_name": "First Name",
        "last_name": "Last Name",
        "mobile": "Mobile",
        "cell": "Cell",
        "change_password": "Change Password",
        "old_password": "Old Password",
        "new_password": "New Password",
        "next": "Next",
        "notes": "Notes",
        "my_wallet": "Wallet",
        "my_wallet_info": "Payment method settings",
        "my_addresses": "Addresses",
        "my_addresses_info": "Saved addresses for delivery",

        "clear": "Clear",
        "credit_no": "Credit #",
        "credit_card_number": "Credit Card Number",
        "credit_type": "Credit Type",
        "credit_type_regular": "Credit Card",
        "credit_type_cbus": "Cbus",
        "credit_type_10bis": "10bis",
        "expiration_date": "Expiration Date",
        "expiration_month": "Month",
        "expiration_year": "Year",
        "cvv": "CVV",
        "id_card": "ID Number",
        "scan_pm": "Scan card",
        "add_pm": "Add payment method",
        "HOUR": "Hour",
        "DATE": "Date",
        "PAY": "Pay",
        "CAN_TD": "Order a delivery",
        "expiration": "Expiration",
        "comments": "Comments",
        "PERFORMING_PAYMENT": "Performing payment...",
        "FINALIZING_ORDER": "We're working on it...\nPlease don't close the page",
        "PLEASE_WAIT": "Please wait...",
        "WERE_ON_IT": "We're on it...",
        "choose_restaurant": "Choose a place",

        "my_preferences": "Preferences",
        "my_preferences_description": "Please choose your preferred restaurant categories and dishes",
        "my_preferred_categories": "My Preferred Categories",
        "my_preferred_tags": "My Preferred Tags",
        "my_preferred_dishes": "My Preferred Dishes",
        "my_preferences_info": "Favorite kitchens and allergies",
        "my_preferences_selected_description": "Your preferences",
        "my_preferred_allergies": "Allergies",

        "my_history": "Order History",
        "my_history_info": "Opened and closed orders",
        "my_history_description": "All your visits and orders appear on this list",
        "my_recent": "Lately",
        "search_history": "Search history",
        "search_history_description": "Hey, in case you've visited {{descTitle}} restaurants, we can search and load your order history",

        "my_benefits": "Benefits",
        "benefits_and_promotions": "Discounts & Promotions",
        "benefits_use": "Used benefits",
        "more_benefits": "Discounts",
        "my_benefits_info": "To all your benefits info",
        "my_benefits_description": "All your points and benefits are on this list",
        "immediate_realization": "For immediate redemption",
        "immediate_topup": "For immediate top up press here",
        "prePaid_realization": "In pre-paid card",
        "benefit_details": "Benefit details",
        "valid_until": "Valid until",
        "benefits_club": "Benefits club",
        "brithday_benefit": "Brithday Reward",
        "join_benefit": "Join Reward",
        "renew_benefit": "Renewal Reward",
        "anniversary_benefit": "Anniversary Reward",
        "general_benefit": "Reward",
        "general_time_to": "Until",
        "voucher_benefit": "Voucher",
        "punch_card": "Punch card",
        "points_benefit": "Points Reward",
        "got_it": "Got it",
        "at": "At",

        "my_favorites": "Favorites",
        "your_recent_orders": "Your recent orders",
        "no_recents": "No recent reservations found",
        "no_favorites": "No favorites yet",
        "no_deliveries": "It seems there are no deliveries to your region",
        "future_reservations": "Future reservation",
        "future_reservation": "Future reservation",
        "future_reservations_and_discounts": "Future reservation and discount vouchers",
        "club_benefits": "Club benefits",
        "club_points": "Club points",
        "marketplace_title": "Gift Card Marketplace",
        "your_points_worth": "Your points worth money and available for use",
        "pre_paid_available": "Pre-paid card available for immediate use",
        "pre_paid_top_up": "Top up balance",
        "realize_points": "Redeem points",
        "your_tracked_order": "Your tracked order",
        "order_in_progress": "Order in progress",
        "order_end": "Start a new order?",
        "future_order_pending": "Order pending approval",
        "order_details": "Order details",
        "future_order_standby": "Order on standby",
        "continue_order": "Resume order​",
        "payment_in_progress": "Payment in progress",
        "payment_order": "Continue payment",
        "cancel_order": "Yes, exit",
        "confirm_cancel_order": "Yes, exit",
        "confirm_continue_order": "Resume order​",
        "extended_results": "Extended results",
        "book_a_place": "Reserve a table",
        "enter_order_no": "Enter order number",

        "quickpay": "Quick Pay",
        "quickpay_action": "Quick Pay",
        "issues-feedback-link": "Feedback",

        "version": "Version",

        "all_sites": "All restaurants",
        "to_all_sites_title": "Looking for something specific?",
        "to_all_sites": "To all restaurants",
        "to_restaurant": "To the restaurant",
        "sites_list": "Restaurant list",
        "sites_nearby": "Nearby restaurants",

        "not_available": "Not available",
        "not_available_now": "Not available now",
        "not_available_to_this_address": "Not available for this address",
        "available_from": "Available from ",
        "preorder": "Pre-order",
        "preference": "Preference",
        "future_order": "Future order",
        "availability": "Availability",
        "only_available": "Only available",
        "preparing-food": "As soon as items are added to the order you will see them here",
        "press here": "Press here",

        "near_me": "Near me",
        "extra_services": "Extra services",
        "near_me_pay_more_title": "Restaurants Nearby",
        "kitchens": "Cuisine",
        "cuisine": "Cuisine",

        "area": "Area",
        "latest_orders_title": "Want to order again?",
        "latest_orders_subtitle": "Your latest orders and visits are here below",
        "sites_near_me_title": "Restaurants Nearby",
        "sites_near_me_subtitle": "All the most relevant restaurants can be found right here!",
        "redirect_to_my_history": "View all my latest orders",
        "reserve_a_place_at": "Reserve a table at",
        "confirm_exit": "Exit?",
        "resart_order_confirm": "Exit?",
        "events_and_benefits": "Events and benefits",
        "marketplace_main_title": "Gift Card Marketplace",

        "gratuity": "Tip",
        "AMOUNT_TO_PAY": "Amount to pay",
        "PAYUP": "Continue to payment",
        "TABLE_BILL": "Table bill",
        "WAITER": "Server",
        "PAY_WITH_WALLET": "Pay with your wallet",
        "CONTINUE_WITHOUT_GRATUITY": "Continue without a tip",
        "i_approve_the": "I approve the",
        "approve_commercials": "By filling out this registration form, I authorize the club to send me advertising materials from time to time via SMS text messages.",
        "approve_sms": "SMS marketing notifications",
        "approve_mail": "Email marketing notifications",
        "when_to_book": "When to reserve a table",
        "without_gratuity": "No tip",

        "app_smart_banner_title": "",
        "app_smart_banner_body": "Download the app",
        "contact_us": "Contact us",

        "business_invite_restaurant_owner": "Tabit",
        "business_invite_restaurant_owner_screen_reader": "tabit, to company website,",

        "business_invite_join_us": "Company website ",

        "business_invite_restaurant_owner_seo": "Restaurant owner?",
        "business_invite_join_us_seo": " ",

        "today": "Today",
        "understood": "Understood",
        "try_again": "Try again",
        "wait_on": "Wait",
        "change_language": "Change Language",

        "pepper_pay": {
            "15_bonus": "$15 Benefit",
            "pepper_bonus": "PAY Benefit",
            "pepper_pay_payers": "For PAY application payers!",
            "pepper_pay_realization": "To redeem the benefit pay with PAY on the payment page",
            "takeout_delivery_above_amount_tip_not_included": "Valid for fees over $50 (not including tip) in delivery or takeout orders",
            "look_for_pay_mark": "Look for restaurants marked with PAY (Subject to"
        },
        "VALIDATIONS": {
            "CREDIT_CARD": "Credit card number is invalid",
            "CREDIT_CARD_EXPIRATION": "Expiration date is invalid",
            "INVALID_ID_NUNBER": "ID number is invalid"
        },
        "DAYS": {
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday"
        },
        "PMS": {
            "Stripe": "Stripe",
            "Braintree": "Braintree",
            "Vantiv": "Vantiv",
            "HeartLand": "HeartLand",
            "CardPoint": "CardPoint",
            "AuthorizeNet": "AuthorizeNet",
            "reminder": "Reminder",
            "remainder": "Remainder",
            "remainder_wallet_option": "Remainder",
            "prepaidBalance": "Prepaid balance",
            "points": "Points",
            "creditCard": "Credit",
            "Cibus": "Cibus",
            "10bis": "10bis",
            "CreditGuard": "Credit",
            "cash": "Cash",
            "TavHazahav": "Tav Hazahav",
            "cheque": "Cheque",
            "payLeumi": "Pay Leumi",
            "meshulam": "Bit",
            "giftCard": "Gift Card",
            "ChargeAccount": "Charge Account",
            "ChargeAccountPaymentProvider": "Charge Account",
            "OperaPaymentProvider": "Room Charge",
        },
        "site_details": {
            "tab_label_overview": "Overview",
            "tab_label_reviews": "Reviews",
            "tab_label_map": "Map",
            "tab_label_history": "History",
            "tab_label_menu": "Menus"
        },
        "menus": {
            "evening menu": "Evening menu",
            "morning menu": "Morning menu",
            "night menu": "Night menu",
            "noon menu": "Noon menu"
        },

        "occasions": {
            "occasions": "Occasions",
            "occasion": "Occasion",
            "event": "Event",
            "events": "Events",
            "online_event": "Message",
            "holiday": "Holiday",
            "all_day": "All day",
            "start": "Start",
            "end": "End",
            "time_from": "From",
            "time_until": "Until",
            "hang_out_type": "Type",
            "from_day": "From",
            "every_week": "Every week",
            "every_two_weeks": "Once in two weeks",
            "every_month": "Once a month",
            "on_days": "on",
            "on_day": "on",
            "starts_soon": "Soon",
            "now": "Now",
            "until": "Until",
            "more_info": "More",
            "confirm": "Close",
            "today_all_day": "Today, all day",

            "dates": {
                "events_for_today": "NOW - Today’s events",
                "tomorrow": "Tomorrow",
                "during_the_week": "During the week",
                "next_week": "Next week",
                "upcoming_month": "Upcoming month"
            },

            "tags": {
                "everything": "Everything"
            }
        },
        "marketplace": {
            "results_page_title": "Tabit Gift It",
            "results_page_description": "Give the gift of good food with a gift card!",
            "no_results_found": "No results found",
            "button_text": "Get a gift card",
            "search_placeholder": "Search restaurant",
            "more_information": "More information",
            "search_input": "Search by business/city"
        },
        "RANKS": {
            "0": "Diner",
            "1": "Regular",
            "2": "Guest of honor",
            "3": "Foodie",
            "4": "Order champion",
            "5": "VIP",
            "6": "Living to eat!",
            "7": "Food critic"
        },
        "SERVER_MESSAGES": {
            "ORDER_CLOSED_PAYED": "Order not found. It may have been paid and closed",
            "CHAIN_DISABLED": "Chain disabled",
            "EMAIL_IN_USE": "Email already in use. Please enter another one",
            "EMAIL_NOT_FOUND": "Email not found",
            "FIREBASE_DOWN": "Service unavailable",
            "SHVA_OFFLINE": "Credit card payment currently unavailable.\nPlease use another payment method or try again later",
            "PAYMENT_QUOTA_EXCEEDED": "Insufficient funds ({{v}})",
            "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "The amount is higher than the credit limit set for the room. Please go to the reception or pay with another payment method.",
            "CREDIT_GUARD_EX_ERROR": "There was an error processing the request. Please try again.",
            "EXTERNAL_PROVIDER_ERROR": "{{provider}} Payment Failed",
            "SMS_PHONE_ERROR": "It is not possible to send a text message to the provided number {{phone}}.\nPlease contact the restaurant.",
            "INVALID_ORDER_ID": "Requested transaction not found",
            "INVALID_QR": "Requested transaction not found",
            "INVALID_USER_ORPASS": "Username or Password invalid",
            "MISSING_CHAIN": "Chain not found",
            "NO_ACTIVE_SITES": "No active restaurants",
            "NO_SITES_IN_CHAIN": "No restaurants in chain",
            "OrderLockedByAnotherUser": "Payment failed. Check is handled by another user",
            "UPDATE_ACCOUNT_ERROR": "Account update error"
        },
        "CREDIT_GUARD": {
            "ERROR_CODE_001": "The card is blocked, contact the credit card company (code 001).",
            "ERROR_CODE_002": "The card is stolen, contact the credit card company (code 002).",
            "ERROR_CODE_003": "An error occurred, please contact the credit company (code 003).",
            "ERROR_CODE_004": "A decline was received from the credit company, the transaction was not approved (code 004).",
            "ERROR_CODE_006": "CVV or ID number are wrong, please try again (code 006).",
            "ERROR_CODE_010": "The transaction is not approved for execution, enhanced verification is required. Please contact the credit card company (code 010).",
            "ERROR_CODE_033": "The card number entered is incorrect, please try again (code 033).",
            "ERROR_CODE_036": "Card is expired (code 036).",
            "ERROR_CODE_039": "The card number entered is incorrect, please try again (code 039).",
            "ERROR_CODE_173": "We were unable to process the payment, please make sure all the details are correct (code 173)",
            "ERROR_CODE_200": "We were unable to process the payment, please make sure all the details are correct (code 200)",
            "ERROR_CODE_316": "An external interface failure. Please contact the credit card company (code 316).",
            "ERROR_CODE_358": "Sensitive details were not found in the database (code 358).",
            "ERROR_CODE_401": "We were unable to process the payment, please make sure all the details are correct (code 401)",
            "ERROR_CODE_405": "We were unable to process the payment, please make sure all the details are correct (code 405)",
            "ERROR_CODE_414": "We were unable to process the payment, please make sure all the details are correct (code 414)",
            "ERROR_CODE_520": "There is no authorization to perform the transaction, please contact the credit company (code 520).",
            "ERROR_CODE_599": "The credit issuer's token service is not available, please contact the credit company (code 599).",
            "ERROR_CODE_695": "This type of credit card is not supported, please pay with another card (code 695).",
            "ERROR_CODE_900": "A transaction was blocked due to non-commitment to a secure transaction (code 900).",
        },
        "SERVICES": {
            "book": "Reserve a table",
            "order": "Online order",
            "quickpay": "Quick pay"
        },
        "PO": {
            "CHANGE": "Tip\\Change",
            "ITEM_DISCOUNT": "Item discount",
            "ORDER_NO": "Order number",
            "ORDER_TIP": "Tip",
            "ORDER_TO": "Order address",
            "ORDER_TOTAL": "Order total",
            "ORDERER_NAME": "Orderer name",
            "ORDERER_PHONE": "Phone",
            "OTH": "On the house",
            "PAID": "Paid",
            "REMAINED_PAY": "Remaining",
            "TABLE_BILL": "Table bill",
            "TICKET_DISCOUNT": "Bill discount",
            "TICKET_OTH": "On the house",
            "WAITER": "Server",
            "WITHOUT": "Without"
        },
        "ORDER_SITES_BY": {
            "distance": "Distance",
            "name": "Name",
            "tabit": "Tabit ranking"
        },
        "NOTIFICATIONS_WIDGET": {
            "clear_all": "Clear all",
            "messages": "Messages"
        },
        "_CODES": {
            "409012": "OverTenderNotAllowed",
            "409023": "PaymentFailed",
            "409028": "OrderPaymentDocumentsNotIssued",
            "409030": "PasswordIsTooShort",
            "409036": "AnotherPaymentInProcess",
            "409039": "CourseHasIncompleteItem",
            "409040": "ItemInventoryOutOfStock",
            "409073": "PaymentTimedOut",
            "409091": "ProcessOnlineOrderFailed",
            "409092": "PayForOnlineOrderFailed",
            "409093": "PaymentProviderTimeout",
            "409098": "PaymentServiceUnavailable",
            "409110": "SendSmsMessageFailed",
            "409136": "OnlineOrderAlreadyHandedOff",
            "409806": "CreditCardNotSupported",
            "409808": "CreditCardExpired",
            "409809": "CreditCardCheckDigit",
            "409810": "CreditCardCVVError"
        },
        "areas_search_title": "Areas",
        "areas": {
            "haifa": "Haifa area",
            "north": "North",
            "center": "Center",
            "tel_aviv": "Tel Aviv-Jaffa area",
            "jerusalem": "Jerusalem area",
            "south": "South",
            "dallas": "Dallas",
            "fort_worth": "Fort Worth",
            "irving_grapevine": "Irving & Grapevine",
            "burleson": "Burleson",
            "plano": "Plano",
            "frisco": "Frisco",
            "mckinney": "McKinney",
            "sydney": "Sydney",
        },
        // Needed for dynamic language change
        "חיפה": "Haifa",
        "צפון": "North",
        "מרכז": "Center",
        "תל אביב": "Tel Aviv",
        "ירושלים": "Jerusalem",
        "BENEFIT_TYPE": {
            "points": "Points",
            "Other": "Benefit",
            "BirthdayBenefit": "Birthday Reward",
            "AnniversaryBenefit": "Anniversary Reward",
            "JoinBenefit": "Welcome Reward",
            "PointsBenefit": "Points Reward",
            "RenewBenefit": "Renewal Reward",
            "Vouchers": "Voucher",
            "Benefit": "Reward",
            "PunchCard": "Punch Card",
            "PointsStore": "Rewards Store",
            "PointsStoreBasket": "Points Redemption",
        },
        "ROUTE": {
            "book-a-table": "book-a-table",
            "deliveries": "deliveries",
            "restaurants": "restaurants",
            "new-at-tabit": "new-at-tabit",
            "nearest-restaurants": "nearest-restaurants",
            "events": "events",
            "reservation": "online-reservations"
        },
        "AVS": {
            "billing_address": "Billing Address",
            "state": "State",
            "select_state": "Select State",
            "city": "City",
            "address": "Address",
            "zip_code": "Zip Code",
        },
        "TGM": {
            "FIND_ME_APLACE": "Find me a place",
            "FIND_ME_ARESTAURANT": "Find me a restaurant",
            "DINERS": "Guests",
            "Day": "Day",
            "DATE": "Date",
            "TIME": "Time",
            "BOOK_TITLE": "Booking details",
            "ONE_DINER": "One diner",
            "DETAILS_TITLE": "Fill in the details to complete the process.",
            "SUCCESS_TITLE": "Order accepted",
            "SUCCESS_TITLE_STANDBY": "Please note, we've added you to the waiting list.\nWe will contact you as soom as a table becomes available",
            "SUCCESS_TITLE_PENDING_APPROVAL": "Please note, your order has not yet been approved. We will contact you as soon as possible to confirm your order.",
            "DEPOSIT_TITLE": "To complete the booking process please enter your credit card details for deposit.",
            "LICENSE_AGREEMENT": "By pressing 'Save' you are agreeing our Terms of Use and Privacy Policy. For details press here ⟩",
            "SAVE": "Save",
            "standby": "Waiting list",
            "instant_booking": "Immediate approval",
            "pending_approval": "Approval required",
            "form_description_standby": "To be added to the waiting list, please fill in the following information.",
            "form_description_pending_approval": "This reservation is subject to restaurant approval. Please fill in the following information to complete the process.",
            "standby_list": "Waiting list",
            "missing_cc_details": "Missing cc details",
            "missing_deposit_payment": "Missing deposit payment",
        },
        "WEB_CONTAINER": {
            "start_logo": "tabit - main page",
            "language_select": "Switch site language to Hebrew",
            "home": "Main",
            "search_now": "Search",
            "search": "Search for a restaurant, cuisine, or style",
            "find_table": "Find a table",
            "show_more": "Show more",
            "service": "Service",
            "back_to_regular_results": "Back to Results",
            "booking_bar": {
                "day": "Day",
                "day_screen_reader": "Day selection",
                "hour": "Hour",
                "hour_screen_reader": "Hour selection",
                "guests": "Guests",
                "guests_screen_reader": "Guests number selection",
                "address": "Address",
                "category": "Categories",
                "area": "Areas",
                "choose_day": "What Day?",
                "choose_hour": "What Hour?",
                "choose_guests": "How Many Guests?",
                "all_categories": "All Categories",
                "all_areas": "All Areas",
                "tags": "Tags",
                "tags_screen_reader": "Tags selection, such as restaurant style, food type and other features",
                "clear_tags": "Clear Tags",
                "rating": "Rating",
                "rating_screen_reader": "Rating selection",
                "all_ratings": "All Ratings",
                "price": "Price",
                "price_screen_reader": "Price range selection",
                "all_prices": "All Prices"
            },
            "book_a_table": {
                "tab_title": "Reserve a table",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "new_restaurants_in_tabit": "New Restaurants at Tabit",
                "new_restaurants_in_tabit_screen_reader": "New Restaurants At Tabit",
                "kitchens": "Top Cuisines",
                "restaurants_by_areas": "Featured Areas",
                "show_more": "Show More",
                "near_me": "Near Me",
                "no_results_found": "No results. Please choose another time or date and we'll search again",
                "results_page_description": "Select date, hour, and diners and select 'Find Me a Table'",
                "results_page_title": "Reserve a table",
                "select_future_date": "The selected time has passed, please select again"
            },
            "deliveries": {
                "tab_title": "Deliveries & Takeout",
                "tab_title_screen_reader": "Deliveries and take out",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "results_page_title": "Deliveries & Takeout",
                "no_results_found": "No results"
            },
            "restaurants": {
                "tab_title": "Restaurants",
                "main_title": "Where do you want to hang out?",
                "main_subtitle": "Reserve a table quickly and easily at one of the country's best restaurants",
                "results_page_title": "Restaurants",
                "no_results_found": "No results found"
            },
            "marketplace": {
                "tab_title": "Tabit Gift It",
                "main_title": "Tabit Gift It",
                "home_page_section_title": "Give the gift of good food",
                "search_placeholder": "Search restaurant"
            },
            "web_article": {
                "bottom_content_button": "Next Article >>"
            }
        },
        "MESSAGES": {
            "EXTRA_PAGE_ERROR": "Selection is required to continue",
            "GENERIC_GET_CUSTOMER_ERROR": "An error occurred during the login process. Please try again or contact the restaurant for assistance.",
            "GENERAL_ERROR": "General Error",
            "NO_INTERNET": "Please check your internet connection",
            "ENABLE_LOCATION": "Enable location",
            "ENABLE_NOTIFICATIONS": "Enable notifications",
            "LOADING_INITIAL_SITES": "Looking for your next experience",
            "EMAIL_MISSING": "Hi, we're happy you joined, to enjoy all of the content you must add your email to your profile.",
            "EMAIL_STILL_MISSING": "Only the email left to finish the sign up process",
            "LOADING_SITES": "Wait, there's more...",
            "NO_MORE_RESULTS": "No more results...",
            "NO_ORGS_RESULTS": "We were unable to find matching results",
            "NO_ORGS_RESULTS_FOR_THIS_ADDRESS": "No available restaurants for this address",
            "CONFIRM_SIGNOUT": "Do you want to sign out?",
            "sign_in_by_phone_message": "Hey, we're glad to see you!",
            "sign_in_by_phone_description": "Let’s verify your phone number and we’re good to go",
            "enter_code_message": "We have sent a verification code to ",
            "sign_in_by_email_message": "Hey, please sign in with email and password",
            "wl_sign_up_title": "Sign up for club",
            "sign_up_message": "Just a bit more information and we’re done",
            "saved_successfully": "Saved successfully",
            "deleted_successfully": "Deleted successfully",
            "error_getting_bill": "Error getting your bill",
            "error_full_address_required": "Error, full address required",
            "error_invalid_sign_in_email_already_exists": "Email already exists, please enter another one.",
            "invalid_email_entered": "Invalid email provided",
            "service_not_available_for_site": "This service is currently unavailabale for this restaurant",
            "Q_REMOVE_CARD": "Delete this card?",
            "NO_PMS_FOUND": "No payment methods found for your account",
            "NO_SITE_PMS_FOUND": "No payment methods found for this restaurant",
            "NO_RESTS_FOUND": "No restaurants found for this search",
            "NO_PMS_FOUND_ADD": "No proper payment methods found for your account. Select 'Add payment method' to enable this service.",
            "SIGNIN-EMAIL-EX": "Enter email address",
            "SIGNIN-EMAIL-EX-HELP": "Enter the email address you previously registered with",
            "SIGNIN-CHALLENGE-EX": "A verification code has been sent to your cellphone",
            "SIGNIN-CHALLENGE-EX-HELP": "Haven't got a code? <b>Send again</b>",
            "SAVE_INFO": "Save contact and payment information",
            "SAVE_INFO_END": "Sign up for quick and easy orders in the future",
            "SAVE_INFO_PAYMENT": "Save payment information",
            "SIGNIN_CHALLENGE": "A verification code was sent to you via SMS tex, please enter it and select Login",
            "PAYMENT_CHALLENGE": "Verification code sent to\n{{phone}}\nvia SMS text",
            "PAYMENT_CHALLENGE_RETRY": "The code you entered is incorrect\nAnother verification code was sent via SMS text",
            "PAYMENT_ENTER_PHONE": "Let's verify your phone number and we're good to go",
            "PAYMENT_OTP": "We have sent a verification code to {{phone}}",
            "SIGNIN_VALIDATION": {
                "incorrect-phone-number": "Incorrect phone number",
            },
            "TRANSACTION_SUCCESS": "Paid succesfully",
            "ORDER_CLOSED": "The order was fully paid",
            "INVALID_ORDER": "Order not found",
            "PAYMENT_FAILED": "Payment failed",
            "INVALID_PIN": "The code is invalid",
            "SIGN_FOR_PAYMENT_CONFIRM": "Sign for payment confirmation",
            "SIGNATURE_MANDATORY": "Sign in mandatory for payment confirmation",
            "WAITER_NOTIFIED": "A member of our staff has been informed and will be with you shortly",
            "HAPPY": "We'd love to see you again!",
            "INVOICE_SENT": "An invoice was sent to your email",
            "ITEM_ADDED_TO_BASKET": "Item added to basket",
            "NO_BASKET_ITEMS": "No items in basket",
            "CONFIRM_ORDER_FROM_BRANCH": "You chose to order from: {{branch}}",
            "FINDING_THE_BRANCH_NEAREST_TO_YOU": "Finding the nearest branch to you",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "We're sorry, no branch found serving this address: {{address}}",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "Deliveries to this address are currently available for future orders only",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "Delivery to this address for future orders is currently unavailable",
            "NO_BRANCH_SERVING_ADDRESS": "The restaurant does not deliver to this address",
            "CONFIRM_RESET_ORDER": "Restart order?",
            "DO_YOU_APPROVE_CONTRACT": "Do you accept the Terms and Conditions?",
            "DO_YOU_APPROVE_CONTRACT_TITLE": "Before we go on",
            "CONDITIONS_PRIMARY_BUTTON_TEXT": "Yes",
            "CONDITIONS_SECONDARY_BUTTON_TEXT": "Exit",
            "RESTORE_PASSWORD_1": "Please enter the email connected to the account",
            "SIGNIN_SUCCESS": "Welcome back {{name}}, you have succesfully logged in to the system",
            "FORGOTPASS_SUCCESS": "Login instructions were sent to the email address you entered",
            "ACCOUNT_UPDATE_SUCCESS": "Account updated succesfully!",
            "TITLE_HUNGRY": "We know you’re hungry",
            "BRANCH_DISABLED_NOW": "This service is currently unavailable.\n {{t}}.",
            "BRANCH_DISABLED_NOW_ERROR": "This service is currently unavailable. It will be available at {{t}}.",
            "BRANCH_CLOSED_TODAY": "Branch is closed today.",
            "BRANCH_CLOSED_NOW": "This service opens at {{t}}, but don't worry,\nyou can make a pre-order that will be prepared\nonce the branch opens.",
            "SELECT-LOYALTY-SERVICE": "Please sign in to your loyalty account to continue with the order",
            "MIN-ORDER-PRICE": "Minimum delivery price is {{t}}\n(not including delivery cost).\nPlease add items to the order.",
            "SERVER_ERROR": "There was an error processing your request,\n Please contact the restaurant",
            "PAYMENT_ERROR": "We were unable to process the payment, please make sure all the details are correct.",
            "PAYMENT_ERROR_TITLE": "Payment Error",
            "PAYMENT_LOCKED": "The order was locked by another user. Please try again.",
            "REGISTER_SUCCESS": "Registration successful",
            "PASSWORD_RESET_SUCCESS": "Password reset successful",
            "CUSTOMER_ENTITYALREADYEXISTS": "This email already exists in the system",
            "PHONE_VERIFICATION_FAILED": "The code appears to be incorrect or expired. Please try again.",
            "PHONE_VERIFICATION_FAILED_TITLE": "Hmm...",
            "TOO_MANY_CODE_REQUESTS": "Your account has been temporarily blocked due to too many failed attempts.\nPlease try again later.",
            "VOUCHER_ALREADY_USED": "Looks like the coupon has already been redeemed",
            "SERVICE_UNAVAILABLE": "Ordering service currently unavailable, please try again later",
            "MOD_CANT_SELECT_MORE_THEN": "You can select no more than {{max}} extras",
            "MOD_PLEASE_SELECT_AT_LEAST": "You must select at least {{min}} extras",
            "MOD_PLEASE_SELECT_AT_LEAST_ONE": "At least one extra must be selected",
            "CANNOT_PAY_TRAINING_MODE": "System in training mode. Payment cannot be completed. Please call the restaurant\n {{phone}}",
            "ORDER_CANNOT_BE_PROCESSED": "We were unable to process the payment. Please contact the restaurant.\n Error: {{code}}",
            "TIMEZONE_CHANGED": "It seems there's an issue with your device's clock. To ensure the best experience, please restart your order. Thank you for understanding!",
            "ORDER_ITEMS_VALIDATION_ERROR": "Certain items you've selected cannot be ordered.\nPlease contact the restaurant to continue with your order.",
            "ARE_YOU_SURE": "Are you sure?",
            "Cancel": "Cancel",
            "DISABLED_REGION_MESSAGE": "We currently do not deliver to this address.\nPlease try again later",
            "DISABLED_REGION_TITLE": "Delivery service unavailable",
            "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "Deliveries to this address for future orders is currently unavailable ",
            "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "Deliveries to this address are currently available for future orders only",
            "ENABLED_REGION_TIME_MESSAGE": "Deliveries will resume between {{t}}",
            "ENABLED_REGION_DAY_MESSAGE": "Deliveries will resume {{d}}, between {{t}}",
            "OUT_OF_REACH": "You’re out of reach",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "One or more of the payments you entered failed. Please call the restaurant to complete your order.\n {{phone}}",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "One or more of the payments you entered failed. Please contact the server for help",
            "SELECTTABLE_INFO": "Please enter the table number you would like to order for",
            "ENTERORDER_INFO": "Please enter the order number you would like to pay for",
            "INVALID_TABLE": "Table does not exist",
            "ORDER_RELOAD_NEEDED": "Please note, payment balance changed!",
            "ORDER_NEWPAYMENT_NEEDED": "Please note that the payment balance has changed. A new amount must be entered",
            "ORDER_HELD_BY_SERVER": "We apologize, but payment cannot be processed right now. Please try again in a few minutes",
            "SESSION_VALIDATION_FAILED": "There was an error processing the request, please refresh the page and try again",
            "CREDIT_CARD_3DS_DISCLAIMER": "You may receive a verification code by SMS text or email. Please enter it if prompted.",
            "PAY_ORDER_CHANGED": "Your order was changed. Please review it",
            "PAYMENT_ACCEPTED_SUCCESSFULLY": "Payment successful",
            "PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT": "Payment in the amount of {{amount}} processed successfully",
            "PAYMENT_INTENT_EXPIRED": "Payment expired",
            "CARD_EXPIRED": "Expired",
            "PAYMENT_DELETED_SUCCESSFULLY": "Payment deleted successfully",
            "PAYMENT_ACCEPTED": "Payment successful",
            "OUT_OF_STOCK": "The following items are no longer in stock:\n {{items}}\n Please update your order",
            "GRATUITY_CASH_DISABLE": "Cash cannot be accepted when there is gratuity",
            "ORDER_ITEMS_UNAVALABLE": "Unfortunately, the following items are currently unavailable for ordering:",
            "ORDER_TIMEOUT": "Your order has timed out, please start again",
            "ORDER_TIMEOUT_TITLE": "Timeout",
            "ORDER_DELIVERY_ERROR_TITLE": "Something Went Wrong",
            "ORDER_DELIVERY_ERROR_MESSAGE": "We lost track of your address and unfortunately need you to restart your order. Sorry for the inconvenience",
            "LOCAL_TIME_HAS_BEEN_CHANGED": "The local time on your device has been changed",
            "ORDER_TIMEOUT_LEVEL1": "Don’t worry, you still have 5 more minutes to complete your order",
            "ORDER_TIMEOUT_LEVEL1_TITLE": "Something has changed",
            "BASKET_ERRORS_TITLE": "Sorry",
            "BASKET_ERRORS": "The following items are no longer available: {{errors}} Please update your order",
            "BASKET_ERRORS_BUTTON": "Update",
            "PROMOTION_DISABLED_TITLE": "Sorry",
            "PROMOTION_DISABLED": "This promotion: {{errors}} is no longer available your basket has been updated",
            "PROMOTION_DISABLED_BUTTON": "Update",
            "CONFIRM_ADDRESS": "The address entered is:\n<b>{{address}}</b>\nContinue?",
            "PROBLEM_IN_PAYMENT_INPUT": "There was an error in receiving payment details\nPlease enter payment details again\nIf the problem continues, please contact the restaurant by phone: \n {{phone}}",
            "MEAL_CALC_FACTOR_DESC": "The discount is being calculated for each item by its relative weight in the order",
            "MEAL_CALC_ACTION_DESC": "The dishes to pay must be marked",
            "MEAL_CALC_TOTAL_DESC": "The total portion cannot be greater than the amount remained to pay",
            "EX_PAYMENT_ERROR": "There was an error regarding your payment provider",
            "EX_PAYMENT_AUTHRIZATION_ERROR": "There was a payment confirmation error",
            "LOCALITY_WO_STREETS": "For {{locality}} there are no street names defined\ndo you want to choose this place as delivery order?",
            "SERVICE_END_WARN": "Attention! Order service will be closed in {{t}} minutes. Please complete your order in time.",
            "JUST_SO_YOU_KNOW": "Just so you know",
            "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "OK",
            "QR_HELPER": "Please scan the QR code",
            "INVALID_QR": "There was an error scanning the QR code.\nIf you wish to pay - please use manual code",
            "SITE_NOT_FOUND": "The desired restaurant",
            "TABIT_PAY_MANUAL_ENTER": "For manual code entry and restaurant selection",
            "TABIT_PAY_MANUAL_ENTER_FOR_SITE": "Manual payment",
            "PLEASE_ENABLE_CAMERA": "Please allow camera access in order to scan hte QR code",
            "PAY_INFO": "To continue the payment process, select a restaurant",
            "ENTER_ORDER_NO_INFO": "Ask your server for the code",
            "AREA_NOT_SELECTED": "No area selected",
            "PLEASE_SELECT_AREA": "Please select an area to continue",
            "PLEASE_ENABLE_LOCATION": "Please turn on location services so we can recommend restaurants based on your location.",
            "DESCRIBE_LOCATION": "Describe your location",
            "USER_LOCATION_ERROR": "Please enable location services for a better experience",
            "PLEASE_ENABLE_NOTIFICATIONS": "Please turn on notifications\nthis way you'll stay up-to-date 🙂",
            "CONTINUE_WITHOUT_GRATUITY": "Continue without a tip?",
            "CALL_RESTAURANT": "Call the restaurant",
            "NO_LOCATION": " Location services unavailable",
            "ORDER_REQUIERES_IDCARD": "The payment amount is too high, please reduce the amount or contact the server",
            "ERROR_CROSS_ORGS_TABLE_SEARCH": "It seems that you don't have internet connection... please try again later",
            "CROSS_ORGS_TABLE_NOT_FOUND": "No available tables at the opened restaurants at the desired time, please try to book at another time",
            "GRATUITY_NOT_ALLOWED_FOR_PM": "Tip cannot be added with {{pm}} at this restaurant.\nPlease select another payment method or cancel the tip.",
            "GRATUITY_PA_WARNING": "This restaurant accepts tips through {{pms}} only",
            "FOR_EXTENDED_RESULTS_PRESS_HERE": "Additional Results",
            "EXTENDED_RESULTS_BUTTON_ADDITIONAL_INFO": "Because this relationship is important to us, we don't hide anything. There are other restaurants here that make deliveries with other companies, if you would like.",
            "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Additional payment with the same device",
            "THE_SERVICE_BEGIN_AT": "The service will open at",
            "SERVICE_PREORDER_DONT_WORRY": "But don't worry, you can place a pre-order which will be ready when the service opens.",
            "SERVICE_DOWN": "Service is down, please try again later",
            "SUMMARY_SENT_BY_MAIL": "Order summary has been sent to your email",
            "ENABLE_GRATUITY_CREDIT_ONLY": "Can only pay with credit when tip added",
            "CC_PAYMENT_OVERFLOW": "Payments on one card cannot exceed NIS {{amount}}. Please contact your server to pay.",
            "HOTEL_NO_GUEST_WITH_PHONE": "No phone number related to room",
            "HOTEL_ROOM_NOT_FOUND": "Room not found",
            "HOTEL_NOT_ELIGIBLE": "Room Charge not authorized",
            "HOTEL_REPORT_TO_DESK": "Please contact reception",
            "HOTEL_NO_GUEST_FOUND": "No guests found",
            "SELECT_MAP_LOCATION": "Select location on map",
        },
        "EX_PAYMENT": {
            "dialog_title": "Payment Verification",
            "wallet_title": "Card Verification",
            "wallet-popup-title": "Card saved successfully",
            "description": "This page is used to verify the payment method used on the site. Actual payment will take place on the business' site once the verification process is complete. Please verify that all of the details on this page are correct and fill out the required credit card details.",
            "popup-title": "Payment Verification Complete",
            "form-title": "Credit Card Details",
            "popup-description": "Complete the transaction on the business' site",
            "submit-btn": "Continue",
            "clear": "Clear",
            "credit_card_number": "Credit Card Number",
            "expiration": "Expiration",
            "cancel": "Cancel",
            "currency": "Currency:",
            "transaction_details": "Transaction Details",
            "transaction_acknowledge_ord": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_gcs": "By clicking on the “Pay” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_rsv": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "total_amount": "Total Amount",
            "month": "Month",
            "year": "Year",
            "click_here": "Click here",
            "save":"Save",
        },
        "expayTitle": "Card details approved",
        "NATIVE_PAYMENT": {
            "error_dialog_title": "We are unable to process your request at the moment.",
            "error_dialog_description": "Please select another payment method",
            "error_dialog_no_amount_description": "To use {{type}} Pay, tap the X next to the payment amount and try again",
        },
        "SERVICE_BUTTON": {
            "home": "Main",
            "order": "Delivery/TO",
            "book": "Reserve",
            "pay": "QR scan",
            "pay_generic": "Pay",
            "profile": "Profile",
            "delivery": "Delivery",
            "takeaway": "Takeout",
            "future_order": "Future Order",
            "eatin": "Eat in",
            "waitingList": "Join waitlist",
        },
        "DASHBOARD": {
            "we_have_missed_you!": "Hi, we missed you!",
            "when_we_would_see_you?": " ",
            "all_the_restaurants_click_away": "Your favorite restaurants - one click away",
            "BUTTONS": {
                "delivery": "Delivery",
                "takeaway": "Takeout",
                "book": "Reserve a table"
            }
        },
        "WEB_HOME": {
            "H1_TITLE": "Reserve a table!",
            "H2_TITLE": "Just say when and which restaurant, and the rest is on us",
        },
        "WEB_BOOK": {
            "H1_TITLE": "Reserve a table!",
            "H2_TITLE": "Search for a table at Tabit restaurants",
        },
        "ALLERGIES": {
            "gluten": "Gluten",
            "eggs": "Eggs",
            "peanuts": "Peanuts",
            "nuts": "Nuts",
            "sesame": "Sesame",
            "lactose": "Lactose",
            "spicy": "Spicy",
            "fish": "Fish"
        },
        "_LOYALTY": {
            "id_number_already_exist": "The ID number already exist in the system, please use another ID number.",
            "registration_error_title": "Connect to Club",
            "order_types": "Only available for {{types}}",
            "cancel_benefits": "Cancel promo code",
            "card_balance": "Card balance",
            "money_compensation": "Voucher",
            "connect": "Apply",
            "connect_to_tabit": "Log in to Tabit",
            "connect_info": "Phone or card number",
            "expires": "Expires on",
            "greeting": "Hello {{name}}!",
            "greeting_voucher": "Hello!",
            "hey": "Hi!",
            "member_not_found_title": "We couldn’t find you",
            "general_error_title": "Just a moment",
            "general_error": "An error occurred while calculating your benefits. Please try again or contact the restaurant directly",
            "renew_is_forbidden_title": "Your Membership Has Expired",
            "renew_is_forbidden_text": "To enjoy the Loyalty program rewards, you must renew your membership. For more details please contact the restaurant.",
            "comps_extra_text": "The comps have been added to the order and can be redeemed.",
            "good_to_see_you_title": "Good to see you",
            "member_not_found": "This number isn't connected to a membership",
            "member_not_found_PRIMARY_BUTTON_TEXT": "OK",
            "customer_mustComplete_the_registration": "In order to add the club member to the order, please fill out the registration form sent by SMS or in our app.",
            "of": "Of ",
            "points_balance": "Points balance",
            "punch_card_offer": "From {{from}} to {{to}}",
            "review_benefits": "Total benefits",
            "review_points": "Redeem points",
            "use_points": "Points",
            "signout": "Sign out",
            "skip": "Skip",
            "no-benefits": "Benefits weren't redeemed",
            "title": "Club members {{name}}?",
            "title_voucher": "Hi, do you have a voucher?",
            "title_club": "Hi, do you have a promo code or loyalty membership?",
            "voucher": "Voucher",
            "max_redeemable_points": "Maximum points to redeem",
            "my_benefits": "My promotions",
            "benefits_use": "Used promotions",
            "redeem_points_prompt_1": "Your points are worth money!",
            "redeem_points_prompt_2": "Max. points to redeem: {{points}}",
            "redeem_points_after": "{{points}} saved by redeeming points",

            "voucher_gtreeting": "Hi, do you have a promo code?",
            "voucher_inline_gtreeting": "Enter promo code",
            "voucher_benefits_title": "My promotions",

            "club_gtreeting": "Let’s find any codes or memberships you may have",
            "club_inline_gtreeting": "Enter promo code or loyalty membership",
            "club_inline_gtreeting_voucher_only": "Enter promo code",
            "club_benefits_title": "My promotions",
            "select_club": "Select a club",

            "benefits_title": "My benefits",
            "no_benefits_title": "No available benefits",
            "register": "Join Our Loyalty Program",
            "to_promotion_disclaimer": "Discount will be applied in the next step",
            "benefit_types": "Available for {{val}} only",
            "auth_title": "Confirmation code required",
            "auth_sub_title": "A confirmation code has been sent to your phone",
            "auth_code": "Enter code",
            "auth_resend": "Resend code",
            "for_your_disposal": "At your disposal",
            "gift-card": "Got a gift card?",
            "add-gift-card": "Add another card",

            "registration_terms": "By registering you agree to receive SMS texts about deals and offers.\nYou can cancel your registration at any time.",
            "regCostMessage": "Club Membership costs {{currency}}{{price}}. This will be added to your cart",
            "confirmDeleteOfferJoin": "There’s a fee to become a Loyalty Member.",
            "confirmDeleteOfferRenewal": "Cancel the membership renewal?",
            "confirmDeleteOfferJoinDescription": "Removing it will prevent the receipt of benefits, would you like to remove it?",
            "confirmDeleteOfferRenewalDescription": "This will prevent you from receiving Loyalty benefits",
            "removeOfferJoinButton": "Yes, remove",
            "removeOfferRenewalButton": "Cancel renewal",
            "keepOfferJoinButton": "No, I want to join",
            "keepOfferRenewalButton": "Renew my membership",
            "confirmExpiredSoonDeleteOffer": "Cancel membership renewal?",
            "removeExpiredSoonOfferButton": "Cancel renewal",
            "keepExpiredSoonOfferButton": "Renew my membership",
            "regTitle": "Club Members have more fun!",
            "regDetailsDescription": "We couldn’t find your number {{phone}} in our system. Now’s the time to join!",
            "regCancel": "Cancel",
            "continueReg": "Join",
            "regRenewMessage": "Club Renewal costs {{currency}}{{price}}. This will be added to your cart",
            "regRenewPromptPrice": "Membership renewal costs {{currency}}{{price}}",
            "regRenewTitle": "Time flies!",
            "regRenewBody": "It’s time to renew your club membership",
            "regSoonExpiredRenewBody": "Your membership expires on {{date}}.\You can pay membership renewal fee to continue and enjoy the rewards included in this loyalty program in the future.",
            "regSoonExpiredSignOut": "Your membership expires on {{date}}.",
            "regPromptTitle": "We couldn’t find your number {{phone}} in our system.\nNow’s the time to join!",
            "regPromptPrice": "It costs {{currency}}{{price}} to become a Club Member",
            "regUpdateNessage": "Please verify the details",
            "registerButton": "Join",
            "continueWithoutRenewing": "Continue without Renewal",
            "continueWithoutReg": "Continue without Joining",
            "renewMembership": "Membership Renewal",
            "phoneNumber": "Phone Number",
            "textForConfirmSms": "By registering you agree to receive SMS texts about deals and offers. You can cancel your registration at any time",
            "textForConfirmSmsScreenReader": "By registering you agree to receive SMS texts about deals and offers. You can cancel your registration at any time",
            "textForConfirmClubRules": "I agree to the Club Terms and Conditions",
            "termsContinue": "Continue",
            "termsFirstContinue": "Continue",
            "termsBody": "It seems that you have not yet completed the registration for the club \n A few last details and we are good to go :)",
            "termsTitle": "Hi {{name}}",
            "first_name": "First name",
            "last_name": "Last name",
            "email": "Email address",
            "zehut": "ID number",
            "phone": "Phone number",
            "anniversary": "Anniversary",
            "birth_date": "Date of birth",
            "regErrorTitle": "Something went wrong",
            "email_not_unique": "This email is already in use, please enter a different email address",
            "no_registration_items": "No renewal or joining items found in the published menu.",
            "regError": "Unable to complete the join process, please contact the restaurant.",
            "continueErrorTitle": " Hey ",
            "continueErrorText": "Please add items to the cart, to enjoy the club's benefits",
            "understood": "Okey",
            "minimum_order_required_title": "Just a moment",
            "minimum_order_required_body": "After adding your rewards, your order total comes to {{total}}{{currency}} and doesn't meet the minimum {{amount}}{{currency}} required",
            "minimum_order_required_button": "Back to Menu",
            "get_benefits_401_error": "Your session has expired. Please log in again to the Loyalty club to continue using the system."
        },
        "validation_errors": {
            "required": "Required Field",
            "max_length": "Character limit is 256",
            "invalid_email": "Invalid email",
            "invalid_phone": "Invalid phone",
            "load_range": "Enter an amount within the range",
            "no_decimal": "Please enter a valid amount",
            "only_5_digits": "Enter 5 digits only",
            "min_2_digits": "Must contain at least 2 characters",
            "only_numbers": "Enter numbers only",
            "only_letters": "Enter letters only",
            "invalid_zip_code": "Invalid zip code",
        },
        "_GIFTCARD": {
            "title": "Have a prepaid card or gift card?",
            "gift_card": "Gift Card",
            "select_card": "Select a Card",
            "please_select_card": "Please select a card",
            "edit_card": "Edit gift card",
            "add_card": "Pay with Gift Card",
            "number": "Card number",
            "amount": "Amount to pay",
            "balance": "Card balance",
            "max": "Max amount",
            "cvv": "CVV",
            "cvv_required": "CVV required",
            "cvv_alt": "CVV required",
            "invalid_cvv": "Invalid CVV number",
            "inuse_error": "The same card cannot be used more than once in the same order",
            "balance_error": "Card has no balance",
            "tip_not_allowed": "This card cannot be used to pay tip",
            "notfound_error": "Card not found!",
            "delete_prompt": "Are you sure?",
            "no_order_amount": "Order is fully covered, no need to add another payment",
            "reset_split_payments_prompt": "Adding or updating a gift card will reset split payments. Continue?",
            "please_finish_payment": "Press 'Pay' to finish the order",
            "wallet-item-needed": "One more thing",
            "add-wallet-item": "Before we continue, please add a credit card",
            "move-to-my-wallet": "To my wallet",
            "otp_required": "Confirmation code required",
            "otp_required_alt": "Confirmation code was sent to",
            "otp_required_alt_num": "***-****{{v}}",
            "otp_alt": "Confirmation code",
            "invalid_otp": "Invalid Confirmation code",
            "resend_otp": "Resend confirmation code",
            "cannotPayOnOnline": "This type of Card is not available to be used online, but you may be able to use it at the restaurant",
        },
        "order_tracker": {
            "hey": "Hey",
            "ETA": "Estimated",
            "FEES": "Fees",
            "CASH_DISCOUNT": "Cash Discount",
            "ETA_takeaway": "The order will be ready at",
            "ETA_delivery": "The delivery will arrive by",
            "track_order": "Track Order",
            "my_order": "My order",
            "order_status": "Actual status",
            "actual_status": "Order status",
            "status": "Status",
            "order": "Order",
            "order_a": "Order",
            "order_is_being_loaded": "Wait for it...",
            "order_accepted": "We're reviewing your order",
            "order_being_prepared": "Our chef is working on it",
            "order_in_progress": "Working on it",
            "takeaway": "Takeout",
            "delivery": "Delivery",
            "arrived": "Arrived",
            "track": "Track",
            "send": "Send",
            "ok": "OK",
            "order_ready": "Your order is ready!",
            "order_ready_for_delivery": "Ready for Delivery",
            "order_taken": "Order Taken",
            "order_taken_on_its_way": "On Its Way!",
            "order_delivery_delivered": "Delivered",
            "add_review": "Add Review",
            "your_order_not_found": "Could not find your order",
            "hey_im_here": "Hey, I am here!",
            "im_here_explanation": "We would be glad to know when you're here -",
            "how_can_we_find_you": "How can we find you?",
            "car_information": "Car information",
            "instructions_accepted": "Thanks for the update :)",
            "update_notes": "Update notes",
            "order_not_here": "Your order is not here anymore...",
            "dont_cry_over_spilled_juice": "But don't cry over spilled juice",
            "can_we_offer_you_something_else": "Can we offer you something else?",
            "im_here_confirmation_title": "Arrival confirmation",
            "im_here_confirmation_message": "Should we inform the restaurant you arrived?",
            "im_here_confirmation_hurry_message": "That was fast, here already?",
            "im_here_confirm": "Yes",
            "im_here_cancel": "Not yet",
            "disclaimer_with_phone": "For any request regarding your order, please contact {{ name }} directly by clicking on the 📞",
            "disclaimer_without_phone": "For any request regarding your order, please contact {{ name }} directly"
        },
        "to_disclaimer": "For any questions regarding your order, please contact {{ name }} directly",
        "by_phone": "by phone: ",
        "user_reviews": "User review",
        "user_review_hey": "Hey",
        "user_review_end": "Exit",
        "user_review_thanks": "Thanks",
        "user_review_thanks_for_sharing": "Thanks for sharing ",
        "user_review_waiting_to_hear_from_you": "Waiting to hear from you",
        "user_review_what_did_you_like": "What did you like?",
        "user_review_comment": "We would like to hear what you have to say",
        "user_review_positive_thank_you_description": "Looking forward to your next visit!",
        "user_review_negative_thank_you_description": "Hope you enjoy it next time!",
        "user_review_add_comment": "Tell us more",
        "user_review_food": "Food",
        "user_review_service": "Service",
        "user_review_package": "Packing",
        "user_review_times": "Times",
        "user_review_order_process": "Order process",
        "user_review_atmosphere": "Atmosphere",
        "user_review_perfect": "Perfect",
        "user_review_ok": "Very Good",
        "user_review_plausible": "Fine",
        "user_review_expected_more": "So So",
        "user_review_bad": "Could be better",
        "user_review_add_a_review": "Add a review",
        "user_review_how_was_it": "How was it?",
        "user_review_sorry_to_hear": "Sorry to hear...",
        "user_review_what_to_improve": "We would like to know how to improve",
        "in_progress_message": "Your are in the middle...",
        "deletion_will_remove_all_payments": "This will delete all payments",
        "sorry": "Sorry",
        "were_sorry": "We're sorry",

        "notifications": {
            "got_all_notifications": "It seems you are up-to date",
            "promise_to_inform": "The bell will turn yellow when an update arrives",
            "unsupported_browser_title": "Keep It Light",
            "unsupported_browser_confirm": "I understand",
            "unsupported_browser": "For best viewing experience, set display settings to light mode, or avoid using Samsung's browser."
        },

        "USER_POINTS": {
            "btw_you_have": "By the way, you already have",
            "btw_you_dont_have": "By the way, you don't have points",
            "for_each_order_more_bonus": "For each ordered table you would get 10 more",
            "for_each_order_bonus": "For each ordered table you would get 10"
        },
        "KFC": {
            "title": "KFC {{country}}",
            "main_sub_title": "The world's favorite chicken",
            "sec_sub_title": "Prepare your fingers!"
        },
        "SHIOU": {
            "title": "Shiuou! You have a touch for sushi",
            "sub_title": "Shortest way from imagination to taste!"
        },
        "GRECO": {
            "title": "GRECO",
            "sub_title": "Real Greek party"
        },
        "GIFT_CARD_SHOP": {
            "tracker": {
                "to_from": "Basic details",
                "when_where": "Sending methods",
                "checkout": "Checkout",
            },
            "card_recipient": {
                "title": "Send Gift Card to...?"
            },
            "user_wallet": {
                "title": "Choose a payment method",
                "other": "Another payment method",
            },
            "choose_amount": {
                "current_balance": "Current balance",
                "you_pay": "Pay",
                "you_get": "Get",
            },
            "create_card": {
                "title": "Card Details",
                "to": "To",
                "from": "From",
                "first_name": "First Name",
                "last_name": "Last Name",
                "send_to_friend": "Send Gift Card to a friend",
                "where_to_send_the_gift": "Where should we send the gift card?",
                "your_message": "Message (Optional)",
                "your_message_placeholder": "Type a few words",
                "enter_amount": "Gift Card Amount ({{min_amount}} - {{max_amount}})",
                "enter_amount_error": "Please enter an amount within the range",
                "send_to_phone": "Send Gift Card to",
                "toPhone": "Phone",
                "toEmail": "Email",
                "required_load_sum": "How much do you want to charge?",
                "billing_address": "Billing Address",
                "state": "State",
                "select_state": "State",
                "city": "City",
                "address": "Address",
                "zip_code": "Zip Code",
                "legalText": "This voucher is valid for 5 years from date of purchase, as required by Consumer Protection Law 14(h)", // Only for IL/US
                "legalTextProcess": "This voucher is valid for 5 years from date of purchase, as required by Consumer Protection Law 14(h)", // Only for IL/US
                "expirationDate": "This card expires 3 years after supply",// Only for AU
                "bonus_title": "Enjoy a {{percent}}% bonus!"
            },
            "delivery_options": {
                "best_send_to": "Sending methods",
                "toPhone": "Phone",
                "toEmail": "Email",
                "phone": "Phone number",
                "email": "Email address"
            },
            "checkout": {
                "title": "Summary",
                "gift_card_total": "Total",
                "gift_card": "Gift Card",
                "send_to": "Send to",
                "to": "Recipient",
                "from": "From",
                "message": "Message",
                "send_receipt_to": "Where should we send your receipt?",
                "send_all_receipts_to": "Send gift card and receipt to",
                "myPhone": "My Phone",
                "myEmail": "My Email",
                "receiptContactPhone": "Phone",
                "receiptContactEmail": "Email",
                "Email": "Email",
                "total": "Total:",
                "approve_notifications": "Notify me When the card is viewd by the recipient.",
                "approve_terms": "I have read and approved the",
                "approve_terms_and": "and",
                "approve_terms_link_1": "Terms of Service",
                "approve_terms_link_2": "Privacy Notice",
            },
            "buttons_texts": {
                "pay": "Pay {{currency}}{{amount}}",
                "next": "Next",
                "go_back": "Go Back",
                "you_get": "You get {{currency}}{{amount}}",
                "website": "Website",
                "recipient_type_me": "Myself",
                "recipient_type_not_me": "Friend"
            },
            "validation_errors": {
                "required": "Required Field",
                "max_length": "Character limit is 256",
                "invalid_email": "Invalid email",
                "invalid_phone": "Invalid phone",
                "load_range": "Enter an amount within the range",
                "no_decimal": "Please enter a valid amount",
                "only_5_digits": "Enter 5 digits only",
                "min_2_digits": "must contain at least 2 characters",
                "only_numbers": "Enter numbers only",
                "only_letters": "Enter letters only",
                "invalid_zip_code": "Invalid zip code",
            },
            "modals": {
                "please_wait": "Please wait...",
                "success": {
                    "title": "Thank you!",
                    "body": "Your gift card is on its way",
                    "primary_button": "Close",
                    "secondary_button": "I want another card",
                    "top_up": {
                        "body": "Top up completed",
                        "secondary_button": "Top up again",
                    }
                },
                "error": {
                    "title": "Something's Wrong",
                    "body": "Unable to process payment",
                    "body_general_error": "An error occurred",
                    "body_error_occurred": "An error occurred\nPlease contact the restaurant {{phone}}",
                    "body_validation_mismatch": "An error occured\nCard declined, address validation mismatch",
                    "body_card_declined": "An error occured\nCard declined",
                    "shop_inactive_error": "Oops! Looks like this service is down at the moment",
                    "card_inactive_error": "Error in creating card, please contact the restaurant",
                    "primary_button": "Close",
                    "secondary_button": "Try again",
                },
                "pay": {
                    "pay_with_cibus": "Pay with Cibus",
                    "card_number": "Card Number"
                }
            },
            "redeem_card": {
                "title": "Your gift card balance:",
                "show_qr": "Show QR code at the restaurant",
                "code": "Code",
                "cvv": "CVV",
                "recipient_title": "Hey ",
                "recipient_text": "You received {{amount}}",
                "recipient_from_text": "from {{sender}}",
            },
            "general": {
                "gift_card_purchase": "Gift card for a friend"
            }
        },
        "BIGA": {
            "title": "BIGA",
            "sub_title": "Bakery & kitchen"
        },
        "FOODBA": {
            "title": "Foodba",
            "sub_title": "Be'er Sheba's delivery application",
            "seo_title": "Be'er Sheba | Foodba",
        },
        "FOOTER": {
            "area_link_prefix": " ",
            "other_links": "Featured areas",
            "contact": "Contact",
            "join_us": "Join Us",
            "to_tabit_site": "To Tabit Site",
            "contact_us": "Contact Us",
            "privacy_policy": "Privacy Policy",
            "club_terms": "Loyalty Terms",
            "terms_of_use": "Terms Of Use",
            "terms_of_service": "Terms of Service",
            "career": "Career",
        },
        "loyalty-terms-dialog": {
            "title": "We have a new customer club",
            "descriptionMain": "We have amazing benefits for you!",
            "descriptionSecond": "In order to continue, we need a few more things",
            "validation": "Do you want to give up the benefit of joining?",
            "added_to_your_cart": "This reward has been applied to the item(s) in your cart. Enjoy!",
            "ok": "OK",
            "remove_reward": "Remove reward?",
            "remove_reward_btn_yes": "Yes, Remove",
            "remove_reward_btn_no": "No",
        },
        "loyalty-rewards-dialog": {
            'auto-rewards-title': 'We\'ve added these rewards to your order:',
            'my-rewards': 'Your Rewards',
            'all-my-rewards': 'Select rewards',
            'all-my-rewards-single': 'Claim this reward',
            'club-rewards-will-be-calculated-before-payment': 'Ready to redeem? Select the rewards you would like to add to your order below',
            'back-to-order': 'Back to Order',
            'done-thanks': 'Done, Thanks',
            "select_between_range": "{{quantity}} out of {{max}} rewards selected",
            "loyalty_reward_terms_dialog_title": "You got it!",
            "loyalty_reward_terms_dialog": "Your reward is active and will be applied at checkout.",
            "loyalty_reward_terms_dialog_ok": "Go to menu",
            "loyalty_reward_terms_dialog_cancel": "Cancel",
            "not-available": "Reward is not available at the moment",
        },
        "pickup-order-dialog": {
            "Branch_pickup_order": "Branch pickup order",
            "address": "address",
        },
        "SIGN-UP-DATE-ERROR": "Date format is incorrect",
        "general_credit_card": {
            "form": {
                "card_number": "CARD NUMBER:",
                "expiration": "CARD EXPIRATION:",
                "cvv": "CARD CVV:",
                "amount_to_pay": "PAY"
            },
            "errors": {
                "invalid_exp": "Please fill in all required fields",
                "invalid_cvv": "Invalid CVV",
            }
        },
        "REMOVE_ACCOUNT": {
            "disclaimer_1": "The completion of this process requires a two-step authentication process through a verification code that will be sent by SMS text.",
            "disclaimer_2": "The account will be removed immediately.",
            "disclaimer_3": "Benefits and comps associated with your phone number will be saved even after the account is removed from the app and you will be able to use them on other channels.",
        },
        "tou_offer": {
            "required": "Required",
            "additional_options": "Additional options",
            "group_guiding_text": {
                "before_selection": {
                    "choose_at_least": "Select at least {{min}} items",
                    "choose_at_least_singular": "Select at least one item",
                    "select_at_least": "Select at least {{min}} more items",
                    "select_at_least_singular": "Select at least one more item",
                    "select_at_least_no_max": "Order must contain at least {{min}} more items",
                    "select_at_least_no_max_singular": "Order must contain at least one more item",
                    "select_between_range": "Select between {{min}} and {{max}} items",
                    "select_between_range_with_defaults": "Order must contain at least {{min}} more items",
                    "select_between_range_with_defaults_singular": "Order must contain at least one more item",
                    "select_minimum_items": "Order must contain at least {{min}} more items",
                    "select_minimum_items_singular": "Order must contain at least one more item",
                    "select_quantity": "Select {{min}}",
                    "select_quantity_singular": "Select one",
                    "select_quantity_singular_without_default": "Select one",
                    "select_up_to": "Select up to {{max}} items",
                    "select_up_to_singular": "Select up to one item",
                    "select_up_to_without_defaults": "Select up to {{max}} items",
                },
                "during_selection": {
                    "choose_at_least": "Select at least {{min}} items",
                    "choose_at_least_singular": "Select at least one more item",
                    "select_at_least": "Select at least {{min}} more items",
                    "select_at_least_singular": "Select at least one more item",
                    "select_at_least_no_max": "Order must contain at least {{min}} more items",
                    "select_at_least_no_max_singular": "Order must contain at least one more item",
                    "select_between_range": "{{quantity}} out of {{max}} selected",
                    "select_between_range_with_defaults": "{{quantity}} out of {{max}} selected",
                    "select_between_range_with_defaults_singular": "{{quantity}} out of {{max}} selected",
                    "select_minimum_items": "Order must contain at least {{min}} more items",
                    "select_minimum_items_singular": "Order must contain at least one more item",
                    "select_quantity": "{{quantity}} selected",
                    "select_quantity_singular": "One selected",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "{{quantity}} out of {{max}} selected",
                    "select_up_to_singular": "One item selected",
                    "select_up_to_without_defaults": "Select up to {{max}} items",
                },
                "after_selection": {
                    "choose_at_least": "{{quantity}} items selected",
                    "choose_at_least_singular": "One item selected",
                    "select_at_least": "",
                    "select_at_least_singular": "",
                    "select_at_least_no_max": "",
                    "select_at_least_no_max_singular": "",
                    "select_between_range": "Max of {{max}} reached",
                    "select_between_range_with_defaults": "{{quantity}} selected",
                    "select_between_range_with_defaults_singular": "One selected",
                    "select_minimum_items": "",
                    "select_minimum_items_singular": "",
                    "select_quantity": "{{min}} selected",
                    "select_quantity_singular": "One selected",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "Max of {{max}} reached",
                    "select_up_to_singular": "Max of one reached",
                    "select_up_to_without_defaults": "{{quantity}} selected",
                }
            },
        },
        "new_restaurant_page": "Restaurant page",
    },
    'ar-IL': {
        "number_of_restaurants_found_screen_reader": "ּعدد المطاعم التي تم العثور عليها,",
        "number_of_addresses_found_screen_reader": "عدد العناوين التي تم العثور عليها،",
        "number_of_dishes_found_screen_reader": "عدد الجرعات التي تم العثور عليها",
        "localeCaption": "EN",
        "share_this": "مرحبًا، إليكم شيء وجدته على تابيت. تريدون المحاولة؟",
        "hours": "ساعات",
        "days": "أيام",
        "tabit": "تابيت",
        "minutes": "الدقائق",
        "footer_data": "حجز مكان | طلب طاولة | تابيت | Tabit",
        "terms_of_service": "شروط الاستخدام",
        "privacy_notice": "سياسة الخصوصية",
        "under_construction_message": "هذه الصفحة جاري تحضيرها",
        "summary": "ملخص",
        "service_selection": "تحديد نوع الخدمة",
        "future_service_desc": "ينبغي تحديد الخدمة المستقبلية المرجوة",
        "choose_navigation_option": "تحديد خيار التصفح",
        "choose_option": "تحديد الخيار",
        "choose_hotel": "في أي فندق تمكثون؟",
        "_and": "و",
        "KEYPAD_DIALOG_WARNING": "يمكن إدخال ما يصل إلى {{maxVal}} {{currency}}",
        "rsv_management": {
            "guests": "ضيوف",
            "disclaimer": "في كل طلب أو استفسار يتعلق بطلبيتك يرجى الاتصال مباشرة بـ",
            "description": {
                "future_reservation_created": "مرحبًا {{name}},\nتم حفظ طلبيتكم لدينا بنجاح.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
                "future_reservation_standby_created": "مرحبًا {{name}},\nنلفت انتباهكم إلى إضافتكم إلى قائمة انتظار. سنتصل بكم في حالة توفر مكان.",
                "future_reservation_standby_created_pending_approval": "مرحبًا {{name}},\nنلفت انتباهكم إلى عدم تأكيد طلبيتكم بعدُ. سنتصل بكم في أسرع وقت ممكن لتأكيد طلبيتكم.",
                "future_reservation_reminded": "مرحبًا {{name}},\nيرجى تأكيد طلبيتكم.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
                "future_reservation_reminded_free_text": "يرجى تأكيد طلبيتكم.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
                "future_reservation_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!",
                "future_reservation_checked_in": "تم استيعاب وصولكم. سنبلغكم عندما تكون الطاولة جاهزة.",
                "future_reservation_deleted": "تم إلغاء طلبيتكم.",
                "future_reservation_verified": "لقد أكدتم وصولكم. شكرًا!",
                "future_reservation_will_be_late": "شكرًا على إبلاغنا بوصولكم متأخرين",
                "future_reservation_undo_late": "شكرًا على إبلاغنا",
                "future_reservation_cc_deposit": "مرحبًا {{name}},\nتم حفظ طلبيتكم لدينا بنجاح.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من تاريخ الطلبية.\nسيسرنا لقاؤكم!\nنلفت انتباهكم إلى إلزامكم بدفع مبلغ الإيداع في حال ألغيتم أقل من {{time_limit_cancel}} من وقت الطلب.",
                "future_reservation_cc_deposit_reminded": "مرحبًا {{name}},\nيرجى تأكيد طلبيتكم.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من تاريخ الطلبية.\nسيسرنا لقاؤكم!\nنلفت انتباهكم إلى إلزامكم بدفع مبلغ الإيداع في حال ألغيتم أقل من {{time_limit_cancel}} من وقت الطلب.",
                "future_reservation_cc_deposit_notified": "مرحبًا {{name}},\nطاولتك جاهزة. مرحبًا بك للحضور!\nيرجى ملاحظة أنه سيتم تحصيل مبلغ الإيداع منك إذا قمت بالإلغاء أقل من {{time_limit_cancel}} من وقت الحجز.",
                "future_reservation_cc_deposit_checked_in": "مرحبًا {{name}}،\nتم تسجيل وصولك. سنخبرك عندما تصبح الطاولة جاهزة.\nيُرجى ملاحظة أنه سيتم تحصيل مبلغ الوديعة منك إذا قمت بالإلغاء أقل من {{time_limit_cancel }} من وقت الحجز.",
                "walked_in_created": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
                "walked_in_saved": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
                "walked_in_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!",
                "walked_in_checked_in": "تم استيعاب وصولكم. سنبلغكم عندما تكون الطاولة جاهزة.",
                "called_in_created": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
                "called_in_saved": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
                "called_in_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!"
            },
            "buttons": {
                "confirm_label": "تأكيد الوصول",
                "cancel_label": "إلغاء الطلبية",
                "will_be_late_label": "سنكون متأخرين",
                "undo_late_label": "سنصل في الوقت المحدد",
                "fill_health_statement": "تعبئة تصريح صحة",
                "modify_label": "تحديث الطلب"
            },
            "cancel_reservation_dialog": {
                "title": "إلغاء الطلبية",
                "body": "هل أنتم متأكدون من أنكم تريدون إلغاء الطلبية؟",
                "confirm": "إلغاء الطلبية",
                "cancel": "العودة"
            },
            "failure_reason": {
                "reservation_not_found": "لم يتم العثور على طلبية",
                "reservation_in_progress": "الضيوف يجلسون إلى الطاولة فعلاً. استمتعوا!",
                "organization_not_found": "لم يتم العثور على منظمة",
                "feedback_module_disabled": "للأسف نظام التعليقات معطل بالنسبة لهذا المطعم"
            },
            "place_in_queue": "موقعكم في الدور",
            "preferences": "التفضيلات",
            "preference_exception": "חוץ מ{{preference}}",
            "social": {
                "share": {
                    "whatsapp": {
                        "button_label": "أرسل إلى صديق/ة",
                        "app_message": "مرحبًا، قد حجزت لنا مكانًا في",
                        "web_message": "مرحبًا,\nلقد حجزت لنا مكانًا في {{organization}}\n{{link}}"
                    },
                    "share": "شارك"
                }
            },
            "notifications": {
                "email": {
                    "unsubscribe": {
                        "success": "تمت إزالتكم بنجاح من تلقي تحديثات عبر البريد الإلكتروني لهذه الطلبية",
                        "error": "وقع خطأ غير متوقع عند الإزالة من التحديثات في البريد الإلكتروني. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بالمطعم."
                    }
                }
            },
            "call_customer": {
                "request": "لدي تحديث، عاودوا الاتصال بي",
                "request_sent": "سنعاود الاتصال بكم في أسرع وقت ممكن",
                "request_cancel": "(اضغطوا لإلغاء الطلب)"
            },
            "deposit_payment": {
                "qr_code_button": "إبراز رمز QR",
                "qr_code_description": "ينبغي إبراز رمز الـ QR بمجرد وصولك إلى المطعم",
                "cancel_reservation_dialog": {
                    "body": "الإلغاء خلال فترة زمنية أقل من {{time_limit_cancel}} من تاريخ الطلبية يترتب عنه تحمل كلفة السلفة. يرجى الاتصال بالمطعم في أي مسألة تتعلق بهذا. \n هل تريد إلغاء الطلبية؟"
                },
                "refund_failed_dialog": {
                    "title": "لا يمكن إلغاء الطلبية",
                    "body": "وقع خطأ خلال عملية إلغاء الطلبية - يرجى الاتصال بالمطعم"
                },
                "description": {
                    "cancel_after_time_limit": " تم إلغاء طلبيتك مع تحميل رسوم السلفة. يجب توجيه أي طلب في هذا الموضوع إلى المطعم.",
                    "cancel_before_time_limit": "تم إلغاء طلبيتك وعدم تحميل رسوم السلفة",
                    "no_voucher_balance": "تم إلغاء الطلبية.\nنلفت انتباهك إلى تطبيق السلفة بالفعل وبالتالي عدم إلغاء رسومها. لمزيد من التفاصيل، يرجى الاتصال بالمطعم."
                }
            },
            "cc_deposit": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "الإلغاء بنطاق يقل عن {{time_limit_cancel}} من موعد الطلبية سيتم تحميله كلفة الإيداع.\بشأن كل سؤال في هذا الموضوع يرجى الاتصال بالمطعم.\nهل تريدون إلغاء الطلبية؟"
                    },
                    "prevent": {
                        "title": "لا يمكن إلغاء الطلبية",
                        "body": "تبقى أقل من {{time_limit_cancel}} حتى تاريخ الطلبية.\nلتنفيذ الإلغاء - اتصلوا بالمطعم",
                        "primary_button": "اتصلوا بالمطعم",
                        "secondary_button": "العودة"
                    }
                },
                "credit_details_dialog": {
                    "form_title": "To verify your card, we will pre-authorize a 1 NIS charge, which will be released by the end of the day",
                    "submit_button": "Continue"
                }
            },
            "hour": "ساعة واحدة",
            "two_hours": "ساعتين"
        },
        "splash_screen": {
            "failure_dialog": {
                "title": "وقع خلل ما",
                "content": "النظام واجه مشكلة غير متوقعة",
                "ok_button": "كرر المحاولة"
            }
        },
        "booking": {
            "site_name": "اسم الموقع",
            "site_address": "عنوان الموقع",
            "disclaimer_description": "من خلال الضغط على \"حفظ\" أوافق على",
            "disclaimer_description_deposit_payment": "من خلال الضغط على \"الموافقة والدفع\" أوافق على ",
            "disclaimer_description_connection": " و",
            "terms_of_service": "شروط الاستخدام",
            "privacy_notice": "سياسة الخصوصية",
            "button_label": {
                "walked_in": "نصل قريبًا",
                "future_reservation": "حجز مكان"
            },
            "search": {
                "person": "ضيف واحد",
                "people": "{{value}} ضيوف",
                "larger_party": "{{value}}+ ضيوف",
                "minutes": "{{value}} دقائق",
                "no_results": "لم نعثر على ما بحثتم عنه تمامًا,\nيرجى تحديد موعد آخر أو الاتصال بالمطعم",
                "no_alternatives": "لم نعثر على ما بحثتم عنه تمامًا,\nيرجى تحديد موعد آخر أو الاتصال بالمطعم",
                "no_waiting_list": "لا يوجد انتظار، أنتم مدعوون للوصول",
                "waiting_list_full": "عذرًا، قائمة الانتظار لدينا ممتلئة",
                "results_info": "لم نعثر على ما بحثتم عنه - لكن فيما يلي عدة خيارات أخرى",
                "call_restaurant_large_party": "مرحبًا! يبدو أنكم مجموعة كبيرة للغاية - يرجى الاتصال بنا على الهاتف وسنكون سعداء لحجز مكان لكم.",
                "call_restaurant_error": "للأسف المطعم ممتلئ - يرجى الاتصال بنا",
                "booking_window_error": "عذرًا، لا يمكن التسجيل في الدور حاليًا",
                "today": "اليوم",
                "first_available": "بدون تفضيل",
                "choose_preference": "حددوا التفضيل *",
                "inside": "في الداخل",
                "outside": "في الخارج",
                "vip": "VIP",
                "bar": "بار",
                "gallery": "غاليريا",
                "balcony": "شرفة",
                "smoking": "مدخنون",
                "private_room": "غرفة خاصة",
                "patio": "الفناء",
                "tv_view": "التوجه للشاشة",
                "stage": "المنصة",
                "with_stroller": "مع عربة",
                "with_power": "مع الكهرباء",
                "standby_reservation_description": "مع ذلك أريد التاريخ الذي طلبته، يرجى إضافتي إلى قائمة الانتظار",
                "alternative_organizations_description": "دائمًا يمكن البحث في مطاعمنا الأخرى أيضًا",
                "meta_description": "لحجز مكان لدى {{name}} حددوا التاريخ، والوقت وعدد الضيوف,.",
                "back_to_reservation": "العودة إلى الحجز",
                "field_label": {
                    "seats_count": "عدد الضيوف",
                    "date": "التاريخ",
                    "time": "الوقت",
                    "preference": "المنطقة",
                    "arriving_within": "الوصول خلال"
                },
                "button_label": {
                    "search": "حجز مكان",
                    "search_walked_in": "الإضافة إلى الدور",
                    "search_modify": "متابعة",
                    "standby_reservation": "قوموا بإضافتي إلى قائمة الانتظار",
                    "phone": "اتصلوا بنا"
                },
                "today_blocked": "انتبهوا إلى تعذر حجز أماكن لهذا اليوم",
                "reservation_in_edit_current_title": "تفاصيل الحجز الحالية",
                "reservation_in_edit_modified_details": "ما الذي تريد تحديثه؟"
            },
            "details": {
                "description": "لاستكمال العملية يرجى تعبئة التفاصيل التالية.",
                "description_pending_approval": "هذا الطلب مشروط بموافقة المطعم. لاستكمال العملية يرجى تعبئة التفاصيل التالية.",
                "description_standby": "للإضافة إلى قائمة الانتظار، يرجى ملء التفاصيل التالية.",
                "description_modify_warning": "انتبه! بعد التحديث، لن يتم حفظ الطلب الأصلي.\nسوف ينتقل الطلب المحدث إلى قائمة الانتظار حتى يتم تأكيده من قبل المطعم.",
                "description_modify_cc_deposit_warning": "انتبه! سيؤدي تحديث الطلب إلى إزالة إيداع الائتمان المقدم للطلب الأصلي.\nفي الشاشة التالية، سيُطلب منك إدخال إيداع ائتماني جديد.",
                "future_reservation_info": "نلفت انتباهكم إلى حفظ الطاولة لكم خلال الدقائق الـ {{value}} المقبلة",
                "future_standby_reservation_info": "يرجى تعبئة التفاصيل التالية على أن نبعث لكم رسالة بمجرد خلو مكان.",
                "called_in_info": "أضفناكم إلى الدور.\nيرجى الوصول إلى المطعم خلال الدقائق الـ {{minutes}} المقبلة.",
                "timer": "لديك {{minutes}} دقائق أخرى",
                "button_label": {
                    "confirm": "الحفظ",
                    "confirm_and_pay": "التأكيد والدفع",
                    "proceed_to_deposit": "المتابعة",
                    "cancel": "الإلغاء",
                    "back_to_search": "العودة للبحث",
                    "continue": "المتابعة",
                },
                "field_label": {
                    "first_name": "الاسم الشخصي",
                    "last_name": "اسم العائلة",
                    "full_name": "الاسم الكامل",
                    "phone": "الهاتف",
                    "email": "البريد الإلكتروني",
                    "notes": "ملاحظات",
                    "tags": "ملاحظات",
                    "search": "البحث"
                },
                "field_error": {
                    "phone": "رقم خاطئ"
                },
                "countries_dropdown": {
                    "find_country": "البحث"
                }
            },
            "modal": {
                "confirmation": {
                    "time_limit_title": "نلفت انتباهكم",
                    "time_limit_body": "طلبيتكم ستكون محدودة زمنيًا حتى الساعة {{reserved_until}} ({{duration}} فقط).\nهل تريدون المتابعة؟",
                    "queue_addition_title": "الإضافة إلى الدور",
                    "queue_addition_body": "هل تريدون أن تتم إضافتكم إلى الدور؟",
                    "still_here": "لا تزال هنا؟"
                },
                "alert": {
                    "unsuccessful_attempt_to_reserve_title": "أوبس...",
                    "unsuccessful_attempt_to_reserve_body": "يبدو أنكم غير متصلين بالإنترنت حاليًا.\nيرجى الاتصال وتكرار المحاولة.",
                    "unsuccessful_attempt_to_reserve_body_invalid_cc": "تحققوا من تفاصيل بطاقة الائتمان",
                    "reservation_not_found_title": "أوبس...",
                    "reservation_not_found_body": "لقد مرت أكثر من {{minutes}} دقائق منذ حفظ طلبيتكم وبالتالي فانتهت صلاحية الطلبية. يرجى إعادة إدخالها\n\nلمزيد من المساعدة، يمكن الاتصال بالمطعم",
                    "fill_mandatory_fields_title": "حقول إلزامية",
                    "fill_mandatory_fields_body": "يرجى تعبئة كافة الحقول الإلزامية",
                    "online_booking_blocked_title": "الطلب عبر الإنترنت غير متاح",
                    "online_booking_blocked_body": "للأسف الطلب عبر الإنترنت غير متاح لهذا المطعم",
                    "preference_required_title": "لم يتم تحديد منطقة",
                    "preference_required_body": "يرجى تحديد منطقة للمتابعة",
                    "illegal_group_size_title": "انتبهوا!",
                    "illegal_group_size_body": "عدد الزبائن الذي حددته أكبر للتاريخ والوقت المحددين.",
                    "customer_additional_reservation_blocked_title": "يتعذر تحرير طلبية أخرى",
                    "customer_additional_reservation_blocked_body": "يبدو أنكم قد حجزتم بالفعل مكانًا لدينا بنطاق لا يقل عن {{hours}} ساعات.\nمن أجل تحرير طلبية جديدة، يرجى إلغاء الطلبية السابقة أو الاتصال بنا.",
                    "cc_deposit_transaction_failed": "فشل المعاملة",
                    "back_to_create_reservation_button": "العودة إلى حجز مكان",
                    "call_restaurant_button": "اتصلوا بالمطعم"
                },
                "confirm": "التأكيد",
                "cancel": "الإلغاء",
                "retry": "كرر المحاولة",
                "continue": "المتابعة",
                "back": "العودة"
            },
            "user_abandon_warning": "نلفت انتباهكم إلى أنكم تغادرون الصفحة وعدم حفظ التعديلات التي قمتم بها",
            "online_booking_blocked_description": "في هذه المرحلة لا يمكن إنجاز طلبيات عبر الإنترنت. يرجى الاتصال بنا عن طريق الضغط على زر الهاتف أدناه."
        },
        "reservation_details": {
            "date_label": "اليوم {{value}}",
            "time_label": "عند الساعة",
            "arriving_within_label": "نصل بعد",
            "people": "{{value}}"
        },
        "feedback": {
            "feedback_form": "استمارة التعليق",
            "possible_answers": "الإجابات المحتملة",
            "mandatory_questions": "سؤال إلزامي",
            "guest": "ضيف",
            "description": "مرحبًا {{name}},\nسيسرنا إذا أجبتم على الأسئلة التالية",
            "conclusion": {
                "title": "شكرًا على تعليقاتكم!",
                "satisfied_customer_sub_title": "سيسرنا مشاهدتكم مجددًا عما قريب",
                "unsatisfied_customer_sub_title": "نعتذر على الإزعاج.\nتم تحويل استفسارك إلى مدير المطعم, وسنواصل بذل قصارى جهودنا من أجل تحسين تجربتكم."
            },
            "button_label": {
                "confirm": "أرسل تعليقاتك",
                "poor": "غير جيد",
                "fair": "جيد تقريبًا",
                "good": "جيد",
                "very_good": "جيد جدًا",
                "excellent": "ممتاز"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "جواب ناقص",
                    "fill_mandatory_fields_body": "يرجى الإجابة عن كافة الأسئلة"
                },
                "confirm": "التأكيد"
            },
            "unsubscribe": {
                "link": "لا أريد تلقي المزيد من استطلاعات الرأي من {{organization}}",
                "success": "أزلناكم بنجاح من تلقي استطلاعات الرأي في المستقبل.",
                "error": "وقع خطأ غير متوقع عند الإزالة من تلقي استطلاعات الرأي. يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بالمطعم."
            }
        },
        "deposit": {
            "description": "من أجل استكمال عملية حفظ الطلبية - يرجى تعبئة تفاصيل الائتمان لغرض الإيداع.",
            "card_number": "رقم البطاقة",
            "cvv": "CVV",
            "year": "العام",
            "month": "الشهر",
            "id_number": "رقم الهوية",
            "zip_code": "الرمز البريدي",
            "date_passed": "التاريخ فات",
            "invalid_cc_number": "رقم البطاقة خاطئ",
            "invalid_id_number": "رقم الهوية خاطئ",
            "button_label": {
                "save": "الحفظ",
                "confirm_and_continue": "للتأكيد والمتابعة",
                "cancel": "إلغاء الطلبية",
                "continue": "المتابعة"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "حقول إلزامية",
                    "fill_mandatory_fields_body": "يرجى تعبئة كافة الحقول الإلزامية",
                    "submit_error_title": "فشل الإيداع",
                    "submit_error_body_general": "تحققوا من التفاصيل في الاستمارة ثم كرروا المحاولة",
                    "submit_error_body_invalid_cc": "تحققوا من تفاصيل بطاقة الائتمان ثم كرروا المحاولة"
                },
                "confirm": "التأكيد"
            },
            "amount_details": {
                "title": "مبلغ الإيداع",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },
        "cc_deposit": {
            "description": "الطلبية مشروطة بتعبئة تفاصيل الائتمان لغرض الإيداع بواقع {{currency_symbol}}{{amount_per_guest}} للزبون.\nيمكن إلغاء الطلبية حتى {{time_limit_cancel}} مسبقًا.\nلاستكمال الطلبية أدخلوا التفاصيل التالية",
        },
        "deposit_payment": {
            "description": "الطلبية مشروطة بدفع سلفة بواقع {{currency_symbol}}{{amount_per_guest}} للزبون.\nيمكن إلغاء الطلبية حتى {{time_limit_cancel}} مسبقًا والحصول على خصم كامل مقابل الدفع.\nلاستكمال الطلبية تابعوا الدفع",
            "illegal_amount": "مبلغ الدفع غير صالح"
        },
        "health_statement": {
            "title": "تصريح صحة",
            "customer_thanks": "شكرًا {{first_name}} {{last_name}}",
            "health_statement_saved": "حفظ تصريح الصحة بنجاح",
            "health_statement_not_found": "لم يتم إيجاد تصريح صحة",
            "share_health_statement_form_button": "شارك تصريح الصحة مع غيرك من المدعوين",
            "share_health_statement_form_text": "مرحبًا,\nلقد حجزت لنا مكانًا في {{organization}}.\nطلب منا تعبئة تصريح الصحة:\n{{link}}",
            "no_fever_statement": "أصرح بخضوعي اليوم لقياس درجة حرارة الجسم، والذي وُجد من خلاله أن حرارة جسمي لا تتجاوز 38 درجة مئوية",
            "no_cough_statement": "أصرح بأنني لا أعاني من السعال وبأنني لا أواجه الصعوبات التنفسية ما عدا السعال أو الصعوبة عند التنفس الناتجة عن حالة مزمنة مثل الربو أو غيره من حالات الحساسية",
            "coronavirus_law": "بموجب المادة 3أ(1) من أنظمة وقت الطوارئ - فيروس الكورونا"
        },
        "smart_banner": {
            "title": "لماذا من خلال المتصفح؟",
            "body": "من خلال التطبيق هذا أسهل :)"
        },
        "server_time_client_diff_dialog": {
            "title": "نلفت انتباهكم",
            "content": "الساعة على جهازكم مختلفة عن توقيت المطعم على {{time_diff}}.\nكافة القيم الزمنية سيتم عرضها حسب توقيت المطعم.",
            "ok_button": "الموافقة"
        },
        "site-links": {
            "address_screen_reader": "عنوان المطعم:",
            "from_screen_reader": "مفتوح اعتبارًا من الساعة",
            "up_until_screen_reader": ",حتى الساعة",
            "phone_number_screen_reader": "رقم الهاتف:",
            "to_call_screen_reader": "اضغط هنا للاتصال",
            "to_navigate_screen_reader": "اضغط هنا للاطلاع على تعليمات الوصول والتوجيه",
            "show_work_hours_screen_reader": "اضغط هنا للاطلاع على أوقات عمل المطعم",
            "to_web_site_screen_reader": "اضغط هنا للانتقال إلى موقع المطعم"
        },
        "dialog_screen_reader": {
            "default": "تم فتح صندوق حوار",
            "before_you_pay_suggestion": "تم فتح صندوق حوار: اقتراح وجبات قبل الدفع",
            "order_scheduling": "فتح صندوق حوار: تنسيق تاريخ ووقت طلب الإرسال أو الاستلام الذاتي",
        },
        "forms_screen_reader": {
            "sign-up": {
                "pop_up_announcement": "تم فتح نافذة تسجيل الدخول لنظام تابيت، يرجى إدخال رقم الهاتف للتحقق",
                "missing_fields": "الحقول التالية إلزامية ولم تتم تعبئتها أو تمت تعبئتها بشكل خاطئ:",
                "phoneControl": "رقم الهاتف:",
            },
            "to-desktop-sign-up": {
                "missing_fields": "الحقول التالية إلزامية ولم تتم تعبئتها أو تمت تعبئتها بشكل خاطئ:",
                "cPhone": "رقم الهاتف:",
                "email": "عنوان البريد الإلكتروني",
                "firstName": "الاسم الأول",
                "lastName": "اسم العائلة",
                "apartment": "رقم الشقة",
                "floor": "رقم الطابق",
                "address": "العنوان الكامل",
                "terms": "ׁالموافقة على سياسة الخصوصية وشروط الاستخدام"
            },
            "customer_details": {
                "missing_fields": "الحقول التالية إلزامية ولم تتم تعبئتها أو تمت تعبئتها بشكل خاطئ:",
                "email": "عنوان البريد الإلكتروني",
                "first_name": "الاسم الشخصي",
                "last_name": "اسم العائلة",
                "notes": "ملاحظات",
                "phone": "رقم الهاتف:",
            },
            "loyalty_sign_up": {
                "missing_fields": "الحقول التالية إلزامية ولم تتم تعبئتها أو تمت تعبئتها بشكل خاطئ:",
                "firstName": "الاسم الأول",
                "lastName": "اسم العائلة",
                "email": "عنوان البريد الإلكتروني",
                "mobile": "رقم الهاتف",
                "zehut": "رقم بطاقة الهوية",
                "membershipPrice": "ׁالموافقة على إضافة الدفع على التسجيل",
                "clubTerms": "ׁالموافقة على شروط النادي",
            }
        },
        "social-share": {
            "share_label": "مشاركة الدعوة",
            "calendar_label": "الإضافة إلى اليوميات"
        },
        "social-share_screen_reader": {
            "share": "اضغط للمشاركة",
            "add_to_my_calender": "اضغط للإضافة إلى التقويم",
        },
        "contact-details": {
            "location_label": "الملاحة",
            "phone_label": "الهاتف",
            "menu_label": "قائمة الطعام",
            "choose_menu": "تحديد لائحة الطعام",
            "no_available_menu": "لا توجد هناك لائحة طعام متاحة",
            "menu_error_dialog_title": "حدث خطأ",
            "menu_error_dialog_body": "يرجى تكرار المحاولة لاحقا"
        },
        "contact-details_screen_reader": {
            "location": "اضغط لتوجيهات الوصول إلى المطعم",
            "phone": "اضغط للاتصال بالمطعم",
        },
        "TO-DELETE": "الحذف",
        "TO-EDIT": "التحرير",
        "TO-ADD": "الإضافة",
        "TO-UPDATE": "التحديث",
        "CANCEL": "الإلغاء",
        "NO_THANKS": "لا, شكرًا",
        "CLEAR_SELECTION": "امسح التحديد",
        "ADD": "أضف",
        "UPDATE": "حدّث",
        "DEFAULT_FORMATION": "عادي",
        "ORDER-TOTAL": "ملخص الطلبية",
        "REMOVE": "الإزالة",
        "CASH": "أموال نقدية",
        "THANK_YOU": "شكرًا جزيلاً!",
        "TIP": "الإكرامية",
        "PAYED": "تم الدفع",
        "CONTINUE": "المتابعة",
        "CART_SUBTOTAL": "سلة المتابعة",
        "TOTAL_IN_TIP": "إجمالاً يشمل الإكرامية",
        "PARTIAL_PAY_IN_TIP": "الدفع الجزئي يشمل الإكرامية",
        "CLEAR": "امسح",
        "CONFIRM_SIGN_OUT": "نعم، سجل الخروج",
        "APPROVE": "التاكيد",
        "REMAINED_PAY": "المبلغ المتبقي للدفع",
        "REMAINED_PAY_CASH": "النقود المتبقية للدفع",
        "TO_PAY": "للدفع",
        "SAVE_CARD_INFO": "حفظ تفاصيل الدفع",
        "TOTAL": "إجمالاً",
        "CARD_HOLDER_NAME": "اسم صاحب البطاقة",
        "CARD_NUMBER": "رقم البطاقة",
        "SIGNIN": "سجل الدخول",
        "SIGNIN_INFO": "أدخل تفاصيل التعرف",
        "VALIDATING_CREDENTIALS": "جاري فحص المعطيات...",
        "WELCOME_BACK": "مرحبًا",
        "SELECT_EXISTING_CARD": "اختاروا من بطاقة قائمة",
        "ADD_NEW_CARD": "أضف بطاقة جديدة",
        "SAVE_CARD_AS_DEFAULT": "احفظ البطاقة كإعداد افتراضي",
        "ORDER_TOTAL": "إجمالي مبلغ الطلبية",
        "ORDER_VAT_TOTAL": "(يشمل ضريبة القيمة المضافة {{total}} {{currency}})",
        "FOREIGN_CURRENCY_TOTAL": "القيمة بـ {{symbol}}",
        "ORDER_TIP": "الإكرامية",
        "PAID": "تم الدفع",
        "ENTER_ORDER_N": "أدخل رمز الطلبية",
        "TO_HOMEPAGE": "للاطلاع على الصفحة الرئيسية",
        "VIEW_MORE": "البنود الإضافية",
        "VIEW_LESS": "إخفاء البنود",
        "REQUIERED": "التعبئة إلزامية",
        "REQUIERED_CHECK": "التوسيم إلزامي",
        "EDIT": "التحرير",
        "PASSWORDS_DONT_MATCH": "كلمات المرور غير متطابقة",
        "PASSWORDS_MIN_LENGTH": "يجب أن تكون كلمة المرور من 6 رموز على الأقل",
        "INVALID_CREDIT_NUMBER": "رقم البطاقة غير صالح",
        "INVALID_CVV": "CVV غير صالح",
        "INVALID_CARD_EXPIRATION": "تاريخ انتهاء الصلاحية غير صالح",
        "INVALID_EMAIL": "البريد الإلكتروني غير صالح",
        "EMAIL_FOR_INVOCE": "EMAIL لإرسال فاتورة",
        "CARD_EXPIRATION": "تاريخ انتهاء الصلاحية",
        "CARD_HOLDER_CELL": "هاتف صاحب البطاقة",
        "ROOM_OWNER_CELL": "إلى أي رقم تريد إرسال رمز التحقق؟",
        "CARD_HOLDER_MAIL": "البريد الإلكتروني الخاص بصاحب البطاقة",
        "Q_SHARING_BILL": "للدفع الجزئي",
        "Q_TIP_AMOUNT": "كم إكرامية ينبغي إضافتها؟",
        "Q_HOW_MUCH_PAY": "ما هو المبلغ الذي تريد دفعه؟",
        "Q_HOW_MUCH_SPLIT": "كم عدد الأشخاص الذي تريد التقسيم بينهم؟",
        "Q_REGISTARED": "هل سجلت الدخول سابقًا؟ سجل الدخول",
        "Q_NEW_REGISTARED": "زبون جديد؟ سجل",
        "Q_INVOICE_BY_MAIL": "تريد الحصول على فاتورة عبر البريد الإلكتروني؟",
        "Q_REMOVE_CARD": "شطب البطاقة من المحفظة؟",
        "Q_REMOVE_PAYMENT": "هل أنت متأكد من أنك تريد شطب هذه الدفعة؟",
        "SIGNIN_REGISTER": "سجل / سجل الدخول",
        "END": "النهاية",
        "ENTER_TIP": "اطبع الإكرامية",
        "ENTER_AMOUNT": "اطبع المبلغ",
        "SPLIT_AMOUNT": "تقسيم المبلغ",
        "YOUR_AMOUNT_TO_PAY": "مبلغك للدفع؟",
        "PRESS_HERE": "اضغط هنا",
        "OTHER": "غير ذلك",
        "SENDING_INVOICE": "جاري إرسال الفاتورة...",
        "CHALLENGE_CODE": "رمز التحقق",
        "ENTER_CHALLENGE_CODE": "اطبع رمز التحقق",
        "REMEMBER_ME_ON_PHONE": "اذكرني على هذا الجهاز",
        "BILL_AMOUNT": "مبلغ الفاتورة",
        "PAY_WITH_ONOTHER_CARD": "ادفع ببطاقة أخرى",
        "PAYMENT_METHOD": "وسيلة دفع",
        "SELECT_PAYMENT_METHOD": "تحديد وسيلة الدفع",
        "CREDIT_CARD": "بطاقة ائتمان",
        "CREDIT": "الائتمان",
        "creditCard": "الائتمان",
        "CreditGuard": "كريديت غارد",
        "CIBUS": "سيبوس",
        "Cibus": "سيبوس",
        "CIBUS_CARD": "بطاقة سيبوس",
        "CIBUS_CARD_NUMBER": "رقم البطاقة",
        "TENBIS": "تِن بيس",
        "10bis": "تِن بيس",
        "10bis_card": "بطاقة تن بيس",
        "10bis_card_number": "رقم البطاقة",
        "loyaltypm_card_number": "رقم البطاقة",
        "EDIT_ACCOUNT_SETTINGS": "تعديل إعدادات الحساب",
        "MY_ACCOUNT": "حسابي",
        "SIGN_OUT": "سحل الخروج",
        "CLUB_SIGN_OUT": "إزالة عضو نادي من الطلبية",
        "VOUCHER_SIGH_OUT": "إزالة الاستفادة من القسيمة من الطلبية",
        "COMPS_SIGH_OUT": "إزالة التعويض من الطلبية",
        "EXPIRED_SOON_SIGN_OUT": "إلغاء التجديد",
        "EXPIRED_SOON_APPROVE": "المتابعة من نفس المكان",
        "REMOVE_VOUCHER": "نعم، أزل",
        "REMOVE_COMPS": "نعم، أزل",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION": "متأكد من أنك معني بإزالة عضو النادي من الطلبية؟",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_TEXT": "تسجيل الخروج من النادي سيلغي الاستحقاق للامتيازات",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_VOUCHER": "متأكد من أنك تريد إزالة القسيمة من الطلبية",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_COMPS": "متأكد من أنك معني بإزالة التعويض من الطلبية؟",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_EXPIRED_SOON": "إلغاء تجديد النادي؟",
        "MY_ACCOUNT_DETAILS": "تفاصيل حسابي",
        "EDIT_PAYMENT_INFO": "تعديل وسيلة الدفع",
        "DELETE_CARD": "حذف البطاقة",
        "SET_DEFAULT_CARD": "حدد كإعداد افتراضي",
        "YES_DELETE": "نعم، احذف",
        "OFFER_REMARKS": "ملاحظات للوجبة",
        "OFFER_DONE": "I'm done, thanks!",
        "ENTER_OFFER_REMARKS": "أدخل ملاحظات للوجبة",
        "DELETE_PAYMENT": "حذف الدفعة",
        "CONFIRM_CELL": "Confirm Phone",
        "CONFIRM_CELL_MESSAGE": "رسالة التأكيد سترسل إليك إلى الهاتف الجوال",
        "USE_ENTERED_PHONE": "أرسل إلى:",
        "ENTER_ALTERNATE_PHONE": "استخدم آخر:",
        "EMAIL_NOT_FOUND": "Email غير موجود على النظام",
        "GRATUITY": "الإكرامية",
        "WITHOUT_GRATUITY": "بدون إكرامية",
        "ORDER_SCHEDULING": "متى يناسبك تلقي الطلبية؟",
        "SELECT_SERVICE_TYPE": "نوع الخدمة",
        "CLOSE": "مغلق",
        "CLOSE_DIALOG_BOX_SCREEN_READER": "أغلق نافذة الحوار",
        "YES": "نعم",
        "NO": "لا",
        "SEND_ORDER": "إرسال الطلبية",
        "TRACK_YOUR_ORDER": "لتتبع طلبيتك",
        "YOUR_ORDERS": "طلبياتي",
        "ORDER_CREATION_TIME": "وقت الطلبية",
        "CONTINUE_ORDERING": "تابع للطلبية",
        "ADD_ITEM": "أضف بند",
        "SELECT_ITEM": "حددوا أحد البنود",
        "UPDATE_ITEM": "تحديث البند",
        "TOPPINGS": "إضافات للوجبة",
        "DECISIONS": "القرارات",
        "ITEM_CHANGES": "تعديلات على الوجبة",
        "SELECTION_GROUP_SELECT": "(حددوا {{count}} بنود)",
        "SELECTION_GROUP_SELECT_SINGLE": "(حددوا بندًا واحدًا)",
        "SELECTION_GROUP_SELECT_MIN": "(حددوا على الأقل {{count}} بنود)",
        "SELECTION_GROUP_SELECT_SINGLE_MIN": "(حددوا على الأقل بندًا واحدًا)",
        "GROUP_MOD_SELECT_ERROR": "حددوا على الأقل {{count}} إضافات من: {{group}}",
        "GROUP_MOD_SELECT_SINGLE_ERROR": "حددوا على الأقل واحدًا من: {{group}}",
        "GROUP_SELECT_ERROR": "حددوا على الأقل {{count}} بنود من: {{group}}",
        "GROUP_SELECT_SINGLE_ERROR": "حددوا على الأقل بندًا واحدًا من: {{group}}",
        "SELECTION_GROUP_OPTIONAL_MAX": "اختياري، حددوا ما يصل إلى {{count}} بنود",
        "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "اختياري، حددوا ما يصل إلى بند واحد",
        "CHECKOUT": "تابع للدفع",
        "Q_REMOVE_OFFER_FROM_BASKET": "هل أنت متأكد من أنك معني بإزالة البند؟",
        "ENTER_DELIVERY_ADDRESS": "أدخل العنوان للإرسال",
        "SELECT_ADDRESS": "حددوا العنوان",
        "BRANCH": "المطعم",
        "CHOOSE_BRANCH": "لتحديد المطعم",
        "ADDRESS": "العنوان",
        "PHONE": "الهاتف",
        "ENTRANCE": "المدخل",
        "FLOOR": "الطابق",
        "ENTER_STREET_INCLUDING_NUMBER": "يرجى طباعة العنوان الكامل يشمل رقم المنزل",
        "MISSING_STREET_NUMBER": "كيف سنصل إليك أصلاً؟",
        "START_ORDER": "المتابعة للطلبية",
        "MAKE_AN_ORDER": "حرر الطلبية",
        "SELECT_CITY": "حددوا المدينة",
        "SELECT_DELIVERY_CITY": "حددوا المدينة التي يتم الإرسال إليها",
        "SELECT_BRANCH": "حددوا الفرع",
        "ORDER_MENU": "قائمة الطعام",
        "eatin": "الطلب والجلوس",
        "ADDITIONAL_DETAILS": "المزيد من التفاصيل",
        "RESET_ORDER": "بدء الطلب مجددًا",
        "ORDER_FROM": "الطلب من الفرع",
        "ORDER_TO": "الإرسال إلى العنوان",
        "CONTACT_INFO": "التفاصيل الشخصية",
        "CONTACT_ALCOHOL_DECLARATION": "أبلغ من العمر أكثر من {{age}} وطالما يلزم الأمر سأبرز بطاقة للتحقق من هويتي لغرض شراء الكحول",
        "PAYMENT_INFO": "تفاصيل الائتمان",
        "PAYMENT_INFO_MISSING": "نفتقر إلى بعض التفاصيل للدفع بالبطاقة {{cardLastDigits}}",
        "FIRST_NAME": "الاسم الشخصي",
        "LAST_NAME": "اسم العائلة",
        "CELL": "الخلوي",
        "EMAIL": "Email",
        "REVIEW_YOUR_ORDER": "تفاصيل الطلبية",
        "REVIEW_YOUR_ORDER_BUTTON": "عرض الإيصال",
        "OKKAMI_APP_LINK": "Go Back to OKKAMI App",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "نلفت انتباهك إلى كون أحد أو أكثر من وسائل الدفع التي تم إدخالها غير صحيحة",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "يرجى الاتصال بالمطعم عبر الهاتف {{phone}}",
        "EXTERNAL_DELIVERY_FAILURE": "تم إرسال الطلبية بنجاح",
        "EXTERNAL_DELIVERY_FAILURE_CALL_REST": "ينبغي الاتصال بالمطعم عبر الهاتف {{phone}} لتنسيق شكل تنسيق الإرسال",
        "ADD_ITEMS": "إضافة بنود",
        "UPDATE_ITEMS": "حدّث التفاصيل",
        "ORDER_SENT_SUCCESS": "تم إرسال الطلبية بنجاح",
        "ORDER_NEED_ATTENDANCE": "تم إرسال الطلبية مع خطأ",
        "CALL_RESTAURANT_TO_COMPLETE_ORDER": "يرجى الاتصال بالمطعم",
        "ORDER_NUMBER": "رقم الطلبية",
        "START_NEW_ORDER": "إبدأ طلبية جديدة",
        "ORDER_ITEMS": "تفاصيل الطلبية",
        "ORDER_WILL_BE_SENT_TO": "سيتم إرسال الطلبية إلى العنوان",
        "ESTIMATED_DELIVERY_TIME": "وقت الوصول حوالي",
        "ESTIMATED_PREPARATION_TIME": "وقت الوصول حوالي",
        "ESTIMATED_SUPPLY_TIME": "وقت الإمداد",
        "MINUTES": "الدقائق",
        "PREPARATION_TIME": "وقت التحضير",
        "ORDER_TYPE": "نوع الطلبية",
        "PLEASE_SELECT": "يرجى التحديد",
        "PLACEMENT": "الخيارات",
        "NUMBER_OF_PEOPLE": "عدد الزبائن",
        "READ_AND_APPROVE_CONTRACT": "قرأت وأنا موافق",
        "READ_AND_APPROVE_CONTRACT_C": "للاطلاع على شروط الاستخدام",
        "READ_CONTRACT": "إقرأ تفاصيل الاستخدام...",
        "PLEASE_APPROVE_CONTRACT": "يرجى الموافقة على شروط الاستخدام",
        "USAGE_CONRACT": "شروط الاستخدام",
        "ADDRESS_REMARKS": "ملاحظات للساعي",
        "LOADING_MENU": "جاري تحميل قائمة الطعام...",
        "MOD_WITH": "الإضافات",
        "MOD_WITHOUT": "بدون",
        "PASSWORD": "كلمة مرور",
        "CONFIRM_PASSWORD": "أدخل كلمة المرور مجددًا",
        "FORGOT_PASSWORD": "نسيت كلمة المرور...",
        "RESTORE_PASSWORD": "استرجاع كلمة المرور",
        "INCLUDE_SILVERWARE": "أضف أدوات المائدة المستخدمة لمرة واحدة",
        "LEAVE_DELIVERY": "Leave delivery at door",
        "CURBSIDE_PICKUP": "الطلب للسيارة",
        "CURBSIDE_PICKUP_DESC": "صفوا من فضلكم نموذج السيارة، ولونه ورقم لوحة ترخيضه",
        "AUTHENTICATING": "جاري التحقق من التفاصيل",
        "SIGNED_IN_AS": "سجل الدخول باسم",
        "PAY_WITH_SAVED_PMS": "وسائل الدفع محفوظة",
        "SIGHN_IN_EMAIL": "Email للتحقق من الهوية",
        "SIGHN_IN_PASSWORD": "كلمة مرور للتحقق من الهوية",
        "UPDATE_PAYMENT_INFO": "تحديث وسائل الدفع",
        "UPDATE_ACCOUNT": "تحديث الحساب",
        "ADD_PAYMENT_INFO": "إضافة وسيلة دفع",
        "UPDATING_ACCOUNT": "جاري تحديث حسابك...",
        "UPDATE_SIGNIN_INFO": "حدّث التفاصيل المعرّفة",
        "PASS_MIN_LENGTH_ERROR": "يجب أن تتضمن كلمة المرور على الأقل 6 رموز",
        "OLD_PASSWORD": "كلمة مرور قديمة",
        "NEW_PASSWORD": "كلمة مرور جديدة",
        "BRANCH_TIME_CONFIRM_0": "نحن مغلقون حاليًا. سنجدد العمل بين الساعات {{t0}}",
        "BRANCH_TIME_CONFIRM_1": "نحن مغلقون حاليًا. سنجدد العمل بين الساعات {{t0}} و {{t1}}",
        "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "الخدمة لم تعد متاحة. سنجدد العمل بين الساعات {{t0}}",
        "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "الخدمة لم تعد متاحة. سنجدد العمل بين الساعات {{t0}} و {{t1}}",
        "BRANCH_TIME_0": "أوقات عمل الخدمة {{t0}}",
        "BRANCH_TIME_1": "أوقات عمل الخدمة {{t0}} و {{t1}}",
        "CONFIRM": "التأكيد",
        "ORDER_SUCCESS": "تأكيد الطلبية",
        "MIN-ORDER-PRICE": "مبلغ الطلبية الأدنى",
        "DELIVERY-PRICE": "رسوم الإرسال",
        "TOTAL-ITEMS": "إجمالي عدد البنود",
        "TOTAL-TO-PAY": "إجمالي مبلغ الدفع",
        "MENU_ITEM_SEARCH": "البحث عن وجبة",
        "MENU_ITEM_SEARCH_NO_RESULTS": "لم نعثر على نتائج من أجل \"{{searchValue}}\" 🧐",
        "MENU_ITEM_SEARCH_OTHER": "تريدون تجريب شيء آخر؟",
        "MENU_ITEM_SEARCH_CLEAN": "مسح البحث",
        "PROMOTION_MIN_ORDER_PRICE": "* عند الشراء بأكثر من {{currency}}{{minOrderPrice}}",
        "WITHOUT": "بدون",
        "CONTINUE_TO_PAYMENTINFO": "المتابعة لتفاصيل الدفع",
        "PAYMENT": "الدفع",
        "BRANCHES": "الفروع",
        "SERVER_ERROR": "خطأ الخادم",
        "ERROR": "حطأ",
        "PAYMENT_ERROR": "خطأ الدفع",
        "PARTIAL_PAYMENT_ERROR": "خطأ الدفع",
        "NAME": "الاسم",
        "REGISTERING": "حملة التسجيل",
        "REGISTER": "سجل",
        "RESET_PASSWORD": "إعادة ضبط كلمة المرور",
        "RESET_PASSWORD_INFO": "أدخل كلمة مرور جديدة واضغط على صفر",
        "ORDER_HISTORY": "تاريخ الطلبيات",
        "SELECT_ORDER_METHOD": "حددوا شكل الإرسال",
        "ORDER_METHOD": "شكل الطلب",
        "ORDER_FIXED": "حساب",
        "SET_QUANTITY": "أدخل الكمية",
        "CELL_PATTERN": "סלולרי צריך להתחיל ב 05",
        "CELL_MINLENGTH": "الخلوي ينبغي أن يتضمن 10 رموز",
        "VIEW_MENU": "مشاهدة قائمة الطعام",
        "ORDER_SERVICE_CLOSED": "خدمة الطلبيات مغلقة",
        "SET_ORDER_DELAY": "أجّل تنفيذ الطلبية...",
        "DELAY_TO": "أجّل للساعة",
        "SET_ORDER_DELAY_TO_HOUR": "أجّل تنفيذ الطلبية للساعة",
        "ASAP": "أبكر ما يمكن",
        "PRMARY_PAYMENT_METHOD": "إعداد افتراضي",
        "ADD_PAYMENT": "إضافة دفع",
        "SPLIT_PAYMENT": "افصل الدفع",
        "SPLIT_PAYMENTS_DIFF_ERROR": "المدفوعات لا تتلخص في {{total}}, هناك فارق بواقع {{diff}}",
        "BACK_TO_SITE": "بالعودة إلى الموقع",
        "BACK_TO_APP": "العودة إلى التطبيق",
        "FREE_DELIVERY_FROM": "الإرسال المجاني من سعر",
        "ADD_REMAINNING_AMOUNT": "أكمل الدفع لمبلغ الطلبية",
        "PAYMENTS_EXCEED_TOTAL_BY": "إجمالي المدفوعات أكبر من مبلغ الطلبية بواقع",
        "PAYMENT_AMOUNT": "مبلغ الدفع",
        "ENTER_FIRST_PAYMENT_AMOUNT": "أدخل مبلغًا للدفع الأول",
        "EXCESS_PAYMENT": "رصيد الدفع",
        "CLICK_TO_REMOVE_EXCESS_AMOUNT": "انقر للخصم من المبلغ الفائض",
        "CANCEL_SPLIT_PAYMENT": "إلغاء تقسيم الدفع",
        "APPLY_PAYMENTS": "تأكيد المدفوعات",
        "SINGLE_PAYER": "دافع فردي",
        "MULTIPLE_PAYERS": "عدد من الدافعين",
        "SPLIT_ORDER_BETWEEN_PAYERS": "التقسيم على عدد من الدافعين",
        "CALL_RESTAURANT": "الاتصال بالمطعم",
        "SELECT_TABLE": "حددوا طاولة",
        "TABLE_NO": "رقم الطاولة",
        "CHHOSE_ROOM_NO": "ما هو رقم غرفتكم؟",
        "ROOM_NO": "رقم الغرفة",
        "TABLE_NO_ORDER": "رقم الطاولة {{no}}",
        "ENTER_ORDER_NO": "إدخال رقم الطلبية",
        "ADD_GRATUITY_TO_COURIER": "إضافة إكرامية للساعي",
        "ORDER_TOTAL_WITHOUT_GRATUITY": "المبلغ الإجمالي للدفع بدون الإكرامية",
        "ORDER_TOTAL_WITH_GRATUITY": "المبلغ الإجمالي للدفع يشمل الإكرامية: {{total}}",
        "GRATUITY_PERCENT": "النسبة المئوية للإكرامية",
        "ENTER_GRATUITY_AMOUNT": "إدخال مبلغ الإركامية",
        "CONTINUE_TO_GRATUITY": "المتابعة لإدخال الإكرامية",
        "REFRESH_ORDER": "تحديث الطلبية",
        "MONTH": "الشهر",
        "YEAR": "العام",
        "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "إدخال مبلغ للدفع، لا يشمل الإكرامية",
        "ENTER_AMOUNT_TO_PAY": "أدخل المبلغ للدفع",
        "FULL_PAYMENT": "الدفع الكامل",
        "PARTIAL_PAYMENT": "الدفع الجزئي",
        "AMOUNT_EXEEDS_BALANCE_DUE": "المبلغ أكبر من رصيد الدفع",
        "BACK": "العودة",
        "WORK_HOURS": "أوقات العمل",
        "RESTAURANT_WEBSITE": "موقع المطعم",
        "SELECT_ONE": "حددوا واحدًا",
        "DELIVERY_ADDRESS": "العنوان للإرسال",
        "DELIVERY_DETAILS": "تفاصيل الإرسال",
        "ADDRESS_DETAILS": "تفاصيل العنوان",
        "BRANCH_DETAILS": "تفاصيل المطعم",
        "ADDITIONAL_OPTIONS": "المزيد من الخيارات",
        "MINUTES_VAL": "{{m}} دقائق",
        "ADDITIONAL_OFFERS": "الإضافات والصلصات",
        "SELECT_ADDITIONAL_OFFERS": "اختاروا الإضافات والصلصات",
        "CONTINUE_TO_CONTACTINFO": "المتابعة لتفاصيل الاتصال",
        "CC_CUSTOMER_NAME": "الاسم",
        "CC_CUSTOMER_ID": "رقم الهوية / رقم الشركة الخاصة",
        "ORDERER_NAME": "اسم الجهة الطالبة",
        "ORDERER_PHONE": "الهاتف",
        "ORDER_NO": "رقم الطلبية",
        "ORDER_TAGS": {
            "BREAKFAST": "الإفطار",
            "LUNCH": "الغداء",
            "DINNER": "العشاء",
            "WORK": "العمل"
        },
        "ENTER_LOCALITY": "أدخل اسم المدينة/ البلدة",
        "LOCALITY_TITLE": "اسم المدينة/ البلدة",
        "STREET_TITLE": "اسم الشارع",
        "HOUSE_TITLE": "رقم المنزل",
        "TITLE": "{{name}} الإرساليات - TabitOrder",
        "TITLE_1": "{{name}} الإرساليات",
        "TITLE_2": "{{name}} - TabitOrder",
        "PAYMENT_IN_PROGRESS": "جاري الدفع...\nيرجى عدم إغلاق النافذة",
        "CREATING_ORDER": "جاري إرسال الصفقة...",
        "LOADING_ORDER": "جاري تحميل الصفقة...",
        "INVALID_AMOUNT": "مبلغ الدفع غير صالح",
        "INVALID_AMOUNT_RANGE": "مبلغ صالح بين {{min}} و {{max}}",
        "BACK_TO_MENU": "العودة إلى قائمة الطعام",
        "ORDER_NOTES": "ملاحظات الطلبية",
        "Q_SAVEPM_1": "معنيون بتقصير فترة الطلبية؟",
        "Q_SAVEPM_2": "You can save your payment method in Tabit's digital wallet for quicker and faster payments",
        "Q_SAVEPM_3": "Pay and Save",
        "Q_SAVEPM_4": "Pay without saving",
        "DISH_CALCULATOR": "حاسبة الوجبات",
        "MAKE_ADDITIONAL_PAYMENT": "المتابعة لدفع آخر",
        "DINER": "الزبون",
        "GENERAL_DINER": "وسط الطاولة",
        "ADDRESS_WITHOUT_HOUSE": "عنوان بدون رقم منزل",
        "TAX_AMOUNT": "الضرائب",
        "VAT_AMOUNT": "ضريبة القيمة المضافة",
        "INCLUSIVE_TAXES": "الضرائب شاملة",
        "ADDITIONAL_FEES": "عمولة",
        "ADDITIONAL_SURCHARGERS": "عمولات أخرى",
        "ADDRESS_TYPE": "نوع العنوان",
        "VALID_UNTILL": "ساري المفعول حتى",
        "ADDRESS_DESKTOP_HEADER": "مرحبًا، فقط نتأكد من وصولنا إليك...",
        "ADDRESS_DESKTOP_ANNOUNCEMENT_SCREEN_READER": "سنفتح نافذة للبحث عن العنوان، يرجى إدخال العنوان الكامل: المدينة، واسم الشارع ورقم المنزل",
        "RESTAURANT_DESKTOP_SEARCH_ANNOUNCEMENT_SCREEN_READER": "تم فتح نافذة للكشف عن مطعم، يرجى إدخال: اسم المطعم أو عنوانه",
        "SITES_DESKTOP_HEADER": "تحديد المطعم للاستلام",
        "SITES_DESKTOP_MENU_HEADER": "حددوا فرعًا للمشاهدة على قائمة الطعام",
        "FUTURE_ORDER_DIALOG_HEADER": "متى سنلتقى؟",
        "FUTURE_ORDER_POP_ANNOUNCEMENT_SCREEN_READER": "تم فتح نافذة لتنسيق استلام طلبية مستقبلية؛ يرجى تحديد التاريخ على العمود الأيمن، والوقت على العمود الأيسر",
        "CHAIN_DIALOG_HEADER": "تحديد مطعم",
        "FUTURE_DELIVERY_ORDER_DIALOG_HEADER": "متى يناسبك وصولنا؟",
        "WHERE_CAN_WE_FIND_YOU": "أين يمكن العثور عليك؟",
        "share_message": "مرحبًا، هنا {{name}} - مطعم وجدته على تابيت. تريدون المحاولة؟",
        "share_message_without_site": "مرحبًا، هنا مطعم وجدته على تابيت. تريدون المحاولة؟",
        "from_you": "منك",
        "general_time_to": "حتى",
        "restaurants": "المطاعم",
        "restaurants_subtitle": "اعثروا على المطاعم المفضلة لديكم",
        "order": "الإرسال",
        "order_subtitle": "الاستلام أو الإرسال",
        "order_action": "الإرسال/TA",
        "book": "حجز مكان",
        "short_code": "الدفع السريع",
        "payment_code": "الرمز للدفع",
        "payment_code_expiration": "ساري المفعول لمدة {{minutes}} دقائق",
        "payment_code_expiration_till": "(حتى {{time}})",
        "short_code_flow": {
            "steps": {
                "first": "تحرير طلبية",
                "second": "إبراز الرمز",
                "third": "الدفعة ستكون ملزمًا للبطاقة القابلة للشحن"
            }
        },
        "balance": "رصيد البطاقة القابلة للشحن",
        "quickLoad": "للشحن السريع",
        "book_subtitle": "احجزوا طاولة في المطعم المفضل لديكم",
        "book_action": "حجز مكان",
        "pay": "الدفع",
        "pay_subtitle": "ادفعوا هنا بسهولة من خلال جهازكم الجوال",
        "pay_action": "الدفع من خلال الجوال",
        "quickpay_action": "الدفع السريع",
        "default": "إعداد افتراضي",
        "save": "الحفظ",
        "okay": "المتابعة",
        "OK": "الموافقة",
        "close": "مغلق",
        "back": "العودة",
        "continue": "المتابعة",
        "register": "سجل/ي",
        "registration": "التسجيل",
        "reorder_action": "اطلب مجددًا!",
        "location_select": "تحديد منطقة",
        "my_location": "الموقع الحالي",
        "change_location": "التغيير",
        "my_location_unavailable": "الموقع غير متاح",
        "more": "المزيد",
        "km": "كيلومتر",
        "mile": "ميل",
        "at": "إلى",
        "until": "حتى",
        "far_away": "بعيد جدًا",
        "when_to_book": "متى تريدون الجلوس",
        "choose_restaurant": "اختاروا مكانًا",
        "book_tables_title": "طاولات فارغة",
        "book_tables_subtitle": "اضغطوا على أحد المطاعم لحجز طاولة فورًا",
        "book_tables_standby_title": "قائمة انتظار",
        "book_tables_standby_subtitle": "اضغطوا على أحد المطاعم من أجل الانضمام إلى قائمة الانتظار",
        "booking_description": "فقط قولوا متى لنعثر على طاولة من أجلكم!",
        "website": "الموقع الإلكتروني",
        "i_approve_the": "قرأت وصادقت على",
        "privacy_policy": "سياسة الخصوصية",
        "club_terms": "لائحة النادي الداخلية",
        "terms_and_conditions": "شروط الاستخدام",
        "approve_commercials": "عند تعبئة استمارة الانضمام هذه إنني آذن للنادي بإرسال مواد ترويجية إليّ من فينة لأخرى من خلال الرسائل النصية.",
        "approve_sms": "استلام رسائل ترويجية من خلال رسائل نصية",
        "approve_mail": "استلام رسائل ترويجية من خلال البريد الإلكتروني",
        "account_removal": "إزالة حساب",
        "remove_account": "إزالة حساب",
        "and": "و",
        "around_you": "حول موقعك الحالي",
        "today": "اليوم",
        "redirect_to_device_settings": "الانتقال إلى إعدادات الجهاز",
        "next_time": "مرة أخرى",
        "redirect_to_profile": "انتقل إلى الملف الشخصي",
        "understood": "فهمت",
        "delivery_or_takeway": "الإرسال أو الاستلام الذاتي",
        "order_type_title": "الإرسال أو الاستلام",
        "order_type_description": "يرجى تحديد تفضيلكم.",
        "delivery": "الإرسال",
        "takeaway": "الاستلام الذاتي",
        "takeaway_or_delivery_screen_reader": "ַتحديد الاستلام الذاتي أو الإرسال",
        "seated": "الجلوس",
        "house": "المنزل",
        "office": "المكتب",
        "apartment": "الشقة",
        "entrance": "المدخل",
        "floor": "الطابق",
        "no_addresses": "انتظروا",
        "address_type": "نوع العنوان",
        "please_select_type": "يرجى تحديد النوع",
        "address_not_found": "لم يتم العثور على عنوان",
        "address_title": "العنوان للإرسال",
        "address_description": "يرحى تحديد عنوانكم.",
        "please_type_address_short": "إضافة عنوان جديد",
        "please_type_address_long": "اطبعوا المكان، المدينة أو العنوان الكامل",
        "please_type_delivery_address_short": "إضافة عنوان للإرسال",
        "please_type_delivery_address_long": "اطبعوا العنوان الكامل يشمل رقم المنزل",
        "please_type_address_full": "اطبعوا العنوان الكامل",
        "new_address": "عنوان جديد",
        "saved_address": "عناوين تم تحديدها سابقًا",
        "address_placeholder": "الشارع والرقم، المدينة",
        "business_invite_restaurant_owner": "Tabit",
        "business_invite_restaurant_owner_screen_reader": "للاطلاع على موقع الشركة، تابيت",
        "business_invite_join_us": "للاطلاع على موقع الشركة",
        "business_invite_restaurant_owner_seo": "لديك مطعم؟",
        "business_invite_join_us_seo": "هذا من أجلك",
        "error_title": "لحظة...",
        "error_general": "حدث خطأ مجهول. يرجى تكرار المحاولة.",
        "error_invalid_cards_for_auto_attach": "حدث خطأ، يرجى الاتصال بالمطعم",
        "error_invalid_sign_in_credentials": "يبدو أن البريد الإلكتروني أو كلمة المرور غير صحيحة أو غير تابعة لرقم الهاتف الذي أدخلته. يرجى تكرار المحاولة.",
        "error_invalid_touchid": "لم نستطع التحقق من هويتك من خلال الوجه أو بصمة الإصبع. يرجى الدخول برقم الهاتف الجوال.",
        "error_invalid_sign_in_email_already_exists": "هذا البريد الإلكتروني موجود في النظام. عليك إدخال بريد إلكتروني آخر.",
        "error_invalid_load_sites": "حدث خطأ عند تحميل المطاعم... يرجى تكرار المحاولة.",
        "error_client_credentials": "حدث خطأ... يرجى تكرار المحاولة.",
        "error_sign_up": "حدث خطأ في تسجيل المستخدم... يرجى تكرار المحاولة.",
        "error_sign_up_user_already_exists": "يبدو أن مستخدم لديه نفس البريد الإلكتروني موجود بالفعل. يرجى محاولة الدخول بواسطة اسم المستخدم وكلمة المرور الخاصة بكم.",
        "error_reset_password_missing_email": "يرجى تعبئة عنوان بريدكم الإلكتروني من أجل تجديد كلمة المرور.",
        "invalid_email_entered": "عنوان البريد الإلكتروني الذي تم إدخاله غير صالح",
        "error_reset_password": "يبدو أن بريدكم الإلكتروني غير صالح أو غير موجود في النظام. أو حاولوا مجددًا أو سجلوا مجددًا.",
        "error_full_address_required": "يرجى طباعة العنوان الكامل يشمل رقم المنزل",
        "confirmation_title": "التأكيد",
        "confirmation_password_reset": "بريد إلكتروني يتضمن تعليمات بشأن كيفية تجديد كلمة المرور تم إرساله إلى صندوقكم.",
        "main": "رئيسي",
        "login": "سجل الدخول",
        "logout": "سجل الخروج",
        "name": "الاسم",
        "first_name": "الاسم الشخصي",
        "last_name": "العائلة",
        "phone": "الهاتف",
        "birthDate": "تاريخ الولادة",
        "anniversary": "تاريخ يوم الزواج",
        "send": "أرسل",
        "sending": "الإرسال",
        "enter_code": "رمز التحقق",
        "verification_code": "رمز التحقق",
        "verification_code_message": "رمز التحقق أحادي المرة الخاص بكم: {{pincode}}",
        "confirm_code": "التأكيد",
        "did_not_arrive_resend": "أرسلوا لي مجددًا",
        "resent": "أرسلنا مجددًا...",
        "email": "البريد الإلكتروني",
        "password": "كلمة مرور",
        "forgot_your_password": "نسيت كلمة المرور",
        "continue_with_email": "أدخل البريد الإلكتروني",
        "or_continue_with": "أو تابع مع",
        "sign_in": "تسجيل الدخول",
        "sign_up": "زبون جديد؟ جل/ي",
        "facebook": "Facebook",
        "google": "Google",
        "search": "البحث",
        "restaurants_search": "البحث عن المطاعم",
        "occasions_search": "البحث عن الفعاليات",
        "searching": "جاري البحث",
        "categories": "الفئات",
        "tags": "الشارات",
        "ratings": "التصنيف",
        "price": "السعر",
        "services": "الخدمات",
        "auth_touch": "التعرف من خلال الوجه / الإصبع",
        "style": "الأسلوب",
        "accessibility": "إمكانية الوصول",
        "mood": "المزاج",
        "kosher": "كوشير",
        "collective": "التركيبة",
        "events": "الفعاليات",
        "specials": "المميزات الخاصة",
        "suitable": "مناسب لـ",
        "by_date": "حسب التاريخ",
        "by_restaurant": "حسب المطعم",
        "restaurant_type": "نوع المطعم",
        "tabit_restaurants": "مطاعم Tabit",
        "about_the_restaurant": "نبذة عن المطعم",
        "articles": "المقالات",
        "regulations": "اللائحة الداخلية",
        "paid": "تم الدفع",
        "on_the_house": "على حساب البيت",
        "to_main_page": "للاطلاع على الصفحة الرئيسية",
        "input_pay_amount": "أدخل المبلغ للدفع",
        "choose_payment": "حددوا وسيلة الدفع",
        "show_code_to_staff": "أبرز الرمز على الفريق",
        "code_valid_time_1": "صلاحية الرمز هي لمدة 5 دقائق. سيتم تكبيد الرسوم فقط",
        "code_valid_time_2": "بعد الموافقة على الرمز من قبل الفريق.",
        "user_reviews": "الرأي",
        "user_review_hey": "مرحبًا",
        "user_review_end": "النهاية",
        "user_review_thanks": "شكرًا",
        "user_review_thanks_for_sharing": "شكرًا على المشاركة معنا",
        "user_review_waiting_to_hear_from_you": "ننتظر الاستماع منك",
        "user_review_what_did_you_like": "ما هو أكثر شيء أعجبك؟",
        "user_review_comment": "سيسرنا استماع تعليقك",
        "user_review_positive_thank_you_description": "ننتظر بفارغ الصبر للقائنا القادم!",
        "user_review_negative_thank_you_description": "نأمل بلقائك مجددًا وتحقيق نجاح أكبر",
        "user_review_add_comment": "احكوا لنا المزيد",
        "user_review_food": "الطعام",
        "user_review_service": "الخدمة",
        "user_review_package": "التعليب",
        "user_review_times": "الأوقات",
        "user_review_order_process": "عملية الطلب",
        "user_review_atmosphere": "الأجواء",
        "user_review_perfect": "مثالي",
        "user_review_ok": "جيد",
        "user_review_plausible": "معقول",
        "user_review_expected_more": "كنت أنتظر شيئًا أفضل",
        "user_review_bad": "سيء نسبيًا",
        "user_review_add_a_review": "أضف التعليق",
        "user_review_how_was_it": "كيف كان؟",
        "user_review_sorry_to_hear": "يؤسفنا سماع ذلك...",
        "user_review_what_to_improve": "سيسرنا معرفة النقاط التي تتطلب التحسن",
        "in_progress_message": "أنتم في المنتصف...",
        "deletion_will_remove_all_payments": "ستسبب هذه المعاملة شطب كافة الدفعات",
        "sorry": "عذرًا",
        "contact_us": "اتصل بنا",
        "were_sorry": "آسفين",
        "notifications": {
            "got_all_notifications": "يبدو أن الخبر قد وردك بالفعل",
            "promise_to_inform": "نعد بصبغ الجرس باللون الأصفر عند حدوث شيء جديد",
            "unsupported_browser_title": "نوضح الوضع",
            "unsupported_browser_confirm": "فهمت",
            "unsupported_browser": "لتحسين العرض ، يوصى بتغيير إعدادات العرض إلى الوضع الساطع، أو عدم استخدام متصفح سامسونج"
        },
        "order_tracker": {
            "hey": "مرحبًا",
            "ETA": "التوقع",
            "FEES": "العمولات",
            "CASH_DISCOUNT": "خصم نقدي",
            "track_order": "متابعة الطلبية",
            "my_order": "طلبيتي",
            "ETA_takeaway": "ستكون الطلبية جاهزة حتى",
            "ETA_delivery": "الشحنة ستصل حتى",
            "actual_status": "الوضع الحالي",
            "status": "وضع",
            "order": "الطلبية",
            "order_a": "لقد طلبت",
            "order_is_being_loaded": "لحظة من فضلك...",
            "order_accepted": "استلمنا طلبيتك",
            "order_being_prepared": "جاري تحضير الطلبية",
            "order_in_progress": "نحن نعمل على ذلك",
            "takeaway": "الاستلام الذاتي",
            "delivery": "الإرسال",
            "arrived": "لقد وصلت",
            "track": "التتبع",
            "send": "أرسل",
            "ok": "الموافقة",
            "order_ready": "طلبيتك جاهزة",
            "order_ready_for_delivery": "يجري التحضير للانطلاق",
            "order_taken": "تم استلام الطلبية",
            "order_taken_on_its_way": "الساعي في طريقه إليك",
            "order_delivery_delivered": "بالهناء والشفاء! تم تسليم الطلبية",
            "add_review": "أضف التعليق",
            "your_order_not_found": "لم يتم العثور على طلبيتك",
            "hey_im_here": "مرحبًا، أنا هنا!",
            "im_here_explanation": "عند وصولكم سيسركم معرفة -",
            "how_can_we_find_you": "كيف يمكننا العثور عليك؟",
            "car_information": "تفاصيل المركبة",
            "instructions_accepted": "شكرًا على إبلاغنا :)",
            "update_notes": "حدّث الملاحظات",
            "order_not_here": "طلبيتك لم تعد هنا...",
            "dont_cry_over_spilled_juice": "نحن لا نبكي على عصير يتم سكبه",
            "can_we_offer_you_something_else": "يمكن عرض شيء آخر عليك؟",
            "im_here_confirmation_title": "تأكيد الوصول",
            "im_here_confirmation_message": "هل تريد أن نبلغ المطعم بوصولك؟",
            "im_here_confirmation_hurry_message": "هذا كان سريعًا، متأكد من وصولك؟",
            "im_here_confirm": "نعم",
            "im_here_cancel": "ليس بعدُ",
            "disclaimer_with_phone": "في كل طلب أو استفسار بشأن طلبيتك يرجى الاتصال مباشرةً بـ {{name}} من خلال الضغط على 📞",
            "disclaimer_without_phone": "في كل طلب أو استفسار يتعلق بطلبيتك يرجى الاتصال مباشرة بـ {{name}}"
        },
        "to_disclaimer": "في كل طلب أو استفسار يتعلق بطلبيتك يرجى الاتصال مباشرة بـ {{name}}",
        "by_phone": "عبر الهاتف: ",
        "my_account": "منطقتي",
        "my_account_description": "بإمكانكم تحرير تفاصيلكم الشخصية هنا.",
        "personal_information": "التفاصيل الشخصية",
        "mobile": "الهاتف الجوال",
        "cell": "الهانف الجوال",
        "change_password": "استبدل كلمة المرور",
        "old_password": "كلمة مرور قديمة",
        "new_password": "كلمة مرور جديدة",
        "my_wallet": "وسيلة دفع",
        "my_wallet_info": "إعداد وسيلة الدفع",
        "my_addresses": "العناوين",
        "my_addresses_info": "العناوين محفوظة لغرض الإرساليات",
        "credit_no": "البطاقة #",
        "credit_card_number": "رقم البطاقة",
        "credit_type": "نوع البطاقة",
        "credit_type_regular": "بطاقة ائتمان",
        "credit_type_cbus": "سي-بوس",
        "credit_type_10bis": "تِن-بيس",
        "expiration_date": "تاريخ انتهاء الصلاحية",
        "expiration_month": "الشهر",
        "expiration_year": "الصلاحية",
        "cvv": "3 أرقام على ظهر البطاقة",
        "comments": "ملاحظات",
        "my_preferences": "تفضيلات",
        "my_preferences_info": "المطابخ المفضلة والحساسيات",
        "my_preferences_selected_description": "فئاتكم وتفضيلاكم",
        "my_preferences_description": "من أجل تحديث الفئات والحساسيات حددوها في القوائم التالية",
        "my_preferred_categories": "الفئات المفضلة",
        "my_preferred_dishes": "الوجبات المفضلة",
        "my_preferred_tags": "الشارات المفضلة",
        "my_preferred_allergies": "الحساسيات",
        "my_history": "تاريخ الطلبيات",
        "my_history_info": "الطلبيات المفتوحة والتاريخ",
        "my_history_description": "كافة زياراتكم وطلبياتكم واردة في القائمة التالية",
        "search_history": "تحميل التاريخ",
        "search_history_description": "مرحبًا، في حال زرت سابقًا المطاعم {{descTitle}} يمكننا البحث عن تاريخ طلبياتك وتحميله",
        "my_benefits": "امتيازاتي",
        "benefits_and_promotions": "الحملات والامتيازات",
        "more_benefits": "الامتيازات الأخرى",
        "my_benefits_info": "للاطلاع على معلومات بشأن كافة امتيازاتك ونقاطك",
        "my_benefits_description": "كافة امتيازاتك ونقاطك واردة في القائمة التالية",
        "immediate_realization": "للاستفادة الفورية",
        "immediate_topup": "للشحن السريع اضغطوا هنا",
        "prePaid_realization": "على بطاقة قابلة للشحن",
        "benefit_details": "تفاصيل الامتياز",
        "valid_until": "ساري المفعول حتى",
        "benefits_club": "نادي الامتيازات",
        "brithday_benefit": "امتياز تاريخ الولادة",
        "join_benefit": "امتياز الانضمام",
        "renew_benefit": "امتياز التجديد",
        "anniversary_benefit": "امتياز تاريخ الزواج",
        "general_benefit": "امتياز عام",
        "voucher_benefit": "قسيمة",
        "punch_card": "تذكرة الامتيازات",
        "points_benefit": "امتياز المراكمة",
        "my_favorites": "تفضيلاتي",
        "my_recent": "مؤخرًا",
        "your_recent_orders": "إذن ما كان لدينا مؤخرًا؟",
        "no_recents": "لم يتم العثور على طلبياتك سابقة",
        "no_favorites": "لا توجد هناك تفضيلات بعدُ",
        "no_deliveries": "يا للأسف... يبدو أنه لا توجد هناك إرساليات إلى موقعكم حاليًا",
        "future_reservations": "عمليات حجز مكان",
        "future_reservation": "عملية حجز مكان",
        "future_reservations_and_discounts": "عمليات حجز مكان مستقبلية وقسائم خصم",
        "club_benefits": "امتيازات النادي",
        "club_points": "نقاط النادي",
        "marketplace_title": "غيفت كارد لمطاعم Tabit",
        "your_points_worth": "نقاطك تساوي المال ومتاحة للاستفادة منها",
        "pre_paid_available": "البطاقة المتاحة للشحن متاحة للاستفادة الفورية",
        "pre_paid_top_up": "شحن البطاقة",
        "realize_points": "الاستفادة من النقاط",
        "your_tracked_order": "لتتبع طلبيتك",
        "quickpay": "الدفع السريع",
        "issues-feedback-link": "سيسرنا سماع رأيكم",
        "latest_orders_title": "أريد الطلب مجددًا!",
        "latest_orders_subtitle": "طلبياتكم وزياراتكم الأخيرة",
        "sites_near_me_title": "المطاعم القريبة منكم",
        "sites_near_me_subtitle": "كل المطاعم المربحة - هنا تمامًا!",
        "redirect_to_my_history": "للاطلاع على القائمة الكاملة لكافة الطلبيات الأخيرة",
        "HOUR": "ساعة واحدة",
        "DATE": "التاريخ",
        "add_pm": "إضافة وسيلة دفع",
        "scan_pm": "مسح البطاقة",
        "add_address": "حفظ العنوان",
        "my_recent_addresses": "عناويني الأخيرة",
        "my_default_addresses": "العنوان المفضل",
        "please_confirm": "لحظة...",
        "please_note": "רגע...",
        "just_a_moment": "لحظة...",
        "dont_worry": "لا داعي للقلق",
        "please_enter_value": "يرجى إدخال قيمة",
        "please_enter_location_value": "يرجى إدخال وصف المكان",
        "are_you_sure": "هل أنت متأكد",
        "delete_account": "حذف الحساب؟",
        "accept": "الموافقة",
        "Cancel": "الإلغاء",
        "update_account": "تحديث",
        "your rank": "تصنيفكم",
        "you_have": "لديك",
        "accumulated": "راكمت",
        "points": "النقاط",
        "prepaid_points": "نقاط تم دفعها مسبقًا",
        "recent_actions": "المعاملات الأخيرة",
        "view_bill": "الحساب",
        "goto-rest-page": "انتقل إلى صفحة المطعم",
        "photos": "الصور",
        "openning_houres": "ساعات العمل",
        "open_now": "مفتوح حاليًا",
        "closed_now": "مغلق حاليًا",
        "will_be_open_at": "سيتم فتحه عند الساعة",
        "new_in_tabit": "جديد لدى TABIT",
        "rank": "التصنيف",
        "near_me": "قريب مني",
        "extra_services": "خدمات أخرى",
        "near_me_pay_more_title": "ربما اختلطت الأمور لدينا؟ مطاعم على مقربة منكم",
        "kitchens": "مطابخ",
        "cuisine": "مطابخ",
        "slide_down_to_close": "انزلق للأسفل من أجل الإغلاق",
        "Open": "مفتوح حاليًا",
        "Open Now": "مفتوح حاليًا",
        "Closed": "مغلق حاليًا",
        "Closed Now": "مغلق حاليًا",
        "profile": "الملف الشخصي",
        "update_details": "استكمال التفاصيل",
        "reload_app": "إعادة تحميل الصفحة",
        "version": "الإصدار",
        "closed_for_day": "مغلق لهذا اليوم",
        "missing_site_description": "أزل وصف المطعم. موصى به...",
        "show_more_sites": "عرض المزيد من المطاعم",
        "show_more": "عرض المزيد",
        "all_sites": "كافة المطاعم",
        "to_all_sites_title": "تبحثون عن شيء معيّن؟",
        "to_all_sites": "للاطلاع على كافة المطاعم",
        "enter_order_no": "أدخل رمز الطلبية",
        "ORDER_DATE": "تاريخ الطلبية",
        "ORDER_TIME": "مدة الانتظار",
        "preparing-food": "بمجرد إضافة وجبات للطلبية ستستطيعون مشاهدتها هنا",
        "ADD_GRATUITY_TO_ORDER": "إكرامية",
        "AMOUNT_TO_PAY": "المبلغ للدفع",
        "PAYUP": "تابع للدفع",
        "TABLE_BILL": "فاتورة للطاولة",
        "WAITER": "النادل",
        "PAY_WITH": "ادفع بواسطة",
        "PAY_WITH_WALLET": "ادفع بواسطة محفظتك",
        "SELECT_ALTERNATE_PYMENT": "حددوا وسيلة دفع أخرى",
        "PAY": "ادفع",
        "clear": "امسح",
        "please_enter_signature": "لتأكيد الدفع وقّع هنا",
        "notes": "ملاحظات",
        "id_card": "بطاقة الهوية",
        "next": "تابع",
        "press here": "اضغط هنا",
        "day": "اليوم",
        "month": "شهر",
        "year": "سنة",
        "preference": "التفضيل",
        "single_payer": "دافع فردي",
        "partial_payment": "الدفع الجزئي",
        "split_payment_by": "تقسيم الحساب حسب",
        "other": "غير ذلك",
        "gratuity": "الإكرامية",
        "order_balance": "الدفع المتبقي",
        "without_gratuity": "بدون إكرامية",
        "select_items": "حدد التفاصيل",
        "pay_by_items": "الدفع حسب البند",
        "pay_by_items_title": "أريد الدفع نظير...",
        "area": "المنطقة",
        "GRATUITY_AMOUNT": "مبلغ الإكرامية",
        "ENTER_CVV": "أدخل CVV",
        "PLEASE_ENTER_CVV": "يرجى إدخال CVV",
        "ENTER_CVV_MESSAGE": "أدخل CVV للبطاقة: {{card}}",
        "SAVE_ACCOUNT_PAYMENT_INFO": "حفظ وسيلة الدفع في حسابك",
        "CAN_TD": "طلب الإرسالية",
        "otcSeated": "المنضدة",
        "expiration": "الصلاحية",
        "PASS_ORDER": "تتقاسمون حسابًا؟",
        "PASS_ORDER_TITLE": "التحويل إلى صديق",
        "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "الدفع الإضافي بهذا الجهاز’آ",
        "PASS_ORDER_BY_PHONE_MESSAGE": "لتحويل الرابط عبر رسالة نصية ينبغي إدخال رقم هاتف",
        "PASS_ORDER_BY_PHONE_CAPTION": "رقم خلوي",
        "PASS_ORDER_BY_PHONE_SEND": "إرسال دعوة",
        "PASS_ORDER_BY_PHONE_QR_MESSAGE": "أو مسح الرمز الشريطي",
        "PASS_ORDER_SENT_SUCCESS": "تم إرسال الطلبية بنجاح",
        "CANNOT_SEND_ORDER_BY_SMS": "نعتذر لاستحالة إرسال الرابط حاليًا.",
        "CONTINUE_WITHOUT_GRATUITY": "المتابعة بدون الإكرامية",
        "PERFORMING_PAYMENT": "ينفذ الدفع...",
        "FINALIZING_ORDER": "جاري العمل على ذلك...\nيرجى عدم إغلاق الصفحة",
        "PLEASE_WAIT": "يرجى الانتظار...",
        "WERE_ON_IT": "نحن نعمل على ذلك...",
        "LOREM_SMALL": "لوريم إيبسوم دولور سيت أمط، كونسكتورر أديبسينج إليث هوعنيب يهوشفف شعرش شمحويط - شلوشع وتلبرو حشلو شعوتلشخ وحايت نوبش عرششف",
        "reserve_a_place_at": "حجزت مكانًا لدى",
        "full-payment": "الدفع الكامل",
        "goto-tip": "إضافة إكرامية",
        "partial-payment": "الدفع الجزئي",
        "goto-payment": "الدفع",
        "add-items": "إضافة البنود",
        "add-to-order": "الإضافة إلى الطلبية",
        "send-order": "إرسال طلبية",
        "add-to-order-with-amount": "الإضافة إلى الطلبية {{amount}}",
        "back-to-order": "العودة إلى الطلبية",
        "ID_CARD": "بطاقة هوية صاحب البطاقة",
        "ENTER_IDCARD": "أدخل بطاقة الهوية",
        "ENTER_IDCARD_MESSAGE": "أدخل بطاقة الهوية للبطاقة: {{card}}",
        "got_it": "فهمت",
        "confirm_exit": "المغادرة؟ متأكد؟",
        "resart_order_confirm": "لن يتم حفظ الوجبات التي اخترتها",
        "events_and_benefits": "الفعاليات والامتيازات",
        "marketplace_main_title": "غيفت كارد لمطاعم Tabit",
        "date": "التاريخ",
        "to_restaurant": "للمطعم",
        "sites_list": "قائمة الفروع",
        "sites_nearby": "المطاعم المجاورة",
        "not_available": "غير متاح",
        "not_available_now": "غير متاح حاليًا",
        "not_available_to_this_address": "غير متاح لهذا العنوان",
        "available_from": "بدءًا من ",
        "preorder": "طلبية مبكرة",
        "future_order": "طلبية مستقبلية",
        "availability": "التوفر",
        "only_available": "متوفر حاليًا",
        "future_order_standby": "الطلبية في قائمة الانتظار",
        "future_order_pending": "الطلبية تنتظر الموافقة",
        "order_details": "تفاصيل الطلبية",
        "order_in_progress": "إعادة بدء الطلبية؟",
        "order_end": "بدء طلبية جديدة؟",
        "continue_order": "متابعة الطلبية",
        "payment_in_progress": "الدفع",
        "payment_order": "متابعة الدفع",
        "cancel_order": "نعم، المغادرة",
        "confirm_cancel_order": "نعم، البدء من جديد",
        "confirm_continue_order": "المتابعة من نفس المكان",
        "payment_screen": "الدفع بمسح الرمز الشريطي",
        "short-code_screen": "الدفع السريع لدى آلة تسجيل النقد",
        "extended_results": "المزيد من النتائج",
        "book_a_place": "حجز مكان",
        "app_smart_banner_title": "لماذا من خلال المتصفح؟",
        "app_smart_banner_body": "من خلال التطبيق هذا أسهل :)",
        "try_again": "كرروا المحاولة",
        "wait_on": "انتظروا",
        "change_language": " استبدال اللغة",
        "pepper_pay": {
            "15_bonus": "15 ₪ امتياز",
            "pepper_bonus": "امتياز PAY",
            "pepper_pay_payers": "للدافعين عبر تطبيق PAY!",
            "pepper_pay_realization": "للاستفادة من الامتياز ادفعوا من خلال Pay على صفحة الدفع",
            "takeout_delivery_above_amount_tip_not_included": "ساري المفعول عند دفع مبلغ أكثر من 50 ₪ (لا يشمل رسوم الإرسال) عند طلب إرسالية / الاستلام الذاتي",
            "look_for_pay_mark": "ابحثوا عن مطاعم ذات توسيم PAY (رهنًا ب"
        },
        "BENEFIT_TYPE": {
            "points": "النقاط",
            "Other": "الامتياز",
            "BirthdayBenefit": "امتياز تاريخ الولادة",
            "AnniversaryBenefit": "امتياز تاريخ الزواج",
            "JoinBenefit": "امتياز الانضمام",
            "PointsBenefit": "امتياز المراكمة",
            "RenewBenefit": "امتياز التجديد",
            "Vouchers": "القسيمة",
            "Benefit": "امتياز",
            "PunchCard": "تذكرة الامتيازات",
            "PointsStore": "متجر امتيازات",
            "PointsStoreBasket": "الاستفادة من النقاط",
        },
        "ROUTE": {
            "book-a-table": "book-a-table",
            "deliveries": "deliveries",
            "restaurants": "restaurants",
            "new-at-tabit": "new-at-tabit",
            "nearest-restaurants": "nearest-restaurants",
            "events": "events",
            "reservation": "online-reservations",
        },
        "AVS": {
            "billing_address": "عنوان لتحصيل الرسوم",
            "state": "الدولة",
            "select_state": "حددوا الدولة",
            "city": "المدينة",
            "address": "العنوان",
            "zip_code": "الرمز البريدي",
        },
        "TGM": {
            "FIND_ME_APLACE": "اعثر لي على مكان",
            "FIND_ME_ARESTAURANT": "اعثر لي على مطعم",
            "DINERS": "الضيوف",
            "Day": "اليوم",
            "DATE": "التاريخ",
            "TIME": "الوقت",
            "BOOK_TITLE": "פרטי הזמנה",
            "ONE_DINER": "ضيف واحد",
            "DETAILS_TITLE": "لاستكمال العملية يرجى تعبئة التفاصيل التالية.",
            "SUCCESS_TITLE": "تم استلام طلبيتك بنجاح",
            "SUCCESS_TITLE_STANDBY": "نلفت انتباهكم، لقد أضفناكم إلى قائمة انتظار. سنتصل بكم في حالة توفر مكان.",
            "SUCCESS_TITLE_PENDING_APPROVAL": "نلفت انتباهكم إلى عدم الموافقة على طلبيتكم بعدُ. سنتصل بكم في أسرع وقت ممكن لتأكيد طلبيتكم.",
            "DEPOSIT_TITLE": "من أجل استكمال عملية حفظ الطلبية - يرجى تعبئة تفاصيل الائتمان من أجل الإيداع.",
            "LICENSE_AGREEMENT": "من خلال الضغط على \"حفظ\" أنتم توافقون على شروط الاستخدام وسياسة الخصوصية. للمزيد من التفاصيل اضغطوا هنا ⟩",
            "SAVE": "الحفظ",
            "standby": "قائمة انتظار",
            "instant_booking": "الموافقة الفورية",
            "pending_approval": "مشروط بالموافقة",
            "form_description_standby": "للإضافة إلى قائمة الانتظار، يرجى ملء التفاصيل التالية.",
            "form_description_pending_approval": "هذا الطلب مشروط بموافقة المطعم. لاستكمال العملية يرجى تعبئة التفاصيل التالية.",
            "standby_list": "قائمة انتظار",
            "missing_cc_details": "جاري انتظار الإيداع",
            "missing_deposit_payment": "جاري انتظار دفع سلفة",
        },
        "ORDER_SITES_BY": {
            "tabit": "تصنيف Tabit",
            "distance": "المسافة",
            "name": "الاسم"
        },
        "SERVICES": {
            "order": "الطلبية عبر الإنترنت",
            "book": "حجز مكان",
            "quickpay": "الدفع السريع"
        },
        "SERVICE_BUTTON": {
            "home": "الرئيسية",
            "order": "الإرسال/TA",
            "book": "حجز مكان",
            "delivery": "الإرسال",
            "takeaway": "الاستلام الذاتي",
            "pay": "مسح QR",
            "pay_generic": "الدفع",
            "profile": "الملف الشخصي",
            "future_order": "طلبية مستقبلية",
            "eatin": "الطلب والجلوس",
            "waitingList": "قائمة انتظار",
        },
        "MESSAGES": {
            "EXTRA_PAGE_ERROR": "Selection is required to continue",
            "GENERIC_GET_CUSTOMER_ERROR": "حدث خطأ أثناء عملية تسجيل الدخول. يرجى المحاولة مرة أخرى أو التواصل مع المطعم للحصول على المساعدة.",
            "LOADING_INITIAL_SITES": "نبحث عن تجربتك التالية",
            "EMAIL_MISSING": "مرحبًا، يسرنا انضمامك :)\n من أجل الاستفادة من كافة المضامين لم يبقَ سوى إضافة عنوان البريد الإكتروني لملفك الشخصي.",
            "EMAIL_STILL_MISSING": "من أجل استكمال التسجيل لم يبقى سوى إضافة عنوان بريد إلكتروني",
            "LOADING_SITES": "لحظة... هناك المزيد.",
            "NO_MORE_RESULTS": "انتهى... لا يوجد هناك مزيد من النتائج.",
            "NO_ORGS_RESULTS": "لن نستطيع العثور على نتائج مناسبة",
            "NO_ORGS_RESULTS_FOR_THIS_ADDRESS": "لم يتم العثور على مطاعم لهذا العنوان",
            "GENERAL_ERROR": "وقع خطأ غير متوقع - يرجى تكرار المحاولة",
            "NO_INTERNET": "يبدو أنكم غير متصلين بالإنترنت",
            "CONFIRM_SIGNOUT": "تسجيل الخروج من الحساب؟",
            "sign_in_by_phone_message": "مرحبًا! يسرنا مشاهدتك :)",
            "sign_in_by_phone_description": "فقط التحقق السريع من الهاتف ثم سنتابع",
            "enter_code_message": "لقد أرسلنا إليك رمز تحقق إلى ",
            "sign_in_by_email_message": "مرحبًا، يرجى الدخول بالبريد الإلكتروني وكلمة المرور",
            "wl_sign_up_title": "التسجيل للنادي",
            "sign_up_message": "بعض الأمور الأخيرة لننتهي",
            "saved_successfully": "تم الحفظ بنجاح",
            "deleted_successfully": "تم الحذف بنجاح",
            "error_getting_bill": "وقع خطأ في جلب الحساب",
            "service_not_available_for_site": "الخدمة غير متاحة حاليًا في هذا المطعم.",
            "Q_REMOVE_CARD": "شطب البطاقة من المحفظة؟",
            "NO_PMS_FOUND": "لم يتم العثور على وسيلة دفع في حسابك",
            "NO_SITE_PMS_FOUND": "لم يتم العثور على وسيلة دفع للمطعم",
            "NO_RESTS_FOUND": "لم يتم العثور على مطاعم للبحث المطلوب",
            "NO_PMS_FOUND_ADD": "لم يتم العثور على وسائل دفع ملائمة في حسابك. انقر فوق إضافة طريقة دفع للإتاحة الخدمة",
            "SIGNIN-EMAIL-EX": "أدخل عنوان البريد الإلكتروني الذي سجلت به سابقًا",
            "SIGNIN-EMAIL-EX-HELP": "سيتم إرسال رمز التحقق إلى الهاتف الجوال المسند لعنوان البريد الإلكتروني",
            "SIGNIN-CHALLENGE-EX": "تم إرسال رمز التحقق إلى الهاتف",
            "SIGNIN-CHALLENGE-EX-HELP": "لم تحصل على الرمز؟ <b>أرسلوا مجددًا</b>",
            "SAVE_INFO": "حفظ تفاصيل الاتصال والدفع",
            "SAVE_INFO_END": "سجلوا للطلب السهل والسريع في المستقبل",
            "SAVE_INFO_PAYMENT": "حفظ تفاصيل الدفع",
            "SIGNIN_CHALLENGE": "تم إرسال رمز إجابة إليك من خلال SMS، يرجى إدخاله ثم الضغط على تسجيل الدخول",
            "PAYMENT_CHALLENGE": "تم إرسال رمز تحقق إلى \n{{phone}}\nمن خلال SMS",
            "PAYMENT_ENTER_PHONE": "فقط التحقق السريع من الهاتف ثم سنتابع",
            "PAYMENT_OTP": "أرسلنا إليك رمز تحقق إلى \n {{phone}}",
            "PAYMENT_CHALLENGE_RETRY": "الرمز الذي أدخلته خاطئ!\nتم إرسال رمز تحقق آخر من خلال SMS",
            "SIGNIN_VALIDATION": {
                "incorrect-phone-number": "خطأ في طباعة الرقم",
            },
            "TRANSACTION_SUCCESS": "تم إنجاز الدفع بنجاح",
            "ORDER_CLOSED": "تم دفع الطلبية بأكملها",
            "INVALID_ORDER": "لم يتم العثور على الطلبية",
            "PAYMENT_FAILED": "فشل الدفع",
            "INVALID_PIN": "الرمز الذي تم إدخاله غير متطابق",
            "SIGN_FOR_PAYMENT_CONFIRM": "لتأكيد الدفع وقّع هنا",
            "SIGNATURE_MANDATORY": "لكي نستطيع تأكيد وسيلة الدفع، يجب التوقيع!",
            "WAITER_NOTIFIED": "لقد تلقى فريقنا إشارة وسيصل إليك قريبًا!",
            "HAPPY": "سيسرنا مشاهدتكم مجددًا",
            "Cancel": "Cancel",
            "INVOICE_SENT": "تم إرسال فاتورة إليك عبر البريد الإلكتروني",
            "ITEM_ADDED_TO_BASKET": "تمت إضافة بند إلى السلة",
            "NO_BASKET_ITEMS": "لا توجد هناك بنود في السلة",
            "CONFIRM_ORDER_FROM_BRANCH": "لقد اخترت الطلب من فرع: {{branch}}",
            "FINDING_THE_BRANCH_NEAREST_TO_YOU": "يعثر على أقرب فرع من منزلكم",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "لم يتم العثور على فرع يخدم العنوان: {{address}}",
            "NO_BRANCH_SERVING_ADDRESS": "المطعم لا ينفذ الإرساليات إلى هذا العنوان",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "نقوم حاليًا بشحن الطلبات الفورية فقط إلى هذا العنوان",
            "CONFIRM_RESET_ORDER": "هل أنت متأكد من رغبتك في إعادة بدء الطلبية",
            "DO_YOU_APPROVE_CONTRACT": "هناك موافقة على شروط الاستخدام؟",
            "DO_YOU_APPROVE_CONTRACT_TITLE": "لحظة قبل أن نتابع",
            "CONDITIONS_PRIMARY_BUTTON_TEXT": "نعم",
            "CONDITIONS_SECONDARY_BUTTON_TEXT": "الخروج",
            "RESTORE_PASSWORD_1": "يرجى إدخال الـ Email الذي استخدمته للتعرف على هويتك.",
            "SIGNIN_SUCCESS": "مرحبًا {{name}}، لقد سجلت الدخول بنجاح إلى النظام",
            "FORGOTPASS_SUCCESS": "تم إرسال تعليمات تسجيل الدخول إلى عنوان الـ Email الذي أدخلتموه",
            "ACCOUNT_UPDATE_SUCCESS": "تم تحديث حسابك بنجاح!",
            "TITLE_HUNGRY": "يؤسفنا إبلاغك",
            "BRANCH_DISABLED_NOW": "Service is closed right now. {{t}}.",
            "BRANCH_DISABLED_NOW_ERROR": "But this service has closed. It's available between {{t}}.",
            "BRANCH_CLOSED_TODAY": "هذه الخدمة غير نشطة اليوم",
            "BRANCH_CLOSED_NOW": "سيتم فتح الخدمة عند الساعة {{t}}, لكن لا داعي للقلق,\nيمكن إجراء طلب مبكر والذي سيدخل إلى التحضير بمجرد فتح الخدمة.",
            "MIN-ORDER-PRICE": "الحد الأدنى من الطلبية المرسلة هو {{t}}\n(لا يشمل رسوم الإرسال).\nينبغي إضافة بنود إلى الطلبية",
            "SELECT-LOYALTY-SERVICE": "من أجل التقدم في الطلبيةن يرجى إدخال بطاقة النادي",
            "SERVER_ERROR": "وقع خطأ عند معالجة الطلب,\nאيرجى الاتصال بالمطعم",
            "PAYMENT_ERROR": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل.",
            "PAYMENT_ERROR_TITLE": "خطأ الدفع",
            "PAYMENT_LOCKED": "تم إغلاق الطلبية من قبل مستخدم آخر. يرجى تكرار المحاولة",
            "REGISTER_SUCCESS": "سجلت بنجاح",
            "PASSWORD_RESET_SUCCESS": "تمت إعادة ضبط كلمة المرور بنجاح",
            "CUSTOMER_ENTITYALREADYEXISTS": "زبون لديه البريد الإلكتروني المعيّن موجود فعلاً على النظام",
            "PHONE_VERIFICATION_FAILED": "يبدو أن الرمز غير صحيح، يرجى تكرار المحاولة.",
            "PHONE_VERIFICATION_FAILED_TITLE": "لحظة...",
            "TOO_MANY_CODE_REQUESTS": "تم حظر حسابك مؤقتًا لأنك وصلت إلى الحد الأقصى لعدد المحاولات المسموح بها.\nيرجى المحاولة مرة أخرى لاحقًا.",
            "VOUCHER_ALREADY_USED": "يبدو أن القسيمة قد تم استخدامها بالفعل",
            "SERVICE_UNAVAILABLE": "هذه الخدمة غير نشطة حاليًا، يرجى تكرار المحاولة في وقت لاحق",
            "MOD_CANT_SELECT_MORE_THEN": "يرجى تحديد ما لا يتجاوز {{max}} إضافات!",
            "MOD_PLEASE_SELECT_AT_LEAST": "ينبغي تحديد على الأقل {{min}} إضافات!",
            "MOD_PLEASE_SELECT_AT_LEAST_ONE": "ينبغي تحديد إضافة واحدة على الأقل!",
            "CANNOT_PAY_TRAINING_MODE": "النظام في وضع التمرن. لا يمكن إتمام الصفقة. يرجى الاتصال بالمطعم\n {{phone}}",
            "ORDER_CANNOT_BE_PROCESSED": "وقع خطأ في تنفيذ الدفع، يمكن الاتصال بالمطعم عبر الهاتف.\n (خطأ: {{code}})",
            "TIMEZONE_CHANGED": "It seems there's an issue with your device's clock. To ensure the best experience, please restart your order. Thank you for understanding!",            "ARE_YOU_SURE": "هل أنت متأكد",
            "DISABLED_REGION_TITLE": "خدمة الإرساليات غير متاحة",
            "DISABLED_REGION_MESSAGE": "نحن لا نصل حاليًا إلى هذا العنوان.\nيرجى تكرار المحاولة لاحقًا",
            "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "حاليًا نحن نرسل الطليات الفورية فقط إلى هذا العنوان",
            "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "حاليًا نحن نرسل الطليات المستقبلية فقط إلى هذا العنوان",
            "ENABLED_REGION_TIME_MESSAGE": "سنعود للعمل بين الساعات {{t}}",
            "ENABLED_REGION_DAY_MESSAGE": "سنعود للعمل {{d}}, بين الساعات {{t}}",
            "OUT_OF_REACH": "يؤسفنا إبلاغك",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "بأن أحد أو أكثر من الدفعات التي أدخلتها فشلت. يرجى الاتصال بالمطعم لإكمال الطلبية.\n {{phone}}",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "أحد أو أكثر من الدفعات التي أدخلتها فشلت. يرجى التوجه إلى النادل/ة لمتابعة المعالجة",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "نلفت انتباهك إلى كون أحد أو أكثر من وسائل الدفع التي تم إدخالها غير صحيحة",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "يرجى الاتصال بالمطعم عبر الهاتف {{phone}}",
            "SELECTTABLE_INFO": "يرجى إدخال رقم الطاولة التي تريد الطلب إليها",
            "ENTERORDER_INFO": "يرجى إدخال رقم الطلبية التي تريد الدفع نظيرها.",
            "INVALID_TABLE": "الطاولة غير موجودة",
            "ORDER_RELOAD_NEEDED": "يرجى الانتباه! مبلغ الدفع تم تعديله!",
            "ORDER_NEWPAYMENT_NEEDED": "نلفت انتباهك إلى تغير الرصيد للدفع. ينبغي إدخال مبلغ جديد",
            "ORDER_HELD_BY_SERVER": "نحن نعتذر، لكن لا يمكن الدفع في هذه اللحظة. يرجى تكرار المحاولة بعد عدة دقائق",
            "SESSION_VALIDATION_FAILED": "وقع خطأ في معالجة الطلب، يرجى تحديث الصفحة وتكرار المحاولة",
            "CREDIT_CARD_3DS_DISCLAIMER": "قد يطلب منكم إدخال رمز تحقق والذي سيتم إرساله إليكم من شركة الائتمان عبر رسالة نصية أو البريد الإلكتروني.",
            "PAY_ORDER_CHANGED": "تم تعديل الطلبية! يرجى مراجعة الطلبية.",
            "PAYMENT_ACCEPTED_SUCCESSFULLY": "تم تنفيذ الدفع بنجاح",
            "PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT": "تم استلام دفعة بمبلغ {{amount}} بنجاح",
            "PAYMENT_INTENT_EXPIRED": "الدفعة منتهية الصلاحية!",
            "CARD_EXPIRED": "منتهية الصلاحية",
            "PAYMENT_DELETED_SUCCESSFULLY": "تم حذف الدفعة بنجاح",
            "PAYMENT_ACCEPTED": "تم تنفيذ الدفع بنجاح",
            "OUT_OF_STOCK": "الوجبات التالية نفدت من المخزون خلال الطلب:\n {{items}}\n سيرنا اقتراح خيارات أخرى عليك من قائمة الطعام",
            "GRATUITY_CASH_DISABLE": "لا يمكن الدفع بأموال نقدية إذا ما كانت هناك إكرامية",
            "ORDER_ITEMS_UNAVALABLE": "للأسف البنود التالية غير متاحة حاليًا للطلب:",
            "ORDER_TIMEOUT": "يرجى بدء الطلبية من جديد",
            "ORDER_TIMEOUT_TITLE": "الوقت فات",
            "ORDER_DELIVERY_ERROR_TITLE": "حدث خطأ ما",
            "ORDER_DELIVERY_ERROR_MESSAGE": "لم يتم حفظ عنوان التسليم الذي أدخلته وكان علينا إعادة تشغيل الطلب. نأسف على الإزعاج",
            "LOCAL_TIME_HAS_BEEN_CHANGED": "تم تنفيذ تغيير على التوصيت المحلي في جهازك",
            "ORDER_TIMEOUT_LEVEL1": "لكن لا داعي للقلق فلديكم 5 دقائق أخرى لاستكمال الطلبية",
            "ORDER_TIMEOUT_LEVEL1_TITLE": "اطلعوا على آخر المستجدات",
            "BASKET_ERRORS_TITLE": "عفوًا لقد نفد",
            "BASKET_ERRORS": "البنود التالية غير متوفرة: {{errors}} حدثوا من فضلكم طلبيتكم",
            "BASKET_ERRORS_BUTTON": "حدّث",
            "PROMOTION_DISABLED_TITLE": "عفوًا لقد نفد",
            "PROMOTION_DISABLED": "الحملة غير متاحة: {{errors}} تم تحديث سلتك بموجب ذلك",
            "PROMOTION_DISABLED_BUTTON": "حدّث",
            "CONFIRM_ADDRESS": "العنوان الذي تم إدخاله هو:\n<b>{{address}}</b>\nهل تريد المتابعة؟",
            "PROBLEM_IN_PAYMENT_INPUT": "وقع خطأ في استيعاب تفاصيل الدفع\nيرجى إعادة إدخال تفاصيل الدفع\nفي حال استمرت المشكلة، يرجى الاتصال بالمطعم هاتفيًا: \n {{phone}}",
            "MEAL_CALC_FACTOR_DESC": "يتم حساب الخصم على كل بند حسب وزنه النسبي في الطلبية",
            "MEAL_CALC_ACTION_DESC": "ينبغي توسيم الوجبات للدفع",
            "MEAL_CALC_TOTAL_DESC": "إجمالي الوجبات لا يمكن أن يتجاوز المبلغ المتبقي للدفع",
            "EX_PAYMENT_ERROR": "وقع خطأ في التحويل إلى مزود الدفع",
            "EX_PAYMENT_AUTHRIZATION_ERROR": "وقع خطأ في تأكيد الدفع من المزود",
            "LOCALITY_WO_STREETS": "بالنسبة للبلدة {{locality}} لا يتم تعريف أسماء الشوارع\nهل تريد تحديد هذه البلدة باعتبارها وجهة إرسالية؟",
            "SERVICE_END_WARN": "انتبهوا! خدمة الطلبيات سيتم إغلاقها بعد {{t}} دقائق. يجب إكمال تنفيذ الطلبية في الوقت المتبقي.",
            "JUST_SO_YOU_KNOW": "يهمنا الإبلاغ",
            "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "الموافقة",
            "QR_HELPER": "ينبغي مسح الـ QR",
            "INVALID_QR": "وقع خطأ في مسح الـ QR. \n في حال كنت تريد الدفع يرجى إدخال رمز يدوي",
            "SITE_NOT_FOUND": "المطعم المطلوب لم يتم العثور عليه",
            "TABIT_PAY_MANUAL_ENTER": "لإدخال رمز يدوي واختيار مطعم",
            "TABIT_PAY_MANUAL_ENTER_FOR_SITE": "للدفع برمز يدوي",
            "PLEASE_ENABLE_CAMERA": "من أجل مسح QR يرجى إتاحة الوصول إلى الكاميرا",
            "PAY_INFO": "من أجل متابعة عملية الدفع يرجى اختيار مطعم",
            "ENTER_ORDER_NO_INFO": "لدى فريق المطعم رمز من أجلك",
            "AREA_NOT_SELECTED": "لم يتم تحديد منطقة",
            "PLEASE_SELECT_AREA": "يرجى تحديد منطقة للمتابعة",
            "PLEASE_ENABLE_LOCATION": "لنستطيع إصدار توصية لك بشأن المطاعم حسب موقعك، ينبغي تفعيل خدمات الموقع",
            "DESCRIBE_LOCATION": "وصف الموقع",
            "MAP_LOCATION_INFO": "لكي نستطيع الوصول إليك، ينبغي أن تشير العلامة إلى موقعك الدقيق",
            "USER_LOCATION_ERROR": "لقد انتبهنا إلى أن خدمات الموقع لديك غير تشطة\nلكي نستطيع الوصول إليكن ينبغي أن تشير العلامة إلى موقعك الدقيق",
            "PLEASE_ENABLE_NOTIFICATIONS": "هناك أشياء تحدث هنا 🙂 الإنذارات ستتيح لنا مشاركة  كل هذا الخير معك, نضمن لك عدم الإزعاج!",
            "ENABLE_LOCATION": "يرجى تفعيل خدمات الموقع",
            "ENABLE_NOTIFICATIONS": "يرجى تفعيل الإنذارات",
            "CONTINUE_WITHOUT_GRATUITY": "المتابعة بدون الإكرامية؟",
            "CALL_RESTAURANT": "الاتصال بالمطعم",
            "NO_LOCATION": " خدمات الموقع غير نشطة!",
            "ORDER_REQUIERES_IDCARD": "مبلغ الدفع أعلى من اللازم، يرجى تخفيض المبلغ أو التوجه إلى النادل",
            "ERROR_CROSS_ORGS_TABLE_SEARCH": "يبدو أنكم غير متصلين بالإنترنت",
            "CROSS_ORGS_TABLE_NOT_FOUND": "لم يتم العثور على طاولات فارغة في مطاعم مفتوحة في الوقت المطلوب، يرجى محاولة الطلب في وقت آخر",
            "GRATUITY_NOT_ALLOWED_FOR_PM": "في هذا المطعم لا يمكن إضافة إكرامية بـ {{pm}}.\nينبغي تحديد وسيلة دفع أخرى أو إلغاء إضافة الإكرامية.",
            "GRATUITY_PA_WARNING": "في هذا المطعم نحصل على إكرامية من خلال {{pms}} فقط",
            "FOR_EXTENDED_RESULTS_PRESS_HERE": "لمزيد من النتائج اضغطوا هنا",
            "EXTENDED_RESULTS_BUTTON_ADDITIONAL_INFO": "لأن هذه العلاقات هامة لنا، لا نخفي أي شيء. هناك المزيد من المطاعم التي تنفذ إرساليات مع شركات أخرى. إذا كنت ترغب في ذلك.",
            "THE_SERVICE_BEGIN_AT": "سيتم فتح الخدمة عند الساعة",
            "SERVICE_PREORDER_DONT_WORRY": "لكن لا داعي للقلق، يمكن تحرير طلبية مبكرة والتي سيتم تحضيرها بمجرد فتح الخدمة.",
            "SERVICE_DOWN": "الخدمة غير متوفرة حاليًا، يرجى تكرار المحاولة",
            "SUMMARY_SENT_BY_MAIL": "خلاصة الطلبية تم إرسالها إلى بريدك الإلكتروني",
            "ENABLE_GRATUITY_CREDIT_ONLY": "يمكن الدفع ببطاقة ائتمان فقط إذا ما كانت هناك إكرامية",
            "ITEM_OUTOF_STOCK": "الوجبة التي حددتها غير موجودة في المخزون حاليًا لكن هناك العديد من الخيارات غيرها!",
            "ITEM_OUTOF_STOCK_TITLE": "للأسف نفدت",
            "ITEM_OUTOF_STOCK_BUTTON": "العودة إلى قائمة الطعام",
            "MAX_CAT_OFFERS_CONT": "لا يمكن طلب أكثر من {{max}} بنود من قائمة الطعام {{target}}",
            "MAX_ORDER_OFFERS_CONT": "لم يعد هناك مكان في السلة…\nلا يمكن طلب أكثر من {{max}} بنود في الطلبية",
            "MAX_SAME_DISH_COUNT": "لا يمكن طلب أكثر من {{max}} {{target}}",
            "ORDER_CHANGED_AFTER_HANDOFF": "نلفت انتباهكم!\nقد تم إرسال الطلبية من المطعم فعلاً، لم يتم حفظ التعديلات الجديدة.\nيرجى الاتصال بالمطعم لإجراء التعديل: {{phone}}",
            "PLACE-MAP-MARKER": "يرجى وضع الخارطة في أقرب مكان ممكن من العنوان",
            "LOYALTY_BENEFIT_TIMEOUT_ERROR": "مرحبًا، لقد مر بعض الوقت...\nسنحتاج للاستفادة من امتيازاتك مجددًا",
            "LOYALTY_BENEFIT_HANDOFF_ERROR": "لا يمكن الدفع بالبطاقة المنتهية بالأرقام {{num}} في هذه الطلبية.\nللمزيد من المعلومات يرجى التوجه إلى المطعم",
            "LOYALTY_BENEFIT_CONFLICT_ERROR": "אופס..\nيبدو أن هناك مشكلة أو أن الامتياز قد تمت الاستفادة منها فعلاً.\nيرجى تكرار المحاولة",
            "CARD_VALIDATION_MISMATCH": "Card validation mismatch",
            "CARD_VALIDATION_ACTION_NEEDED": "Needed additional action",
            "ORDER_ITEMS_VALIDATION_ERROR": "لا يمكن طلب البنود المحددة.\nيرجى الاتصال بالمطعم لمتابعة الطلبية",
            "SIGNIN_SISABLED": "Sign in/Registration is temporarily disabled. you can still place orders as usual by entering your contact info.",
            "CC_PAYMENT_OVERFLOW": "لا يمكن دفع أكثر من {{amount}} شيكل بنفس البطاقة. لمتابعة الدفع في المحطة المادية يرجى التوجه إلى الفريق.",
            "HOTEL_NO_GUEST_WITH_PHONE": "لم يتم العثور على رقم هاتف متصل بالغرفة",
            "HOTEL_ROOM_NOT_FOUND": "لم يتم العثور على غرفة",
            "HOTEL_NOT_ELIGIBLE": "لا يوجد إذن بالخصم",
            "HOTEL_REPORT_TO_DESK": "يرجى التوجه إلى الاستقبال",
            "HOTEL_NO_GUEST_FOUND": "لم يتم العثور على ضيوف",
            "SELECT_MAP_LOCATION": "تحديد الموقع على الخارطة",
        },
        "EX_PAYMENT": {
            "dialog_title": "الموافقة على الصفقة",
            "description": "هذه الصفقة تُستخدم باعتبارها موافقة على الصفقة للدفع على الموقع. سيتم الدفع بالفعل فقط بعد استكمال العملية على الصفحة الرئيسية للمصلحة التجارية. يجب أولاً تأكيد صحة جميع التفاصيل الموجودة في هذه الصفحة وتعبئة تفاصيل الائتمان كما هو مطلوب.",
            "popup-title": "تم تأكيد تنفيذ الصفقة",
            "form-title": "تفاصيل بطاقة الائتمان",
            "popup-description": "الدفع فعلاً سيتم تنفيذه بعد استكمال المعاملة",
            "submit-btn": "المتابعة",
            "clear": "إعادة الضبط",
            "credit_card_number": "رقم البطاقة",
            "expiration": "صلاحية البطاقة",
            "cancel": "الإلغاء",
            "currency": "العملة:",
            "transaction_details": "تفاصيل الصفقة",
            "transaction_acknowledge_ord": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_gcs": "By clicking on the “Pay” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_rsv": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "total_amount": "المبلغ",
            "month": "الشهر",
            "year": "العام",
            "click_here": "اضغط هنا للانتقال",
        },
        "expayTitle": "تفاصيل البطاقة تم استقبالها بنجاح",
        "NATIVE_PAYMENT": {
            "error_dialog_title": "لا يمكن معالجة طلبك حاليًا.",
            "error_dialog_description": "حدد وسيلة دفع أخرى",
            "error_dialog_no_amount_description": "للدفع من خلال {{type}} Pay ينبغي الضغط على X على سطر الدفع وتكرار المحاولة",
        },
        "SERVER_MESSAGES": {
            "ORDER_CLOSED_PAYED": "لم يتم العثور على الطلبية، ربما قد تم الدفع نظيرها وإغلاقها بالفعل.",
            "OrderLockedByAnotherUser": "فشل الدفع. مشغول من قبل مستخدم آخر",
            "INVALID_USER_ORPASS": "اسم المستخدم أو كلمة المرور غير صحيحين",
            "UPDATE_ACCOUNT_ERROR": "لم تستطع تحديث حسابك",
            "EMAIL_NOT_FOUND": "Email غير موجود على النظام",
            "EMAIL_IN_USE": "Email موجود على النظام! يرجى تحديد Email مختلف",
            "INVALID_ORDER_ID": "لم يتم العثور على الصفقة المطلوبة",
            "INVALID_QR": "لم يتم العثور على الصفقة المطلوبة",
            "FIREBASE_DOWN": "الخدمة غير متاحة",
            "SHVA_OFFLINE": "يبدو أن هناك مشكلة مع الخصومات الائتمانية حاليًا.\nنوصي بالدفع بوسيلة دفع مختلفة، أو إذا الجوع لم يكن يزعجنا كثيرًا، تكرار المحاولة بعد بضع دقائق.",
            "PAYMENT_QUOTA_EXCEEDED": "لا يوجد هناك رصيد يكفي على البطاقة({{v}})",
            "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "The amount is higher than the credit limit set for the room. Please go to the reception or pay with another payment method.",
            "CREDIT_GUARD_EX_ERROR": "وقع خطأ في معالجة الطلب، يرجى تكرار المحاولة.",
            "EXTERNAL_PROVIDER_ERROR": "الدفع بواسطة {{provider}} فشل!",
            "SMS_PHONE_ERROR": "لا يمكن إرسال رسالة نصية إلى الرقم الذي تم إدخاله {{phone}}.\nيرجى الاتصال بالمطعم",
            "MISSING_CHAIN": "لم يتم العثور على شبكة",
            "CHAIN_DISABLED": "شبكة غير نشطة",
            "NO_SITES_IN_CHAIN": "لم يتم العثور على مواقع عبر الشبكة",
            "NO_ACTIVE_SITES": "لم يتم العثور على مواقع نشطة"
        },
        "CREDIT_GUARD": {
            "ERROR_CODE_001": "البطاقة محجوبة، يرجى الاتصال بشركة الائتمان (الرمز 001).",
            "ERROR_CODE_002": "البطاقة مسروقة، يرجى الاتصال بشركة الائتمان (الرمز 002).",
            "ERROR_CODE_003": "وقع خطأ، يرجى الاتصال بشركة الائتمان (الرمز 003).",
            "ERROR_CODE_004": "هناك رفض من شركة الائتمان، الصفقة لم يتم اعتمادها (الرمز 004).",
            "ERROR_CODE_006": "بطاقة الهوية او ثلاثة الأرقام على ظهر البطاقة غير صحيحة، يرجى تكرار المحاولة (الرمز 006).",
            "ERROR_CODE_010": "الصفقة غير معتمدة للتنفيذ، يرجى التحقق الزائد. ينبغي الاتصال بشركة الائتمان (الرمز 010).",
            "ERROR_CODE_033": "رقم البطاقة الذي تم إدخاله غير صحيح، يرجى تكرار المحاولة (الرمز 033).",
            "ERROR_CODE_036": "البطاقة منتهية الصلاحية (الرمز 036).",
            "ERROR_CODE_039": "رقم البطاقة الذي تم إدخاله غير صحيح، يرجى تكرار المحاولة (الرمز 039).",
            "ERROR_CODE_173": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 173).",
            "ERROR_CODE_200": "لن نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 200).",
            "ERROR_CODE_316": "فشل واجهة خارجية. يرجى الاتصال بشركة الائتمان (الرمز 316).",
            "ERROR_CODE_358": "لم يتم العثور على تفاصيل حساسة على قاعدة البيانات (الرمز 358).",
            "ERROR_CODE_401": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 401).",
            "ERROR_CODE_405": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 405).",
            "ERROR_CODE_414": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 414).",
            "ERROR_CODE_520": "لا يوجد هناك إذن بإنجاز الصفقة، يرجى الاتصال بشركة الائتمان (الرمز 520).",
            "ERROR_CODE_599": "الخدمة الرمزية لدى الجهة المصدرة لبطاقة الائتمان غير متاحة، يرجى الاتصال بشركة الائتمان (الرمز 599).",
            "ERROR_CODE_695": "بطاقة الائتمان من هذا النوع غير مدعومة، يرجى الدفع ببطاقة أخرى (الرمز 695).",
            "ERROR_CODE_900": "تم حجب الصفقة عقب عدم الالتزام بصفقة آمنة (الرمز 900).",
        },
        "PMS": {
            "Stripe": "Stripe",
            "Braintree": "Braintree",
            "Vantiv": "Vantiv",
            "HeartLand": "HeartLand",
            "CardPoint": "CardPoint",
            "AuthorizeNet": "AuthorizeNet",
            "reminder": "المبلغ المتبقي للدفع",
            "remainder": "المبلغ المتبقي للدفع",
            "remainder_wallet_option": "الرصيد",
            "prepaidBalance": "بطاقة قابلة للشحن",
            "points": "النقاط",
            "creditCard": "الائتمان",
            "Cibus": "سيبوس",
            "10bis": "تِن بيس",
            "CreditGuard": "كريديت غارد",
            "cash": "أموال نقدية",
            "TavHazahav": "الشارة الذهبية",
            "cheque": "حوالة",
            "payLeumi": "باي لئومي",
            "meshulam": "Bit",
            "giftCard": "بطاقة قابلة للشحن",
            "ChargeAccount": "الخصم من الحساب",
            "ChargeAccountPaymentProvider": "الخصم من الحساب",
            "OperaPaymentProvider": "تكبيد الغرفة الرسوم"
        },
        "RANKS": {
            "0": "زبون",
            "1": "ثابت",
            "2": "ضيف شرف",
            "3": "فودي",
            "4": "بطل الطلبيات",
            "5": "VIP",
            "6": "عائش من أجل الأكل!",
            "7": "زائر مطاعم"
        },
        "ALLERGIES": {
            "gluten": "غلوتن",
            "eggs": "بيض",
            "peanuts": "فول سوداني",
            "nuts": "مكسرات",
            "sesame": "السمسم",
            "lactose": "لاكتوز",
            "spicy": "حار",
            "fish": "أسماك"
        },
        "PO": {
            "TABLE_BILL": "فاتورة للطاولة",
            "WAITER": "النادل",
            "ORDER_NO": "رقم الطلبية",
            "ORDERER_NAME": "اسم الجهة الطالبة",
            "ORDERER_PHONE": "الهاتف",
            "ORDER_TO": "الإرسال إلى العنوان",
            "OTH": "OTH",
            "ITEM_DISCOUNT": "הנחת פריט",
            "TICKET_DISCOUNT": "خصم الحساب",
            "TICKET_OTH": "نتحمل كل شيء",
            "CHANGE": "إكرامية\\فائض",
            "WITHOUT": "بدون",
            "ORDER_TOTAL": "إجمالي مبلغ الطلبية",
            "ORDER_TIP": "الإكرامية",
            "PAID": "تم الدفع",
            "REMAINED_PAY": "المبلغ المتبقي للدفع"
        },
        "DAYS": {
            "sunday": "الأحد",
            "monday": "الاثنين",
            "tuesday": "الثلاثاء",
            "wednesday": "الأربعاء",
            "thursday": "الخميس",
            "friday": "الجمعة",
            "saturday": "السبت"
        },
        "VALIDATIONS": {
            "ה": "رقم البطاقة غير صالح",
            "CREDIT_CARD_EXPIRATION": "السريان غير صالح",
            "INVALID_ID_NUNBER": "بطاقة الهوية غير صالحة"
        },
        "occasions": {
            "occasions": "مناسبات",
            "occasion": "مناسبة",
            "event": "فعالية",
            "online_event": "إشعار",
            "holiday": "חג",
            "all_day": "طول النهار",
            "start": "البدء",
            "end": "النهاية",
            "time_from": "من",
            "time_until": "إلى",
            "events": "الفعاليات",
            "hang_out_type": "نوع الترفيه",
            "from_day": "من يوم",
            "every_week": "كل أسبوع",
            "every_two_weeks": "مرة كل أسبوعين",
            "every_month": "مرة شهريًا",
            "on_days": "في أيام",
            "on_day": "في يوم",
            "starts_soon": "يبدأ قريبًا",
            "now": "الآن",
            "until": "حتى",
            "more_info": "المزيد",
            "confirm": "مغلق",
            "today_all_day": "اليوم، طول النهار",
            "dates": {
                "events_for_today": "الآن - فعاليات اليوم",
                "tomorrow": "يوم غد",
                "during_the_week": "في وقت لاحق من الأسبوع",
                "next_week": "الأسبوع القادم",
                "upcoming_month": "في الشهر القادم"
            },
            "tags": {
                "everything": "كل شيء"
            }
        },
        "marketplace": {
            "results_page_title": "Tabit Gift It",
            "results_page_description": "تريدون تدليل الأشخاص الذين تحبونهم بوجبة لذيذة؟</br> لقد وصلتم إلى المكان الصحيح!",
            "no_results_found": "لم يتم العثور على نتائج",
            "button_text": "طلب غيفت كارد",
            "search_placeholder": "البحث عن مطعم",
            "more_information": "المزيد من المعلومات",
            "search_input": "البحث حسب المصلحة التجارية/المدينة",
        },
        "site_details": {
            "tab_label_overview": "معلومات عامة",
            "tab_label_reviews": "الرأي",
            "tab_label_map": "الخارطة",
            "tab_label_history": "طلبياتي",
            "tab_label_menu": "قوائم الطعام"
        },
        "menus": {
            "morning menu": "قائمة طعام الإفطار",
            "noon menu": "قائمة طعام الغداء",
            "evening menu": "قائمة طعام العشاء",
            "night menu": "قائمة طعام الليلة"
        },
        "_CODES": {
            "409012": "لم يمكن تجاوز المبلغ المتبقي للدفع",
            "409023": "الدفع فشل. ينبغي المحاولة مرة أخرى أو استبدال طريقة الدفع",
            "409028": "هناك وثائق لم يتم إنتاجها",
            "409030": "كلمة مرور أقصر من اللازم",
            "409036": "نتعامل مع الدفع أولاً، يرجى المحاولة مرة أخرى",
            "409039": "هناك بنود غير مكتملة",
            "409040": "عنصر PARAM غير متوفر",
            "409073": "يبدو أن الدفع تأخر. يرجى تكرار المحاولة.",
            "409091": "عفوًا.. يبدو أن هناك مشكلة. يرجى الاتصال بالمطعم لإكمال الطلب (الهاتف)",
            "409092": "عفوًا.. يبدو أن هناك مشكلة. يرجى الاتصال بالمطعم لإكمال الطلب (الهاتف)",
            "409093": "يبدو أن الدفع تأخر. يرجى تكرار المحاولة.",
            "409098": "خدمة الدفع غير نشطة. يرجى المحاولة مرة أخرى لاحقا أو تغيير وسيلة الدفع.",
            "409110": "إرسال الـ SMS إلى رقم [PARAM1] فشل, يرجى تكرار المحاولة",
            "409136": "تم إنشاء الطلب وإرساله إلى المطعم",
            "409806": "العلامة التجارية الائتمانية غير مدعومة في هذا المطعم",
            "409808": "انتهت صلاحية وسيلة الدفع",
            "409809": "نوصي بالتأكد من صحة جميع التفاصيل",
            "409810": "نوصي بالتأكد من صحة جميع التفاصيل"
        },
        "areas_search_title": "المناطق",
        "areas": {
            "haifa": "حيفا ومحيطها",
            "north": "المنطقة الشمالية",
            "center": "المنطقة الوسطى",
            "tel_aviv": "تل أبيب يافا ومحيطها",
            "jerusalem": "أورشليم القدس ومحيطها",
            "south": "المنطقة الجنوبية",
            "dallas": "Dallas",
            "fort_worth": "Fort Worth",
            "irving_grapevine": "Irving & Grapevine",
            "burleson": "Burleson",
            "plano": "Plano",
            "frisco": "Frisco",
            "mckinney": "McKinney",
            "sydney": "Sydney",
        },
        // Needed for dynamic language change
        "Haifa area": "حيفا ومحيطها",
        "North": "المنطقة الشمالية",
        "Center": "المنطقة الوسطى",
        "Tel Aviv-Jaffa area": "تل أبيب يافا ومحيطها",
        "Jerusalem area": "أورشليم القدس ومحيطها",
        "SLIP": {
            "OTH": "OTH",
            "ITEM_DISCOUNT": "הנחת פריט",
            "TICKET_DISCOUNT": "خصم الحساب",
            "TICKET_OTH": "نتحمل كل شيء",
            "CHANGE": "إكرامية\\فائض"
        },
        "GROUP_VALIDATION": {
            "SELECT_ONE": "حددوا بندًا واحدًا",
            "SELECT_SOME": "حددوا {{min}} بنود",
            "SELECT_ATLEAST_ONE": "حددوا على الأقل بندًا واحدًا",
            "SELECT_ATLEAST_SOME": "حددوا على الأقل {{min}} بنود",
            "SELECT_UPTO_ONE": "حددوا ما يصل إلى بند واحد",
            "SELECT_UPTO_SOME": "حددوا ما يصل إلى {{max}} بنود",
            "SELECT_BETWEEN": "حددوا بين {{min}} و {{max}} بنود",
            "GROUP_EXCEPTION": {
                "TITLE": "مرحبًا، طرأت تحديثات لدينا",
                "BODY": "منذ المرة الأخيرة التي أجريت طلبية فيها أدخلنا عدة تغييرات على الوجبة، يرجى تحديث الوجبة الجديدة بموجب ذلك",
            },
        },
        "ACCESS": {
            "service_selection": "تحديد نوع الخدمة: يرجى تحديد الخدمة المطلوبة",
            "tabit_logo": "شعار تابيت",
            "call": "الاتصال",
            "gift-card-redeem": "صفحة الاستفادة من بطاقة هدية",
            "payment-dialog-open": "نافذة للدفع",
            "login-successfully": "تم استكمال الاتصال بنجاح!",
            "contact-details": "تفاصيل الاتصال بالمطعم",
            "site_service": "خدمات المطعم",
            "address": "العنوان",
            "distance": "المسافة",
            "minus_circle": "مينوس",
            "plus_circle": "بلوس",
            "accessibility_statement": "تصريح إمكانية الوصول. الموقع يدعم قارئ الشاشة من طراز إن في دي أي NVDA",
            "selected": "البند المحدد",
            "deselected": "تمت إزالة البند",
            "ITEM_ADDED_PLUS": "تمت إضافة بند مشابه",
            "unselected": "لم يتم تحديده",
            "same-page-link": "رابط داخلي على قائمة الطعام",
            "BASKET": "سلة بنود",
            "right-arrow-nav": "سهم التنقل الأيمن",
            "left-arrow-nav": "سهم التنقل الأيسر",
            "restaurant": "مطعم",
            "ACCESSIBILITY_DECLARATION": "تصريح إمكانية الوصول",
            "ACCESSIBILITY": "لائحة طعام يسهل الوصول إليها",
            "ACCESSIBILITY_STATEMENT": "تصريح إمكانية الوصول",
            "ACCESSIBILITY_SITE": "انتقل إلى الموقع الذي يمكن الوصول إليه",
            "ACCESSIBILITY_IN_PROGRESS": "الموقع يمر بعملية إتاحة الوصول السهل إليه",
            "CLEAR_ACCESSIBILITY_FEATURES": "امسح خيارات الوصول السهل",
            "monochrom": "مونوخروم",
            "light-contrast": "التباين المضيء",
            "dark-contrast": "التباين الداكن",
            "enlarge-font": "تكبير الخط",
            "reduce-font": "تصغير الخط",
            "readable-font": "الخط المقروء",
            "big-white-curser": "علامة كبيرة بيضاء",
            "big-black-curser": "علامة كبيرة سوداء",
            "enlarge-frame": "التكبير",
            "highlight-links": "إبراز الروابط",
            "highlight-headers": "إبراز العناوين",
            "image-info": "وصف للصور",
            "site-main-image": "صورة المطعم الرئيسي",
            "back-button": "اضغط للعودة إلى الصفحة السابقة",
            "enter_amount": "مبلغ الهدية ({{min_amount}} حتى {{max_amount}})",
            "delete": "احذف",
            "view-menu": "مشاهدة قائمة الطعام",
            "add": "الإضافة",
            "remove": "الإزالة",
            "edit": "التحرير",
            "day": "حدد اليوم",
            "time": "حدد الوقت",
            "guests": "حدد عدد الضيوف",
            "language_screen_reader": "حدد اللغة",
            "future-reservation-time": "متى يتم الوصول؟",
            "close": "مغلق",
            "close_dialog": "أغلق الحوار",
            "delete_content": "احذف محتوى الخلية",
            "out_of_stock": ":هناك نقص حاليًا في المخزون",
            "press_to_approve_and_continue_to_menu": "اضغط للموافقة وتابع إلى صفحة لائحة الطعام",
            "service_buttons_takeaway": "الاستلام الذاتي",
            "service_buttons_delivery": "الإرسالية",
            "service_buttons_delay": "الطلبية ليوم آخر",
            "service_buttons_eatin": "اطلب واجلس",
            "service_buttons_future_takeaway": "الاستلام الذاتي في طلبية مستقبلية",
            "service_buttons_future_delivery": "إرسال طلبية مستقبلية",
            "item_added": "بند واحد أضيف إلى السلة",
            "selection_confirmed": "تمت الموافقة على الاختيار",
            "contrast-button": "التباين",
            "invalid-form": "لم تتم تعبئة كافة الحقول الإلزامية، يرجى تعبئة الحقول اللازمة",
            "remove-payment_line": "إزالة سطر الدفع",
        },
        "ADDRESS_TYPES": {
            "apartment": "الشقة",
            "office": "المكتب",
            "house": "منزل خاص"
        },
        "OFFER_BADGES": {
            "new": "جديد",
            "sale": "حملة"
        },
        "WEB_HEADER": {
            "table_booking": "حجز مكان",
            "order_delivery": "الإرسال / TA",
            "restaurants": "المطاعم"
        },
        "_LEUMIPAY": {
            "payment_error": "الدفع بواسطة PAY. فشل!\nيرجى تكرار المحاولة",
            "min_order_amount_error": "للدفع بـ PAY يجب أن يتجاوز مبلغ بنود الطلبية 50 شيكل",
            "contrat": "للائحة PAY الداخلية..."
        },
        "_LOYALTY": {
            "id_number_already_exist": "رقم الهوية هذا مستعمل فعلاً يرجى طباعة رقم هوية أخرى.",
            "registration_error_title": "تسجيل الدخول للنادي",
            "order_types": "ساري المفعول في {{types}} فقط",
            "cancel_benefits": "إلغاء الخصومات",
            "card_balance": "بطاقة قابلة للشحن",
            "money_compensation": "تعويض مالي",
            "connect": "الاتصال",
            "connect_to_tabit": "الاتصال بـ Tabit",
            "connect_info": "رقم الهاتف أو رقم البطاقة",
            "expires": "ساري المفعول حتى",
            "greeting": "مرحبًا {{name}}!",
            "greeting_voucher": "مرحبًا!",
            "hey": "مرحبًا!",
            "member_not_found_title": "لم نعثر عليك",
            "general_error_title": "Just a moment",
            "renew_is_forbidden_title": "عضويتك لدى النادي انتهت",
            "renew_is_forbidden_text": "لمواصلة الاستفادة من امتيازات النادي، ينبغي تجديد العضوية لمزيد من التفاصيل، يرجى الاتصال بالمطعم.",
            "comps_extra_text": "تمت إضافة التعويض للطلبية ويمكن الاستفادة منه.",
            "good_to_see_you_title": "يسرنا مشاهدتك",
            "member_not_found": "رقم هاتفك غير وارد على قائمة أعضاء نادينا",
            "member_not_found_PRIMARY_BUTTON_TEXT": "التأكيد",
            "customer_mustComplete_the_registration": "من أجل إضافة عضو النادي للطلبية، ينبغي بداية تعبئة استمارة التسجيل المرسلة برسالة نصية أو عبر تطبيقنا",
            "of": "من",
            "points_balance": "رصيد النقاط",
            "points_balance_cap": "لديك {{x}} نقاط للاستفادة الفورية",
            "punch_card_offer": "تبقى {{from}} من {{to}}",
            "review_benefits": "ملخص الخصومات",
            "review_points": "استبدال النقاط",
            "use_points": "النقاط",
            "signout": "سجل الخروج",
            "skip": "تخطَ",
            "no-benefits": "لم تتم الاستفادة من الامتيازات",
            "title": "أعضاء نادي {{name}}؟",
            "title_voucher": "مرحبًا، لديك قسيمة؟",
            "title_club": "مرحبًا، لديك نادي أو قسيمة؟",
            "voucher": "قسيمة",
            "max_redeemable_points": "الحد الأقصى من النقاط للاستفادة",
            "my_benefits": "امتيازاتي",
            "benefits_use": "ستتم الاستفادة في هذه الصفقة",
            "redeem_points_prompt_1": "نقاطك تساوي المال!",
            "redeem_points_prompt_2": "الحد الأقصى من النقاط التي يمكن الاستفادة منها: {{points}}",
            "redeem_points_after": "لقد وفرت {{points}} من خلال الاستفادة من النقاط",
            "voucher_gtreeting": "مرحبًا، لديك قسيمة؟",
            "voucher_inline_gtreeting": "لديك قسيمة؟",
            "voucher_benefits_title": "امتيازاتي",
            "voucher_placeholder": "رقم القسيمة",
            "club_gtreeting": "تعالوا نتحقق من وجود امتيازات لدينا أو العضوية في النادي",
            "club_inline_gtreeting": "اطبعوا رمز القسيمة أو عضو النادي",
            "club_inline_gtreeting_voucher_only": "لديك قسيمة؟",
            "club_benefits_title": "امتيازاتي",
            "club_placeholder": "الهاتف/ رقم القسيمة",
            "select_club": "حددوا النادي",
            "benefits_title": "امتيازاتي",
            "no_benefits_title": "يبدو أن ليست امتيازات متاحة حاليًا",
            "register": "تريد الانضمام إلى النادي؟ للتسجيل",
            "to_promotion_disclaimer": "الخصم النهائي سيتم حسابه قبل الدفع",
            "benefit_types": "صالح لدى {{val}} فقط",
            "auth_title": "مطلوب رمز تحقق",
            "auth_sub_title": "رمز التحقق تم إرساله إلى الهاتف",
            "auth_code": "رمز التحقق",
            "auth_resend": "أرسلوا لي مجددًا",
            "for_your_disposal": "لديك",
            "gift-card": "لديك بطاقة قابلة للشحن أو بطاقة هدية؟",
            "add-gift-card": "إدخال بطاقة أخرى",
            "wallet-item-needed": "شيء بسيط آخر",
            "add-wallet-item": "قبل المتابعة، ينبغي إدخال بطاقة ائتمان",
            "move-to-my-wallet": "الانتقال إلى محفظتي",
            "registration_terms": "التسجيل هو أيضًا موافقة على تلقي رسائل SMS أحيانًا بشأن الامتيازات والحملات المربحة. يمكن تسجيل الخروج من النشرة الإخبارية في كل لحظة",
            "regCostMessage": "الانضمام إلى النادي منوط بدفع {{currency}}{{price}} ستضاف إلى سلة البنود في طلبيتك",
            "confirmDeleteOfferJoin": "إلغاء الانضمام إلى النادي؟",
            "confirmDeleteOfferRenewal": "إلغاء تجديد العضوية في النادي؟",
            "confirmDeleteOfferJoinDescription": "إلغاء الانضمام إلى النادي سيمنع الحصول على الامتيازات",
            "confirmDeleteOfferRenewalDescription": "إلغاء تجديد النادي سيمنع الحصول على الامتيازات",
            "removeOfferJoinButton": "إلغاء الانضمام",
            "removeOfferRenewalButton": "إلغاء التجديد",
            "keepOfferJoinButton": "المتابعة من نفس المكان",
            "keepOfferRenewalButton": "المتابعة من نفس المكان",
            "confirmExpiredSoonDeleteOffer": "إلغاء تجديد النادي؟",
            "removeExpiredSoonOfferButton": "إلغاء التجديد",
            "keepExpiredSoonOfferButton": "المتابعة من نفس المكان",
            "regTitle": "أعضاء النادي يستفيدون أكثر!",
            "regCancel": "الإلغاء",
            "regDetailsDescription": "لم نعثر على رقم هاتفك {{phone}} في قائمة أعضائنا. لقد حان الوقت للانضمام والاستفادة من الامتيازات!\n",
            "continueReg": "الانضمام إلى النادي",
            "regRenewMessage": "تجديد النادي تبلغ تكلفته {{currency}}{{price}}, والتي ستضاف إلى سلة البنود في طلبيتك",
            "regPromptTitle": "لم نعثر على الرقم {{phone}} في نادينا.\nلقد حان الوقت للانضمام!",
            "regPromptPrice": "الانضمام إلى النادي يكلف {{currency}}{{price}}",
            "regRenewPromptPrice": "تجديد النادي يكلف {{currency}}{{price}}",
            "regRenewTitle": "الوقت يطير عندما نستمتع بالطعام!",
            "regRenewBody": "لقد حان الوقت لتجديد العضوية في نادينا\n",
            "regSoonExpiredRenewBody": "صلاحية العضوية في النادي انتهت في {{date}}.\nمن أجل الاستمرار في الاستفادة من امتيازات النادي، يمكن تجديد العضوية الآن حتى!",
            "regSoonExpiredSignOut": "صلاحية العضوية في النادي انتهت في {{date}}.",
            "regUpdateNessage": "يرجى التأكد من صحة التفاصيل التي تم إدخالها",
            "registerButton": "الانضمام",
            "continueWithoutRenewing": "المتابعة بدون تجديد",
            "continueWithoutReg": "المتابعة بدون انضمام",
            "renewMembership": "تجديد النادي",
            "phoneNumber": "رقم الجوال",
            "textForConfirmSms": "إنني أسمح للنادي بإرسال مواد ترويجية إليّ بين الفينة والأخرى من خلال الرسائل النصية. سأستطيع إزالة نفسي من النشرة الإخبارية في كل وقت.",
            "textForConfirmSmsScreenReader": "إنني آذن للنادي بإرسال مواد ترويجية إليّ بين الفينة والأخرى من خلال الرسائل النصية، على أن أستطيع إزالة نفسي من القائمة البريدية في كل وقت.",
            "textForConfirmClubRules": "إنني أوافق على اللائحة الداخلية للنادي",
            "textForConfirmClubRulesScreenReader": "إنني أوافق على لائحة النادي الداخلية",
            "termsContinue": "إلى الأمام للامتيازات",
            "termsFirstContinue": "استكمال التفاصيل",
            "termsBody": "يبدو أنك لم تستكمل بعدُ التسجيل في النادي \n عدة تفاصيل أخيرة لتحصل على الامتيازات :)",
            "termsTitle": "مرحبًا {{name}}",
            "first_name": "الاسم الشخصي",
            "last_name": "اسم العائلة",
            "email": "البريد الإلكتروني",
            "zehut": "بطاقة الهوية",
            "phone": "الجوال",
            "anniversary": "تاريخ الزواج",
            "birth_date": "تاريخ الولادة",
            "regErrorTitle": "حدث خطأ ما",
            "email_not_unique": "هذا البريد الإلكتروني مستعمل فعلاً، يرجى طباعة عنوان بريد إلكتروني آخر",
            "regError": "تعذر استكمال عملية الانضمام، يرجى الاتصال بالمطعم",
            "no_registration_items": "لم يتم العثور على بند تجديد/انضمام منشور على قائمة الطعام",
            "continueErrorTitle": " مرحبًا ",
            "continueErrorText": "من أجل الاستفادة من امتيازات النادي ينبغي إضافة بنود إلى السلة",
            "understood": "فهمت",
            "minimum_order_required_title": "...لحظة",
            "minimum_order_required_body": "بعد حساب الامتيازات، المبلغ للدفع هو {{currency}}{{total}} وهو أقل من مبلغ الحد الأدنى للطلبية -  {{currency}}{{amount}}",
            "minimum_order_required_button": "العودة إلى قائمة الطعام",
            "get_benefits_401_error": "Your session has expired. Please log in again to the Loyalty club to continue using the system."
        },
        "validation_errors": {
            "required": "حقل إلزامي",
            "max_length": " قيد الرموز 256",
            "invalid_email": "عنوان البريد الإلكتروني غير صالح",
            "invalid_phone": "رقم الهاتف غير صالح",
            "load_range": "يرجى إدخال مبلغ في النطاق",
            "no_decimal": "يرجى إدخال مبلغ كامل",
            "only_5_digits": "الحقل مقيد لـ 5 رموز",
            "min_2_digits": "الحقل يتطلب على الأقل رمزين",
            "only_numbers": "الحقل مقيد للأرقام فقط",
            "only_letters": "الحقل مقيد للأحرف فقط",
            "invalid_zip_code": "الرمز البريدي غير صالح",
        },
        "_WEIGHT": {
            "units": "الوحدة",
            "price_per_weight": "الثمن لـ {{unit}} = {{price}}",
            "item_weight": "وزن البند",
            "kg": "كلغ (kg)",
            "gr": "غرام (gr)",
            "lb": "رطل (lb)",
            "oz": "للأونصة (oz)",
            "l": "لتر (l)",
            "ml": "ميليغرام (ml)",
            "GRAM": "غرام"
        },
        "_GIFTCARD": {
            "title": "لديك بطاقة قابلة للشحن أو بطاقة هدية قابلة للاستفادة؟",
            "gift_card": "بطاقة هدية",
            "select_card": "حددوا البطاقة",
            "please_select_card": "يرجى تحديد البطاقة",
            "edit_card": "تحديث البطاقة",
            "add_card": "التأكيد",
            "number": "رقم البطاقة",
            "amount": "المبلغ للدفع",
            "balance": "الرصيد في البطاقة",
            "max": "الحد الأقصى للاستفادة في هذه الطلبية",
            "cvv": "CVV",
            "cvv_required": "يجب تعبئة CVV",
            "invalid_cvv": "رقم الـ CVV الذي تم إدخاله خاطئ",
            "inuse_error": "لا يمكن الاستفادة من نفس البطاقة أكثر من مرة واحدة في الطلبية",
            "balance_error": "لا يوجد هناك رصيد في البطاقة",
            "notfound_error": "تفاصيل البطاقة خاطئة أو البطاقة غير واردة على النظام",
            "delete_prompt": "إزالة البطاقة من الطلبية؟",
            "no_order_amount": "الدفعات تغطي الطلبية بالفعل، لا حاجة لدفع إضافي 😎",
            "reset_split_payments_prompt": "إضافة أو تحديث البطاقة سيبدأ فصل المدفوعات! المتابعة؟",
            "please_finish_payment": "اضغطوا ״الدفع״ للانتهاء",
            "otp_required": "رمز التحقق تم إرساله إلى رقم الهاتف",
            "otp_required_alt": "رمز التحقق تم إرساله إلى رقم الهاتف",
            "otp_required_alt_num": "{{v}}-****-***",
            "otp_alt": "رمز التحقق",
            "invalid_otp": "رمز التحقق الذي تم إدخاله خاطئ",
            "resend_otp": "أرسلوا لي مجددًا",
            "cannotPayOnOnline": "لا يمكن الدفع بهذه البطاقة من خلال الشراء عبر الإنترنت، وإنما من خلال المطعم فقط",
        },
        "_DELAYED": {
            "title": "توقيت الطلبية",
            "service_caption": "لتاريخ آخر",
            "supply_time": "تاريخ التزويد",
            "select_date": "يرجى تحديد تاريخ التزويد",
            "select_slot": "يرجى تحديد وقت التزويد",
            "service_today": "طلبية لليوم",
            "service_future": "طلبية مستقبلية",
            "for_hour": "للساعة {{val}}",
            "for_date": "اليوم {{val}}",
            "between": "بين الساعات",
            "at_hour": "عند الساعة",
            "select_slot_prompt": "ينبغي تحديد وقت التزويد",
            "service_disabled_today": "الخدمة غير متاحة حاليًا!"
        },
        "CHECKOUT_STEP": {
            "extra_offers": "اقتراحات إضافية",
            "contact": "تفاصيلي",
            "gratuity": "الإكرامية",
            "pay": "الدفع",
            "summary": "ملخص",
        },
        "WEB_CONTAINER": {
            "start_logo": "تابيت - الصفحة الرئيسية",
            "language_select": "استبدل لغة الموقع إلى الإنجليزية",
            "home": "الرئيسية",
            "search_now": "ابحث",
            "search": "البحث عن المطاعم، المطابخ، الأساليب أو الأذواق",
            "find_table": "اعثروا لي على طاولة",
            "show_more": "عرض المزيد",
            "service": "الخدمة",
            "back_to_regular_results": "العودة للنتائج العادية",
            "booking_bar": {
                "day": "اليوم",
                "day_screen_reader": "تحديد اليوم",
                "choose_day": "في أي يوم تريدون الترفيه؟",
                "hour": "ساعة واحدة",
                "hour_screen_reader": "تحديد الوقت",
                "choose_hour": "في أي وقت؟",
                "guests": "ضيوف",
                "guests_screen_reader": "تحديد عدد الضيوف ",
                "choose_guests": "كم سيكون عددكم؟",
                "address": "العنوان",
                "category": "الفئة",
                "all_categories": "كافة الفئات",
                "area": "المنطقة",
                "all_areas": "كافة المناطق",
                "tags": "الشارات",
                "tags_screen_reader": "تحديد الوسوم، مثل أسبلوب المطعم، ونوع الطعام وغيرها من الخصائص",
                "clear_tags": "امسح الوسوم",
                "rating": "التصنيف",
                "rating_screen_reader": "اختيار التصنيف",
                "all_ratings": "كافة التصنيفات",
                "price": "السعر",
                "price_screen_reader": "تحديد نطاق السعر",
                "all_prices": "كافة الأسعار"
            },
            "book_a_table": {
                "tab_title": "حجز مكان",
                "main_title": "عندما نشتاق,\nيكون الطعام أحلى",
                "main_subtitle": "فقط قولوا متى، لنرتب لكل الأمور الأخرى",
                "new_restaurants_in_tabit": "مطاعم جديدة لدى Tabit",
                "new_restaurants_in_tabit_screen_reader": "مطاعم جديدة لدى تابيت",
                "kitchens": "مطابخ",
                "cuisine": "مطابخ",
                "restaurants_by_areas": "مطاعم حسب المناطق",
                "near_me": "قريب مني",
                "show_more": "عرض المزيد",
                "results_page_title": "حجز مكان",
                "results_page_description": "حددوا التاريخ، والوقت وعدد الضيوف واضغطوا لإيجاد طاولة من أجلي",
                "no_results_found": "يبدو أنه لا توجد أماكن شاغرة في أي من المطاعم. يرجى اختيار تاريخ أو وقت آخر لنبحث من أجلكم مرة أخرى.",
                "select_future_date": "لقد مر التاريخ المحدد، يرجى التحديد مرة أخرى"
            },
            "deliveries": {
                "tab_title": "الإرساليت و TA",
                "tab_title_screen_reader": "الإرساليات والاستلام الذاتي",
                "main_title": "ماذا تريد تناوله؟",
                "main_subtitle": "فقط قولوا إلى أين، وسنرتب لبقية الأمور",
                "results_page_title": "الإرساليت و TA",
                "results_page_description": "",
                "no_results_found": "يبدو أنه لا توجد حاليًا مطاعم تتيح خدمات الإرساليات في هذا الوقت إلى العنوان الذي حددتموه"
            },
            "restaurants": {
                "tab_title": "المطاعم",
                "main_title": "الاستكشاف، التذوق، الترفيه",
                "main_subtitle": "أكثر الأماكن ربحًا، واعتبارًا وسخونة حاليًا",
                "results_page_title": "المطاعم",
                "results_page_description": "",
                "no_results_found": "عفوًا... لا توجد نتائج لبحثك"
            },
            "marketplace": {
                "tab_title": "Tabit Gift It",
                "main_title": "Tabit Gift It",
                "home_page_section_title": "غيفت كارد لأكثر المطاعم جدوة!",
            },
            "web_article": {
                "bottom_content_button": "للتقرير التالي >>"
            }
        },
        "NOTIFICATIONS_WIDGET": {
            "messages": "الرسائل",
            "clear_all": "امسح كل شيء"
        },
        "DASHBOARD": {
            "we_have_missed_you!": "حجز طاولة يتم هنا!",
            "when_we_would_see_you?": "فقط قولوا متى، وسنرتب لبقية الأمور",
            "all_the_restaurants_click_away?": "فقط قولوا متى، وسنرتب لبقية الأمور",
            "BUTTONS": {
                "delivery": "الإرسال",
                "takeaway": "الاستلام الذاتي",
                "book": "حجز مكان"
            }
        },
        "WEB_HOME": {
            "H1_TITLE": "حجز مكان يتم هنا!",
            "H2_TITLE": "فقط قولوا متى، وفي أي مطعم وسنرتب لبقية الأمور",
        },
        "WEB_BOOK": {
            "H1_TITLE": "حجز طاولة",
            "H2_TITLE": "البحث عن طاولة لدى مطاعم تابيت",
        },
        "KFC": {
            "title": "KFC {{country}}",
            "main_sub_title": "أحلى دجاج في العالم",
            "sec_sub_title": "حضّروا الأصابع!"
        },
        "SHIOU": {
            "title": "شيو! لديك لمس لللسوشي.",
            "sub_title": "أقصر طريق من الخيال إلى اللسان، بالهناء!"
        },
        "BIGA": {
            "title": "BIGA",
            "sub_title": "Bakery & kitchen"
        },
        "FOODBA": {
            "title": "Foodba",
            "sub_title": "تطبيق الإرساليات والترفيه الخاصة ببئر السبع",
            "seo_title": "Foodba | بئر السبع",
        },
        "MAISON": {
            "title": "MAISON KAYSER",
            "sub_title": "ARTISAN BOULANGER, PÂTISSIER, CAFÉ & RESTAURANT"
        },
        "GRECO": {
            "title": "GRECO",
            "sub_title": "حفلة يونانية حقيقية"
        },
        "USER_POINTS": {
            "btw_you_have": "بالمناسبة، لديك بالفعل",
            "btw_you_dont_have": "بالمناسبة، لا توجد لديكم نقاط بعدُ",
            "for_each_order_more_bonus": "مقابل حجز كل طاولة ستحصلون على 10",
            "for_each_order_bonus": "مقابل حجز كل طاولة ستحصلون على 10",
        },
        "GIFT_CARD_SHOP": {
            "tracker": {
                "to_from": "التفاصيل الأولية",
                "when_where": "خيارات الإرسال",
                "checkout": "الدفع"
            },
            "card_recipient": {
                "title": "الجهة المستلمة للهدية"
            },
            "user_wallet": {
                "title": "طريقة الدفع",
                "other": "الدفع بوسيلة أخرى",
            },
            "choose_amount": {
                "required_gift_sum": "المبلغ المطلوب",
                "current_balance": "الرصيد الحالي",
                "you_pay": "نقوم بالشحن",
                "you_get": "نتلقى",
            },
            "create_card": {
                "title": "تفاصيل الهدية",
                "to": "ما هي الجهة المستلمة للهدية؟",
                "from": "ما هي الجهة المرسلة للهدية؟",
                "first_name": "الاسم الشخصي",
                "last_name": "اسم العائلة",
                "send_to_friend": "أرسل الهدية إلى صديق",
                "where_to_send_the_gift": "أين أرسل الهدية؟",
                "your_message": "تهنئة",
                "your_message_placeholder": "عدة كلمات (اختياري)",
                "enter_amount": "مبلغ الهدية ({{min_amount}} - {{max_amount}})",
                "enter_amount_error": "ينبغي إدخال مبلغ حسب النطاق",
                "send_to_phone": "إلى أين يتم إرسال الهدية؟",
                "toPhone": "إلى الهاتف",
                "toEmail": "إلى البريد الإلكتروني",
                "required_gift_sum": "المبلغ المطلوب",
                "required_load_sum": "مبلغ الشحن؟",
                "billing_address": "عنوان لتحصيل الرسوم",
                "state": "الدولة",
                "select_state": "حددوا الدولة",
                "city": "المدينة",
                "address": "العنوان",
                "zip_code": "الرمز البريدي",
                "legalText": "شارة الشراء هذه سارية المفعول لمدة خمس سنوات، بموجب المادة 14(ح) من قانون حماية المستهلك",
                "legalTextProcess": "هذه الصفقة تمنح شارة شراء وهي سارية المفعول لمدة خمس سنوات، بموجب المادة 14(ح) من قانون حماية المستهلك",
                "expirationDate": "This card expires 3 years after supply"
            },
            "delivery_options": {
                "best_send_to": "خيارات الإرسال",
                "toPhone": "الهاتف",
                "toEmail": "البريد الإلكتروني",
                "phone": "رقم الهاتف",
                "email": "عنوان البريد الإلكتروني"
            },
            "checkout": {
                "title": "ختامًا",
                "gift_card_total": "الخلاصة",
                "gift_card": "بطاقة هدية",
                "send_to": "أرسل إلى",
                "to": "مستلم الهدية",
                "from": "مرسل الهدية",
                "message": "تهنئة",
                "send_receipt_to": "إلى أين ينبغي إرسال شهادة الدفع؟",
                "send_all_receipts_to": "إلى أين ينبغي إرسال البطاقة وشهادة الدفع؟",
                "myPhone": "إلى هاتفي",
                "myEmail": "إلى بريدي الإلكتروني",
                "receiptContactPhone": "الهاتف",
                "receiptContactEmail": "البريد الإلكتروني",
                "Email": "البريد الإلكتروني",
                "total": "المبلغ الإجمالي للدفع",
                "approve_notifications": "Notify me When the card is viewd by the recipient.",
                "approve_terms": "قرأت ووافقت على",
                "approve_terms_and": "وعلى",
                "approve_terms_link_1": "شروط الاستخدام",
                "approve_terms_link_2": " سياسة الخصوصية"
            },
            "buttons_texts": {
                "pay": "{{currency}}{{amount}} للدفع",
                "next": "تابع",
                "go_back": "العودة",
                "you_get": "{{currency}}{{amount}} نتلقى",
                "website": "موقع المطعم",
                "recipient_type_me": "لنفسي",
                "recipient_type_not_me": "لشخص آخر"
            },
            "validation_errors": {
                "required": "حقل إلزامي",
                "max_length": " قيد الرموز 256",
                "invalid_email": "عنوان البريد الإلكتروني غير صالح",
                "invalid_phone": "رقم الهاتف غير صالح",
                "load_range": "يرجى إدخال مبلغ في النطاق",
                "no_decimal": "يرجى إدخال مبلغ كامل",
                "only_5_digits": "الحقل مقيد لـ 5 رموز",
                "min_2_digits": "الحقل يتطلب على الأقل رمزين",
                "only_numbers": "الحقل مقيد للأرقام فقط",
                "only_letters": "الحقل مقيد للأحرف فقط",
                "invalid_zip_code": "الرمز البريدي غير صالح",
            },
            "modals": {
                "please_wait": "لحظة من فضلك...",
                "success": {
                    "title": "شكرًا!",
                    "body": "هديتك في طريقها إليك",
                    "primary_button": "مغلق",
                    "secondary_button": "أريد هدية أخرى",
                    "top_up": {
                        "body": "استكمال الشحن",
                        "secondary_button": "شحن آخر",
                    }
                },
                "error": {
                    "title": "حدث خطأ ما",
                    "body": "فشل الدفع",
                    "body_general_error": "وقع خطأ",
                    "body_error_occurred": "وقع خطأ\nيرجى الاتصال بالمطعم عبر الهاتف",
                    "body_validation_mismatch": "وقع خطأ\nينبغي التحقق من تفاصيل الدفع",
                    "body_card_declined": "وقع خطأ\nينبغي التحقق من تفاصيل الدفع",
                    "shop_inactive_error": "الخدمة غير نشطة حاليًا",
                    "card_inactive_error": "خطأ عند تشكبل البطاقة، يرجى الاتصال بالمطعم",
                    "primary_button": "مغلق",
                    "secondary_button": "كرر المحاولة"
                },
                "pay": {
                    "pay_with_cibus": "الدفع من خلال سيبوس",
                    "card_number": "رقم البطاقة"
                }
            },
            "redeem_card": {
                "title": "الرصيد الحالي:",
                "show_qr": "للدفع ينبغي إبراز الـ QR لدى المطعم",
                "body": "رقم البطاقة",
                "cvv": "CVV",
                "recipient_title": "مرحبًا  ",
                "recipient_text": "حصلت على {{amount}}",
                "recipient_from_text": "من {{sender}}"
            },
            "general": {
                "gift_card_purchase": "بطاقة هدية لصديق"
            }
        },
        "FOOTER": {
            "area_link_prefix": "مطاعم لدى",
            "other_links": "المزيد من الروابط",
            "contact": "اتصل بنا",
            "join_us": "انضموا إلينا",
            "to_tabit_site": "إلى موقع تابيت",
            "contact_us": "اتصل بنا",
            "privacy_policy": "سياسة الخصوصية",
            "club_terms": "لائحة النادي الداخلية",
            "terms_of_use": "شروط الاستخدام",
            "career": "مطلوبون",
        },
        "loyalty-terms-dialog": {
            "title": "لدينا نادي زبائن جديد!",
            "descriptionMain": "لقد حضّرنا لكم امتيازات مربحة للغاية",
            "descriptionSecond": "أمران بسيطان لتستطيع الاستفادة الامتيازات:",
            "validation": "متأكد/ة أنك تريد/ين التنازل عن امتيازات النادي؟",
            "added_to_your_cart": "يا للسعادة! حدّثوا الامتياز على البنود في السلة",
            "ok": "الموافقة",
            "remove_reward": "إزالة الامتياز؟",
            "remove_reward_btn_yes": "نعم، الإزالة",
            "remove_reward_btn_no": "لا",
        },
        "loyalty-rewards-dialog": {
            "auto-rewards-title": "We've added these rewards to your order:",
            "my-rewards": "امتيازاتي",
            "all-my-rewards": "للاطلاع على كافة امتيازاتك",
            "all-my-rewards-single": "خذني إلى فائدة",
            "club-rewards-will-be-calculated-before-payment": "سيتم تحديث امتيازات النادي قبل الدفع",
            "back-to-order": "العودة إلى الطلبية",
            "done-thanks": "انتهيت، شكرًا",
            "select_between_range": "تم تحديد {{quantity}} من أصل {{max}}",
            "loyalty_reward_terms_dialog_title": "أنت رائع!",
            "loyalty_reward_terms_dialog": "تم تفعيل الامتياز على أن يتم تحديثه تلقائيًا عند الانتقال للدفع.",
            "loyalty_reward_terms_dialog_ok": "قائمة الطعام",
            "loyalty_reward_terms_dialog_cancel": "الإلغاء",
            "not-available": "الامتياز غير متوفر حاليًا",
        },
        "pickup-order-dialog": {
            "Branch_pickup_order": "طلبية استلام من",
            "address": "عند العنوان",
        },
        "SIGN-UP-DATE-ERROR": "التاريخ الذي تم إدخاله خاطئ",
        "general_credit_card": {
            "form": {
                "card_number": "رقم البطاقة:",
                "expiration": "السريان:",
                "cvv": "CVV:",
                "amount_to_pay": "PAY"
            },
            "errors": {
                "invalid_exp": "يرجى تعبئة كافة الحقول",
                "invalid_cvv": "CVV خاطئ",
            }
        },
        "REMOVE_ACCOUNT": {
            "disclaimer_1": "استكمال العملية مشروط بتحقق ذي مرحلتين من خلال رمز تعرف سيتم إرساله عبر SMS.",
            "disclaimer_2": "سيتم الحذف فورًا",
            "disclaimer_3": "سيتم حفظ الامتيازات والتعويضات المسندة إلى رقم هاتفك أيضًا بعد إزالة الحاب من التطبيق على أن يتسنى الاستفادة منها بالقنوات المختلفة."
        },
        "tou_offer": {
            "required": "إلزامي",
            "additional_options": "المزيد من الخيارات",
            "group_guiding_text": {
                "before_selection": {
                    "choose_at_least": "حددوا على الأقل {{min}}",
                    "choose_at_least_singular": "حددوا واحدًا",
                    "select_at_least": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_at_least_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_at_least_no_max": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_at_least_no_max_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_between_range": "حددوا بين {{min}} و {{max}} بنود",
                    "select_between_range_with_defaults": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_between_range_with_defaults_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_minimum_items": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_minimum_items_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_quantity": "حددوا {{min}}",
                    "select_quantity_singular": "حددوا واحدًا",
                    "select_quantity_singular_without_default": "حددوا واحدًا",
                    "select_up_to": "يرجى تحديد ما يصل إلى {{max}} بنود",
                    "select_up_to_singular": "يمكن تحديد ما يصل إلى بند واحد",
                    "select_up_to_without_defaults": "يرجى تحديد ما يصل إلى {{max}} بنود",
                },
                "during_selection": {
                    "choose_at_least": "حددوا على الأقل {{min}}",
                    "choose_at_least_singular": "حددوا واحدًا على الأقل",
                    "select_at_least": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_at_least_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_at_least_no_max": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_at_least_no_max_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_between_range": "تم تحديد {{quantity}} من أصل {{max}} بنود",
                    "select_between_range_with_defaults": "تم تحديد {{quantity}} من أصل {{max}} بنود",
                    "select_between_range_with_defaults_singular": "تم تحديد {{quantity}} من أصل {{max}} بنود",
                    "select_minimum_items": "الوجبة يجب أن تحتوي على ما لا يقل عن {{min}} بنود أخرى",
                    "select_minimum_items_singular": "الوجبة يجب أن تحتوي على بند آخر على الأقل",
                    "select_quantity": "{{quantity}} تم تحديد",
                    "select_quantity_singular": "تم تحديد واحد",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "تم تحديد {{quantity}} من أصل {{max}} بنود",
                    "select_up_to_singular": "تم تحديد بند واحد",
                    "select_up_to_without_defaults": "يرجى تحديد ما يصل إلى {{max}} بنود",
                },
                "after_selection": {
                    "choose_at_least": "{{quantity}} بنود تم تحديدها",
                    "choose_at_least_singular": "تم تحديد بند واحد",
                    "select_at_least": "",
                    "select_at_least_singular": "",
                    "select_at_least_no_max": "",
                    "select_at_least_no_max_singular": "",
                    "select_between_range": "{{quantity}} بنود تم تحديدها",
                    "select_between_range_with_defaults": "تم تحديد {{quantity}} بنود",
                    "select_between_range_with_defaults_singular": "تم تحديد بند واحد",
                    "select_minimum_items": "",
                    "select_minimum_items_singular": "",
                    "select_quantity": "{{quantity}} تم تحديد",
                    "select_quantity_singular": "تم تحديد واحد",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "{{quantity}} بنود تم تحديدها",
                    "select_up_to_singular": "تم تحديد بند واحد",
                    "select_up_to_without_defaults": "{{quantity}} بنود تم تحديدها",
                }
            },
        },
        "new_restaurant_page": "صفحة المطعم",
    },
    'ru-RU': {
        "number_of_restaurants_found_screen_reader": "Количество найденных рестора-нов,",
        "number_of_addresses_found_screen_reader": "Количество найденных адресов,",
        "number_of_dishes_found_screen_reader": "Количество найденных блюд,",
        "localeCaption": "EN",
        "share_this": "Здравствуйте, кое-что найдено на Табите. Хотите попробовать?",
        "hours": "Часы ",
        "days": "Дни ",
        "tabit": "Табит",
        "minutes": "Минуты ",
        "footer_data": "Бронирование места|  Заказ столика|  Табит | Tabit",
        "terms_of_service": "Условия использования ",
        "privacy_notice": "Политика конфиденциальности ",
        "under_construction_message": "Данная страница находится в разработке ",
        "summary": "Резюме ",
        "service_selection": "Выбор услуги ",
        "future_service_desc": "Выберите услугу, необходимую вам в будущем  ",
        "choose_navigation_option": "Выбор варианта навигации ",
        "choose_option": "Выбор опции ",
        "choose_hotel": "В каком отеле вы остановились?",
        "_and": "ו",
        "KEYPAD_DIALOG_WARNING": "Вы можете вводить до {{maxVal}} {{currency}}",
        "rsv_management": {
            "guests": "Гости ",
            "disclaimer": "По любым вопросам, связанным с вашим заказом, обращайтесь к. ",
            "description": {
                "future_reservation_created": "Здравствуйте, {{name}},\ Ваш заказ успешно со-хранен.\  Ваш столик будет ждать Вас в течение 15 минут с момента, на который оформ-лен  заказ.\  Мы будем рады Вас видеть!",
                "future_reservation_standby_created": "Здравствуйте. Обратите внимание, мы добавили Вас в лист ожидания. Мы свяжемся с Вами, если появится место.",
                "future_reservation_standby_created_pending_approval": "Здравствуйте. Обра-тите внимание, Ваш заказ еще не подтвержден. Для подтверждения Вашего заказа мы свяжемся с Вами как можно скорее.",
                "future_reservation_reminded": "Здравствуйте.  Пожалуйста, подтвердите свой заказ.\ Ваш столик будет зарезервирован за Вами в течение 15 минут с момента, на кото-рый оформлен заказ.\ Мы будем рады Вас видеть!",
                "future_reservation_reminded_free_text": "Пожалуйста, подтвердите свой за-каз.\ Ваш столик будет зарезервирован за Вами в течение 15 минут с момента, на кото-рый оформлен заказ.\ Мы будем рады Вас видеть!",
                "future_reservation_notified": "Ваш столик готов. Добро пожаловать!",
                "future_reservation_checked_in": "Спасибо , что Вы подтвердили свое прибы-тие. Мы сообщим Вам, когда столик будет готов.",
                "future_reservation_deleted": "Ваш заказ отменен.",
                "future_reservation_verified": "Вы подтвердили свое прибытие. Спасибо!",
                "future_reservation_will_be_late": "Спасибо, что предупредили об опозда-нии",
                "future_reservation_undo_late": "Спасибо, что предупредили ",
                "future_reservation_cc_deposit": "Здравствуйте, {{name}}!\ Ваш заказ успешно сохранен.\  Ваш столик будет ждать Вас в течение 15 минут с момента, на который оформлен  заказ.\  Мы будем рады Вас видеть!\ Обратите внимание, с Вас будет снят де-позит при отмене заказа менее чем за {{time_limit_cancel}} до момента, на который оформлен заказ.",
                "future_reservation_cc_deposit_reminded": "Здравствуйте.  Пожалуйста, под-твердите свой заказ.\ Ваш столик будет зарезервирован за Вами в течение 15 минут с момента, на который оформлен заказ.\ Мы будем рады Вас видеть! !\ Обратите внима-ние, с Вас будет снят депозит при отмене заказа менее чем за {{time_limit_cancel}} до мо-мента , на который оформлен заказ.",
                "future_reservation_cc_deposit_notified": "Здравствуйте, {{name}}!\nВаш стол готов. Добро пожаловать!\nОбратите внимание, что с вас будет снята сумма депозита, если вы отмените менее чем {{time_limit_cancel}} с время бронирования.",
                "future_reservation_cc_deposit_checked_in": "Здравствуйте, {{name}}!\nВаше прибытие записано. Мы сообщим вам, когда стол будет готов.\nОбратите внимание, что с вас будет снята сумма депозита, если вы отмените менее чем {{time_limit_cancel }} со времени резервирования.",
                "walked_in_created": "Вы у нас зарегистрированы. Мы сообщим Вам, когда столик будет готов.",
                "walked_in_saved": "Вы у нас зарегистрированы. Мы сообщим Вам, когда сто-лик будет готов.",
                "walked_in_notified": "Ваш столик готов. Добро пожаловать!",
                "walked_in_checked_in": "Спасибо , что Вы подтвердили свое прибытие. Мы сообщим Вам, когда столик будет готов.",
                "called_in_created": "Вы у нас зарегистрированы. Мы сообщим Вам, когда сто-лик будет готов.",
                "called_in_saved": "Вы у нас зарегистрированы. Мы сообщим Вам, когда сто-лик будет готов.",
                "called_in_notified": "Ваш столик готов. Добро пожаловать!"
            },
            "buttons": {
                "confirm_label": "Подтверждение прибытия ",
                "cancel_label": "Отмена заказа ",
                "will_be_late_label": "Мы будем позже ",
                "undo_late_label": "Мы приедем вовремя ",
                "fill_health_statement": "Заполнение декларации о состоянии здоровья ",
                "modify_label": "Обновить порядок"
            },
            "cancel_reservation_dialog": {
                "title": "Отмена заказа ",
                "body": "Вы уверены, что хотите отменить заказ?",
                "confirm": "Отмена заказа ",
                "cancel": "Вернуться "
            },
            "failure_reason": {
                "reservation_not_found": "Заказ не найден ",
                "reservation_in_progress": "Гости уже за столом. Наслаждайтесь!",
                "organization_not_found": "Организация не найдена ",
                "feedback_module_disabled": "К сожалению, у этого ресторана отключена си-стема обратной связи"
            },
            "place_in_queue": "Ваша очередь ",
            "preferences": "Предпочтения ",
            "preference_exception": "Кроме {{preference}}",
            "social": {
                "share": {
                    "whatsapp": {
                        "button_label": "Отправить другу ",
                        "app_message": "Здравствуйте, я забронировал нам место в ",
                        "web_message": ",\ Я забронировал нам место в {{organization}}\n{{link}}"
                    },
                    "share": "Поделиться "
                }
            },
            "notifications": {
                "email": {
                    "unsubscribe": {
                        "success": "Вы были успешно удалены из списка для получения обнов-лений по электронной почте, касающихся этого заказа. ",
                        "error": "Произошла непредвиденная ошибка при удалении обновлений по электронной почте. Пожалуйста, повторите попытку позже или свяжитесь с рестора-ном.."
                    }
                }
            },
            "call_customer": {
                "request": "У меня есть новая информация, свяжитесь со мной. ",
                "request_sent": "Мы скоро свяжемся с Вами ",
                "request_cancel": "( Нажмите  для отмены запроса)"
            },
            "deposit_payment": {
                "qr_code_button": "Показать код QR",
                "qr_code_description": "QR-код необходимо предъявить по прибытии в ресто-ран  ",
                "cancel_reservation_dialog": {
                    "body": "В момент, на который был оформлен заказ,  будет списана стои-мость депозита. Пожалуйста, связавайтесь с рестораном по любым вопросам, связан-ным с этим. \ Отменить заказ?"
                },
                "refund_failed_dialog": {
                    "title": "Заказ не может быть отменен ",
                    "body": "Произошла ошибка в процессе отмены заказа. Пожалуйста, свя-житесь с рестораном. "
                },
                "description": {
                    "cancel_after_time_limit": "Ваш заказ был отменен, депозит не был удер-жан. По любым вопросам по этому поводу обращайтесь в ресторан",
                    "cancel_before_time_limit": "Ваш заказ отменен, , депозит был удержан. ",
                    "no_voucher_balance": "ההזמנה בוטלה.\ Важно отметить: депозит уже ис-пользован, поэтому возврат не возможен. За более подробной информацией обращай-тесь в ресторан."
                }
            },
            "cc_deposit": {
                "cancel_reservation_dialog": {
                    "allow": {
                        "body": "{{time_limit_cancel}} При отмене менее чем за {{time_limit_cancel}} до даты, на которую сделан заказ, будет взиматься депозит.\ По всем вопросам обращайтесь в ресторан.\ Отменить заказ?"
                    },
                    "prevent": {
                        "title": "Заказ не может быть отменен ",
                        "body": "До даты, на которую сделан заказ, осталось меньше {{time_limit_cancel}}.\ Для отмены - свяжитесь с рестораном",
                        "primary_button": "Свяжитесь с рестораном ",
                        "secondary_button ": "Вернуться "
                    }
                },
                "credit_details_dialog": {
                    "form_title": "To verify your card, we will pre-authorize a 1 NIS charge, which will be released by the end of the day",
                    "submit_button": "Continue"
                }
            },
            "hour": "Час ",
            "two_hours": "Два часа "
        },
        "splash_screen": {
            "failure_dialog": {
                "title": "Что-то не получилось ",
                "content": "В системе возникла непредвиденная проблема ",
                "ok_button": "Попробуйте еще раз "
            }
        },
        "booking": {
            "site_name": "Название веб-сайта ",
            "site_address": "Адрес веб-сайта ",
            "disclaimer_description": "Нажимая Сохранить подтверждаю ",
            "disclaimer_description_deposit_payment": "Нажимая на кнопку Подтвержде-ние и оплата, я подтверждаю",
            "disclaimer_description_connection": "ו",
            "terms_of_service": "Условия использования ",
            "privacy_notice": "Политика конфиденциальности ",
            "button_label": {
                "walked_in": "Скоро будет ",
                "future_reservation": "Бронирование "
            },
            "search": {
                "person": "Один гость ",
                "people": "{{value}} гостя / гостей ",
                "larger_party": "+{{value}} гость/  гостя / гостей ",
                "minutes": "{{value}} ",
                "no_results": "Мы не нашли то, что Вы искали,\  Пожалуйста, выберите другую дату или позвоните в ресторан",
                "no_alternatives": "Мы не нашли то, что Вы искали,\  Пожалуйста, выберите другую дату или позвоните в ресторан",
                "no_waiting_list": "Ждать не нужно, проходите. ",
                "waiting_list_full": "Извините, наш лист ожидания заполнен ",
                "results_info": "Мы не нашли именно то, что Вы искали, предлагаем другие варианты. ",
                "call_restaurant_large_party": "Здравствуйте! Кажется, вас действительно мно-го - свяжитесь с нами по телефону и мы будем рады зарезервировать для вас столик. ",
                "call_restaurant_error": "К сожалению, в ресторане нет свободных мест. Пожа-луйста, позвоните нам. ",
                "booking_window_error": "Извините, в настоящее время невозможно запи-саться в очередь ",
                "today": "Сегодня ",
                "first_available": "Без предпочтений ",
                "choose_preference": "Ваши предпочтения *",
                "inside": "Внутри ",
                "outside": "Снаружи ",
                "vip": "VIP",
                "bar": "Бар ",
                "gallery": "Галерея ",
                "balcony": "Терраса ",
                "smoking": "Курение ",
                "private_room": "Отдельная комната ",
                "patio": "Патио ",
                "tv_view": "Лицом к экрану ",
                "stage": "Сцена ",
                "with_stroller": "С тележкой ",
                "with_power": "С электричеством ",
                "standby_reservation_description": "Мне все еще нужна эта дата, пожалуйста, добавьте меня в лист ожидания.",
                "alternative_organizations_description": "Вы всегда можете поискать и в других наших ресторанах. ",
                "meta_description": "Чтобы забронировать место в {{name}}, выберите дату, время и количество гостей ",
                "back_to_reservation": "вернуться к резервированию",
                "field_label": {
                    "seats_count": "Количество гостей ",
                    "date": "Дата ",
                    "time": "Время ",
                    "preference": "Место ",
                    "arriving_within": "Прибытие в "
                },
                "button_label": {
                    "search": "Бронирование ",
                    "search_walked_in": "Добавить в очередь ",
                    "search_modify": "Продолжить",
                    "standby_reservation": "Добавьте меня в лист ожидания ",
                    "phone": "Позвоните нам "
                },
                "today_blocked": "Обратите внимание, на сегодня невозможно забронировать места. ",
                "reservation_in_edit_current_title": "Текущие детали бронирования",
                "reservation_in_edit_modified_details": "Что бы вы хотели обновить?"
            },
            "details": {
                "description": "Чтобы завершить процесс, пожалуйста, введите следующие данные.",
                "description_pending_approval": "Этот заказ должен быть одобрен рестора-ном. Чтобы завершить процесс, пожалуйста, введите следующие данные.",
                "description_standby": "Чтобы Вас добавили в лист ожидания, пожалуйста, введите сле-дующие данные.",
                "description_modify_warning": "Внимание! После обновления исходный заказ не будет сохранен.\nОбновленный заказ попадет в список ожидания до тех пор, пока не будет повторно подтвержден рестораном.",
                "description_modify_cc_deposit_warning": "Внимание! Обновление заказа приведет к удалению кредитного депозита, указанного для исходного заказа.\nНа следующем экране вам будет предложено ввести новый кредитный депозит.",
                "future_reservation_info": "Обратите внимание: столик зарезервирован для вас на {{value}} минут",
                "future_standby_reservation_info": "Пожалуйста, введите следующие данные, и мы отправим Вам сообщение, когда место освободится.",
                "called_in_info": "Мы добавили Вас в очередь.\  Пожалуйста, приходите в ре-сторан в ближайшие {{минуты}} минут.",
                "timer": "У Вас есть еще {{minints}} минут",
                "button_label": {
                    "confirm": "Сохранить ",
                    "confirm_and_pay": "Подтверждение и оплата ",
                    "proceed_to_deposit": "Продолжить ",
                    "cancel": "Отмена ",
                    "back_to_search": "Вернуться к поиску ",
                    "continue": "Продолжить ",
                },
                "field_label": {
                    "first_name": "Имя ",
                    "last_name": "Фамилия ",
                    "full_name": "полное имя",
                    "phone": "Телефон ",
                    "email": "Электронная почта ",
                    "notes": "Примечания ",
                    "tags": "Примечания ",
                    "search": "Поиск"
                },
                "field_error": {
                    "phone": "Неправильный номер "
                },
                "countries_dropdown": {
                    "find_country": "Поиск"
                }
            },
            "modal": {
                "confirmation": {
                    "time_limit_title": "Внимание ",
                    "time_limit_body": "Ваш заказ будет ограничен по времени до {{reserved_until}} ({{duration}}).\Продолжить?",
                    "queue_addition_title": "Добавить в очередь ",
                    "queue_addition_body": "Хотите, чтобы мы добавили Вас в очередь?",
                    "still_here": "Вы еще здесь?"
                },
                "alert": {
                    "unsuccessful_attempt_to_reserve_title": "упс...",
                    "unsuccessful_attempt_to_reserve_body": "Кажется, в данный момент Вы не подключены к Интернету.\ Подключитесь и повторите попытку.",
                    "unsuccessful_attempt_to_reserve_body_invalid_cc": "Проверьте данные кредитной кар-ты ",
                    "reservation_not_found_title": "упс...",
                    "reservation_not_found_body": "Прошло более {{минут}} минут, поэтому срок действия вашего бронирования истек. Пожалуйста, введите его еще раз.\Для получения дополнительной помощи Вы можете связаться с рестораном",
                    "fill_mandatory_fields_title": "Обязательные поля ",
                    "fill_mandatory_fields_body": "Пожалуйста, заполните все обязательные поля ",
                    "online_booking_blocked_title": "Онлайн-заказ не возможен ",
                    "online_booking_blocked_body": "К сожалению, в этом ресторане нет воз-можности сделать онлайн-заказ ",
                    "preference_required_title": "Место не выбрано ",
                    "preference_required_body": "Пожалуйста, для продолжения нужно вы-брать место ",
                    "illegal_group_size_title": "Внимание!",
                    "illegal_group_size_body": "Выбранное вами количество гостей слишком велико для выбранной даты и времени.",
                    "customer_additional_reservation_blocked_title": "Дополнительный заказ сделать не-возможно. ",
                    "customer_additional_reservation_blocked_body": "Кажется, Вы уже забро-нировали у нас место менее чем {{hours}} час / часа / часов назад.\ Чтобы сделать новый заказ, отмените предыдущее бронирование или позвоните нам.",
                    "cc_deposit_transaction_failed": "Операция не удалась ",
                    "back_to_create_reservation_button": "Вернуться к бронированию ",
                    "call_restaurant_button": "Позвоните в ресторан "
                },
                "confirm": "Подтвердить ",
                "cancel": "Отменить ",
                "retry": "Попробуйте еще раз ",
                "continue": "Продолжить",
                "back": "Вернуться "
            },
            "user_abandon_warning": "Обратите внимание – Вы покидаете страницу и вне-сенные вами изменения не будут сохранены.",
            "online_booking_blocked_description": "В настоящее время онлайн-заказы не принима-ются. Пожалуйста, свяжитесь с нами по телефону, нажав кнопку телефона ниже"
        },
        "reservation_details": {
            "date_label": "День ",
            "time_label": "В ",
            "arriving_within_label": "Скоро будет ",
            "people": "{{value}}"
        },
        "feedback": {
            "feedback_form": "Форма для обратной связи ",
            "possible_answers": "Возможные ответы ",
            "mandatory_questions": "Обязательный вопрос ",
            "guest": "Гость ",
            "description": "Здравствуйте, {{name}}!\n Мы будем признательны, если вы отве-тите на следующие вопросы.",
            "conclusion": {
                "title": "Спасибо за ответ!",
                "satisfied_customer_sub_title": "Мы хотели бы увидеть Вас снова в ближайшее время ",
                "unsatisfied_customer_sub_title": "Приносим извинения за неудобства. Ваш запрос был передан менеджеру ресторана, и мы продолжим делать для Вас все воз-можное.."
            },
            "button_label": {
                "confirm": "Отправить отзыв ",
                "poor": "Плохо ",
                "fair": "Удовлетворительно ",
                "good": "Хорошо ",
                "very_good": "Очень хорошо ",
                "excellent": "Отлично "
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Нет ответа ",
                    "fill_mandatory_fields_body": "Пожалуйста, ответьте на все вопросы "
                },
                "confirm": "Подтвердить "
            },
            "unsubscribe": {
                "link": "Я больше не хочу получать опросы от   {{organization}}",
                "success": "Мы успешно удалили Вас из списка для получения опросов.",
                "error": "При отмене получения опросов произошла непредвиденная ошиб-ка. Пожалуйста, повторите попытку позже или свяжитесь с рестораном."
            }
        },
        "deposit": {
            "description": "Для завершения процесса сохранения заказа - пожалуйста, ука-жите информацию для внесения депозита.",
            "card_number": "Номер кредитной карты ",
            "cvv": "CVV",
            "year": "Год ",
            "month": "Месяц ",
            "id_number": "Удостоверение личности ",
            "zip_code": "Почтовый индекс ",
            "date_passed": "Дата прошла ",
            "invalid_cc_number": "Ошибка в  номере карты ",
            "invalid_id_number": "Ошибка в  номере удостоверения личности ",
            "button_label": {
                "save": "Сохранить",
                "confirm_and_continue": "וПодтвердить и продолжить",
                "cancel": "Отменить заказ ",
                "continue": "Продолжить"
            },
            "modal": {
                "alert": {
                    "fill_mandatory_fields_title": "Обязательные поля ",
                    "fill_mandatory_fields_body": "Пожалуйста, заполните все обязательные поля ",
                    "submit_error_title": "Депозит не сохранен  ",
                    "submit_error_body_general": "Проверьте данные в форме и повторите по-пытку. ",
                    "submit_error_body_invalid_cc": "Проверьте данные кредитной карты и по-вторите попытку. "
                },
                "confirm": "Подтвердить "
            },
            "amount_details": {
                "title": "Сумма депозита ",
                "total": "{{currency_symbol}}{{total_amount}}",
                "per_guest": "{{currency_symbol}}{{amount_per_guest}} X {{seats_count}}"
            },
        },
        "cc_deposit": {
            "description": "Заказ обусловлен предоставлением информации кредитной кар-те для депозита в размере {{currency_symbol}}{{amount_per_guest}} за ужин.\  Заказ мож-но отменить заранее до {{time_limit_cancel}}.\  Чтобы завершить заказ, введите следую-щие данные ",
        },
        "deposit_payment": {
            "description": "При бронировании необходимо внести предоплату в размере {{currency_symbol}}{{amount_per_guest}} за каждого посетителя.\ Вы можете отменить заказ до {{time_limit_cancel}} заранее и получить полный возврат.\  Для завершения бронирования, приступайте к оплате.",
            "illegal_amount": "Сумма к оплате указана неверно. "
        },
        "health_statement": {
            "title": "Декларация о состоянии здоровья ",
            "customer_thanks": "תודה {{first_name}} {{last_name}}",
            "health_statement_saved": "Декларация о состоянии здоровья успешно сохра-нена. ",
            "health_statement_not_found": "Декларация о состоянии здоровья не найдена ",
            "share_health_statement_form_button": "Декларация о состоянии здоровья дру-гих гостей  ",
            "share_health_statement_form_text": "Привет!\ Я забронировал нам место в {{organization}}.\ Нас попросили заполнить декларацию о состоянии здоровья:\n{{link}}",
            "no_fever_statement": "Заявляю, что сегодня была измерена моя температура  и она меньше 38 градусов по Цельсию.",
            "no_cough_statement": "Заявляю, что я не кашляю и что у меня нет проблем с ды-ханием, за исключением кашля или затруднений с дыханием, возникающих в результате хронического заболевания, такого как астма или аллергия. ",
            "coronavirus_law": "Согласно разделу 3a(1) Правил чрезвычайного положения по причине коронавируса "
        },
        "smart_banner": {
            "title": "Почему в браузере?",
            "body": "Приложение значительно лучше :)"
        },
        "server_time_client_diff_dialog": {
            "title": "Внимание ",
            "content": "Время на Вашем устройстве отличается от времени в ресторане на {{time_diff}}.\ Время будет отображаться в соответствии со временем в ресторане.",
            "ok_button": "Подтвердить "
        },
        "site-links": {
            "address_screen_reader": "Адрес ресторана:",
            "from_screen_reader": "Открыто с ",
            "up_until_screen_reader": ", До ",
            "phone_number_screen_reader": "Номер телефона:",
            "to_call_screen_reader": "Нажмите здесь, чтобы позвонить ",
            "to_navigate_screen_reader": "Нажмите здесь, чтобы узнать как добраться. ",
            "show_work_hours_screen_reader": "Нажмите здесь, чтобы узнать часы работы ресторана ",
            "to_web_site_screen_reader": "Нажмите здесь, чтобы перейти на сайт ресторана "
        },
        "dialog_screen_reader": {
            "default": "Открыто диалоговое окно ",
            "before_you_pay_suggestion": "Открыто диалоговое окно: предложение блюд до оплаты. ",
            "order_scheduling": "Открыто диалоговое окно: согласование даты и времени заказа на доставку или самовывоз."
        },
        "forms_screen_reader": {
            "sign-up": {
                "pop_up_announcement": "Открылось окно подключения к системе Табит, введите номер телефона для проверки",
                "missing_fields": "Следующие поля являются обязательными и должны быть заполнены правильно.:",
                "phoneControl": "Номер телефона:",
            },
            "to-desktop-sign-up": {
                "missing_fields": "Следующие поля являются обязательными и должны быть заполнены правильно:",
                "cPhone": "Номер телефона:",
                "email": "Адрес электронной почты ",
                "firstName": "Имя ",
                "lastName": "Фамилия ",
                "apartment": "Номер квартиры ",
                "floor": "Этаж ",
                "address": "Полный адрес ",
                "terms": " Подтверждение Политики конфиденциальности  וְУсловий исполь-зования "
            },
            "customer_details": {
                "missing_fields": "Следующие поля являются обязательными и должны быть заполнены правильно:",
                "email": "Адрес электронной почты ",
                "first_name": "Имя ",
                "last_name": "Фамилия ",
                "notes": "Примечания ",
                "phone": "Номер телефона:",
            },
            "loyalty_sign_up": {
                "missing_fields": "Следующие поля являются обязательными и должны быть заполнены правильно ",
                "firstName": "Имя",
                "lastName": "Фамилия",
                "email": "Адрес электронной почты ",
                "mobile": "Номер телефона ",
                "zehut": "Номер удостоверения личности ",
                "membershipPrice": "Подтверждение доплаты при регистрации ",
                "clubTerms": "Подтверждение условий клуба ",
            }
        },
        "social-share": {
            "share_label": "Поделиться заказом ",
            "calendar_label": "Добавить в календарь "
        },
        "social-share_screen_reader": {
            "share": "Нажмите, чтобы поделиться ",
            "add_to_my_calender": "Нажмите, чтобы добавить в календарь ",
        },
        "contact-details": {
            "location_label": "Навигация ",
            "phone_label": "Телефон ",
            "menu_label": "Меню ",
            "choose_menu": "Выбор меню ",
            "no_available_menu": "Нет доступного меню ",
            "menu_error_dialog_title": "Произошла ошибка ",
            "menu_error_dialog_body": "Пожалуйста, повторите попытку позже "
        },
        "contact-details_screen_reader": {
            "location": "Нажмите здесь, чтобы узнать как добраться ",
            "phone": "Нажмите, чтобы позвонить в ресторан ",
        },
        "TO-DELETE": "Удалить ",
        "TO-EDIT": "Изменить ",
        "TO-ADD": "Добавить ",
        "TO-UPDATE": "Обновить ",
        "CANCEL": "Отменить ",
        "NO_THANKS": "Нет, спасибо ",
        "CLEAR_SELECTION": "Очистить выбор ",
        "ADD": "Добавить ",
        "UPDATE": "Обновить ",
        "DEFAULT_FORMATION": "Обычный ",
        "ORDER-TOTAL": "Сумма заказа ",
        "REMOVE": "Удалить ",
        "CASH": "Наличные ",
        "THANK_YOU": "Спасибо!",
        "TIP": "Чаевые ",
        "PAYED": "Оплачено ",
        "CONTINUE": "Продолжить",
        "CART_SUBTOTAL": "Промежуточный итог",
        "TOTAL_IN_TIP": "Итог, включая чаевые. ",
        "PARTIAL_PAY_IN_TIP": "Частичная оплата, включая чаевые ",
        "CLEAR": "Очистить ",
        "CONFIRM_SIGN_OUT": "Да, отключиться ",
        "APPROVE": "Подтвердить ",
        "REMAINED_PAY": "Осталось заплатить ",
        "REMAINED_PAY_CASH": "Осталось заплатить наличными  ",
        "TO_PAY": "Для оплаты ",
        "SAVE_CARD_INFO": "Сохранить данные платежа ",
        "TOTAL": "Итог ",
        "CARD_HOLDER_NAME": "Имя владельца карты ",
        "CARD_NUMBER": "Номер карты ",
        "SIGNIN": "Подключиться ",
        "SIGNIN_INFO": "Введите идентификационные данные ",
        "VALIDATING_CREDENTIALS": "Проверка данных...",
        "WELCOME_BACK": "Добро пожаловать ",
        "SELECT_EXISTING_CARD": "Выберите существующую карту ",
        "ADD_NEW_CARD": "Добавить новую карту ",
        "SAVE_CARD_AS_DEFAULT": "Сохранить карту по умолчанию ",
        "ORDER_TOTAL": "Сумма заказа ",
        "ORDER_VAT_TOTAL": "( включая НДС {{total}} {{currency}})",
        "FOREIGN_CURRENCY_TOTAL": "значение {{symbol}}",
        "ORDER_TIP": "Чаевые ",
        "PAID": "Оплачено ",
        "ENTER_ORDER_N": "Введите код заказа ",
        "TO_HOMEPAGE": "На домашнюю страницу ",
        "VIEW_MORE": "Дополнительные данные ",
        "VIEW_LESS": "Скрыть данные ",
        "REQUIERED": "Обязательно для заполнения ",
        "REQUIERED_CHECK": "Обязательно отметить ",
        "EDIT": "Изменить ",
        "PASSWORDS_DONT_MATCH": "Пароли не совпадают ",
        "PASSWORDS_MIN_LENGTH": "Пароль должен содержать не менее 6 знаков ",
        "INVALID_CREDIT_NUMBER": "Номер карты недействителен ",
        "INVALID_CVV": "CVV недействителен ",
        "INVALID_CARD_EXPIRATION": "Дата истечения срока действия недействительна. ",
        "INVALID_EMAIL": "Электронная почта не верна ",
        "EMAIL_FOR_INVOCE": "EMAIL Отправить счет-фактуру ",
        "CARD_EXPIRATION": "Дата истечения срока действия ",
        "CARD_HOLDER_CELL": "Номер телефона владельца карты ",
        "ROOM_OWNER_CELL": "На какой номер следует отправить код подтверждения?",
        "CARD_HOLDER_MAIL": "Электронная почта владельца карты ",
        "Q_SHARING_BILL": "Частичная оплата ",
        "Q_TIP_AMOUNT": "Сколько чаевых добавить?",
        "Q_HOW_MUCH_PAY": "Сколько Вы хотите заплатить?",
        "Q_HOW_MUCH_SPLIT": "На сколько человек разделить счет?",
        "Q_REGISTARED": "Вы регистрировались раньше? Соединить ",
        "Q_NEW_REGISTARED": "Новый клиент? Зарегистрироваться ",
        "Q_INVOICE_BY_MAIL": "Хотите получить счет по электронной почте?",
        "Q_REMOVE_CARD": "Удалить карту из кошелька?",
        "Q_REMOVE_PAYMENT": "Вы уверены, что хотите удалить этот платеж?",
        "SIGNIN_REGISTER": "Зарегистрироваться / Войти ",
        "END": "Закончить ",
        "ENTER_TIP": "Введите чаевые ",
        "ENTER_AMOUNT": "Введите сумму ",
        "SPLIT_AMOUNT": "Разделить сумму ",
        "YOUR_AMOUNT_TO_PAY": "Ваша сумма к оплате?",
        "PRESS_HERE": "Нажмите здесь ",
        "OTHER": "Другое ",
        "SENDING_INVOICE": "Отправка счета...",
        "CHALLENGE_CODE": "Код верификации ",
        "ENTER_CHALLENGE_CODE": "Введите код верификации ",
        "REMEMBER_ME_ON_PHONE": "Запомнить меня на этом устройстве ",
        "BILL_AMOUNT": "Сумма счета ",
        "PAY_WITH_ONOTHER_CARD": "Оплатить другой картой ",
        "PAYMENT_METHOD": "Способы оплаты",
        "SELECT_PAYMENT_METHOD": "Выбор способа оплаты ",
        "CREDIT_CARD": "Кредитная картаי",
        "CREDIT": "Кредитная ",
        "creditCard": "Кредитная карта ",
        "CreditGuard": "КредитГард ",
        "CIBUS": "Сибус ",
        "Cibus": "Сибус ",
        "CIBUS_CARD": "Карта Сибус ",
        "CIBUS_CARD_NUMBER": "Номер карты ",
        "TENBIS": "Тен Бис ",
        "10bis": "Тен Бис ",
        "10bis_card": "Карта Тен Бис ",
        "10bis_card_number": "Номер карты ",
        "loyaltypm_card_number": "Номер карты",
        "EDIT_ACCOUNT_SETTINGS": "Изменить настройки учетной записи ",
        "MY_ACCOUNT": "Моя учетная запись ",
        "SIGN_OUT": "Выйти ",
        "CLUB_SIGN_OUT": "Удаление члена клуба из заказа ",
        "VOUCHER_SIGH_OUT": "Удаление ваучера из заказа ",
        "COMPS_SIGH_OUT": "Удаление компенсации из заказа ",
        "EXPIRED_SOON_SIGN_OUT": "Отмена продления ",
        "EXPIRED_SOON_APPROVE": "Продолжить с этого места ",
        "REMOVE_VOUCHER": "Да, удалить ",
        "REMOVE_COMPS": "Да, удалить ",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION": "Вы уверены, что хотите удалить члена клуба из заказа?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_TEXT": "Отказ от членства в клубе анну-лирует право на льготы.",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_VOUCHER": "Вы уверены, что хотите уда-лить ваучер из заказа?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_COMPS": "Вы уверены, что хотите убрать компенсацию из заказа?",
        "CLUB_DIALOG_SIGN_OUT_CONFIRMATION_EXPIRED_SOON": "Отменить продление членства в клубе?",
        "MY_ACCOUNT_DETAILS": "Детали моей учетной записи ",
        "EDIT_PAYMENT_INFO": "Изменить способ оплаты ",
        "DELETE_CARD": "Удалить карту ",
        "SET_DEFAULT_CARD": "Установить по умолчанию ",
        "YES_DELETE": "Да, удалить ",
        "OFFER_REMARKS": "Примечания к блюду ",
        "OFFER_DONE": "Я закончил, спасибо ",
        "ENTER_OFFER_REMARKS": "Введите примечания к блюду ",
        "DELETE_PAYMENT": "Удалить платеж ",
        "CONFIRM_CELL": "Confirm Phone",
        "CONFIRM_CELL_MESSAGE": "На Ваш мобильный телефон будет отправлено под-тверждение. ",
        "USE_ENTERED_PHONE": "Отправить:",
        "ENTER_ALTERNATE_PHONE": "Используйте другой:",
        "EMAIL_NOT_FOUND": "Адрес электронной почты не найден в системе",
        "GRATUITY": "Чаевые ",
        "WITHOUT_GRATUITY": "Без чаевых ",
        "ORDER_SCHEDULING": "Когда вам удобно получить заказ?",
        "SELECT_SERVICE_TYPE": "Тип услуги ",
        "CLOSE": "Закрыть ",
        "CLOSE_DIALOG_BOX_SCREEN_READER": "Закрыть диалоговое окно ",
        "YES": "Да ",
        "NO": "Нет ",
        "SEND_ORDER": "Отправить заказ ",
        "TRACK_YOUR_ORDER": "Для отслеживания вашего заказа ",
        "YOUR_ORDERS": "Мои заказы ",
        "ORDER_CREATION_TIME": "Время создания заказа ",
        "CONTINUE_ORDERING": "Продолжить процесс заказа ",
        "ADD_ITEM": "Добавить товар ",
        "SELECT_ITEM": "Выбрать товар ",
        "UPDATE_ITEM": "Обновить товар ",
        "TOPPINGS": "Добавки к блюду ",
        "DECISIONS": "Решения ",
        "ITEM_CHANGES": "Изменения в блюде ",
        "SELECTION_GROUP_SELECT": "(Выберите {{count}} товар / товара /товаров)",
        "SELECTION_GROUP_SELECT_SINGLE": "( Выберите один товар)",
        "SELECTION_GROUP_SELECT_MIN": "(Выберите не менее {{count}} товаров)",
        "SELECTION_GROUP_SELECT_SINGLE_MIN": "( Выберите хотя бы один товар)",
        "GROUP_MOD_SELECT_ERROR": "Выберите не менее {{count}} добавок из: {{group}}",
        "GROUP_MOD_SELECT_SINGLE_ERROR": "Выберите хотя бы один из: {{group}}",
        "GROUP_SELECT_ERROR": "Выберите не менее {{count}} добавок из: {{group}}",
        "GROUP_SELECT_SINGLE_ERROR": "Выберите хотя бы один товар из: {{group}}",
        "SELECTION_GROUP_OPTIONAL_MAX": "При желании выберите до {{count}} това-ров",
        "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "Опция, выберите еще один товар ",
        "CHECKOUT": "Перейти к оплате ",
        "Q_REMOVE_OFFER_FROM_BASKET": "Вы уверены, что хотите удалить товар?",
        "ENTER_DELIVERY_ADDRESS": "Введите адрес доставки ",
        "SELECT_ADDRESS": "Выберите адрес ",
        "BRANCH": "Ресторан ",
        "CHOOSE_BRANCH": "Выберите ресторан ",
        "ADDRESS": "Адрес ",
        "PHONE": "Телефон ",
        "ENTRANCE": "Вход ",
        "FLOOR": "Этаж ",
        "ENTER_STREET_INCLUDING_NUMBER": "Пожалуйста, введите полный адрес, вклю-чая номер дома. ",
        "MISSING_STREET_NUMBER": "Как именно до Вас добраться?",
        "START_ORDER": "Перейти к заказу ",
        "MAKE_AN_ORDER": "Сделать заказ",
        "SELECT_CITY": "Выберите город ",
        "SELECT_DELIVERY_CITY": "Выберите город для доставки ",
        "SELECT_BRANCH": "Выберите филиал ",
        "ORDER_MENU": "Меню ",
        "eatin": "Закажи и съешь ",
        "ADDITIONAL_DETAILS": "Дополнительная  информация ",
        "RESET_ORDER": "Сделать заказ заново ",
        "ORDER_FROM": "Заказать в филиале ",
        "ORDER_TO": "Доставка по адресу ",
        "CONTACT_INFO": "Личная информация ",
        "CONTACT_ALCOHOL_DECLARATION": "Я старше {{age}} и при необходимости предо-ставляю удостоверение личности для покупки алкоголя",
        "PAYMENT_INFO": "Данные кредитной карты ",
        "PAYMENT_INFO_MISSING": "Нам не хватает некоторых данных для оплаты картой {{cardLastDigits}}",
        "FIRST_NAME": "Имя ",
        "LAST_NAME": "Фамилия ",
        "CELL": "Сотовый ",
        "EMAIL": "Email",
        "REVIEW_YOUR_ORDER": "Подробности заказа ",
        "REVIEW_YOUR_ORDER_BUTTON": "Просмотреть квитанцию",
        "OKKAMI_APP_LINK": "Go Back to OKKAMI App",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "Обратите внимание: один или несколь-ко введенных способов оплаты недействительны.",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "С рестораном необходимо свя-заться по телефону {{phone}}",
        "EXTERNAL_DELIVERY_FAILURE": "Заказ успешно отправлен ",
        "EXTERNAL_DELIVERY_FAILURE_CALL_REST": "Необходимо связаться с рестораном по телефону {{phone}}, чтобы согласовать доставку",
        "ADD_ITEMS": "Добавить товары ",
        "UPDATE_ITEMS": "Обновить товары ",
        "ORDER_SENT_SUCCESS": "Заказ успешно отправлен ",
        "ORDER_NEED_ATTENDANCE": "Ошибка при  отправке заказа ",
        "CALL_RESTAURANT_TO_COMPLETE_ORDER": "Пожалуйста, позвоните в ресторан ",
        "ORDER_NUMBER": "Номер заказа ",
        "START_NEW_ORDER": "Начать новый заказ ",
        "ORDER_ITEMS": "Детали заказа ",
        "ORDER_WILL_BE_SENT_TO": "Заказ будет отправлен по адресу ",
        "ESTIMATED_DELIVERY_TIME": "Время прибытия примерно ",
        "ESTIMATED_PREPARATION_TIME": "Время подготовки примерно ",
        "ESTIMATED_SUPPLY_TIME": "Срок поставки ",
        "MINUTES": "Минуты  ",
        "PREPARATION_TIME": "Время подготовки ",
        "ORDER_TYPE": "Тип заказа ",
        "PLEASE_SELECT": "Пожалуйста, выберите ",
        "PLACEMENT": "Варианты ",
        "NUMBER_OF_PEOPLE": "Количество человек ",
        "READ_AND_APPROVE_CONTRACT": "Я прочитал и согласен ",
        "READ_AND_APPROVE_CONTRACT_C": "С Условиями использования ",
        "READ_CONTRACT": "Прочтите Условия использования...",
        "PLEASE_APPROVE_CONTRACT": "Пожалуйста, подтвердите  Условия использования ",
        "USAGE_CONRACT": "Условия использования ",
        "ADDRESS_REMARKS": "Указания для курьера ",
        "LOADING_MENU": "Загрузка меню...",
        "MOD_WITH": "Добавки ",
        "MOD_WITHOUT": "Без ",
        "PASSWORD": "Пароль ",
        "CONFIRM_PASSWORD": "Введите пароль еще раз ",
        "FORGOT_PASSWORD": "Забыли пароль...",
        "RESTORE_PASSWORD": "Восстановить пароль ",
        "INCLUDE_SILVERWARE": "Добавьте одноразовые столовые приборы ",
        "LEAVE_DELIVERY": "Оставьте доставку у двери",
        "CURBSIDE_PICKUP": "Заказать из машины ",
        "CURBSIDE_PICKUP_DESC": "Пожалуйста, опишите модель автомобиля, цвет и но-мер лицензии. ",
        "AUTHENTICATING": "Проверьте детали ",
        "SIGNED_IN_AS": "Подключиться по имени ",
        "PAY_WITH_SAVED_PMS": "Сохраненные способы оплаты ",
        "SIGHN_IN_EMAIL": "Электронная почта для идентификации ",
        "SIGHN_IN_PASSWORD": "Пароль для идентификации ",
        "UPDATE_PAYMENT_INFO": "Обновить способ оплаты ",
        "UPDATE_ACCOUNT": "Обновить учетную запись ",
        "ADD_PAYMENT_INFO": "Добавить способ оплаты ",
        "UPDATING_ACCOUNT": "Обновление Вашей учетной записи...",
        "UPDATE_SIGNIN_INFO": "Обновить идентификационную информацию ",
        "PASS_MIN_LENGTH_ERROR": "Пароль должен содержать не менее 6 символов ",
        "OLD_PASSWORD": "Старый пароль ",
        "NEW_PASSWORD": "Новый пароль ",
        "BRANCH_TIME_CONFIRM_0": "В настоящее время мы закрыты. Мы вернемся к рабо-те {{t0}}",
        "BRANCH_TIME_CONFIRM_1": "В настоящее время мы закрыты. Мы работаем с {{t0}} до {{t1}}",
        "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "Услуга больше не доступна. Мы работаем с {{t0}} ",
        "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "Услуга больше не доступна. Мы работаем с {{t0}} до {{t1}}",
        "BRANCH_TIME_0": "Часы работы {{t0}}",
        "BRANCH_TIME_1": "Часы работы с {{t0}} до {{t1}}",
        "CONFIRM": "Подтвердить ",
        "ORDER_SUCCESS": "Подтвердить заказ ",
        "MIN-ORDER-PRICE": "Минимальная сумма заказа ",
        "DELIVERY-PRICE": "Стоимость доставки ",
        "TOTAL-ITEMS": "Всего товаров ",
        "TOTAL-TO-PAY": "Всего к оплате ",
        "MENU_ITEM_SEARCH": "Поиск блюда ",
        "MENU_ITEM_SEARCH_NO_RESULTS": "Мы не нашли результатов по запросу \"{{searchValue}}\" ",
        "MENU_ITEM_SEARCH_OTHER": "Хотите попробовать что-то еще?",
        "MENU_ITEM_SEARCH_CLEAN": "Очистка поиска",
        "PROMOTION_MIN_ORDER_PRICE": "* При покупке выше {{currency}}{{minOrderPrice}}",
        "WITHOUT": "без ",
        "CONTINUE_TO_PAYMENTINFO": "Перейти к оплате ",
        "PAYMENT": "Оплата ",
        "BRANCHES": "Филиалы ",
        "SERVER_ERROR": "Ошибка сервера ",
        "ERROR": "Ошибка ",
        "PAYMENT_ERROR": "Ошибка при оплате ",
        "PARTIAL_PAYMENT_ERROR": "Ошибка при оплате ",
        "NAME": "Имя ",
        "REGISTERING": "Зарегистрируйтесь ",
        "REGISTER": "Зарегистрироваться ",
        "RESET_PASSWORD": "Переустановка пароля ",
        "RESET_PASSWORD_INFO": "Введите новый пароль и нажмите Сброс. ",
        "ORDER_HISTORY": "История заказов ",
        "SELECT_ORDER_METHOD": "Выберите способ доставки ",
        "ORDER_METHOD": "Форма заказа ",
        "ORDER_FIXED": "Счет ",
        "SET_QUANTITY": "Введите количество ",
        "CELL_PATTERN": "Номер мобильного должен начинаться с 05",
        "CELL_MINLENGTH": "Номер мобильного должен состоять из 10 цифр",
        "VIEW_MENU": "Просмотр меню ",
        "ORDER_SERVICE_CLOSED": "Прием заказов закрыт ",
        "SET_ORDER_DELAY": "Задержка в выполнении заказа...",
        "DELAY_TO": "Задержка на час ",
        "SET_ORDER_DELAY_TO_HOUR": "Задержка в выполнении заказа на час ",
        "ASAP": "как можно скорее ",
        "PRMARY_PAYMENT_METHOD": "По умолчанию ",
        "ADD_PAYMENT": "Добавить платеж ",
        "SPLIT_PAYMENT": "Разделить платеж ",
        "SPLIT_PAYMENTS_DIFF_ERROR": "Сумма платежей не равна {{total}}, разница со-ставляет {{diff}}",
        "BACK_TO_SITE": "Вернуться на сайт ",
        "BACK_TO_APP": "Вернуться в приложение ",
        "FREE_DELIVERY_FROM": "Бесплатная доставка от ",
        "ADD_REMAINNING_AMOUNT": "Полная оплата суммы заказа ",
        "PAYMENTS_EXCEED_TOTAL_BY": "Сумма платежей превышает сумму заказа на ",
        "PAYMENT_AMOUNT": "Сумма платежа ",
        "ENTER_FIRST_PAYMENT_AMOUNT": "Введите сумму первого платежа ",
        "EXCESS_PAYMENT": "Баланс оплаты ",
        "CLICK_TO_REMOVE_EXCESS_AMOUNT": "Нажмите, чтобы загрузить лишнюю сумму ",
        "CANCEL_SPLIT_PAYMENT": "Отменить разделение платежей ",
        "APPLY_PAYMENTS": "Подтвердите платежи ",
        "SINGLE_PAYER": "Единственный плательщик ",
        "MULTIPLE_PAYERS": "Несколько плательщиков ",
        "SPLIT_ORDER_BETWEEN_PAYERS": "Разделить между несколькими плательщиками ",
        "CALL_RESTAURANT": "Позвонить в ресторан ",
        "SELECT_TABLE": "Выбрать столик ",
        "TABLE_NO": "Номер столика ",
        "CHHOSE_ROOM_NO": "Какой у Вас номер комнаты?",
        "ROOM_NO": "Номер комнаты ",
        "TABLE_NO_ORDER": "Номер столика {{no}}",
        "ENTER_ORDER_NO": "Введите номер заказа ",
        "ADD_GRATUITY_TO_COURIER": "Добавить чаевые курьеру ",
        "ORDER_TOTAL_WITHOUT_GRATUITY": "Общая сумма к оплате без чаевых ",
        "ORDER_TOTAL_WITH_GRATUITY": "Общая сумма платежа, включая чаевые: {{total}}",
        "GRATUITY_PERCENT": "Процент чаевых ",
        "ENTER_GRATUITY_AMOUNT": "Введите сумму чаевых ",
        "CONTINUE_TO_GRATUITY": "Перейти к чаевым ",
        "REFRESH_ORDER": "Обновить заказ ",
        "MONTH": "Месяц ",
        "YEAR": "Год ",
        "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "Введите сумму к оплате, не считая чаевых.",
        "ENTER_AMOUNT_TO_PAY": "Введите сумму к оплате ",
        "FULL_PAYMENT": "Полная оплата ",
        "PARTIAL_PAYMENT": "Частичная оплата ",
        "AMOUNT_EXEEDS_BALANCE_DUE": "Сумма превышает баланс платежа ",
        "BACK": "Вернуться ",
        "WORK_HOURS": "Часы работы ",
        "RESTAURANT_WEBSITE": "Сайт ресторана ",
        "SELECT_ONE": "Выберите один ",
        "DELIVERY_ADDRESS": "Адрес доставки ",
        "DELIVERY_DETAILS": "Подробности доставки ",
        "ADDRESS_DETAILS": "Подробности адреса ",
        "BRANCH_DETAILS": "Подробности о ресторане ",
        "ADDITIONAL_OPTIONS": "Дополнительные варианты ",
        "MINUTES_VAL": "{{m}} минут",
        "ADDITIONAL_OFFERS": "Добавки и соусы ",
        "SELECT_ADDITIONAL_OFFERS": "Выбирайте добавки и соусы ",
        "CONTINUE_TO_CONTACTINFO": "Перейти к контактным  данным",
        "CC_CUSTOMER_NAME": "Имя ",
        "CC_CUSTOMER_ID": "Удостоверение личности / регистрационный номер ",
        "ORDERER_NAME": "Имя заказчика ",
        "ORDERER_PHONE": "Телефон ",
        "ORDER_NO": "Номер заказа ",
        "ORDER_TAGS": {
            "BREAKFAST": "Завтрак ",
            "LUNCH": "Обед ",
            "DINNER": "Ужин ",
            "WORK": "Работа "
        },
        "ENTER_LOCALITY": "Введите название города/поселка ",
        "LOCALITY_TITLE": "Название города/поселка ",
        "STREET_TITLE": "Название улицы ",
        "HOUSE_TITLE": "Номер дома ",
        "TITLE": "Поставки {{name}} – TabitOrder",
        "TITLE_1": "{{name}} Поставки ",
        "TITLE_2": "{{name}} - TabitOrder",
        "PAYMENT_IN_PROGRESS": "Производим платеж...\  Пожалуйста, не закрывайте ок-но",
        "CREATING_ORDER": "Отсылаем транзакцию...",
        "LOADING_ORDER": "Загрузка транзакции...",
        "INVALID_AMOUNT": "Сумма к оплате указана неверно ",
        "INVALID_AMOUNT_RANGE": "Действительная сумма от {{min}} до {{max}}",
        "BACK_TO_MENU": "Вернуться в меню ",
        "ORDER_NOTES": "Примечания к заказу ",
        "Q_SAVEPM_1": "Заинтересованы в сокращении времени заказа?",
        "Q_SAVEPM_2": "You can save your payment method in Tabit's digital wallet for quicker and faster payments",
        "Q_SAVEPM_3": "Pay and Save",
        "Q_SAVEPM_4": "Pay without saving",
        "DISH_CALCULATOR": "Калькулятор порций",
        "MAKE_ADDITIONAL_PAYMENT": "Внесите дополнительную оплату",
        "DINER": "Человек ",
        "GENERAL_DINER": "Центр стола ",
        "ADDRESS_WITHOUT_HOUSE": "Адрес без номера дома ",
        "TAX_AMOUNT": "Налоги ",
        "VAT_AMOUNT": "НДС ",
        "INCLUSIVE_TAXES": "Налоги включены ",
        "ADDITIONAL_FEES": "Комиссия ",
        "ADDITIONAL_SURCHARGERS": "Дополнительная плата ",
        "ADDRESS_TYPE": "Тип адреса ",
        "VALID_UNTILL": "Годен до ",
        "ADDRESS_DESKTOP_HEADER": "Просто хочу убедиться, что мы доберемся до Вас...",
        "ADDRESS_DESKTOP_ANNOUNCEMENT_SCREEN_READER": "Откроется окно поиска адреса. Введите полный адрес: город, название улицы и номер дома.",
        "RESTAURANT_DESKTOP_SEARCH_ANNOUNCEMENT_SCREEN_READER": "Открылось окно с рестораном, введите: название или адрес ресторана",
        "SITES_DESKTOP_HEADER": "Выбор ресторана для самовывоза ",
        "SITES_DESKTOP_MENU_HEADER": "Выберите филиал, чтобы просмотреть меню ",
        "FUTURE_ORDER_DIALOG_HEADER": "Когда мы увидимся?",
        "FUTURE_ORDER_POP_ANNOUNCEMENT_SCREEN_READER": "Откроется окно для со-гласования самовывоза: выберите дату в правом столбце и время в левом столбце.",
        "CHAIN_DIALOG_HEADER": "Выбор ресторана ",
        "FUTURE_DELIVERY_ORDER_DIALOG_HEADER": "Когда Вам удобно приехать?",
        "WHERE_CAN_WE_FIND_YOU": "Где мы можем Вас найти?",
        "share_message": "Привет, вот {{name}} — ресторан, который я нашел на Табите. Хо-чешь попробовать?",
        "share_message_without_site": "Привет, вот ресторан, который я нашел на Табите. Хотите попробовать?",
        "from_you": "от Вас ",
        "general_time_to": "До ",
        "restaurants": "Рестораны ",
        "restaurants_subtitle": "Найдите свои любимые рестораны ",
        "order": "Доставка ",
        "order_subtitle": "На вынос или доставка ",
        "order_action": "Доставка/TA",
        "book": "Заказать место ",
        "short_code": "Быстрая оплата ",
        "payment_code": "Код платежа ",
        "payment_code_expiration": "действителен в течение {{минут}} минут",
        "payment_code_expiration_till": "(до {{time}})",
        "short_code_flow": {
            "steps": {
                "first": "Сделать заказ ",
                "second": "Показать код ",
                "third": "Оплата будет списана с загруженной карты "
            }
        },
        "balance": "Баланс карты пополнен ",
        "quickLoad": "Быстрая загрузка ",
        "book_subtitle": "Забронируйте столик в любимом ресторане ",
        "book_action": "Заказать место ",
        "pay": "Оплата ",
        "pay_subtitle": "Оплачивайте здесь с помощью мобильного телефона ",
        "pay_action": "Оплата с помощью мобильного телефона ",
        "quickpay_action": "Быстрая оплата ",
        "default": "По умолчанию ",
        "save": "Сохранить ",
        "okay": "Продолжить",
        "OK": "OK ",
        "close": "Закрыть ",
        "back": "Вернуться ",
        "continue": "Продолжить",
        "register": "Зарегистрироваться ",
        "registration": "Регистрация ",
        "reorder_action": "Закажите снова!",
        "location_select": "Выберите регион ",
        "my_location": "Настоящее местоположениеי",
        "change_location": "Изменить ",
        "my_location_unavailable": "Местоположение недоступно ",
        "more": "Более ",
        "km": "км ",
        "mile": "миля ",
        "at": "в ",
        "until": "До ",
        "far_away": "Очень далеко ",
        "when_to_book": "Когда бы Вы хотели? ",
        "choose_restaurant": "Выберите место ",
        "book_tables_title": "Свободные столики ",
        "book_tables_subtitle": "Нажмите на один из ресторанов, чтобы немедленно занять столик. ",
        "book_tables_standby_title": "лист ожидания ",
        "book_tables_standby_subtitle": "Нажмите на один из ресторанов, чтобы добавить в лист ожидания.",
        "booking_description": "Просто скажите когда и мы найдём для вас столик!",
        "website": "Домашняя страница ",
        "i_approve_the": "Я прочитал и одобрил ",
        "privacy_policy": "Политика конфиденциальности ",
        "club_terms": "Правила клуба ",
        "terms_and_conditions": "Условия использования ",
        "approve_commercials": "Заполняя эту форму, я разрешаю клубу время от времени присылать мне текстовые сообщения c рекламными материалами.",
        "approve_sms": "Получение текстовых сообщений c рекламными материалами ",
        "approve_mail": "Получение маркетинговых сообщений по электронной почте ",
        "account_removal": "Удаление учетной записи ",
        "remove_account": "Удаление учетной записи ",
        "and": "и",
        "around_you": "Вокруг вашего текущего местоположения ",
        "today": "Сегодня ",
        "redirect_to_device_settings": "Перейти к настройкам устройства ",
        "next_time": "В другой раз ",
        "redirect_to_profile": "Перейти в профиль ",
        "understood": "Я понял ",
        "delivery_or_takeway": "Доставка или самовывоз?",
        "order_type_title": "Доставка или самовывоз?",
        "order_type_description": "Пожалуйста, выберите ваши предпочтения.",
        "delivery": "Доставка ",
        "takeaway": "Самовывоз ",
        "takeaway_or_delivery_screen_reader": "Выбор самовывоза или доставки ",
        "seated": "Сидя в ресторане ",
        "house": "Дом ",
        "office": "Офис ",
        "apartment": "Квартира ",
        "entrance": "Вход ",
        "floor": "Этаж ",
        "no_addresses": "Мы ждали ",
        "address_type": "Тип адреса ",
        "please_select_type": "Пожалуйста, выберите тип ",
        "address_not_found": "Адрес не найден ",
        "address_title": "Адрес доставки ",
        "address_description": "Пожалуйста, выберите свой адрес.",
        "please_type_address_short": "Добавление нового адреса ",
        "please_type_address_long": "Введите место, город или полный адрес ",
        "please_type_delivery_address_short": "Добавьте  адрес доставки ",
        "please_type_delivery_address_long": "Введите полный адрес, включая номер дома. ",
        "please_type_address_full": "Введите полный адрес ",
        "new_address": "Новый адрес ",
        "saved_address": "Ранее выбранные адреса ",
        "address_placeholder": "Улица и номер, город ",
        "business_invite_restaurant_owner": "Табит ",
        "business_invite_restaurant_owner_screen_reader": "Перейти на сайт компании, Та-бит ",
        "business_invite_join_us": "На сайт компании ",
        "business_invite_restaurant_owner_seo": "У вас есть ресторан?",
        "business_invite_join_us_seo": "Это вам ",
        "error_title": "момент...",
        "error_general": "Произошла неизвестная ошибка. Пожалуйста, попробуйте еще раз.",
        "error_invalid_cards_for_auto_attach": "Произошла ошибка, пожалуйста, свяжитесь с рестораном. ",
        "error_invalid_sign_in_credentials": "Видимо, адрес электронной почты или пароль неверны или не относятся к введенному вами номеру телефона. Пожалуйста, попробуй-те еще раз.",
        "error_invalid_touchid": "Нам не удалось подтвердить вашу личность по лицу или отпечатку пальца. Пожалуйста, введите номер своего мобильного телефона.",
        "error_invalid_sign_in_email_already_exists": "Этот адрес электронной почты суще-ствует в системе. Вы должны ввести другой адрес электронной почты.",
        "error_invalid_load_sites": "При загрузке ресторанов произошла ошибка. Повтори-те попытку.",
        "error_client_credentials": "Произошла ошибка. Пожалуйста, попробуйте еще раз.",
        "error_sign_up": "Произошла ошибка при регистрации пользователя... повторите попытку..",
        "error_sign_up_user_already_exists": "Кажется, что пользователь с таким адресом электронной почты уже существует. Пожалуйста, попробуйте войти в систему, используя свое имя пользователя и пароль.",
        "error_reset_password_missing_email": "Пожалуйста, введите свой адрес электрон-ной почты, чтобы обновить пароль.",
        "invalid_email_entered": "Введенный адрес электронной почты недействителен. ",
        "error_reset_password": "Кажется, ваш адрес электронной почты неверен или не существует в системе. Пожалуйста, попробуйте еще раз или зарегистрируйтесь снова.",
        "error_full_address_required": "Вы должны ввести полный адрес, включая номер до-ма. ",
        "confirmation_title": "Подтверждение ",
        "confirmation_password_reset": "На ваш почтовый ящик будет отправлено письмо с инструкциями о том, как обновить пароль.",
        "main": "основной ",
        "login": "вход в систему ",
        "logout": "выход из системы ",
        "name": "Имя ",
        "first_name": "Имя ",
        "last_name": "Фамилия ",
        "phone": "Телефон ",
        "birthDate": "Дата рождения ",
        "anniversary": "Дата годовщины свадьбы ",
        "send": "Отправить ",
        "sending": "Отправить ",
        "enter_code": "Код верификации ",
        "verification_code": "Код верификации ",
        "verification_code_message": "Ваш одноразовый код верификации: {{pincode}}",
        "confirm_code": "Подтвердить ",
        "did_not_arrive_resend": "Отправить повторно ",
        "resent": "Отправить повторно ",
        "email": "электронная почта ",
        "password": "Пароль ",
        "forgot_your_password": "Я забыл пароль ",
        "continue_with_email": "Войти по электронной почте ",
        "or_continue_with": "או Продолжить  с ",
        "sign_in": "Вход ",
        "sign_up": "Новый клиент? Зарегистрируйтесь ",
        "facebook": "Facebook",
        "google": "Google",
        "search": "Поиск",
        "restaurants_search": "Поиск ресторанов",
        "occasions_search": "Поиск мероприятий",
        "searching": "идет поиск ",
        "categories": "категории ",
        "tags": "теги ",
        "ratings": "рейтинги ",
        "price": "цена ",
        "services": "Услуги ",
        "auth_touch": "Распознавание лица/пальцев ",
        "style": "стиль ",
        "accessibility": "доступность ",
        "mood": "Настроение ",
        "kosher": "кошерный ",
        "collective": "коллективный ",
        "events": "Мероприятия ",
        "specials": "Особые возможности ",
        "suitable": "Подходит для ",
        "by_date": "По дате ",
        "by_restaurant": "по ресторану ",
        "restaurant_type": "тип ресторана ",
        "tabit_restaurants": "Рестораны Табит ",
        "about_the_restaurant": "о ресторане ",
        "articles": "статьи ",
        "regulations": "Правила ",
        "paid": "оплачено ",
        "on_the_house": "За счет заведения ",
        "to_main_page": "На главную страницу ",
        "input_pay_amount": "Введите сумму к оплате ",
        "choose_payment": "Выберите способ оплаты ",
        "show_code_to_staff": "Покажите код команде ",
        "code_valid_time_1": "Код действителен в течение 5 минут. Плата будет произво-диться только ",
        "code_valid_time_2": "После одобрения кода командой.",
        "user_reviews": "Мнение ",
        "user_review_hey": "Привет ",
        "user_review_end": "Конец ",
        "user_review_thanks": "Спасибо ",
        "user_review_thanks_for_sharing": "Спасибо, что поделились с нами ",
        "user_review_waiting_to_hear_from_you": "Хотим услышать Ваше мнение ",
        "user_review_what_did_you_like": "Как это повлияло на Вас? ",
        "user_review_comment": "Мы хотели бы узнать, что Вы думаете",
        "user_review_positive_thank_you_description": "Уже жду нашей следующей встре-чи!",
        "user_review_negative_thank_you_description": "Надеюсь увидеть Вас снова и до-биться большего успеха ",
        "user_review_add_comment": "Расскажите подробнее ",
        "user_review_food": "Еда ",
        "user_review_service": "обслуживание ",
        "user_review_package": "упаковка ",
        "user_review_times": "Время ",
        "user_review_order_process": "процесс заказа ",
        "user_review_atmosphere": "атмосфера ",
        "user_review_perfect": "Отлично ",
        "user_review_ok": "Замечательно ",
        "user_review_plausible": "Терпимо ",
        "user_review_expected_more": "я ожидал большего ",
        "user_review_bad": "Ничего особенного ",
        "user_review_add_a_review": "Добавить мнение ",
        "user_review_how_was_it": "Как было?",
        "user_review_sorry_to_hear": "жаль слышать...",
        "user_review_what_to_improve": "Мы хотели бы знать, что  нужно улучшить ",
        "in_progress_message": "Вы находитесь посередине…",
        "deletion_will_remove_all_payments": "Данное действие приведет к удалению всех платежей. ",
        "sorry": "Извините ",
        "contact_us": "Связаться с нами ",
        "were_sorry": "мы сожалеем ",
        "notifications": {
            "got_all_notifications": "Похоже, вы уже  получили все уведомления ",
            "promise_to_inform": "Мы обещаем исправиться ",
            "unsupported_browser_title": "прояснить ситуацию ",
            "unsupported_browser_confirm": "Я понял ",
            "unsupported_browser": "Для улучшения просмотра рекомендуется изменить настройки дисплея на яркий режим или не использовать браузер Samsung"
        },
        "order_tracker": {
            "hey": "Привет ",
            "ETA": "ожидается ",
            "FEES": "комиссии ",
            "CASH_DISCOUNT": "Скидка при оплате наличными ",
            "track_order": "Отслеживание заказа ",
            "my_order": "мой заказ ",
            "ETA_takeaway": "Заказ будет готов к ",
            "ETA_delivery": "Посылка прибудет через ",
            "actual_status": "Текущее состояние ",
            "status": "состояние ",
            "order": "Заказ ",
            "order_a": "Заказ ",
            "order_is_being_loaded": "Минуточку...",
            "order_accepted": "Мы получили ваш заказ ",
            "order_being_prepared": "Готовим заказ ",
            "order_in_progress": "заказ в процессе ",
            "takeaway": "Самовывоз ",
            "delivery": "Доставка ",
            "arrived": "я приехал ",
            "track": "Отслеживание ",
            "send": "Отправлен ",
            "ok": "OK",
            "order_ready": "Ваш заказ готов ",
            "order_ready_for_delivery": "заказ готов к отправке ",
            "order_taken": "Заказ принят ",
            "order_taken_on_its_way": "Курьер уже едет к вам ",
            "order_delivery_delivered": "Приятного аппетита! Заказ доставлен ",
            "add_review": "Добавить мнение ",
            "your_order_not_found": "Ваш заказ не найден ",
            "hey_im_here": "Привет, я здесь!",
            "im_here_explanation": "Мы хотели бы знать когда вы приедете, -",
            "how_can_we_find_you": "Как мы можем Вас найти?",
            "car_information": "Информация об автомобиле ",
            "instructions_accepted": "Спасибо за информацию :)",
            "update_notes": "Обновить комментарии ",
            "order_not_here": "Вашего заказа здесь нет...",
            "dont_cry_over_spilled_juice": "Но не жалейте ",
            "can_we_offer_you_something_else": "Могу ли я предложить Вам что-то еще?",
            "im_here_confirmation_title": "подтверждение прибытия ",
            "im_here_confirmation_message": "Сообщить в ресторан, что Вы приехали?",
            "im_here_confirmation_hurry_message": "Так быстро, ты уверен, что прибыл?",
            "im_here_confirm": "Да ",
            "im_here_cancel": "Еще нет ",
            "disclaimer_with_phone": "По любым вопросам относительно вашего заказа об-ращайтесь непосредственно к {{name}}, нажав 📞",
            "disclaimer_without_phone": "По любым вопросам относительно вашего заказа обращайтесь напрямую к {{name}}."
        },
        "to_disclaimer": "По любым вопросам относительно вашего заказа обращайтесь к {{имя }}",
        "by_phone": "по телефону: ",
        "my_account": "моя учетная запись ",
        "my_account_description": "Вы можете отредактировать свои личные данные здесь.",
        "personal_information": "личные данные ",
        "mobile": "Мобильный телефон ",
        "cell": "Мобильный телефон ",
        "change_password": "Изменение пароля ",
        "old_password": "Старый пароль ",
        "new_password": "Новый пароль ",
        "my_wallet": "Способы оплаты ",
        "my_wallet_info": "Настройка способа оплаты ",
        "my_addresses": "Адреса ",
        "my_addresses_info": "Сохраненные адреса для доставки ",
        "credit_no": "карта #",
        "credit_card_number": "Номер карты ",
        "credit_type": "Тип карты ",
        "credit_type_regular": "Кредитная карта ",
        "credit_type_cbus": "Сибус ",
        "credit_type_10bis": "Тен Бис ",
        "expiration_date": "Дата истечения срока действия ",
        "expiration_month": "месяц ",
        "expiration_year": "период действия ",
        "cvv": "3 цифры на обратной стороне карты",
        "comments": "Примечания ",
        "my_preferences": "предпочтения ",
        "my_preferences_info": "Любимая кухня и аллергия ",
        "my_preferences_selected_description": "Ваши категории и предпочтения",
        "my_preferences_description": "Чтобы обновить категории или аллергия, отметьте их в следующих списках.",
        "my_preferred_categories": "любимые категории",
        "my_preferred_dishes": "Любимые блюда ",
        "my_preferred_tags": "Любимые теги ",
        "my_preferred_allergies": "аллергия ",
        "my_history": "История заказов ",
        "my_history_info": "Открытые ордера и история ",
        "my_history_description": "Все Ваши посещения и заказы отображаются в следую-щем списке ",
        "search_history": "Загрузка истории ",
        "search_history_description": "Здравствуйте, если Вы ранее посещали рестораны {{descTitle}}, мы можем найти и загрузить историю ваших заказов",
        "my_benefits": "мои льготы ",
        "benefits_and_promotions": "Акции ",
        "more_benefits": "Дополнительные льготы ",
        "my_benefits_info": "Для получения информации обо всех ваших льготах и баллах ",
        "my_benefits_description": "Все ваши льготы и баллы перечислены ниже. ",
        "immediate_realization": "для немедленной реализации ",
        "immediate_topup": "Для быстрой загрузки нажмите здесь ",
        "prePaid_realization": "Карта загружена. ",
        "benefit_details": "Подробности о льготе ",
        "valid_until": "действует до ",
        "benefits_club": "Бонусный клуб ",
        "brithday_benefit": "Бонус на День рождения ",
        "join_benefit": "Бонус за присоединение ",
        "renew_benefit": "Бонус за продление ",
        "anniversary_benefit": "Бонус на годовщину свадьбы ",
        "general_benefit": "Обычный бонус ",
        "voucher_benefit": "Ваучер ",
        "punch_card": " Бонусная карта ",
        "points_benefit": "Накопительный бонус ",
        "my_favorites": "избранное ",
        "my_recent": "в последнее время ",
        "your_recent_orders": "Итак, что у нас было в последнее время?",
        "no_recents": "Предыдущие заказы не найдены ",
        "no_favorites": "Избранного пока нет ",
        "no_deliveries": "Досадно... похоже, в данный момент доставки в ваш город нет ",
        "future_reservations": "Бронирование ",
        "future_reservation": "Бронирование ",
        "future_reservations_and_discounts": "Будущие заказы и купоны на скидку ",
        "club_benefits": "Клубные бонусы ",
        "club_points": "клубные очки ",
        "marketplace_title": "Подарочная карта Табит для ресторанов",
        "your_points_worth": "Ваши баллы стоят денег и их можно обменять. ",
        "pre_paid_available": "Загруженная карта доступна для немедленного использова-ния. ",
        "pre_paid_top_up": "Загрузка карты ",
        "realize_points": "Использовать баллы ",
        "your_tracked_order": "Для отслеживания вашего заказа ",
        "quickpay": "быстрая оплата ",
        "issues-feedback-link": "Мы хотели бы услышать Ваше мнение ",
        "latest_orders_title": "Хочу заказать снова!",
        "latest_orders_subtitle": "Ваши недавние заказы и посещения ",
        "sites_near_me_title": "Ближайшие к Вам рестораны ",
        "sites_near_me_subtitle": "Все лучшие рестораны – прямо здесь!",
        "redirect_to_my_history": "Полный список всех последних заказов ",
        "HOUR": "Час ",
        "DATE": "Дата ",
        "add_pm": "Добавить способ оплаты ",
        "scan_pm": "сканирование карты ",
        "add_address": "сохранить адрес ",
        "my_recent_addresses": "Мои последние адреса ",
        "my_default_addresses": "Предпочтительный адрес ",
        "please_confirm": "момент...",
        "please_note": "Подождите...",
        "just_a_moment": "момент...",
        "dont_worry": "Не волнуйтесь ",
        "please_enter_value": "Пожалуйста, введите значение ",
        "please_enter_location_value": "Необходимо ввести описание местоположения. ",
        "are_you_sure": "Вы уверены?",
        "delete_account": "Удалить учетную запись?",
        "accept": "Подтвердить ",
        "Cancel": "Отменить ",
        "update_account": "обновить ",
        "your rank": "Ваш рейтинг ",
        "you_have": "У Вас есть ",
        "accumulated": "накопленные ",
        "points": "баллы ",
        "prepaid_points": "Предоплаченные баллы ",
        "recent_actions": "Последние действия ",
        "view_bill": "счет ",
        "goto-rest-page": "Перейти на страницу ресторана ",
        "photos": "Фото ",
        "openning_houres": "Часы работы ",
        "open_now": "сейчас открыто ",
        "closed_now": "Сейчас закрыто ",
        "will_be_open_at": "откроется в ",
        "new_in_tabit": "новое в Табит ",
        "rank": "рейтинг ",
        "near_me": "недалеко от меня ",
        "extra_services": "дополнительные услуги ",
        "near_me_pay_more_title": "Может быть, мы запутались? Рестораны поблизости ",
        "kitchens": "кухни ",
        "cuisine": "кухни ",
        "slide_down_to_close": "Проведите пальцем вниз, чтобы закрыть ",
        "Open": "сейчас открыто ",
        "Open Now": "сейчас открыто ",
        "Closed": "Сейчас закрыто ",
        "Closed Now": "Сейчас закрыто ",
        "profile": "Личный профиль ",
        "update_details": "Введите недостающие данные ",
        "reload_app": "Перезагрузить страницу ",
        "version": "версия ",
        "closed_for_day": "закрыто на сегодня ",
        "missing_site_description": "Нет описания ресторана ...",
        "show_more_sites": "Показать дополнительные рестораны ",
        "show_more": "показать еще ",
        "all_sites": "Все рестораны ",
        "to_all_sites_title": "Ищете что-то конкретное?",
        "to_all_sites": "Все рестораны ",
        "enter_order_no": "Введите код заказа ",
        "ORDER_DATE": "Дата заказа ",
        "ORDER_TIME": "время заказа ",
        "preparing-food": "Как только блюда будут добавлены в заказ, Вы сможете увидеть их здесь",
        "ADD_GRATUITY_TO_ORDER": "Чаевые ",
        "AMOUNT_TO_PAY": "Сумма к оплате ",
        "PAYUP": "Перейти к оплате ",
        "TABLE_BILL": "Счет за столик ",
        "WAITER": "Официант ",
        "PAY_WITH": "Оплатить с помощью ",
        "PAY_WITH_WALLET": "Оплатите с помощью кошелька ",
        "SELECT_ALTERNATE_PYMENT": "Выберите другой способ оплаты ",
        "PAY": "Оплатить ",
        "clear": "Очистить ",
        "please_enter_signature": "Для подтверждения оплаты распишитесь здесь ",
        "notes": "Примечания ",
        "id_card": "удостоверение личности ",
        "next": "Продолжить",
        "press here": "Нажмите здесь ",
        "day": "день ",
        "month": "Месяц",
        "year": "Год",
        "preference": "предпочтение ",
        "single_payer": "единственный плательщик ",
        "partial_payment": "частичная оплата ",
        "split_payment_by": "разделение счета в зависимости от ",
        "other": "Другой ",
        "gratuity": "Чаевые ",
        "order_balance": "баланс платежа ",
        "without_gratuity": "Без чаевых ",
        "select_items": "Выберите товары ",
        "pay_by_items": "Оплата за единицу товара ",
        "pay_by_items_title": "Я хочу заплатить за...",
        "area": "Место ",
        "GRATUITY_AMOUNT": "Сумма чаевых ",
        "ENTER_CVV": "הזן CVV",
        "PLEASE_ENTER_CVV": "Пожалуйста, введите CVV",
        "ENTER_CVV_MESSAGE": "Введите CVV карты: {{card}}",
        "SAVE_ACCOUNT_PAYMENT_INFO": "Сохранение способа оплаты в Вашей учетной запи-си ",
        "CAN_TD": "Заказ доставки ",
        "otcSeated": "прилавок ",
        "expiration": "срок действия ",
        "PASS_ORDER": "Разделить счет?",
        "PASS_ORDER_TITLE": "Переслать другу ",
        "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Дополнительный платеж с этим устройством ",
        "PASS_ORDER_BY_PHONE_MESSAGE": "Чтобы отправить ссылку в текстовом сообще-нии, введите номер телефона.",
        "PASS_ORDER_BY_PHONE_CAPTION": "Номер мобильного телефона ",
        "PASS_ORDER_BY_PHONE_SEND": "Отправить заказ ",
        "PASS_ORDER_BY_PHONE_QR_MESSAGE": "или отсканируйте штрих-код ",
        "PASS_ORDER_SENT_SUCCESS": "Заказ успешно отправлен ",
        "CANNOT_SEND_ORDER_BY_SMS": "Приносим извинения, но в данный момент ссылку отправить невозможно..",
        "CONTINUE_WITHOUT_GRATUITY": "Продолжить без чаевых",
        "PERFORMING_PAYMENT": "Платежная операция...",
        "FINALIZING_ORDER": "Работаем над этим...\  Пожалуйста, не закрывайте страницу",
        "PLEASE_WAIT": "пожалуйста, подождите...",
        "WERE_ON_IT": "Работаем над этим...  ",
        "LOREM_SMALL": "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף",
        "reserve_a_place_at": "зарезервировать место",
        "full-payment": "полная оплата ",
        "goto-tip": "Добавить чаевые ",
        "partial-payment": "частичная оплата ",
        "goto-payment": "оплата ",
        "add-items": "Добавление товаров ",
        "add-to-order": "Добавить к заказу ",
        "send-order": "отправка заказа ",
        "add-to-order-with-amount": "Добавить к заказу {{amount}}",
        "back-to-order": "Вернуться к заказу ",
        "ID_CARD": "удостоверение личности (владельца карты) ",
        "ENTER_IDCARD": "Введите удостоверение личности ",
        "ENTER_IDCARD_MESSAGE": "Введите идентификатор карты: {{card}}",
        "got_it": "Я понял ",
        "confirm_exit": "Выйти? Уверены?",
        "resart_order_confirm": "Выбранные вами блюда не будут сохранены. ",
        "events_and_benefits": "Мероприятия и бонусы ",
        "marketplace_main_title": "Подарочная карта для ресторанов Табита",
        "date": "дата ",
        "to_restaurant": "в ресторан ",
        "sites_list": "список филиалов ",
        "sites_nearby": "Рестораны поблизости ",
        "not_available": "Нет в наличии ",
        "not_available_now": "В настоящее время недоступно ",
        "not_available_to_this_address": "Недоступно для этого адреса ",
        "available_from": "Начиная с-",
        "preorder": "Предварительный заказ ",
        "future_order": "Будущий заказ ",
        "availability": "доступность ",
        "only_available": "Доступен сейчас ",
        "future_order_standby": "Заказ в листе ожидания ",
        "future_order_pending": "Заказ ожидает подтверждения ",
        "order_details": "детали заказа ",
        "order_in_progress": "Заказать заново?",
        "order_end": "Начать новый заказ?",
        "continue_order": "Продолжить заказ   ",
        "payment_in_progress": "оплата ",
        "payment_order": "Перейти к оплате ",
        "cancel_order": "Да, выйти ",
        "confirm_cancel_order": "Да, начать сначала ",
        "confirm_continue_order": "продолжить с этого места ",
        "payment_screen": "Оплата путем сканирования штрих-кода ",
        "short-code_screen": "Быстрая оплата при оформлении заказа ",
        "extended_results": "תוצאות נוספות ",
        "book_a_place": "Забронировать место ",
        "app_smart_banner_title": "Почему в браузере?",
        "app_smart_banner_body": "Приложение значительно лучше :)",
        "try_again": "Попробуйте еще раз ",
        "wait_on": "Подождите ",
        "change_language": "Переключение языка ",
        "pepper_pay": {
            "15_bonus": "Бонус 15 шекелей ",
            "pepper_bonus": "Бонус PAY ",
            "pepper_pay_payers": "Для оплаты через приложение PAY!",
            "pepper_pay_realization": "Чтобы воспользоваться бонусом, оплатите с помо-щью Pay на странице оплаты.",
            "takeout_delivery_above_amount_tip_not_included": "Действует при оплате на сумму более 50 шекелей (не включая стоимость доставки) при заказе доставки/самовывоза.",
            "look_for_pay_mark": "Ищите рестораны с отметкой PAY"
        },
        "BENEFIT_TYPE": {
            "points": "баллы ",
            "Other": "бонус",
            "BirthdayBenefit": "Бонус на День рождения ",
            "AnniversaryBenefit": "Бонус на годовщину свадьбы ",
            "JoinBenefit": "Бонус за присоединение ",
            "PointsBenefit": "Накопительный бонус ",
            "RenewBenefit": "Бонус за продление ",
            "Vouchers": "ваучер ",
            "Benefit": "бонус",
            "PunchCard": "Бонусная карта ",
            "PointsStore": "Магазин бонусов ",
            "PointsStoreBasket": "Использовать баллы ",
        },
        "ROUTE": {
            "book-a-table": "book-a-table",
            "deliveries": "deliveries",
            "restaurants": "restaurants",
            "new-at-tabit": "new-at-tabit",
            "nearest-restaurants": "nearest-restaurants",
            "events": "events",
            "reservation": "online-reservations",
        },
        "AVS": {
            "billing_address": "Адрес для выставления счета ",
            "state": "Страна ",
            "select_state": "Выберите страну ",
            "city": "Город ",
            "address": "Адрес ",
            "zip_code": "Почтовый индекс ",
        },
        "TGM": {
            "FIND_ME_APLACE": "Найдите мне место ",
            "FIND_ME_ARESTAURANT": "Найдите мне ресторан ",
            "DINERS": "гости ",
            "Day": "день ",
            "DATE": "Дата ",
            "TIME": "Время",
            "BOOK_TITLE": "детали заказа ",
            "ONE_DINER": "один гость ",
            "DETAILS_TITLE": "Чтобы завершить процесс, пожалуйста, введите следующие данные.",
            "SUCCESS_TITLE": "Заказ успешно получен ",
            "SUCCESS_TITLE_STANDBY": "Обратите внимание, мы добавили Вас в лист ожида-ния. Мы свяжемся с вами, если появится место.",
            "SUCCESS_TITLE_PENDING_APPROVAL": "Обратите внимание: Ваш заказ еще не подтвержден. Мы свяжемся с Вами как можно скорее для подтверждения заказа.",
            "DEPOSIT_TITLE": "Для завершения процесса сохранения заказа - пожалуйста, заполните кредитную информацию для внесения депозита.",
            "LICENSE_AGREEMENT": "Нажимая Сохранить, вы соглашаетесь с Условиями использования и Политикой конфиденциальности. Нажмите здесь, чтобы узнать по-дробности ⟩",
            "SAVE": "Сохранить ",
            "standby": "лист ожидания ",
            "instant_booking": "Мгновенное подтверждение ",
            "pending_approval": "Подлежит подверждению ",
            "form_description_standby": "Чтобы Вас внестли в лист ожидания, пожалуйста, внесите следующие данные.",
            "form_description_pending_approval": "Этот заказ подлежит одобрению ресто-рана. Чтобы завершить процесс, пожалуйста, внесите следующие данные.",
            "standby_list": "лист ожидания ",
            "missing_cc_details": "Ожидание депозита ",
            "missing_deposit_payment": "Ожидание предоплаты ",
        },
        "ORDER_SITES_BY": {
            "tabit": "דרוג Tabit",
            "distance": "расстояние ",
            "name": "Имя "
        },
        "SERVICES": {
            "order": "Заказать онлайн ",
            "book": "бронирование ",
            "quickpay": "быстрая оплата "
        },
        "SERVICE_BUTTON": {
            "home": "основной ",
            "order": "Доставка/TA",
            "book": "Заказать место ",
            "delivery": "Доставка ",
            "takeaway": "самовывоз",
            "pay": "סריקת QR",
            "pay_generic": "Оплата ",
            "profile": "профиль ",
            "future_order": "Будущий заказ ",
            "eatin": "Закажи и съешь ",
            "waitingList": "лист ожидания ",
        },
        "MESSAGES": {
            "EXTRA_PAGE_ERROR": "Selection is required to continue",
            "GENERIC_GET_CUSTOMER_ERROR": "Произошла ошибка во время входа в систему. Пожалуйста, попробуйте еще раз или свяжитесь с рестораном для помощи.",
            "LOADING_INITIAL_SITES": "Ищите новые впечатления? ",
            "EMAIL_MISSING": "Привет, я рад, что вы присоединились :)\ Чтобы пользоваться всем контентом, все, что вам нужно сделать, это добавить адрес электронной почты в свой профиль.",
            "EMAIL_STILL_MISSING": "Для завершения регистрации добавьте адрес элек-тронной почты ",
            "LOADING_SITES": "Подожди... это еще не все ",
            "NO_MORE_RESULTS": "Всё... Больше результатов нет ",
            "NO_ORGS_RESULTS": "Мы не смогли найти подходящих результатов ",
            "NO_ORGS_RESULTS_FOR_THIS_ADDRESS": "По этому адресу не найдено ни одно-го ресторана. ",
            "GENERAL_ERROR": "Произошла непредвиденная ошибка. Повторите попытку. ",
            "NO_INTERNET": "Похоже, у вас нет подключения к Интернету ",
            "CONFIRM_SIGNOUT": "выйти из учетной записи?",
            "sign_in_by_phone_message": "Привет! Рад вас видеть :)",
            "sign_in_by_phone_description": "Просто быстрая проверка телефона и мы про-должим ",
            "enter_code_message": "Мы отправили вам код подтверждения на",
            "sign_in_by_email_message": "Привет, пожалуйста, введите адрес электронной почты и пароль ",
            "wl_sign_up_title": "Регистрация в клубе ",
            "sign_up_message": "Ещё немного и мы закончим ",
            "saved_successfully": "Успешно сохранено ",
            "deleted_successfully": "Удален успешно ",
            "error_getting_bill": "Не удалось получить учетную запись. ",
            "service_not_available_for_site": "В настоящее время услуга в этом ресторане не-активна..",
            "Q_REMOVE_CARD": "Удалить карту из кошелька?",
            "NO_PMS_FOUND": "В вашей учетной записи не найдено способов оплаты ",
            "NO_SITE_PMS_FOUND": "Для ресторана не найдено способов оплаты. ",
            "NO_RESTS_FOUND": "По запросу не найдено ни одного ресторана.",
            "NO_PMS_FOUND_ADD": "В вашей учетной записи не найдено подходящих спо-собов оплаты. Нажмите Добавить способ оплаты, чтобы включить услугу",
            "SIGNIN-EMAIL-EX": "Введите адрес электронной почты, с которым Вы ранее ре-гистрировались ",
            "SIGNIN-EMAIL-EX-HELP": "Код подтверждения будет отправлен на мобильный телефон, связанный с адресом электронной почты. ",
            "SIGNIN-CHALLENGE-EX": "Код подтверждения отправляется на телефон ",
            "SIGNIN-CHALLENGE-EX-HELP": "Не получили код? Отправили еще раз",
            "SAVE_INFO": "Сохраните контактные и платежные данные ",
            "SAVE_INFO_END": "Зарегистрируйтесь , чтобы в будущем Вам было просто де-лать заказы ",
            "SAVE_INFO_PAYMENT": "Сохранить данные платежа ",
            "SIGNIN_CHALLENGE": "Вам был отправлен ответный код по SMS, введите его и нажмите Подключиться",
            "PAYMENT_CHALLENGE": "Код подтверждения будет отправлен на\ {{phone}}\ по SMS",
            "PAYMENT_ENTER_PHONE": "Просто быстрая проверка телефона и мы продол-жим ",
            "PAYMENT_OTP": "Мы отправили вам код подтверждения на\n {{phone}}",
            "PAYMENT_CHALLENGE_RETRY": "Введенный вами код неверен!\nЕще один про-верочный код был отправлен по SMS",
            "SIGNIN_VALIDATION": {
                "incorrect-phone-number": "Ошибка при вводе номера ",
            },
            "TRANSACTION_SUCCESS": "Платеж успешно произведен ",
            "ORDER_CLOSED": "Заказ оплачен полностью ",
            "INVALID_ORDER": "Заказ не найден ",
            "PAYMENT_FAILED": "Платеж не прошел ",
            "INVALID_PIN": "Введённый код не совпадает ",
            "SIGN_FOR_PAYMENT_CONFIRM": "Для подтверждения оплаты распишитесь здесь ",
            "SIGNATURE_MANDATORY": "Для того, чтобы мы подтвердили способ оплаты, Вам необходимо расписаться!",
            "WAITER_NOTIFIED": "Наша команда получила ваше обращение и скоро свяжется с вами!",
            "HAPPY": "Мы хотели бы видеть Вас снова ",
            "Cancel": "Cancel",
            "INVOICE_SENT": "Счет был отправлен вам на электронную почту ",
            "ITEM_ADDED_TO_BASKET": "Товар добавлен в корзину ",
            "NO_BASKET_ITEMS": "Корзина пуста ",
            "CONFIRM_ORDER_FROM_BRANCH": "Вы выбрали заказ в филиале: {{branch}}",
            "FINDING_THE_BRANCH_NEAREST_TO_YOU": "Ближайший к вашему дому филиал ",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "Не найдено ни одного филиала, обслуживающего адрес: {{address}}",
            "NO_BRANCH_SERVING_ADDRESS": "Ресторан не осуществляет доставку по этому адресу.",
            "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "В настоящее время мы отправляем только мгновенные заказы на этот адрес",
            "CONFIRM_RESET_ORDER": "Вы уверены, что хотите снова начать заказ?",
            "DO_YOU_APPROVE_CONTRACT": "Подтвердить Условия использования?",
            "DO_YOU_APPROVE_CONTRACT_TITLE": "Продолжим через секунду ",
            "CONDITIONS_PRIMARY_BUTTON_TEXT": "Да ",
            "CONDITIONS_SECONDARY_BUTTON_TEXT": "Выход ",
            "RESTORE_PASSWORD_1": "Пожалуйста, введите адрес электронной почты, кото-рый Вы использовали для идентификации.",
            "SIGNIN_SUCCESS": "С возвращением {{name}}, вы успешно подключились к си-стеме",
            "FORGOTPASS_SUCCESS": "Инструкции по входу были отправлены на указанный вами адрес электронной почты.",
            "ACCOUNT_UPDATE_SUCCESS": "Ваша учетная запись успешно обновлена!",
            "TITLE_HUNGRY": "Извините ",
            "BRANCH_DISABLED_NOW": "Service is closed right now. {{t}}.",
            "BRANCH_DISABLED_NOW_ERROR": "But this service has closed. It's available between {{t}}.",
            "BRANCH_CLOSED_TODAY": "Эта услуга сегодня не активна ",
            "BRANCH_CLOSED_NOW": "Сервис откроется в {{t}}, но не волнуйтесь,\Вы можете сделать предварительный заказ, который будет подготовлен к моменту открытия.",
            "MIN-ORDER-PRICE": "Минимальный заказ для доставки — {{t}}\n(не включая стоимость доставки).\ Товары должны быть добавлены в заказ",
            "SELECT-LOYALTY-SERVICE": "Для продолжения оформления заказа необходимо ввести клубную карту ",
            "SERVER_ERROR": "При обработке запроса произошла ошибка.\nПожалуйста, свяжитесь с рестораном",
            "PAYMENT_ERROR": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов.",
            "PAYMENT_ERROR_TITLE": "Ошибка оплаты ",
            "PAYMENT_LOCKED": "Заказ заблокирован другим пользователем. Повторите по-пытку. ",
            "REGISTER_SUCCESS": "Вы успешно зарегистрировались ",
            "PASSWORD_RESET_SUCCESS": "Пароль успешно изменен ",
            "CUSTOMER_ENTITYALREADYEXISTS": "Клиент с данным адресом электронной по-чты уже существует в системе. ",
            "PHONE_VERIFICATION_FAILED": "Кажется, код неправильный, попробуйте еще раз.",
            "PHONE_VERIFICATION_FAILED_TITLE": "минуточку...",
            "TOO_MANY_CODE_REQUESTS": "Ваша учетная запись временно заблокирована, поскольку вы достигли максимально разрешенного количества попыток.\nПовторите попытку позже.",
            "VOUCHER_ALREADY_USED": "Похоже, купон уже использован. ",
            "SERVICE_UNAVAILABLE": "Эта услуга в настоящее время недоступна, повторите попытку позже. ",
            "MOD_CANT_SELECT_MORE_THEN": "Вы должны выбрать не более {{max}} доба-вок!",
            "MOD_PLEASE_SELECT_AT_LEAST": "Необходимо выбрать не менее {{min}} доба-вок!",
            "MOD_PLEASE_SELECT_AT_LEAST_ONE": "Вы должны выбрать хотя бы одну добав-ку!",
            "CANNOT_PAY_TRAINING_MODE": "Система в режиме проверки. Транзакция не может быть выполнена. Пожалуйста, позвоните в ресторан\n {{phone}}",
            "ORDER_CANNOT_BE_PROCESSED": "При оплате произошла ошибка, Вы можете связаться с рестораном по телефону.\n (ошибка: {{код}})",
            "TIMEZONE_CHANGED": "It seems there's an issue with your device's clock. To ensure the best experience, please restart your order. Thank you for understanding!",
            "ARE_YOU_SURE": "Вы уверены?",
            "DISABLED_REGION_TITLE": "Служба доставки не доступна ",
            "DISABLED_REGION_MESSAGE": "В настоящее время мы не можем связаться с этим адресом.\nПожалуйста, повторите попытку позже.",
            "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "В настоящее время мы от-правляем только срочные заказы на этот адрес.",
            "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "В настоящее время мы отправляем на этот адрес только будущие заказы",
            "ENABLED_REGION_TIME_MESSAGE": "Мы вернемся к работе в {{t}}",
            "ENABLED_REGION_DAY_MESSAGE": "Мы вернемся к работе {{d}} в{{t}}",
            "OUT_OF_REACH": "Извините за неудобство ",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "Один или несколько введенных Вами платежей не удалось провести. Позвоните в ресторан, чтобы завершить заказ.\n {{phone}}",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "Один или несколько введен-ных вами платежей не удалось провести. Пожалуйста, свяжитесь с официантом, чтобы продолжить.",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "Обратите внимание: один или не-сколько введенных способов оплаты недействительны.",
            "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "С рестораном необходи-мо связаться по телефону {{phone}}",
            "SELECTTABLE_INFO": "Пожалуйста, введите номер стола, который Вы хотите за-резервировать ",
            "ENTERORDER_INFO": "Пожалуйста, введите номер заказа, за который Вы хотите заплатить.",
            "INVALID_TABLE": "Стол не существует ",
            "ORDER_RELOAD_NEEDED": "Пожалуйста, обратите внимание! Сумма к оплате изменилась!",
            "ORDER_NEWPAYMENT_NEEDED": "Обратите внимание, что сумма к оплате изме-нилась. Необходимо ввести новую сумму.",
            "ORDER_HELD_BY_SERVER": "Приносим свои извинения, но оплата в настоящее время невозможна. Пожалуйста, повторите попытку через несколько минут.",
            "SESSION_VALIDATION_FAILED": "При обработке запроса произошла ошибка, об-новите страницу и повторите попытку.",
            "CREDIT_CARD_3DS_DISCLAIMER": "Вам может потребоваться ввести код под-тверждения, который будет отправлен Вам компанией-эмитентом кредитной карты в текстовом сообщении или по электронной почте.",
            "PAY_ORDER_CHANGED": "Заказ изменился! Пожалуйста, проверьте  заказ.",
            "PAYMENT_ACCEPTED_SUCCESSFULLY": "Платеж прошел успешно ",
            "PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT": "Платеж на сумму {{amount}} успешно получен",
            "PAYMENT_INTENT_EXPIRED": "Срок оплаты истек!",
            "CARD_EXPIRED": "Срок действия истек ",
            "PAYMENT_DELETED_SUCCESSFULLY": "Платеж успешно удален ",
            "PAYMENT_ACCEPTED": "платеж прошел успешно ",
            "OUT_OF_STOCK": "На момент заказа нет в наличии следующих блюд:\n {{items}}\n Мы будем рады предложить Вам другие варианты из меню",
            "GRATUITY_CASH_DISABLE": "При наличии чаевых оплата наличными невозмож-на. ",
            "ORDER_ITEMS_UNAVALABLE": "К сожалению, на данный момент для заказа не-доступны следующие позиции:",
            "ORDER_TIMEOUT": "Необходимо заказать заново ",
            "ORDER_TIMEOUT_TITLE": "Время вышло ",
            "ORDER_DELIVERY_ERROR_TITLE": "Что-то пошло не так",
            "ORDER_DELIVERY_ERROR_MESSAGE": "Введенный вами адрес доставки не был сохранен, и нам пришлось перезапустить заказ. Приносим извинения за неудобства",
            "LOCAL_TIME_HAS_BEEN_CHANGED": "Местное время на вашем устройстве было изменено. ",
            "ORDER_TIMEOUT_LEVEL1": "Но не волнуйтесь, у вас есть еще 5 минут, чтобы вы-полнить заказ.",
            "ORDER_TIMEOUT_LEVEL1_TITLE": "Обновление ",
            "BASKET_ERRORS_TITLE": "ох, все кончено ",
            "BASKET_ERRORS": "Следующие товары отсутствуют на складе: {{errors}} Пожалуй-ста, обновите свой заказ",
            "BASKET_ERRORS_BUTTON": "Обновление ",
            "PROMOTION_DISABLED_TITLE": "Ой, закончилось ",
            "PROMOTION_DISABLED": "Предложение недоступно: {{errors}} Ваша корзина со-ответственно обновлена",
            "PROMOTION_DISABLED_BUTTON": "Обновление ",
            "CONFIRM_ADDRESS": "Введен адрес:\n<b>{{address}}</b>\n Продолжить?",
            "PROBLEM_IN_PAYMENT_INPUT": "Произошла ошибка при получении платежных данных.\nПожалуйста, введите данные платежа еще раз.\nЕсли проблема не исчезнет, свяжитесь с рестораном по телефону: \n {{phone}}",
            "MEAL_CALC_FACTOR_DESC": "Скидка рассчитывается на каждый товар в зависи-мости от его относительного веса в заказе.",
            "MEAL_CALC_ACTION_DESC": "Блюда должны быть отмечены для оплаты. ",
            "MEAL_CALC_TOTAL_DESC": "Общая сумма стоимости блюд не может превышать сумму, оставшуюся к оплате.",
            "EX_PAYMENT_ERROR": "Произошла ошибка при ссылке на платежную систему",
            "EX_PAYMENT_AUTHRIZATION_ERROR": "Произошла ошибка при подтверждении оплаты от поставщика. ",
            "LOCALITY_WO_STREETS": "В населенном пункте {{locality}} названия улиц не определены\Хотите выбрать этот населенный пункт в качестве пункта назначения до-ставки?",
            "SERVICE_END_WARN": "Внимание! Служба бронирования закроется через {{t}} минут. Заказ должен быть выполнен за оставшееся время",
            "JUST_SO_YOU_KNOW": "Нам важно проинформировать вас ",
            "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "Подтвердить",
            "QR_HELPER": "Сосканируйте QR",
            "INVALID_QR": "Произошла ошибка при сканировании QR. \n Если вы хотите оплатить, введите код вручную",
            "SITE_NOT_FOUND": "Запрошенный ресторан не найден ",
            "TABIT_PAY_MANUAL_ENTER": "ввести код вручную и выбрать ресторан ",
            "TABIT_PAY_MANUAL_ENTER_FOR_SITE": "Для оплаты с помощью кода ",
            "PLEASE_ENABLE_CAMERA": "Чтобы отсканировать QR, разрешите доступ к каме-ре",
            "PAY_INFO": "Чтобы продолжить процесс оплаты, необходимо выбрать ресторан ",
            "ENTER_ORDER_NO_INFO": "У персонала ресторана есть для вас код ",
            "AREA_NOT_SELECTED": "Место не выбрано ",
            "PLEASE_SELECT_AREA": "Пожалуйста, выберите, чтобы продолжить ",
            "PLEASE_ENABLE_LOCATION": "Чтобы мы могли рекомендовать вам рестораны в соответствии с вашим местоположением, необходимо активировать службы определе-ния местоположения.",
            "DESCRIBE_LOCATION": "Описание местоположения ",
            "MAP_LOCATION_INFO": "Чтобы мы могли связаться с вами, маркер должен ука-зывать на ваше точное местоположение.",
            "USER_LOCATION_ERROR": "Мы заметили, что ваши службы определения место-положения не активны. Чтобы мы могли связаться с вами, курсор должен указывать на ваше точное местоположение",
            "PLEASE_ENABLE_NOTIFICATIONS": "Здесь происходят удивительные вещи 🙂 Уве-домления позволят нам поделиться с вами, обещаем не надоедать!",
            "ENABLE_LOCATION": "Пожалуйста, включите службы определения местополо-жения ",
            "ENABLE_NOTIFICATIONS": "Пожалуйста, включите уведомления ",
            "CONTINUE_WITHOUT_GRATUITY": "Продолжить без чаевых?",
            "CALL_RESTAURANT": "Позвонить в ресторан ",
            "NO_LOCATION": "Нельзя определить местоположение!",
            "ORDER_REQUIERES_IDCARD": "Сумма платежа слишком велика, уменьшите сум-му или обратитесь к официанту.",
            "ERROR_CROSS_ORGS_TABLE_SEARCH": "Похоже, у вас нет подключения к Интер-нету ",
            "CROSS_ORGS_TABLE_NOT_FOUND": "В ресторанах, открытых в нужное Вам вре-мя, свободных столиков не обнаружено, попробуйте изменить время.",
            "GRATUITY_NOT_ALLOWED_FOR_PM": "В этом ресторане невозможно добавить чаевые в {{pm}}.\ Вы должны выбрать другой способ оплаты или отменить добавление чаевых.",
            "GRATUITY_PA_WARNING": "В этом ресторане чаевые получают чаевые только за {{pms}}",
            "FOR_EXTENDED_RESULTS_PRESS_HERE": "Для получения дополнительных ре-зультатов нажмите здесь ",
            "EXTENDED_RESULTS_BUTTON_ADDITIONAL_INFO": "Поскольку Вы важны нам, мы ничего не скрываем. Здесь есть и другие рестораны, которые осуществляют доставку с другими компаниями. если хотите.",
            "THE_SERVICE_BEGIN_AT": "Сервис откроется в ",
            "SERVICE_PREORDER_DONT_WORRY": "Но не волнуйтесь, Вы можете сделать предварительный заказ, который будет подготовлен к открытию.",
            "SERVICE_DOWN": "В настоящее время услуга недоступна, попробуйте еще раз.",
            "SUMMARY_SENT_BY_MAIL": "Сводная информация о заказе отправлена на ваш адрес электронной почты. ",
            "ENABLE_GRATUITY_CREDIT_ONLY": "Вы можете оплатить кредитной картой толь-ко при наличии чаевых. ",
            "ITEM_OUTOF_STOCK": "Выбранного вами блюда на данный момент нет в нали-чии, но есть еще много вариантов!",
            "ITEM_OUTOF_STOCK_TITLE": "О, всё закончилось ",
            "ITEM_OUTOF_STOCK_BUTTON": "Вернуться в меню ",
            "MAX_CAT_OFFERS_CONT": "Вы не можете заказать более {{max}} позиций из ме-ню {{target}}",
            "MAX_ORDER_OFFERS_CONT": "В корзине больше нет места...\ Нельзя заказать более {{max}} товаров в одном заказе",
            "MAX_SAME_DISH_COUNT": "Нельзя заказать более {{max}} {{target}}",
            "ORDER_CHANGED_AFTER_HANDOFF": "Внимание!\ Заказ уже отправлен в ресто-ран, новые изменения не сохранены.\ Для внесения изменений необходимо позвонить в ресторан: {{phone}}",
            "PLACE-MAP-MARKER": "Пожалуйста, разместите карту как можно ближе к адре-су. ",
            "LOYALTY_BENEFIT_TIMEOUT_ERROR": "Привет, прошло много времени...\ при-дется использовать ваши бонусы",
            "LOYALTY_BENEFIT_HANDOFF_ERROR": "Этот заказ невозможно оплатить картой, оканчивающейся на цифры {{num}}.\ Для получения дополнительной информации свя-житесь с рестораном,",
            "LOYALTY_BENEFIT_CONFLICT_ERROR": "К сожалению...\ Похоже, возникла про-блема или бонус уже использован.\ Пожалуйста, повторите попытку",
            "CARD_VALIDATION_MISMATCH": "Несоответствие при валидации карты ",
            "CARD_VALIDATION_ACTION_NEEDED": "Требуются дополнительные действия ",
            "ORDER_ITEMS_VALIDATION_ERROR": "Невозможно заказать выбранные пози-ции.\ Пожалуйста, свяжитесь с рестораном, чтобы продолжить заказ",
            "SIGNIN_SISABLED": "Вход/Регистрация временно отключена. Вы по-прежнему можете размещать заказы, как обычно, указав свою контактную информацию.",
            "CC_PAYMENT_OVERFLOW": "Одной картой невозможно заплатить более {{amount}} шекелей. Чтобы продолжить оплату на физическом терминале, свяжитесь с командой.",
            "HOTEL_NO_GUEST_WITH_PHONE": "Не найден номер телефона, связанный с комнатой. ",
            "HOTEL_ROOM_NOT_FOUND": "Номер не найден ",
            "HOTEL_NOT_ELIGIBLE": "Нет разрешения на списание средств ",
            "HOTEL_REPORT_TO_DESK": "Пожалуйста, свяжитесь со стойкой регистрации. ",
            "HOTEL_NO_GUEST_FOUND": "Гости не найдены ",
            "SELECT_MAP_LOCATION": "Выбор местоположения на карте ",
        },
        "EX_PAYMENT": {
            "dialog_title": "Подтверждение транзакции ",
            "description": "Данная страница используется для подтверждения транзакции по оплате на сайте. Платеж фактически будет произведен только после завершения про-цесса на странице компании. Сначала Вы должны подтвердить, что все данные на этой странице верны, и внести необходимые данные кредитной карты.",
            "popup-title": "Транзакция одобрена к выполнению ",
            "form-title": "Данные кредитной карты ",
            "popup-description": "Фактическая оплата будет произведена после завершения операции. ",
            "submit-btn": "Продолжить",
            "clear": "Очистить ",
            "credit_card_number": "Номер карты ",
            "expiration": "Дата истечения срока действия ",
            "cancel": "отменить ",
            "currency": "валюта:",
            "transaction_details": "Детали транзакции ",
            "transaction_acknowledge_ord": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_gcs": "By clicking on the “Pay” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "transaction_acknowledge_rsv": "By clicking on the “Continue” button, I confirm the transaction details and acknowledge the cancellation policy as per the Payment Services Law.",
            "total_amount": "сумма ",
            "month": "Месяц ",
            "year": "Год ",
            "click_here": "Нажмите здесь для перевода ",
        },
        "expayTitle": "Данные карты успешно введены ",
        "NATIVE_PAYMENT": {
            "error_dialog_title": "В настоящее время Ваш запрос не может быть обработан.",
            "error_dialog_description": "Выберите другой способ оплаты ",
            "error_dialog_no_amount_description": "Чтобы оплатить с помощью {{type}} Pay, нажмите X в строке платежа и повторите попытку."
        },
        "SERVER_MESSAGES": {
            "ORDER_CLOSED_PAYED": "Заказ не найден, возможно он уже оплачен и закрыт.",
            "OrderLockedByAnotherUser": "Платеж не прошел. Обслуживается другой поль-зователь ",
            "INVALID_USER_ORPASS": "Имя пользователя или пароль неверны ",
            "UPDATE_ACCOUNT_ERROR": "Нам не удалось обновить вашу учетную запись ",
            "EMAIL_NOT_FOUND": "Электронная почта в системе не найдена ",
            "EMAIL_IN_USE": "Электронная почта существует в системе! Выберите другой ад-рес электронной почты",
            "INVALID_ORDER_ID": "Запрошенная транзакция не найдена ",
            "INVALID_QR": "Запрошенная транзакция не найдена ",
            "FIREBASE_DOWN": "Услуга недоступна ",
            "SHVA_OFFLINE": "Кажется, в данный момент возникла проблема с расходами по кредитой карте.\ Мы рекомендуем оплатить другим способом, или повторите попытку через несколько минут.",
            "PAYMENT_QUOTA_EXCEEDED": "На карте недостаточно баланса ({{v}})",
            "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "The amount is higher than the credit limit set for the room. Please go to the reception or pay with another payment method.",
            "CREDIT_GUARD_EX_ERROR": "При обработке запроса произошла ошибка, по-пробуйте еще раз..",
            "EXTERNAL_PROVIDER_ERROR": "Платеж {{provider}} не выполнен!",
            "SMS_PHONE_ERROR": "Невозможно отправить текстовое сообщение на введенный но-мер {{phone}}.\ Необходимо связаться с рестораном,",
            "MISSING_CHAIN": "Сеть не найдена ",
            "CHAIN_DISABLED": "Сеть не активна ",
            "NO_SITES_IN_CHAIN": "Сайты в сети не найдены ",
            "NO_ACTIVE_SITES": "Активных сайтов не найдено "
        },
        "CREDIT_GUARD": {
            "ERROR_CODE_001": "Карта заблокирована, обратитесь в кредитную компанию (код 001).",
            "ERROR_CODE_002": "Карта украдена, обратитесь в кредитную компанию (код 002).",
            "ERROR_CODE_003": "Произошла ошибка, обратитесь в кредитную компанию (код 003).",
            "ERROR_CODE_004": "От кредитной компании получен отказ, сделка не одобре-на (код 004).",
            "ERROR_CODE_006": "Удостоверение личности или три цифры на обратной сто-роне карты неверны, попробуйте еще раз (код 006).",
            "ERROR_CODE_010": "Транзакция не одобрена к исполнению, требуется усилен-ная проверка. Вам следует обратиться в кредитную компанию (код 010).",
            "ERROR_CODE_033": "Введенный номер карты неверен, попробуйте еще раз (код 033).",
            "ERROR_CODE_036": "Карта недействительна (код 036).",
            "ERROR_CODE_039": "Введенный номер карты неверен, попробуйте еще раз (код 039).",
            "ERROR_CODE_173": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 173).",
            "ERROR_CODE_200": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 200).",
            "ERROR_CODE_316": "Внешний интерфейс вышел из строя. Свяжитесь с кредит-ной компанией (код 316).",
            "ERROR_CODE_358": "Конфиденциальные данные не найдены в базе данных (код 358).",
            "ERROR_CODE_401": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 401).",
            "ERROR_CODE_405": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 405).",
            "ERROR_CODE_414": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 414).",
            "ERROR_CODE_520": "Авторизации на проведение операции нет, необходимо обратиться в кредитную компанию (код 520).",
            "ERROR_CODE_599": "Сервис токенов эмитента кредита недоступен, необходимо обратиться в кредитную компанию (код 599).",
            "ERROR_CODE_695": "Этот тип кредитной карты не поддерживается, необходимо оплатить другой картой (код 695).",
            "ERROR_CODE_900": "Транзакция была заблокирована из-за невыполнения обя-зательств по безопасной транзакции (код 900).",
        },
        "PMS": {
            "Stripe": "Stripe",
            "Braintree": "Braintree",
            "Vantiv": "Vantiv",
            "HeartLand": "HeartLand",
            "CardPoint": "CardPoint",
            "AuthorizeNet": "AuthorizeNet",
            "reminder": "сумма, оставшаяся к оплате ",
            "remainder": "сумма, оставшаяся к оплате ",
            "remainder_wallet_option": "баланс ",
            "prepaidBalance": "Карта загружена ",
            "points": "баллы ",
            "creditCard": "Кредитная карта ",
            "Cibus": "Сибус ",
            "10bis": "Тен Бис ",
            "CreditGuard": "КредитГард ",
            "cash": "Наличные ",
            "TavHazahav": "Тав Заав",
            "cheque": "чек ",
            "payLeumi": "PAY Леуми ",
            "meshulam": "Bit",
            "giftCard": "Подарочная карта ",
            "ChargeAccount": "Кредит",
            "ChargeAccountPaymentProvider": "Кредит",
            "OperaPaymentProvider": "Оплата с комнаты "
        },
        "RANKS": {
            "0": "Человек ",
            "1": "постоянный посетитель",
            "2": "Почетный гость ",
            "3": "Гурман ",
            "4": "Чемпион заказов ",
            "5": "VIP",
            "6": "Живет, чтобы есть!",
            "7": "Ресторанный критик "
        },
        "ALLERGIES": {
            "gluten": "глютен ",
            "eggs": "яйца ",
            "peanuts": "арахис ",
            "nuts": "орехи ",
            "sesame": "Кунжут ",
            "lactose": "Лактоза ",
            "spicy": "острый ",
            "fish": "рыба "
        },
        "PO": {
            "TABLE_BILL": "Счет за столик ",
            "WAITER": "Официант ",
            "ORDER_NO": "Номер заказа ",
            "ORDERER_NAME": "Имя заказчика ",
            "ORDERER_PHONE": "телефон ",
            "ORDER_TO": "Доставка по адресу ",
            "OTH": "OTH",
            "ITEM_DISCOUNT": "Скидка на товар ",
            "TICKET_DISCOUNT": "Скидка на счет ",
            "TICKET_OTH": "все за наш счет ",
            "CHANGE": "чаевые/сдача ",
            "WITHOUT": "без ",
            "ORDER_TOTAL": "Сумма заказа",
            "ORDER_TIP": "Чаевые ",
            "PAID": "оплачено ",
            "REMAINED_PAY": "осталось заплатить "
        },
        "DAYS": {
            "sunday": "воскресенье ",
            "monday": "понедельник ",
            "tuesday": "вторник ",
            "wednesday": "среда ",
            "thursday": "четверг ",
            "friday": "пятница ",
            "saturday": "суббота "
        },
        "VALIDATIONS": {
            "CREDIT_CARD": "Номер карты недействителен ",
            "CREDIT_CARD_EXPIRATION": "Срок действия недействителен ",
            "INVALID_ID_NUNBER": "Удостоверение личности недействительно. "
        },
        "occasions": {
            "occasions": "поводы ",
            "occasion": "повод ",
            "event": "Мероприятие ",
            "online_event": "сообщение ",
            "holiday": "Праздник ",
            "all_day": "Весь день ",
            "start": "начало ",
            "end": "Конец ",
            "time_from": "с",
            "time_until": "До ",
            "events": "Мероприятия ",
            "hang_out_type": "тип развлечений ",
            "from_day": "со дня ",
            "every_week": "каждую неделю ",
            "every_two_weeks": "Раз в две недели ",
            "every_month": "раз в месяц ",
            "on_days": "в дни ",
            "on_day": "в день ",
            "starts_soon": "скоро начнётся ",
            "now": "сейчас ",
            "until": "До ",
            "more_info": "Ещё ",
            "confirm": "подтверждено ",
            "today_all_day": "Сегодня весь день ",
            "dates": {
                "events_for_today": "Сейчас - мероприятия сегодняшнего дня ",
                "tomorrow": "завтра ",
                "during_the_week": "на этой неделе",
                "next_week": "На следующей неделе ",
                "upcoming_month": "в следующем месяце "
            },
            "tags": {
                "everything": "Все "
            }
        },
        "marketplace": {
            "results_page_title": "Tabit Gift It",
            "results_page_description": "Хотите побаловать близких вкусной едой?</br> Вы в правильном месте!",
            "no_results_found": "Результаты не найдены ",
            "button_text": "Заказать подарочную карту ",
            "search_placeholder": "Поиск ресторана  ",
            "more_information": "Дополнительная информация ",
            "search_input": "Поиск по названию компании/городу",
        },
        "site_details": {
            "tab_label_overview": "общий ",
            "tab_label_reviews": "отзыв ",
            "tab_label_map": "карта",
            "tab_label_history": "мои заказы ",
            "tab_label_menu": "Меню "
        },
        "menus": {
            "morning menu": "Меню завтрака ",
            "noon menu": "обеденное меню ",
            "evening menu": "вечернее меню ",
            "night menu": "ночное меню "
        },
        "_CODES": {
            "409012": "Платежный баланс не может быть превышен",
            "409023": "Оплата не удалась. Попробуйте еще раз или измените способ оплаты",
            "409028": "Существуют непредставленные документы",
            "409030": "Пароль слишком короткий",
            "409036": "Мы обрабатываем предыдущий платеж, попробуйте еще раз",
            "409039": "Существуют открытые позиции",
            "409040": "Товара PARAM нет в наличии",
            "409073": "Похоже, платеж был задержан. Повторите попытку.",
            "409091": "Упс... кажется, возникла проблема. Необходимо связаться с рестораном для оформления заказа (телефон)",
            "409092": "Упс... кажется, возникла проблема. Необходимо связаться с рестораном для оформления заказа (телефон)",
            "409093": "Похоже, что платеж был задержан. Повторите попытку.",
            "409098": "Платежный сервис не активен. Повторите попытку позже или измените спо-соб оплаты",
            "409110": "Отправить SMS на номер [PARAM1] не удалось, попробуйте еще раз",
            "409136": "Заказ создан и отправлен в ресторан",
            "409806": "Кредитная карта в этом ресторане не поддерживается",
            "409808": "Срок действия способа оплаты истек",
            "409809": "Рекомендуем убедиться в правильности всех данных",
            "409810": "Рекомендуем убедиться, что все данные верны"
        },
        "areas_search_title": "Регионы ",
        "areas": {
            "haifa": "Хайфа и окрестности ",
            "north": "север ",
            "center": "центр ",
            "tel_aviv": "Тель-Авив-Яффо и окрестности ",
            "jerusalem": "Иерусалим и окрестности ",
            "south": "Юг ",
            "dallas": "Dallas",
            "fort_worth": "Fort Worth",
            "irving_grapevine": "Irving & Grapevine",
            "burleson": "Burleson",
            "plano": "Plano",
            "frisco": "Frisco",
            "mckinney": "McKinney",
            "sydney": "Sydney",
        },
        // Needed for dynamic language change
        "Haifa area": "Хайфа и окрестности ",
        "North": "север ",
        "Center": "центр ",
        "Tel Aviv-Jaffa area": "Тель-Авив-Яффо и окрестности ",
        "Jerusalem area": "Иерусалим и окрестности ",
        "SLIP": {
            "OTH": "OTH",
            "ITEM_DISCOUNT": "Скидка на товар ",
            "TICKET_DISCOUNT": "Скидка на счет ",
            "TICKET_OTH": "все за наш счет ",
            "CHANGE": "чаевые/сдача "
        },
        "GROUP_VALIDATION": {
            "SELECT_ONE": "Выберите один товар ",
            "SELECT_SOME": "выберите {{min}} товаров",
            "SELECT_ATLEAST_ONE": "Выберите хотя бы один товар ",
            "SELECT_ATLEAST_SOME": "Выберите не менее {{min}} товаров",
            "SELECT_UPTO_ONE": "Выберите еще один товар ",
            "SELECT_UPTO_SOME": "Выберите до {{max}} товаров ",
            "SELECT_BETWEEN": "Выберите еще от {{min}} и {{max}} товаров ",
            "GROUP_EXCEPTION": {
                "TITLE": "Привет, заказ обновлен ",
                "BODY": "После вашего последнего заказа мы внесли некоторые изменения в блюдо, новый заказ следует обновить."
            },
        },
        "ACCESS": {
            "service_selection": "Выбор типа услуги: Выберите нужную услугу. ",
            "tabit_logo": "Логотип Табит ",
            "call": "Позвоните ",
            "gift-card-redeem": "Страница для погашения подарочной карты ",
            "payment-dialog-open": "Платежное окно ",
            "login-successfully": "Вход успешно завершен!",
            "contact-details": "Контактные данные ресторана ",
            "site_service": "Ресторанные услуги ",
            "address": "Адрес ",
            "distance": "расстояние",
            "minus_circle": "минус ",
            "plus_circle": "плюс ",
            "accessibility_statement": "Заявление о доступности. Сайт поддерживает про-грамму чтения с экрана NVDA.",
            "selected": "Выбранный товар ",
            "deselected": "Товар удален. ",
            "ITEM_ADDED_PLUS": "Еще один идентичный товар ",
            "unselected": "Не выбран ",
            "same-page-link": "Внутренняя ссылка в меню ",
            "BASKET": "Корзина товаров ",
            "right-arrow-nav": "Стрелка навигации вправо ",
            "left-arrow-nav": "Стрелка навигации влево ",
            "restaurant": "ресторан",
            "ACCESSIBILITY_DECLARATION": "Заявление о доступности ",
            "ACCESSIBILITY": "Меню доступности ",
            "ACCESSIBILITY_STATEMENT": "Заявление о доступности ",
            "ACCESSIBILITY_SITE": "Перейти на доступный сайт",
            "ACCESSIBILITY_IN_PROGRESS": "Сайт в процессе доступа ",
            "CLEAR_ACCESSIBILITY_FEATURES": "Очистить параметры доступности ",
            "monochrom": "Монохромный ",
            "light-contrast": "Яркий контраст ",
            "dark-contrast": "Темный контраст ",
            "enlarge-font": "Увеличение шрифта ",
            "reduce-font": "Уменьшение шрифта ",
            "readable-font": "Разборчивый шрифт ",
            "big-white-curser": "Большой белый маркер. ",
            "big-black-curser": "Большой черный маркер ",
            "enlarge-frame": "Увеличение ",
            "highlight-links": "Выделить ссылки ",
            "highlight-headers": "Выделить заголовки ",
            "image-info": "Описание к картинкам ",
            "site-main-image": "Главное изображение ресторана ",
            "back-button": "Нажмите, чтобы вернуться на предыдущую страницу ",
            "enter_amount": "Сумма подарка (от {{min_amount}} до {{max_amount}})",
            "delete": "Удалить ",
            "view-menu": "Просмотр меню ",
            "add": "добавить ",
            "remove": "удалить ",
            "edit": "редактировать ",
            "day": "Выберите день ",
            "time": "Выберите время ",
            "guests": "Выберите количество гостей ",
            "language_screen_reader": "выбрать язык ",
            "future-reservation-time": "Когда Вы приедете?",
            "close": "закрыть ",
            "close_dialog": "Закрыть диалог ",
            "delete_content": "удалить содержимое ячейки ",
            "out_of_stock": ": В настоящее время нет в наличии ",
            "press_to_approve_and_continue_to_menu": "Нажмите, чтобы подтвердить и пе-рейти на страницу меню.",
            "service_buttons_takeaway": "Самовывоз",
            "service_buttons_delivery": "доставка",
            "service_buttons_delay": "Заказ на другой день ",
            "service_buttons_eatin": "Закажи и съешь ",
            "service_buttons_future_takeaway": "Самовывоз в будущего заказа. ",
            "service_buttons_future_delivery": "Отправка будущих заказов ",
            "item_added": "В корзину добавлен один товар ",
            "selection_confirmed": "Выбор подтвержден. ",
            "contrast-button": "Контраст ",
            "invalid-form": "Не все обязательные поля заполнены, пожалуйста, заполните обязательные поля ",
            "remove-payment_line": "Удаление платежной строки ",
        },
        "ADDRESS_TYPES": {
            "apartment": "Квартира ",
            "office": "Офис ",
            "house": "Частный домי"
        },
        "OFFER_BADGES": {
            "new": "Новый ",
            "sale": "Распродажа "
        },
        "WEB_HEADER": {
            "table_booking": "Заказать место ",
            "order_delivery": "Доставка/TA",
            "restaurants": "рестораны"
        },
        "_LEUMIPAY": {
            "payment_error": "Платеж через PAY не выполнен!\nПожалуйста, повторите по-пытку",
            "min_order_amount_error": "Для оплаты через PAY сумма заказа должна превы-шать 50 шекелей",
            "contrat": "К положению о PAY..."
        },
        "_LOYALTY": {
            "id_number_already_exist": "Этот номер удостоверения личности уже использу-ется, введите другой номер.",
            "registration_error_title": "Вступление в клуб ",
            "order_types": "Действительно только для {{types}}",
            "cancel_benefits": "Отменить скидки ",
            "card_balance": "Карта загружена ",
            "money_compensation": "Денежная компенсация ",
            "connect": "Подключение ",
            "connect_to_tabit": "Подключение к Табиту",
            "connect_info": "Номер телефона или номер карты ",
            "expires": "годен до ",
            "greeting": "Привет, {{name}}!",
            "greeting_voucher": "Привет!",
            "hey": "Привет!",
            "member_not_found_title": "Мы не нашли тебя ",
            "general_error_title": "Just a moment",
            "renew_is_forbidden_title": "Ваше членство в клубе закончилось ",
            "renew_is_forbidden_text": "Чтобы продолжать пользоваться преимуществами клуба, членство необходимо продлить. За более подробной информацией обращайтесь в ресторан.",
            "comps_extra_text": "Компенсация добавлена в заказ и может быть погашена..",
            "good_to_see_you_title": "Приятно тебя видеть ",
            "member_not_found": "Ваш номер телефона не отображается в списке членов нашего клуба ",
            "member_not_found_PRIMARY_BUTTON_TEXT": "Подтвердить",
            "customer_mustComplete_the_registration": "Для того, чтобы добавить члена клуба в заказ, необходимо сначала заполнить регистрационную форму, отправленную по СМС или в нашем приложении.",
            "of": "от",
            "points_balance": "оставшиеся баллы ",
            "points_balance_cap": "У вас есть {{x}} баллов, которые можно немедленно ис-пользовать ",
            "punch_card_offer": "осталось {{from}} из {{to}}",
            "review_benefits": "Перечисление скидок ",
            "review_points": "Использовать баллы ",
            "use_points": "баллы ",
            "signout": "Отсоединиться ",
            "skip": "Пропустить ",
            "no-benefits": "Бонусы не были реализованы ",
            "title": "Члены клуба {{name}}?",
            "title_voucher": "здравствуйте, у Вас есть ваучер?",
            "title_club": "Привет, Вы член клуба или у Вас есть ваучер?",
            "voucher": "ваучер ",
            "max_redeemable_points": "Максимальное количество баллов для использова-ния ",
            "my_benefits": "Мои бонусы",
            "benefits_use": "будет использовано в этом заказе",
            "redeem_points_prompt_1": "Ваши баллы стоят денег!",
            "redeem_points_prompt_2": "Максимальное количество баллов для использо-вания: {{points}}",
            "redeem_points_after": "Вы сэкономили {{points}}, использовав баллы",
            "voucher_gtreeting": "Привет, у Вас есть ваучер? ",
            "voucher_inline_gtreeting": "י У Вас есть ваучер?",
            "voucher_benefits_title": "Мои бонусы ",
            "voucher_placeholder": "Номер ваучера ",
            "club_gtreeting": "Давайте проверим, есть ли у Вас льготы или членство в клубе ",
            "club_inline_gtreeting": "Введите код купона или номер члена клуба ",
            "club_inline_gtreeting_voucher_only": "Мои бонусы ",
            "club_benefits_title": "Мои бонусы ",
            "club_placeholder": "Номер телефона/ваучера ",
            "select_club": "Выбрать клуб ",
            "benefits_title": "У Вас есть ваучер? ",
            "no_benefits_title": "Кажется, сейчас у Вас нет никаких льгот ",
            "register": "Хотите вступить в клуб? Зарегистрироваться ",
            "to_promotion_disclaimer": "Окончательная скидка будет рассчитана перед оплатой. ",
            "benefit_types": "Действительно только в течение {{val}}",
            "auth_title": "Требуется код верификации ",
            "auth_sub_title": "Код верификации отправлен на телефон ",
            "auth_code": "Код верификации ",
            "auth_resend": "Отправили еще раз ",
            "for_your_disposal": "К вашим услугам ",
            "gift-card": "У вас есть предоплаченная карта или подарочная карта?",
            "add-gift-card": "Введите другую карту ",
            "wallet-item-needed": "Еще одна мелочь ",
            "add-wallet-item": "Прежде чем продолжить, вам необходимо ввести данные кредитной карты. ",
            "move-to-my-wallet": "Перейти к моему кошельку ",
            "registration_terms": "Регистрация является подтверждением возможности вре-мя от времени получать СМС-сообщения о выгодных преимуществах и акциях. Вы можете отказаться от подписки на рассылку в любое время.",
            "regCostMessage": "Вступление в клуб предполагает оплату в размере {{currency}}{{price}}, которая будет добавлена в корзину товаров вашего заказа",
            "confirmDeleteOfferJoin": "Отказаться от клуба?",
            "confirmDeleteOfferRenewal": "Отменить продление членства в клубе?",
            "confirmDeleteOfferJoinDescription": "Отмена вступления в клуб лишит Вас воз-можности получать льготы.",
            "confirmDeleteOfferRenewalDescription": "Отмена продления членства в клубе помешает получению льгот",
            "removeOfferJoinButton": "Отмена вступления ",
            "removeOfferRenewalButton": "Отмена продления ",
            "keepOfferJoinButton": "продолжить с этого места ",
            "keepOfferRenewalButton": "продолжить с этого места ",
            "confirmExpiredSoonDeleteOffer": "Отменить продление членства в клубе?,",
            "removeExpiredSoonOfferButton": "Отмена продления ",
            "keepExpiredSoonOfferButton": "продолжить с этого места ",
            "regTitle": "Членам клуба веселее!",
            "regCancel": "Отмена ",
            "regDetailsDescription": "Мы не нашли ваш номер телефона {{phone}} в нашем списке. Пришло время присоединиться и воспользоваться преимуществами!\n",
            "continueReg": "Присоединение к клубу ",
            "regRenewMessage": "Продление членства в клубе стоит {{currency}}{{price}}, сум-ма будет добавлена в корзину товаров в вашем заказе.",
            "regPromptTitle": "Мы не смогли найти номер {{phone}} в нашем клубе.\ Пришло время вступить!",
            "regPromptPrice": "Вступление в клуб стоит {{currency}}{{price}}",
            "regRenewPromptPrice": "Продление членства в клубе стоит {{currency}}{{price}}",
            "regRenewTitle": "Время летит незаметно, когда вкусно!",
            "regRenewBody": " Пришло время продлить членство в нашем клубе \ ",
            "regSoonExpiredRenewBody": "Срок членства в клубе истек {{date}}.\nЧтобы и дальше пользоваться преимуществами клуба, вы можете продлить свое членство прямо сейчас!",
            "regSoonExpiredSignOut": "Срок членства в клубе истек {{date}}.",
            "regUpdateNessage": "Пожалуйста, убедитесь, что введенные данные верны ",
            "registerButton": "Присоединение ",
            "continueWithoutRenewing": "Продолжить без продления",
            "continueWithoutReg": "Продолжить, не присоединяясь",
            "renewMembership": "Продлить членство в клубе ",
            "phoneNumber": "Номер мобильного телефона ",
            "textForConfirmSms": "Я разрешаю клубу время от времени присылать мне ре-кламные материалы посредством текстовых сообщений. Я могу удалить себя из списка рассылки в любое время.",
            "textForConfirmSmsScreenReader": "Настоящим я разрешаю клубу время от вре-мени присылать мне рекламные материалы посредством сообщений, и я могу удалить себя из списка рассылки в любое время.",
            "textForConfirmClubRules": "Подтверждаю регламент клуба ",
            "textForConfirmClubRulesScreenReader": "Подтверждаю регламент клуба ",
            "termsContinue": "Перейдем к бонусам ",
            "termsFirstContinue": "Полные данные",
            "termsBody": " Кажется, Вы еще не завершили регистрацию в клубе \ некоторые последние подробности и преимущества с Вами :)",
            "termsTitle": "Привет, {{имя}}",
            "first_name": "Имя ",
            "last_name": "Фамилия ",
            "email": "Электронная почта ",
            "zehut": "Удостоверение личности",
            "phone": "Мобильный телефон ",
            "anniversary": "Дата свадьбы",
            "birth_date": "Дата рождения",
            "regErrorTitle": "Что-то пошло не так ",
            "email_not_unique": "Этот адрес электронной почты уже используется, введите другой адрес электронной почты.",
            "regError": "Процесс присоединения не может быть завершен, необходимо об-ратиться в ресторан. ",
            "no_registration_items": "В меню не найдена возможность продле-ния/присоединения ",
            "continueErrorTitle": "Привет",
            "continueErrorText": "Чтобы воспользоваться преимуществами клуба, товары необходимо добавлять в корзину. ",
            "understood": "Понятно",
            "minimum_order_required_title": "Минуточку… ",
            "minimum_order_required_body": "После расчета бонуса сумма, подлежащая оплате, составляет {{currency}}{{total}} и меньше минимального заказа - {{currency}}{{amount}}",
            "minimum_order_required_button": "Вернуться в меню ",
            "get_benefits_401_error": "Your session has expired. Please log in again to the Loyalty club to continue using the system."
        },
        "validation_errors": {
            "required": "Обязательное поле ",
            "max_length": "Ограничение в 256 символов",
            "invalid_email": "Неверный адрес электронной почты ",
            "invalid_phone": "Неверный номер телефона ",
            "load_range": "Пожалуйста, введите сумму в пределах диапазона ",
            "no_decimal": "Необходимо ввести всю сумму ",
            "only_5_digits": "Поле ограничено 5 символами ",
            "min_2_digits": "В поле можно ввести минимум 2 символа ",
            "only_numbers": "В поле можно вводить только числа ",
            "only_letters": "В поле можно вводить только буквы ",
            "invalid_zip_code": "Неправильный индекс ",
        },
        "_WEIGHT": {
            "units": "единица ",
            "price_per_weight": "цена за {{unit}} = {{price}}",
            "item_weight": "Вес товара ",
            "kg": "(кг)",
            "gr": "гр (gr)",
            "lb": "фунт (lb)",
            "oz": "унция (oz)",
            "l": "литр (l)",
            "ml": "мл (ml)",
            "GRAM": "гр"
        },
        "_GIFTCARD": {
            "title": "У Вас есть предоплаченная карта или подарочная карта, которую можно погасить?",
            "gift_card": "Подарочная карта ",
            "select_card": "Выберите карту ",
            "please_select_card": "Пожалуйста, выберите карту ",
            "edit_card": "Обновление карты ",
            "add_card": "Подтвердить",
            "number": "Номер карты ",
            "amount": "Сумма к оплате",
            "balance": "Остаток на карте",
            "max": "Максимум для использования в этом заказе ",
            "cvv": "CVV",
            "cvv_required": "CVV необходимо заполнить.",
            "invalid_cvv": "Введенный номер CVV неверен.",
            "inuse_error": "Одну и ту же карту нельзя активировать более одного раза за за-каз. ",
            "balance_error": "На карте нет остатка ",
            "notfound_error": "Данные карты неверны или карта не существует в системе ",
            "delete_prompt": "Удалить карту из заказа?",
            "no_order_amount": "Оплата уже покрывает заказ, дополнительная оплата не требуется 😎 ",
            "reset_split_payments_prompt": "Добавление или обновление карты приведет к сбросу разделенных платежей! Продолжать?",
            "please_finish_payment": "Нажмите Оплата, чтобы завершить ",
            "otp_required": "На номер телефона будет отправлен код верификации ",
            "otp_required_alt": "На номер телефона будет отправлен код верификации ",
            "otp_required_alt_num": "{{v}}-****-***",
            "otp_alt": "код верификации ",
            "invalid_otp": "Введенный код верификации неверен ",
            "resend_otp": "Отправьте мне еще раз",
            "cannotPayOnOnline": "Данной картой нельзя оплатить онлайн-покупки, можно платить только в ресторане. ",
        },
        "_DELAYED": {
            "title": "Запланировать заказ ",
            "service_caption": "на другой день ",
            "supply_time": "дата доставки ",
            "select_date": "Пожалуйста, выберите дату доставки ",
            "select_slot": "Пожалуйста, выберите время доставки ",
            "service_today": "Заказ на сегодня ",
            "service_future": "Будущий заказ",
            "for_hour": "на {{val}}",
            "for_date": "на {{val}}",
            "between": "между часами",
            "at_hour": "в ",
            "select_slot_prompt": "Пожалуйста, выберите время доставки ",
            "service_disabled_today": "Сегодня данная услуга закрыта!"
        },
        "CHECKOUT_STEP": {
            "extra_offers": "Дополнительные предложения ",
            "contact": "мои данные ",
            "gratuity": "Чаевые",
            "pay": "Платеж",
            "summary": "резюме",
        },
        "WEB_CONTAINER": {
            "start_logo": "Табит - главная страница ",
            "language_select": "Изменить язык сайта на английский ",
            "home": "главная ",
            "search_now": "Поиск ",
            "search": "Поиск ресторанов, кухонь, стилей или вкусов",
            "find_table": "Мне нашли столик ",
            "show_more": "показать еще ",
            "service": "Услуга ",
            "back_to_regular_results": "Вернуться к обычным результатам ",
            "booking_bar": {
                "day": "день ",
                "day_screen_reader": "Выберите день ",
                "choose_day": "Выберите день ",
                "hour": "час ",
                "hour_screen_reader": "Выберите час ",
                "choose_hour": "В какое время?",
                "guests": "Гости",
                "guests_screen_reader": "выбрать  количество гостей",
                "choose_guests": "сколько будет человек",
                "address": "адрес",
                "category": "категория ",
                "all_categories": "Все категории ",
                "area": "регион",
                "all_areas": "Все регионы ",
                "tags": "теги",
                "tags_screen_reader": "Выбор тегов, таких как стиль ресторана, тип еды и дру-гие характеристики. ",
                "clear_tags": "Очистить теги ",
                "rating": "рейтинг ",
                "rating_screen_reader": "Выбрать рейтинг ",
                "all_ratings": "рейтинги ",
                "price": "цена ",
                "price_screen_reader": "Выбор ценового диапазона ",
                "all_prices": "Все цены "
            },
            "book_a_table": {
                "tab_title": "заказать место",
                "main_title": "Когда проголодаешься,\ еда становится вкуснее",
                "main_subtitle": "Просто скажите когда, мы позаботимся обо всем остальном ",
                "new_restaurants_in_tabit": "Новые рестораны в Табит",
                "new_restaurants_in_tabit_screen_reader": "מ Новые рестораны в Табит ",
                "kitchens": "кухни ",
                "cuisine": "кухни ",
                "restaurants_by_areas": "Рестораны по регионам ",
                "near_me": "недалеко от меня ",
                "show_more": "показать еще ",
                "results_page_title": "Заказать место",
                "results_page_description": "Выберите дату, время и количество гостей и нажмите Найти мне столик.",
                "no_results_found": "Похоже, что в настоящее время ни в одном из ресторанов нет свободных мест. Пожалуйста, выберите другую дату или время, и мы будем искать вас снова.",
                "select_future_date": "Выбранная дата прошла, выберите еще раз"
            },
            "deliveries": {
                "tab_title": "Доставка/TA",
                "tab_title_screen_reader": "Доставка и самовывоз ",
                "main_title": "что ты хочешь съесть",
                "main_subtitle": "Просто скажите где, мы позаботимся обо всем остальном ",
                "results_page_title": "Доставка/TA",
                "results_page_description": "",
                "no_results_found": "Кажется, в настоящее время нет ресторанов, осуществля-ющих доставку по выбранному вами адресу"
            },
            "restaurants": {
                "tab_title": "рестораны ",
                "main_title": "Найти, попробовать, развлечься ",
                "main_subtitle": "Места, достойные внимания и самые популярные сегодня ",
                "results_page_title": "рестораны ",
                "results_page_description": "",
                "no_results_found": "Неприятность... По вашему запросу нет результатов. "
            },
            "marketplace": {
                "tab_title": "Tabit Gift It",
                "main_title": "Tabit Gift It",
                "home_page_section_title": "Подарочная карта в самые достойные рестора-ны!",
            },
            "web_article": {
                "bottom_content_button": "к следующей статье >>"
            }
        },
        "NOTIFICATIONS_WIDGET": {
            "messages": "Сообщения ",
            "clear_all": "очистить все "
        },
        "DASHBOARD": {
            "we_have_missed_you!": "Забронируйте столик здесь!",
            "when_we_would_see_you?": "Просто скажи когда, остальное сделаем мы ",
            "all_the_restaurants_click_away": "Просто скажи когда, остальное сделаем мы ",
            "BUTTONS": {
                "delivery": "Доставка ",
                "takeaway": "самовывоз ",
                "book": "Заказать место "
            }
        },
        "WEB_HOME": {
            "H1_TITLE": "Забронируйте место здесь!",
            "H2_TITLE": "Просто скажи когда, в каком ресторане, а остальное сделаем мы. ",
        },
        "WEB_BOOK": {
            "H1_TITLE": "Заказать столик ",
            "H2_TITLE": "Поиск столика в ресторанах Tabit",
        },
        "KFC": {
            "title": "KFC {{country}}",
            "main_sub_title": "Самая вкусная курица в мире ",
            "sec_sub_title": "Пальчики оближешь!"
        },
        "SHIOU": {
            "title": "Ты знаешь, что такое суши ",
            "sub_title": "Кратчайший путь от воображения к языку, приятного аппетита!"
        },
        "BIGA": {
            "title": "BIGA",
            "sub_title": "Bakery & kitchen"
        },
        "FOODBA": {
            "title": "Foodba",
            "sub_title": "Приложение для доставки и развлечений в Беэр-Шеве ",
            "seo_title": "Foodba | Беэр-Шева ",
        },
        "MAISON": {
            "title": "MAISON KAYSER",
            "sub_title": "ARTISAN BOULANGER, PÂTISSIER, CAFÉ & RESTAURANT"
        },
        "GRECO": {
            "title": "GRECO",
            "sub_title": "Настоящий греческий праздник "
        },
        "USER_POINTS": {
            "btw_you_have": "Кстати, у тебя уже есть ",
            "btw_you_dont_have": "Кстати, у тебя еще нет баллов ",
            "for_each_order_more_bonus": "За каждое бронирование столика вы получаете еще 10",
            "for_each_order_bonus": "За каждый заказ столика вы получите 10"
        },
        "GIFT_CARD_SHOP": {
            "tracker": {
                "to_from": "Первоначальные подробности ",
                "when_where": "Варианты доставки ",
                "checkout": "оплата "
            },
            "card_recipient": {
                "title": "Для кого этот подарок? "
            },
            "user_wallet": {
                "title": "способ оплаты ",
                "other": "Другие способы оплаты ",
            },
            "choose_amount": {
                "required_gift_sum": "Запрашиваемая сумма ",
                "current_balance": "текущий баланс ",
                "you_pay": "Запрос ",
                "you_get": "Получение",
            },
            "create_card": {
                "title": "Детали подарка ",
                "to": "Для кого этот подарок ",
                "from": "От кого подарок?",
                "first_name": "Имя",
                "last_name": "фамилия ",
                "send_to_friend": "Отправьте подарок другу ",
                "where_to_send_the_gift": "Куда отправить подарок?",
                "your_message": "Пожелание ",
                "your_message_placeholder": "Несколько слов (по желанию)",
                "enter_amount": "Сумма подарка ({{min_amount}} – {{max_amount}})",
                "enter_amount_error": "Введите сумму в соответствии с диапазоном ",
                "send_to_phone": "Куда отправить подарок?",
                "toPhone": "на телефон ",
                "toEmail": "по электронной почте ",
                "required_gift_sum": "Запрашиваемая сумма ",
                "required_load_sum": "Сколько взимать?",
                "billing_address": "Адрес для выставления счета ",
                "state": "Страна ",
                "select_state": "Выберите страну ",
                "city": "Город ",
                "address": "Адрес ",
                "zip_code": "Почтовый индекс ",
                "legalText": "Этот ваучер действителен в течение пяти лет в соответствии с раз-делом 14(h) Закона о защите прав потребителей. // Только для США/Иллинойса.",
                "legalTextProcess": "Эта транзакция предоставляет ваучер на покупку, действи-тельный в течение пяти лет, в соответствии с разделом 14(h) Закона о защите прав потре-бителей. // Только для США/Иллинойса.",
                "expirationDate": "This card expires 3 years after supply"
            },
            "delivery_options": {
                "best_send_to": "Варианты доставки ",
                "toPhone": "телефон ",
                "toEmail": "Электронная почта ",
                "phone": "Номер телефона ",
                "email": "Адрес электронной почты "
            },
            "checkout": {
                "title": "Резюме ",
                "gift_card_total": "Резюме ",
                "gift_card": "подарочная карта ",
                "send_to": "Отправить мне ",
                "to": "Получатель подарка ",
                "from": "Отправитель подарка ",
                "message": "Пожелание",
                "send_receipt_to": "Куда отправить подтверждение оплаты?",
                "send_all_receipts_to": "Куда отправить карту и подтверждение оплаты?",
                "myPhone": "на мой телефон ",
                "myEmail": "На мою электронную почту ",
                "receiptContactPhone": "телефон ",
                "receiptContactEmail": "Электронная почта ",
                "Email": "Электронная почта ",
                "total": "Всего к оплате",
                "approve_notifications": "Notify me When the card is viewd by the recipient.",
                "approve_terms": "Я прочитал и одобрил ",
                "approve_terms_and": "ואת",
                "approve_terms_link_1": "Условия использования ",
                "approve_terms_link_2": "политика конфиденциальности "
            },
            "buttons_texts": {
                "pay": "к оплате {{currency}}{{amount}}",
                "next": "Продолжить",
                "go_back": "вернуться ",
                "you_get": "Получать {{currency}}{{amount}}",
                "website": "Сайт ресторана ",
                "recipient_type_me": "себе ",
                "recipient_type_not_me": "Другому человеку "
            },
            "validation_errors": {
                "required": "Обязательные поля ",
                "max_length": "Ограничение в 256 символов ",
                "invalid_email": "Неверный адрес электронной почты ",
                "invalid_phone": "Неверный номер телефона ",
                "load_range": "Пожалуйста, введите сумму в пределах диапазона ",
                "no_decimal": "Необходимо ввести всю сумму ",
                "only_5_digits": "Поле ограничено 5 символами ",
                "min_2_digits": "В поле можно ввести минимум 2 символа ",
                "only_numbers": "В поле можно вводить только числа ",
                "only_letters": "В поле можно вводить только буквы ",
                "invalid_zip_code": "Неправильный индекс ",
            },
            "modals": {
                "please_wait": "Минуточку…",
                "success": {
                    "title": "Спасибо! ",
                    "body": "Ваш подарок уже в пути ",
                    "primary_button": "Закрыть",
                    "secondary_button": "Я хочу еще один подарок ",
                    "top_up": {
                        "body": "Загрузка завершена ",
                        "secondary_button": "Доплата ",
                    }
                },
                "error": {
                    "title": "Что-то пошло не так ",
                    "body": "Платеж не прошел ",
                    "body_general_error": "Произошла ошибка ",
                    "body_error_occurred": "Произошла ошибка\ С рестораном необходимо связаться по телефону",
                    "body_validation_mismatch": "Произошла ошибка\nПожалуйста, проверьте детали платежа",
                    "body_card_declined": "Произошла ошибка\nПожалуйста, проверьте дета-ли платежа",
                    "shop_inactive_error": "На данный момент услуга неактивна ",
                    "card_inactive_error": "Ошибка при создании карты, обратитесь в ресторан. ",
                    "primary_button": "Закрыть ",
                    "secondary_button": "Попробуйте еще раз "
                },
                "pay": {
                    "pay_with_cibus": "Оплата через Сибус ",
                    "card_number": "Номер карты "
                }
            },
            "redeem_card": {
                "title": "Остаток:",
                "show_qr": "Для оплаты необходимо предъявить QR-код в ресторане",
                "code": "Номер карты ",
                "cvv": "CVV",
                "recipient_title": "Привет  ",
                "recipient_text": "Вы получили {{amount}}",
                "recipient_from_text": "От {{sender}}"
            },
            "general": {
                "gift_card_purchase": "Подарочная карта для друга "
            }
        },
        "FOOTER": {
            "area_link_prefix": "Рестораны в ",
            "other_links": "Дополнительные ссылки ",
            "contact": "связаться ",
            "join_us": "Присоединяйтесь к нам ",
            "to_tabit_site": "на сайт Табит ",
            "contact_us": "Связаться с нами ",
            "privacy_policy": "Политика конфиденциальности ",
            "club_terms": "Правила клуба ",
            "terms_of_use": "Условия использования ",
            "career": "Требуются ",
        },
        "loyalty-terms-dialog": {
            "title": "У нас новый клуб клиентов!",
            "descriptionMain": "Мы подготовили для вас действительно стоящие бонусы ",
            "descriptionSecond": "Две небольшие вещи, чтобы вы могли насладиться пре-имуществами:",
            "validation": "Вы уверены, что хотите отказаться от клубных привилегий?",
            "added_to_your_cart": "как здорово! Мы обновили скидку на товары в корзине. ",
            "ok": "Подтвердить",
            "remove_reward": "Удалить льготу?",
            "remove_reward_btn_yes": "Да, удалить ",
            "remove_reward_btn_no": "Нет ",
        },
        "loyalty-rewards-dialog": {
            "auto-rewards-title": "We've added these rewards to your order:",
            "my-rewards": "Мои бонусы",
            "all-my-rewards": "Ко всем моим бонусам",
            "all-my-rewards-single": "отведи меня на пользу",
            "club-rewards-will-be-calculated-before-payment": "Клубные преимущества будут обновлены перед оплатой",
            "back-to-order": "Вернуться к заказу ",
            "done-thanks": "Я закончил, спасибо ",
            "select_between_range": "выбрано {{quantity}} из {{max}}",
            "loyalty_reward_terms_dialog_title": "у вас это есть!",
            "loyalty_reward_terms_dialog": "Преимущество активировано и будет автомати-чески обновлено после оплаты..",
            "loyalty_reward_terms_dialog_ok": "меню ",
            "loyalty_reward_terms_dialog_cancel": "отмена ",
            "not-available": "Предложение на данный момент недоступно ",
        },
        "pickup-order-dialog": {
            "Branch_pickup_order": "Самовывоз заказа из ",
            "address": "по адресу ",
        },
        "SIGN-UP-DATE-ERROR": "Введена неверная дата ",
        "general_credit_card": {
            "form": {
                "card_number": "Номер карты:",
                "expiration": "срок действия:",
                "cvv": "CVV:",
                "amount_to_pay": "PAY"
            },
            "errors": {
                "invalid_exp": "Пожалуйста, заполните все поля ",
                "invalid_cvv": "Неверный CVV",
            }
        },
        "REMOVE_ACCOUNT": {
            "disclaimer_1": "Завершение процесса обусловлено двухэтапной верификацией с использованием идентификационного кода, который будет отправлен по SMS.",
            "disclaimer_2": "Удаление произойдет немедленно. ",
            "disclaimer_3": "Преимущества и бонусы, связанные с вашим номером телефона, будут сохранены даже после удаления учетной записи из приложения, и их можно будет использовать по различным каналам."
        },
        "tou_offer": {
            "required": "Обязательный ",
            "additional_options": "Дополнительные варианты ",
            "group_guiding_text": {
                "before_selection": {
                    "choose_at_least": "Выберите хотя бы {{min}}",
                    "choose_at_least_singular": "выберите один ",
                    "select_at_least": "Блюдо должно содержать как минимум еще {{min}} ин-гредиентов",
                    "select_at_least_singular": "Блюдо должно содержать хотя бы еще один ин-гредиент. ",
                    "select_at_least_no_max": "Блюдо должно содержать как минимум еще {{min}} ингредиентов",
                    "select_at_least_no_max_singular": "Блюдо должно содержать хотя бы еще один ингредиент. ",
                    "select_between_range": "Выберите от {{min}} до {{max}} товаров",
                    "select_between_range_with_defaults": "Блюдо должно содержать как ми-нимум еще {{min}} ингредиентов",
                    "select_between_range_with_defaults_singular": "Блюдо должно содержать хотя бы еще один ингредиент",
                    "select_minimum_items": "Блюдо должно содержать как минимум еще {{min}} ингредиентов",
                    "select_minimum_items_singular": "Блюдо должно содержать хотя бы еще один ингредиент",
                    "select_quantity": "выберите {{мин}}",
                    "select_quantity_singular": "Выберите один ",
                    "select_quantity_singular_without_default": "Выберите один ",
                    "select_up_to": "Вы можете выбрать до {{max}} товаров",
                    "select_up_to_singular": "Выберите еще один ",
                    "select_up_to_without_defaults": "Вы можете выбрать до {{max}} товаров"
                },
                "during_selection": {
                    "choose_at_least": "Выберите хотя бы {{min}}",
                    "choose_at_least_singular": "Выберите хотя бы еще один ",
                    "select_at_least": "Блюдо должно содержать как минимум еще {{min}} ингредиентов",
                    "select_at_least_singular": "Блюдо должно содержать хотя бы еще один ин-гредиент",
                    "select_at_least_no_max": "Блюдо должно содержать как минимум еще {{min}} ингредиентов",
                    "select_at_least_no_max_singular": "Блюдо должно содержать хотя бы еще один ингредиент",
                    "select_between_range": "Выберите {{quantity}} из {{max}} товаров",
                    "select_between_range_with_defaults": "Выберите {{quantity}} из {{max}} то-варов ",
                    "select_between_range_with_defaults_singular": "Выберите {{quantity}} из {{max}} товаров",
                    "select_minimum_items": "Блюдо должно содержать как минимум еще {{min}} ингредиентов",
                    "select_minimum_items_singular": "Блюдо должно содержать хотя бы еще один ингредиент",
                    "select_quantity": "Выберите {{quantity}}",
                    "select_quantity_singular": "Выберите еще один ",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "Выберите {{quantity}} из {{max}} товаров ",
                    "select_up_to_singular": "Выбран один товар ",
                    "select_up_to_without_defaults": "Можно выбрать до {{max}} товаров",
                },
                "after_selection": {
                    "choose_at_least": "Выбрано {{quantity}} товаров",
                    "choose_at_least_singular": "Выбран один товар ",
                    "select_at_least": "",
                    "select_at_least_singular": "",
                    "select_at_least_no_max": "",
                    "select_at_least_no_max_singular": "",
                    "select_between_range": "Выбрано {{quantity}} товаров",
                    "select_between_range_with_defaults": "Выбрано {{quantity}} товаров",
                    "select_between_range_with_defaults_singular": "Выбран один товар ",
                    "select_minimum_items": "",
                    "select_minimum_items_singular": "",
                    "select_quantity": "Выбрано {{quantity}}",
                    "select_quantity_singular": "Выбран один ",
                    "select_quantity_singular_without_default": "",
                    "select_up_to": "Выбрано {{quantity}} товаров",
                    "select_up_to_singular": "Выбран один товар ",
                    "select_up_to_without_defaults": "Выбрано {{quantity}} товаров"
                }
            },
        },
        "new_restaurant_page": "Страница ресторана ",
    },

};
