import { Component, OnInit, OnDestroy, OnChanges, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { TimerUtilsService } from '../../_core/timer.utils.service';
import { MixpanelService, mixpanelGeneralEventTypesMap, MIXPANL_GENERAL_EVENT_TYPE, CLIENT_TYPE } from '../../_core/mixpanel.service';

@Component({
    selector: 'app-sign-in-by-phone-verify-code-form',
    templateUrl: './sign-in-by-phone-verify-code-form.component.html',
    styleUrls: ['./sign-in-by-phone-verify-code-form.component.scss'],
})
export class SignInByPhoneVerifyCodeFormComponent implements OnInit, OnDestroy, OnChanges {

    public formGroup = new UntypedFormGroup({
        'codeControl': new UntypedFormControl('', [Validators.required, Validators.pattern("[0-9]+")])
    });

    @Input() formModel = { code: '' };
    @Input() phone: string = '';
    @Input() disabled: boolean = false;
    @Input() thisStepCurrentlyVisible: boolean;
    @Input() dialogMode: boolean = false;
    @Input() mixpanelTrackingData: any = {};

    @Output() onSubmit = new EventEmitter<{}>();
    @Output() onResend = new EventEmitter<{}>();
    @Output() onBack = new EventEmitter<{}>();

    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;
    private destroy$ = new Subject();

    public countdownValue$ : Observable<number>;
    public countdownDone: boolean = false;
    public domain: any;

    // Due to iOS limitations - the autofocus only works on the first element in the screen (and we use it on the Phone field)
    //@ViewChild('codeInputElement', {static: false})    codeInputElement:ElementRef;

    constructor(
        public appService: AppService,
        public configurationsService: ConfigurationsService,
        public mixpanelService: MixpanelService,
    ) { }

    ngOnInit() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData
        .subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;
                });
            }
        })
    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            if (changes?.thisStepCurrentlyVisible) {
                // Start listening for the SMS
                if (window['cordova'] && window['cordova']['plugins'] && window['cordova']['plugins']['smsRetriever']) {
                    window['cordova']['plugins']['smsRetriever']['startWatching'](
                        (result: any) => {
                            console.debug('SMSRetriever > startWatching > Success:', result);
                            if (result && result.Message && result.Message.match(/\d+/)) {
                                this.formModel.code = result.Message.match(/\d+/)[0];
                            }
                        },
                        (err: any) => {
                            console.warn('SMSRetriever > startWatching > Error:', err);
                        }
                    );
                }
                // We want the "Resend" button to appear ~5 seconds after this step becomes visible.
                // Now, since the mat-tab are using animations (when moving between steps), it "cancels" the child's (this view) animation
                // So the result is, that we can't apply a delay to the "resend" button through the 'animations' above.
                // Instead, we need to detect the change and trigger it manually with a delay.
                // If we could instruct mat-tab to do "animateChild" this could have been solved in a nicer way. But unfortunately, this does not seem to be possible.
                if (this.thisStepCurrentlyVisible) {
                    // We reset the "code" input field when this view becomes visible.
                    this.formGroup.reset();
                }
            }
        });
    }

    _verifyCode(form) {
        const trackingData = {
            ...this.mixpanelTrackingData,
            Product: this.dialogMode ? CLIENT_TYPE.ORD : CLIENT_TYPE.APP
        }
        this.mixpanelService.track(mixpanelGeneralEventTypesMap[MIXPANL_GENERAL_EVENT_TYPE.otp_inserted], trackingData);

        if (!form.valid) return;

        this.onSubmit.emit(this.formModel);
    }

    _resendCode() {
        this.startResendCodeCountdown();
        this.onResend.emit(this.formModel);
        // Resetting the value of the field
        this.formGroup.reset();
    }
    
    _back() {
        this.onBack.emit({});
    }
    
    startResendCodeCountdown(): void {
        this.countdownDone = false;

        this.countdownValue$ = TimerUtilsService.startCountdownTimer(30, 1000, this.destroy$)
            .pipe(tap(value => {
                if (value === 0) {
                    this.countdownDone = true;
                }
            }));
    }

    get validCodeLength(): boolean {
        return this.formModel?.code?.length >= 4;
    }

}
