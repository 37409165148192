import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-preorder-dialog',
    templateUrl: './preorder-dialog.component.html',
    styleUrls: ['./preorder-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class PreorderDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {
    }

    getBeginTime(): string {
        let timeStamp = this.data.serviceAvailableTimestamp;
        if (!timeStamp) return '';
        return moment(timeStamp).format('H:mm');
    }

}
