import { Component, OnInit, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { AppService } from '../../app.service';
import { WebContainerService } from '../../web-container/web-container.service';
import { TagsService } from '../../_core/tags.service';
import { map } from 'rxjs/operators';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'food-category-item',
    templateUrl: './food-category-item.component.html',
    styleUrls: ['./food-category-item.component.scss'],
    animations: [
        trigger('categoryCardAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(1.05)' }),
                animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(0.8)' })),
                animate('0.2s 0s ease-in', style({ transform: 'scale(1)' }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgIf, TranslateModule, SafeStylePipe]
})
export class FoodCategoryItemComponent implements OnInit {

    tag: any = null;
    _tagId: any = null;

    @Input() tagId: string;

    @Input()
    set customTag(customTag: {}) {
        if (customTag) {
            this.tag = customTag;
        }
    }
    get customTag(): {} { return this.customTag; }

    @Input() multiline: boolean;
    @Input() parentRoute: string;
    @Input() web: boolean;
    @Input() index: number;

    constructor(
        public appService: AppService,
        private tagsService: TagsService,
        private webContainerService: WebContainerService,
    ) { }

    isMultiline () {
        return this.multiline !== undefined;
    }

    ngOnInit() {
        this.tagsService.tagsData$.pipe(map(tags => {
            return tags.find(tag => tag._id === this.tagId);
        })).subscribe(tag => {
            this.tag = tag;
        });
    }

    /**
     * Extract me out! The parent needs to decide on what click will do!
     */
    clickTag() {
        let route = this.appService.redirectValueByLocale('restaurants', 'ROUTE');
        if (this.web) {
            let tagId = this.tag._id;
            if (tagId) this.webContainerService.updateSharedSearch({ skip: 0, tags: [tagId] });
            else console.error('Food tag clicked but no real tag found for:', this.tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label);
            this.appService.redirect([route]);
        } else {
            this.appService.redirect(['/home/sites'], { queryParams: { tag: this.tag._id } });
        }

    }

}
