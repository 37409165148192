import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntlTelInputComponent } from './intl-tel-input.component';
import { IntlTelCountryDialogComponent } from './intl-tel-country-dialog/intl-tel-country-dialog.component';
import { SearchPipe } from './search.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        IntlTelInputComponent,
        IntlTelCountryDialogComponent,
        SearchPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        MatIconModule,
        ReactiveFormsModule
    ],
    exports: [
        IntlTelInputComponent,
    ],
})
export class IntlTelInputModule { }
