<div id="nearme-module-bar" class="module-bar splash">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="navigateBack()">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <div class="title flex-grow-1">
            <ng-container *ngIf="!appService.skin">{{'near_me' | translate}}</ng-container>
        </div>

        <button id="btn-center-map" class="menu" mat-icon-button (click)="centerMap()">
            <mat-icon svgIcon="center_map"></mat-icon>
        </button>

        <button class="menu" mat-icon-button routerLink="/home/sites">
            <mat-icon class="list" svgIcon="list view"></mat-icon>
        </button>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<!--<span class="fab-fixed-bottom animated fadeIn" *ngIf="showScrollUp">
    <button mat-fab (click)="appService.scrollToTop('#sites-module-content')">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</span>-->

<div id="nearme-module-splash" class="module-splash">
    <google-map height="100%" [center]="{lat: currentLocation.lat, lng: currentLocation.lng}" [zoom]="initialZoomLevel" [options]="{
          disableDefaultUI: true,
          zoomControl: false,
          gestureHandling: 'greedy',
          streetViewControl: false,
          backgroundColor: styleService.isDarkMode ? darkModeMapBackgroundColor : lightModeMapBackgroundColor,
          styles: styleService.isDarkMode ? mapStyleDark : []
        }" (mapInitialized)="onMapReady($event)" (mapClick)="onMapClick()" (boundsChanged)="onBoundsChanged($event)">
    
        <map-marker *ngFor="let site of orgs | filterBy: {external: true}"
            [position]="{lat: site.location.lat, lng: site.location.lng}" [options]="{ icon: siteIcon(site), zIndex: 1 }"
            (mapClick)="clickedMarker(site._id)">
        </map-marker>
    
        <map-marker *ngFor="let site of orgs | filterBy: {external: false}"
            [position]="{lat: site.location.lat, lng: site.location.lng}" [options]="{ icon: siteIcon(site), zIndex: 2 }"
            (mapClick)="clickedMarker(site._id)">
        </map-marker>
    
        <map-marker [position]="{lat: locationService.currentLocation.lat, lng: locationService.currentLocation.lng}"
            [options]="{ icon: lightModeMapBackgroundColor, zIndex: 3 }">
        </map-marker>
    </google-map>
  
</div>

<mat-list @focusSite [@animateSwitchSites]="siteAnimationSwitch" *ngIf="siteIdInFocus" class="focused-site-container generic-list">
    <site-item [siteId]="siteIdInFocus" (click)="siteClick(siteIdInFocus)" single></site-item>
</mat-list>
