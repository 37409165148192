import { Component, OnInit } from '@angular/core';
import { BookService } from '../../_core/book.service';
import { WebFooterComponent } from '../web-footer/web-footer.component';
import { EventsListComponent } from '../../events/events-list/events-list.component';

@Component({
    selector: 'web-events',
    templateUrl: './web-events.component.html',
    styleUrls: ['./web-events.component.scss'],
    standalone: true,
    imports: [EventsListComponent, WebFooterComponent]
})
export class WebEventsComponent implements OnInit {

    constructor(
        private bookService: BookService,
    ) { }

    ngOnInit() {
        this.bookService.startWithoutSite();
    }

}
