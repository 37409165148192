import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { forkJoin, Subscription } from 'rxjs';
import moment from 'moment';
import { get, extend } from 'lodash-es';

import { environment } from './../../environments/environment';
import { AppService } from '../app.service';
import { EntityService } from '../_core/entity.service';
import { BookService } from '../_core/book.service';
import { DialogsService } from '../_core/dialogs.service';
import { OrganizationsService } from '../_core/organizations.service';
import { OccasionsService } from '../_core/occasions.service';

import { TabitbookStartComponent } from './tabitbook-start/tabitbook-start.component';
import { TranslateModule } from '@ngx-translate/core';
import { TabitbookEndComponent } from './tabitbook-end/tabitbook-end.component';
import { TabitbookDetailsComponent } from './tabitbook-details/tabitbook-details.component';
import { TabitbookAltComponent } from './tabitbook-alt/tabitbook-alt.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf } from '@angular/common';
import { WidgetOpenerComponent } from '../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-tabit-book',
    templateUrl: './tabit-book.component.html',
    styleUrls: ['./tabit-book.component.scss'],
    standalone: true,
    imports: [
        BlockUIModule,
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        MatButton,
        NgIf,
        MatTabGroup,
        MatTab,
        TabitbookStartComponent,
        TabitbookAltComponent,
        TabitbookDetailsComponent,
        TabitbookEndComponent,
        TranslateModule,
    ],
})
export class TabitBookComponent implements OnInit, OnDestroy {
	@BlockUI() blockUI: NgBlockUI;
    @ViewChild(TabitbookStartComponent, {static: false})    private tabitBookStartComponent: TabitbookStartComponent;

	loading: boolean = true;
	loadingStep: boolean = false;
    editorToSet: string = '';
	site: any = {};
	siteName: string = '...';
	step: number = 0
	pms: any = [];
	date = new Date();
	$storage: any = {};
	bindedGotoStart: Function;

	childOptions: any = {
		startBlock: () => { this.blockUI.start() },
		stopBlock: () => { this.blockUI.stop() },
		postTempReservation: (standby_reservation) => { this.postTempReservation(standby_reservation) },
		selectAltSlot: (area, slot) => { this.selectAltSlot(area, slot) },
		gotoStart: () => { this.gotoStart() },
		gotoAlt: () => { this.gotoAlt() },
		gotoDetails: () => { this.gotoDetails() },
		gotoEnd: () => { this.gotoEnd() },
		close: () => { this.close() }
	}

    private androidBackSubscription: Subscription;
    private routeQueryParamsSubscription: Subscription;

	constructor(
		public appService: AppService,
		public entityService: EntityService,
		public bookService: BookService,
		public dialogsService: DialogsService,
		public router: Router,
        private route: ActivatedRoute,
        private organizationsService: OrganizationsService,
        private occasionsService: OccasionsService,
		public elementRef: ElementRef,
        private ngZone: NgZone,
	) {
        this.bindedGotoStart = this.gotoStart.bind(this);
	}

    makeNextTickPromise(payload?: any) {
        return new Promise(r => setTimeout(() => r(payload)));
    }

	ngOnInit() {

        this.routeQueryParamsSubscription = this.route.queryParams.subscribe(params => {
            console.debug('=== params: ', params);
            this.site = this.organizationsService.getOrganizationWithHandle(params.site);
            console.debug('=== site: ', this.site);
			this.siteName = this.site && this.site.name;
            let presetBookingDetails = params.presetBookingDetails === undefined ? null : this.copyBookingDetails(params.presetBookingDetails, {value: params.preferenceValue, text: params.preferenceText});

			if (!presetBookingDetails) {
                this.bookService.resetStorage();
                this.editorToSet = 'date';
            }

            // Forkjoin will complete olnly when all of the Observables will complete:
            forkJoin([

                this.organizationsService.getFullOrganization(this.site ? this.site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier : params.site),
                this.occasionsService.occasions(params.site),

            ]).subscribe(([fullOrganization, occasions]: [any, any[]]) => {

                this.loading = false;
                this.site = fullOrganization;
                this.siteName = this.site.name;

                this.bookService.start(this.site.bookingData, params.site, presetBookingDetails, occasions);

                // Only after next tick the 'loading' will make the ChildView of tabitbookStart available.
                // Next uncommented line looks terrible. Let's just start with that: fullOrganization.bookingData === site
                // Currently not changing the name: 'site' that was given to 'site booking details'
                // Same naming scheme is persistent at bookService.start
                this.makeNextTickPromise(this.site.bookingData).then(site => {

                    this.$storage = this.bookService.$storage;

                    if (presetBookingDetails) {
                        if (presetBookingDetails.updatedTimestamp) {

                            let datePicked = this.getAvailableDateFromTimestamp(presetBookingDetails.updatedTimestamp);

                            if (datePicked) {
                                this.tabitBookStartComponent.selectDate(datePicked);
                            }

                            let time = moment(presetBookingDetails.updatedTimestamp);

                            this.tabitBookStartComponent.selectTime({
                                text: time.format("HH:mm"),
                                value: this.bookService.getMinutesFromMoment(time)
                            });

                            this.tabitBookStartComponent.setEditor('date');
                        }

                        if (!params.noReservation) return this.postTempReservation(!!params.standby, site);
                    }
                });

			}, err => {
                console.error('Error getting booking data:', err);
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'MESSAGES.GENERAL_ERROR'
                    }).then(() => {
                        this.loading = false;
                        this.makeNextTickPromise().then(() => this.goBack());
                    });
                });
			});
		})

        this.appService.androidBackButton
            .pipe(untilDestroyed(this))
            .subscribe(() =>  {
                this.ngZone.run(() => {
                    this.goBack()
                });
            });

        this.appService.confirmBeforeRedirect = true;
	}

    copyBookingDetails(updatedTimestamp: any, preference?: any) {
        return {
            bookForm: extend({}, this.bookService.$storage.bookForm, {
                preference: preference ? preference : {
                    value: this.$storage.preferencesRequired ? 'choose_preference' : 'first_available',
                    text: this.bookService.translate(`booking.search.${this.$storage.preferencesRequired ? 'choose_preference' : 'first_available'}`)
                },
            }),
            bookFormChanged: extend({}, this.bookService.$storage.bookFormChanged),
            updatedTimestamp: updatedTimestamp,
        };
    }

	postTempReservation(standby_reservation, site?: any) {
		let data = this.$storage.bookForm;
		let date = moment(data.date.date).startOf('day');
		date.add(data.time.value, 'minutes');

        // When creating temp reservation, make sure all form fields marked "changed" which is like "decided"
        Object.keys(this.bookService.$storage.bookFormChanged).forEach(editor => this.bookService.$storage.bookFormChanged[editor] = true);

        if (site) {
            if (this.$storage.preferencesRequired) {
                let pref = this.$storage.bookForm.preference;
                if (!pref || pref.value == 'choose_preference') {
                    this.tabitBookStartComponent.setEditor('preference');
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogTitle: "MESSAGES.AREA_NOT_SELECTED",
                            dialogText: "MESSAGES.PLEASE_SELECT_AREA"
                        }).then().catch()
                    });
                    return;
                }
            }
            if(!standby_reservation) standby_reservation = !!get(this.$storage, 'site.future_reservation.standby.apply_to_all_reservations');
        }

		let req = {
			"organization": this.$storage.organization,
			"type": "future_reservation",
			"standby_reservation": standby_reservation,
			"seats_count": data.diners.value,
			"preference": data.preference.value,
			"timestamp": date.toDate(),
            "online_booking_source": "tabit",
            "online_booking_source_client": {
                name: 'tabit-app',
                version: this.appService.appConfig.version,
                environment: environment.appConfig.title
            }
		}

        this.blockUI.start();
		return this.bookService.postTempReservation(req).then(res => {
            this.$storage.request_deposit = res.request_deposit;
			if (res.reservation) {
				this.$storage.reservation = res.reservation;
				this.gotoDetails();
			} else {
				let desc = res.description_string;
				if (res.alternative_results && res.alternative_results.length) {
					res.desc = this.bookService.translate(desc);
					this.$storage.resAlt = res;
					this.gotoAlt();
				} else {
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogText: desc
                        }).then(res => { });
                    });
				}
			}
		}).catch(e => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'error_client_credentials'
                });
            });
            console.error('Error creating temp reservation:', e);
			this.blockUI.stop();
		}).then(res => {
			this.blockUI.stop();
		})
	}

	selectAltSlot(area, slot) {
		const data = this.$storage.bookForm;
		if (!area) {
			area = { name:'first_available'};
		}
		const req = {
			"organization": this.$storage.organization,
			"type": "future_reservation",
			"standby_reservation": false,
			"seats_count": data.diners.value,
			"preference": area.name,
			"timestamp": slot,
            "online_booking_source": "tabit",
            "online_booking_source_client": {
                name: 'tabit-app',
                version: this.appService.appConfig.version,
                environment: environment.appConfig.title
            }
		}

		this.blockUI.start();
		this.bookService.postTempReservation(req).then(res => {
			this.$storage.request_deposit = res.request_deposit;
			if (res.reservation) {
				data.preference = { value: area.name, text: this.$storage.preferencesMap[area.name] }
				let mDate = moment(slot);
				data.date = {
					enabled: false,
					date: mDate.toDate()
				}
				let nMinutes = this.bookService.getMinutesFromMoment(mDate)
				data.time = {
					value: nMinutes,
					text: this.bookService.parseMinutes(nMinutes)
				}

				this.$storage.reservation = res.reservation;
				this.gotoDetails();
			} else {
				let desc = res.description_string;
				if (res.alternative_results && res.alternative_results.length) {
					res.desc = this.bookService.translate(desc);
					this.$storage.resAlt = res;
					this.gotoAlt();
				} else {
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogText: this.bookService.translate(desc)
                        }).then(res => { });
                    });
				}
			}
		}).catch(e => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'error_client_credentials'
                });
            });
			this.blockUI.stop();
		}).then(res => {
			this.blockUI.stop();
		})
	}

	counter;
	startCounter() {
		let timeToHoldTable = get(this.$storage, 'site.temp_reservation_time_to_hold_table') || 15;

		this.clearTimer();
		this.counter = window.setTimeout(() => {
			this.appService.animDialog({
				hideTitle: true,
				content: this.bookService.translate('booking.modal.alert.reservation_not_found_body', { minutes: 15 }),
				animation: this.appService.base('/assets/animations/expired.json'),
				loop: true
			}).then(res => { });
			this.gotoStart();
		}, (1000 * 60 * timeToHoldTable));
	}

	clearTimer() {
		window.clearTimeout(this.counter);
	}

	ngOnDestroy() {
		this.clearTimer();
        this.androidBackSubscription.unsubscribe();
        this.routeQueryParamsSubscription.unsubscribe();

        this.appService.confirmBeforeRedirect = false;
	}

	gotoStart(editor='date') {
        this.editorToSet = editor;
		this.deleteTempReservation();
		this.clearTimer();
		this.step = 0;
	}

	gotoAlt() {
		this.clearTimer();
		this.step = 1;
	}

	gotoDetails() {
		this.startCounter();
		this.step = 2;
	}

	gotoEnd() {
		this.clearTimer();
        this.step = 3;
        this.appService.confirmBeforeRedirect = false;
	}

	goBack() {
		if (this.loadingStep) return;

        // When editing book details, back is "back one input editor":
        if (this.step === 0 && !this.tabitBookStartComponent.isFirstEditor()) {
            this.tabitBookStartComponent.setPrevEditor();
            return;
        }

		if (this.step === 0 || this.step === 3) {
			this.close();
		} else if (this.step == 2) {
			this.deleteTempReservation();

            if (this.bookService.crossOrgSearchResult) this.appService.goBack();
			else this.setStep(0);
		} else {
			this.setStep(this.step - 1);
		}
	}

	close(confirm?:boolean) {
        let site = this.organizationsService.getOrganization(this.$storage.organization);
        if (!site) return console.error('No site found at loaded organizations:', this.$storage.organization);
		if (this.step === 3) {
            this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
		} else {
            if (this.step == 2) this.deleteTempReservation();

            if (confirm) {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    return this.appService.mainMessage({
                        dialogType: 'info',
                        dialogTitle: 'order_in_progress',
                        dialogText: 'confirm_exit',
                        primaryButtonText: 'cancel_order',
                        secondaryButtonText: 'continue_order'
                    }).then(response => {
                        this.appService.goBack();
                    }).catch(err => {});
                });
            } else {
                this.appService.goBack();
            }
		}
	}

	setStep(step) {
        this.step = step;
        if (step == 3) {
            this.appService.confirmBeforeRedirect = false;
        }
	}

	deleteTempReservation() {
		if (!(this.$storage.reservation && this.$storage.reservation._id && this.$storage.site)) return;

		this.bookService.deleteTempReservation(this.$storage.reservation._id, this.$storage.organization, this.$storage.site).subscribe({
            next: () => this.$storage.reservation = null,
			error: err => console.debug('Failed to Delete Temp Reservation', err)
		});
    }

    getAvailableDateFromTimestamp(timestamp: any) {
        let date = this.bookService.$storage.bookingDates.find(date => moment(date.date).format('YYYY-MM-DD') === moment(timestamp).format('YYYY-MM-DD'));
        return date;
    }

}
