import { Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { StyleService } from '../../_core/style.service';

import hexRgb from 'hex-rgb';

import { get } from 'lodash-es';

declare const $: any;

/*
    map of brand color numbers as they appear in the Office "customer order -> site setup" page
    ---
    1: hilight_color
    2: highlight_bg_primary
    3: header_bg_primary
    4: header_txt_primary
    5: header_bg_secondary
    6: header_txt_secondary
    7: button_bg_primary
    8: button_txt_primary
    9: button_bg_secondary
    10: button_txt_secondary
    11: button_service_bg
    12: button_service_txt
    13: price_txt_primary
    14: card_bg_primary
    15: card_txt_primary
    16: blur_bg_primary
    17: more_details_txt
    18: cart_mobile_bg_primary
*/
const supportedBrandSkins = ['pappas', 'shakeshack'];

@Injectable({
    providedIn: 'root'
})

export class TOBrandingService {

    public brandingScriptId: string = 'tabit-order-branding';
    public brand;
    public brandSkin;

    constructor(
		public appService: AppService,
        private styleService: StyleService
    ) { }

    public setBrandingConfig(config) {
        if (config?.brandV2) this.brand = config.brandV2;

        if (!config?.brandV2 || window['cordova'] || this.appService.skin || this.appService.redirectFromAppOrSite || this.hasContrast) {
            this.toggleBrandingClass(false);
            return console.log('=== No branding override needed ===');
        }
        // Brand skin wins over regular branding
        if (config?.brandSkin) this.initBrandSkin(config);

        if (get(this.brand, 'blur_bg_primary')) this.setBlurBackground(get(this.brand, 'blur_bg_primary'));
        this.toggleBrandingClass(true);
        this.setBranding(this.brand);
    };

    get hasContrast() {
        const accessibilityScript = document.getElementById(this.appService.accessibilityScriptId);
        return !!accessibilityScript;
    }

    setBlurBackground(blur_bg_primary: string) {
        const matteRgba = hexRgb(blur_bg_primary, {format: 'css', alpha: 0.55});
        const lightRgba = hexRgb(blur_bg_primary, {format: 'css', alpha: 0.15});
        const style = document.createElement('style');
        style.innerHTML = `
            app-to-menu .basket-wrapper { background-color: ${lightRgba} !important; }
            app-to-desktop-checkout .basket-wrapper { background-color: ${lightRgba} !important; }
            app-tabit-order .customize-material-stepper { background-color: ${lightRgba} !important; }
            .to-desktop-container .side-bar-container { background-color: ${lightRgba} !important; }
            .custom-background-image .item-wrapper:not(.folder-item) { background-color: ${lightRgba} !important; }
            .to-splash-dialog { background-color: ${matteRgba} !important; }
        `;

        document.body.appendChild(style);
    };

    setBranding(brand) {
        // inject branding styles
        if (!brand) console.error('No brand data to override');

        const style = document.createElement('style');
        style.id = this.brandingScriptId;

        // from now on, use the 'branding-color-#' class in templates to reference the color in the brand object
        style.innerHTML = `
            .branding-color-1 {color: ${brand.hilight_color} !important;}
            .branding-color-2 {color: ${brand.highlight_bg_primary} !important;}
            .branding-color-3 {color: ${brand.header_bg_primary} !important;}
            .branding-color-4 {color: ${brand.header_txt_primary} !important;}
            .branding-color-5 {color: ${brand.header_bg_secondary} !important;}
            .branding-color-6 {color: ${brand.header_txt_secondary} !important;}
            .branding-color-7 {color: ${brand.button_bg_primary} !important;}
            .branding-color-8 {color: ${brand.button_txt_primary} !important;}
            .branding-color-9 {color: ${brand.button_bg_secondary} !important;}
            .branding-color-10 {color: ${brand.button_txt_secondary} !important;}
            .branding-color-11 {color: ${brand.button_service_bg} !important;}
            .branding-color-12 {color: ${brand.button_service_txt} !important;}
            .branding-color-13 {color: ${brand.price_txt_primary} !important;}
            .branding-color-14 {color: ${brand.card_bg_primary} !important;}
            .branding-color-15 {color: ${brand.card_txt_primary} !important;}
            .branding-color-16 {color: ${brand.blur_bg_primary} !important;}
            .branding-color-17 {color: ${brand.more_details_txt} !important;}
            .branding-color-18 {color: ${brand.cart_mobile_bg_primary} !important;}
            .branding-background-1 {background: ${brand.hilight_color} !important;}
            .branding-background-2 {background: ${brand.highlight_bg_primary} !important;}
            .branding-background-3 {background: ${brand.header_bg_primary} !important;}
            .branding-background-4 {background: ${brand.header_txt_primary} !important;}
            .branding-background-5 {background: ${brand.header_bg_secondary} !important;}
            .branding-background-6 {background: ${brand.header_txt_secondary} !important;}
            .branding-background-7 {background: ${brand.button_bg_primary} !important;}
            .branding-background-8 {background: ${brand.button_txt_primary} !important;}
            .branding-background-9 {background: ${brand.button_bg_secondary} !important;}
            .branding-background-10 {background: ${brand.button_txt_secondary} !important;}
            .branding-background-11 {background: ${brand.button_service_bg} !important;}
            .branding-background-12 {background: ${brand.button_service_txt} !important;}
            .branding-background-13 {background: ${brand.price_txt_primary} !important;}
            .branding-background-14 {background: ${brand.card_bg_primary} !important;}
            .branding-background-15 {background: ${brand.card_txt_primary} !important;}
            .branding-background-16 {background: ${brand.blur_bg_primary} !important;}
            .branding-background-17 {background: ${brand.more_details_txt} !important;}
            .branding-background-18 {background: ${brand.cart_mobile_bg_primary} !important;}

            .cdk-overlay-pane { --mat-option-selected-state-label-text-color: ${brand.button_service_bg} !important; --mat-minimal-pseudo-checkbox-selected-checkmark-color : ${brand.button_service_bg} !important;}
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions .primary-action-button,
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions .secondary-action-button.button-appearance  { color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important; }
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions .secondary-action-button.anchor-appearance,
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions .secondary-action-button.anchor-appearance .mdc-button__label,
            main-message-dialog .dialog-container .dialog-top-section .close-dialog .mat-icon { color: ${brand.button_service_bg} !important; text-decoration-color: ${brand.button_service_bg} !important;}

            app-to-delay-dialog form .mat-mdc-dialog-content mat-form-field mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper,
            app-to-delay-dialog .close-button mat-icon { color: ${brand.button_service_bg} !important; }
            a, a:not([href]) { color: ${brand.button_txt_secondary} }
            main-message-dialog a{ color: ${brand.button_service_bg} !important; }
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions button.continue-button { color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important; }
            main-message-dialog .dialog-container .dialog-bottom-section .page-actions .go-back { color: ${brand.button_service_bg} !important; }

            app-message-dialog .mat-mdc-dialog-actions .primary-button { color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important; }
            app-message-dialog .mat-mdc-dialog-actions .secondary-button { color: ${brand.button_service_bg} !important; }
            app-message-dialog .close-button mat-icon { color: ${brand.button_service_bg} !important; }
            app-message-dialog textarea { color: ${brand.button_service_bg} !important; border-color: ${brand.button_service_bg} !important; }

            .block-ui-wrapper .block-ui-spinner > .message,.block-ui-wrapper .block-ui-template > .message { color: ${brand.highlight_bg_primary} !important; }

            service-buttons .menu-view-container svg rect.menu-front-side,
            service-buttons .menu-view-container svg path.menu-back-side { fill: ${brand.button_service_bg} !important; }
            service-buttons .menu-view-container svg rect.menu-front-side,
            service-buttons .menu-view-container svg path.menu-back-side {stroke: ${brand.button_service_txt} !important;}
            service-buttons .menu-view-container svg path.menu-letter {fill: ${brand.button_service_txt} !important;}

            service-buttons .service-button { background-color: ${brand.button_service_bg} !important; border: 0.2px solid ${brand.button_service_txt} !important; border-right: none !important; }
            service-buttons .menu-view-button-span { color: ${brand.more_details_txt} !important; }
            service-buttons .service-button-span { color: ${brand.button_service_txt} !important; }
            service-buttons .delayed-service { color: ${brand.button_service_txt} !important; }

            app-tabit-order .to-splash-container .header-container { color: ${brand.header_txt_secondary} !important; background-color: ${brand.header_bg_secondary} !important; }
            app-tou-map .header-container { color: ${brand.header_txt_secondary} !important; background-color: ${brand.header_bg_secondary} !important; }
            app-tou-map .to-continue-button button { color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important; border: 0.2px solid ${brand.button_service_txt} !important; }
            app-tabit-order .nav-header-wrapper { background: ${brand.header_bg_primary} !important; }
            
            app-tabit-order .nav-header-wrapper header-links .separator { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .nav-header-wrapper header-links span { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .nav-header-wrapper header-links mat-icon:not(.language) svg path { fill: ${brand.header_txt_primary} !important; }
            app-tabit-order .nav-header-wrapper header-links .language svg g .dynamic-fill { fill: ${brand.header_txt_primary} !important; }
            app-tabit-order .nav-header-wrapper header-links .language svg g .dynamic-stroke { stroke: ${brand.header_txt_primary} !important; }

            to-future-dialog .header-container mat-icon,.header-name { color: ${brand.header_txt_secondary} !important; }
            to-future-dialog .header-container { color: ${brand.header_txt_secondary} !important; background: ${brand.header_bg_secondary} !important; }
            to-future-dialog .title-container .selected { border-bottom: 3px solid ${brand.button_bg_secondary} !important; }
            to-future-dialog .title-container .selected::after { border-top: 8px solid ${brand.button_bg_secondary} !important; }
            to-future-dialog .data-container .selected { background-color: ${brand.button_bg_secondary} !important; border: 1px solid ${brand.button_bg_secondary} !important;}
            to-future-dialog .data-container .selected { background-color: ${brand.button_bg_secondary} !important; border: 1px solid ${brand.button_bg_secondary} !important;}

            to-future-dialog .data-container .date-container.selected .date { color: ${brand.button_txt_secondary} !important; }
            to-future-dialog .data-container .date-container.selected .day { color: ${brand.button_txt_secondary} !important; }
            to-future-dialog .data-container .hour-container.selected { color: ${brand.button_txt_secondary} !important; }
            to-future-dialog .data-container ::-webkit-scrollbar-thumb { background: ${brand.button_bg_secondary} !important; }
            to-future-dialog .data-container ::-webkit-scrollbar-thumb:hover { background: ${brand.button_bg_primary} !important; }
            to-future-dialog .dialog-container .main-container { color: ${brand.card_txt_primary} !important; }
            to-future-dialog .dialog-container .header-container { background: ${brand.header_bg_secondary} !important; }

            to-future-dialog .data-container .separator { border: 1px solid ${brand.button_bg_secondary} !important; }
            to-future-dialog .data-container ::-webkit-scrollbar-thumb { background: ${brand.highlight_bg_primary} !important; }
            to-future-dialog .data-container ::-webkit-scrollbar-thumb:hover { background: ${brand.highlight_bg_primary} !important; }
            to-future-dialog .dialog-container .main-container { color: ${brand.card_txt_primary} !important; }
            to-future-dialog .dialog-container .header-container { background: ${brand.header_bg_secondary} !important; }
            to-future-dialog .primary-continue-button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }
            to-future-dialog .disclaimer { color: ${brand.header_txt_secondary} !important; background: ${brand.header_bg_secondary} !important; }

            app-to-table-dialog .mat-mdc-dialog-actions button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            to-room-dialog .mat-mdc-dialog-actions button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            to-room-dialog .mat-mdc-form-field.mat-mdc-accent .mat-mdc-input-element { caret-color: ${brand.button_service_bg} !important }

            app-to-delay-dialog button[type="submit"] {  color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important;}
            app-to-delay-dialog .mat-mdc-select-arrow-wrapper, app-to-delay-dialog button[[attr.aria-label]="'ACCESS.close' | translate"] {color: ${brand.button_service_txt} !important }
            app-to-delay-dialog .mat-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick { color: ${brand.button_service_bg} !important }
            app-to-delay-dialog .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing { border-color: ${brand.button_service_bg} !important }
            app-to-delay-dialog .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading { border-color: ${brand.button_service_bg} !important }
            .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label { color: ${brand.button_service_bg} !important }
            .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple { background-color: ${brand.button_service_bg} !important }

            app-loyalty-sign-in-dialog .mat-mdc-dialog-actions button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            app-loyalty-sign-in-dialog button.primary-action-button, app-loyalty-sign-in-dialog .mdc-button--unelevated.mat-mdc-primary {  color: ${brand.button_service_txt} !important; background-color: ${brand.button_service_bg} !important;}
            app-loyalty-sign-in-dialog .mat-mdc-select-arrow-wrapper, app-loyalty-sign-in-dialog button[[attr.aria-label]="'ACCESS.close' | translate"], app-loyalty-sign-in-dialog button.secondary-action-button {color: ${brand.button_service_txt} !important }
            app-loyalty-sign-in-dialog .mat-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick, app-loyalty-sign-in-dialog button.secondary-action-button.anchor-appearance { color: ${brand.button_service_bg} !important; text-decoration-color: ${brand.button_service_bg}!important;}
            app-loyalty-sign-in-dialog button.secondary-action-button.anchor-appearance .mdc-button__label {color: ${brand.button_service_bg} !important;}
            app-loyalty-sign-in-dialog .mat-mdc-checkbox-checked .mat-mdc-checkbox-background .mat-mdc-checkbox-checkmark-path { stroke:  ${brand.header_txt_primary ? brand.header_txt_primary : brand.more_details_txt } !important; }
            app-loyalty-sign-in-dialog .mat-mdc-checkbox-checked .mat-mdc-checkbox-background svg {filter: none !important;}

            app-loyalty-sign-in-dialog .to-loyalty-container { background-color: ${brand.button_bg_secondary} !important; }
            app-loyalty-sign-in-dialog .to-loyalty-input { border: 1px solid ${brand.button_bg_secondary} !important; }
            app-loyalty-sign-in-dialog .phone-input{ border: 2px solid  ${brand.button_bg_secondary} !important; }
            app-loyalty-sign-in-dialog .to-loyalty-button { color: ${brand.button_txt_secondary} !important; background-color: ${brand.button_bg_secondary} !important; background: ${brand.button_bg_secondary} !important; }
            app-loyalty-sign-in-dialog .to-loyalty-button + div { color: ${brand.button_txt_secondary} !important; }
            app-loyalty-sign-in-dialog mat-spinner circle { border-color: ${brand.button_txt_secondary}!important; stroke: ${brand.button_txt_secondary}!important; }
            app-loyalty-sign-in-dialog .to-loyalty-login > div { background-color: ${brand.header_bg_primary} !important; }
            app-loyalty-sign-in-dialog .to-loyalty-login form { border:none !important; }

            to-profile-dialog .buttons-container button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            to-profile-dialog .buttons-container button.mat-mdc-button-disabled { opacity: 0.6 }
            app-sign-in svg { fill: ${brand.button_bg_primary} !important; }
            app-sign-in .btn.btn-trans.dialog-button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important; }
            app-sign-in mat-form-field.mat-mdc-focused .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick,
            .mat-mdc-form-field-label mat-label:not(.remarks-text),
            .mat-mdc-form-field-label mat-label:not(.remarks-text),
            .mat-mdc-input-element:not(.remarks-text),
            .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label:not(.remarks-text),
            app-to3-contact .mat-form-field-appearance-outline:not(.mat-mdc-form-field-invalid) .mat-mdc-form-field-outline-thick {color: ${brand.card_txt_primary} !important; caret-color: ${brand.card_txt_primary} !important;}
            to-profile-dialog mat-form-field.mat-mdc-focused .mat-mdc-form-field-ripple {background-color: ${brand.button_service_bg} !important;}
            consent-text .consent-text-container a { color: ${brand.button_bg_primary} !important; }

            .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
                border-color: ${brand.button_service_bg} !important;
            }
            .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
                border-color: ${brand.button_service_bg} !important;
            }
            .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
                border-color: ${brand.button_service_bg} !important;
            }
            .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
                color: ${brand.button_service_bg} !important;
            }

            /*
                to-side-menu .nav-header { background: ${brand.header_bg_primary} !important; }
                to-side-menu .nav-header .name { color: ${brand.header_txt_primary} !important; }
                to-side-menu .site-info-container { background: ${brand.header_bg_primary} !important; border: 1px solid ${brand.header_bg_primary}; }
                to-side-menu .reset-button button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }
                to-side-menu to-site-info .site-info-container { color: ${brand.header_txt_primary} !important; }
                to-side-menu to-site-info .site-info-container svg path { stroke: ${brand.header_txt_primary} !important; }
                to-side-menu to-site-info .site-info-container .time svg polyline { stroke: ${brand.header_txt_primary} !important; }

                app-tabit-order .site-info-container .working-time-slots { color: ${brand.header_txt_primary} !important; }
            */

            app-tabit-order .ordered-before-container { border: 6px solid ${brand.highlight_bg_primary} !important; }
            app-tabit-order .ta-plus-circle path { fill: ${brand.highlight_bg_primary} !important; }
            app-tabit-order button:disabled .ta-plus-circle path { opacity: 0.2 }
            
            app-tabit-order .ta-pen path { fill: ${brand.highlight_bg_primary} !important; }

            .to-mobile-dialog svg g .dynamic-stroke { stroke: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog svg g .dynamic-fill { fill: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .to-summary-button { background-color: ${brand.button_bg_secondary} !important;}
            .to-mobile-dialog .to-summary-button .offer-dialog-price { color: ${brand.button_txt_secondary} !important; }
            .to-mobile-dialog .header-back-icon { color: ${brand.button_bg_secondary} !important;}
            .to-mobile-dialog .modifiers-group-increment-box button { background-color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .quantity-actions .item-price { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item .quantity-action-button.add-item mat-icon { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .item-title-summary { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .header-title-container .header-title-items .item-price { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item .modifiers-group-item-content .item-edit mat-icon { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active { background: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .header-title-items .header-title { color: ${brand.button_txt_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .header-title-items .item-price { color: ${brand.button_txt_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .quantity-actions .item-price { color: ${brand.button_txt_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container .modifiers-group-items-collapse { color: ${brand.button_bg_secondary} !important; }
            .to-mobile-dialog .modifiers-group-container .modifiers-group-items-collapse .modifiers-group-items-collapse-title mat-icon { color: ${brand.button_bg_secondary} !important; }
            .scrollbar-container.tou-offer-scroll ::-webkit-scrollbar-thumb { background: ${brand.button_bg_secondary} !important; }
            .scrollbar-container.tou-offer-scroll ::-webkit-scrollbar-thumb:hover { background: ${brand.button_bg_secondary} !important; }

            app-to2-extras .checkout-stage-container { background-color: ${brand.card_bg_primary} !important; }
            app-to2-extras .checkout-stage-container .to-tbl-extras-row button .ta-minus-circle path { fill: ${brand.hilight_color}; }
            app-to2-extras .checkout-stage-container .to-tbl-extras-row button:disabled .ta-minus-circle path { fill: rgba(0, 0, 0, 0.26) !important; }
            app-to2-extras .to-tbl-extras-header, app-to2-extras .extra-count{ color: ${brand.card_txt_primary} !important;}
            app-to2-extras .to-tbl-extras-row mat-icon.add-item-button { color: ${brand.button_bg_secondary} !important; }

            app-to3-contact .checkout-contact-container { background-color: ${brand.card_bg_primary} !important; }
            app-to3-contact .checkout-contact-container .connect-button-wrapper button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }

            ._desktop .to-desktop-checkout-step .to-step-content { background-color: ${brand.card_bg_primary} !important; }
            app-to-end .checkout-contact-container { background-color: ${brand.card_bg_primary} !important; }
            app-to-end .disclaimer a { color: ${brand.card_txt_primary} !important; }

            ._desktop app-to-end .end-disclaimer { background-color: ${brand.card_bg_primary} !important; }

            app-tou-offer-item svg g .dynamic-stroke { stroke: ${brand.hilight_color} !important; }
            app-tou-offer-item svg g .dynamic-fill { fill: ${brand.hilight_color} !important; }
            app-tou-offer-item-dialog .dialog-add-button { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important; }
            .to-bundle-dialog mat-dialog-container app-tou-bundle .bundle-dialog-header {color: ${brand.button_txt_primary} !important; background: ${brand.button_bg_primary} !important;}
            .to-bundle-dialog mat-dialog-container app-tou-bundle .mat-mdc-dialog-actions button {color: ${brand.button_txt_primary} !important; background: ${brand.button_bg_primary} !important;}

            .to-bundle-dialog mat-dialog-container app-tou-rewards .to-dialog-header {color: ${brand.header_txt_secondary} !important; background: ${brand.button_bg_primary} !important;}
            .to-bundle-dialog mat-dialog-container app-tou-rewards .mat-mdc-dialog-actions button {color: ${brand.header_txt_secondary} !important; background: ${brand.button_bg_primary} !important;}
            .to-bundle-dialog mat-dialog-container app-tou-rewards .not-available{color: ${brand.header_txt_secondary} !important; background: ${brand.button_bg_primary} !important;}
            app-desktop-to-start header-links .side-menu-toggle * { color: ${brand.more_details_txt} !important; }
            .module-bar-language-icon { stroke: ${brand.more_details_txt} !important; }
            header-links .separator { color: ${brand.more_details_txt} !important; }
            header-links span, .module-bar-language-icon { color: ${brand.more_details_txt} !important; stroke: ${brand.more_details_txt} !important;}

            header-links .language { stroke: ${brand.more_details_txt} !important; }
            header-links .mat-mdc-select-languages .mat-mdc-select-arrow {color: ${brand.more_details_txt} !important;}
            header-links .language svg g .dynamic-fill { fill: ${brand.more_details_txt} !important; }
            header-links .language svg g .dynamic-stroke { stroke: ${brand.more_details_txt} !important; }

            to-desktop-header header-links .separator { color: ${brand.header_txt_primary} !important; }
            to-desktop-header header-links .side-menu-toggle * { color: ${brand.header_txt_primary} !important; }
            to-desktop-header header-links mat-icon:not(.language) svg path { fill: ${brand.header_txt_primary} !important; }
            to-desktop-header header-links .language svg g .dynamic-fill { fill: ${brand.header_txt_primary} !important; }
            to-desktop-header header-links .language svg g .dynamic-stroke { stroke: ${brand.header_txt_primary} !important; }

            to-desktop-header .to-inner-header { background: ${brand.header_bg_primary} !important; }
            to-desktop-header .to-site-name { color: ${brand.header_txt_primary} !important; }
            
            to-loyalty-banner .club-login-background { background: linear-gradient( 270deg, ${brand.button_bg_secondary} 39%, ${brand.header_bg_primary} 100%) !important; }
            body.ltr to-loyalty-banner .club-login-background { background: linear-gradient( 90deg, ${brand.button_bg_secondary} 39%, ${brand.header_bg_primary} 100%) !important; }
            to-loyalty-banner .banner-text { color: ${brand.button_txt_secondary} !important; }
            to-loyalty-banner mat-spinner circle { stroke: ${brand.button_txt_secondary}!important; }
            .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow { color: ${brand.button_bg_secondary} !important; }
            to-menu-category .menu-category-header { color: ${brand.header_txt_secondary} !important; background-color: ${brand.header_bg_secondary} !important; }

            to-mobile-basket-button .submit-btn-icon { background-color: ${brand.highlight_bg_primary} !important; }
            to-mobile-basket-button button > div:first-child > div { background: ${brand.highlight_bg_primary} !important; }
            to-mobile-basket-button .basket-total-amount { color: ${brand.highlight_bg_primary} !important; }
            to-mobile-basket-button .payment-step-footer .total-payment { background-color: ${brand.button_bg_secondary} !important; }
            to-mobile-basket-button .shiny-button .shiny { background-color: ${brand.button_bg_secondary} !important; }

            app-to-sms-dialog button.mat-mdc-primary { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            app-to-sms-dialog button.mdc-button--unelevated { background-color: ${brand.button_bg_primary} !important; color: ${brand.button_txt_primary} !important }
            app-to-sms-dialog .mat-mdc-input-element:not(.remarks-text) { caret-color: ${brand.button_service_bg} !important }

            app-to-menu svg g .dynamic-fill { fill: ${brand.highlight_bg_primary} !important; }
            app-to-menu svg g .dynamic-stroke { stroke: ${brand.highlight_bg_primary} !important; }
            app-to-menu .to-menu-container.saved-category { border: 6px solid ${brand.highlight_bg_primary}; }
            app-to-menu .desktop-navigation-menu .selected_menu_item { border-bottom: 3px solid ${brand.header_bg_primary} !important; }
            app-to-menu .desktop-navigation-menu .selected_menu_item::after { border-top: 8px solid ${brand.header_bg_primary} !important; }
            app-to-menu .to-menu-navigation > div.active { color: ${brand.button_bg_secondary} !important; }
            app-to-menu .to-menu-navigation > div.pressed { color: ${brand.button_bg_secondary} !important; }
            app-to-menu .to-menu-navigation:not(.scorlling) > div.active { color: ${brand.button_bg_secondary} !important; }
            app-to-menu .desktop-navigation-menu { color: ${brand.header_txt_secondary} !important; background-color: ${brand.header_bg_secondary} !important; }
            app-to-menu .desktop-navigation-menu svg .arrow { fill: ${brand.highlight_bg_primary} !important; }
            app-to-menu .action-menu-item-name.menu-name { color: ${brand.hilight_color} !important; }
            app-to-menu .mobile-basket-container.scale-up { border: 1px solid ${brand.highlight_bg_primary} !important; }
            app-to-menu .to-mobile-menu-wrapper ::-webkit-scrollbar-thumb { background: ${brand.highlight_bg_primary} !important; }
            app-to-menu .to-mobile-menu-wrapper ::-webkit-scrollbar-thumb:hover { background: ${brand.highlight_bg_primary} !important; }
            app-to-menu .to-tab-scroll-content .no-search-results .no-search-results-content {background-color: ${brand.header_bg_secondary} !important;}

            mat-snack-bar-container.mat-mdc-snack-bar-container.add-to-basket-snackbar { color: ${brand.button_txt_secondary} !important; background-color: ${brand.button_bg_secondary} !important; }
            mat-snack-bar-container.mat-mdc-snack-bar-container.add-to-basket-snackbar simple-snack-bar .mdc-snackbar__label, mat-snack-bar-container .mat-mdc-snack-bar-action, mat-snack-bar-container .mdc-snackbar__surface { color: ${brand.button_txt_secondary ? brand.button_txt_secondary : '#000'} !important; background-color: ${brand.button_bg_secondary} !important;}

            .mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) { color: ${brand.highlight_bg_primary} !important; }
            .to-language-menu-selected { color: ${brand.highlight_bg_primary} !important; }

            app-to4-gratuity .checkout-contact-container  { background: ${brand.card_bg_primary} !important; }
            app-to4-gratuity .checkout-contact-container .gratuity-options .gratuity-option { background: ${brand.card_bg_primary} !important; }
            app-to4-gratuity .checkout-contact-container .gratuity-options .gratuity-option { color: ${brand.card_txt_primary} !important; border-color: ${brand.card_txt_primary} !important;}
            app-to4-gratuity .checkout-contact-container .gratuity-options .gratuity-disclaimer { color: ${brand.card_txt_primary} !important;}
            
            app-to5-pay .payment-page-container.desktop-branding { background: ${brand.card_bg_primary} !important; }
            app-to5-pay .payment-active.desktop-branding { border-color: ${brand.card_txt_primary} !important; }
            app-to5-pay .divider-line.desktop-branding {border-left-color: ${brand.card_txt_primary} !important; }
            app-to5-pay .active-new-payment { color: ${brand.card_txt_primary} !important;}
            app-to5-pay .payment-divider .line {border-top-color: ${brand.card_txt_primary} !important; }
            app-to5-pay .amount-currency { color: ${brand.button_bg_secondary} !important; }

            .to-menu-messages > div.extra { background: ${brand.header_bg_secondary} !important; }
            to-menu-item .menu-item  { background-color: ${brand.card_bg_primary} !important; }
            to-menu-item .menu-item .item-name { color: ${brand.card_txt_primary} !important; }
            to-menu-item .menu-item .item-info .item-info-section .no-modifiers, .item-description-text { color: ${brand.card_txt_primary} !important; }
            to-menu-item .menu-item .item-info .price-and-badge .item-price { color: ${brand.price_txt_primary} !important; }
            to-menu-item .menu-item .item-info .item-info-section .order-modifiers { color: ${brand.hilight_color} !important; }
            to-menu-item .menu-item .selected-item-indication, to-menu-item .menu-item .selected-item-indication-reward { border-color: ${brand.highlight_bg_primary} !important; }
            to-menu-item .menu-item.reward-item-selected { border-color: ${brand.highlight_bg_primary} !important; }
            to-menu-item .menu-item .selected-item-indication > div, to-menu-item .menu-item .selected-item-indication-reward > div, .reward-selected-background { background-color: ${brand.highlight_bg_primary} !important; }
            app-tou-rewards to-menu-item .menu-item .auto-added {background-color: ${brand.button_bg_secondary} !important; }
            app-tou-rewards to-menu-item .menu-item .selected-item-indication > div, app-tou-rewards to-menu-item .menu-item .selected-item-indication-reward > div  { background-color: ${brand.button_bg_secondary} !important; color: ${brand.button_txt_secondary}}
            app-tou-rewards to-menu-item .menu-item .selected-item-indication, app-tou-rewards to-menu-item .menu-item .selected-item-indication-reward { border-color: ${brand.button_bg_secondary} !important; }

            app-tou-loyalty-store-points-bottom-sheet [data-mat-icon-name="circle"] path { fill: ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-store-points-bottom-sheet [data-mat-icon-name="check_circle_outline"] .dynamic-stroke { stroke: ${brand.button_bg_secondary} !important} 
            app-tou-loyalty-store-points-bottom-sheet [data-mat-icon-name="check_circle_outline"] .dynamic-fill { fill:${brand.button_bg_secondary} !important; stroke: ${brand.button_bg_secondary} !important }
            app-tou-loyalty-store-points-bottom-sheet .wl-app-primary-text-color-background {background-color: ${brand.button_bg_secondary} !important; }

            app-tabit-order .to-checkout-button { background-color: ${brand.button_bg_primary} !important; }
            to-basket .to-desktop-basket .basket-action-wrapper button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }
            to-basket .to-desktop-basket ::-webkit-scrollbar-thumb { background: ${brand.highlight_bg_primary} !important; }
            to-basket .to-desktop-basket ::-webkit-scrollbar-thumb:hover { background: ${brand.highlight_bg_primary} !important; }
            to-basket .basket-text { color: ${brand.header_txt_secondary} } !important; }
            to-basket .basket-text { color: ${brand.header_txt_secondary} } !important; }

            to-basket mat-expansion-panel, mat-expansion-panel * { background: ${brand.header_bg_secondary} !important; }
            to-basket mat-expansion-panel { background-color: transparent !important; }
            to-basket .closing-basket-dialog { background-color: ${brand.header_bg_secondary} !important; border-top: 1px solid ${brand.highlight_bg_primary} !important; }
            .mobile-basket-container to-basket .closing-basket-dialog { background-color: ${brand.cart_mobile_bg_primary ? brand.cart_mobile_bg_primary : brand.header_bg_secondary} !important; border-top: 1px solid ${brand.highlight_bg_primary} !important; }
            .mobile-basket-container .basket-free-delivery-target { color: ${brand.header_txt_secondary} } !important; }
            to-mobile-basket-button .submit-btn-description { border: 1px solid ${brand.highlight_bg_primary} !important; }

            app-tou-basket-info .basket-price { color: ${brand.price_txt_primary} !important; }
            .to-text-highlight { color: ${brand.price_txt_primary} !important; }
            app-tou-basket ._price, app-tou-basket .to-text-highlight, app-tou-basket-info .basket-price { color: ${brand.header_txt_secondary} !important; }
            app-tou-basket-info svg g .dynamic-fill { fill: ${brand.highlight_bg_primary} !important; }
            app-tou-basket .to-basket-row { background: ${brand.header_bg_secondary} }
            app-tou-basket .to-basket-row > ._body ._actions mat-icon { color: ${brand.highlight_bg_primary} !important; }
            app-tou-basket .to-basket-row-animation { animation: fade-in-row 0.5s ease-in, background-color-row-brand 2s ease-in !important;}
            app-tou-basket ._actions .text-center {color: ${brand.header_txt_secondary} !important; }
            app-tou-basket .rewards-star path {fill: ${brand.header_txt_secondary} !important; stroke: ${brand.highlight_bg_primary} !important;}

            .basket-free-delivery-complete {color: ${brand.header_txt_secondary} !important; }
            app-tou-basket-info .free-delivery-from {color: ${brand.header_txt_secondary} !important; }

            tou-extra-info .additional-order-details-container { background-color: ${brand.header_bg_secondary} !important; }
            tou-extra-info .order-detail-divider { border-left: 1px solid ${brand.button_bg_secondary} !important; }
            tou-extra-info .basket-text { color: ${brand.header_txt_secondary} } !important; }

            .to-loyalty-dialog mat-dialog-container { background: ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-container.no-info { background-color: ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-dialog .no-info .to-loyalty-header { color: ${brand.button_txt_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-input { border: 1px solid ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-dialog .phone-input{ border: 2px solid  ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-button { color: ${brand.button_txt_secondary} !important; background-color: ${brand.button_bg_secondary} !important; background: ${brand.button_bg_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-button + div { color: ${brand.button_txt_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-button mat-spinner circle { stroke: ${brand.button_txt_secondary}!important; }

            app-tou-loyalty-dialog .manual-benefits-container button { background: ${brand.button_bg_secondary} !important; color: ${brand.button_txt_secondary} !important; }
            app-tou-loyalty-dialog .manual-benefits-container .manual-benefits-container-title { color: ${brand.header_txt_primary} !important; }
            app-tou-loyalty-dialog .manual-benefits-container { background: ${brand.header_bg_primary} !important; }
            app-tou-loyalty-dialog .to-loyalty-container .to-loyalty-login > div { background-color: ${brand.header_bg_primary} !important; }
            app-tou-loyalty-dialog .loyalty-register-container { background-color: ${brand.header_bg_primary} !important; }
            app-tou-loyalty-dialog .loyalty-register-container a { color: ${brand.header_txt_primary} !important; }
            app-tou-loyalty-dialog .benefits-comment { color: ${brand.button_txt_secondary} !important; }
            app-tou-loyalty-dialog .to-loyalty-container .to-loyalty-login form { border:none !important; }

            to-basket .to-desktop-basket { background-color: ${brand.header_bg_secondary} !important; }
            .mobile-basket-container to-basket .to-desktop-basket { background-color: ${brand.cart_mobile_bg_primary ? brand.cart_mobile_bg_primary : brand.header_bg_secondary} !important; }
            .mobile-basket-container app-tou-basket .to-basket-row { background-color: ${brand.cart_mobile_bg_primary ? brand.cart_mobile_bg_primary : brand.header_bg_secondary} !important; }
            to-basket .free-delivery-progress-bar .mat-mdc-progress-bar-buffer { background-color: ${brand.button_bg_primary} !important; filter: brightness(1.2); }
            to-basket .free-delivery-progress-bar .mat-mdc-progress-bar-fill::after { background-color: ${brand.button_bg_primary} !important; }
            to-basket .free-delivery-progress-bar-complete .mat-mdc-progress-bar-fill::after { background-color: ${brand.button_bg_primary} !important; }
            to-basket .closing-basket-dialog .payment-arrow { background: ${brand.highlight_bg_primary} !important; }
            app-to3-contact .mat-mdc-checkbox-checked .mat-mdc-checkbox-background, app-loyalty-sign-in-dialog .mat-mdc-checkbox-checked .mat-mdc-checkbox-background, .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple, .mat-mdc-form-field-underline { background-color: ${brand.card_txt_primary} !important; }
            app-to3-contact .mat-mdc-select-arrow, .mat-mdc-checkbox-label { color: ${brand.card_txt_primary} !important; caret-color: ${brand.card_txt_primary} !important; }
            app-to3-contact .mdc-checkbox__background { border-color: ${brand.card_txt_primary} !important; }
            app-to3-contact .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
                border-color:  ${brand.card_txt_primary} !important; ;
                background-color:  ${brand.card_txt_primary} !important; ;
            }
            app-to3-contact .terms-link a { color: ${brand.card_txt_primary} !important; }

            to-menu-search .desktop-menu-search {background-color: ${brand.header_bg_secondary} !important;}
            to-menu-search .desktop-menu-search ::placeholder { color: ${brand.header_txt_secondary} !important; }
            ._mobile .menu-search input, ._desktop .menu-search input {color: ${brand.header_txt_secondary} !important; caret-color: ${brand.header_txt_secondary} !important;}

            app-tabit-order .customize-material-stepper .mat-step-label-selected { color: ${brand.header_txt_secondary} !important; }
            app-tabit-order .customize-material-stepper .mat-horizontal-stepper-header-container { background-color: ${brand.header_bg_secondary} !important; }
            app-tabit-order .customize-material-stepper .mat-step-label.mat-step-label-active:not(.mat-step-label-selected) { color: ${brand.header_txt_secondary} !important; opacity: 0.6; }

            app-keypad-dialog .mat-mdc-dialog-content ._value { border-bottom-color: ${brand.button_bg_secondary} !important; }

            app-to-desktop-checkout .mat-horizontal-content-container { background: ${brand.card_bg_primary} !important; }
            app-to-desktop-checkout .to-checkout-title { color: ${brand.header_txt_secondary} !important; background-color: ${brand.header_bg_secondary} !important; }
            app-to-desktop-checkout .to-checkout-button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }

            app-tabit-order .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit { background-color: ${brand.button_service_bg} !important; }
            app-tabit-order .mat-step-header .mat-step-icon-state-number { background-color: ${brand.button_service_bg} !important; opacity: 0.6; }
            app-tabit-order mat-form-field.mat-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick { color: ${brand.button_service_bg} !important; caret-color: ${brand.button_service_bg}; }
            app-tabit-order .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar { background-color: ${brand.button_bg_secondary} !important; }

            .to-bundle-dialog mat-dialog-container app-tou-bundle .bundle-dialog-header {color: ${brand.button_txt_primary} !important; background: ${brand.button_bg_primary} !important;}
            .to-bundle-dialog mat-dialog-container app-tou-bundle .mat-mdc-dialog-actions button {color: ${brand.button_txt_primary} !important; background: ${brand.button_bg_primary} !important;}
            @keyframes background-color-row-brand {from {background: ${brand.button_bg_secondary} }to {background: ${brand.header_bg_secondary}}}
            app-tabit-order .to-main-container .module-bar { background: ${brand.header_bg_primary} !important; color: ${brand.header_txt_primary} !important; }
            app-tabit-order .to-main-container .module-bar > ._inner .title { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .to-main-container .module-bar > ._inner .to-checkout-back-button { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .to-main-container .module-bar header-links .separator { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .to-main-container .module-bar header-links span { color: ${brand.header_txt_primary} !important; }
            app-tabit-order .to-main-container .module-bar header-links mat-icon:not(.language) svg path { fill: ${brand.header_txt_primary ? brand.header_txt_primary : brand.more_details_txt } !important; }
            app-tabit-order .to-main-container .module-bar .module-bar-language-icon svg g .dynamic-fill { fill:  ${brand.header_txt_primary ? brand.header_txt_primary : brand.more_details_txt } !important; }
            app-tabit-order .to-main-container .module-bar .module-bar-language-icon svg g .dynamic-stroke { stroke:  ${brand.header_txt_primary ? brand.header_txt_primary : brand.more_details_txt } !important; }
            app-tabit-order .to-main-container .module-bar .module-bar-language-icon { stroke:  ${brand.header_txt_primary ? brand.header_txt_primary : brand.more_details_txt } !important; }

            app-to5-pay .active-new-payment {color:${brand.button_bg_secondary}}
            app-to5-pay .line {border-color:${brand.button_bg_secondary}}
            app-to5-pay .paymet-contact-container { background-color: ${brand.card_bg_primary} !important; }
            app-to5-pay .payment-field .mdc-notched-outline__leading,
            .payment-field .mdc-notched-outline__notch,
            app-to5-pay .payment-field .mdc-notched-outline__trailing {border:none !important;}

            app-to-giftcard-dialog .apply-gift-card { background-color: ${brand.button_bg_primary} !important;}
            app-to-giftcard-dialog .mat-form-field-appearance-outline .mat-mdc-form-field-outline-thick {color: ${brand.button_service_bg} !important; caret-color: ${brand.button_service_bg} !important;}

            .to_messages_card.alert-info { background-color: ${brand.button_bg_secondary} !important; }
            .scrollbar-container:not(.tou-offer-scroll) ::-webkit-scrollbar-thumb { background: ${brand.highlight_bg_primary} !important; }
            .scrollbar-container:not(.tou-offer-scroll) ::-webkit-scrollbar-thumb:hover { background: ${brand.highlight_bg_primary} !important; }
            .to-menu-messages .to_messages_card { color: ${brand.button_txt_secondary} !important; }
            .to-menu-messages .to_promotion_card { background: ${brand.header_bg_primary} !important; color: ${brand.header_txt_primary} !important; }
            to-sites-dialog .header-container { background-color: ${brand.header_bg_secondary} !important; }

            app-to-payment-dialog label span,
            app-to-payment-dialog label mat-label,
            app-to-payment-dialog .mat-mdc-form-field-label mat-label:not(.remarks-text),
            app-to-payment-dialog .mat-mdc-checkbox *,
            app-to-payment-dialog .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-suffix .mat-icon { color: ${brand.card_txt_primary} !important; }
            app-to-payment-dialog .mat-mdc-checkbox .mat-mdc-checkbox-frame { border-color: ${brand.card_txt_primary} !important; }
            app-to-payment-dialog .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
                border-color:  ${brand.card_txt_primary} !important; ;
                background-color:  ${brand.card_txt_primary} !important; ;
            }

            app-to-payment-dialog .mdc-checkbox__ripple { background-color: ${brand.card_txt_primary} !important; border-color: ${brand.card_txt_primary} !important; }
            app-to-payment-dialog .mdc-checkbox__background { background-color: ${brand.card_txt_primary} !important; border-color: ${brand.card_txt_primary} !important; }

            app-to-payment-dialog .mat-mdc-form-field-underline { background: ${brand.card_txt_primary} !important; }
            app-to-payment-dialog .mat-mdc-checkbox-ripple .mat-mdc-ripple-element,
            app-to-payment-dialog .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {background-color: ${brand.card_txt_primary} !important;}
            .payment-dialog-container .embedded-payment-form-button-container button { color: ${brand.button_txt_primary} !important; background-color: ${brand.button_bg_primary} !important; }
            .payment-dialog-container .mat-mdc-dialog-title { color: ${brand.header_txt_primary} !important; background-color: ${brand.header_bg_primary} !important; }
        `;

        document.head.appendChild(style);

        // change the 'main-message-dialog' svg icons colors with feColorMatrix
        if (brand.button_service_bg) {
            this.styleService.setSvgColorMatrix(brand.button_service_bg.trim());
        }
    };

    toggleBrandingClass(expression: boolean): void {
        // add 'custom-branding' id also for WL apps, to block all dark-mode rules
        if (this.appService.skin) {
            expression = true;
            $('body').addClass('_skin');
        }
        // For now we force light-mode in Android devices
        const isAndroidApp = window['cordova'] && this.appService.platformService.ANDROID;
        expression || isAndroidApp ? $('html').attr('id', 'custom-branding') : $('html').attr('id', 'default-branding');
    }

    removeBrandingScript() {
        const script = document.getElementById(this.brandingScriptId);
        if (script?.parentNode) script.parentNode.removeChild(script);
    }

    applyBrandAfterContrastRemoval() {
        if (!this.brand) return;
        this.setBrandingConfig(this.brand);
    }

    initBrandSkin(config) {
        const brandSkin = config.brandSkin;
        if (supportedBrandSkins.includes(brandSkin)) {
            this.brandSkin = brandSkin;
            $(`<link href="/assets/brands/${brandSkin}/style.css?v=${this.appService.oneTimeCacheBusting}" rel="stylesheet" id="customOrgBrandSkin">`).appendTo("body");

            if (this.brandSkin != 'shakeshack' || !config?.brandV2?.header_sitename_logo_url?.url) return;
            this.addDynamicChainLogo(config);
        }
    }

    addDynamicChainLogo(config) {
        const style = document.createElement('style');
        style.id = this.brandSkin;

        style.innerHTML = `
            .module-bar .mobile-title-text {
            background-size: contain;
            background-position: center;
            height: 75%;
            font-size: 0px !important;
            background-image: url("${config.brandV2.header_sitename_logo_url.url}");
            will-change: content;
            background-repeat: no-repeat
        }`;

        document.head.appendChild(style);
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    removeBrand() {
        $('#customOrgBrandSkin').remove();
    }

    public isNeedToShowNavBarImage(menu) {
        const show = !!this.brandSkin && this.brandSkin == 'shakeshack' && menu.customImage?.image;
        return show;
    }
}
