import { Component, Inject, NgZone, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';

declare const $: any;

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'authorizenet-pay-dialog',
    templateUrl: './authorizenet-pay-dialog.component.html',
    styleUrls: ['./authorizenet-pay-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, FormsModule, TranslateModule]
})
export class AuthorizenetPayDialogComponent implements AfterViewInit {

    private authorizeNetURL;

    constructor(
        private ngZone: NgZone,
        public appService: AppService,
        public entityService: EntityService,
        public dialogRef: MatDialogRef<AuthorizenetPayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngAfterViewInit() {
        this.authorizeNetURL = this.data.iframeUrl || this.appService.appConfig.authorizeNetURL;

        $("#send_token").attr({ "action": this.authorizeNetURL , "target": "add_payment" }).submit();

        if (!window["AuthorizeNetIFrame"]) window["AuthorizeNetIFrame"] = {};
        window["AuthorizeNetIFrame"].onReceiveCommunication = querystr => {
            console.log('Message from auth.net', querystr);

            const params = parseQueryString(querystr);
            switch (params["action"]) {
                case "successfulSave":
                    break;
                case "cancel":
                    this.ngZone.run(() => {
                        this.dialogRef.close({ mode: "cancel" });
                    });

                    break;
                case "resizeWindow":
                    var h = parseInt(params["height"]);
                    var ifrm = document.getElementById("add_payment");
                    //ifrm.style.width = w.toString() + "px";
                    ifrm.style.height = h.toString() + "px";
                    break;
                case "transactResponse":
                    this.ngZone.run(() => {
                        const response: any = JSON.parse(params["response"]);
                        if (response?.transId) {
                            this.dialogRef.close({
                                mode: "success",
                                transactionId: response.transId,
                                billTo: response.billTo,
                            });
                        } else {
                            this.appService.mainMessage({
                                dialogType: 'error',
                                dialogText: `MESSAGES.EX_PAYMENT_ERROR`,
                                primaryButtonText: 'OK',
                                secondaryButtonHidden: true,
                            })
                        }
                    });
                    break;
            }

            function parseQueryString(str) {
                var vars = [];
                var arr = str.split('&');
                var pair;
                for (var i = 0; i < arr.length; i++) {
                    pair = arr[i].split('=');
                    vars.push(pair[0]);
                    vars[pair[0]] = unescape(pair[1]);
                }
                return vars;
            }
        };
    }

    closeDialog() {
        this.dialogRef.close({ mode: 'cancel' });
    }

}

