import { AfterViewInit, Component, Inject, ViewChild, ViewContainerRef, EnvironmentInjector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignInComponent } from '../sign-in.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { CdkTrapFocus } from '@angular/cdk/a11y';

@Component({
    selector: 'sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss'],
    standalone: true,
    imports: [CdkTrapFocus, MatIconButton, MatIcon]
})
export class SignInDialogComponent implements AfterViewInit {
    @ViewChild('signInContainer', { read: ViewContainerRef, static: true }) signInContainer!: ViewContainerRef;

    constructor(
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private environmentInjector: EnvironmentInjector
    ) { }

    ngAfterViewInit() {
        this.loadSignInComponent();
    }

    loadSignInComponent() {
        // Dynamically create SignInComponent
        const componentRef = this.signInContainer.createComponent(SignInComponent, {
            environmentInjector: this.environmentInjector
        });

        // Set input properties
        componentRef.instance.dialogMode = true;

        // Bind output events
        componentRef.instance.signInCompleted.subscribe(() => this.closeDialog());
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
