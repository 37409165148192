import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

import { get, pick, compact, omitBy, isNil, isEmpty, concat } from 'lodash-es';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class LoggerService {
    public appConfig: any = environment.appConfig;
    public additionalData: any = {};

    constructor(
        private deviceService: DeviceDetectorService,
        private http: HttpClient,
    ) { }

    public sendLog(args: { message?: string, durationInSeconds?: number, tracker?: any, tabitOrder?: any, auditData?: any, giftCard?:any, payment?: any, tabitPay?: any, module?: string, user?: any, walletPayment?: any}, additionalData: any): {
        module: string,
        environment: {
            appConfig: {
                title: string,
                version: string,
            }
        },
        navigator: {
            vendor: string,
            platform: string,
            language: string,
            userAgent: string,
        },
        cordova: {
            version: string
        },
        cordovaWrapper: {
            environment: string,
            version: string
        },
        user: {
            id: string,
            phone: string,
            email: string,
            loyaltyCustomer: {
                FirstName: string,
                LastName: string,
                Mobile: string,
                Email: string,
            },
            deviceInfo: any,
        },
        clientId: string,
        route: {
            landingUrl: string,
            landingUrlParams: any,
        },
        durationInSeconds: number,
        tracker: {
            siteId: string,
            siteName: string,
            orderId: string,
            orderNumber: string,
            serviceType: string,
            additionalData: string
        },
        tabitOrder: {
            actionType: string,
            address: any,
            orderStep: string,
            benefitsTotal: number,
            customer: any,
            code: any,
            estimatedTime: string,
            delayMode: string,
            formattedAddress: string,
            geoLocation: string,
            userSelectedAddress: any,
            giftCardsTotal: number,
            grandTotal: number,
            gratuity: number,
            isDesktopMode: boolean,
            isFuture: boolean,
            isLoggedIn: boolean,
            isMember: boolean,
            itemsDiscount: number,
            itemsTotal: number,
            itemsCount: number,
            items: any,
            deviceLocalDate: any,
            locationUnavailableReason: string
            nativePayment: any,
            orderId: string,
            orderMode: string,
            orderType: string,
            organizationId: string,
            organizationName: string,
            route: string,
            serverTax: any,
            serverUrl: string,
            service: string,
            timestamp: any,
            additionalData: any,
            payment: {
                result: 'success' | 'partial-success' | 'failure',
                payments: Array<{
                    success: boolean,
                    accountName: string, // e.g 'אשראי' / 'סיבוס'
                    tenderType: string, // e.g credit / charge / prepay
                    last: string | number,
                    amount: number,
                    errorCode?: string | number,
                    errorMessage?: string
                }>
            },
            contactData: any,
            timeDiff: any
        },
        tabitPay: any,
        walletPayment: any,
        giftCard:{
            additionalData: any,
            marketplace: boolean,
            campaign: string,
            sendToFriend: boolean,
            topUp:boolean,
            paymentMethods:string,
            isWallet:boolean,
            orderID:string,
            isSuccess: boolean,
            statusDescription: string,
            amount: number,
            request: any,
            siteId: string,
            siteName: string,
            customer: any,
        },
        auditData: {
            request: string,
            error: string,
            status: number
        },
    } {
        this.additionalData = additionalData;

        const payloadData = {
            module: args.module || 'tabitAppGeneral',
            client: window['cordova'] ? 'tabitApp' : 'tabitWeb',
            environment: {
                appConfig: {
                    title: environment.appConfig && environment.appConfig.title,
                    version: environment.appConfig && environment.appConfig.version,
                }
            },
            navigator: {
                vendor: navigator?.vendor,
                platform: navigator?.platform,
                language: navigator?.language,
                userAgent: navigator?.userAgent,
            },
            cordova: {
                version: window['cordova']?.version,
            },
            cordovaWrapper: {
                environment: window['environment'],
                version: window['config']?.version,
            },
            user: {
                id: additionalData?.user?._id,
                phone: additionalData?.user?.phone,
                email: additionalData?.user?.email,
                loyaltyCustomer: {
                    FirstName: args?.user?.loyaltyCustomer?.firstName,
                    LastName:  args?.user?.loyaltyCustomer?.lastName,
                    Mobile:  args?.user?.loyaltyCustomer?.mobile,
                    Email: args?.user?.loyaltyCustomer?.email,
                    FullName: args?.user?.loyaltyCustomer?.fullName,
                },
                deviceInfo: this.deviceService.getDeviceInfo(),
            },
            clientId: additionalData?.clientId,
            route: {
                landingUrl: additionalData?.landingUrl,
                landingUrlParams: additionalData?.landingUrlParams,
            },
            durationInSeconds: args.durationInSeconds,
            tracker: {
                siteId: args?.tracker?.siteId,
                siteName: args?.tracker?.siteName,
                orderId: args?.tracker?.orderId,
                orderNumber: args?.tracker?.orderNumber,
                serviceType: args?.tracker?.serviceType,
                additionalData: args?.tracker?.additionalData || '',
            },
            tabitOrder: {
                actionType: args?.tabitOrder?.actionType,
                address: args?.tabitOrder?.address,
                orderStep: args?.tabitOrder?.orderStep,
                benefitsTotal: args?.tabitOrder?.benefitsTotal,
                code: args?.tabitOrder?.code,
                customer: args?.tabitOrder?.customer,
                deviceLocalDate: args?.tabitOrder?.deviceLocalDate,
                delayMode: args?.tabitOrder?.delayMode,
                estimatedTime: args?.tabitOrder?.estimatedTime,
                formattedAddress: args?.tabitOrder?.formattedAddress,
                geoLocation: args?.tabitOrder?.geoLocation,
                userSelectedAddress: args?.tabitOrder?.userSelectedAddress,
                giftCardsTotal: args?.tabitOrder?.giftCardsTotal,
                grandTotal: args?.tabitOrder?.grandTotal,
                gratuity: args?.tabitOrder?.gratuity,
                isDesktopMode: args?.tabitOrder?.isDesktopMode,
                isFuture: args?.tabitOrder?.isFuture,
                isLoggedIn: args?.tabitOrder?.isLoggedIn,
                isMember: args?.tabitOrder?.isMember,
                itemsDiscount: args?.tabitOrder?.itemsDiscount,
                itemsTotal: args?.tabitOrder?.itemsTotal,
                itemsCount: args?.tabitOrder?.itemsCount,
                items: args?.tabitOrder?.items,
                locationUnavailableReason: args?.tabitOrder?.locationUnavailableReason,
                nativePayment: args?.tabitOrder?.nativePayment,
                orderId: args?.tabitOrder?.orderId,
                orderMode: args?.tabitOrder?.orderMode,
                orderType: args?.tabitOrder?.orderType,
                organizationId: args?.tabitOrder?.organizationId,
                organizationName: args?.tabitOrder?.organizationName,
                route: args?.tabitOrder?.route,
                serverTax: args?.tabitOrder?.serverTax,
                serverUrl: args?.tabitOrder?.serverUrl,
                service: args?.tabitOrder?.service,
                timestamp: args?.tabitOrder?.timestamp,
                additionalData: args?.tabitOrder?.additionalData,
                contactData: args?.tabitOrder?.contactData,
                timeDiff: args?.tabitOrder?.timeDiff,
                payment: {
                    result: args?.tabitOrder?.payment?.result,
                    payments: args?.tabitOrder?.payment?.payments,
                },
            },
            tabitPay: {
                ...args?.tabitPay
            },
            walletPayment: {
                ...args?.walletPayment
            },
            giftCard: {
                additionalData: args?.giftCard?.additionalData,
                marketplace: args?.giftCard?.marketplace,
                campaign: args?.giftCard?.campaign,
                sendToFriend: args?.giftCard?.sendToFriend,
                topUp: args?.giftCard?.topUp,
                paymentMethods: args?.giftCard?.paymentMethods,
                isWallet: args?.giftCard?.isWallet,
                orderID: args?.giftCard?.orderID,
                isSuccess: args?.giftCard?.isSuccess,
                statusDescription: args?.giftCard?.statusDescription,
                amount: args?.giftCard?.amount,
                request: args?.giftCard?.request,
                siteId: args?.giftCard?.siteId,
                siteName: args?.giftCard?.siteName,
                customer: args?.giftCard?.customer,
            },
            auditData: {
                request: args?.auditData?.request,
                error: args?.auditData?.error,
                status: args?.auditData?.status,
            },
        };

        const loggerData = {
            message: `[${payloadData.client}] ${args.message}`,
            client: 'order',
            logVars: {}
        };

        loggerData.logVars = this.createIndexedLogData(payloadData);

        this._loggerHandler$(loggerData)
        .subscribe((response: any) => {
            //console.log('logger to Bridge Success: ', response);
        }, (err: any) => {
            console.debug('logger Error: ', err);
        });

        return payloadData;
    }

    private createIndexedLogData(payloadData: any) {
        const { giftCard = {}, tabitOrder = {}, tracker = {}, user = {}, route = {}, auditData = {} } = payloadData || {};
        const clientParams = this.buildClientParams(payloadData.module, payloadData.durationInSeconds, { tabitOrder, giftCard, tracker, user, auditData, route });

        return clientParams;
    }

    private buildClientParams(module: string, durationInSeconds: number, { tabitOrder, giftCard, tracker, user, auditData, route }: any) {
        let clientParams: any = {};

        switch (module) {
            case 'tabitOrder':
                clientParams = this.buildTabitOrderParams(tabitOrder);
                break;
            case 'giftCard':
                clientParams = this.buildGiftCardParams(giftCard);
                break;
            case 'tracker':
                clientParams = this.buildTrackerParams(tracker);
                break;
        }

        const baseParams: any = {
            source: this.appConfig.title,
            service: { name: this.appConfig.title },
            version: this.appConfig.version,
            device: this.buildDeviceParams(user),
            namespace: window['cordova'] ? 'tabitApp' : 'tabitWeb',
            payload: {
                ...clientParams.payload,
                auditData: { request: auditData?.request, error: auditData?.error },
                durationInSeconds: durationInSeconds,
                deviceLocalDate: new Date().toISOString(),
                deviceServerDate: this.additionalData?.realDateISOString,
            }
        };

        // Only for error
        if (auditData?.error) {
            baseParams.error = {
                code: auditData?.status,
                message: auditData?.error,
                stack: typeof auditData?.request == 'object' ? JSON.stringify(auditData?.request) : auditData?.error
            }
        };

        const consumerParams = this.buildConsumerParams(user, tabitOrder, giftCard);
        if (consumerParams.consumer) baseParams.consumer = consumerParams.consumer;

        const paymentParams = this.buildPaymentParams(tabitOrder, giftCard);
        if (paymentParams.payment) baseParams.payment = paymentParams.payment;

        const loyaltyParams = this.buildLoyaltyConsumerParams(user);
        if (loyaltyParams.consumer) baseParams.loyalty = { consumer: loyaltyParams.consumer };

        if (window['cordova']) {
            baseParams.payload.cordova = {
                version: window['cordova']?.version,
                cordovaWrapper: {
                    environment: window['environment'],
                    version: window['config']?.version
                }
            }
        }

        if (clientParams?.tags?.length) {
            clientParams.tags.unshift(window['cordova'] ? 'tabitApp' : 'tabitWeb')
        } else {
            clientParams.tags = [window['cordova'] ? 'tabitApp' : 'tabitWeb', 'general']
        }

        clientParams = { ...clientParams, ...baseParams };

        return clientParams;
    }

    private buildTabitOrderParams(tabitOrder: any) {
        const tags = compact(['TabitOrder', tabitOrder.orderStep, tabitOrder.orderMode, tabitOrder.isFuture ? 'isFuture' : null]);
        const mergedTags = concat(tags, tabitOrder.additionalData?.tags || []);

        const tabitOrderParams: any = {
            tags: mergedTags,
            order: omitBy({
                id: get(tabitOrder, 'orderId'),
                number: get(tabitOrder, 'orderNumber'),
                price: { amount: get(tabitOrder, 'grandTotal') },
                estimated_time: get(tabitOrder, 'estimatedTime'),
                order_type: get(tabitOrder, 'orderType'),
                delay_mode: get(tabitOrder, 'delayMode'),
                screen_name: get(tabitOrder, 'orderStep'),
            }, isNil),
            organization: omitBy({
                id: get(tabitOrder, 'organizationId'),
                name: get(tabitOrder, 'organizationName'),
            }, isNil),
            payload: {
                tabitOrder: pick(tabitOrder, [
                    'benefitsTotal', 'formattedAddress', 'geoLocation', 'giftCardsTotal', 'grandTotal',
                    'gratuity', 'itemsDiscount', 'itemsTotal', 'itemsCount', 'items', 'locationUnavailableReason',
                    'nativePayment', 'serverTax', 'service', 'additionalData', 'contactData', 'timeDiff',
                    'payment.result', 'payment.payments', 'userSelectedAddress', 'address'
                ]),
            },
        };
        return tabitOrderParams;
    }

    private buildGiftCardParams(giftCard: any) {
        return {
            tags: ['GiftCard'],
            order: omitBy({
                id: get(giftCard, 'orderID'),
                number: get(giftCard, 'orderNumber'),
                price: { amount: get(giftCard, 'amount', 0) },
            }, isNil),
            organization: omitBy({
                id: get(giftCard, 'siteId'),
                name: get(giftCard, 'siteName'),
            }, isNil),
            payload: {
                giftCard: pick(giftCard, [
                    'marketplace', 'campaign', 'sendToFriend', 'topUp', 'paymentMethods', 'isWallet', 'isSuccess',
                    'statusDescription', 'request', 'customer',
                ]),
            },
        };
    }

    private buildTrackerParams(tracker: any) {
        return {
            tags: ['Tracker'],
            order: omitBy({
                id: get(tracker, 'orderId'),
                number: get(tracker, 'orderNumber'),
                price: { amount: get(tracker, 'grandTotal', 0) },
            }, isNil),
            organization: omitBy({
                id: get(tracker, 'organizationId'),
                name: get(tracker, 'organizationName'),
            }, isNil),
        };
    }

    private buildPaymentParams(tabitOrder: any, giftCard: any) {
        const buildPaymentObject = (paymentData: any) => {
            const { _id, id, tenderType, amount, confirmationNum, is3DS } = paymentData || {};
            if (_id || id || confirmationNum || amount) {
                return {
                    payment: omitBy({
                        id: _id || id || confirmationNum || paymentData.paymentProcessId,
                        amount: amount || 0,
                        type: tenderType || paymentData.paymentType,
                        provider: {
                            status_code: confirmationNum || paymentData.error?.code,
                        },
                        is3DS,
                        providerInfo: paymentData?.providerInfo
                    }, isNil),
                };
            }
            return {};
        };
        const buildErrorPaymentObject = (serverError: any) => {
            const result = serverError?.providerError?.result;
            const tranId = serverError?.providerError?.tranId;
            const name = serverError?.name;
            
            if (tranId || result || name) {
                return {
                    payment: omitBy({
                        id: tranId,
                        type: name,
                        provider: {
                            status_code: result,
                        },
                    }, isNil),
                };
            }
            return {};
        };
        if (tabitOrder?.additionalData?.serverError || giftCard?.additionalData?.serverError) {
            return buildErrorPaymentObject(tabitOrder?.additionalData?.serverError) || buildErrorPaymentObject(giftCard?.additionalData?.serverError) || {};
        }

        if (!isEmpty(tabitOrder?.additionalData?.payment)) {
            return buildPaymentObject(tabitOrder?.additionalData?.payment);
        }

        if (!isEmpty(giftCard?.additionalData?.payment)) {
            return buildPaymentObject(giftCard?.additionalData?.payment);
        }

        return {};
    }

    private buildConsumerParams(user: any, tabitOrder: any, giftCard: any) {
        return {
            consumer: omitBy({
                phone:get(tabitOrder?.customer, 'phone') || get(user, 'phone') || get(giftCard?.customer, 'ordererPhone'),
                name: get(tabitOrder?.customer, 'name') || get(giftCard?.customer, 'name'),
                email: get(tabitOrder?.customer, 'email') || get(giftCard?.customer, 'ordererEmail'),
            }, isNil),
        };
    }

    private buildDeviceParams(user: any) {
        return {
            id: this.additionalData.clientId || '',
            type: user.deviceInfo.deviceType,
            type_name: user.deviceInfo.os,
            os_name: user.deviceInfo.os_name,
            os_version: user.deviceInfo.os_version,
            useragent: user.deviceInfo.userAgent,
            orientation: user.deviceInfo.orientation,
            route: window.location.pathname,
            url: window.location.href,
            timestamp: this.additionalData.realDate,
        };
    }

    private buildLoyaltyConsumerParams(user: any) {
        return {
            consumer: omitBy({
                phone:get(user?.loyaltyCustomer, 'Mobile'),
                name: get(user?.loyaltyCustomer, 'FullName') || null,
                email: get(user?.loyaltyCustomer, 'Email'),
            }, isNil),
        };
    }

    private _loggerHandler$(loggerPayload: any): Observable<any> {
        return this.http.post<{}>(`${this.appConfig.tabitBridge}/logger`, loggerPayload, this.additionalData.appHttpOptions);
    }

}
