import { Component, OnInit, Input } from '@angular/core';

import { BookService } from '../../_core/book.service';
import { AppService } from '../../app.service';

@Component({
    selector: 'time-slots',
    templateUrl: './time-slots.component.html',
    styleUrls: ['./time-slots.component.scss']
})
export class TimeSlotsComponent implements OnInit {

    @Input() public siteId: any;
    @Input() public timeSlots: any;
    @Input() public areaDescriptions: any;

    public navigationLink: any;

    constructor(
        public appService: AppService,
        public bookService: BookService
    ) { }

    ngOnInit() {
        if (this.timeSlots) {
            this.timeSlots = this.bookService.populateDisabledTimeSlots(this.timeSlots);
        }
    }

    async navigateToWebLink(event: Event, timeSlot: any) {
        if (['disabled'].includes(timeSlot?.class_name)) {
            event.preventDefault(); // Prevent navigation if the time slot is disabled
        }

        this.appService.startBlock();
        try {
            const link: any = await this.bookService.getLinkForTimeSlotOrRedirect(event, timeSlot, this.areaDescriptions, this.siteId);
            if (link) {
                this.navigationLink = link;
                window.location.href = link.toString();
            }
        } catch(err) {
            console.error('Error during navigation:', err);
        } finally {
            this.appService.stopBlock();
        }
    }
}
