import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss']
})
export class SignInDialogComponent implements AfterViewInit {
    mixpanelTrackingData: any;

    constructor(
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
        if (data.mixpanelTrackingData) this.mixpanelTrackingData = data.mixpanelTrackingData;
    }

    ngAfterViewInit() {
        // this.appService.updatePrivateStore('TOSideMenuOpen', false);
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
