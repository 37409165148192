import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';

import { BehaviorSubject, Subject } from 'rxjs';
import { get, cloneDeep, assignIn, find, round, each, isObject, extend } from 'lodash-es';
import moment from 'moment-timezone';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { LoyaltyService, JoinChannelOrigin } from '../../_core/loyalty.service';
import { Address, AddressesService } from '../../_core/addresses.service';
import { NotificationsService } from '../../_core/notifications.service';
import { MarketingService, ANALYTICS_LAYER_TYPES, PIXEL_EVENT_TYPES } from '../../_core/marketing-tools.service';
import { TOBrandingService } from './to.branding.service';
import { TabitpayService } from '../../order/tabit-pay/tabit-pay.service';
import { TranslationService } from '../../_core/translation.service';

import { orderingStrings_heIL } from '../i18n/translations.ordering.he-IL';
import { orderingStrings_enUS } from '../i18n/translations.ordering.en-US';
import { orderingStrings_enAU } from '../i18n/translations.ordering.en-AU';
import { orderingStrings_arIL } from '../i18n/translations.ordering.ar-IL';
import { orderingStrings_ruRU } from '../i18n/translations.ordering.ru-RU';


declare const $: any;
@Injectable({
	providedIn: 'root',
})
export class TODataService {

    private redirectSubject = new BehaviorSubject<any>(null);
    public redirect = this.redirectSubject.asObservable();
    public navigateToStep = new BehaviorSubject<any>(null);

    // We need that behaviour subject in order to listen to it in valuable places
    // that are a must in the payment process, so we could initiate no amount order
    // please refer to - tabit-order, to-desktop-checkout and tou-loyalty-dialog
    public processNoAmountOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // For now we use it only in room charge flow
    // but in the future, it's a possibility that organizations
    // in Israel or abroad would allow using foreign currency
    public paymentsForeignCurrencySubject: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);

    // We need that subject in order to listen to Hammer changes
    public setHammerSubject = new Subject<any>();
    public preventForward = false;
    public preventNavigateBack = false;
    public started: boolean = false;
    public landedFromChildFolder: boolean = false;

    public translations: any = {};
    public $storage: any = {};
    public basketDynamicHeight;
    public customFallbackImage: string;

    public ISDESKTOP: boolean = false;

    public backgroundImageStyle = {
        // 'background-image': 'url(http://localhost:5200/assets/images/example-background.jpg)',
        // 'background-size': 'cover',
        // 'background-repeat': 'no-repeat'
    };

    public orderingStrings: any = {};

    public orderSteps = {
        enter: 0,
        address: 1,
        menu: 2,
        extras: 3,
        contact: 4,
        gratuity: 5,
        pay: 6,
        end: 7
    };

    public orderStepsMap = {
        0: "enter",
        1: "address",
        2: "menu",
        3: "extras",
        4: "contact",
        5: "gratuity",
        6: "pay",
        7: "end"
    }

    public orderSections = {
        enter: 0,
        menu: 1,
        checkout: 2,
        end: 3
    }

	public tdOrderTypeMap: any = {
		"Seated": "eatin",
		"Delivery": "delivery",
		"TA": "takeaway"
    }

    public tdTypeMap = {
        "takeaway": "TA",
        "eatin": "OTC",
        "delivery": "Delivery"
    }

    public tdServiceTypeMap = {
        "eatin": "seated",
        "takeaway": "takeaway"
    }

    public UIArgs: any = {
        viewDefIcon: 'images/icon-done.svg',
        categoryDefIcon: 'images/icon-done.svg',
        itemDefIcon: 'images/icon-done.svg',
        selectedMenu: null
    }

    public taxRegions = ["en-US"];

    public locals = {
        "he-IL": {
            angularLocal: "he-il",
            momentLocal: "he",
            isRTL: true,
            transLang: "he-IL",
            baseLang: "he-IL",
            currecySymbol: "\u20aa",
            mapSearchLocalComponents: "country:IL|street_address",
            mapLanguage: "iw",
            decimals: 2,
            timeFormat: 'HH:mm',
            shortDateFormat: 'dd/MM',
            phoneCountry: 'il',
            preferredCountries: ['us', 'fr', 'gb', 'ru', 'de', 'it', 'ua']
        },
        "en-US": {
            angularLocal: "en",
            momentLocal: "en",
            isRTL: false,
            transLang: "en",
            baseLang: "en",
            currecySymbol: "$",
            mapSearchLocalComponents: "country:US|street_address",
            mapLanguage: "en",
            decimals: 2,
            timeFormat: 'h:mm a',
            shortDateFormat: 'MM/dd',
            phoneCountry: 'us',
            preferredCountries: ['mx', 'ca', 'gb', 'jp', 'ch', 'kr', 'de', 'br', 'fr', 'au']
        },
        "en-AU": {
            angularLocal: "en",
            momentLocal: "en",
            isRTL: false,
            transLang: "en",
            baseLang: "en",
            currecySymbol: "$",
            mapSearchLocalComponents: "country:AU|street_address",
            mapLanguage: "en",
            decimals: 2,
            timeFormat: 'h:mm a',
            shortDateFormat: 'dd/MM',
            phoneCountry: 'au',
            preferredCountries: ['nz', 'us', 'in', 'sg', 'cn', 'jp', 'kr', 'id', 'my', 'ca', 'de', 'hk', 'fr', 'th', 'il']
        },
        "ar-IL": {
            angularLocal: "ar-IL",
            momentLocal: "ar",
            isRTL: true,
            transLang: "ar-IL",
            baseLang: "ar-IL",
            currecySymbol: "\u20aa",
            mapSearchLocalComponents: "country:IL|street_address",
            mapLanguage: "iw",
            decimals: 2,
            timeFormat: 'HH:mm',
            shortDateFormat: 'dd/MM',
            phoneCountry: 'il',
            preferredCountries: ['us', 'fr', 'gb', 'ru', 'de', 'it', 'ua']
        },
        "ru-RU": {
            angularLocal: "ru",
            momentLocal: "ru",
            isRTL: false,
            transLang: "ru",
            baseLang: "ru",
            currecySymbol: "\u20aa",
            mapSearchLocalComponents: "country:IL|street_address",
            mapLanguage: "ru",
            decimals: 2,
            timeFormat: 'HH:mm',
            shortDateFormat: 'dd/MM',
            phoneCountry: 'il',
            preferredCountries: ['mx', 'ca', 'gb', 'jp', 'ch', 'kr', 'de', 'br', 'fr', 'au']
        },
    }

    public allowForeignNumbers(allow?) {
        const local = this.$storage.local;
        if (!this.$storage?.local) return;
        if (allow) {
            local.phonePreferredCountries = [local.phoneCountry].concat(local.preferredCountries);
            local.phoneOnlyCountries = [];
        } else {
            local.phonePreferredCountries = [];
            local.phoneOnlyCountries = [local.phoneCountry];
        }
    }

    public futureDelayBase: any = {
        minDelayDays: 0,
        maxDelayDays: 30,
        enableOutsideofWorkhours: true,
        showServiceButton: true,
        supplyTimeSteps: 30,
        supplyTimeDisclaimer: undefined,
        days: [
            {
                "day": -1,
                type: "custom",
                enableOrder: true,
                maxTimeForSameDay: 960,

                enableSupply: true,
                enableSameDaySupply: true,
                supplyMode: 'asap',
                supplyRange: {
                    from: 480,
                    to: 960
                },
                stopOrderSlots: []
                //supplyMode: 'slots'
            }, {
                "day": 0, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 1, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 2, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 3, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 4, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 5, type: "default", enableOrder: true, enableSupply: true,
            }, {
                "day": 6, type: "default", enableOrder: false, enableSupply: false,
            }
        ],
        activeDays: [],
        "inactiveDates": [],
        "dates": [],
        "activeSlots": [],
    }

    private cacheDisabled: boolean = false;

    constructor(
        private http: HttpClient,
        private addressesService: AddressesService,
        private brandingService: TOBrandingService,
        public marketingService: MarketingService,
		public appService: AppService,
		public entityService: EntityService,
        public notificationsService: NotificationsService,
        public loyaltyService: LoyaltyService,
        public tabitPayService: TabitpayService,
        private translationService: TranslationService,
		public platformService: Platform,
    ) {
        this.orderingStrings['he-IL'] = orderingStrings_heIL;
        this.orderingStrings['en-US'] = orderingStrings_enUS;
        this.orderingStrings['en-AU'] = orderingStrings_enAU;
        this.orderingStrings['ar-IL'] = orderingStrings_arIL;
        this.orderingStrings['ru-RU'] = orderingStrings_ruRU;
    }

    init() {
        if (this.started) return Promise.resolve();
        const ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            this.ISDESKTOP = false;
            document.body.classList.add("_mobile");
            this.disableReCaptchaClick();
        } else {
            this.ISDESKTOP = true;
            document.body.classList.add("_desktop");
        }

        this.translations = this.orderingStrings[this.appService.localeId];
        this.appService.updateTranslations(this.orderingStrings[this.appService.localeId]);
        return Promise.resolve(this.translations);
    }

    getUserLocal() {
        const defaultLocalId = this.appService.appConfig.locale; // hardcoded untill site region is supported
        if (this.appService.localeId !== defaultLocalId) {
            return this.appService.localeId;
        }
    }

    // --------------------------------------------------------------------------------------------------------------->

    getStorageKey(params) {
        // Must support lowercase format here
        return params.site || params.org || params.siteName || params.orgName || params.sitename || params.orgname;
    }

    cacheSessionOrder() {
        if (this.appService.isApp) return;
        //if (window['cordova']) return;
        if (get(this.$storage, 'orderMode') == 'VIEW_MENU' || this.cacheDisabled) return;
        let key = get(this.$storage, 'storageKey');
        if (key) {
            try {
                this.saveDataAtSessionStorage(key);
            } catch (e) {
                console.error(`Error in saving tos_order_${key}, ${e}`);
                // It clears the whole storage, so use carefully
                // if the session storage would have wider usage
                // the logic would must be changed
                this.appService.clearSessionStorage();
                try {
                    this.saveDataAtSessionStorage(key);
                } catch(e) {
                    // Prevent caching attempts
                    this.cacheDisabled = true;
                    console.error(`Second try to cache data also failed: ${e}`);
                }
            }
        }
    }

    cacheSessionToken(token) {
        let key = get(this.$storage, 'storageKey');
        if (key) {
            sessionStorage.setItem(`to_ltk_${key}`, JSON.stringify(token));
        }
    }

    getTokenFromSessionStorage() {
        let token;
        try {
            let key = get(this.$storage, 'storageKey');
            if (key) {
                token = sessionStorage.getItem(`to_ltk_${key}`);
                token = JSON.parse(token);
            }

        } catch (e) {
        }
        return token;
    }

    getSessionOrder(key, step) {
        return new Promise((resolve, reject) => {
            try {
                let sOrder = this.getDataFromSessionStorage(key);
                if (!sOrder) throw ({});
                let oOrder = JSON.parse(sOrder);
                if (oOrder.orderStep != this.orderSteps[step]) throw ({});
                this.brandingService.setBrandingConfig(oOrder.config);

                //ONLY FOR TEST NEED TO REMOVE!!!
                if (oOrder['siteId'] == "661657421e711574e03c3c98") {
                    this.implementIpracticomScript('972779800472');
                    resolve(oOrder);
                }

                const externalChat = oOrder?.rosConfig?.externalChat;
                if (externalChat?.provider) {
                    this.setExternalChat(externalChat);
                }
                resolve(oOrder);
            } catch (e) {
                this.clearSessionOrder(key);
                reject();
            }
        });
    }

    getDataFromSessionStorage(key) {
        const order = sessionStorage.getItem(`tos_order_${key}`);
        return order;
    }

    saveDataAtSessionStorage(key) {
        sessionStorage.setItem(`tos_order_${key}`, JSON.stringify(this.$storage));
    }

    clearSessionOrder(key?) {
        if (!key) key = get(this.$storage, 'storageKey');
        sessionStorage.removeItem(`tos_order_${key}`);
    }

    // --------------------------------------------------------------------------------------------------------------->
	// API
	// --------------------------------------------------------------------------------------------------------------->

    cacheAddress(address: Address, enrichStoredAddress?: boolean) {
        // address = pick(address, ['_id', 'addressType', 'location', 'formatted_address', 'city', 'house', 'street', 'apartment', 'floor', 'entrance', 'notes', 'default']);
        this.addressesService.saveAddress(address, enrichStoredAddress).subscribe(addressSaved => {
            console.debug('=== TO/DATA/SERVICE === address saved:', addressSaved);
        }, (err) => {
            console.error('Error saving address:', err);
        });
    }

    deleteAddress(address: Address) {
        this.$storage.userAddresses = this.addressesService.deleteAddress(address);
    }

	// --------------------------------------------------------------------------------------------------------------->
	// logger
	// --------------------------------------------------------------------------------------------------------------->

    logger(actionType, data?, message?) {
        if (!message) message = `tabitOrder: ${actionType}`;
        const _order = get(this.$storage, 'order', {});
        const _branch = _order.branch || {};
        const _args = assignIn(this.loggerTabitOrderData(_order, _branch, actionType), data);

        if (actionType == 'handoff') {
            // Location
            if (get(_order.address, 'location')) {
                _args.geoLocation = `${_order.address.location.lat}, ${_order.address.location.lng}`;
            }
            // Native payment (Apple/Google Pay)
            if (get(_args, 'payment.nativePayment')) {
                _args.nativePayment = get(_args, 'payment.nativePayment');
                delete _args.payment.nativePayment;
            }
        }
        let user = {};

        if (this.$storage?.loyaltyData?.customer?.mobile) {
            user = {
                loyaltyCustomer: {
                    firstName: this.$storage.loyaltyData.customer.firstName,
                    lastName: this.$storage.loyaltyData.customer.lastName,
                    fullName: this.$storage.loyaltyData.customer.fullName,
                    mobile: this.$storage.loyaltyData.customer.mobile,
                    email: this.$storage.loyaltyData.customer.email,
                }
            };
        }

        this.appService.logger({ message, tabitOrder: _args, user, module: 'tabitOrder' });
    }

    loggerTabitOrderData(_order?, organization?, actionType?) {
        const supplyArgs = this.getSupplyTime();
        return {
            actionType,
            address: _order?.address,
            orderStep: this.orderStepsMap[this.$storage.orderStep],
            benefitsTotal: _order?.benefitsTotal || 0,
            customer: this.prepareCustomerForLog(_order?.customer),
            delayMode: supplyArgs?.delayMode || 'ASAP',
            estimatedTime: supplyArgs?.ETA,
            giftCardsTotal: _order?.giftCardsTotal,
            grandTotal: _order?.grandTotalPlusTip,
            gratuity: _order?.gratuityAmount,
            isDesktopMode: this.ISDESKTOP,
            isFuture: !!this.$storage.futureOrder,
            isLoggedIn: this.appService.isAuthUser(),
            isMember: !!this.$storage.loyaltyData,
            itemsDiscount: _order?.benefitsTotal,
            itemsTotal: _order?.itemsTotal,
            itemsCount: _order?.quantity,
            items: this.prepareBasketForLog(),
            deviceLocalDate: new Date(),
            orderId: this.$storage.$orderId,
            orderMode: this.getOrderMode(),
            orderType: this.tdTypeMap[this.$storage.orderMode],
            organizationId: organization._id,
            organizationName: organization.name,
            platform: navigator.platform,
            route: document.location.href,
            serverTax: _order?.tax,
            serverUrl: this.appService.appConfig.tabitAPI,
            service: this.getOrderMode(),
            timestamp: this.appService.getRealDate(),
            userAgent: navigator.userAgent,
        };
    }

    getOrderMode() {
        const readOnly = this.$storage?.order?.readOnly;
        if (readOnly || this.$storage?.orderMode == 'VIEW_MENU') return 'VIEW_MENU';
        return this.$storage?.orderMode;
    }


	// --------------------------------------------------------------------------------------------------------------->
	// CACHE/RESTORE
	// --------------------------------------------------------------------------------------------------------------->

    getPreviousOffers() {
        return this.getPreviousOffers_from_db()
        .then(res => {
            return res;
        })
    }

    getPreviousOffers_from_db() {
        const $storage = this.$storage;
        const organizationId = get($storage, 'siteId');
        if (!this.appService.user || Object.keys(this.appService.user).length === 0 || !organizationId) return;

        const url = `${this.appService.appConfig.tabitBridge}/customers/full-history/${organizationId}`;
        const httpHeaders = this.appService.appHttpOptions;
        httpHeaders.headers = httpHeaders.headers.set('mobile', this.appService.user?.loyaltyCustomer?.Mobile || '');

        return new Promise((resolve, reject) => {
            return this.http.get<{}>(url, httpHeaders
            ).subscribe(
                (results: any) => {
                    let res = [];
                    each(results, record => {
                        if (record.fullItems) record.orderedItems = record.fullItems;
                        res.push(record);
                    })
                    resolve(res);
                },
                (err) => {
                    resolve([]);
                    console.error('=== Error receiving customer history ===', err);
                }
            );
        });
    }

    prepareBasketForLog() {
        const $storage = this.$storage;
        const order = $storage.order;
        const that = this;
        let orderedOffers = [];
        let deliveryInfo: any = {};

        //start basket
        each(this.$storage.basket, function (offer) {
            var q = offer.quantity || 1;
            for (var i = 0; i < q; i++) {
                var parsedOffer: any = {
                    "_id": that.getObjectId(),
                    "amount": Math.round(offer.total * 100),
                    "menu": offer.menu,
                    "price": offer.openPrice ? 0 : Math.round(offer.price * 100),
                    "offer": offer.offer,
                    "name":  offer.realName ? offer.realName : offer._offer?.realName ? offer._offer.realName : offer.name,
                    "orderedItems": []
                }
                if (offer.upsellType) parsedOffer.upsellType = offer.upsellType;
                let note = offer.offerRemrks;
                if ($storage.complexDiners) parsedOffer.diner = offer.diner;

                // main offer item
                if (offer._item) {
                    var pitem = prepareBasketOfferItem(offer._item, offer._item.total, null, note);
                    if (offer.isWeight) {
                        pitem.uom = offer.uom;
                        pitem.units = offer.baseUnits;
                    }
                    if (pitem) {
                        if (offer.isWeight) {
                            pitem.uom = offer.uom;
                            pitem.units = offer.baseUnits;
                        }
                        if (offer.openPrice) {
                            pitem.amount = pitem.price = parsedOffer.amount;
                        }
                        parsedOffer.orderedItems.push(pitem);
                    }
                }
                // basket items
                each(offer.selectionSummary, function (selectionGroup) {
                    if (!selectionGroup.isModifier) {
                        each(selectionGroup.items, function (item) {
                            let count = item.count || 1
                            for (let i = 0; i < count; i++) {
                                var pitem = prepareBasketOfferItem(item._item, item.price, selectionGroup);
                                if (pitem) {
                                    parsedOffer.orderedItems.push(pitem);
                                }
                            }
                        });
                    }
                });
                orderedOffers.push(cloneDeep(parsedOffer));
            }

            function prepareBasketOfferItem(item, itemPrice, group?, note?): any {
                if (!itemPrice) itemPrice = 0;
                var parsedItem: any = {
                    "_id": that.getObjectId(),
                    "category": item.category && item.category._id,
                    "item": item._id,
                    "name": item.realName ? item.realName : item.name,
                    "price": Math.round(itemPrice * 100),
                    "tags": item.tags || []
                }
                if (note) parsedItem.note = note;
                if ($storage.complexDiners) parsedItem.diner = offer.diner;

                if (item.notes) {
                    parsedItem.note = item.notes;
                }
                if (group) {
                    parsedItem.itemGroup = item.itemGroup;
                    parsedItem.itemGroupName = group.name;
                } else {
                    parsedItem.offer = offer.offer;
                    parsedItem.menu = offer.menu;
                }
                //if (note) parsedItem.note = note;
                var itemAmount = parsedItem.price || 0;
                var modifiers = [];
                var removedModifiers = [];

                each(item.modifierGroups, function (group) {
                    if (group.singleSelection) {
                        var mod: any = find(group.modifiers, { '_selected': true });
                        if (mod) {
                            modifiers.push(prepareBasketModifier(mod, group));
                            if (!mod._isDefault) {
                                var defMod = find(group.modifiers, { '_isDefault': true });
                                if (defMod) removedModifiers.push(prepareBasketModifier(defMod, group))
                            }
                        }
                    } else {
                        each(group.modifiers, function (mod) {
                            if (mod.formationUnit) {
                                let modCount = mod.count || 1;
                                for (var i = 0; i < modCount; i++) {
                                    if (mod.price) itemAmount = itemAmount + Math.round(mod.price * 100);
                                    modifiers.push(prepareBasketModifier(mod, group, true));
                                }
                            } else if (mod.defaultFormationUnit) {
                                removedModifiers.push(prepareBasketModifier(mod, group))
                            }
                        });
                    }
                });
                parsedItem.selectedModifiers = modifiers;
                parsedItem.removedModifiers = removedModifiers;
                parsedItem.amount = Math.round(itemAmount);
                return parsedItem;
            }
        });

        //start delivery offer ------------------------------------------------------------>

        if (!order?.freeDelivery) {
            if (get(order, 'region.deliveryOffer')) {
                var deliveryOffer = cloneDeep(order.region.deliveryOffer);
                delete deliveryOffer._id;
                deliveryOffer.amount = deliveryOffer.price;
                deliveryOffer.orderedItems[0].price = null;
                deliveryOffer.isDeliveryFee = true;
                orderedOffers.push(deliveryOffer);
            }
        }

        //start extra Fees ------------------------------------------------------------>

        if ($storage?.extraFees?.length) {
            const extraFees = $storage.extraFees
                .filter(extraFee => extraFee?.active != false)
                .map(({ orderedItems, menu, offer, amount, name }) => ({
                    orderedItems,
                    menu,
                    offer,
                    price: amount ?? 0,
                    name,
                    amount: amount ?? 0,
                }));

            orderedOffers.push(...extraFees);
        }

        // Start promotions ---------------------------------------------------------------->

        let orderedDiscounts = [];
        if (this.orderEligibleForPromotion()) {
            const discount = {
                discountType: 'amount',
                value: round(order.promotion.calc.amount * 100),
                onlinePromoId: order.promotion._id,
                comment: order.promotion.name,
                valueType: order.promotion.valueType,
                reason: order.promotion.reason,
            };
            orderedDiscounts.push(discount);
        }

        //start extra offers ---------------------------------------------------------------->

        each($storage.selectedExtraOffers, (newOffer) => {
            prepareExtraOffer(newOffer);
        });

        each($storage.selectedExtraOffersGroups, (group) => {
            if (group.count) {
                each(group.offers, (newOffer) => {
                    prepareExtraOffer(newOffer);
                });
            }
        });

        each($storage.extraQuestions, (question) => {
            if (question?.active) {
                if (!deliveryInfo?.declerations) deliveryInfo.declerations = [];
                deliveryInfo.declerations.push(question.name);
                if (question.offer) {
                    let newOffer = cloneDeep(question.offer);
                    delete newOffer.limitBy;
                    delete newOffer.organization;
                    delete newOffer.active;
                    newOffer.count = 1
                    prepareExtraOffer(newOffer)
                }
            }
        });

        function prepareExtraOffer(newOffer): void {
            let count = newOffer.count || 0;
            let newItem = newOffer.orderedItems[0];
            for (var i = 0; i < count; i++) {
                let offerId = that.getObjectId();
                let itemId = that.getObjectId();
                orderedOffers.push({
                    "_id": offerId,
                    "amount": 0,
                    "menu": newOffer.menu,
                    "price": 0,
                    "offer": newOffer.offer,
                    "name": newOffer.name,
                    "orderedItems": [
                        {
                            "_id": itemId,
                            "category": newItem.category && newItem.category._id,
                            "item": newItem.item,
                            "name": newItem.name,
                            "amount": 0,
                            "price": 0,
                            "tags": [],
                            "offer": newItem.offer,
                            "menu": newItem.menu,
                            "removedModifiers": [],
                            "selectedModifiers": []
                        }
                    ]
                });
            }
        }

        function prepareBasketModifier(mod, group, checkSubMods?): any {
            var _mod: any = {
                "modifier": mod._id,
                "name": mod.realName ? mod.realName : mod.name,
                "modifierGroup": group._id,
                "modifierGroupName": group.realName ? group.realName : group.name,
                "item": (mod.item && mod.item._id) || mod.item,
                "isDefault": mod._isDefault || mod.defaultFormationUnit != null,
                "price": mod.price ? Math.round(mod.price * 100) : 0
            }
            if (checkSubMods) {
                _mod.formationUnit = mod.formationUnit;
                var selectedModifiers = [];
                each(mod.groups, function (group) {
                    selectedModifiers.push({
                        "modifier": group.value,
                        "name": group.valueName,
                        "modifierGroup": group._id,
                        "modifierGroupName": group.realName ? group.realName : group.name,
                        "isDefault": group._default == group.value
                    });
                });
                if (selectedModifiers.length) {
                    _mod.selectedModifiers = selectedModifiers;
                }
            }
            return _mod;
        }

        return orderedOffers;
    }

    prepareCustomerForLog(orderCustomer) {
        const customer = {
            approvedTerms: false,
            dinerCount: 1,
            email:'',
            firstName: '',
            includeCutlery: false,
            lastName: '',
            orderNotes: '',
            name: '',
            phone: '',
        };

        if (!orderCustomer && this.appService.user?.loyaltyCustomer) {
            extend(customer, {
                approvedTerms: true,
                email: get(this.appService.user.loyaltyCustomer, 'Email'),
                firstName: get(this.appService.user.loyaltyCustomer, 'FirstName'),
                lastName: get(this.appService.user.loyaltyCustomer, 'LastName'),
                name: get(this.appService.user.loyaltyCustomer, 'FullName'),
                phone: get(this.appService.user.loyaltyCustomer, 'Mobile'),
            });
        }

        const logCustomer = Object.assign(customer, orderCustomer);

        return logCustomer;
    }

    getObjectId() {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

	// --------------------------------------------------------------------------------------------------------------->
	// HTTP UTILS
	// --------------------------------------------------------------------------------------------------------------->

    getHeaders(withOrg?, siteId?, acceptLanguage?, otpVerified?: string) {
        if (!withOrg) return;
        const headers = { 'ros-organization': siteId ? siteId : this.$storage.siteId }
        if (acceptLanguage) headers['accept-language'] = acceptLanguage;
        if (!!otpVerified) headers['otp-verified'] = otpVerified;

        return headers;
    }

    get(url, payLoad, withOrg = true, siteId?) {
        return this.entityService.get(url, payLoad, this.getHeaders(withOrg, siteId), this.appService.appConfig.tabitOrder_tabitAPI ? `${this.appService.appConfig.tabitOrder_tabitAPI}${url}` : null, null, 1);
    }
    getBridge(url, payLoad, withOrg = true, siteId?) {
        return this.entityService.get(null, payLoad, this.getHeaders(withOrg, siteId), `${this.appService.appConfig.tabitBridge}${url}`);
    }
    post(url, payLoad, withOrg = true, retries = 1, acceptLanguage?) {
        return this.entityService.post(url, payLoad, this.getHeaders(withOrg, null, acceptLanguage), this.appService.appConfig.tabitOrder_tabitAPI ? `${this.appService.appConfig.tabitOrder_tabitAPI}${url}` : null, null, retries);
    }
    postBridge(url, payLoad, withOrg = true, retries = 1, enableRecaptcha = false, otpVerified = null) {
        return this.entityService.post(url, payLoad, this.getHeaders(withOrg, null, null, otpVerified), `${this.appService.appConfig.tabitBridge}${url}`, null, retries, enableRecaptcha);
    }
    put(url, payLoad, withOrg = true) {
        return this.entityService.put(url, payLoad, this.getHeaders(withOrg, null), this.appService.appConfig.tabitOrder_tabitAPI ? `${this.appService.appConfig.tabitOrder_tabitAPI}${url}` : null);
    }
    exHTTPGet(url, params) {
        return new Promise((resolve, reject) => {
            this.http.get(url, { params: params }).subscribe(
                (results: any) => { resolve(results); },
                (err) => { reject(err) }
            );
        });
    }

    public setInnerRedirect(step) {
        this.redirectSubject.next(step);
    }

    orderEligibleForPromotion() {
        const calc = this.$storage.order?.promotion?.calc;
        const calculatedAmount = calc?.amount || 0;
        return calculatedAmount > 0;
    }
    // --------------------------------------------------------------------------------------------------------------->
	// GTM / GA - DATA LAYER (E-Commerce)
    // --

    public prepareGA4LayerAndPushIfNeeded(data, type: ANALYTICS_LAYER_TYPES) {
        this.marketingService.prepareGA4LayerAndPushIfNeeded(data, type, this.$storage);
    }

    public preparePixelLayerAndPushIfNeeded(data, type: PIXEL_EVENT_TYPES) {
        this.marketingService.preparePixelAndPushIfNeeded(data, type, this.$storage);
    }

    public checkTimeDiff(clientDate, serverDate, timezone, silent) {
        const timediff = this.appService.calcServerDateDiff(clientDate, serverDate, timezone, silent);
        this.$storage.timeDiffFromServer = {
            shown: false,
            timediff
        };
    }

    public showTimeDiffIfNeeded() {
        const timeDiff = this.$storage.timeDiffFromServer.timediff;
        if (this.$storage.timeDiffFromServer.shown) return;
        // 5 Minutes difference
        const messageTimeDiff = timeDiff / 1000 / 60;
        if (this.appService.hasTimeDiff(messageTimeDiff)) {
            this.appService.showTimeDiffMessage(messageTimeDiff);
            this.$storage.timeDiffFromServer.shown = true;
        }
    }

    isOrderTimerActive() {
        return this.$storage.started && !this.$storage.orderClosed && this.$storage.catalog && !this.$storage.payInProgress;
    }

    public prepareForeignCurrencyData(payments) {
        let amount = 0;
        let symbol;

        // Run throught all payments and create currency total
        payments?.map(payment => {
            const currency = get(payment, 'providerReporting.currency', 0);
            if (currency) {
                amount += currency.amount;
                if (!symbol) symbol = currency.symbol;
            }
        });

        const foreignCurrencyData = {
            amount: round((amount / 100), 2),
            symbol
        };

        this.paymentsForeignCurrencySubject.next(foreignCurrencyData);
    }

    async stopOrderProc() {
        const orderId = get(this.$storage, '$orderId');

        const payload = {
            contactData: {
                phone: get(this.$storage, 'order.customer.phone'),
                email: get(this.$storage, 'order.customer.email'),
                deviceId: this.notificationsService.getDeviceId,
            }
        };

        return this.postBridge(`/validation/${orderId}`, payload, true, 0)
        .then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            console.debug(err);
            return Promise.reject(err);
        });
    }

    setExternalChat(externalChatObj: { provider: string; identifier: string; apiKey?: string }) {
        const { provider, identifier, apiKey } = externalChatObj;
        if (!provider || !identifier) return;

        //ONLY FOR TEST NEED TO REMOVE!!!
        if (this.$storage.siteId == "661657421e711574e03c3c98") {
            this.implementIpracticomScript('972779800472');
            return;
        }

        switch (provider) {
            case 'Intercom':
                this.implementIntercomScript(identifier);
                break;
            case 'Ipracticom':
                this.implementIpracticomScript(identifier);
                break;
            case 'Glassix':
                if (!apiKey) return;
                this.implementGlassixScript({ apiKey, snippetId: identifier });
                break;
        }
    }

    //implementIpracticomScript
    implementIpracticomScript(phoneNumber) {

        const globalWindow: any = window;

        globalWindow['chatProvider'] = 'Ipracticom';
        var BASE_URL = "https://www.online.99digital.co.il";
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = BASE_URL + "/packs/js/wa_widget.js";
        var options = {
            baseUrl: BASE_URL,
            phoneNumber,
            brand: { "name": "iPracticom", "logo": "ip-com-logo.svg", "link": "http://www.ip-com.co.il/" }
        };
        s.onload = function () {
            globalWindow.onlineWaWidget.run(options);
        };
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    }

    implementGlassixScript(externalChatParams: { apiKey: string; snippetId: string }) {
        const globalWindow: any = window;
        const { apiKey, snippetId } = externalChatParams;

        if (!apiKey || !snippetId) return;

        globalWindow['chatProvider'] = 'Glassix';

        let scriptToLoadClone;
        const direction = this.appService.direction == 'rtl' ? 'right' : 'left';
        const styleObject = { position: direction, mobilePosition: direction };
        const widgetOptions = { ...styleObject, ...externalChatParams };

        if (!widgetOptions.apiKey || !widgetOptions.snippetId) return;

        const initializeWidget = () => {
            if (typeof globalWindow.GlassixWidgetClient === 'function' && globalWindow.GlassixWidgetClient) {
                globalWindow.widgetClient = new globalWindow.GlassixWidgetClient(widgetOptions);
                globalWindow.widgetClient.attach();
                if (globalWindow.glassixWidgetScriptLoaded) globalWindow.glassixWidgetScriptLoaded();
            } else {
                loadFallback();
            }
        };

        const loadFallback = function () {
            const scriptParentNode = document.getElementsByTagName('script')[0];
            const scriptToLoad = document.createElement('script');
            scriptToLoad.onload = initializeWidget;
            scriptToLoad.src = 'https://cdn.glassix.net/clients/widget.1.2.min.js';
            scriptParentNode.removeChild(scriptToLoadClone);
            scriptParentNode.insertBefore(scriptToLoad, scriptParentNode.firstChild);
        };



        const loadGlassix = function () {
            const scriptParentNode = document.getElementsByTagName('script')[0];
            const scriptToLoad = document.createElement('script');
            scriptToLoad.async = true;
            scriptToLoad.type = 'text/javascript';
            scriptToLoad.crossOrigin = 'anonymous';
            scriptToLoad.id = 'glassix-widget-script';
            scriptToLoadClone = scriptToLoad.cloneNode();
            scriptToLoad.onload = initializeWidget;
            scriptToLoad.src = 'https://cdn.glassix.com/clients/widget.1.2.min.js';

            if (!document.getElementById(scriptToLoad.id) && document.body) {
                scriptParentNode.parentNode!.insertBefore(scriptToLoad, scriptParentNode);
                scriptToLoad.onerror = loadFallback;
            }
        }

        if (document.readyState === 'complete') {
            loadGlassix();
        } else if (globalWindow.attachEvent) {
            globalWindow.attachEvent('onload', loadGlassix);
        } else {
            globalWindow.addEventListener('load', loadGlassix, false);
        }
    }

    // Intercom Logic
    implementIntercomScript(app_id) {
        // Add the Intercom script to the HEAD
        const globalWindow: any = window;
        const that = this;

        globalWindow['chatProvider'] = 'Intercom';

        globalWindow.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id,
            alignment: this.appService.direction == 'rtl' ? 'right' : 'left',
        };
        const intercomFunction: any = globalWindow.Intercom;

        if (typeof intercomFunction === 'function') {
            intercomFunction('reattach_activator');
            intercomFunction('update', globalWindow.customIntercomSettings);
        } else {
            const doc = document;
            const intercomWrapper = function () { intercomWrapper.q.push(arguments); };

            intercomWrapper.q = [];

            intercomWrapper.c = function (args: any) {
                intercomWrapper.q.push(args);
            };

            globalWindow.Intercom = intercomWrapper;

            const loadIntercom = function () {
                const scriptElement = doc.createElement('script');
                scriptElement.id = 'custom-intercom-widget';
                scriptElement.type = 'text/javascript';
                scriptElement.async = true;
                scriptElement.src = `https://widget.intercom.io/widget/${app_id}`;

                const existingScript = doc.getElementsByTagName('script')[0];
                existingScript.parentNode!.insertBefore(scriptElement, existingScript);

                that.implementIntercomStyle();
                // globalWindow.Intercom('onHide', function() { alert('Hide') });
                globalWindow.Intercom('onShow', function () { if (!that.appService.isDesktop()) that.applyStylesToIntercomIframe() });
            };

            if (document.readyState === 'complete') {
                loadIntercom();
            } else if (globalWindow.attachEvent) {
                globalWindow.attachEvent('onload', loadIntercom);
            } else {
                globalWindow.addEventListener('load', loadIntercom, false);
            }
        }
    }

    applyStylesToIntercomIframe() {

        const styleElement = document.createElement('style');

        // Setting the CSS rules
        styleElement.textContent = `
            /* Your CSS rules go here */
            [role="tablist"] {
                border-bottom-left-radius: 20px !important;
                border-bottom-right-radius: 20px !important;
            }
            #spaces-messages:first-child {
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
            }
            .intercom-ruskli {
                border-radius: 20px;
            }`;

        setTimeout(() => {
            // Get the iframe element
            const iframe = window.frames['intercom-messenger-frame'];
            iframe.document.head.appendChild(styleElement);

        }, 1000);
    }

    removeIntercomScript() {
        const globalWindow: any = window;

        //Intercom('shutdown'); I'm not using it to save the message history.

        const intercomContainer = document.getElementById('intercom-container');
        if (intercomContainer?.parentNode) intercomContainer.parentNode.removeChild(intercomContainer);

        const widgetIntercomScript = document.getElementById('custom-intercom-widget');
        if (widgetIntercomScript?.parentNode) widgetIntercomScript.parentNode.removeChild(widgetIntercomScript);

        const intercomCssContainer = document.getElementById('intercom-css-container');
        if (intercomCssContainer?.parentNode) intercomCssContainer.parentNode.removeChild(intercomCssContainer);

        const iframeToRemove = document.getElementById('intercom-frame');
        if (iframeToRemove) iframeToRemove.parentNode.removeChild(iframeToRemove);

        const intercomLauncher = $('.intercom-launcher');
        if (intercomLauncher.length > 0) intercomLauncher.parent().remove();

        if (globalWindow.Intercom) delete globalWindow.Intercom;
        if (globalWindow.intercomSettings) delete globalWindow.intercomSettings;
        if (globalWindow.customIntercomSettings) delete globalWindow.customIntercomSettings;
        if (globalWindow.__intercomAssignLocation) delete globalWindow.__intercomAssignLocation;
        if (globalWindow.__intercomReloadLocation) delete globalWindow.__intercomReloadLocation;

        this.removeIntercomStyle();
    }

    removeChatScript() {
        const globalWindow: any = window;
        switch (globalWindow?.chatProvider) {
            case 'Intercom':
                this.removeIntercomScript();
                break;
            case 'Glassix':
                this.removeGlassixScript();
                break;
        }
        if (globalWindow.chatProvider) delete globalWindow.chatProvider;
    }

    removeGlassixScript() {
        const globalWindow: any = window;
        const widgetGlassixScript = document.getElementById('glassix-widget-script');
        const glassixContainer = document.getElementById('glassix-container');

        if (widgetGlassixScript?.parentNode && glassixContainer?.parentNode) {
            widgetGlassixScript.parentNode.removeChild(widgetGlassixScript);
            glassixContainer.parentNode.removeChild(glassixContainer);
            if (globalWindow.widgetClient) delete globalWindow.widgetClient;
            if (globalWindow.intercomSettings) delete globalWindow.GlassixWidgetClient;
        } else {
            globalWindow.widgetClient.destroy();
        }
    }

    implementIntercomStyle() {
        // Only for Mobile
        if (!this.ISDESKTOP) {
            const styleId = 'intercom-style';
            const script = document.createElement('style');
            script.id = styleId;
            const bottom = this.appService.isApp ? 7 : 5;
            const height = this.appService.isApp ? 75 : 85;
            script.innerHTML = `
            span[data-testid="active-notifications"] {position: absolute !important; bottom: ${2 - bottom}rem !important;}
            .intercom-lightweight-app-launcher {bottom: ${bottom}rem !important;}
            `;

            document.head.appendChild(script);
        }
    }

    public removeIntercomStyle() {
        const styleId = 'intercom-style';
        const styleScript = document.getElementById(styleId);

        if (!styleScript) return;
        else {
            const parentElement = styleScript.parentNode;
            // Remove the script element from the head
            parentElement.removeChild(styleScript);
        }
    }

    public adjustExternalChatByLocaleIfNeeded() {
        const globalWindow: any = window;
        if (!globalWindow?.chatProvider) return;
        this.removeChatScript();
        setTimeout(() => {
            const externalChat = this.$storage?.rosConfig?.externalChat;
            if (externalChat?.provider) {
                this.setExternalChat(externalChat);
            }
        }, 100);
    }

    // Region validator
    public regionValidator(regions: string[]) {
        const validRegion = regions.includes(this.$storage.region);
        return validRegion;
    }

    public disableReCaptchaClick() {
        const styleId = 'recaptcha-click-style';
        const styleScript = document.getElementById(styleId);

        // Add style
        if (!styleScript) {
            const script = document.createElement('style');
            script.id = styleId;

            script.innerHTML += `
            .grecaptcha-badge {pointer-events: none;}
            `;

            document.head.appendChild(script);
        // Remove
        } else {
            const parentElement = styleScript.parentNode;
            // Remove the script element from the head
            parentElement.removeChild(styleScript);
        }
    }

    public getDateAndTimeFormat (locale, timestamp) {
        return moment(timestamp).locale(locale).format((locale == 'he-IL' ? 'D [ב]MMMM, YYYY'  : 'MMMM D, YYYY'));
    };

    public getTitleImage() {
        const titleImage = this.$storage.isChain ? getImageForChain(this.$storage) : get(this.$storage, 'rosConfig.config.brandV2.header_sitename_logo_url.url', null);
        return titleImage;

        function getImageForChain($storage) {
            const relevantSite = get($storage.organization, 'branches[0]');
            if (relevantSite) {
                const image = get(relevantSite, 'brandV2.header_sitename_logo_url.url', null);
                return image;
            } return null;
        }
    }

    public timeFormatConverter(value) {
        if (!value) return;
        let lastUsedLang = this.translationService.getLastUsedLang();
        if (!lastUsedLang) lastUsedLang = this.appService?.appConfig?.locale;
        if (!lastUsedLang || this.regionValidator(['IL'])) return value;
        return moment(value, 'HH:mm').format(this.locals[lastUsedLang].timeFormat);
    }

    getSupplyTime() {
        const $storage = this.$storage;
        const orderDelay: any = get($storage, 'order.orderDelay');
        const realDate = this.appService.getRealDateMoment();
        const args = {
            delayMode: null,
            initialDate: $storage.clientInitialDate,
            orderStartDate: $storage.started,
            serverDateDiff: realDate.toDate().valueOf() - Date.now().valueOf(),
        }

        const prepTime = this.getPreparationTime();
        let deliveryEstimate, exEstimate = $storage.order?.branch?.exDeliveryProvider?.estimate;
        if (exEstimate) {
            const _prepTime = $storage.order.branch.exDeliveryProvider.preparationTime;
            deliveryEstimate = {
                toBePreparedOn: moment(exEstimate.pickupTime).subtract(_prepTime, 'minutes').toDate(),
                toBePickedAt: new Date(exEstimate.pickupTime),
                toBeSuppliedOn: new Date(exEstimate.deliveryTime),
                ETA: new Date(exEstimate.deliveryTime)
            }
        }

        if ($storage.futureOrder) {
            if ($storage.futureOrder.slot.id == "ASAP") this.$storage.allDayReffTime = true;
            return extend(args, {
                delayMode: 'futureOrder',
                ETA: $storage.futureOrder.slot.date,
                toBeSuppliedMax: $storage.futureOrder.slot.dateTo,
                asapOnSupplyDay: $storage.futureOrder.slot.id == "ASAP",
                toBePreparedOn: moment($storage.futureOrder.slot.date).add(prepTime * -1, 'minutes').toDate(),
                toBeSuppliedOn: $storage.futureOrder.slot.date
            }, deliveryEstimate);
        } else if (orderDelay?.date && moment(orderDelay?.date).isValid() && moment(orderDelay?.date).isAfter(realDate)) {
            return extend(args, {
                delayMode: 'orderDelay',
                ETA: orderDelay.date,
                toBePreparedOn: moment(orderDelay.date).add(prepTime * -1, 'minutes').toDate(),
                toBeSuppliedOn: orderDelay.date
            }, deliveryEstimate);
        } else {
            const d = realDate;
            if ($storage.preOrderM) {
                var mm = (d.hours() * 60) + d.minutes();
                var diff = $storage.preOrderM - mm;
                if (diff > 0) {
                    d.add(diff, 'minutes');
                    if ($storage.config?.settings?.schedulePreorderFire) {
                        let toBePreparedOn = d.toDate();
                        let eta = d.add(prepTime, 'minutes').toDate();
                        return extend(args, {
                            delayMode: 'orderDelay',
                            ETA: eta,
                            toBePreparedOn: toBePreparedOn,
                            toBeSuppliedOn: eta
                        }, deliveryEstimate);
                    }
                }
            }
            return extend(args, { ETA: deliveryEstimate?.ETA || d.add(prepTime, 'minutes').toDate() });
        }
    }

    getPreparationTime() {
        const $storage = this.$storage;
        switch ($storage?.orderMode) {
            case "delivery":
                return get($storage, "order.region.deliveryTime", 60);
            case "takeaway":
                return get($storage?.order?.branch, "takeaway.preparationTime", 30) + get($storage?.order?.branch, "timesOffsetSetup.prepTimeOffset", 0);
            default:
                return get($storage?.order?.branch[$storage?.orderMode], "preparationTime", 30);
        }
    }
}
