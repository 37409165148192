import { Component, NgZone, OnInit } from '@angular/core';
import { ConfigurationsService } from '../../../../app/_core/configurations.service';
import { AppService } from '../../../../app/app.service';
import { TabitpayService } from '../../../../app/order/tabit-pay/tabit-pay.service';
import { get } from 'lodash-es';
import { OrderModule } from 'ngx-order-pipe';
import { FooterLinksButtonComponent } from './footer-links-button/footer-links-button.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
@Component({
    selector: 'footer-links',
    templateUrl: './footer-links.component.html',
    styleUrls: ['./footer-links.component.scss'],
    standalone: true,
    imports: [NgIf, FooterLinksButtonComponent, NgFor, AsyncPipe, OrderModule]
})
export class FooterLinksComponent implements OnInit {

    public linksConfig: any;
    public payRouteName: string
    public showPayLink = true;
    public links = [];
    public linkButtons = ["book", "pay", "order"];
    public isIndexExist = true;

    constructor(
        public appService: AppService,
        public configurationsService: ConfigurationsService,
        private ngZone: NgZone,
        public orderService: TabitpayService,
    ) { }

    ngOnInit(): void {

        this.appService.domain.subscribe(domain => {
            if (domain) {
                this.linksConfig = get(domain, 'defaults.serviceConfiguration.bottomLinks');
                let payConfig = this.linksConfig.pay;

                Object.entries(this.linksConfig).forEach(entry => {
                    const [key, value] = entry;
                    let linkConfig = value;
                    if (!linkConfig['index'] && linkConfig['index'] !== 0) this.isIndexExist = false;
                    linkConfig['name'] = key;
                    if (key == 'pay') linkConfig['visible'] = (!payConfig || (payConfig?.visible && (payConfig?.tabitPayEnabled || payConfig?.shortCodeEnabled)))
                    this.links.push(linkConfig);
                });

                // default sort
                if (!this.isIndexExist) {
                    this.links = this.sortLinks(this.links, this.linkButtons, 'name');
                }
            }
        })
    }

    public redirect(route: any) {
        if (this.appService.confirmBeforeRedirect) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                return this.appService.mainMessage({
                    dialogType: 'info',
                    dialogTitle: 'in_progress_message',
                    dialogText: 'confirm_exit',
                    primaryButtonText: 'cancel_order',
                    secondaryButtonText: 'continue'
                }).then(response => {
                    this.appService.redirect(route, { replaceUrl: true });
                }).catch(err => { });
            });
        } else {
            if (route[0]?.includes('pay')) {
                this.resolvePaymentRoute();
            } else {
                this.appService.redirect(route);
            }

        }
    }

    resolvePaymentRoute() {
        if (this.linksConfig?.pay?.tabitPayEnabled && this.linksConfig?.pay?.shortCodeEnabled) {
            return this.appService.mainMessage({
                dialogType: 'info',
                dialogTitle: '',
                dialogText: '',
                primaryButtonText: 'short-code_screen',
                secondaryButtonText: 'payment_screen'
            }).then(response => {
                if (get(response, 'primaryButtonClick')) {
                    this.payRouteName = 'short-code'
                    this.appService.redirect(['short-code'], { replaceUrl: true });
                }
            }).catch(err => {
                if (get(err, 'secondaryButtonClick')) {
                    this.payRouteName = 'pay/scanner'
                    this.appService.redirect(['pay/scanner'], { replaceUrl: true });
                }
            });
        }
        if (!this.linksConfig?.pay || (this.linksConfig?.pay?.tabitPayEnabled && !this.linksConfig?.pay?.shortCodeEnabled)) {
            this.payRouteName = 'pay/scanner'
            this.appService.redirect(['pay/scanner'], { replaceUrl: true });
        } else {
            if (!this.linksConfig?.pay?.tabitPayEnabled && this.linksConfig?.pay?.shortCodeEnabled) {
                this.payRouteName = 'short-code'
                this.appService.redirect(['short-code'], { replaceUrl: true });
            }
        }
    }

    public checkActiveRoute(verifyRoute: string, currentUrl: string) {
        if (RegExp(`(^\/|-).*${verifyRoute}`).test(currentUrl)) {
            return true;
        } else {
            return false;
        }
    }

    sortLinks(array, order, key) {
        array.sort((a, b) => {
            var A = a[key], B = b[key];
            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    }

}
