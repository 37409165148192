import { Subject, Observable, Subscription } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { retry, timeout, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { map as _map, orderBy, uniqBy, get, merge, extend, remove, cloneDeep, each, find, assignIn } from 'lodash-es';
import moment from 'moment';

import { environment } from '../../environments/environment';
import { AppService, ACTION_TYPES } from '../app.service';
import { AuthService } from './auth.service';
import { EntityUtilsService } from './entity.utils.service';
import { OrganizationsService } from './organizations.service';
import { TagsService } from './tags.service';
import { StorageService } from './storage.service';
import { StyleService } from './style.service';
import { LocationService } from './location.service';
import { AddressesService } from './addresses.service';
import { UserReviewsService } from './user.reviews.service';
import { ConfigurationsService } from './configurations.service';
import { LoyaltyService, JoinChannelOrigin } from './loyalty.service';
import { NotificationsService } from './notifications.service';

@Injectable({
	providedIn: 'root',
})
export class EntityService {
    @BlockUI() blockUI: NgBlockUI;

	public appConfig: any = environment.appConfig;
    // wallet is relevant only for the israeli domain
    public isWalletNeeded = this.appConfig.countryCode === 'IL';

	private historyChangedSource = new Subject<void>();
	public historyChanged$ = this.historyChangedSource.asObservable();

	constructor(
		private http: HttpClient,
        private ngZone: NgZone,
		private appService: AppService,
		private authService: AuthService,
		public utilsService: EntityUtilsService,
		public organizationsService: OrganizationsService,
        private userReviewsService: UserReviewsService,
        public locationService: LocationService,
        private configurationsService: ConfigurationsService,
		private addressesService: AddressesService,
        private tagsService: TagsService,
        private loyaltyService: LoyaltyService,
        private storageService: StorageService,
        private styleService: StyleService,
        private notificationsService: NotificationsService,
	) { }

    public loadData(): Promise<any> {
        // Core data: Waiting on it:
        const coreDataPromise = this.getDomain().then((domainResponse) => {

            this.appService.updateDomainData(domainResponse.domain);

            // inject custom WL domain styling to the DOM
            if (domainResponse?.domain?.theme && this.appService.skin) {
                this.styleService.setDomainCustomTheme(domainResponse?.domain.theme)
            }

            // Anonymous Token (if needed):
            if (this.isNeedToGetAnonymousToken()) this.getAnonymousToken();

            return this.getServerTime()
            .then((serverTime) => {
                this.appService.calcServerDateDiff(Date.now(), serverTime);
                this.appService.stopLoadingCoreData();

                setTimeout(() => {
                    this.appService.removeCordovaWrapperSplash();
                    this.appService.stopBlock({ class: 'white-block' });
                }, 1000); // Added a short delay so that the Splash won't go away "too fast";
                // console.log('=== ENTITY-SERVICE/loadData - domainResponse, serverTime: ===', domainResponse, serverTime);
            });
        });

        return coreDataPromise;
    }

    public initNotAuthenticated(): Promise<any> {
        this.getUserAddresses();
        this.appService.stopLoadingMetaData();
        return Promise.resolve();
    }

    private isNeedToGetAnonymousToken(): boolean {
        if (!this.storageService.getItem('token')) return true;
        this.authService.setHttpHeadersAccessToken(this.storageService.getItem('token'));
        return false;
    }

    private getAnonymousToken(): Subscription {
        return this.authService.getAnonymousToken();
    }

    private userInfo(withWallet?: boolean, withAttributes?: boolean): PromiseLike<any>[] {
        let promises = [

            /**
             * ====================================================
             *  1: Allergies
             * ====================================================
             * This doesn't belong to the user. This is the food allergies SET
             * The reason this is coming with user info is that the needs user authentication from ROS
             * TODO: Move this to "loadData"
             */
            withAttributes ? this.getAllergies() : null,

            /**
             * ====================================================
             *  2: ROS Customer Details
             * ====================================================
             */
            this.get('/online-shopper/customers/current'),

            /**
             * ====================================================
             *  3: Bridge Customer Extra Data
             * ====================================================
             * Here comes the PERSONAL preferences: Favorites + Allergies
             * Also Wallets extra data: CVV, Ids (Removed the wallets)
             */
            this.get(null, null, null, `${this.appConfig.tabitBridge}/customers/current/meta`).catch(err => {
                if (err.status === 404) console.debug(`No preferences nor favorites saved for current user`);
                else console.error('Cannot get user favorites & preferences:', err);
                return null;
            }),

            /**
             * ====================================================
             *  4: Loyalty Customer Details
             * ====================================================
             */
            this.getLoyaltyCostumer(),

            /**
             * ====================================================
             *  5: Customer Addresses
             * ====================================================
             */
            this.getUserAddresses(),

            /**
             * ====================================================
             *  6: User History
             * ====================================================
             */
            //this.getUserHistory(),
			// 2020-03-13 - This is done in the ngOnInit of the Dashboard; We get the user history every time on-init of the Dashboard (because we need to update the user-points and history section, every time the user enters the Dashboard)

            /**
             * ====================================================
             *  8: ROS Wallet
             * ====================================================
             */
            withWallet ? this.get('/online-shopper/customers/current/wallet') : null

        ];

        return promises;

    }

    private getLoyaltyCostumer(): Promise<any> {
        return this.get(null, null, null, `${this.appConfig.tabitLoyaltyAPI}/customer`, this.loyaltyService.getLoyaltyHeaders());
    }

    public showLoyaltyTermsDialog(domain) : Promise<any> {
        if (!domain) return Promise.resolve();
        return this.appService.showLoyaltyTermsAndMailDialog(domain)
            .then(form => {
                this.updateCustomer(form, true);
            }).catch(err => {
                console.error('Cannot show loyalty terms dialog:', err);
            });
	}

    public getUserInfo(withWallet?: boolean, withAttributes?: boolean): Promise<any> {
        return Promise.all(this.userInfo(withWallet, withAttributes)).then(([

            allergiesSet,
            user,
            userMeta,
            loyaltyResponse,
			addresses,
            wallet

        ]: any[]) => {

            this.appService.user = this.utilsService.prepareUser(user);
            this.appService.updatePrivateStore('user', this.appService.user);

            if (userMeta) {
                this.appService.account.favorites = userMeta.favorite_organizations || [];
                this.appService.account.preferences = userMeta.preferences || {foods:[], allergies:[]};
            }

            if (loyaltyResponse && this.appService.user) {
                this.appService.user.loyaltyCustomer = loyaltyResponse.ResponseData;

                console.debug('Loyalty Customer Details: ', this.appService.user.loyaltyCustomer);

                // In case we're in the app, we want to register the fcmPermissionKey under the device with the loyaltyCustomer
                if (this.isNeedToRegisterDevice()) this.notificationsService.registerDeviceForNotifications(localStorage.getItem('fcmPermissionKey'), this.appService.user.loyaltyCustomer);

                // [ ! ] IMPORTANT NOTE
                // The LoyaltyCustomer.Mobile (Loyalty Phone) MUST be identical to the user.phone (ROS Phone)
                // We assume they will ALWAYS be identical...

                if (!this.appService.user?.loyaltyCustomer?.CustomerId) throw new Error('Loyalty Customer data is missing or bad');

                if (['aromatlv', 'falafelbaribua'].includes(this.appService.skin) && loyaltyResponse.ResponseData) {
                    if (loyaltyResponse.ResponseData?.IsFilledJoinForm == 1 || loyaltyResponse.ResponseData?.IsFilledJoinForm == "1" || loyaltyResponse.ResponseData?.IsFilledJoinForm == true) {
                        // We do NOT show anything!
                    } else {
                        this.appService.loadingCoreData.subscribe(loadingCoreData => {
                            if (!loadingCoreData) {
                                this.appService.domain.subscribe(domain => {
                                    if (domain) this.showLoyaltyTermsDialog(domain);
                                });
                            };
                        });
                    }
                }

                // Getting the customer user reviews
                // Not required anymore, since we fetch it everytime we init the Dashboard, My History or Site Details
                //this.getUserReviews(this.appService.user.loyaltyCustomer.CustomerId);
            }

            console.debug('==== wallet', wallet);

            if (wallet) {
                const preparedWallet = this.prepareWallet(wallet);
                this.appService.user.wallet = preparedWallet;
                this.appService.updatePrivateStore('user', this.appService.user);
			}

			// Setting the Loyalty Cutomer details in the App Service
            if (!this.appService.user) {
                this.appService.user = {};
            }

            // This must be after 'this.appService.account.preferences'
            this.tagsService.prepareFoodPersonalPrefs();

            this.appService.stopLoadingMetaData();

            this.appService.updatePrivateStore('user', this.appService.user);

            if (this.appConfig.countryCode === 'IL') this.getCustomerReservations();

            return this.appService.user;

        }).catch(err => {

            console.error('Problem with some user or user dependent info:', err);

        });
	}

    public prepareWallet(wallet) {
        // Need to filter expiration dates
        // Remove wallets that have pan
        const currentDate = moment();

        let preparedWallet = wallet;

        if (wallet?.payments?.length) {
            preparedWallet.payments = wallet.payments
            .filter(walletPayment => {
                if (walletPayment.paymentType == 'creditCard' && !walletPayment.hasProviderToken) return false;
                else return true;
            }) // Filter out payments with the 'hasProviderToken' property
            .map(walletPayment => {
                if (walletPayment?.expYear && walletPayment?.expMonth) {
                    // Create a moment object for the card's expiration date
                    const expirationDate = moment({
                        year: walletPayment.expYear,
                        month: walletPayment.expMonth - 1, // Moment months are zero-indexed
                        day: 1 // Set to the first day of the month for comparison
                    });

                    walletPayment.expired = currentDate.isAfter(expirationDate);
                }
                return walletPayment;
            })
        }

        return preparedWallet;
    }

	public getUserWallet(currentWallet?): Promise<any> {
        // We want that everytime we'd have the current details BEFORE refreshing
        // because old cvv's and id's would be deprecated
        const extraDataForWallet = currentWallet || this.appService.user.wallet;
		return this.get('/online-shopper/customers/current/wallet')
        .then(freshWallet => {
            const filteredWallet = this.prepareWallet(freshWallet);
			this.appService.user.wallet = filteredWallet;
            this.appService.updatePrivateStore('user', this.appService.user);
            this.appService.populateUserWallet.next(filteredWallet); // needed only due to the many instances of "user.wallet" and circular deps issues. will be obsolete upon a fix.
			return this.appService.user;
		});
	}

    public getUserAddresses(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.addressesService.loadAddresses().subscribe(resolve, reject);
        });
    }

	public toggleFavorites(site): Promise<any> {

        this.appService.account.favorites = this.organizationsService.toggleFavorites(site._id);

        return this.post(null, {
            favorite_organizations: this.appService.account.favorites,
        }, null, `${this.appConfig.tabitBridge}/customers/current/meta`).catch(err => {
            console.error('Error saving favorites:', err);
        });

    }

    public saveUserPreferences(preferences): Promise<any> {
        return this.post(null, { preferences }, null, `${this.appConfig.tabitBridge}/customers/current/meta`).then(userMeta => {
            console.debug('Preferences saved. User meta now:', userMeta);
            return userMeta && userMeta.preferences;
        });
    }

	public getUserHistory(): Promise<any[] | { _id: any; organization: any; name: any; date: any; service: string; serviceType: any; amount: any; billId: any; orderId: any; _o: any; }[]> {
        let that = this;
        let param = {};
        let historyLoadType = this.storageService.getItem('historyLoadType');
        if ((!historyLoadType || historyLoadType != 'full')) {
            if (this.appService.user.loyaltyCustomer.JoinDate) {
                Object.assign(param , { from: this.appService.user.loyaltyCustomer.JoinDate })
            } else {
                Object.assign(param , { from: moment().toISOString() })
            }
        };
        let url = `${this.appConfig.tabitBridge}/customers/current/history`;
		return this.get(null, param, null, url, null, 1, 60000).then((response) => {
			if (!response) response = [];
            let serviceTypes = [];

            // Filtering out duplications by tlog
            response.history = uniqBy(response.history, 'tlog');
            response.history = response.history.filter(history => history.serviceType !== 'mediaexchange');

			let members = {
				delivery: { icon: 'take away active', img: this.appService.images.his_delivery },
				takeaway: { icon: 'basket', img: this.appService.images.his_ta },
				seated: { icon: 'pay in rest', img: this.appService.images.his_pay }
			}

			let ret = _map(response.history, order => {
				let siteId = order.organization?._id;
				let member = members[order.serviceType] || members.takeaway;
				if (serviceTypes.indexOf(order.serviceType) == -1) {
					serviceTypes.push(order.serviceType);
				}

				return {
					"_id": order.tlog,
					"organization": siteId,
					"name": order.organization?.name,
					"date": order.opened,
					"service": "order",
					"serviceType": order.serviceType,
					"amount": order.totalAmount,
                    "billId": order.tlog,
                    "orderId": order.order,
					"_o": member
				}
			});
            ret = orderBy(ret, ['date'], ['desc']);
            // After we would deal with the pagination in the Bridge I've sliced the response in this components (Performance related) -
            // dashboard, app-site-details, my-history
			that.appService.account.history = ret;
			that.historyChangedSource.next();
			this.appService.account.loadingHistory = false;

			// Calculating User Points based on history
			this.calculateUserPoints(ret);

			return ret;

		}).catch(err => {
            console.error('Error getting customer history:', err);
            this.appService.account.loadingHistory = false;
            return [];
        });
	}

    public calculateUserPoints(userHistory:any[]): number {
        if (userHistory.length > 0) {
            this.appService.userPoints = userHistory.length * 10;
        }
        return this.appService.userPoints;
	}

	public getSiteConfig(siteId): Promise<any> {
		return this.get('/online-shopper/configuration', null, { 'ros-organization': siteId }).then(response => {
            this.organizationsService.setOrganizationConfig(response);
			return response;
		});
	}

    public getOrderById(siteId, orderId:string): Promise<any> {
        if (!orderId) return Promise.resolve({});

        let params:any = {};
        if (orderId && orderId.length === 24) {
            params.orderId = orderId;
        } else if (orderId && orderId.length === 6) {
            params.shortId = orderId.toUpperCase();
        } else {
            return Promise.reject({
                type: 'ERROR',
                message: this.appService.translate("SERVER_MESSAGES.INVALID_ORDER_ID") //We must leave is like so, beacsue a dialog dont have a template
            });
        }

        return this.get(`/online-shopper/bills`, params, { 'ros-organization': siteId })
        .then(response => {
            return response[0];
        });
    }

	public getOrderBill(dataRow): Promise<any> {
        return this.get(`/online-shopper/receipts`, { tlogId: dataRow.billId }, { 'ros-organization': dataRow.organization}).then(response => {
			return get(response, '[0].printData');
		});
	}

	public addPaymentMethodToWallet(paymentMethod): Promise<any> {

        const self = this;

        // saving a new card
		return this.post('/online-shopper/customers/current/wallet/payments', paymentMethod)
        .then(newPaymentMethod => {
            return walletUpdateCallback(newPaymentMethod);
        }).catch(err => {
            this.appService.stopBlock();
            console.error('Error adding new payment method to wallet', err);

            const errorCode = err?.error?.code || err?.status;
            const dialogText = errorCode == 409142 ? 'error_cg_wallet_credentials' : 'error_client_credentials';
            this.appService.mainMessage({
                dialogType: 'error',
                dialogTitle: '',
                dialogText: this.appService.translate(dialogText),
                primaryButtonText: 'CLOSE',
                hideSecondaryButton: true
            });

            throw(err)
        });

        function walletUpdateCallback(newPaymentMethod = {}): Promise<any> {

            // save the new data to the local & bridge-db wallet
            return self.addMetasToPaymentMethod(merge(paymentMethod, newPaymentMethod), true)
            .then(userMetaWallet => {

                if (userMetaWallet) console.debug('Metadata added to payment!', userMetaWallet);

                // get the updated user wallet only after all updates
                return self.getUserWallet(userMetaWallet)
                .catch(err => {
                    console.error('Error getting user wallet', err);
                })
            })
            .catch(err => {
                console.error('Error adding metadata to payment:', err);
            });
        }
	}

    public addMetasToPaymentMethod(paymentMethod, updateLocalWallet?: boolean): Promise<any> {
        if (!this.appService.user.wallet) return Promise.reject(new Error('Error saving wallet metadata, no payments exists'));

        const paymentMethodId = paymentMethod?.id || paymentMethod.walletPayment;

        // find related wallet payment-method (by id)
        let walletPaymentMethodIndex = this.appService.user.wallet.payments.findIndex(paymentMethod => paymentMethod._id === paymentMethodId);

        // make sure the local wallet to reflect the update ("current/meta" only saves to db)
        if (updateLocalWallet && walletPaymentMethodIndex !== -1) {
            const newInfo: any = {};
            extend(this.appService.user.wallet.payments[walletPaymentMethodIndex], newInfo);

        // new payment option, append to wallet (wallet will be overwritten by getUserWallet() after saving/updating all the CCs)
        } else if (walletPaymentMethodIndex === -1) {
            this.appService.user.wallet.payments.push(paymentMethod)
        }

        return Promise.resolve();
    }

	public removePaymentFromWallet(pmId): Promise<any> {
		return this.remove('/online-shopper/customers/current/wallet/payments/' + pmId, {}).then(response => {
            return true;
		}).catch(e => {
			throw (e);
		});
	}

    public removeAllPaymentsFromWallet(): Promise<boolean> {
        const userWallet = get(this.appService.user, 'wallet.payments');
        if (!userWallet?.length) Promise.resolve();
		return Promise.all(
            each(userWallet, async wallet => {
                const walletId = wallet._id || wallet.id;
                await this.removePaymentFromWallet(walletId);
            })
        ).then(response => {
            return true;
		}).catch(e => {
			throw (e);
		});
	}

	public updateAccount(data): Promise<any> {
		const customerData: any = {
			email: data.email,
			name: data.name,
			phone: data.phone,
			oldPassword: data.oldPassword,
			newPassword: data.newPassword
		};

		let defaultWalletPayment = data.defaultWalletPayment;
		let that = this;

		return this.put('/online-shopper/customers/current', customerData)
        .then(response => {
            // 2020-02-23: [ ! ] Important Note
            // The prepareUser function used to override and remove the loyaltyCustomer object.
            // So now, the utilsService.prepareUser checks if the input has loyaltyCustomer, and if not - we maintain the original appService.user.loyaltyCustomer.
            this.appService.user = this.utilsService.prepareUser(response);
            this.appService.updatePrivateStore('user', this.appService.user);

			if (defaultWalletPayment) {
				return this.post('/online-shopper/customers/current/wallet/payments/' + defaultWalletPayment + '/default').then(response => {
					return this.getUserWallet();
				})
			}
			return this.getUserWallet();
		}).catch(e => {
			throw (e);
		});
	}

    public subscribeToCoreData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getTags();
            // No need to init location and push notifications when I'm landing in external module
            console.debug('Subscribing to GPS location before loading organizations...');

            if (window['cordova']) {
                // For Tabit App (Cordova) we WAIT for the GPS
                this.locationService.firstActualLocation.subscribe(attempted => {
                    if (!attempted) return;
                    this.getOrganizationsAccordingToLocation();
                });
            } else {
                this.locationService.locationInitialized.subscribe(initialized => {
                    // For Webapp (Desktop and Mobile) we do NOT wait for the GPS, only for initializing the "default" location:
                    if (!initialized) return;
                    this.getOrganizationsAccordingToLocation();
                });
            }

            this.locationService.startLocationManagement();

            this.appService.subscribedToLocationAndGotOrganizations.next(true);

            // Resolve only when get organizations first time:
            this.organizationsService.initialOrganizationsLoaded.subscribe(loaded => { if (loaded) resolve({}); }, reject);
        });
    }

    private getOrganizationsAccordingToLocation(): void {
         // This will: Fetch organizations for first time + re-fetch what's needed the next times location is updated:
        this.locationService.location.subscribe(labeledLocation => {
            if (labeledLocation) console.debug('Main location subscriber got location update:', labeledLocation.location, `('${labeledLocation.label}')`, 'Loading organizations...');
            else console.debug('Main location subscriber got location update with no location. Loading organizations...');
            this.organizationsService.loadAllOrgsTypes();
        }, err => {
            console.error('Error subsribing to location:', err);
            console.debug('Loading organizations with no GPS location...');
            // Even if is there an error, load organization for the first time:
            if (!this.organizationsService.hasNearbyOrganizations()) this.organizationsService.loadAllOrgsTypes();
        });
    }

	public getSite(siteId): Promise<any> {
		return this.get('/online-shopper/organizations').then((sites) => {
			return find(sites, { _id: siteId });
		})
	}

	public getTags(): Promise<any> {
        // Subscribing to tags
        return new Promise((resolve, reject) => {
            this.tagsService.getTags().subscribe(resolve, reject);
        });
	}

    public getDomain(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.configurationsService.getDomain().subscribe(resolve, reject);
        });
	}

	public getAllergies(): Promise<any> {
        return this.getGlobalAttributes('allergies').then(response => {
            return this.utilsService.prepareAllergies(response);
		});
	}

	public getGlobalAttributes(category: string): Promise<any> {
		return this.get(`/online-shopper/attributes/${category}`);
	}

	public getPaymentIntent(args): Promise<any> {
		let req = {
			"walletPayment": args.pm.id,
			"amount": args.amount * 100
		}
		//return this.getSimulated("quickPayCode", args);
		return this.post(`/online-shopper/paymentIntents`, req, { 'ros-organization': args._id });
	}

	public getPaymentIntents(args): Promise<any> {
		return this.get(`/online-shopper/paymentIntents`, null, { 'ros-organization': args._id });
	}

	public deletePaymentIntent(args): Promise<any> {
		return this.remove(`/online-shopper/paymentIntents/${args.paymentIntentId}`, null, { 'ros-organization': args._id });
	}

	public getGooglePlace(placeId): Promise<any> {
		let params:any = {
			params: {
				placeid: placeId,
				key: this.appConfig.googleKey
			}
		}
		return new Promise((resolve, reject) => {
			this.http.get("https://maps.googleapis.com/maps/api/place/details/json", params)
				.subscribe(
					(results: any) => {
						resolve(results && results.result);
					},
					(err) => {
						reject(err)
					}
				);
		});
	}

	// public getGooglePlacePhotoUrl(photoId) {
	// 	return "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=" + photoId + "&key=" + this.appConfig.googleKey
	// }

    public post(url, payload?, headers?, anotherURI?:string, alternativeHeaders?:any, retries?:number, enableRecaptcha?: boolean): Promise<any> {
        const params = assignIn({}, alternativeHeaders || this.appService.appHttpOptions);
        const uri = anotherURI || (this.appConfig.tabitAPI + url);

        if (headers) {
            each(params.headers.keys(), key => {
                headers[key] = params.headers.get(key);
            });
            params.headers = headers;
		}
		return new Promise((resolve, reject) => {
			(!enableRecaptcha ? this.http.post(uri, payload || {}, params) : this.appService.processRequestWithCaptcha(ACTION_TYPES.PAYMENT_SUBMIT, uri, payload, params))
				.pipe(
                    timeout(45000), // 45 Seconds
                    retry(retries || 0)
				)
				.subscribe(
					(results: any) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

    public put(url, payload?, headers?, anotherURI?: string, alternativeHeaders?: any): Promise<any> {
		let params = assignIn({}, alternativeHeaders || this.appService.appHttpOptions, { params: payload });
        if (headers) {
            each(params.headers.keys(), key => {
                headers[key] = params.headers.get(key);
            });
            params.headers = headers;
		}
		return new Promise((resolve, reject) => {
			this.http.put(anotherURI || (this.appConfig.tabitAPI + url), payload || {}, params)
				.subscribe(
					(results: any) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	public get(url, payload?, headers?, anotherURI?:string, alternativeHeaders?:any, retries?:number, timeOut?:number): Promise<any> {
        let params = assignIn({}, alternativeHeaders || this.appService.appHttpOptions, { params: payload });
        if (headers) {
            each(params.headers.keys(), key => {
                headers[key] = params.headers.get(key);
            });
            params.headers = headers;
		}
		return new Promise((resolve, reject) => {
			this.http.get(anotherURI || (this.appConfig.tabitAPI + url), params)
				.pipe(
					timeout(timeOut || 30000),
                    retry(retries || 3)
				)
				.subscribe(
					(results: any) => {
						resolve(results);
					},
					(err) => {
						reject(err)
					}
				);
		});
	}

    public remove(url, payload?, headers?): Promise<any> {

		let params = assignIn({}, this.appService.appHttpOptions, { params: payload });
		if (headers) {
			each(params.headers.keys(), key => {
				headers[key] = params.headers.get(key);
			});
			params.headers = headers;
		}

		return new Promise((resolve, reject) => {
			this.http.delete(this.appConfig.tabitAPI + url, params)
				.subscribe(
					(results: any) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

    getSiteByPublicUrl(siteName): Promise<any> {
        let params = this.appService.appHttpOptions;

		return new Promise((resolve, reject) => {
            let url = (this.appService.appConfig.tabitOrder_tabitAPI || this.appService.appConfig.tabitAPI) + '/online-shopper/organizations?publicUrlLabel=' + siteName + '&includeUnlisted=1';
			this.http.get(url, params)
				.subscribe(
					(results: any) => {
						resolve(results[0]);
					},
					(err) => {
						reject(err)
					}
				);
		});
    }

	setStorage(key: string, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				localStorage.setItem(key, JSON.stringify(data));
				resolve(data);
			} catch (err) {
				console.error('Error saving to localStorage', err);
				reject(err);
			}
		});
	}

    public getCustomerReservations(src?:string) : Promise<any> {
        if (!this.appService.isAuthUser()) return Promise.resolve([]);
        const url = `${this.appConfig.tabitBridge}/customers/reservations`;
        const origin: JoinChannelOrigin = {
            type: this.appService.skin ? 'WhiteLabel' : 'TabitApp'
        };
        return this.get(null, null, null, url, this.loyaltyService.getLoyaltyHeadersForWL(origin),
        ).then(response => {
            const reservations = this.checkDepositData(response.reservations);

            const orderedReservations = orderBy(reservations, reservation => {
                if (!reservation.reservation_details || !reservation.reservation_details.reserved_from) return reservation.created;
                return reservation.reservation_details.reserved_from;
            }, 'asc');
            // Store customer reservations
            this.appService.reservations = orderedReservations;
            return orderedReservations;
        }).catch(err => {
            console.error('Cannot load customer reservations:', err);
        });
    }

    private checkDepositData(reservations) {
        each(reservations, reservation => {
            // Deposit
            reservation.isMissingDeposit = reservation.notified_deposit && !reservation.deposit?.id && !reservation.cc_deposit;
            // Deposit payment
            reservation.isMissingDepositPayment = reservation.notified_deposit_payment && reservation.deposit_payment_status != 'paid';
        })
        return reservations;
    }

    public saveUserReview(reviewData: any): Promise<any> {
        return this.userReviewsService.saveUserReview(reviewData).toPromise().then((userReview: any) => {
            console.debug('User review saved. User review now:', userReview);
            return userReview.reviewData;
        });
    }

    public checkIfHistoryItemValidForReview(reviews:any, recentOrders:any): any {
        if (!reviews?.length) return recentOrders;
        recentOrders.forEach((recentOrder:any) => {
            // console.log('=== ENTITY/SERVICE: recentOrder ===', recentOrder);
            if (recentOrder.orderId) reviews.find(review => String(review.orderId) === String(recentOrder.orderId)) ? recentOrder.reviewedAlready = true : recentOrder.reviewedAlready = false;
            else reviews.find(review => String(review.tlogId) === String(recentOrder._id)) ? recentOrder.reviewedAlready = true : recentOrder.reviewedAlready = false;
            // If the order is older than 30 days, the user cannot add a review for it - TAB-8623
            if (moment().diff(recentOrder.date, 'days') > 30) recentOrder.reviewedAlready = true;
        });
        return recentOrders;
    }

    public getServerTime(): Promise<any> {
        return this.getServerTimeFromBridge$().toPromise();
    }

    public getServerTimeFromBridge$(): Observable<any> {
        let url = `${this.appService.appConfig.tabitBridge}/status`;

        return this.http.get(url).pipe(
            map((response: any) => {
                return response.unix_time;
            })
        );
    }

    public updateCustomer(userDetails, loyaltyTerms?: any) {
		this.blockUI.start();
        
        let customerDetails = {}
        if(loyaltyTerms){
            customerDetails = {
                isConfirmMail:  userDetails?.IsConfirmMail ? 1 : 0,
                isConfirmSms:  userDetails?.IsConfirmSms ? 1 : 0,
                isFilledJoinForm:  userDetails?.IsFilledJoinForm ? 1 : 0,
                loyaltyTerms: true
            }
        }
        else{
            customerDetails = {
                firstName: userDetails?.loyaltyCustomer?.FirstName || '',
                lastName: userDetails?.loyaltyCustomer?.LastName || '',
                email: userDetails?.loyaltyCustomer?.Email || '',
                birthDate:  userDetails?.loyaltyCustomer?.BirthDate || '',
                anniversary:  userDetails?.loyaltyCustomer?.Anniversary || '',
                isConfirmSms:  userDetails?.loyaltyCustomer?.IsConfirmSms ? 1 : 0,
                isConfirmMail:  userDetails?.loyaltyCustomer?.IsConfirmMail ? 1 : 0,
                isFilledJoinForm:  userDetails?.loyaltyCustomer?.IsFilledJoinForm ? 1 : 0
            }
        }

        // if (loyaltyTerms) {
        //     if (typeof loyaltyTerms.IsFilledJoinForm !== 'undefined') customerDetails['isFilledJoinForm'] = loyaltyTerms.IsFilledJoinForm;
        //     if (typeof loyaltyTerms.IsConfirmSms !== 'undefined') customerDetails['isConfirmSms'] = loyaltyTerms.IsConfirmSms;
        // }

        return this.authService.updateCustomer$(customerDetails).subscribe(
			(response: any) => {
                
				console.debug('Profile service > updateCustomer > updateCustomer$ > Success: ', response);
				this.blockUI.stop();

				if (response?.IsSuccess) {
					this.appService.popSpecialMessage('MESSAGES.saved_successfully', 'success', false, 5 * 1000);
				} else {
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogTitle: 'error_title',
                            dialogText: 'error_general'
                        });
                    });
				}
			},
			(err) => {
				console.debug('Profile service > updateCustomer > updateCustomer$ > Error: ', err);
				if (err.status == '409') {
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogTitle: 'error_title',
                            dialogText: 'error_invalid_sign_in_email_already_exists'
                        });
                    });
				} else {
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogTitle: 'error_title',
                            dialogText: 'error_general'
                        });
                    });
				}
				this.blockUI.stop();
			}
		);
	}

    private isNeedToRegisterDevice() {
        // We want to register the token with the user only for WL apps
        return this.appService.skin && window['cordova'] && localStorage.getItem('fcmPermissionKey');
    }

}
