import { Component, OnInit } from '@angular/core';
import { WebFooterComponent } from '../../web-container/web-footer/web-footer.component';
import { UserReviewsComponent } from '../user-reviews.component';

@Component({
    selector: 'web-user-review',
    templateUrl: './web-user-review.component.html',
    styleUrls: ['./web-user-review.component.scss'],
    standalone: true,
    imports: [UserReviewsComponent, WebFooterComponent]
})
export class WebUserReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
