import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';

import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'multiple-choice',
    templateUrl: './multiple-choice.component.html',
    styleUrls: ['./multiple-choice.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatDialogClose, MatIcon, MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatSelect, NgFor, MatOption, TranslateModule]
})
export class MultipleChoiceComponent implements OnInit {

    selectedOption: any;

    constructor(
        public appService: AppService,
        public dialogRef: MatDialogRef<MultipleChoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.selectedOption = this.data?.options[0];
    }

    chooseOption(option) {
        this.selectedOption = option;
        this.dialogRef.close(option);
    }

}
