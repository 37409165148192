import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { TagsService, Tag } from '../../_core/tags.service';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatChip } from '@angular/material/chips';
import { NgFor } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-my-preferences',
    templateUrl: './my-preferences.component.html',
    styleUrls: ['./my-preferences.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        NgFor,
        MatChip,
        TranslateModule,
        SafeStylePipe,
    ],
})
export class MyPreferencesComponent implements OnInit, OnDestroy {

    allergies = [];

    prefs: any = {
        'foods': [],
        'allergies': []
    };

    loadingSubscripiton: Subscription;

    groupedTags: { name: string, tagsArray: Tag[] }[] = [];

    constructor(
		public appService: AppService,
		public entityService: EntityService,
		public utilsService: EntityUtilsService,
        private tagsService: TagsService,
        public ngZone: NgZone,
    ) {

        this.loadingSubscripiton = combineLatest([
            this.appService.loadingMetaData,
            this.tagsService.tagsData$,
        ]).subscribe(([loading, tags]) => {
            if (loading) return;
            try {
                this.prefs = this.appService.account.preferences || {foods:[], allergies:[]};
            } catch (e) {
                console.error('Preferences is not available:', e);
            }
            this.allergies = this.appService.allergies;
            this.groupedTags = this.tagsService.makeGroupedTags(tags);
        });
    }

	ngOnInit() {
        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/profile/my-profile'])
                    });
                });
        }
	}

	ngOnDestroy() {
        this.loadingSubscripiton.unsubscribe();
	}

	tagToggle(event: object, tag, tagMember): void {
		if (tag == "foods") tagMember.isFavorite = !tagMember.isFavorite;
		let tagId = tagMember._id;
        let tags = this.prefs[tag];
        if (!tags) tags = this.prefs[tag] = [];
        window.setTimeout(() => {
            let index = tags.indexOf(tagId);
            if (index == -1) {
                tags.push(tagId);
            } else {
                tags.splice(index, 1);
            }
            this.entityService.saveUserPreferences(this.prefs).catch(err => {
                console.error('Error saving user preferences:', err);
            });
        });
    }

    categoryToggle(event: object, category, categoryMember): void {
		if (category == "foods") categoryMember.isFavorite = !categoryMember.isFavorite;
		let categoryId = categoryMember._id;
        let categories = this.prefs[category];
        if (!categories) categories = this.prefs[category] = [];
        window.setTimeout(() => {
            let index = categories.indexOf(categoryId);
            if (index == -1) {
                categories.push(categoryId);
            } else {
                categories.splice(index, 1);
            }
            this.entityService.saveUserPreferences(this.prefs).catch(err => {
                console.error('Error saving user preferences:', err);
            });
        });
    }


}
