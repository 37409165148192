import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

import { get } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'under-construction',
    templateUrl: './under-construction.component.html',
    styleUrls: ['./under-construction.component.scss'],
    standalone: true,
    imports: [LottieComponent, NgIf, MatMiniFabButton, MatIcon, TranslateModule]
})
export class UnderConstructionComponent implements OnInit {

    public facebookURL: string;
    public instagramURL: string;
    public linkedinURL: string;
    public tabitBusinessURL: string;

    public animationPath = this.appService.getAnimationPath('under-construction-page-animation');

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
        this.facebookURL = get(this.appService, 'appConfig.facebookURL', 'https://www.facebook.com/tabitisrael');
        this.instagramURL = get(this.appService, 'appConfig.instagramURL', 'https://www.instagram.com/eat.with.tabit');
        this.linkedinURL = get(this.appService, 'appConfig.linkedinURL', 'https://www.linkedin.com/company/tabit---restaurant-technologies/');
        this.tabitBusinessURL = get(this.appService, 'appConfig.tabitBusinessURL', 'https://www.tabit.cloud/');
    }

    public open(link) {
        if (!link) return;
        window.open(link);
    }

}
