<div *ngIf="!usingExtendedResults" class="web-selection-bar card" [class.home-selection-bar]="isHome" [class.vertical]="webSearchType != 'home'" [class.mobile]="!appService.isDesktop()">

    <div class="field-container" (click)="selectService.open()" *ngIf="(selectionFields | async).service">
        <label *ngIf="!selectedService">{{ 'services' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedService">
            <small>{{ 'WEB_CONTAINER.service' | translate }}</small>
            <div class="selected-data-value">{{ (selectedService === 'delivery' ? 'delivery' : 'takeaway') | translate }}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'takeaway_or_delivery_screen_reader' | translate }}" [(ngModel)]="selectedService" class="header-select-list" #selectService>
            <!-- <mat-option><b>{{ 'services' | translate }}</b></mat-option> -->
            <mat-option value="takeaway">{{ 'takeaway' | translate }}</mat-option>
            <mat-option value="delivery">{{ 'delivery' | translate }}</mat-option>
        </mat-select>
    </div>

    <!-- select rating -->
    <div class="field-container small" (click)="selectRating.open()" *ngIf="(selectionFields | async).rating">
        <label *ngIf="!selectedRating">{{ 'WEB_CONTAINER.booking_bar.rating' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedRating">
            <small>{{'WEB_CONTAINER.booking_bar.rating' | translate}}</small>
            <div class="selected-data-value">
                <div class="star-rating" dir="ltr">
                    <mat-icon svgIcon="{{value}}" *ngFor="let value of selectedRating.value"></mat-icon>
                </div>
            </div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'WEB_CONTAINER.booking_bar.rating_screen_reader' | translate }}" [(ngModel)]="selectedRating" class="header-select-list" #selectRating>
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.all_ratings' | translate }}</b></mat-option>
            <mat-option *ngFor="let star of stars" [value]="star">
                <div class="star-rating" dir="ltr">
                    <mat-icon svgIcon="{{value}}" *ngFor="let value of star.value"></mat-icon>
                </div>
            </mat-option>
        </mat-select>
    </div>

    <!-- select price -->
    <div class="field-container small" (click)="selectPrice.open()" *ngIf="(selectionFields | async).price">
        <label *ngIf="!selectedPrice">{{ 'WEB_CONTAINER.booking_bar.price' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedPrice">
            <small>{{ 'WEB_CONTAINER.booking_bar.price' | translate }}</small>
            <div class="selected-data-value">{{selectedPrice.value}}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'WEB_CONTAINER.booking_bar.price_screen_reader' | translate }}" [(ngModel)]="selectedPrice" class="header-select-list" #selectPrice>
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.all_prices' | translate }}</b></mat-option>
            <mat-option *ngFor="let price of prices" [value]="price">
                {{price.value}}
            </mat-option>
        </mat-select>
    </div>

    <!-- select day -->
    <div class="field-container" (click)="selectDate.open()" *ngIf="(selectionFields | async).date">
        <label *ngIf="!selectedDate" for="select-date">{{ 'WEB_CONTAINER.booking_bar.day' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedDate">
            <small>{{selectedDate.date | date:'EEEE': '': appService.localeId}}</small>
            <div class="selected-data-value">{{selectedDate.date | date:'M / d': '': appService.localeId}}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'WEB_CONTAINER.booking_bar.day_screen_reader' | translate }}" [(ngModel)]="selectedDate" class="header-select-list" #selectDate (selectionChange)="onSelectedDayChange($event)">
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.choose_day' | translate }}</b></mat-option>
            <mat-option *ngFor="let date of dates" [value]="date">
                {{date.date | date:'EEEE, M / d': '': appService.localeId}}
            </mat-option>
        </mat-select>
    </div>

    <!-- select hour -->
    <div class="field-container" (click)="selectHour.open()" *ngIf="(selectionFields | async).hour">
        <label *ngIf="!selectedTimeSlot" for="select-hour">{{ 'WEB_CONTAINER.booking_bar.hour' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedTimeSlot">
            <small>{{'WEB_CONTAINER.booking_bar.hour' | translate}}</small>
            <div class="selected-data-value">{{ activeSlotsTextParse(selectedTimeSlot.text) }}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'WEB_CONTAINER.booking_bar.hour_screen_reader' | translate }}" [(ngModel)]="selectedTimeSlot" class="header-select-list" #selectHour>
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.choose_hour' | translate }}</b></mat-option>
            <mat-option *ngFor="let timeSlot of timeSlots" [value]="timeSlot">
                {{timeSlot.text | AMPMConverter}}
            </mat-option>
        </mat-select>
    </div>

    <!-- select diners -->
    <div class="field-container" (click)="selectGuests.open()" *ngIf="(selectionFields | async).guests">
        <label *ngIf="!selectedDiners" for="select-guests">rrrr{{ 'WEB_CONTAINER.booking_bar.guests' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedDiners">
            <small>{{'WEB_CONTAINER.booking_bar.guests' | translate}}</small>
            <div class="selected-data-value">{{ selectedDiners.textSmall }}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? !isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical' : 'web-selection-bar-vertical' }}" aria-label="{{ 'WEB_CONTAINER.booking_bar.guests_screen_reader' | translate }}" [(ngModel)]="selectedDiners" class="header-select-list guests" #selectGuests>
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.choose_guests' | translate }}</b></mat-option>
            <mat-option *ngFor="let diner of diners" [value]="diner">
                {{ diner.textSmall }}
            </mat-option>
        </mat-select>

    </div>

    <!-- select tag -->
    <div class="field-container" [ngClass]="{ 'small': webSearchType !== 'booking' }" (click)="selectTag.open()" *ngIf="(selectionFields | async).tags" [dir]="direction === 'rtl' ? 'ltr' : 'rtl'">
        <label *ngIf="!selectedTag">{{ 'WEB_CONTAINER.booking_bar.tags' | translate }}</label>
        <div class="field-selected-data" *ngIf="selectedTag">
            <small>{{ 'WEB_CONTAINER.booking_bar.tags' | translate }}</small>
            <div class="selected-data-value">{{selectedTag?.lang[appService.localeId.toLocaleLowerCase()]?.label}}</div>
        </div>
        <mat-select panelClass="{{ appService.isDesktop() ? (!isHome ? 'web-selection-bar-horizontal' : 'web-selection-bar-vertical') : 'web-selection-bar-vertical' }} tags-panel-class" aria-label="{{ 'WEB_CONTAINER.booking_bar.tags_screen_reader' | translate }}" [(ngModel)]="selectedTag" class="header-select-list" #selectTag>
            <mat-option><b>{{ 'WEB_CONTAINER.booking_bar.clear_tags' | translate }}</b></mat-option>
                <mat-optgroup *ngFor="let group of groupedTags" [label]="group.name | translate">
                    <mat-option *ngFor="let tag of group.tagsArray" [value]="tag">
                        {{tag?.lang?.[appService.localeId.toLocaleLowerCase()]?.label ?? tag?.lang?.[appService.appConfig.locale.toLocaleLowerCase()]?.label}}
                    </mat-option>
                </mat-optgroup>
        </mat-select>
    </div>

    <div class="field-container" *ngIf="webSearchType == 'home' && appService.isDesktop()">
        <button mat-button class="action-button" *ngIf="appService.isDesktop()" (click)="webSearchSites($event)">
            <span *ngIf="!searching">{{ searchButtonStringKey | translate }}</span>
            <mat-spinner *ngIf="searching" diameter="20" strokeWidth="2" class="light-grey"></mat-spinner>
        </button>
    </div>

</div>

<div class="field-container" [class.vertical]="webSearchType != 'home'" [class.mobile]="!appService.isDesktop()" *ngIf="webSearchType != 'home' || !appService.isDesktop()">
    <button mat-button class="action-button" (click)="webSearchSites($event)">
        <span *ngIf="!searching && !usingExtendedResults">{{ searchButtonStringKey | translate }}</span>
        <span *ngIf="!searching && usingExtendedResults">{{ 'WEB_CONTAINER.back_to_regular_results' | translate }}</span>
        <mat-spinner *ngIf="searching" diameter="30" strokeWidth="3" class="light-grey"></mat-spinner>
    </button>
</div>

<div class="location-widget-wrapper" [class.vertical]="webSearchType != 'home'" [class.mobile]="!appService.isDesktop()">
    <!-- Decided to not use houseRequired, and return premise addresses -->
    <location-widget [class.horizontal]="webSearchType === 'home' && appService.isDesktop()" [houseRequired]="false" class="transparent-location-widget"></location-widget>
    <!-- <location-widget [class.horizontal]="webSearchType === 'home' && appService.isDesktop()" [houseRequired]="webSearchType == 'order' ? true : false"></location-widget> -->
</div>

