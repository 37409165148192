import { debounce, get } from 'lodash-es';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { routeAnimation } from '../_core/static/route-animations';

import { AppService } from '../app.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { WebContainerService } from './web-container.service';
import { WebHeaderSearchComponent } from './web-header/web-header-search/web-header-search.component';
import { MatIcon } from '@angular/material/icon';
import { WebAppSmartBannerComponent } from './web-app-smart-banner/web-app-smart-banner.component';
import { NgIf } from '@angular/common';
import { WebHeaderTabsComponent } from './web-header-tabs/web-header-tabs.component';
import { NgxDetectScrollDirective } from '../_core/directives';

declare const $: any;

@UntilDestroy()
@Component({
    selector: 'app-web-container',
    templateUrl: './web-container.component.html',
    styleUrls: ['./web-container.component.scss'],
    animations: [
        trigger('mobileWebHeaderSearchOpened', [
            state('opened', style({
                opacity: 0
            })),
            state('closed', style({
                opacity: 1
            })),
            transition('opened => closed', animate('250ms 350ms ease-in-out')),
            transition('closed => opened', animate('250ms 0s ease-in-out'))
        ]),
        routeAnimation,
        [
            trigger('WebAppSmartBannerOpened', [
                state('opened', style({
                    top: '0'
                })),
                state('closed', style({
                    top: '-5rem',
                })),
                transition('opened => closed', animate('250ms 0s ease-in-out')),
                transition('closed => opened', animate('250ms 0s ease-in-out'))
            ])
        ]
    ],
    standalone: true,
    imports: [NgxDetectScrollDirective, MatSidenavContainer, MatSidenav, WebHeaderTabsComponent, NgIf, WebAppSmartBannerComponent, MatIcon, WebHeaderSearchComponent, RouterOutlet]
})
export class WebContainerComponent implements OnInit, OnDestroy {

    @ViewChild('mobilewebTabsSidenav', {static: true})    public mobilewebTabsSidenav: MatSidenav;

    private mainSubscription: Subscription;

    webMobileHeaderSearchOpened = false;
    webMobileTabsSidenavOpened = false;

    webBackButton: boolean = false;

    domain: any;

    constructor(
        public appService: AppService,
        public webContainerService: WebContainerService,
        public activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            this.domain = domain;
        });

        // When the App would be available to the US - Remember to remove :host-context() from .mobile-web-tabs-header-container
        this.webContainerService.setSidenav(this.mobilewebTabsSidenav);
        this.mainSubscription = this.activatedRoute.data.subscribe(data => {
            // console.log('Web containber data:', data);
            this.webBackButton = !!data.webBackButton;
        });

        if (this.webContainerService.getMainSearchQuery()) {
            // If we have seach value - we must turn this flag ON so that the relevant header icons will become hidden (e.g. thh sidebar nav trigger, while the search field is open)
            this.webMobileHeaderSearchOpened = true;
        }
    }

    ngOnDestroy() {
        this.mainSubscription.unsubscribe();
    }

    handleScroll(event: any) {
        // We use this component's handle-scroll to load more sites ONLY on Mobile (because on Desktop - the scroll is handled by inner components, e.g. site-list-map)
        if (this.appService.isDesktop()) return;
        // When we scroll the list-map - we make sure the component is "in-view"
        //console.log('=== Web Container - handleScroll', event.isReachingBottom, event.direction);
        if (event.isReachingBottom && event.direction === 'down') this.debounceScrollBottom(event);
    }

    debounceScrollBottom = debounce((event) => {
        this.webContainerService.loadMoreSites(true);
    }, 500);

    navigateBack() {
        if ($('app-site-details')[0]) {
            // When in Site Details - we need to animate the view out using "old school" jQuery, for performance reasons (instead of using Angular animations via Routing)
            $('app-site-details').animate({ left: '100vw' }, 250, () => {
                this.appService.goBack();
            });
        } else {
            this.appService.goBack();
        }
    }

    getAnimationTag(outlet: RouterOutlet) {
        let tag = outlet && outlet.activatedRouteData['animationTag'];
        if (this.appService.isDesktop() && tag === 'siteDetails') return 'desktopSiteDetails'; // Disable site in desktop
        return tag;
    }

    getMediaPath() {
        let path = this.appService.getValidBaseUrl(get(this.domain, 'webHeaderLogoPath'));
        if (!path) {
            path = this.appService.base('assets/images/tabit_white_yellow_ribbon.svg');
        }
        return path;
    }

}
