import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '../app.service';
import { TagsService } from '../_core/tags.service';
import { EntityService } from '../_core/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { FoodCategoryItemComponent } from '../components/food-category-item/food-category-item.component';
import { NgFor } from '@angular/common';
import { NgxDetectScrollDirective } from '../_core/directives';
import { WidgetOpenerComponent } from '../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-food-categories',
    templateUrl: './food-categories.component.html',
    styleUrls: ['./food-categories.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, NgxDetectScrollDirective, NgFor, FoodCategoryItemComponent, OrderModule, TranslateModule]
})
export class FoodCategoriesComponent implements OnInit, OnDestroy {

    tagsSubscription: Subscription = null;
    coreSubscription: Subscription = null;

    tags: any = [];
    scrollDirection: string = 'up';
    showScrollUp: boolean = false;

    constructor(
        public appService: AppService,
        private tagsService: TagsService,
        private entityService: EntityService,
    ) { }

    ngOnInit() {
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        this.tagsSubscription = this.tagsService.tagsData$.pipe(map(tags => {
            return tags?.filter(tag => tag.type !== 'occasions' && tag.organizations_count > 0 &&  tag.showOnTagsView == true);
        })).subscribe(tags => {
            this.tags = tags;
        });
    }

    ngOnDestroy() {
        this.coreSubscription.unsubscribe();
        this.tagsSubscription.unsubscribe();
    }

    public handleScroll(event: any) {
        let direction = event.isReachingTop || event.direction == 'up' ? 'up' : 'down';
        if (direction != this.scrollDirection) this.scrollDirection = direction;
        this.showScrollUp = !event.isReachingTop && event.scrollTop && event.scrollTop > 200;
    }

}
