import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { WebContainerService } from '../../web-container.service';
import { TranslationService } from '../../../_core/translation.service';
import { AppService } from '../../../../../src/app/app.service';

import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { AccessibleDirective } from '../../../accessability/accessible/accessible.directive';

declare const $: any;

@Component({
    selector: 'app-web-header-search',
    templateUrl: './web-header-search.component.html',
    styleUrls: ['./web-header-search.component.scss'],
    animations: [
        trigger('toggleHeaderSearchField', [
            state('opened', style({ width: '100%' })),
            state('partly-opened', style({ width: '80%' })),
            state('closed', style({
                width: '2.5rem'
            })),
            transition('* => closed', animate('250ms 0s ease-in-out')),
            transition('closed => *', animate('250ms 350ms ease-in-out')),
        ])
    ],
    standalone: true,
    imports: [AccessibleDirective, FormsModule, ReactiveFormsModule, NgIf, MatButton, MatIcon, MatIconButton, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, TranslateModule]
})
export class WebHeaderSearchComponent implements OnInit, OnDestroy {

    @Input() searchFieldOpen: boolean;
    @Output() searchFieldOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('searchQueryInput', {static: false}) searchQueryInput: ElementRef;

    public searchQuery: string;
    public searchForm = new UntypedFormGroup({
        'searchQuery': new UntypedFormControl('')
    });
    public domain: any;
    public languageActive: boolean = false;
    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;
    
    private navigateToSitesOnSearch: boolean = false;
    public selectedLanguageValue: string;
    public filteredLanguages: any;

    toggleSearchField(expression?) {
        this.searchFieldOpen = typeof expression !== 'undefined' ? expression : !this.searchFieldOpen;
        this.searchFieldOpenChange.emit(this.searchFieldOpen);
    }

    toggleSearchFieldAndFocusOnInput(expression?) {
        this.toggleSearchField(expression);
        if(this.searchFieldOpen) {
            if (this.searchQueryInput?.nativeElement) {
                this.searchQueryInput.nativeElement.focus();
            }
        } else {
            if (this.searchQueryInput?.nativeElement) {
                this.searchQueryInput.nativeElement.blur();
            }
        }

    }

    constructor(
        public appService: AppService,
        public translationService: TranslationService,
        private webContainerService: WebContainerService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData.subscribe(loading => {
            if (!loading) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;
                    this.languageActive = this.domain?.multiLanguageEnabled;
                    this.selectedLanguageValue = this.appService.localeId;

                    this.filteredLanguages = this.translationService.getFilteredLanguages('tabit', true);
                });
            }
        });

        this.searchQuery = this.webContainerService.getMainSearchQuery();

        if (this.searchQuery) this.searchFieldOpen = true;

        this.activatedRoute.data.subscribe((data: Data) => {
            if (data && data.navigateToSitesOnSearch !== undefined) this.navigateToSitesOnSearch = !!data.navigateToSitesOnSearch;
            if (this.navigateToSitesOnSearch) {
                this.searchQuery = '';
                this.toggleSearchField(false);
            }
        });

    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
    }

    search(event: any) {
        event.stopPropagation();
        this.webContainerService.webSelectionSearchEvent.emit(true);

        let webSearchDetails = this.webContainerService.getSharedSearchValue() || {};
        this.webContainerService.updateSharedSearch({ ...webSearchDetails, query: this.searchQuery, skip: 0 });
        if (this.navigateToSitesOnSearch) {
            let route = this.appService.redirectValueByLocale('restaurants', 'ROUTE');
            this.router.navigate([route]);
        }
        if (this.searchQueryInput?.nativeElement) {
            this.searchQueryInput.nativeElement.blur();
        }
        /*
        } else if ($('.mobile-web-tabs-header-container')[0]) {
            // Scrolling the results into View (on Mobile only)
            // Note: we all reduce 1rem (16/2 pixels) due to the bottom border of the header
            let scrollTopValue = parseInt($('web-header').outerHeight()) - parseInt($('.mobile-web-tabs-header-container').outerHeight()) - (16/2);
            $('.web-container-inner-wrapper').animate({ scrollTop: scrollTopValue }, 400);
        }
        */
    }

    onSearchBlur() {
        let oldValue = this.webContainerService.getMainSearchQuery();
        if (!oldValue) {
            this.searchQuery = '';
            this.toggleSearchField();
        } else {
            this.searchQuery = oldValue;
        }
    }

    clearSearchQuery(event: Event) {
        event.stopPropagation();
        this.searchQuery = '';
        let webSearchDetails = { ...(this.webContainerService.getSharedSearchValue() || {}) };
        if (webSearchDetails.query) delete webSearchDetails.query;
        this.webContainerService.updateSharedSearch(webSearchDetails);

    }

    handleSearchFieldKeyUp(event: Event) {
        if (!this.searchQuery) this.clearSearchQuery(event);
    }

    changeUsedLanguage(value) {
        this.selectedLanguageValue = value;
        this.translationService.setLastUsedLangForMainApp(this.selectedLanguageValue);
        this.translationService.changeUsedLanguage(this.selectedLanguageValue);
    }
}
