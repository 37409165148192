<ng-container *ngIf="(specialMessagesService.showMessages$ | async) as messages">
    <div class="to-messages-container">
        <div
            *ngFor="let message of messages; let i = index"
            class="special-message wl-cards-background-color"
            [class]="message.type"
            [style.animation-delay]="(i * 100) + 'ms'"
            (click)="closeMessage(message)"
            [class.remove-message]="message.remove"
        >
            <div
                (click)="closeMessage(message, $event)"
                class="close-icon-container"
                [style.border-color]="getColorFromType(message.type)"
            >
                <mat-icon [style.color]="getColorFromType(message.type)">close</mat-icon>
            </div>
            <mat-icon
                class="message-icon"
                [svgIcon]="message.type + '-message'"
                *ngIf="message.type != 'plain'"
            ></mat-icon>
            <div class="to-message-text"> {{getMessageText(message)}} </div>
        </div>
    </div>
</ng-container>