import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { AccessibilityMenuComponent } from '../../components/accessibility-menu/accessibility-menu.component';

@Component({
    selector: 'app-accessibility-dialog',
    templateUrl: './accessibility-dialog.component.html',
    styleUrls: ['./accessibility-dialog.component.scss'],
    standalone: true,
    imports: [AccessibilityMenuComponent, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})

export class AccessibilityDialogComponent {
    public declarationURL: string;

    // dialog passed data-scope
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appService: AppService,
    ) { }

}
