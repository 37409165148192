import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup, MatTab } from '@angular/material/tabs';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../_core/SiteListsBase';

import { AppService } from '../app.service';
import { LocationService } from '../_core/location.service';
import { EntityService } from '../_core/entity.service';
import { Address } from '../_core/addresses.service';
import { OrganizationsService } from '../_core/organizations.service';
import { DialogsService } from '../_core/dialogs.service';
import { ORGANIZATION_BUCKET_TYPES, SearchDetails } from '../_core/OrganizationBucket';
import { Router, ActivatedRoute } from '@angular/router';

import { orderBy, map } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SiteItemComponent } from '../components/site-item/site-item.component';
import { MatList } from '@angular/material/list';
import { NgFor, NgIf } from '@angular/common';
import { SitesSearchToolsComponent } from '../components/sites-search-tools/sites-search-tools.component';

declare const $: any; // For the scroll we are using jQuery

interface ServiceObj {
    index: number;
    isActive: boolean;
    label?: SearchDetails['service'];
}

@Component({
    selector: 'app-sites-order-ta',
    templateUrl: './sites-order-ta.component.html',
    styleUrls: ['./sites-order-ta.component.scss'],
    host: { class: 'wl-app-primary-background-color' },
    standalone: true,
    imports: [SitesSearchToolsComponent, MatTabGroup, NgFor, MatTab, NgIf, MatList, SiteItemComponent, MatProgressSpinner, TranslateModule]
})
export class SitesOrderTaComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.order) implements OnInit, OnDestroy {

    private baseSubscription: Subscription;
    private coreSubscription: Subscription;
    private domainSubscription: Subscription;

    addresses: Address[] = [];
    public domain: any;
    // Currently not in use:
    selectedAddressLocation: { lat: number, lng: number } = null;

    selectedService: SearchDetails['service'] = this.appService.defaultServiceOrderType;

    services: { [key in ('takeaway' | 'delivery')]: ServiceObj } = {
        takeaway: {
            index: 0,
            isActive: false,
        },
        delivery: {
            index: 1,
            isActive: true,
        },
    }

    servicesArr: ServiceObj[];

    addressDirty: boolean = false;
    public extendedResultsButtonVisible: boolean = false;
    public usingExtendedResults: boolean = false;

    public filterFields = {
        rating: false,
        price: false,
        onlyTabit: false,
        availability: true,
        area: true,
        tags: true
    };

    constructor(
        public appService: AppService,
        private dialogsService: DialogsService,
        private entityService: EntityService,
        protected organizationsService: OrganizationsService,
        private locationService: LocationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        protected liveAnnouncer: LiveAnnouncer,
    ) { super(); }

    ngOnInit() {
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })
        this.domainSubscription = this.appService.domain.subscribe(domain => {
            this.domain = domain;
        });
        this.servicesArr = orderBy(map(this.services, (serviceObj: ServiceObj, key: 'takeaway' | 'delivery'): ServiceObj => ({ ...serviceObj, label: key })), 'index');

        let orderSearchType = this.getSearchType();

        this.initialize();

        // tabit-app: keep track of the order process referring page for exact back-button functionality
        this.appService.setOrderProcessReferringRoute();

        this.baseSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location,
            this.activatedRoute.queryParams
        ]).subscribe(([
            locationInitialized,
            newLocation,
            queryParams
        ]) => {
            let lastSearchDetails = this.organizationsService.getSearchDetails(orderSearchType);

            if (queryParams && queryParams['service-type']) {
                this.selectedService = queryParams['service-type'];
                this.appService.defaultServiceOrderType = queryParams['service-type'];

            } else if (lastSearchDetails && lastSearchDetails.service) {
                this.selectedService = lastSearchDetails.service;
            }

            this.setExtendedResultsButtonVisibility(this.selectedService === 'delivery');

            this.usingExtendedResults = !!(this.selectedService === 'delivery' && lastSearchDetails && lastSearchDetails.externalDeliveryLink);

            if (!locationInitialized || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails(null, this.selectedService);

            this.newSearch(nextSearchDetails, () => {
                //this.manageScroll();
            });
        }, err => {
            console.error('Error initializing page:', err);
        });
    }

    ngOnDestroy() {
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
        this.baseSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        this.cleanUp();
    }

    clearSearchText() {
        super.clearSearchText(this.selectedService);
    }

    searchChanged(newSearchDetails: SearchDetails) {
        // console.log(' === SITES/TA === search changed to:', newSearchDetails);
        let searchDetails: SearchDetails = { ...newSearchDetails, skip: 0, service: this.selectedService };
        this.newSearch(searchDetails);
    }

    siteClick(site) {
        // If the service is available but also begin at the future - this is then a "pre order" availability:
        // 2020-05-13: Because Tabit Order also shows a similar alert, it has been decided to remove this alert for now
        /*
        if (this.appService.isPreorderAvailableNow(site, this.selectedService)) {
            this.dialogsService.showPreorderDialog({
                serviceAvailableTimestamp: this.appService.getServiceAvailableTimestamp(site, this.selectedService),
            }).subscribe(confirmed => {
                if (confirmed) this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
            }, err => {
                console.error('Error at confirm preorder dialog:', err);
            });
        } else {
            this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
        }
        */

        // clear specific flags from previous order, if available
        this.resetSiteFutureFlags(site);

        if (this.selectedService == 'takeaway' && this.domain?.defaults?.verifySiteConfirmationEnabled) {
            this.showConfirmationDialog(site, null);
        } else {
            this.organizationsService.searchScreenNeedsScroll = true;
            this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
        }

    }

    sitePress(site, ev) {

        // clear specific flags from previous order, if available
        this.resetSiteFutureFlags(site);

        if (this.selectedService == 'takeaway' && this.domain?.defaults?.verifySiteConfirmationEnabled) {
            this.showConfirmationDialog(site, ev);
        } else {
            // 2019-09-23 - Deprecated per Barry's request
            // 2019-12-24 - Re-enabled because in Delivery the tap redirects directly to  Ordering, and we want to let the user the ability to go into the Site Details too.
            this.dialogsService.showSiteActionsDialog(site, ev, this.router);
        }
    }

    serviceSelected(event: MatTabChangeEvent) {

        let serviceObj = this.servicesArr[event.index];

        if (!serviceObj || !serviceObj.label) throw new Error(`service for tab: '${event.tab.textLabel}' is not supported`);

        if (this.selectedService === serviceObj.label) return;

        this.selectedService = serviceObj.label;

        this.appService.defaultServiceOrderType = this.selectedService;

        this.router.navigate([], { queryParams: { 'service-type': this.selectedService } });

        // tabit-app: keep track of the order process referring page for exact back-button functionality
        this.appService.setOrderProcessReferringRoute();
    }

    goToNewAddress() {
        this.appService.redirect(['profile', 'my-addresses', { 'initial-action': 'new', 'came-from': true }]);
    }

    public extendedResultsClick() {

        this.usingExtendedResults = true;

        let searchDetails: SearchDetails = {
            ...this.organizationsService.getSearchDetails(this.getSearchType()),
            skip: 0,
            service: this.selectedService,
            externalDeliveryLink: true
        };

        this.newSearch(searchDetails, () => {
            this.manageScroll();
        });

    }

    private setExtendedResultsButtonVisibility(active: boolean) {
        this.extendedResultsButtonVisible = active;
    }

    showConfirmationDialog(site, ev) {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'info',
                dialogTitle: this.getDialogTitle(site),
                dialogText: this.getDialogText(site),
                primaryButtonText: 'START_ORDER',
                secondaryButtonText: 'BACK',
            }).then((response: any) => {
                if (response?.primaryButtonClick) {
                    if (ev != null) {
                        this.organizationsService.searchScreenNeedsScroll = true;
                        this.dialogsService.showSiteActionsDialog(site, ev, this.router);
                    } else {
                        this.organizationsService.searchScreenNeedsScroll = true;
                        this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
                    }
                }
            }).catch(err => {

            });
        });
    }

    getDialogTitle(site) {
        return this.appService.translate('pickup-order-dialog.Branch_pickup_order') +  site.name;
    }

    getDialogText(site) {
        return this.appService.translate('pickup-order-dialog.address') + ' ' +  site.address;
    }

    // clear specific flags from previous order, if available
    private resetSiteFutureFlags(site) {
        delete site?.isFuture;
    }

}
