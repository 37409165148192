import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, NgZone } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppService } from '../../app.service';
import { ConfigurationsService } from '../../_core/configurations.service';

import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-sign-in-by-email-form',
    templateUrl: './sign-in-by-email-form.component.html',
    styleUrls: ['./sign-in-by-email-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        ReactiveFormsModule,
        NgIf,
        MatIcon,
        MatSuffix,
        MatProgressSpinner,
        TranslateModule,
    ],
})
export class SignInByEmailFormComponent implements OnInit, OnDestroy {
	public email = new UntypedFormControl('', [Validators.required, Validators.email]);

    @Input() formModel = {email: '', password: ''};
    @Input() disabled: boolean = false;
    @Input() dialogMode: boolean = false;

    @Output() onSubmit = new EventEmitter<{}>();
    @Output() onResetPassword = new EventEmitter<{}>();
    @Output() onSignup = new EventEmitter<{}>();

    public hide = true;
    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;
    public domain: any;

    constructor(
        private ngZone: NgZone,
        public appService:AppService,
        public configurationsService: ConfigurationsService,
    ) { }

    ngOnInit() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData.subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;
                });
            }
        })
    }

	_signIn(form) {
		if (!form.valid) return;
        this.onSubmit.emit(this.formModel);
    }

	_signUp() {
		this.onSignup.emit({});
	}

	_resetPassword() {
        if (!this.formModel.email) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'error_reset_password_missing_email'
                });
            });
		} else if (!this.email.valid) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'invalid_email_entered'
                });
            });
		} else {
            this.onResetPassword.emit(this.formModel.email);
        }
    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
    }

}
