import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { OrderModule } from 'ngx-order-pipe';
import { MatButton } from '@angular/material/button';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { TabitbookSelectionBarComponent } from '../tabitbook-selection-bar/tabitbook-selection-bar.component';

@Component({
    selector: 'app-tabitbook-alt',
    templateUrl: './tabitbook-alt.component.html',
    styleUrls: ['./tabitbook-alt.component.scss'],
    standalone: true,
    imports: [TabitbookSelectionBarComponent, NgFor, NgIf, MatButton, DatePipe, OrderModule]
})
export class TabitbookAltComponent implements OnInit {
    @Input() options;

    $storage: any;


    constructor(
        public appService: AppService,
        public bookService: BookService,
    ) {
        this.$storage = this.bookService.$storage;
    }

    selectAltSlot(area, slot) {
        this.options.selectAltSlot(area, slot);
    }

    addToWaitingList() {
        this.options.postTempReservation(true);
    }

    ngOnInit() {

    }

}
