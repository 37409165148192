import { Component, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { AppService, Domain } from '../../app.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { DialogsService } from '../../_core/dialogs.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'consent-text',
    templateUrl: './consent-text.component.html',
    styleUrls: ['./consent-text.component.scss'],
    standalone: true,
    imports: [NgIf, AccessibleDirective, TranslateModule]
})
export class ConsentTextComponent implements OnInit {

    public domain: Domain;
    public privacyPolicyURL: string;
    public termsOfUseURL: string;
    public loyaltyTermsURL: string;

    constructor(
        public appService: AppService,
        public dialogService: DialogsService,
        public configurationsService: ConfigurationsService,
    ) { }

    ngOnInit(): void {
        this.appService.domain.subscribe(domain => {
            this.domain = domain;
            this.privacyPolicyURL = get(this.domain, 'links.privacyPolicyURL', `https://www.tabitorder.com/privacy/${this.appService.appConfig.locale === 'he-IL' ? 'he' : 'en'}`);
            this.termsOfUseURL = get(this.domain, 'links.termsOfUseURL', `https://www.tabitorder.com/terms/${this.appService.appConfig.locale === 'he-IL' ? 'he' : 'en'}`);
            this.loyaltyTermsURL = get(this.domain, 'links.loyaltyTermsURL');
        });
    }

    openLink(path: string) {

        let url: string;

        switch (path) {
            case "privacy":
                url = this.privacyPolicyURL;
                break;
            case "loyaltyTerms":
                url = this.loyaltyTermsURL;
                break;
            case "terms":
            default:
                url = this.termsOfUseURL;
                break;
        }

        this.dialogService.toggleActionFrame('link', null, null, window['cordova'], url);
    }

}
