import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { get } from 'lodash-es';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js/mobile';

import { AppService } from '../../app.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { StorageService } from '../../_core/storage.service';

import { FormValidationAnnouncementService } from '../../accessability/form-validation-announcement/form-validation-announcement.service';

declare const $: any;

@Component({
    selector: 'app-sign-in-by-phone-form',
    templateUrl: './sign-in-by-phone-form.component.html',
    styleUrls: ['./sign-in-by-phone-form.component.scss'],
})
export class SignInByPhoneFormComponent implements OnInit, OnDestroy {
    @Input() formModel = { phone: '' };
    @Input() disabled: boolean = false;
    @Input() dialogMode: boolean = false;
    @Input() local: any;

    @Output() onSubmit = new EventEmitter<{}>();
    @Output() onResetPassword = new EventEmitter<{}>();
    @Output() onSignup = new EventEmitter<{}>();
    @Output() onBack = new EventEmitter<{}>();

    public internationalPhoneInput = false;
    public hide = true;
    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;
    public domain: any;
    public privacyPolicyURL: string;
    public termsOfUseURL: string;

    constructor(
        public appService: AppService,
        public configurationsService: ConfigurationsService,
        public storageService: StorageService,
        public liveAnnouncer: LiveAnnouncer,
        private formValidationAnnouncementService: FormValidationAnnouncementService,
    ) { }

    ngOnInit() {
        this.loadDomain();
        let storedPhone = this.storageService.getItem('username');
        if (/^\d+$/.test(storedPhone)) {
            console.debug('Stored Phone: ', storedPhone);
            // If it's all digits - we use it as the initial value of the phone field
            this.formModel.phone = storedPhone;
        }

        if (!this.appService.isApp) {
            this.internationalPhoneInput = true;
        }
        this.liveAnnouncer.announce(this.appService.translate('forms_screen_reader.sign-up.pop_up_announcement'))
    }

    _signIn(form: NgForm) {
        this.updatePhoneFieldAndValidate(form);
        this.formValidationAnnouncementService.validateFormAndAnnounce(form, 'forms_screen_reader.sign-up');
        if (!form.valid) return;
        this.onSubmit.emit(this.formModel);
    }

    updatePhoneFieldAndValidate(form: NgForm) {
        if (form?.controls) {
            const control = form.controls['phoneControl'];
            if (control?.value) {
                control.setValue(this.getFormattedPhoneNumber(control.value));
                control.updateValueAndValidity();
            }
        }
    }

    _signUp() {
        this.onSignup.emit({});
    }

    _back() {
        this.onBack.emit({});
    }

    loadDomain() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData
            .subscribe(loadingCoreData => {
                if (!loadingCoreData) {
                    this.domainSubscription = this.appService.domain.subscribe(domain => {
                        this.domain = domain;
                        this.privacyPolicyURL = get(this.domain);
                        this.termsOfUseURL = get(this.domain);
                    });
                }
            })
    }

    public parsePhoneNumberValidator(): ValidatorFn {
        //local contains localization value, for example US or IL for phone validation
        return (control: AbstractControl): ValidationErrors | null => {
            try {
                const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
                if (this.getParsePhoneNumber(control.value)) {
                    const parsePhone = isValidPhoneNumber(control.value, locale);
                    return parsePhone ? null : { 'val': { value: control.value } };
                } else {
                    return { 'val': { value: control.value } };
                }
            }
            catch (e) {
                return { 'val': { value: control.value } };
            }
        }
    }

    getParsePhoneNumber(phoneNumber) {
        //parsePhoneNumber libphonenumber-js library for parsing and validating international phone numbers
        if (!phoneNumber) return '';
        try {
            const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
            const libPhoneReceiptContactNumber = parsePhoneNumber(phoneNumber, locale);
            if (libPhoneReceiptContactNumber?.number) return libPhoneReceiptContactNumber.number;
            else return '';
        }
        catch (e) {
            return '';
        }
    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
    }

    getFormattedPhoneNumber(phoneNumber) {
        const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
        const libPhoneReceiptContactNumber = parsePhoneNumber(phoneNumber, locale);
        if (!libPhoneReceiptContactNumber) {
            return phoneNumber;
        }
        const formatNational = libPhoneReceiptContactNumber.formatNational();

        if (formatNational) {
            const formattedNational = removeSpacesAndDashes(formatNational);
            if (formattedNational) {
                return formattedNational;
            } else {
                return phoneNumber;
            }
        } else {
            return phoneNumber;
        }

        function removeSpacesAndDashes(str) {
            return str.replace(/\D/g, ''); // remove all non-digits
        }
    }
}
