import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MarketplaceComponent } from '../marketplace.component';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-marketplace',
    templateUrl: './app-marketplace.component.html',
    styleUrls: ['./app-marketplace.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, MarketplaceComponent, TranslateModule]
})
export class AppMarketplaceComponent implements OnInit {

  constructor(
    public appService: AppService,
  ) { }

  ngOnInit(): void {
  }

}
