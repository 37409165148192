import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { Subscription } from 'rxjs';
import { each } from 'lodash-es';
import { SafeStylePipe, AMPMConverterPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgFor, NgClass, NgTemplateOutlet, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'future-reservations',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './future-reservations.component.html',
    styleUrls: ['./future-reservations.component.scss'],
    animations: [
        trigger('reservationCardAnimation', [
            transition(':leave', [
                style({ opacity: 1, transform: 'scale(1)' }),
                animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
                animate('0.2s 0s ease-in', style({ opacity: 0, transform: 'scale(0.7)' }))
            ])
        ]),
    ],
    standalone: true,
    imports: [NgFor, NgClass, NgTemplateOutlet, NgIf, MatIcon, DatePipe, TranslateModule, SafeStylePipe, AMPMConverterPipe]
})
export class FutureReservationsComponent implements OnInit, OnDestroy {

    @Input() reservations: any[] = [];
    @Input() parent: string = '';

    public title: string;
    private organizationSubscription: Subscription;

    constructor(
        public appService: AppService,
        public bookService: BookService,
        public organizationsService: OrganizationsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.reservations?.length) {
            each(this.reservations, reservation => {
                if (!this.organizationsService.getOrganization(reservation.organization)) {
                    this.organizationSubscription = this.organizationsService.getFullOrganization(reservation.organization).subscribe(org => {
                        reservation.fullOrganization = org;
                        this.changeDetectorRef.detectChanges();
                        return;
                    }, err => console.error(`Can't find organization: ${err}`));
                }
            });
        }
    }

    public setTitle(futureReservation: any) {
        if (this.parent === 'dashboard') return this.organizationsService.getOrganization(futureReservation.organization).name;
        if (this.reservationWithoutIssues(futureReservation)) {
            return this.appService.translate('future_reservation');
        } else {
            if (this.isPendingApproval(futureReservation)) return this.appService.translate('future_order_pending');
            if (this.isStandbyList(futureReservation)) return this.appService.translate('future_order_standby');
            if (futureReservation.isMissingDeposit) return this.appService.translate('TGM.missing_deposit');
            if (futureReservation.isMissingDepositPayment) return this.appService.translate('TGM.missing_deposit_payment');
        }
    }

    public checkAlertForTitle(reservation) {
        const isAlert = (
            this.parent != 'dashboard' && (
                reservation.pending_approval ||
                reservation.standby_reservation ||
                reservation.isMissingDeposit ||
                reservation.isMissingDepositPayment
            )
        );

        return isAlert;
    }

    private reservationWithoutIssues(futureReservation) {
        const noIssues = !futureReservation.pending_approval && !futureReservation.standby_reservation && !futureReservation.isMissingDeposit && !futureReservation.isMissingDepositPayment;
        return noIssues;
    }

    public isPendingApproval(futureReservation) {
        const pendingApproval = futureReservation.pending_approval && futureReservation.standby_reservation;
        return pendingApproval;
    }

    public isStandbyList(futureReservation) {
        const standby = !futureReservation.pending_approval && futureReservation.standby_reservation;
        return standby;
    }

    ngOnDestroy() {
        if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
    }
}
