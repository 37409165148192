import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';

import { AppService } from '../../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-to-group-select-dialog',
    templateUrl: './to-group-select-dialog.component.html',
    styleUrls: ['./to-group-select-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, NgFor, NgStyle, TranslateModule]
})
export class ToGroupSelectDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ToGroupSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appService: AppService,
    ) { }

    apply(member) {
        // Add the actual number
        this.dialogRef.close(member);
    }

}
