import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { DialogsService } from '../../_core/dialogs.service';
import { EntityService } from '../../_core/entity.service';
import { LocationService } from '../../_core/location.service';
import { TabitpayService } from '../tabit-pay/tabit-pay.service';

import { orderBy } from 'lodash-es';

import { OrganizationsService } from '../../_core/organizations.service';
import { ORGANIZATION_BUCKET_TYPES } from '../../_core/OrganizationBucket';
import { TranslateModule } from '@ngx-translate/core';
import { SiteCardComponent } from '../../components/site-card/site-card.component';
import { NgFor, SlicePipe } from '@angular/common';
import { LottieComponent } from 'ngx-lottie';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-sites-pay',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sites-pay.component.html',
    styleUrls: ['./sites-pay.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        RouterLink,
        WidgetOpenerComponent,
        LottieComponent,
        NgFor,
        SiteCardComponent,
        SlicePipe,
        TranslateModule,
    ],
})
export class SitesPayComponent implements OnInit {
    private orgsSubscription: Subscription = null;
    private organizationServiceSearch: Subscription = null;
    private coreSubscription: Subscription;

    private sitesLoaded: boolean = false;
    private firstTime: boolean = false;

	orgs: any = [];
    paybackAnimation = this.appService.getAnimationPath('pay-back');

	constructor(
		public appService: AppService,
		public utilsService: EntityUtilsService,
		public dialogsService: DialogsService,
		public entityService: EntityService,
		public locationService: LocationService,
        public orderService: TabitpayService,
        private organizationsService: OrganizationsService,
        private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone
        ) { }

    ngOnInit() {
        // This flag is to prevent showing of the last search results... There are better ways to do it, like subscriber creators
        this.firstTime = true;
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) {
                this.entityService.subscribeToCoreData()
                .then(() => this.subscribeToOrgs());
            }
        })

        this.subscribeToOrgs();

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/home'])
                    })
                });
        }
    }

    subscribeToOrgs() {
        if (this.sitesLoaded) return;

        // console.log('Initializing sites pay!');
        let paySearchType = ORGANIZATION_BUCKET_TYPES.tabitpay;

        // Remember: This is not asynchronic operation:
        this.orgsSubscription = this.organizationsService.data[paySearchType].pipe(map(organizations => {
            return orderBy(organizations, ['distance'], ['asc']);
        })).subscribe(organizations => {
            if (!this.firstTime) {
                this.orgs = organizations;
                this.sitesLoaded = true;
                this.changeDetectorRef.detectChanges();
            } else this.firstTime = false;
        }, err => {
            this.sitesLoaded = false;
            console.error('error with incoming paying (auto - search) organizations:', err);
        });

        this.organizationServiceSearch = this.organizationsService.search({ service: 'tabitpay' }, paySearchType).subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });

        this.organizationsService.specialUrlSearch = 'pay';
    }

	siteClick(site: any, ev: any) {
        console.debug('Tabit Pay > pay/sites > siteClick: ', site._id);
        this.orderService.redirect(site._id);
        // this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: site._id } });
	}

	ngOnDestroy() {
        this.coreSubscription.unsubscribe();
        this.orgsSubscription.unsubscribe();
        this.organizationServiceSearch.unsubscribe();
	}

}
