import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { Dir } from '@angular/cdk/bidi';

@Component({
    selector: 'app-tabitbook-license-dialog',
    templateUrl: './tabitbook-license-dialog.component.html',
    styleUrls: ['./tabitbook-license-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, Dir, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class TabitbookLicenseDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<TabitbookLicenseDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

}
