import { Component, OnInit, ElementRef } from '@angular/core';

import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-my-rank',
    templateUrl: './my-rank.component.html',
    styleUrls: ['./my-rank.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        NgFor,
        NgIf,
        TranslateModule,
    ],
})
export class MyRankComponent implements OnInit {

	constructor(
		elementRef: ElementRef,
		public appService: AppService,
	) {

	}

  ngOnInit() {
  }

}
