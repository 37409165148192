import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { BookService } from '../../_core/book.service';
import { ConfigurationsService } from '../../_core/configurations.service';

import { Subscription } from 'rxjs';
import { SafeStylePipe, DefaultCurrencyPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-history-item',
    templateUrl: './history-item.component.html',
    styleUrls: ['./history-item.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, DatePipe, TranslateModule, SafeStylePipe, DefaultCurrencyPipe]
})
export class HistoryItemComponent implements OnInit, OnDestroy {

    @Input() type:string;
    @Input() siteName:string;
    @Input() historyItem:any;
    @Input() withBill:boolean;

    private configurationSubscription: Subscription;
    public reviewButtonEnabled: boolean = false;

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        public bookService: BookService,
        public configurationsService: ConfigurationsService,
    ) { }

    ngOnInit(): void {
        if (!this.historyItem) throw new Error('Race condition detected! History Item component initialized without history item!');
        if (!this.historyItem._id) throw new Error('History Item component initialized with bad history item!');

        this.configurationSubscription = this.appService.domain.subscribe(domain => {
            this.reviewButtonEnabled = domain?.defaults?.reviewButtonEnabled;
        })
    }

    ngOnDestroy(): void {
        this.configurationSubscription.unsubscribe();
    }

    _getFutureReservationDetails(reservation:any) {
		if (!reservation) return;

		let details = `${reservation.reservation_details.seats_count} ${this.appService.translate('TGM.DINERS')}`

		if (reservation?.reservation_details?.preference != 'first_available') details = details.concat(` (${this.bookService.translate('booking.search.' + reservation.reservation_details.preference)})`);

		return details;
    }

    _redirectToUserReview(historyItem) {
        let queryParams = {
            'user-id': this.appService.user.loyaltyCustomer.CustomerId,
            'organization-id': historyItem.organization,
            'organization-name': historyItem.name,
            'tlog-id': historyItem._id,
            'date': historyItem.date,
            'order-id': historyItem.orderId,
            'referrer': this.appService.currentUrl,
            'serviceType': historyItem.serviceType,
        }

        this.appService.redirect(['/app-user-review'], { queryParams });
    }

    _redirectToSite() {
        this.appService.redirect(['/app-site', this.historyItem.organization]);
    }

}
