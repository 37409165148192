import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { TabitpayService } from './tabit-pay.service';
import { DialogsService } from '../../_core/dialogs.service';

import { Subscription } from 'rxjs';
import { TabitpayCheckoutFullComponent } from './tabitpay-checkout-full/tabitpay-checkout-full.component';
import { TabitpayTicketComponent } from './tabitpay-ticket/tabitpay-ticket.component';
import { TabitpayEnterOrderComponent } from './tabitpay-enter-order/tabitpay-enter-order.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';

declare const $: any;

@UntilDestroy()
@Component({
    selector: 'app-tabit-pay',
    templateUrl: './tabit-pay.component.html',
    styleUrls: ['./tabit-pay.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        MatButton,
        NgIf,
        MatTabGroup,
        MatTab,
        TabitpayEnterOrderComponent,
        TabitpayTicketComponent,
        TabitpayCheckoutFullComponent,
    ],
})
export class TabitPayComponent implements OnInit, OnDestroy {
    loading: boolean = true;
    loadingStep: boolean = false;
    site: any;
    siteName: string;
    step: number = 0
    pms: any = [];
    date = new Date();
    $storage: any = {};
    reloadTimeout: any;
    childOptions: any = {
        startBlock: () => { this.appService.startBlock() },
        stopBlock: () => { this.appService.stopBlock() }
    }
    private routeQueryParamsSubscription: Subscription;
    private orderSubscription: Subscription = null;

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public orderService: TabitpayService,
        public dialogsService: DialogsService,
        public router: Router,
        private route: ActivatedRoute,
        private ngZone: NgZone,
    ) { }

    ngOnInit() {
        this.routeQueryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.appService.startBlock();
            this.entityService.getSiteConfig(params.site).then(site => {
                this.orderService.start(site, params);
                this.$storage = this.orderService.$storage;
                this.$storage.viewHeight = $(window).height();

                this.siteName = site.config.name;

                if (params.oid) {
                    return this.lookUpOrder(params.oid).then(res => {
                        this.$storage.forcePrices = params.price != null;
                        return res;
                    });
                } else {
                    this.loading = false;
                }
            }).catch(err => { }).then(res => {
                this.appService.stopBlock();
            });
        });

        this.appService.androidBackButton
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.goBack()
                });
            });

        this.orderSubscription = this.orderService.orderChanged$.subscribe(() => {
            if (this.step == 1) {
                this.reloadTab();
            }
        });

        this.appService.confirmBeforeRedirect = true;
    }

    reloadTab() {
        clearTimeout(this.reloadTimeout);
        this.loading = true;
        this.reloadTimeout = setTimeout(() => {
            this.loading = false;
        }, 200);
    }

    ngOnDestroy() {
        this.orderService.unloadOrder();
        if (window['QRScanner']) {
            window['QRScanner'].destroy(function (status) {
                this.appService.toastr("Close Module QR Status: " + status);
            });
        }
        this.routeQueryParamsSubscription.unsubscribe();
        this.orderSubscription.unsubscribe();
        this.appService.confirmBeforeRedirect = false;
    }

    goBack() {
        if (this.loadingStep) return;

        // confirm closing the order
        if (this.step == 1 && this.$storage?.order && !this.$storage?.order.closed) {
            this.close(true);
            // no confirmation
        } else {
            if (this.step < 2 || this.step === 4) {
                this.close();
            } else {
                this.setStep(this.step - 1);
            }
        }
    }

    close(confirm?: boolean) {
        if (this.$storage.wasPayment) {
            this.appService.redirect(['/home/dashboard']);
        } else if (confirm) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                return this.appService.mainMessage({
                    dialogType: 'info',
                    dialogTitle: 'payment_in_progress',
                    dialogText: 'confirm_exit',
                    primaryButtonText: 'cancel_order',
                    secondaryButtonText: 'payment_order'
                }).then(response => {
                    // reached the page via home -> pay -> choose-site
                    if (['/pay/sites', '/'].includes(this.appService.previousUrl)) {
                        this.appService.redirect(['/pay/sites']);
                    } else {
                        this.appService.goBack();
                    }
                }).catch(err => { });
            });
        } else {
            // reached the page via home -> pay -> choose-site
            if (['/pay/sites', '/'].includes(this.appService.previousUrl)) {
                this.appService.redirect(['/pay/sites']);
            } else {
                this.appService.goBack();
            }
        }
    }

    loadOrder(args) {
        this.appService.startBlock();
        let that = this;
        this.loading = true;

        let promises = [
            this.entityService.getSiteConfig(args.site),
            this.entityService.getUserWallet()
        ];

        Promise.all(promises).then(res => {
            let site = res[0];
            this.orderService.start(site);
            this.$storage = this.orderService.$storage;
            this.siteName = site.config.name;

            return this.orderService.loadOrder(args.site, args.oid).then(response => {
                that.step = 1;
            });
        }).catch(err => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: err.message
                });
            });
            //this.appService.toastr(err.message, { type: 'warning' });
        }).then(o => {
            this.appService.stopBlock();
            this.loading = false;
        });
    }

    onPayment(args?) {
        this.setStep(1);
        if (args?.showMessage) {
            const dialogText = this.appService.translate("MESSAGES.PAYMENT_ACCEPTED_SUCCESSFULLY_WITH_AMOUNT", { amount: args.amount });
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                return this.appService.mainMessage({
                    dialogType: 'success',
                    dialogTitle: 'THANK_YOU',
                    dialogText,
                    primaryButtonText: 'APPROVE',
                    hideSecondaryButton: true
                }).catch(err => { });
            });

            this.appService.confirmBeforeRedirect = false;
        }
        this.reloadOrder();
    }

    reloadOrder() {
        this.lookUpOrder(this.$storage.order.order_id);
    }

    lookUpOrder(orderNO) {
        if (orderNO) {
            this.appService.startBlock();
            return this.orderService.loadOrder(this.$storage.organization, orderNO).then(response => {
                this.setStep(1);
            }).catch(err => {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'SERVER_MESSAGES.ORDER_CLOSED_PAYED'
                    });
                });
                //this.appService.translate(err.message)
                //this.appService.toastr(err.message, { type: 'error' });
            }).then(o => {
                this.appService.stopBlock();
                this.loading = false;
            });
        } else {
            return Promise.reject();
        }
    }

    gotoPaymentClick(mode?, subMode?) {
        if (mode == 'full_tip') {
            mode = 'full';
            subMode = 'tip';
        }
        if (!mode) { //this.$storage.wasPayment
            const actions = [
                { id: 'gotoFullPayment', text: 'full-payment', icon: 'pay-full' },
                { id: 'gotoPartialPayment', text: 'partial-payment', icon: 'pay-partial' },
                { id: 'gotoAddItems', text: 'pay_by_items', icon: 'pay-items' },
                { id: 'passOrder', text: 'PASS_ORDER_TITLE', icon: 'paper_plane' }
            ];
            this.dialogsService.openBottomSheet({ actions }).then(response => {
                if (response) {
                    switch (response["id"]) {
                        case "gotoFullPayment": this.gotoPayment('full'); break;
                        case "gotoPartialPayment": this.gotoPayment('partial'); break;
                        case "gotoAddItems": this.gotoPayment('partial', 'items'); break;
                        case "passOrder": this.orderService.passOrder(); break;
                    }
                }
            });
        } else {
			this.gotoPayment(mode, subMode);
		}
    }

    gotoPayment(mode?, subMode?) {
        this.orderService.createUserWalletPms();
        this.$storage.stepsMode = mode;
        this.childOptions.subMode = subMode;

        this.setStep(2);
    }

    gotoCheckout() {
        this.setStep(3);
    }

    makePayment() {

    }

    setStep(step) {
        this.step = step;
    }

}

