import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app/app.service';
import { TranslationService } from '../../../app/_core/translation.service';
import { LoyaltyService } from '../../../app/_core/loyalty.service';
import { get } from 'lodash-es';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DefaultCurrencyPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'short-code',
    templateUrl: './short-code.component.html',
    styleUrls: ['./short-code.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, NgIf, NgFor, TranslateModule, DefaultCurrencyPipe]
})
export class ShortCodeComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    domain: any
    domainSubscription: any
    shortCode: any;
    code: number[];
    expirationTime: string
    customer: any;

    constructor(public appService: AppService,
        public translationService: TranslationService,
        private loyaltyService: LoyaltyService
    ) { }

    ngOnInit(): void {
        this.blockUI.start();
        this.domainSubscription = this.appService.domain.subscribe(domain => {
            this.domain = domain;
            const accountGuid = get(this, 'domain.clubIds[0]');

            this.loyaltyService.getCustomerClub(accountGuid).subscribe(customer => {
                this.blockUI.stop();
                this.customer = customer;

                const shortCode: any = localStorage.getItem("shortCode");
                if (!shortCode) {
                    this.getShortCode();
                } else {
                    const _shortCode = JSON.parse(shortCode)
                    const diff = moment(_shortCode.expiredOn).diff(moment().utc());
                    if (diff <= 0) {
                        this.getShortCode();
                    } else {
                        this.setShortCodeParameters(_shortCode);

                    }
                }

            }, error => {
                this.blockUI.stop();
            })
        }, error => {
            this.blockUI.stop();
        });
    }

    getShortCode() {
        const cardNumber = get(this, 'appService.user.loyaltyCustomer.CardNumber');
        const accountGuid = get(this, 'domain.clubIds[0]');

        this.appService.startBlock({ text: "PLEASE_WAIT", class: 'dark-block' });
        this.loyaltyService.getShortCode(cardNumber, accountGuid).subscribe(shortCode => {
            this.appService.stopBlock();
            this.setShortCodeParameters(shortCode)
        }, err => {
            this.appService.stopBlock();
        })
    }

    setShortCodeParameters(shortCode) {
        localStorage.setItem('shortCode', JSON.stringify(shortCode));

        this.shortCode = shortCode;
        this.code = shortCode.shortCode.split('');
        // we calculate expirationWithin only once
        if (!this.shortCode.expirationWithin) {
            this.shortCode.expirationWithin = moment(shortCode.expiredOn).utc().diff(moment(), 'minutes')
        }
        this.expirationTime = moment(shortCode.expiredOn).format('HH:mm')
    }

    onQuickBalanceClick() {
        this.loyaltyService.redirectToGiftCards('topUp');
    }
    close() {
        this.appService.goBack();
    }

}
