import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppService } from '../../app.service';
import { MarketplaceService } from '../../_core/marketplace.service';
import { SiteMoreInfoDialogComponent } from '../../../app/dialogs/site-more-info-dialog/site-more-info-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MarketplaceImageComponent } from './marketplace-image/marketplace-image.component';

@Component({
    selector: 'marketplace-card',
    templateUrl: './marketplace-card.component.html',
    styleUrls: ['./marketplace-card.component.scss'],
    standalone: true,
    imports: [
        MarketplaceImageComponent,
        NgIf,
        TranslateModule,
    ],
})

export class MarketplaceCardComponent implements OnInit {
    @Input() site: any;
    @Input() isDashboard?: boolean;

    siteImage: string;

    constructor(
        public appService: AppService,
        private marketplaceService: MarketplaceService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.siteImage = this.site.CardImg ? this.site.CardImg : (this.appService.isDesktop() ? this.site.DesktopBgImg : this.site.MobileBgImg);
    }

    redirectToGiftCard() {
        this.marketplaceService.redirectToGiftCard(this.site.AccountGuid);
    }

    openMoreInformation() {
        this.dialog.open(SiteMoreInfoDialogComponent, {
            panelClass: ['gift-it', 'fullscreen-no-background-dialog'],
            data: { giftCardSite: this.site },
            disableClose: true
        });
    }
}

