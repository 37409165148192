<form (ngSubmit)="_signIn(signInForm)" #signInForm="ngForm">
    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
        <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div class="title" tabindex="0">{{'MESSAGES.sign_in_by_phone_message' | translate}}</div>
            <div class="subtitle" tabindex="0">{{'MESSAGES.sign_in_by_phone_description' | translate}}</div>

            <!-- International phone input field -->
            <mat-form-field *ngIf="internationalPhoneInput" class="full-width" appearance="outline">
                <mat-label> {{'phone' | translate}} </mat-label>
                <app-intl-tel-input
                    [preferredCountries]="local.phonePreferredCountries"
                    class="phone-field"
                    matInput
                    [(ngModel)]="formModel.phone" 
                    [forceChangeEventOnFirstLoad]="!!formModel.phone"
                    [enablePlaceholder]="true"
                    [enableSearch]="true"
                    #phone="ngModel"
                    required
                    format="national"
                    name="phone"
                    [searchPlaceholder]="'search' | translate"
                    [formPhone]="formModel.phone">
                </app-intl-tel-input>
                <mat-error *ngIf="phone.invalid && phone.touched">{{'MESSAGES.SIGNIN_VALIDATION.incorrect-phone-number' | translate}}</mat-error>
            </mat-form-field>

            <!-- Regular phone input field -->
            <mat-form-field *ngIf="!internationalPhoneInput" appearance="outline">
                <mat-label> {{'phone' | translate}}</mat-label>
                <input autocomplete="off" 
                    matInput
                    [readonly]="disabled"
                    [(ngModel)]="formModel.phone"
                    name="phone" 
                    type="tel"
                    #phone="ngModel"
                    required
                    class="ltr-field">
                <mat-icon matSuffix (click)="formModel.phone = null" *ngIf="formModel.phone">close</mat-icon>
                <mat-error *ngIf="phone.invalid && phone.touched">{{'MESSAGES.SIGNIN_VALIDATION.incorrect-phone-number' | translate}}</mat-error>
            </mat-form-field> 

            <ng-container>
                <button *ngIf="!disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{'continue' | translate}}</button>
                <button *ngIf="disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                    <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </ng-container>

            <div class="back-btn pt-2" *ngIf="!dialogMode">
                <button type="button" class="btn btn-lg btn-link wl-ignore-theme" (click)="_back()">
                    &lsaquo; {{'back' | translate}}
                </button>
            </div>

        </div>
    </div>
</form>