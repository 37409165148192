import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';

@Component({
    selector: 'to-room-dialog',
    templateUrl: './to-room-dialog.component.html',
    styleUrls: ['./to-room-dialog.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatDialogClose, MatIcon, FormsModule, MatDialogTitle, MatDialogContent, MatFormField, MatInput, MatDialogActions, MatButton, TranslateModule]
})
export class ToRoomDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ToRoomDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    apply() {
        this.dialogRef.close(this.data.roomNumber);
    }
}
