<div class="ngx-mat-tel-input-container">
    <button type="button" mat-button (mousedown)="showCountryDialog(focusable)" [ngClass]="formClass" class="country-selector"
        [class.single]="isSingleCountry" [disabled]="disabled">
        <span class="country-selector-flag flag" [ngClass]="selectedCountry?.flagClass"></span>
        <span class="country-selector-code" *ngIf="selectedCountry?.dialCode">+{{ selectedCountry.dialCode }}</span>
        <span class="arrow" *ngIf="!isSingleCountry"></span>
    </button>
    <mat-menu #menu="matMenu">
        <input *ngIf="enableSearch" class="country-search" [(ngModel)]="searchCriteria" type="text" (click)="$event.stopPropagation()" />
        <button type="button" mat-menu-item class="country-list-button"
            *ngFor="let country of preferredCountriesInDropDown" (click)="onCountrySelect(country, focusable)">
            <div class="icon-wrapper">
                <div class="flag" [ngClass]="country?.flagClass"></div>
            </div>
            <div class="label-wrapper">
                {{ country.name }}
                <span *ngIf="country?.dialCode">+{{ country.dialCode }}</span>
            </div>
        </button>
        <mat-divider *ngIf="preferredCountriesInDropDown?.length"></mat-divider>
        <ng-container *ngFor="let country of allCountries">
            <button type="button" mat-menu-item class="country-list-button" *ngIf="country | search: searchCriteria"
                (click)="onCountrySelect(country, focusable)">
                <div class="icon-wrapper">
                    <div class="flag" [ngClass]="country?.flagClass"></div>
                </div>
                <div class="label-wrapper">
                    {{ country.name }} +{{ country.dialCode }}
                </div>
            </button>
        </ng-container>
    </mat-menu>

    <input matInput type="tel" autocomplete="off" class="intl-input" (blur)="onTouched()"
        (keypress)="onInputKeyPress($event)" [(ngModel)]="phoneNumber" (ngModelChange)="onPhoneNumberChange()"
        [errorStateMatcher]="errorStateMatcher" [placeholder]="inputPlaceholder" [disabled]="disabled" #focusable />
</div>