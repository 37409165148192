import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { AppService } from '../../../app.service';
import { MetaService } from '../../../_core/meta.service';
import { ActivatedRoute } from '@angular/router';
import { OrderTrackerFooterComponent } from './order-tracker-footer/order-tracker-footer.component';
import { OrderTrackerComponent } from '../order-tracker.component';
import { WebAppSmartBannerComponent } from '../../../web-container/web-app-smart-banner/web-app-smart-banner.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'web-order-tracker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './web-order-tracker.component.html',
    styleUrls: ['./web-order-tracker.component.scss'],
    animations: [
        [
            trigger('SmartBannerOpened', [
                state('opened', style({
                    height: '5rem'
                })),
                state('closed', style({
                    height: '0',
                })),
                transition('opened => closed', animate('250ms 0s ease-in-out')),
                transition('closed => opened', animate('250ms 0s ease-in-out'))
            ])
        ],
        [
            trigger('TrakerSectionLower', [
                state('opened', style({
                    top: '5rem'
                })),
                state('closed', style({
                    top: '0',
                })),
                transition('opened => closed', animate('250ms 0s ease-in-out')),
                transition('closed => opened', animate('250ms 0s ease-in-out'))
            ])
        ]
    ],
    standalone: true,
    imports: [NgIf, WebAppSmartBannerComponent, OrderTrackerComponent, OrderTrackerFooterComponent]
})
export class WebOrderTrackerComponent implements OnInit {

    constructor(
        public appService: AppService,
        private metaService: MetaService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO(this.activatedRoute.pathFromRoot);
    }

}
