import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { LoyaltyService } from '../../_core/loyalty.service';
import { ConfigurationsService } from '../../_core/configurations.service';

import { forkJoin, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { BenefitCardComponent } from '../../loyalty/benefit-card/benefit-card.component';
import { MatList } from '@angular/material/list';
import { NgFor, NgIf } from '@angular/common';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'my-benefits',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './my-benefits.component.html',
    styleUrls: ['./my-benefits.component.scss'],
    animations: [
        trigger('spinnerAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s 1s linear', style({ opacity: 1 })),
            ])
        ]),
    ],
    standalone: true,
    imports: [BlockUIModule, MatIconButton, MatIcon, WidgetOpenerComponent, MatTabGroup, MatTab, MatAccordion, NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatList, NgIf, BenefitCardComponent, TranslateModule]
})
export class MyBenefitsComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    public loyaltyOrganizationIds: any[] = [];
    public clubsData: any = [];
    public clubIds: any[] = [];
    public clubNameFromRoute: string = '';
    public domain: any;
    public clubsLoading: boolean = true;

    private domainSubscription: Subscription;

    constructor(
        public appService: AppService,
        public loyaltyService: LoyaltyService,
        public configurationsService: ConfigurationsService,
        private changeDetectorRef: ChangeDetectorRef,
        public ngZone: NgZone,
    ) {}

    ngOnInit() {
        this.blockUI.start();

        this.domainSubscription = this.appService.domain.subscribe(domain => {
            this.domain = domain;
            this.clubIds = this.domain?.clubIds || [];
            if (this.clubIds?.length) {
                this.getClubs();
            } else {
                this.getMultiAccountData();
            }

            this.changeDetectorRef.detectChanges();
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(event => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/profile/my-profile'])
                    });
                });
        }
    }

    ngOnDestroy() {
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
    }

    private getClubs() {
        // To prevent benefitDetails open
        this.appService.isWhiteLabel.next(true);

        const clubRequests = this.clubIds.map(id => this.loyaltyService.getCustomerClub(id));

        forkJoin(clubRequests).subscribe(clubsData => {
            // console.log('=== DASHBAORD/getClubs ===', clubsData);

            this.clubsData = clubsData.filter(clubData => (clubData?.points?.length) || clubData?.benefits?.length).map(club => {

                return {
                    benefits: club.benefits,
                    pointsBenefit: club.benefits.find(benefit => benefit.programBenefitTypeAlias === 'PointsBenefit'),
                    points: club.points,
                    clubName: club.clubName,
                    pointsNickname: club.pointsNickname
                }
            });

            this.blockUI.stop();

            this.changeDetectorRef.detectChanges();
        });

    }

    private getMultiAccountData() {
        // To prevent benefitDetails open on multiAccount
        this.appService.isWhiteLabel.next(false);

        this.loyaltyService.getMultiAccountData().subscribe(multiAccount => {
            // console.log('=== DASHBAORD/multiAccount ===', multiAccount);
            this.clubsData = multiAccount.filter(clubData => (clubData?.points?.length || clubData?.benefits?.length));
            this.changeDetectorRef.detectChanges();
            this.blockUI.stop();
        }, (err: any) => {
            console.error('getMultiAccountData Error: ', err);
            this.blockUI.stop();
        });
    }
}
