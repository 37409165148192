import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { getMapAreas, MAP_AREA } from '../../_core/static/map-areas';
import { Subscription, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocationService } from '../../_core/location.service';
import { TagsService, Tag } from '../../_core/tags.service';
import { SearchDetails } from '../../_core/OrganizationBucket';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDetectScrollDirective } from '../../_core/directives';
import { LocationWidgetComponent } from '../location-widget/location-widget.component';
import { FormsModule } from '@angular/forms';
import { StarRatingComponent } from '../star-rating/star-rating/star-rating.component';
import { MatChip } from '@angular/material/chips';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatFabButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'sites-search-tools',
    templateUrl: './sites-search-tools.component.html',
    styleUrls: ['./sites-search-tools.component.scss'],
    animations: [
        trigger('clearRankAnimation', [
            transition(':enter', [
                style({ display: 'none', opacity: 0 }), // We must use "display:none" and then "display:block" so that when th ngIf kicks-in - the <div> won't appear (with opacity 0) and "push aside" the elements (stars) beside it.
                animate('0.4s 1s ease-in-out', style({ display: 'block', opacity: 1 }))
            ]),
        ]),
        trigger('slideFilters', [
            state('open', style({ transform: 'translateY(0)' })),
            state('close', style({ transform: 'translateY(-100vh)' })),
            transition('close => open', [
                animate('0.4s ease-out')
            ]),
            transition('open => close', [
                animate('0.4s ease-in')
            ]),
        ]),
        trigger('padList', [
            state('open', style({ transform: 'translateY(147px)' })),
            state('close', style({ transform: 'translateY(0)' })),
            transition('close => open', [
                animate('0.4s ease-out')
            ]),
            transition('open => close', [
                animate('0.4s ease-in')
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        NgIf,
        WidgetOpenerComponent,
        MatChip,
        NgFor,
        StarRatingComponent,
        FormsModule,
        LocationWidgetComponent,
        MatFabButton,
        NgxDetectScrollDirective,
        AsyncPipe,
        TranslateModule,
    ],
})
export class SitesSearchToolsComponent implements OnInit, OnDestroy {

    @Input() public type: string;
    @Input() public title: string;
    @Input() public initialSearch: SearchDetails;
    @Input() public customFilterFields: {} = {};
    @Output() private scrollReachedBottom = new EventEmitter<void>();
    @Output() private searchTextCleared: EventEmitter<void> = new EventEmitter();
    @Output() private searchChanged: EventEmitter<any> = new EventEmitter();

    // Banana In A Box:
    @Input() public extendedDeliveryResults: boolean = false;
    @Output() public extendedDeliveryResultsChange: EventEmitter<boolean> = new EventEmitter();

    backIcon: any;
    mapAreas: MAP_AREA[];

    searchQuery: string = '';
    selectedArea: any;
    selectedTags: any[] = [];
    selectedRating: number = 0;
    selectedPrice: number = 0;
    onlyTabit: boolean;
    onlyAvailable: boolean;
    filterChipTimeout: any;

    showFilters: 'open' | 'close' = 'close';
    showScrollUp: boolean = false;

    rating: number = 0;
    chooseRating: boolean = false;

    searchQuerySubject: Subject<string> = new Subject();
    private searchQuerySubsciption: Subscription;
    private baseSubscription: Subscription;

    scrollSubject: Subject<void> = new Subject()
    scrollSubscription: Subscription;
    googlePlaceDetailsSubscription: Subscription;

    scrollDirection: string = 'up';
    scrollIsReachingTop: boolean = false;
    scrollIsReachingBottom: boolean = false;

    public stars = [];
    public prices = [];
    public filterFields: BehaviorSubject<any> = new BehaviorSubject ({
        rating: false,
        price: false,
        onlyTabit: false,
        availability: false,
        area: true,
        tags: true
    });

    groupedTags: { name: string, tagsArray: Tag[] }[] = [];
    tagLabelsMap = {};

    constructor(
        public appService: AppService,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        public organizationsService: OrganizationsService,
        private tagsService: TagsService,
        public ngZone: NgZone,
    ) {
        this.backIcon = this.appService.backIcon;
        this.mapAreas = getMapAreas(this.appService.appConfig.locale);
    }

    ngOnInit() {
        this.searchQuerySubsciption = this.searchQuerySubject.pipe(debounceTime(400)).subscribe(() => this.emitCurrentSearch());
        this.scrollSubscription = this.scrollSubject.pipe(debounceTime(500)).subscribe(() => this.scrollReachedBottom.emit());

        if (this.initialSearch?.query) this.searchQuery = this.initialSearch.query;
        if (this.initialSearch?.tags?.length) {
            this.selectedTags = this.initialSearch.tags;
        }
        if (this.initialSearch?.rating) {
            this.selectedRating = this.initialSearch.rating;
            if (this.selectedRating) {
                this.rating = this.selectedRating;
                this.chooseRating = true;
            }
        }
        if (this.initialSearch && this.initialSearch.price) {
            this.selectedPrice = this.initialSearch.price;
        }

        if (this.initialSearch && this.initialSearch.onlyTabit) {
            this.onlyTabit = this.initialSearch.onlyTabit;
        }

        if (this.initialSearch && this.initialSearch.onlyAvailable) {
            this.onlyAvailable = this.initialSearch.onlyAvailable;
        }

        this.baseSubscription = combineLatest([
            this.locationService.location,
            this.activatedRoute.queryParams,
            this.tagsService.tagsData$,
        ]).subscribe(([location, queryParams, tags]) => {
            if (location.area) {
                let area = this.mapAreas.find(area => area.key === location.area);
                if (area) this.selectedArea = area;
            }
            if (queryParams?.tag) {
                if (this.selectedTags.indexOf(queryParams.tag) < 0) {
                    this.selectedTags.push(queryParams.tag);
                }
            }
            this.groupedTags = this.tagsService.makeGroupedTags(tags || []);
            this.tagLabelsMap = this.tagsService.makeTagLabelsMap(tags || []);
        });

        this.stars = this.appService.starRatings;
        this.prices = this.appService.priceLevels;

        if (this.customFilterFields) {
            this.filterFields.next(Object.assign(this.filterFields.getValue(), this.customFilterFields));
        }

        this.googlePlaceDetailsSubscription = this.appService.googlePlaceDetailsSubject.subscribe(googlePlaceDetails => {
            const details = {
                rating: googlePlaceDetails.showRating,
                price: googlePlaceDetails.showPrice,
            };
            this.filterFields.next(Object.assign(this.filterFields.getValue(), details));
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.navigateBack()
                    });
                });
        }
    }

    ngOnDestroy() {
        this.searchQuerySubsciption.unsubscribe();
        this.scrollSubscription.unsubscribe();
        this.baseSubscription.unsubscribe();
        this.googlePlaceDetailsSubscription.unsubscribe();
    }

    handleScroll(event) {

        this.organizationsService.searchScreenScrollPosition = event.scrollTop;

        this.scrollIsReachingTop = event.isReachingTop;
        this.scrollIsReachingBottom = event.isReachingBottom;

        let direction = event.isReachingTop || event.direction == 'up' ? 'up' : 'down';
        if (direction != this.scrollDirection) this.scrollDirection = direction;

        if (event.isReachingBottom && event.direction === 'down') {
            this.scrollSubject.next();
        }

    }

    isPreviousUrlAboveOrSibling() {
        if (this.appService.previousUrl.match(/(^\/tabit-order\?site)|(^\/book)|(^\/order)|(^\/profile)|(^\/pay)/)) return true;
        return false;
    }

    navigateBack() {
        if (this.isPreviousUrlAboveOrSibling()) {
            this.appService.redirect(['/home/dashboard']);
        }
        else this.appService.goBack();
    }

    navigateToMap() {
        this.appService.redirect(['/home/near-me'])
    }

    toggleFiltersShelf() {
        this.showFilters = this.showFilters === 'open' ? 'close' : 'open';
        if (this.showFilters === 'open') this.appService.scrollToTop('#sites-module-content', 0);
    }

    areaToggle(area: any) {
        if (this.selectedArea && this.selectedArea.key == area.key) {
            this.selectedArea = null;
            if (this.locationService.getActualLocation()) {
                this.locationService.chooseActualLocation();
            } else {
                // Choose default location:
                let defaultArea = this.locationService.getDefaultArea();

                this.locationService.chooseSpecifiedLocation(defaultArea.location, this.appService.translate('areas.' + defaultArea.key), defaultArea.key);
            }
        } else {
            this.selectedArea = area;
            if (!area || !area.key) throw new Error('Cannot pick area, bad area object');
            if (!area.location) throw new Error(`Cannot choose area: '${area.key}' as location`);
            this.locationService.chooseSpecifiedLocation(area.location, this.appService.translate('areas.' + area.key), area.key);
            this.toggleFiltersShelf();
        }
    }

    private emitCurrentSearch() {
        let searchDetails: SearchDetails = {
            query: this.searchQuery,
            tags: this.selectedTags,
            rating: this.selectedRating,
            price: this.selectedPrice,
            externalDeliveryLink: this.extendedDeliveryResults,
            onlyTabit: this.onlyTabit,
            onlyAvailable: this.onlyAvailable
        };
        this.searchChanged.emit(searchDetails);
    }

    tagToggle(tagId: string) {
        let index = this.selectedTags.indexOf(tagId);
        if (index < 0) {
            this.selectedTags.push(tagId);
        } else {
            this.selectedTags.splice(index, 1);
        }

        // Tags search debounce:
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            if (index < 0) this.toggleFiltersShelf();
            this.emitCurrentSearch();
        }, 600);
    }

    ratingToggle(starId: number) {
        this.selectedRating = starId;

        // Rating search debounce:
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            if (starId > 0) this.toggleFiltersShelf();
            this.emitCurrentSearch();
        }, 100);
    }

    priceToggle(priceId: number) {
        this.selectedPrice = this.selectedPrice != priceId? priceId : 0;

        // Prices search debounce:
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            if (this.selectedPrice != 0) this.toggleFiltersShelf();
            this.emitCurrentSearch();
        }, 600);
    }

    tabitToggle(value: boolean) {
        this.onlyTabit = value;

        // Tabit only search debounce:
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            if (this.onlyTabit != false) this.toggleFiltersShelf();
            this.emitCurrentSearch();
        }, 600);
    }

    availabilityToggle(value: boolean) {
        this.onlyAvailable = value;

        // Available only search debounce:
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            if (this.onlyAvailable != false) this.toggleFiltersShelf();
            this.emitCurrentSearch();
        }, 600);
    }

    clearSearchText() {
        this.searchQuery = '';
        this.searchTextCleared.emit();
    }

    scrollToTop(domId: string) {
        this.appService.scrollToTop(domId);
    };

    onSearchQueryInput() {

    }

    updateStars(starId: number) {
        this.chooseRating = true;
        this.rating = starId;
        this.ratingToggle(starId);
    }

    clearRating() {
        this.chooseRating = false;
        this.rating = 0;
        this.ratingToggle(this.rating);
    }

    clearExtendedResults() {
        this.extendedDeliveryResults = false;
        if (this.filterChipTimeout) window.clearTimeout(this.filterChipTimeout);
        this.filterChipTimeout = setTimeout(() => {
            this.extendedDeliveryResultsChange.emit(this.extendedDeliveryResults);
            this.emitCurrentSearch();
        }, 600);
    }

}
