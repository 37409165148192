import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { SiteListsBase } from '../../_core/SiteListsBase';
import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { ORGANIZATION_BUCKET_TYPES, SearchDetails } from '../../_core/OrganizationBucket';
import { WebContainerService } from '../web-container.service';
import { BookService } from '../../_core/book.service';
import { MetaService } from '../../_core/meta.service';
import { EntityService } from '../../_core/entity.service';
import { SiteListMapComponent } from '../../components/site-list-map/site-list-map.component';
import { WebBookHeaderComponent } from '../web-home/web-book-header/web-book-header.component';
import { WebHeaderComponent } from '../web-header/web-header.component';

@Component({
    selector: 'app-web-booking-results',
    templateUrl: './web-booking-results.component.html',
    styleUrls: ['./web-booking-results.component.scss'],
    standalone: true,
    imports: [WebHeaderComponent, WebBookHeaderComponent, SiteListMapComponent]
})
export class WebBookingResultsComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.booking) implements OnInit, OnDestroy {

    private baseSubscription: Subscription;
    private searchSubscription: Subscription;
    private loadMoreSitesSubscription: Subscription;
    private coreSubscription: Subscription;

    private searchInitiatedByUser: boolean = false;

    // public selectedLocationType: string = 'actual';

    constructor(
        public appService: AppService,
        private locationService: LocationService,
        private metaService: MetaService,
        protected organizationsService: OrganizationsService,
        public bookService: BookService,
        public webContainerService: WebContainerService,
        public entityService: EntityService,
        private activatedRoute: ActivatedRoute,
        protected liveAnnouncer: LiveAnnouncer,
    ) { super(); }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        this.searchHook = (searchDetails) => {
            this.bookService.crossOrgSearchDetails = searchDetails;
        };

        this.orgsArrivedHook = (organizations) => {
            this.bookService.crossOrgSearchResult = organizations;
        }

        let bookingSearchType = this.getSearchType();

        this.searchSubscription = this.webContainerService.webSelectionSearchEvent.subscribe((searchState: boolean) => {
            this.searching = searchState;
            this.searchInitiatedByUser = searchState;
        });

        // Subscribing to the loadMoreSites Obserable Subject (which decides that once the web-container reaches the bottom, on MOBILE devices, we need to load more sites)
        this.loadMoreSitesSubscription = this.webContainerService.loadMoreSitesObservable.subscribe(loadMoreSites => loadMoreSites ? this.loadMore() : false);

        this.initialize();

        this.baseSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location,
            this.webContainerService.getSharedSearch(bookingSearchType),
        ]).subscribe(([
            locationInitialized,
            newLocation, // We use the 'newLocation' subscription simply in order to trigger a new search when the location-reset is changed.
            searchDetailsFromShared
        ]: [boolean, LocationLabeled, SearchDetails]) => {
            if (!locationInitialized || !newLocation) return;

            // console.log(' === WEB/BOOKING RESULTS === base subscription searchDetailsFromShared:', searchDetailsFromShared);

            let nextSearchDetails = this.prepareNextSearchDetails(searchDetailsFromShared);

            // If no recent booking search (a.k.a direct link to the booking page)
            if (!nextSearchDetails.booking) {
                // That is terrible. Until the booking service refactoring, it will be here:
                try {
                    nextSearchDetails.booking = this.getBookingDetailsFromBookingService();
                } catch (err) {
                    this.bookService.startWithoutSite();
                    nextSearchDetails.booking = this.getBookingDetailsFromBookingService();
                }
            }

            if (this.orgs.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, bookingSearchType)) {

                this.searching = false;
                this.manageScroll();

            } else {

                this.newSearch(nextSearchDetails, orgsFromSearch => {
                    this.manageScroll();
                });

            }
        });

        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO(this.activatedRoute.pathFromRoot);
    }

    protected manageScroll(): void {

        let bookingSearchType = ORGANIZATION_BUCKET_TYPES.booking;

        // TODO: Bring the timeout to here:

        // When landing on this page from the home-page - we perform a search, so we also need to scroll to the results.
        if (this.organizationsService.getLastVisited(bookingSearchType)) {
            // Scroll to last visited org:
            this.webContainerService.scrollToLastVisitedOrg(this.organizationsService.getLastVisited(bookingSearchType));
        } else {
            // Scrolling the results into View
            this.webContainerService.scrollToResults(this.searchInitiatedByUser && !this.searchingMore && !this.preventSearchMore);
        }
    }

    private getBookingDetailsFromBookingService(): { timestamp: string; seats_count: string; } {
        return {
            timestamp: this.bookService.getCrossOrgsSearchTime().toISOString(),
            seats_count: this.bookService.getCrossOrgsSearchSeatCount().toString()
        };
    }

    siteClick(siteId: string) {
        let site = this.organizationsService.getOrganization(siteId);
        if (!site) return console.error('No site found at loaded organizations:', siteId);
        if (site.time_slots) {
            this.bookService.orgTimeSlots[siteId] = site.time_slots;
        }
        this.organizationsService.setLastVisited(siteId, ORGANIZATION_BUCKET_TYPES.booking);
        this.appService.redirect(['/site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
        this.baseSubscription.unsubscribe();
        this.loadMoreSitesSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        this.cleanUp();
    }
}
