import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';

import { AppService } from '../../../../app.service';
import { EntityService } from '../../../../_core/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'app-passorder-dialog',
    templateUrl: './pass-order-dialog.component.html',
    styleUrls: ['./pass-order-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatIcon, MatDialogContent, BlockUIModule, FormsModule, MatFormField, MatInput, NgIf, MatSuffix, MatButton, QRCodeModule, TranslateModule]
})
export class PassOrderDialogComponent {
	@BlockUI() blockUI: NgBlockUI;
	qrCodeUrl: String = null;

	constructor(
		public appService: AppService,
		public entityService: EntityService,
		public dialogRef: MatDialogRef<PassOrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
        this.qrCodeUrl = this.data.link.directUrl;
        if (this.data.forcePrices) this.qrCodeUrl = `${this.qrCodeUrl}&price=true`;
	}

	formModel: any = {}
	passByPhone(form) {
		if (!form.valid) return;
		this.blockUI.start();
		this.entityService.post(`/online-shopper/orders/${this.data.orderId}/share`, this.formModel, { 'ros-organization': this.data.organization }).then(res => {
			this.appService.toastr('PASS_ORDER_SENT_SUCCESS', { type: 'success' });
			this.dialogRef.close();
		}).catch(err => {
			this.appService.toastr('CANNOT_SEND_ORDER_BY_SMS', { type: 'error' });
		}).then(ret => {
			this.blockUI.stop();
		});
	}

	cancel() {
		this.dialogRef.close();
	}

}
