import { BehaviorSubject } from 'rxjs';
import { AfterViewInit } from '@angular/core';
import {Component, OnInit} from '@angular/core';
import { AppService } from '../../app.service';

import { SpecialMessage, SpecialMessagesService } from '../../_core/special-messages.service';

@Component({
  selector: 'special-messages',
  templateUrl: './special-messages.component.html',
  styleUrls: ['./special-messages.component.scss']
})
export class SpecialMessagesComponent implements OnInit {
    constructor(
        public specialMessagesService: SpecialMessagesService,
        public appService: AppService,
    ) { }

    ngOnInit(): void {}

    closeMessage(message: SpecialMessage, ev?) {
        if (ev) ev.stopPropagation();

        message.remove = true;
        setTimeout(() => {
            this.specialMessagesService.removeMessage(message);
        }, 600);
    }

    getColorFromType(type) {
        switch(type) {
            case 'info':
                return '#3cb3e6 !important';
            case 'warning':
                return '#ef9a45 !important';
            case 'alert':
                return '#fb6565 !important';
            case 'promo':
                return '#b6a9f9 !important';
            case 'success':
                return 'var(--primary-theme-500) !important';
            case 'plain':
                return '#000 !important';
        }
    }

    getMessageText(message: SpecialMessage): string {
        return this.appService.isOriginLanguage ? message.message : (message?.translations?.[this.appService.locale.value]?.message || message.message);
    }

}
