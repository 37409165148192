import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../../../_core/SiteListsBase';
import { OrganizationsService } from '../../../_core/organizations.service';
import { SearchDetails, ORGANIZATION_BUCKET_TYPES } from '../../../_core/OrganizationBucket';
import { AppService } from '../../../app.service';
import { LocationService } from '../../../_core/location.service';
import { EntityService } from '../../../_core/entity.service';
import { BookService } from '../../../_core/book.service';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SiteItemComponent } from '../../../components/site-item/site-item.component';
import { MatList } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';
import { SitesSearchToolsComponent } from '../../../components/sites-search-tools/sites-search-tools.component';

declare const $: any; // For the scroll we are using jQuery

@Component({
    selector: 'app-tabitbook-choose-site',
    templateUrl: './tabitbook-choose-site.component.html',
    styleUrls: ['./tabitbook-choose-site.component.scss'],
    standalone: true,
    imports: [SitesSearchToolsComponent, NgIf, MatList, NgFor, SiteItemComponent, MatProgressSpinner, TranslateModule]
})
export class TabitbookChooseSiteComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.booking) implements OnInit, OnDestroy {

    private mainLoadingSubscription: Subscription;
    private coreSubscription: Subscription;

    constructor(
        protected appService: AppService,
        private locationService: LocationService,
        private bookService: BookService,
        private entityService: EntityService,
        protected organizationsService: OrganizationsService,
        protected liveAnnouncer: LiveAnnouncer,
    ) { super(); }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        this.searchHook = (searchDetails) => {
            this.bookService.crossOrgSearchDetails = searchDetails;
        };

        this.orgsArrivedHook = (organizations) => {
            this.bookService.crossOrgSearchResult = organizations;
        }

        // let bookingSearchType = ORGANIZATION_BUCKET_TYPES.booking;

        this.initialize();

        this.mainLoadingSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location
        ]).subscribe(([locationInitialized, newLocation]) => {
            if (!locationInitialized || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails();

            nextSearchDetails.booking = this.getCurrentBookingDetails();

            this.bookService.crossOrgSearchDetails = nextSearchDetails;

            //! we decided to renew the search results every time the search-page is loaded (also solves TAB-19375)
            // if (this.orgs.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, bookingSearchType)) {
            //     this.searching = false;
            //     this.manageScroll();
            // } else ...
            this.newSearch(nextSearchDetails, () => {
                // this.manageScroll();
            });

        });
    }

    ngOnDestroy() {
        this.coreSubscription.unsubscribe();
        this.mainLoadingSubscription.unsubscribe();
        this.cleanUp();
    }

    searchChanged(newSearchDetails: SearchDetails) {
        // console.log('=== TABITBOOK/CHOOSE/SITE === Search changed to:', newSearchDetails);
        let searchDetails: SearchDetails = { ...newSearchDetails };
        searchDetails.booking = this.getCurrentBookingDetails();
        this.newSearch(searchDetails);
    }

    siteClick(site) {
        this.organizationsService.searchScreenNeedsScroll = true;
        this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    private getCurrentBookingDetails(): SearchDetails['booking'] {

        let booking: SearchDetails['booking'];

        if (this.bookService.crossOrgSearchDetails && this.bookService.crossOrgSearchDetails.booking) {
            booking = this.bookService.crossOrgSearchDetails.booking;
        }

        // That is terrible. Until the booking service refactoring, it will be here:
        if (!booking) {
            try {
                booking = this.getBookingDetailsFromBookingService();
            } catch (err) {
                this.bookService.startWithoutSite();
                booking = this.getBookingDetailsFromBookingService();
            }
        }

        return booking;
    }

    private getBookingDetailsFromBookingService(): { timestamp: string; seats_count: string; } {
        return {
            timestamp: this.bookService.getCrossOrgsSearchTime().toISOString(),
            seats_count: this.bookService.getCrossOrgsSearchSeatCount().toString()
        };
    }

}
