import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';

import { each, get } from 'lodash-es';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-bill-dialog',
    templateUrl: './bill-dialog.component.html',
    styleUrls: ['./bill-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, BlockUIModule, DatePipe]
})
export class BillDialogComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;
	loading: boolean = true;
	constructor(
		public appService: AppService,
		public entityService: EntityService,
		public dialogRef: MatDialogRef<BillDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,

	) {

	}

    order: null;

	ngOnInit() {
		this.blockUI.start();
        this.entityService.getOrderBill(this.data).then(response => {
            this.prepareFixedOrder(response);
            this.loading = false;
        }).catch(o => {
            this.appService.toastr('MESSAGES.error_getting_bill', {type:'error'});
            this.dialogRef.close();
        }).then(o => {
			this.blockUI.stop();
		})
	}

    prepareFixedOrder(printData) {
        let newOrder: any = {
            printData: printData
        };
        switch (printData.variables.ORDER_TYPE) {
            case "TAKEAWAY":
                printData.$orderer = printData.variables.ORDERER_NAME;
                break;
            case "DELIVERY":
                newOrder.isDelivery = true;
                let arrAddress = [];
                each(['ORDERER_ADDRESS_STREET', 'ORDERER_ADDRESS_HOUSE', 'ORDERER_ADDRESS_CITY'], function (att) {
                    var v = printData.variables[att];
                    if (v) {
                        if (att == 'ORDERER_ADDRESS_CITY' && arrAddress.length) v = ", " + v;
                        arrAddress.push(v);
                    }
                });
                printData.$deliveryAddress = arrAddress.join(" ");
                printData.$orderer = printData.variables.ORDERER_NAME;
                newOrder.address = {
                    formatted_address: printData.$deliveryAddress,
                    entrance: printData.variables.ORDERER_ADDRESS_ENTRANCE,
                    floor: printData.variables.ORDERER_ADDRESS_FLOOR,
                    apartment: printData.variables.ORDERER_ADDRESS_APARTMENT
                }
                break
        }

        //prepare fixed order print data
        var printOffers = get(printData, 'collections.ORDERED_OFFERS_LIST', []);
        each(printOffers, function (pOffer) {
            var items = pOffer.ALL_ORDERED_ITEMS;
            each(items, function (pItem) {
                var pMods = pItem.MODIFIERS_LIST;
                var hasMods = false;
                var hasModsExclude = false;
                each(pMods, function (pMod) {
                    pMod._show = false;
                    if (pMod.EXCLUDED) {
                        hasModsExclude = true;
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                    } else if (!pMod.IS_DEFAULT_SELECTED && !pMod.FORMATION) {
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                        hasMods = true;
                    } else if (pMod.MODIFIER_PRICE && pMod.MODIFIER_PRICE > 0) {
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                        hasMods = true;
                    }
                });
                pItem._showItem = false;
                if (hasMods) {
                    pItem._hasMods = true;
                };
                if (hasModsExclude) {
                    pItem._hasModsExclude = true;
                };
                if (hasMods || hasModsExclude || pItem.IS_LEADING_ITEM != "1") {
                    pItem._showItem = true;
                };
            });
        });
        this.order = newOrder;

    };


}
