import { Injectable } from "@angular/core";
import { Subject, interval } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class TimerUtilsService {

    // Start a timer and return the remaining time
    public static startCountdownTimer(duration: number, tick = 1000, destroy$: Subject<any> = new Subject()) {
        // duration = 10; // for tests, will be removed
        return (
            interval(tick)
                .pipe(
                    takeUntil(destroy$),
                    take(duration + 1),
                    map((value) => duration - value))
        );
    }
}
