import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose, MatDialogActions } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LottieComponent } from 'ngx-lottie';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-animation-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './animation-dialog.component.html',
    styleUrls: ['./animation-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogClose, NgIf, MatButton, LottieComponent, MatDialogActions, TranslateModule]
})
export class AnimationDialogComponent {
	mode: string = 'alert';
	constructor(
		public dialogRef: MatDialogRef<AnimationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data.audio) {
			var audio = new Audio(this.data.audio);
			audio.play();
		}
	}

}
