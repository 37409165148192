import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { GiftCardsService, loyaltyGiftCardSettings } from '../../gift-cards/gift-cards.service';
import { SafeStylePipe } from '../../_core/pipes';

@Component({
    selector: 'promotion-card',
    templateUrl: './promotion-card.component.html',
    styleUrls: ['./promotion-card.component.scss'],
    standalone: true,
    imports: [SafeStylePipe]
})
export class PromotionCardComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;
    @Input() link: any;

    private _subscriptions: Subscription[] = [];
    public gcsConfig: any;
    public url;
    public image;

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        private giftCardsService: GiftCardsService,
    ) { }

    ngOnInit() {
        if (this.serviceId) this.subscribeToGCSData();
        this.setImage();
    }

    subscribeToGCSData() {
        const dataSubscription = this.giftCardsService.getGiftCardsConfig(this.serviceId)
        .pipe(finalize(() => this._subscriptions.push(dataSubscription)))
        .subscribe((config: loyaltyGiftCardSettings) => {
            this.gcsConfig = config;
        });
    }

    setImage() {
        // For GCS Card
        if (this.serviceId) {
            return this.gcsConfig?.configuration?.images?.logoPath || this.gcsConfig?.configuration?.images?.summaryImagePath;
        }
        // For External Card Link
        if (this.link) return this.link.image;
    }

    getTitle() {
        if (this.serviceId) return this.appService.translate('GIFT_CARD_SHOP.general.gift_card_purchase');
        if (this.link) return this.link.title;
    }

    ngOnDestroy() {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

}
