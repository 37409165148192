import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { DialogsService } from '../_core/dialogs.service';
import { LocationService, LocationLabeled } from '../_core/location.service';
import { OrganizationsService, ServicesActiveState } from '../_core/organizations.service';
import { MetaService } from '../_core/meta.service';
import { EntityUtilsService } from '../_core/entity.utils.service';

import moment from 'moment';
import { SafeStylePipe } from '../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { WebFooterComponent } from '../web-container/web-footer/web-footer.component';
import { SiteInfoTabsComponent } from './site-info-tabs/site-info-tabs.component';
import { SiteLinksComponent } from './site-links/site-links.component';
import { SiteActionsComponent } from '../components/site-actions/site-actions.component';
import { SiteTitleDescComponent } from './site-title-desc/site-title-desc.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { WebSeoHeaderComponent } from '../web-container/web-seo-header/web-seo-header.component';

declare const $: any;

@Component({
    selector: 'web-site-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './web-site-details.component.html',
    styleUrls: ['./web-site-details.component.scss'],
    standalone: true,
    imports: [WebSeoHeaderComponent, NgIf, SiteTitleDescComponent, SiteActionsComponent, SiteLinksComponent, SiteInfoTabsComponent, WebFooterComponent, AsyncPipe, TranslateModule, SafeStylePipe]
})
export class WebSiteDetailsComponent implements OnInit, OnDestroy {
    appConfig: any = environment.appConfig;

    site: any;
    sitePhotos: any[] = [];
    siteActionsActiveState: ServicesActiveState;
    largeImage: string;

    private scrollToMenus: boolean = false;

    private mainSubscription: Subscription;
    private siteSubscription: Subscription;
    private urlSubscription: Subscription;

    public tabitReviews: any = [];

    constructor(
        public appService: AppService,
        public entityUtilsService: EntityUtilsService,
        public titleService: Title,
        private activatedRoute: ActivatedRoute,
        private dialogsService: DialogsService,
        private locationService: LocationService,
        private organizationService: OrganizationsService,
        private metaService: MetaService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.largeImage = this.appService.images.default_site_image;
    }

    ngOnInit() {
        this.urlSubscription = this.activatedRoute.pathFromRoot[0].queryParams.subscribe(params => {
            if (params?.tab == 'menus') {
                this.scrollToMenus = true;
                this.changeDetectorRef.detectChanges();
            }
        });

        this.mainSubscription = combineLatest([
            this.activatedRoute.params,
            this.locationService.location
        ]).subscribe(([ params, locationLabeled ]: [ Params, LocationLabeled ]) => {
            console.debug('=== SITE/DETAILS === News, params:', params, 'locationLabeled:', locationLabeled);
            let orgHandle = params.id;

            let org = this.organizationService.getOrganizationWithHandle(orgHandle);

            if (org) {
                // console.log('=== SITE/DETAILS === updating org with already existing one! orgHandle:', orgHandle);
                this.syncStateWithOrganization(org);
                this.organizationService.fullOrganization(orgHandle).subscribe(org => {
                    this.changeDetectorRef.detectChanges();
                }, err => this.handleFullOrgError(err));
            } else {
                // console.log('=== SITE/DETAILS === First founding one... orgHandle:', orgHandle);
                this.organizationService.fullOrganization(orgHandle).subscribe(org => {
                    // console.log('=== SITE/DETAILS === updating org with found now one! orgHandle:', orgHandle);
                    this.syncStateWithOrganization(org);
                    this.changeDetectorRef.detectChanges();
                }, err => this.handleFullOrgError(err));
            }
        });
    }

    private syncStateWithOrganization(org: any): void {

        // Initial site:
        this.updateStateWithSite(org, 'initial');

        let siteId = org._id;

        if (this.scrollToMenus) {
            this.scrollToMenus = false; // So that when syncStateWithOrganization is called again - we won't scroll again

            setTimeout(() => {
                if (document.querySelector('.mat-mdc-tab-labels') && $('.mobile-web-tabs-header-container') && $('.mobile-web-tabs-header-container').outerHeight() > 0) {
                    // First we scroll the main body to the very top (otherwise the main body top might have an offset for some reason on Safari Mobile)
                    window.scrollTo(0, 0);

                    // We scroll to the Menus only in Mobile
                    let menusTopPosition: number = document.querySelector('.mat-mdc-tab-labels').getBoundingClientRect().top;
                    let headerHeight: number = $('.mobile-web-tabs-header-container').outerHeight();
                    let scrollTopValue = menusTopPosition - headerHeight;
                    $('.web-container-inner-wrapper').animate({ scrollTop: scrollTopValue }, 400);

                    this.changeDetectorRef.detectChanges();
                }
            }, 200); // Required in order to let the browser render first > "breathe" > and then scroll
            // Note: We do NOT use scrollIntoView because it causes issues with the top part
            /*
            setTimeout(() => {
                document.querySelector('.mat-mdc-tab-labels').scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 2000); // Required in order to prevent main body scroll issues (top header overlaps content)
            */
        }

        if (this.siteSubscription) this.siteSubscription.unsubscribe();
        this.siteSubscription = this.organizationService.specificOrgChange(siteId).subscribe(organization => {
            // console.log('=== SITE/DETAILS === Got Organization:', organization);
            this.updateStateWithSite(organization, 'update');

            this.changeDetectorRef.detectChanges();
        });

    }

    ngOnDestroy() {
        this.metaService.removeSeoScript();
        this.mainSubscription.unsubscribe();
        this.urlSubscription.unsubscribe();

        // In case of 404 error or no-org error there will be no subscription to it:
        if (this.siteSubscription) this.siteSubscription.unsubscribe();

    }

    private updateStateWithSite(site: any, from: string) {
        if (!site) return;

        this.site = site;
        if (this.site.largeImage) this.largeImage = this.site.largeImage;

        this.makePhotos();
        if (this.site?.tabitReviews?.length) this.prepareTabitReviews();

        this.changeDetectorRef.detectChanges();
    }

    private makePhotos() {
        this.sitePhotos = this.getPhotosFromSite();
    }

    private getPhotosFromSite() {
        let photos = [];
        // Images from googlePlaceDetails
        // if (this.site?.googlePlaceDetails?.photos) {
        //     this.site.googlePlaceDetails.photos.forEach(image => {
        //         const tempImage = {
        //             photo_url: image.photo_url,
        //         }
        //         photos.push(tempImage);
        //     });
        // }

        // Images from sitePageDetails
        const siteDetailsPage = this.site.siteDetailsPage;
        if (siteDetailsPage?.images?.length) {
            siteDetailsPage?.images.forEach((image, index) => {
                // Only if there is an actual URL, and not ''
                if (image.url) {
                    const tempImage = {
                        photo_url: image.url,
                        alt: image?.alt,
                        index,
                    }
                    photos.push(tempImage);
                }
            });
        }

        return photos;
    }

    openGallery(index: number) {
        if (!this.sitePhotos?.length) return;

        const galleryPhotos = this.sitePhotos;

        this.dialogsService.showGalleryDialog({ images: galleryPhotos, index });
    }

    private handleFullOrgError(err: any){

        // TODO: Display some 404?

        // TODO: Better way to check if it's a network error:
        if (!err.headers) return console.error('Error with site details:', err);

        const urlIdentifierRedirect = this.appService.urlIdentifierRedirectFromError(err);
        if (urlIdentifierRedirect) {
            this.appService.redirect(['/site', urlIdentifierRedirect], { replaceUrl: true });
        } else {
            console.error('Error loading site details:', err);
        }
    }

    private prepareTabitReviews() {
        // Prepare animation path
        this.site.tabitReviews.forEach(tabitReview => {
            switch (tabitReview.review.rating) {
                case 5: tabitReview.review.animationPath = this.appService.base('/assets/animations/perfect-review.json'); break;
                case 4: tabitReview.review.animationPath = this.appService.base('/assets/animations/ok-review.json'); break;
                case 3: tabitReview.review.animationPath = this.appService.base('/assets/animations/plausible-review.json'); break;
                case 2: tabitReview.review.animationPath = this.appService.base('/assets/animations/expected-more-review.json'); break;
                case 1: tabitReview.review.animationPath = this.appService.base('/assets/animations/sad-review.json'); break;
                default: tabitReview.review.animationPath = '';
            }
        })

        // Prepare date
        this.site.tabitReviews.forEach(tabitReview => {
            let formattedDate = moment(tabitReview.created).format('LLL');
            tabitReview.relative_description_date = moment(formattedDate,'LLL').fromNow();
        })
    }
}
