

<div class="close-button-container">
    <button aria-label="close" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
        <mat-icon>close</mat-icon>
    </button>
</div>

<form (ngSubmit)="apply()" #challengeForm="ngForm">
    <h1 mat-dialog-title class="d-flex align-items-center">
        <span class="flex-grow-1 text-truncate">{{'CHHOSE_ROOM_NO' | translate}}</span>
    </h1>

    <div mat-dialog-content>
        <mat-form-field color="accent" class="w-100">
            <input matInput [placeholder]="'ROOM_NO' | translate" type="text" [(ngModel)]="data.roomNumber" name="room" required>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="text-center">
        <button type="submit" [disabled]="!challengeForm.valid" mat-flat-button color="primary" class="rounded lg block mb-3">
            {{('APPROVE') | translate}}
        </button>
    </div>
</form>