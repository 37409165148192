import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { trigger, style, animate, transition } from '@angular/animations';

import { AppService } from '../../app.service'
import { OrganizationsService } from '../../_core/organizations.service';
import { LoyaltyService } from '../../_core/loyalty.service';

import { forkJoin, BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import moment from 'moment';
import { get } from 'lodash-es';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { LottieComponent } from 'ngx-lottie';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'benefit-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './benefit-details.component.html',
    styleUrls: ['./benefit-details.component.scss'],
    animations: [
        trigger('sitesAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1 })),
            ])
        ]),
        trigger('spinnerAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1 })),
            ])
        ]),
    ],
    standalone: true,
    imports: [NgIf, LottieComponent, MatButton, MatDialogClose, MatIcon, NgFor, DatePipe, TranslateModule, SafeStylePipe]
})
export class BenefitDetailsComponent implements OnInit {

    public sites: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public unit: string = this.appService.getUnitByLocale();
    public club: any;
    public benefit: any;
    public details: any = {
        description: '',
        headerForApp: '',
        messageForApp: '',
        orderTypes: []
    };
    public additional_description: string;
    public limitations: string;
    public icon: string;
    public showBenefitAnimation: boolean = false;
    public showPointAnimation: boolean = false;
    public showDate: boolean = true;
    public loadingSites: boolean = true;
    public currencySign: string;
    public pointsNickname: string;

    public type: string;

    public benefitAnimation = this.appService.getAnimationPath('confetti');
    public pointsAnimation = this.appService.getAnimationPath('money-benefit');

    constructor(
        public dialogRef: MatDialogRef<BenefitDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appService: AppService,
        public loyaltyService: LoyaltyService,
        private changeDetectorRef: ChangeDetectorRef,
        private organizationsService: OrganizationsService,
    ) { }

    ngOnInit() {
        // console.log('=== BENEFIT-DETAILS/COMPONENT === DATA:', this.data);
        this.type = (this.data.points) ? 'Points' : this.data.benefit.programBenefitTypeAlias;
        this.showBenefitAnimation = (this.type === 'BirthdayBenefit' || this.type === 'JoinBenefit' || this.type === 'RenewBenefit') ? true: false;
        this.showPointAnimation = (this.type === 'Points' || this.type === 'PointsBenefit') ? true: false;
        this.showDate = this.data?.benefit?.moreInfo?.expired && moment(this.data?.benefit?.moreInfo?.expired).year() >= 1970;
        this.setCurrencyForPrePaid();
        this.setDescription();
        this.setIcon();
        this.setPointsName();
        this.setSites();
    }

    setTitleColor() {
        if (this.data.prePaid) return '#EFF0FA';

        switch (this.type) {
            case "Points":
            case "PointsBenefit":
                return '#ACECE5';
            case "BirthdayBenefit":
                return '#CBE5F6';
            case "AnniversaryBenefit":
                return '#F9EEC8';
            case "JoinBenefit":
            case "RenewBenefit":
                return '#FFD8C2';
            case "Vouchers":
                return '#D6D9EA';
            case "PunchCard":
                return '#F0FEF5';
            case "Other":
            default:
                return '#EFF0FA';
        }
    }

    setAdditionalTitleColor() {
        if (this.data.prePaid) return '#B1B2E7';

        switch (this.type) {
            case "Points":
            case "PointsBenefit":
                return '#60d9db';
            case "BirthdayBenefit":
                return '#53A9DF';
            case "AnniversaryBenefit":
                return '#FFD455';
            case "JoinBenefit":
            case "RenewBenefit":
                return '#FFB185';
            case "Vouchers":
                return '#7882BA';
            case "PunchCard":
                return '#BDF9D4';
            case "Other":
            default:
                return '#B1B2E7';
        }
    }

    setSites() {
        const sitesIds = this.data?.benefit?.moreInfo?.sites ? this.data.benefit.moreInfo.sites.map(site => site.siteId) : [];
        if (false) { // 27.12.2021 - Decided by Idan to depracate for now
            const filteredSites = sitesIds.filter(id => id).slice(0, 10);
            // We always want only the first 10 to prevent network overload
            this.getSitesData(filteredSites);
        } else {
            this.loadingSites = false;
            this.sites.next([]);
        }
    }

    closeAnimation() {
        this.showBenefitAnimation = false;
        this.showPointAnimation = false;
        this.changeDetectorRef.detectChanges();
    }

    getIconByType(type: string): string {
        let icon = '';

        switch (type) {
            case "seated":
                icon = 'brand-site-book';
                break;
            case "takeaway":
                icon = 'brand-site-order-ta';
                break;
            case "order":
                icon = 'brand-site-order-delivery';
                break;
        }

        return icon;
    }

    private getSitesData(sitesIds) {
        forkJoin([
            ...sitesIds.map(id => this.organizationsService.getFullOrganization(id).pipe(
                catchError(err => of(err)
            )))
        ]).subscribe(
            next => {
                this.sites.next(next);
                this.loadingSites = false;
            },
            error => {
                console.error('Error while retrieving sites from server', error);
                this.sites.next([]);
                this.loadingSites = false;
            }
        );
    }

    private setDescription() {
        if (!this.data.points) {
            this.details.headerForApp = this.data.benefit.moreInfo?.headerForApp ?  this.data.benefit.moreInfo?.headerForApp 
                                                                                    : 
                                                                                    this.appService.translate(`BENEFIT_TYPE.${this.type}`);
            this.details.description = this.data.benefit?.description || '';
            this.details.messageForApp = this.data.benefit.moreInfo?.messageForApp;
            this.details.orderTypes = this.getOrderTypes();
        }
        if (this.data.points) {
            this.details.headerForApp = this.data.prePaid ? this.appService.translate('prePaid_realization') : this.appService.translate('immediate_realization');
            this.details.description = this.data.prePaid ? this.setPrepaidDescription() : (this.data.benefit?.description ? this.data.benefit?.description : this.appService.translate('your_points_worth'));
            this.details.messageForApp = this.data.prePaid ? '' : this.data.benefit?.moreInfo?.messageForApp;
        }
    }

    private setPrepaidDescription(): string {
        if (this.loyaltyService.topUpEnabled()) return this.appService.translate('pre_paid_top_up');
        return this.appService.translate('pre_paid_available');
    }

    private getOrderTypes(): any {
        let orderTypes = ['seated', 'takeaway', 'order'];
        if (this.data.benefit?.moreInfo?.orderTypes) orderTypes = this.data.benefit?.moreInfo?.orderTypes;
        return orderTypes;
    }

    private setIcon() {
        switch (this.type) {
            case "Points":
                this.icon = this.appService.base(`assets/images/${this.data.prePaid ? 'vouchers_details' : 'points_details'}.png`);
                break;
            case "PointsBenefit":
                this.icon = this.appService.base('assets/images/points_details.png');
                break;
            case "BirthdayBenefit":
                this.icon = this.appService.base('assets/images/birthday_details.png');
                break;
            case "AnniversaryBenefit":
                this.icon = this.appService.base('assets/images/mariage_details.png');
                break;
            case "JoinBenefit":
            case "RenewBenefit":
                this.icon = this.appService.base('assets/images/join_details.png');
                break;
            case "PunchCard":
                this.icon = this.appService.base('assets/images/punch-card.png');
                break;
            case "Vouchers":
            case "Other":
            default:
                this.icon = this.appService.base('assets/images/other_details.png');
                break;
        }
    }

    private setCurrencyForPrePaid() {
        this.currencySign = this.appService.appConfig.locale === 'he-IL' ? this.appService.currency : this.appService.us_currency;
    }

    private setPointsName() {
        if (!this.data.points) return;
        this.pointsNickname = get(this.data, 'pointsNickname', this.appService.translate('points'));
    }

}
