import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../../../app/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'footer-links-button',
    templateUrl: './footer-links-button.component.html',
    styleUrls: ['./footer-links-button.component.scss'],
    standalone: true,
    imports: [MatIcon, TranslateModule]
})
export class FooterLinksButtonComponent implements OnInit {

    @Input() buttonName: string;
    @Output() private redirect = new EventEmitter<void>();
    translatedName: string = '';
    linkIcon: string = '';

    constructor(
        public appService: AppService
    ) { }

    ngOnInit(): void {
        this.translatedName = 'SERVICE_BUTTON.' + this.buttonName;
        this.linkIcon = 'brand-footer-' + this.buttonName;
        if (this.buttonName == 'profile') this.linkIcon = this.appService.showNotifyIcon() ? 'brand-footer-' + this.buttonName + '-notify' : 'brand-footer-' + this.buttonName;
    }

    redirectOnClick(page) {
        this.redirect.emit(page);
    }

    public checkActiveRoute(verifyRoute: string) {
        if (RegExp(`(^\/|-).*${verifyRoute}`).test(this.appService.currentUrl)) {
            return true;
        } else {
            return false;
        }
    }
}
