import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-to-image-dialog',
    templateUrl: './to-image-dialog.component.html',
    styleUrls: ['./to-image-dialog.component.scss'],
    standalone: true
})
export class ToImageDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ToImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
  }

}
