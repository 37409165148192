import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppService } from '../../../app.service';

import { get, find } from 'lodash-es';
import { DefaultCurrencyPipe } from '../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MatIcon, TranslateModule, DefaultCurrencyPipe]
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
    @Input() order: any;
    @Input() org: any;

    public totalAmount: number;
    public balance: number;
    public cashBalance: number;
    public orderedItems: any;
    public ordersFromLocalStorage: any;
    public basket: any = [];
    public orderBenefits: any;
    public benefitsValue: number = 0;
    public discountsValue: number = 0;
    public remainingAvailableDiscount: number = 0;
    public discountsCounter: number = 0;
    public pointsUsed: number = 0;
    public tip: number;
    public exclusiveTaxes: number = 0;
    public inclusiveTaxes: number = 0;
    public taxes: number;
    public fees: number = 0;
    public deliveryFee: number;
    public showTax: boolean = false;
    public orderFromStorage: boolean = false;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
        this.getOrderData();
        this.prepDataForDisplay();
    }

    ngOnDestroy() { }

    private getOrderData(): void {
        // First we check if there is data in the localStorage
        const localStorageData = this.appService.validateLocalStorageData('state__customer_orders');
        this.ordersFromLocalStorage = JSON.parse(localStorageData || '[]');

        const orderFromLocalStorage = this.ordersFromLocalStorage.find(order => order._id === this.order._id);

        if (orderFromLocalStorage) {
            this.setOrderDataFromLocalStorage(orderFromLocalStorage);
        } else {
            this.setItemsList();
        }
    }

    private setOrderDataFromLocalStorage(order) {
        this.orderFromStorage = true;
        this.basket = order.basket || {};
    }

    private calculateBenefitsValue() {
        this.orderBenefits = this.order.orderedPromotions.find(promotion => promotion.pointsUsed == 0);
        if (this.orderBenefits || this.orderBenefits?.length) {
            if (Array.isArray(this.orderBenefits)) {
                this.orderBenefits.forEach(benefit => {
                    this.benefitsValue += benefit.suggestedAmount / 100;
                });
            } else {
                this.benefitsValue += this.orderBenefits.suggestedAmount / 100;
            }
        }
    }

    private prepDataForDisplay() {
        this.tip = this.getTip();
        this.getTaxes();
        this.getFees();
        this.setAmounts();

        if (this.order?.orderedPromotions?.length) {
            this.calculatePoints();
            this.calculateBenefitsValue();
        }
        if (this.order?.orderedDiscounts?.length) this.calculateDiscountsValue();
    }

    private getTip() {
        let tip = 0;
        if (this.order?.paymentSummary?.cashback > 0 || this.order?.totals?.totalTips > 0) {
            tip = ( (get(this.order, 'paymentSummary.cashback', 0) + get(this.order, 'totals.totalTips', 0)) / 100);
            return tip;
        } else if (this.order?.paymentSummary?.totalAuxAmount > 0) {
            tip = this.order?.paymentSummary?.totalAuxAmount / 100;
            return tip;
        }
        return tip;
    }

    private getTaxes() {
        // Check for AU
        this.showTax = this.appService.appConfig.locale == 'en-US';
        if (this.order?.taxes?.applied?.length) {
            this.order?.taxes?.applied.forEach(tax => {
                tax.rate.inclusive ? this.inclusiveTaxes += tax.amount / 100 : this.exclusiveTaxes += tax.amount / 100;
            });
        }
    }

    private getFees() {
        // Delivery fee - IL
        this.deliveryFee = this.getDeliveryFee();
        // Other Fees - US
        if (this.order?.fees?.applied?.length) {
            this.order.fees.applied.forEach(fee => {
                this.fees += fee.amount / 100;
            });
        }
    }

    private setAmounts() {
        this.totalAmount = this.getTotalAmount();
        this.balance = this.order.balance ? this.order.balance / 100 : 0;
        this.remainingAvailableDiscount = (this.order.totals?.bonuses?.cash?.remainingAvailableDiscount || 0) / 100;
        this.cashBalance = (this.order.totals?.bonuses?.cash?.balance || 0) / 100;
    }

    private getDeliveryFee() {
        let deliveryFeeItem: any;
        if (this.order?.orderedOffers?.length) {
            deliveryFeeItem = this.order.orderedOffers.find(offer => offer.isDeliveryFee);
        }
        return deliveryFeeItem ? deliveryFeeItem.amount / 100 : 0;
    }

    private calculateDiscountsValue() {
        if (!this.order?.rewards?.length) return;
        this.order.rewards.forEach(reward => {
            this.discountsValue += (reward.discount?.percentage !== 100) ? (reward.discount.amount / 100) : 0;
        });
        this.discountsValue = this.discountsValue - (this.benefitsValue + this.pointsUsed);
    }

    private calculatePoints() {
        if (!this.order) return;
        if (!this.order.orderedPromotions?.length) return;
        this.order.orderedPromotions.forEach(promotion => {
            this.pointsUsed += promotion.pointsUsed;
        });
    }

    private getOfferTotal(offer) {
        let total = 0;
        if (!offer) return total;
        total = offer.amount / 100;
        // if (!this.order.rewards?.length) return total;
        // We take the discount from the rewards array
        // if requiredResources includes the offer._id then it must be a discounted offer 
        // this.order.rewards.forEach(reward => {
        //     if (reward.requiredResources?.length) {
        //         let discountForOffer = reward.requiredResources.find(resource => resource.orderedOffer == offer._id);
        //         if (discountForOffer && find(reward.discount?.rewardedResources, { orderedOffer: offer._id })) {
        //             total = ( (offer.amount - (reward.discount.amount || 0)) / 100)
        //         }
        //     }
        // });

        return total;
    }

    private setItemsList() {
        if (!this.order.orderedItems?.length || !this.order.orderedOffers?.length) return;
        let preppedOffers = this.order.orderedOffers.filter(offer => !offer.isDeliveryFee);
        preppedOffers.forEach(offer => {
            let prepItem = {
                quantity: 1,
                name: offer.name,
                total: this.getOfferTotal(offer),
                onTheHouse: this.setOnTheHouse(offer),
            }

            this.basket.push(prepItem)
        });
    }

    private offerHasFullDiscount(offer): boolean {
        let discountReward = this.order.rewards.find(reward => {
            if (reward.requiredResources?.length) {
                return reward.requiredResources.find(resource => resource.orderedOffer == offer._id);
            }
        });
        if (discountReward?.discount?.amount) {
            // The offer has 100% discount of any type
            if ( (offer.price - discountReward.discount.amount) == 0 && find(discountReward.discount?.rewardedResources, { orderedOffer: offer._id })) return true;
        }
        return false;
    }

    private setOnTheHouse(offer) {
        let onTheHouse = offer.onTheHouse ? offer.onTheHouse : ( this.offerHasFullDiscount(offer) ? true : false );
        return onTheHouse;
    }

    private getTotalAmount() {
        let totalAmount = 0;
        let cashback = this.order?.paymentSummary?.cashback > 0 ? this.order.paymentSummary.cashback : 0;
        if (this.order.totals?.totalAmount) {
            totalAmount = this.order.totals?.totalAmount ?
                ((cashback > 0 ?
                    ((this.order.totals.totalAmount + cashback) / 100)
                    :
                    (this.order?.paymentSummary?.totalAuxAmount ?
                        (this.order.totals.totalAmount + this.order.paymentSummary.totalAuxAmount) / 100
                        :
                        this.order.totals.totalAmount / 100
                    )
                ))
                : 0;
        }
        return totalAmount;
    }
}
