import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, forkJoin, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, retry, timeout, filter } from 'rxjs/operators';
import { compact, each, keyBy, find, get, filter as _filter, assignIn } from 'lodash-es';

import { environment } from '../../environments/environment';

import { AppService } from '../app.service';
import { EntityUtilsService } from './entity.utils.service';
import { LocationService, LocationLabeled } from './location.service';
import { OrganizationBucket, ORGANIZATION_BUCKET_TYPES, OrgsChange, SearchDetails } from './OrganizationBucket';

import { getMapAreas, MAP_AREA } from './static/map-areas';

const bucketsToSyncToLocalStorage = [
    ORGANIZATION_BUCKET_TYPES.new,
    ORGANIZATION_BUCKET_TYPES.nearbyTabit,
    ORGANIZATION_BUCKET_TYPES.favorites,
    ORGANIZATION_BUCKET_TYPES.homeOrder,
    ORGANIZATION_BUCKET_TYPES.extra,
    ORGANIZATION_BUCKET_TYPES.nearbySubGroup,
];

export interface ActiveState {
    enabled: boolean;
    active: boolean;
    available: boolean;
}

export interface ServicesActiveState {
    book: ActiveState,
    pay: ActiveState,
    order: ActiveState,
}

@Injectable({
    providedIn: 'root'
})
export class OrganizationsService {

    appConfig: any = environment.appConfig;

    private organizations: any = {}; // Please do not make it public. We try to do good things here

    private organizationBuckets: { [key: string]: OrganizationBucket } = {};

    public initialOrganizationsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public organizationConfig: any = {};

    public data: { [key: string]: Observable<any[]> } = {};

    private orgChange: Subject<any> = new Subject();
    private orgsChange: Subject<OrgsChange> = new Subject();

    public SEARCH_LIMIT = 25;
    public specialUrlSearch: string = null;

    public searchScreenNeedsScroll: boolean = false;
    public searchScreenScrollPosition: number = null;
    public searchedMapBounds: google.maps.LatLngBounds = null;

    private mapAreas: MAP_AREA[];

    constructor(
        private locationService: LocationService,
        private appService: AppService,
        private utilsService: EntityUtilsService,
        private http: HttpClient
    ) {

        compact(Object.keys(ORGANIZATION_BUCKET_TYPES).map(key => parseInt(key))).forEach((orgType: any) => {
            const newBucket = this.organizationBuckets[orgType] = (
                new OrganizationBucket(
                    orgType,
                    ORGANIZATION_BUCKET_TYPES[orgType],
                    this.getOrganization.bind(this),
                    this.getCurrentLocationFromState.bind(this),
                    this.searchAtBridgeAndPublish.bind(this)
                )
            );
            if (bucketsToSyncToLocalStorage.includes(orgType)) newBucket.setSyncToLocalStorage(true);
            this.data[orgType] = newBucket.orgsSubject;
        });

        this.mapAreas = getMapAreas(this.appService.appConfig.locale);

        this.initOrgsManagement();
    }

    get selectedOrganizationConfig() {
        return this.organizationConfig;
    }

    setOrganizationConfig(organizationConfig) {
        this.organizationConfig = organizationConfig;
    }

    private initOrgsManagement(): void {

        this.orgsChange.subscribe((orgsChange: OrgsChange) => {

            try {

                let preparedOrgs = orgsChange?.orgs?.length ? compact(orgsChange.orgs.map((org: any) => {
                    if (orgsChange.prepared) return org;
                    return this.utilsService.prepareSite(org, false, this.getOrganization(org._id));
                })) : [];

                if (orgsChange?.orgs?.length) {
                    this.organizations = { ...this.organizations, ...keyBy(preparedOrgs, '_id') };
                }

                this.informOrgsSubjects(preparedOrgs.map(org => org._id), orgsChange);

            } catch (err) {

                each(this.organizationBuckets, bucket => bucket.error(err));

            }

        }, err => {

            each(this.organizationBuckets, bucket => bucket.error(err));

        });

    }

    public hasNearbyOrganizations(): boolean {
        return !!this.organizationBuckets[ORGANIZATION_BUCKET_TYPES.nearbyAll].num();
    }

    public loadAllOrgsTypes() {

        this.fullOrganizationNeedsUpdate.next(true);

        // Loading from Server
        this.loadInitialOrganizations().subscribe(() => {
            if (!this.initialOrganizationsLoaded.getValue()) this.initialOrganizationsLoaded.next(true);
        }, err => {
            if (!this.initialOrganizationsLoaded.getValue()) this.initialOrganizationsLoaded.error(err);
            this.orgsChange.error(err);
        });

    }

    private informOrgsSubjects(ids: string[], orgsChange: OrgsChange) {

        ids.map(id => this.orgChange.next(this.getOrganization(id)));

        if (this.organizationBuckets[orgsChange.type]) this.organizationBuckets[orgsChange.type].update({ ...orgsChange, ids});

    }

    public specificOrgChange(siteId: string) {
        return this.orgChange.pipe(filter(org => org._id === siteId));
    }

    public getOrganization(siteId: string) {
        return this.organizations[siteId];
    }

    public getOrganizationWithHandle(orgHandle: string) {
        let org = this.organizations[orgHandle];
        if (org) return org;
        return find(this.organizations, (org: any) => {
            return get(org, `seo[${this.appService.appConfig.locale.toLocaleLowerCase()}].urlIdentifier`) === orgHandle;
        });
    }

    private getCurrentLocationFromState(): LocationLabeled {
        let labeledLocation: LocationLabeled = this.locationService.getChosenLocation();
        if (!labeledLocation || !labeledLocation.location) throw new Error('missing location, supposed to always be at state');
        return labeledLocation;
    }

    private putPreparedOrg(org) {
        this.organizations[org._id] = org;
        this.orgChange.next(org);
        each(this.organizationBuckets, bucket => {
            let ids = bucket.getCurrentIds();
            if (ids.indexOf(org._id) >= 0) bucket.update({ids});
        });
    }

    public setFavorites(ids: string[]) {
        let favoritesType = ORGANIZATION_BUCKET_TYPES.favorites;
        this.organizationBuckets[favoritesType].update({ clear: true, ids });
        // this.orgsChange.next({ type: ORGANIZATION_BUCKET_TYPES.favorites, orgs: ids.map(id => this.getOrganization(id))})
    }

    public toggleFavorites(orgId: string): string[] {

        let favoritesType = ORGANIZATION_BUCKET_TYPES.favorites;
        let org = this.getOrganization(orgId);

        let favoritesIds = this.organizationBuckets[favoritesType].getCurrentIds();

        let i = favoritesIds.indexOf(orgId);
        if (i < 0) favoritesIds.push(orgId);
        else favoritesIds.splice(i, 1);

        this.organizationBuckets[favoritesType].update({ ids: favoritesIds, clear: true });
        this.putPreparedOrg({ ...org, isFavorite: !org.isFavorite })
        this.refreshFavoriteOrgsInLocalStorage();

        return favoritesIds;

    }

    public search(searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES) {
        return this.organizationBuckets[type].search(searchDetails, this.getCurrentLocationFromState());
    }

    public getSearchDetails(type: ORGANIZATION_BUCKET_TYPES) {
        return this.organizationBuckets[type].getSearchDetails();
    }

    public setLastVisited(orgId: string, type: ORGANIZATION_BUCKET_TYPES): void {
        return this.organizationBuckets[type].setLastVisited(orgId);
    }

    public getLastVisited(type: ORGANIZATION_BUCKET_TYPES): string {
        return this.organizationBuckets[type].getLastVisited();
    }

    public clearSearch(type: ORGANIZATION_BUCKET_TYPES) {
        this.organizationBuckets[type].clearSearch();
        this.searchScreenNeedsScroll = false;
        this.orgsChange.next({ type, clear: true });
    }

    public setPreSearchDetails(searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES) {
        return this.organizationBuckets[type].setPreSearchDetails(searchDetails);
    }

    public getPreSearchDetails(type: ORGANIZATION_BUCKET_TYPES): Observable<SearchDetails> {
        return this.organizationBuckets[type].preSearchDetailsObservable;
    }

    public isSearchEqualToLastSearch(searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES): boolean {
        return this.organizationBuckets[type].isPreSearchEqualToLastSearch(searchDetails);
    }

    public setIfUsingExtendedResults(value: boolean, type: ORGANIZATION_BUCKET_TYPES): void {
        this.organizationBuckets[type].setIfUsingExtendedResults(value);
    }

    public usingExtendedResults(type: ORGANIZATION_BUCKET_TYPES): Observable<boolean> {
        return this.organizationBuckets[type].usingExtendedResultsObservable;
    }

    private setNextSkip(value: number, type: ORGANIZATION_BUCKET_TYPES): void {
        this.organizationBuckets[type].setNextSkip(value);
    }

    public nextSkip(type: ORGANIZATION_BUCKET_TYPES): Observable<number> {
        return this.organizationBuckets[type].nextSkipObservable;
    }

    private setPreventSearchMore(value: boolean, type: ORGANIZATION_BUCKET_TYPES) {
        this.organizationBuckets[type].setPreventSearchMore(value);
    }

    public preventSearchMore(type: ORGANIZATION_BUCKET_TYPES): Observable<boolean> {
        return this.organizationBuckets[type].preventSearchMoreObservable;
    }

    public fullOrganizationNeedsUpdate: Subject<any> = new Subject();

    public fullOrganization(orgHandle: string, showExcluded?: boolean): Observable<any> {
        return new Observable(observer => {
            this.loadFullOrganization(orgHandle, showExcluded).subscribe({
                next: (org: any) => {
                    const fullOrg = this.utilsService.prepareSite(org, true, this.getOrganization(org._id));
                    // Prepare could invalidate the org:
                    if (fullOrg) {
                        this.putPreparedOrg(fullOrg);
                        observer.next(fullOrg);
                        observer.complete();
                    } else {
                        observer.error(new Error(`Invalid organization: '${orgHandle}'`));
                    }
                    // Must complete here because it's in use within 'forkJoin':
                },
                error: (err: any) => {
                    observer.error(err);
                }
            });
        });
    }

    public getFullOrganization(orgHandle: any, showExcluded?: boolean): Observable<any> {
        return new Observable((observer) => {
            this.fullOrganization(orgHandle, showExcluded).subscribe({
                next: (result) => { observer.next(result) },
                error: (err) => {
                    if (!err.headers) return console.error('Error loading site :', err);

                    let urlIdentifierRedirect = this.appService.urlIdentifierRedirectFromError(err);
                    if (urlIdentifierRedirect) {
                        return this.fullOrganization(urlIdentifierRedirect, showExcluded)
                        .subscribe(observer);
                    } else {
                        return observer.error(`Error loading site: ${err}`);
                    }
                },
                complete: () => {
                    observer.complete();
                }
            });
        });
    }

    public getFullOrganizationById(orgId: string): Observable<any> {
        return new Observable(observer => {
            return this.loadFullOrganizationById(orgId)
            .subscribe(org => {
                const fullOrg = this.utilsService.prepareSite(org, true, this.getOrganization(org._id));
                // Prepare could invalidate the org:
                if (fullOrg) {
                    this.putPreparedOrg(fullOrg);
                    observer.next(fullOrg);
                    observer.complete();
                } else {
                    observer.error(new Error(`invalid organization: '${orgId}'`));
                }
                // Was MUST complete here because it's in use within 'forkJoin':
            }, err => {
                observer.error(err);
            });
        });
    }

    private loadInitialOrganizations(): Observable<void> {

        // Add Favorites
        let additionalOrgsIds = this.appService.account.favorites || [];
        // Add Recent
        if (Array.isArray(this.appService.account.history)) {
            let recentOrgsIdsSet = new Set();

            for (let order of this.appService.account.history) {
                if (order.organization?.id) recentOrgsIdsSet.add(order.organization.id);
                if (recentOrgsIdsSet.size == 5) break;
            }

            additionalOrgsIds = additionalOrgsIds.concat(Array.from(recentOrgsIdsSet));
        }
        // Add sites of reservations:
        if (this.appService.reservations?.length) {
            additionalOrgsIds = additionalOrgsIds.concat(_filter(this.appService.reservations.map(reservation => {
                if (!this.appService.isReservationRelevant(reservation)) return null;
                return reservation.organization;
            })));
        }

        const location = this.getCurrentLocationFromState().location;

        const allOrganizationsParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location, 'include_ids[]': additionalOrgsIds }
        });

        const orderOrganizationsParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location, service: this.appService.defaultServiceOrderType, external: false }
        });

        const newAtTabitOrganizationsParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location }
        });

        const tabitNearbyOrganizationsParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location }
        });

        const nearbyOrganizationsBySubGroupParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location }
        });

        // When (you will) need to wait for this, please use pipe and catch error like at getMore
        return forkJoin([
            this.http.get(`${this.appConfig.tabitBridge}/organizations`, allOrganizationsParams),
            this.http.get(`${this.appConfig.tabitBridge}/organizations/search`, orderOrganizationsParams),
            this.http.get(`${this.appConfig.tabitBridge}/organizations/tabit-nearby`, tabitNearbyOrganizationsParams),
            this.http.get(`${this.appConfig.tabitBridge}/organizations/new-at-tabit`, newAtTabitOrganizationsParams),
            this.http.get(`${this.appConfig.tabitBridge}/organizations/nearby-by-subgroup`, nearbyOrganizationsBySubGroupParams),
        ])
        .pipe(
            timeout(30000),
            retry(3),
            map(([allOrgs, homeOrderOrgs, nearbyTabitOrgs, newAtTabitOrgs, nearbySubGroupOrgs]: any[]) => {

                let nearbyAllOrgs = this.getResponseOrganizations(allOrgs).filter(org => org.nearby);

                let favoritesOrgs = this.getResponseOrganizations(allOrgs).filter(org => (this.appService.account.favorites || []).indexOf(org._id) >= 0);

                // Extra Organizations
                this.publishOrganizations(this.getResponseOrganizations(allOrgs), ORGANIZATION_BUCKET_TYPES.extra, { clear: true });

                // New Organizations
                this.publishOrganizations(this.getResponseOrganizations(newAtTabitOrgs), ORGANIZATION_BUCKET_TYPES.new, { clear: true, skip: this.getResponseSkip(newAtTabitOrgs) });

                // Near by Tabit Organizations
                this.publishOrganizations(this.getResponseOrganizations(nearbyTabitOrgs), ORGANIZATION_BUCKET_TYPES.nearbyTabit, { clear: true, skip: this.getResponseSkip(nearbyTabitOrgs) });

                this.publishOrganizations(this.getResponseOrganizations(nearbySubGroupOrgs), ORGANIZATION_BUCKET_TYPES.nearbySubGroup, { clear: true, skip: this.getResponseSkip(nearbySubGroupOrgs) });

                // Favorite Organizations
                if (favoritesOrgs.length) {
                    this.publishOrganizations(favoritesOrgs, ORGANIZATION_BUCKET_TYPES.favorites, { clear: false });
                }

                // Near by All Organizations (For )
                this.publishOrganizations(nearbyAllOrgs, ORGANIZATION_BUCKET_TYPES.nearbyAll, { clear: true });

                // Home Order Organizations
                this.publishOrganizations(this.getResponseOrganizations(homeOrderOrgs), ORGANIZATION_BUCKET_TYPES.homeOrder, { clear: true, skip: this.getResponseSkip(homeOrderOrgs) });

            })
        )
    }

    private searchAtBridgeAndPublish(searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES, searchLocation: LocationLabeled): Observable<any> {

        const url = `${this.appConfig.tabitBridge}/organizations/search`;

        const newSearch = !searchDetails.skip;

        if (newSearch) this.setPreventSearchMore(false, type);

        // console.log('Searching orgs with location:', searchLocation);

        const searchParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: {
                ...searchLocation.location,
                extendLimit: true,
            }
        });

        if (searchDetails.service) searchParams.params.service = searchDetails.service;
        if (searchDetails.skip) searchParams.params.skip = searchDetails.skip;

        if (searchDetails.query) searchParams.params.q = searchDetails.query;
        if (searchDetails.booking) searchParams.params.booking = JSON.stringify(searchDetails.booking);
        if (searchDetails.tags?.length) searchParams.params['tags[]'] = searchDetails.tags;
        if (searchDetails.rating && searchDetails.rating !== undefined) searchParams.params.rating = searchDetails.rating;
        if (searchDetails.price && searchDetails.price !== 0) searchParams.params.price = searchDetails.price;
        if (searchDetails.onlyTabit && searchDetails.onlyTabit == true) searchParams.params.onlyTabit = searchDetails.onlyTabit;
        if (searchDetails.onlyAvailable && searchDetails.onlyAvailable == true) searchParams.params.onlyAvailable = searchDetails.onlyAvailable;
        if (searchDetails.hasLeumiPayment) searchParams.params.hasLeumiPayment = true;
        if (searchDetails.externalDeliveryLink === true) searchParams.params.externalDeliveryLink = true;

        if (searchDetails.bounds) {
            // Explicit bounds:
            searchParams.params.bounds = searchDetails.bounds;
            searchParams.params.map_search = true;
        } else if (searchLocation.area && !searchDetails.query) {
            // Bound because of area:
            // Specific area selected on location:
            const area = this.mapAreas.find(area => area.key === searchLocation.area);
            if (area?.boundingbox) {
                searchParams.params.bounds = JSON.stringify(area.boundingbox);
            }
        }

        return this.http.get(url, searchParams).pipe(
            map((response: any) => {

                const orgs = this.getResponseOrganizations(response);

                this.publishOrganizations(orgs, type, { clear: newSearch, skip: this.getResponseSkip(response), preventOn: this.SEARCH_LIMIT })

                return orgs;

            }),
            catchError(err => {
                this.orgsChange.error(err);
                return throwError(err);
            }),
        );

    }

    private loadFullOrganization(orgId: string, showExcluded?: boolean): Observable<any> {

        let locationData = this.getCurrentLocationFromState();
        let location = locationData.location;
        let params: any = {...location};

        if (showExcluded) params.showExcluded = true;

        // a specific area boundary was selected as location (e.g "Center", "North")
        if (locationData.area) {
            let area = this.mapAreas.find(area => area.key === locationData.area);
            if (area?.boundingbox) params.bounds = JSON.stringify(area.boundingbox);
        }

        let requestParams: any = assignIn({}, this.appService.appHttpOptions, { params });

        let url = `${this.appConfig.tabitBridge}/organizations/${orgId}`;

        return this.http.get(url, requestParams);

    }

    private loadFullOrganizationById(orgId: string): Observable<any> {
        let url = `${this.appConfig.tabitBridge}/organizations/by-id/${orgId}`;

        return this.http.get(url, this.appService.appHttpOptions);
    }

    private getResponseOrganizations(orgsFromAutoSkipResponse) {
        return orgsFromAutoSkipResponse.organizations || [];
    }

    private getResponseSkip(response) {
        return response.skip || 0;
    }

    public publishOrganizations(orgs: any[], type: ORGANIZATION_BUCKET_TYPES, { clear, skip, preventOn }: { clear: boolean, skip?: number, preventOn?: number }): void {
        this.orgsChange.next({
            type,
            orgs,
            clear,
        })
        if (typeof preventOn !== undefined && orgs.length < preventOn) this.setPreventSearchMore(true, type);
        if (skip && skip >= 0) this.setNextSkip(skip, type);
    }

    public initBucketOrgsFromLocalStorage(type: ORGANIZATION_BUCKET_TYPES): void {
        let orgs = this.organizationBuckets[type].getOrgsFromLocalStorage();
        if (orgs?.length) this.orgsChange.next({ orgs, type, prepared: true });
    }

    public refreshFavoriteOrgsInLocalStorage() {
        this.organizationBuckets[ORGANIZATION_BUCKET_TYPES.favorites].orgsSubject.subscribe(orgs => {
            this.organizationBuckets[ORGANIZATION_BUCKET_TYPES.favorites].resaveOrgsAtLocalStorage(orgs);
        });
    }

}
