import { Component, NgZone, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { AccountService } from '../../_core/account.service';
import { PaymentExpiration } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { MatRadioButton } from '@angular/material/radio';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { BlockUIModule } from 'ng-block-ui';

@UntilDestroy()
@Component({
    selector: 'app-my-wallet',
    templateUrl: './my-wallet.component.html',
    styleUrls: ['./my-wallet.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        BlockUIModule,
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        NgIf,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        NgFor,
        MatRadioButton,
        FormsModule,
        MatFormField,
        MatSelect,
        MatOption,
        NgSwitch,
        NgSwitchCase,
        MatInput,
        MatButton,
        TranslateModule,
        PaymentExpiration,
    ],
})
export class MyWalletComponent implements OnInit, OnDestroy {

    constructor(
        public appService: AppService,
        public accountService: AccountService,
        private route: ActivatedRoute,
        public ngZone: NgZone,
    ) { }

    @ViewChild('addPMForm') myForm: NgForm;

    ngOnInit() {
        let step = this.route.snapshot.paramMap.get('step');
        if (step) this.accountService.pmStep = step;

        // Enable / Disable the Card.IO scan option
        // this.accountService.canScanPM();
        // fetch all user details only if empty
        if (!this.accountService.userDetails?._id) this.accountService.getUserInfo();

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/profile/my-profile'])
                    });
                });
        }
    }

    ngOnDestroy() {
        this.accountService.setInitialCcInfoState();
    }

    submitAddPmForm() {
        this.accountService.addPM()
        .then(() =>{
            this.myForm.resetForm();
        })
    }

}
