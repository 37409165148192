import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { trigger, stagger, transition, query, animateChild } from '@angular/animations';
import { AppService } from '../../../../src/app/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'web-tiles-container',
    templateUrl: './web-tiles-container.component.html',
    styleUrls: ['./web-tiles-container.component.scss'],
    animations: [
        trigger('tilesContentAnimation', [
            transition('hide => show', [
                query('site-card', [
                    stagger(100, [
                        query('@siteCardAnimation', [
                            animateChild()
                        ])
                    ])
                ], { optional: true }),
            ])
        ]),
    ],
    standalone: true,
    imports: [NgClass, NgIf, AccessibleDirective, RouterLink, TranslateModule]
})
export class WebTilesContainerComponent implements OnInit {

    @Input() tilesContainerLink: string;
    @Input() tilesContainerTitle: string;
    @Input() tilesContainerType: string;
    @Input() showMoreButton: boolean;
    @Input() screenReaderLabel: string = null;

    @ViewChild('tilesContent', {static: false})    tilesContent: ElementRef;

    @HostListener('window:scroll') checkScroll() {
        const scrollPosition = window.pageYOffset + window.innerHeight;
        if (this.tilesContent && this.tilesContent.nativeElement.offsetTop >= scrollPosition) {
            this.animateTilesContent = 'show';
        }
    }

    animateTilesContent: string = 'hide';

    constructor(
        public appService: AppService
    ) { }

    ngOnInit() {
    }

}
