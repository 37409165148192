import { finalize, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, throwError, Subject } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { toString as _toString, toNumber, isNull, merge, get, pick, extend, cloneDeep } from 'lodash-es';
import hexRgb from 'hex-rgb';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js/mobile';

import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SmsDialogComponent } from '../dialogs/sms-dialog/sms-dialog.component';
import { LoyaltyService } from '../_core/loyalty.service';
import { TranslationService } from '../_core/translation.service';
import { PaymentDialogComponent } from '../dialogs/payment-dialog/payment-dialog.component';
import { AuthorizenetPayDialogComponent } from '../dialogs/authorizenet-pay-dialog/authorizenet-pay-dialog.component';
import { MainMessageDialogOptions, MainMessageDialogComponent } from '../dialogs/main-message-dialog/main-message-dialog.component';
import { AccountService } from '../_core/account.service';
import { DialogsService } from '../_core/dialogs.service';
import { EntityService } from '../_core/entity.service';
import { ToPaymentDialogComponent } from '../tabit-order/dialogs/to-payment-dialog/to-payment-dialog.component';
import { OrganizationsService } from '../_core/organizations.service';
import { environment } from '../../environments/environment';
import { StyleService } from '../_core/style.service';
import { NotificationsService } from '../_core/notifications.service';
import { GCSAdyenPayDialogComponent } from './dialogs/gcs-adyen-pay-dialog/gcs-adyen-pay-dialog.component';

// GCS settings - card config settings
export interface loyaltyGiftCardSettings {
    siteId?: string;
    giftCardShopId?: number;
    accountId?: number;
    cardTypeId?: number;
    isActive?: boolean;
    configuration?: {
        restaurantName: string;
        additionalDetails?: {
            phone?: string;
            website?: string;
            extraInfo?: string;
        }
        restaurantDetails?: {
            businessAddress: string;
        }
        paymentMethods: Array<{
            _id?: string;
            _type?: string;
            cardNotPresentMerchantNumber?: string;
            paymentVerificationStrategy?: string;
            providerAccountId?: string;
        }>;
        giftCardValue: {
            minAmount: number;
            maxAmount: number;
            // amount input: predefined selection or free text 
            predefinedAmountSelection?: boolean;
            predefinedAmountSelectionStep?: number;
        };
        images: {
            logoPath: string;
            logoFileName: string;
            mainBackgroundImage: string;
            summaryImagePath: string;
            summaryImageFileName: string;
        };
        colors: {
            primary: string;
            secondary: string;
            headerBackground?: string; 
            headerTitle?: string; 
        };
        socialMedia: {
            facebook: string;
            instagram: string;
            website: string;
            phone: number;
            otherLink: string;
            googleTagManager: string;
        },
        redirectUrl?: string;
        hideQR?: boolean; // at the "redeem-card" page
        bonusPercentage?: number;
    }
}

// redeem card: gift card details
export interface giftCardDetails {
    configuration?: any,
    senderFirstName?: string;
    senderLastName?: string;
    receiverFirstName?: string;
    receiverLastName?: string;
    message?: string;
    cardNumber?: number;
    cvv?: number;
    balance?: number; //current card balance
    originalAmount?: number; // original card load amount
    isActive?: boolean; // is shop active
    sendToFriend?: boolean;
    isCardActive?: boolean; // is card active
}

export enum CreditCardPaymentProvider {
    VantivPaymentProvider = 1, // US
    BraintreePaymentProvider = 2, // US
    CreditGuardPaymentProvider = 3, // IL
    CardPointePaymentProvider = 4, // US
    RosWallet = 5, // US,
    CibusPaymentProvider = 6,  // IL
    HeartlandPaymentProvider = 7, // US
    StripePaymentProvider = 8, // US
    AuthorizeNetPaymentProvider = 9, // US
    AdyenPaymentProvider = 10, // AU
}

export enum ShopSteps {
    recipient = 0, 
    details = 1, 
    delivery = 2, 
    checkout = 3 
}

export interface contactDetails {
    firstName: string,
    lastName?: string,
    mobile: string,
    email: string,
    notifyBySms: boolean,
    notifyByEmail: boolean,
    cardNumber?: string
}

export interface buyGiftCardRequestBody {
    sender: contactDetails,
    receiver: contactDetails,
    payment: {
        paymentId: string,
        paymentAccount: string,
        paymentProviderType: string,
        providerTransactionToken: string,
        params: any
    },
    amount: number,
    orderId: string,
    message?: string,
    organizationId?: string,
    requestSource?: string,
    isSelfOrder?: string,
}

@Injectable({
    providedIn: "root",
})
export class GiftCardsService {

    constructor(
        public dialog: MatDialog,
        public platform: Platform,
        public appService: AppService,
		public accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private formBuilder: UntypedFormBuilder,
        private loyaltyService: LoyaltyService,
        private entityService: EntityService,
        public translateService: TranslateService,
        private dialogsService: DialogsService,
        private organizationsService: OrganizationsService,
        private ngZone: NgZone,
        private deviceService: DeviceDetectorService,
        private styleService: StyleService,
        private translationService: TranslationService,
        private notificationsService: NotificationsService,
    ) {
        // initialize the form model
        this.giftCardForm = this.getGiftCardForm();

        // initialize the query params listener
        this.subscribeToQueryParams();

        // visual flags initialization
        this.isSafari = this.platform.SAFARI;
        const isAndroidApp = window['cordova'] && this.appService.platformService.ANDROID;
        this.isDarkMode = window.matchMedia("(prefers-color-scheme: dark)")?.matches && !isAndroidApp;
    }

    public loadingCustomer: boolean = false;

    // the gift card form model
    public giftCardForm: UntypedFormGroup;
    // visual flags
    public shortScreenFlag: boolean = false;  // indicates the screen is shorter than 876px (affecting layout)
    public isSafari: boolean;
    public isDarkMode: boolean;
    public paymentInProgress: boolean = false;
    // is the user logged in
    public userLoggedIn: boolean = false;
    public appConfig: any = environment.appConfig;
    public verifierPhone: string = '';

    public giftCardPurchaseResponse = new Subject<any>();

    // private data store
    private privateStore = {
        // global module name ('redeem-card' | 'create-card')
        _activeRouteId: new BehaviorSubject(<string> 'create-card'),
        // create card
        _giftCardConfig: new BehaviorSubject(<loyaltyGiftCardSettings> {}),
        _currentStepIndex: new BehaviorSubject(<number> 0),
        _accountGuid: new BehaviorSubject(<string> ''),
        // redeem card
        _giftCardId: new BehaviorSubject(<string> ''), // url identifier
        _giftCardData: new BehaviorSubject(<giftCardDetails> {}), // the card data
        // choose-amount input state
        _chooseAmountInputState: new BehaviorSubject(<string> ''),
        // campaign data
        _campaign: new BehaviorSubject(<string> ''),
        // marketplace log data
        _marketplace: new BehaviorSubject(<boolean> false),
    };

    // public data store
    public readonly publicStore = {
        activeRouteId$: this.privateStore._activeRouteId.asObservable(), // global module name (e.g 'redeem-card', 'create-card')
        // create card
        giftCardConfig$: this.privateStore._giftCardConfig.asObservable(),
        currentStepIndex$: this.privateStore._currentStepIndex.asObservable(),
        accountGuid$: this.privateStore._accountGuid.asObservable(),
        // redeem card
        giftCardId$: this.privateStore._giftCardId.asObservable(), // url identifier
        giftCardData$: this.privateStore._giftCardData.asObservable(), // the card data
        // choose-amount input state
        chooseAmountInputState$: this.privateStore._chooseAmountInputState.asObservable(),
    };

    // user browser storage data
    browserStorage: any = {
        giftCardsViewed: []
    }

    // the Loyalty gift card settings model
    public loyaltyGiftCardSettings: loyaltyGiftCardSettings = {
        siteId: null,
        giftCardShopId: null,
        cardTypeId: null,
        isActive: false,
        configuration: {
            restaurantName: null,
            paymentMethods: [],
            giftCardValue: {
                minAmount: 0,
                maxAmount: 0,
                // amount input: predefined selection or free text 
                predefinedAmountSelection: null,
                predefinedAmountSelectionStep: null,
            },
            images: {
                logoPath: null,
                logoFileName: null,
                mainBackgroundImage: null,
                summaryImagePath: null,
                summaryImageFileName: null,
            },
            colors: {
                primary: null,
                secondary: null,
                headerBackground: null,
                headerTitle: null,
            },
            socialMedia: {
                facebook: null,
                instagram: null,
                website: null,
                phone: null,
                otherLink: null,
                googleTagManager: null
            },
            redirectUrl: null,
            hideQR: null,
            bonusPercentage: 0
        }
    };
    
    // keep track of the selected payment provider
    public selectedPaymentProvider: {
        _id?: string;
        _type?: string;
        cardNotPresentMerchantNumber?: string;
    } = {
        _id: null,
        _type: null,
        cardNotPresentMerchantNumber: null
    }

    // keep track of the selected payment provider
    public selectedOrgWalletPaymentProviderType: any;
    public selectedOrgWalletPaymentProviderId: any;
    public selectedOrgWalletPaymentProviderMerchent: any;

    // allow overriding local parameters with query params
    public queryOverrides = {
        linearStepperFlow: true, // linear flow disables navigation to different steps without completing prior steps. steps are mandatory and go from first up.
        startAtStep: 0,
        sendToFriend: true,
        topUp: false,
    }

    // used once per payment, then get overwritten. to be used in buyGiftCard().
    public temporaryPaymentDetails: any = {
        providerTransactionToken: null,
        paymentId: null,
        orderId: null,
        paymentDialogInitToken: null
    }
    public giftCardPaymentMethods: string;
    public giftCardIsWallet: boolean;

    // listen to queryParams changes, update the according app parameters
    subscribeToQueryParams() {
        this.route.queryParams
        .subscribe(params => {
            if (params['marketplace']) {
                // If the user came from the marketplace page, set the corresponding flag and update Local Storage
                // Assing true if 1 was provided as value
                this.updatePrivateStore('_marketplace', params['marketplace'] == '1');
            }

            if (params['locale_id']) {
                this.changeUsedLanguage(params['locale_id']);
            }

            // * Loyalty reports
            if (params['campaign']) {
                this.updatePrivateStore('_campaign', params['campaign']);
            }

            // * redeem card: show QR and details
            if (params['id']) {
                // update the active module id
                this.updatePrivateStore('_activeRouteId', 'redeem-card');
                this.updatePrivateStore('_giftCardId', params['id']);

            // * create-card: buy or top-up existing cards
            } else if (params['accountGuid']) {

                // update the active module id
                this.updatePrivateStore('_activeRouteId', 'create-card');
                this.updatePrivateStore('_accountGuid', params['accountGuid']);

                // enable non-linear GCS steps flow
                if (params['linear']) this.queryOverrides.linearStepperFlow = !!toNumber(params['linear']);
                // defines minimum step for the session
                if (this.isNumeric(params['startStep'])) this.queryOverrides.startAtStep = toNumber(params['startStep']);
                // toggle between gift-card purchase and top-up flow (logged-in users only)
                if (this.isNumeric(params['topUp'])) this.queryOverrides.topUp = !!toNumber(params['topUp']);
                // if topUp=true, it'll override sendToFriend
                if (this.queryOverrides.topUp) {
                    this.queryOverrides.sendToFriend = false;
                    this.cardCreationDetailsControl?.get('sendToFriend').setValue(false);
                    this.setQueryParam({sendToFriend: 0})
                // set recipient type, or choose default if not specified
                } else if (params['sendToFriend'] ) {
                    this.queryOverrides.sendToFriend = !!toNumber(params['sendToFriend']);
                    this.cardCreationDetailsControl?.get('sendToFriend').setValue(true);
                    this.setQueryParam({sendToFriend: 1});
                    this.setQueryParam({topUp: 0});
                }
                // select the step
                let step = this.isNumeric(params['step']) ? params['step'] : 0;
                // prevent navigation to steps prior to startAtStep
                if (this.queryOverrides.startAtStep > step) step = this.queryOverrides.startAtStep;

                this.setStepIndex(step);
            }

            // payment process in progress: 
            // because the payment process is captured within the payment iframe - hide the DOM components to prevent a "site within a site" display
            if (params['done']) {
                this.paymentInProgress = true;
                this.appService.startBlock({ text: "PLEASE_WAIT", class: 'dark-block' });
            } else {
                this.paymentInProgress = false;
                this.appService.stopBlock();
            }

            if (params['step'] == this.queryOverrides.startAtStep) {
                this.setQueryParam({ result: 'new' });
            }
        });
    }

    public parsePhoneNumberValidator(): ValidatorFn {
        //local contains localization value, for example US or IL for phone validation
        return (control: AbstractControl): ValidationErrors | null => {
            try {
                const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
                if (this.getParsePhoneNumber(control.value)) {
                    const parsePhone = isValidPhoneNumber(control.value, locale);
                    return parsePhone ? null : { 'val': { value: control.value } };
                } else {
                    return { 'val': { value: control.value } };
                }
            }
            catch (e) {
                return { 'val': { value: control.value } };
            }
        }
    }

    public lengthValidator(maxLength: number, minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (value && (value.length > maxLength || value.length < minLength)) {
                return { length: true };
            }
            
            return null;
        }
    }

    getGiftCardForm(): UntypedFormGroup {
        return this.formBuilder.group({
            // first page
            cardCreationDetails: this.formBuilder.group({
                // recipient details
                recipientFirstName: ['', [Validators.required]],
                recipientLastName: ['', [Validators.required]],
                sendToFriend: this.queryOverrides.sendToFriend,
                // sender details
                senderFirstName: [''],
                senderLastName: [''],
                // card details
                optionalMessage: ['', [Validators.maxLength(256)]],
                cardLoadAmount: null,
            }),

            // second page
            cardDeliveryDetails: this.formBuilder.group({
                senderPhone: ['', [Validators.pattern('^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$')]],
                senderEmail: ['', [Validators.email]],
                recipientPhone: ['', [Validators.required, this.parsePhoneNumberValidator()]],// Validators.pattern('^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$')]],
                recipientEmail: ['', [Validators.pattern(this.appService.emailRegex)]],
                recipientState: ['',[Validators.required]],
                recipientCity: ['',[Validators.required]],
                recipientAddress: ['',[Validators.required]],
                recipientZipCode: ['',[Validators.required, Validators.pattern(/^[0-9]+$/), this.lengthValidator(5, 5)]],
                sendGiftCardTo: 'phone'
            }),

            // third page
            cardCheckout: this.formBuilder.group({
                approveTerms: [false, [Validators.required]],
                approveNotifications: false,
                receiptContactPhone: ['', [Validators.required, this.parsePhoneNumberValidator()]],// Validators.pattern('^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$')]],
                receiptContactEmail: [{value: '', disabled: true}, [Validators.required, Validators.pattern(this.appService.emailRegex)]],
                receiptContactState: ['',[Validators.required]],
                receiptContactCity: ['',[Validators.required]],
                receiptContactAddress: ['',[Validators.required]],
                receiptContactZipCode: ['',[Validators.required, Validators.pattern(/^[0-9]+$/), this.lengthValidator(5, 5)]],
                sendReceiptToPhone: true,
                sendReceiptToEmail: false
            }),
        });
    }

    resetGiftCardForm(loyaltyCustomer?): void {
        this.giftCardForm?.reset();
        // reselect 'phone' as default sendGiftCardTo
        this.giftCardForm?.get('cardDeliveryDetails')?.patchValue({sendGiftCardTo: 'phone'})
        this.giftCardForm?.get('cardCheckout')?.patchValue({sendReceiptToPhone: true, sendReceiptToEmail: false})

        this.mergeLoyaltyUserDetails(loyaltyCustomer || this.appService.user.loyaltyCustomer);
    }

    setStepIndex(newIndex: number, resetForm?: boolean, forceStep?: boolean, caller?): any {

        newIndex = toNumber(newIndex);
        const sendToFriend = this.queryOverrides.sendToFriend;

        this.privateStore._currentStepIndex
        .pipe(take(1))
        .subscribe(currentIndex => {

            // explicit topUp flow: prevent navigating to recipient selection page
            if (this.queryOverrides.topUp && newIndex == 0) return this.setStepIndex(1);

            // prevent manual navigation to steps with index less than "queryOverrides.startAtStep"
            if (!forceStep && newIndex <= this.queryOverrides.startAtStep) return this.setStepIndex(this.queryOverrides.startAtStep, false, true);

            // send to self: skip step 1, go straight to checkout page
            if (!sendToFriend && newIndex == 1) return this.setStepIndex(2);

            // linear mode - prevent manual navigation to different steps without completing prior steps
            if (this.queryOverrides.linearStepperFlow && !forceStep) {
                if (newIndex == 1 && isNull(sendToFriend)) return this.setStepIndex(0);
                if (newIndex == 2 && (sendToFriend && !this.giftCardForm?.value.cardCreationDetails.recipientFirstName)) return this.setStepIndex(1); // send to friend: prevent going straight to checkout page
                if (newIndex >= 3 && !this.giftCardForm?.value.cardDeliveryDetails.recipientPhone) return this.setStepIndex(2);
            }

            // reset the form model (if it exists)
            if (resetForm) {
                this.resetGiftCardForm();
            }

            // set form step
            this.privateStore._currentStepIndex.next(newIndex);
            // set the according step queryParam
            this.setQueryParam({step: newIndex});
        })

    }

    // Get data of the gift card shop belonging to the given accountGuid.
    getGiftCardsConfig(accountGuid: string): Observable<loyaltyGiftCardSettings> {
        // return subscription
        return this.loyaltyService.getGiftCardsConfig(accountGuid);
    }

    // Creates a media exchange order in ROS and returns the payment method link and the order ID.
    getGiftCardsPaymentLink(accountGuid: string, giftCardConfig: loyaltyGiftCardSettings): Observable<any> {
        const giftCardFormValues = this.giftCardForm.value;
        const sendToFriend = this.queryOverrides.sendToFriend;

        const reqBody: any = {
            amount: giftCardFormValues.cardCreationDetails.cardLoadAmount,
            sender: {
                firstName: giftCardFormValues.cardCreationDetails[sendToFriend ? 'senderFirstName' : 'recipientFirstName'],
                lastName: giftCardFormValues.cardCreationDetails[sendToFriend ? 'senderLastName' : 'recipientLastName'],
                mobile: this.getParsePhoneNumber(giftCardFormValues.cardCheckout.receiptContactPhone),
                email: giftCardFormValues.cardCheckout.receiptContactEmail,
                notifyBySms: giftCardFormValues.cardCheckout.sendReceiptToPhone,
                notifyByEmail: giftCardFormValues.cardCheckout.sendReceiptToEmail
            },
            organizationId: giftCardConfig.siteId,
            returnUrl: this.getBaseUrl() + '&done=1',
        }

        const payParams = this.createPaymentAccountDetails(giftCardConfig);

        // chain the "paymentProviderType" to the queryParams
        reqBody.returnUrl += `&pid=${CreditCardPaymentProvider[payParams.paymentProviderType]}`

        // build the payment provider and finance data
        reqBody.paymentProviderType = payParams.paymentProviderType;

        // Prevent GAP buttons
        if (reqBody.paymentProviderType == 'CreditGuardPaymentProvider') {
            const direction = this.appService.direction;
            const uiLang = this.appService.localeId == 'he-IL' ? 'heb' : 'eng';
            const textAlign = direction == 'rtl' ? 'right' : 'left';

            reqBody.iframeConfigurations = `{
                "uiCustomData": {
                    "uiLang": "${uiLang}",
                    "customStyle": "body {direction:${direction}; text-align:${textAlign}} #card-number, #cvv, .cg-message-input {direction:ltr; text-align:${textAlign}} #cg-ghost-btns {text-align: center !important;}",
                    "paymentMethods": [{ "hidden": true, "type": "applepay" },{ "hidden": true, "type": "googlepay" }]
                }
            }`
        };

        // * logged-in user (not a Cibus voucher payment)
        if (this.userLoggedIn && payParams.paymentProviderType != 'CibusPaymentProvider') {

            // if user chose to pay with his wallet, the specific wallet paymentAccount should be used
            reqBody.paymentAccount = (this.selectedOrgWalletPaymentProviderType?.id && this.selectedOrgWalletPaymentProviderId) ?  this.selectedOrgWalletPaymentProviderId : payParams.paymentAccount;

            // top-up of existing card
            if (this.queryOverrides.topUp) {
                reqBody.sender.cardNumber = this.appService.user?.loyaltyCustomer?.CardNumber;
                if (!reqBody.sender.cardNumber) {
                    const errorText = 'No loyalty Customer "CardNumber" found!';
                    console.error(errorText);
                    // return throwError(errorText);
                }
            }
        // regular credit pay
        } else {
            reqBody.paymentAccount = payParams.paymentAccount;
        }

        // no paymentAccount or paymentProviderType found. can't proceed.
        if (!payParams.paymentAccount || !payParams.paymentProviderType) {
            return throwError('No paymentAccount or paymentProviderType provided!');
        }

        if (giftCardFormValues.cardCheckout.receiptContactZipCode) {

            reqBody.paymentVerificationDetails = {
                zipCode: giftCardFormValues.cardCheckout.receiptContactZipCode,
            }

            if (giftCardFormValues.cardCheckout.receiptContactState) {
                reqBody.paymentVerificationDetails.address1 = giftCardFormValues.cardCheckout.receiptContactAddress;
                reqBody.paymentVerificationDetails.city = giftCardFormValues.cardCheckout.receiptContactCity;
                reqBody.paymentVerificationDetails.state = giftCardFormValues.cardCheckout.receiptContactState;
            }
        }

        if (payParams.paymentProviderType == 'AuthorizeNetPaymentProvider') {
            reqBody.returnUrl = `${document.location.origin}/assets/authorizenet_ret.html`;
        }

        if (payParams.paymentProviderType == 'AdyenPaymentProvider') {
            reqBody.successUrl = `${document.location.origin}/assets/adyen/success.html`;
        }

        // logger for the getGiftCardPaymentLink
        this.logger(null, `Attempt getting gift card payment link for ${payParams.paymentProviderType} provider`, reqBody);

        // return subscription
        return this.loyaltyService.getGiftCardPaymentLink(this.appService.removeNullProperties(reqBody, true), accountGuid);
    }

    getBaseUrl() {
        const params = this.appService.convertQueryStringToQueryParams(window.location.href);
        const baseLocation = this.appService.getBaseLocation(params);
        const baseURL = this.appService.base(baseLocation, false);
        return baseURL;
    }

    createPaymentAccountDetails(giftCardConfig: loyaltyGiftCardSettings): any {
        try {

            this.selectedPaymentProvider = giftCardConfig.configuration.paymentMethods[0];

            // pay via the saved user wallet
            // check if the saved user wallet is selected
            if (this.userLoggedIn && this.selectedOrgWalletPaymentProviderType?.id) {
                return {
                    paymentAccount: this.selectedOrgWalletPaymentProviderType.id,
                    paymentProviderType: 'RosWallet',
                    merchantNumber: this.selectedOrgWalletPaymentProviderMerchent
                };
            // user not logged in, or chose not to use saved wallet
            } else {
                return {
                    paymentAccount: this.selectedPaymentProvider._id,
                    paymentProviderType: this.selectedPaymentProvider._type,
                    merchantNumber: this.selectedPaymentProvider.cardNotPresentMerchantNumber
                };
            }

        } catch (error) {
            return {};
        }
    }

    getParsePhoneNumber(phoneNumber) {
        //parsePhoneNumber libphonenumber-js library for parsing and validating international phone numbers
        if (!phoneNumber) return '';
        try {
            const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
            let libPhoneReceiptContactNumber = parsePhoneNumber(phoneNumber, locale);
            if (libPhoneReceiptContactNumber?.number) return libPhoneReceiptContactNumber.number;
            else return ''; 
        } catch (e) {
            return '';
        }
    }

    // Purchase a gift card from the Loyalty gift card shop.
    async buyGiftCard(options: any = {}) {
        // get the loyalty shop configuration
        combineLatest([
            this.publicStore.accountGuid$,
            this.publicStore.giftCardConfig$
        ])
        .pipe(take(1))
        .subscribe(async ([accountGuid, giftCardConfig]) => {

            // merge the importProcess into default settings
            const settings = merge({
                accountGuid,
                payParams: null,
            }, options);

            // loading animation
            this.appService.startBlock({ text: "PLEASE_WAIT", class: 'dark-block' });

            // build the request body
            const giftCardFormValues = this.giftCardForm.value;
            const sendToFriend = this.queryOverrides.sendToFriend;

            // if topUp, send to phone.
            let notifyRecipientBy =  this.queryOverrides.topUp ? 'phone' : (sendToFriend ? giftCardFormValues.cardDeliveryDetails.sendGiftCardTo : giftCardFormValues.cardCheckout.sendReceiptTo);

            // if topUp, send gift card to phone.
            if (this.queryOverrides.topUp) {
                notifyRecipientBy = 'phone'
            // send to friend
            } else if (sendToFriend) {
                notifyRecipientBy = giftCardFormValues.cardDeliveryDetails.sendGiftCardTo;
            // send to self, logged in / not logged in
            } else {
                notifyRecipientBy = giftCardFormValues.cardCheckout.sendReceiptToPhone ? 'phone' : 'email'
            }

            const reqBody: any = {
                sender: {
                    firstName: giftCardFormValues.cardCreationDetails[sendToFriend ? 'senderFirstName' : 'recipientFirstName'],
                    lastName: giftCardFormValues.cardCreationDetails[sendToFriend ? 'senderLastName' : 'recipientLastName'],
                    mobile: this.getParsePhoneNumber(giftCardFormValues.cardCheckout.receiptContactPhone),
                    email: giftCardFormValues.cardCheckout.receiptContactEmail,
                    notifyBySms: giftCardFormValues.cardCheckout.sendReceiptToPhone,
                    notifyByEmail: giftCardFormValues.cardCheckout.sendReceiptToEmail
                },
                receiver: {
                    firstName: giftCardFormValues.cardCreationDetails.recipientFirstName || giftCardFormValues.cardCreationDetails.senderFirstName,
                    lastName: giftCardFormValues.cardCreationDetails.recipientLastName || giftCardFormValues.cardCreationDetails.senderLastName,
                    mobile: sendToFriend ? this.getParsePhoneNumber(giftCardFormValues.cardDeliveryDetails.recipientPhone) : this.getParsePhoneNumber(giftCardFormValues.cardCheckout.receiptContactPhone),
                    email: sendToFriend ? giftCardFormValues.cardDeliveryDetails.recipientEmail : giftCardFormValues.cardCheckout.receiptContactEmail,
                    notifyBySms: notifyRecipientBy == 'phone',
                    notifyByEmail: notifyRecipientBy == 'email'
                },
                payment: {
                    paymentId: this.temporaryPaymentDetails.paymentId,
                    paymentAccount: null,
                    paymentProviderType: null,
                    providerTransactionToken: this.temporaryPaymentDetails.providerTransactionToken,
                    params: settings.payParams
                },
                amount: giftCardFormValues.cardCreationDetails.cardLoadAmount,
                orderId: this.temporaryPaymentDetails.orderId,
                message: giftCardFormValues.cardCreationDetails.optionalMessage,
                organizationId: giftCardConfig.siteId,
                isSelfOrder: !sendToFriend,
            }
            // adding phone verifier to request
            if (this.verifierPhone) reqBody.verifier = { phoneNumber: this.verifierPhone };
            // include address details
            if (giftCardFormValues.cardCheckout.receiptContactZipCode || settings?.paymentVerificationDetails) {
                reqBody.payment.paymentVerificationDetails = {
                    zipCode: giftCardFormValues.cardCheckout.receiptContactZipCode || settings?.paymentVerificationDetails?.zip,
                }
                if (giftCardFormValues.cardCheckout.receiptContactState || settings?.paymentVerificationDetails?.state) {
                    reqBody.payment.paymentVerificationDetails.address1 = giftCardFormValues.cardCheckout.receiptContactAddress || settings?.paymentVerificationDetails?.address;
                        reqBody.payment.paymentVerificationDetails.city = giftCardFormValues.cardCheckout.receiptContactCity || settings?.paymentVerificationDetails?.city;
                        reqBody.payment.paymentVerificationDetails.state = giftCardFormValues.cardCheckout.receiptContactState || settings?.paymentVerificationDetails?.state;
                }
            }

            // Loyalty reports
            if (this.privateStore._campaign?.value) {
                reqBody.campaign = this.privateStore?._campaign?.value;
            }

            const payParams = this.createPaymentAccountDetails(giftCardConfig);
            this.giftCardPaymentMethods = giftCardConfig.configuration.paymentMethods[0]['tenderType'];
            this.giftCardIsWallet = this.selectedOrgWalletPaymentProviderType?.id ? true: false;

            // * user is logged in
            if (this.userLoggedIn) {

                // * top-up of existing card
                if (this.queryOverrides.topUp) {
                    reqBody.receiver.cardNumber = this.appService.user?.loyaltyCustomer?.CardNumber;
                    reqBody.sender.cardNumber = this.appService.user?.loyaltyCustomer?.CardNumber;

                    const errorText = 'No loyalty Customer "CardNumber" found!';
                    if (!reqBody.receiver.cardNumber) console.error(errorText);
                }

                // * user chose to pay via saved wallet
                if (this.selectedOrgWalletPaymentProviderType?.id) {

                    reqBody.payment.params = {
                        walletPayment : this.selectedOrgWalletPaymentProviderType.id,
                        cvv: this.selectedOrgWalletPaymentProviderType.cvv,
                        idCard: this.selectedOrgWalletPaymentProviderType.idCard,
                    }

                    // check the payment option for idCard/CVV validity and prompt to fill missing details
                    try {
                        const updatedPaymentMethod = await this.addMissingWalletPaymentDetails(this.selectedOrgWalletPaymentProviderType);
                        if (updatedPaymentMethod.idCard || updatedPaymentMethod.cvv) merge(reqBody.payment.params, updatedPaymentMethod);
                    
                    // "missing details" dialog was dismissed. abort operation.
                    } catch (error) {
                        this.appService.stopBlock();
                        return false;
                    }

                    // clean irrelevant pay params
                    reqBody.payment.params =  pick(reqBody.payment.params, ['cvv', 'idCard', 'walletPayment']);

                    reqBody.payment.paymentProviderType = 'RosWallet';

                // * user chose to pay via the shop's configured payment-provider
                } else {
                    reqBody.payment.paymentProviderType = payParams.paymentProviderType;
                }

                // if user chose to pay with his wallet, the specific wallet paymentAccount should be used
                reqBody.payment.paymentAccount = (this.selectedOrgWalletPaymentProviderType?.id && this.selectedOrgWalletPaymentProviderId) ?  this.selectedOrgWalletPaymentProviderId : payParams.paymentAccount;

            // * user not logged in, pay via the configured store payments providers
            } else {
                // build the payment provider and finance data
                reqBody.payment.paymentProviderType = payParams.paymentProviderType;
                reqBody.payment.paymentAccount = payParams.paymentAccount;
            }

            // no paymentAccount or paymentProviderType found. can't proceed.
            if (!payParams.paymentAccount || !payParams.paymentProviderType) {
                return throwError('No paymentAccount or paymentProviderType provided!');
            }

            // special param only for creditGuard
            if (payParams.merchantNumber) {
                reqBody.payment.params.merchantNumber = payParams.merchantNumber
            }

            // special param only for Stripe
            if (settings.payParams?.id) {
                reqBody.payment.params = {
                    providerTransactionId: settings.payParams.id
                };
            }

            // We need it here for the final log
            const logPayment = {
                accountName: payParams?.paymentProviderType || payParams.paymentAccount || reqBody.payment?.params || reqBody.payment?.params, // e.g 'אשראי' / 'סיבוס'
                tenderType: 'Credit', // e.g credit / charge / prepay
                amount: settings.payParams?.amount || reqBody.amount,
                confirmationNum: settings?.payParams?.paymentProcessId || reqBody.payment?.params?.providerTransactionId || reqBody.payment?.providerTransactionToken || settings?.payParams?.token || settings?.payParams?.token_value
            }

            // logger for the buyGiftCard
            this.logger(null, 'Attempt buying a gift card', reqBody, { payment: logPayment });

            // buy the gift card at the loyalty api
            return this.loyaltyService.buyGiftCard(this.appService.removeNullProperties(reqBody, true), accountGuid)
            .pipe(
                take(1),
                finalize(() => this.appService.stopBlock()),
            ).subscribe((response: any) => {
                this.logger(response.IsSuccess, response.Message, null, { payment: logPayment });
                this.ngZone.run(() => {
                    // Needed to listen to events inside the Adyen dialog
                    this.giftCardPurchaseResponse.next(response);
                });

                // successful payment
                if (response.IsSuccess) {
                    this.setQueryParam({ result: 'success' });
                    let primaryButtonActions: any = { navigateToStoreRedirectLink: true };
                    let secondaryButtonActions: any = { resetShopProcess: true };
                    if (this.isSignedUserSelfTopUp) {
                        primaryButtonActions.showDetailsWithNewBalance = true;
                        secondaryButtonActions.showDetailsWithNewBalance = true;
                    };
                    this.showSuccessDialog({
                        primaryButtonActions,
                        secondaryButtonActions,
                        hideXIcon: true
                    })

                    if (this.userLoggedIn) {
                        // update missing user wallet details
                        if (this.selectedOrgWalletPaymentProviderType?.updatePaymentInfo) {
                            this.entityService.addPaymentMethodToWallet(this.selectedOrgWalletPaymentProviderType)
                        }
                        // top up: reflect new balance
                        if (this.queryOverrides.topUp) {
                            this.publicStore.giftCardConfig$
                            .pipe(take(1))
                            .subscribe((giftCardConfig: loyaltyGiftCardSettings) => {
                                const amountWithBonus = this.displayAmountBonus(giftCardConfig?.configuration?.bonusPercentage, this.cardCreationDetailsControl?.value.cardLoadAmount);
                                this.appService.user.loyaltyCustomer.PrePaidBalance = this.appService.user.loyaltyCustomer.PrePaidBalance + amountWithBonus;
                            })
                        }
                    }
                // error in payment
                } else {
                    this.logger(false, response.Message, response, response, true);
                    this.handleErrorMessage(response?.Message, response?.status);
                }
            }, error => {
                this.ngZone.run(() => {
                    // Needed to listen to events inside the Adyen dialog
                    this.giftCardPurchaseResponse.next(error?.error);
                    // Adyen 3DS flow
                    if (error?.error?.problems?.Key == 'PaymentActionRequired') {
                        this.logger(false, 'Gift Card Adyen Payment 3DS response', error, error, true);
                        return;
                    }

                    this.setQueryParam({ result: 'fail' });
                    this.logger(false, 'Failure in buying gift card', error, error, true);
                    this.handleErrorMessage(error?.error?.problems?.Message || error?.message, error?.status);
                });

            })
        })

    }

    // get card-redeem details
    getGiftCardsDetails(giftCardId: string): Observable<giftCardDetails> {
        return this.loyaltyService.getGiftCardDetails(giftCardId);
    }

    showSuccessDialog(options: MainMessageDialogOptions = {}): void {
        // merge the importProcess into default settings
        const dialogData: MainMessageDialogOptions = merge({
            dialogType: 'success',
            dialogTitle: this.getDialogTitle('success'),
            dialogText: this.getDialogText('success'),
            primaryButtonText: this.getPrimaryButtonText('success'),
            primaryButtonActions: {},
            secondaryButtonText: this.getSecondaryButtonText('success'),
            secondaryButtonActions: {},
            closeOnIdleTimeout: true, // count down 15 seconds and close the dialog,
            hideXIcon: true,
            dialogCustomIcon: 'gcs-thanks'
        }, options);

        const dialogRef  = this.dialog.open(MainMessageDialogComponent, {
            panelClass: ['gift-cards','main-message-dialog-container'],
            data: dialogData,
            position: {
                top: '4rem'
            },
            disableClose: true
        });

        this.appService.centerDialogContainer(dialogRef);

        dialogRef.afterClosed().subscribe(result => {

            // optionally - navigate to the custom redirect-url provided in the loyalty gift-card-shop settings
            if (result?.buttonsCallbackOptions?.navigateToStoreRedirectLink) {
                this.publicStore.giftCardConfig$
                .pipe(take(1))
                .subscribe((config: loyaltyGiftCardSettings) => {
                    // navigate to external route or reset process
                    if (config.configuration.redirectUrl || window['cordova']) {
                        // If we're in the app, redirect back to the dashboard
                        // else, we'll need to pass other route to redirect
                        if (window['cordova']) return this.appService.redirect(['/home/dashboard']);
                        window.location.href = config.configuration.redirectUrl
                    } else {
                        this.setStepIndex(0, true);
                    }
                })
            }

            // optionally - reset the shop process
            if (result?.buttonsCallbackOptions?.resetShopProcess) {
                this.setStepIndex(0, true);
            }

            // optionally - reset the shop process
            if (result?.buttonsCallbackOptions?.showDetailsWithNewBalance) {
                this.showBenefitsWithNewBalance();
            }
        })
    }

    handleErrorMessage(message?: string, errorStatus?: number): void {
        const errorDialogMessage = {
            dialogText: this.appService.translate('GIFT_CARD_SHOP.modals.error.body_general_error'),
            hideSecondaryButton: true,
            backdropClass: null
        };

        if (message) {
            if (message.includes('409188')) {
                errorDialogMessage.dialogText = this.appService.translate('GIFT_CARD_SHOP.modals.error.body_validation_mismatch');
            } else if (
                message.includes('409142') ||
                message.includes('409031') ||
                (message.includes('409023') && !(message.includes('internalError') && errorStatus === 500))
            ) {
                errorDialogMessage.dialogText = this.appService.translate('GIFT_CARD_SHOP.modals.error.body_card_declined');
            } else if (
                message.includes('400001') ||
                message.includes('409075') ||
                message.includes('409091') ||
                message.includes('409092') ||
                message.includes('409409') ||
                (message.includes('409023') && !(message.includes('internalError') && errorStatus === 500)) ||
                [404, 501, 502, 503, 504].includes(errorStatus)
            ) {
                const restaurantPhone = this.privateStore?._giftCardConfig?.value?.configuration?.additionalDetails?.phone;
                errorDialogMessage.dialogText = this.appService.translate('GIFT_CARD_SHOP.modals.error.body_error_occurred',  { phone: restaurantPhone });
            }
        }

        // reCAPTCHA unauthorized error
        if (errorStatus == 403) errorDialogMessage.dialogText = 'MESSAGES.SESSION_VALIDATION_FAILED';

        this.showErrorDialog(errorDialogMessage);
    }

    showErrorDialog(options: MainMessageDialogOptions = {}): void {
        // merge the importProcess into default settings
        const dialogData: MainMessageDialogOptions = merge({
            dialogType: 'error',
            dialogTitle: this.getDialogTitle('error'),
            dialogText: this.getDialogText('error'),
            primaryButtonText: this.getPrimaryButtonText('error'),
            primaryButtonActions: {},
            secondaryButtonText: this.getSecondaryButtonText('error'),
            secondaryButtonActions: {},
            hideSecondaryButton: true,
            backdropClass: 'dialog-backdrop-dark',
            hideXIcon: true
        }, options);

        const dialogRef = this.dialog.open(MainMessageDialogComponent, {
            panelClass: ['gift-cards', 'main-message-dialog-container'],
            backdropClass: dialogData.backdropClass,
            data: dialogData,
            position: {
                top: '4rem'
            },
            disableClose: true
        });

        this.appService.centerDialogContainer(dialogRef);

        dialogRef.afterClosed().subscribe(returnValue => {

            // optionally - navigate to the custom redirect-url provided in the loyalty gift-card-shop settings
            if (returnValue?.buttonsCallbackOptions?.navigateToStoreRedirectLink) {
                this.publicStore.giftCardConfig$
                .pipe(take(1))
                .subscribe((config: loyaltyGiftCardSettings) => {
                    // navigate to external route or go back
                    if (config?.configuration?.redirectUrl) {
                        window.location.href = config.configuration.redirectUrl
                    } else {
                        this.appService.goBack();
                    }
                })
            }

            // optionally - reload the web page
            if (returnValue?.buttonsCallbackOptions?.reloadWebPage) {
                window.location.reload();
            }

            // optionally - reset the shop process
            if (returnValue?.buttonsCallbackOptions?.resetShopProcess) {
                this.setStepIndex(0, true);
            }

            // navigate back
            if (returnValue?.buttonsCallbackOptions?.navigateBackOnClose) {
                this.appService.goBack();
            }
        })
    }

    getDialogTitle(type: 'success' | 'error') {
        let title;

        switch (type) {
            case 'success':
                title = 'GIFT_CARD_SHOP.modals.success.title';
                break;
            case 'error':
                title = 'GIFT_CARD_SHOP.modals.error.title';
                break;
        }

        return title;
    }

    getDialogText(type: 'success' | 'error') {
        let text;

        switch (type) {
            case 'success':
                text = this.isSignedUserSelfTopUp ? 'GIFT_CARD_SHOP.modals.success.top_up.body' : 'GIFT_CARD_SHOP.modals.success.body';
                break;
            case 'error':
                text = 'GIFT_CARD_SHOP.modals.error.body';
                break;
        }

        return text;
    }

    getPrimaryButtonText(type: 'success' | 'error') {
        let text;

        switch (type) {
            case 'success':
                text = 'GIFT_CARD_SHOP.modals.success.primary_button';
                break;
            case 'error':
                text = 'GIFT_CARD_SHOP.modals.error.primary_button';
                break;
        }

        return text;
    }

    getSecondaryButtonText(type: 'success' | 'error') {
        let text;

        switch (type) {
            case 'success':
                text = this.isSignedUserSelfTopUp ? 'GIFT_CARD_SHOP.modals.success.top_up.secondary_button' : 'GIFT_CARD_SHOP.modals.success.secondary_button';
                break;
            case 'error':
                text = 'GIFT_CARD_SHOP.modals.error.secondary_button';
                break;
        }

        return text;
    }

    showBenefitsWithNewBalance() {
        let benefitDetailsData = this.appService.benefitDetailsDataSubject.getValue();
        benefitDetailsData.points[0].prePaid = this.appService.user.loyaltyCustomer.PrePaidBalance;
        this.appService.benefitDetailsDataSubject.next(benefitDetailsData);
        this.dialogsService.showBenefitDetailsDialog();
    }

    async showPaymentDialog(iframeUrl?: string, giftCardConfig?: loyaltyGiftCardSettings, customSuccessCallback?, additionalData?: any) {
        // * pay via wallet saved payment provider
        if (this.userLoggedIn && this.selectedOrgWalletPaymentProviderType?.id) {
            return this.buyGiftCard();
        }

        // For AuthorizeNet we open a specific dialog
        if (this.selectedPaymentProvider._type == 'AuthorizeNetPaymentProvider') {
            const args = {
                ...this.selectedPaymentProvider,
                ...additionalData,
                amount: this.cardCreationDetailsControl?.value?.cardLoadAmount
            }
            return this.handleAuthorizeNetPayment(args);
        }

        // For Adyen we open a specific dialog
        if (this.selectedPaymentProvider._type == 'AdyenPaymentProvider') {
            const showApplePayButton = get(additionalData, 'clientConfiguration.paymentMethodsConfiguration.applepay', null);
            const surcharges = additionalData.surcharges;

            if (showApplePayButton && surcharges) {
                additionalData.showApplePayButton = this.appService.platformService.SAFARI && (this.appService.isMobile() ? true : this.deviceService.getDeviceInfo().browser == 'Safari');

                if (additionalData.showApplePayButton) {
                    const applePaySurcharge = surcharges['applepay'] || surcharges['other'] || 0;
                    if (additionalData.clientConfiguration?.paymentMethodsConfiguration?.applepay?.amount?.value) additionalData.clientConfiguration.paymentMethodsConfiguration.applepay.amount.value += applePaySurcharge;
                } else {
                    // Disable Apple Pay for Chrome
                    if (additionalData.clientConfiguration.paymentMethodsConfiguration.applepay) delete additionalData.clientConfiguration.paymentMethodsConfiguration.applepay;
                }

                const paymentMethods = sortPaymentMethods(additionalData.clientConfiguration.paymentMethodsResponse.paymentMethods);
                if (paymentMethods?.length) additionalData.clientConfiguration.paymentMethodsResponse.paymentMethods = paymentMethods;

                // Function to move the "applepay" payment method to the last position
                function sortPaymentMethods(paymentMethods: any[]) {
                    const filteredMethods = paymentMethods.filter(method => !['applepay', 'googlepay'].includes(method.type));
                    const applePayMethod = paymentMethods.find(method => method.type === 'applepay');
                    const googlePayMethod = paymentMethods.find(method => method.type === 'googlepay');
                    if (applePayMethod && additionalData.showApplePayButton) {
                        filteredMethods.push(applePayMethod);
                    }
                    if (googlePayMethod) {
                        filteredMethods.push(googlePayMethod);
                    }

                    return filteredMethods;
                }
            }
            const args = {
                ...this.selectedPaymentProvider,
                ...additionalData,
                amount: this.cardCreationDetailsControl?.value?.cardLoadAmount * 100
            }
            return this.handleAdyenPayment(args);
        }

        const dialogSettings: any = {
            paymentDialogRef: PaymentDialogComponent,
            panelClasses: ['gift-card-payment-dialog-container'],
            backdropClass: null,
            bindings: {
                // define whether to show an iframe or a native form
                formType: 'genericIframeForm', 
                trustedIframeUrl: null,
                customFormTitle: null,
                amount: this.cardCreationDetailsControl?.value.cardLoadAmount
            }
        }

        // "Card-Pointe": embed the iframe payment-fields in a native form
        if (this.selectedPaymentProvider._type == 'CardPointePaymentProvider') {

            dialogSettings.bindings.formType = 'cardPointeForm';
            dialogSettings.panelClasses.push('embedded-payment-iframe');

            // add css properties manually as queryParams (yep..) 
            const formWidth = window.innerWidth <= 320 ? 250 : 300;
            const selectWidth = window.innerWidth <= 320 ? 110 : 135;
            const iframeCss = `body,input,select{font-family:sans-serif;box-sizing:border-box;border-radius:5px;margin:0;}form{overflow:hidden;margin:0;auto;width:${formWidth}px;padding-top:25px}.error{color:red}input,select{font-size:16px;height:40px;margin:5px 0 15px;width:100percent;border:2px solid ${giftCardConfig.configuration?.colors?.primary};}select{width:${selectWidth}px}`
            iframeUrl += '&inactivityto=100&tokenizewheninactive=true&unique=true&formatinput=true&css=' + encodeURI(iframeCss).replace(/#/g, '%23').replace(/percent/g, '%25');

            dialogSettings.bindings.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);

        // "Cibus": show a native form, no iframe needed
        } else if (this.selectedPaymentProvider._type == 'CibusPaymentProvider') {

            dialogSettings.bindings.formType = 'cibusForm';
            dialogSettings.panelClasses.push('non-iframe-dialog');
            dialogSettings.bindings.customFormTitle = this.appService.translate('GIFT_CARD_SHOP.modals.pay.pay_with_cibus');

        // "Heartland": triggers remote rendering of local form input
        } else if (this.selectedPaymentProvider._type == 'HeartlandPaymentProvider') {

            dialogSettings.bindings.formType = 'heartlandForm';
            dialogSettings.panelClasses.push('heartland-payment-dialog-container');
            dialogSettings.backdropClass = 'dark-backdrop';

            // temporarily save the HeartLand public API key
            if (this.temporaryPaymentDetails?.paymentDialogInitToken) {
                dialogSettings.bindings.paymentDialogInitToken = this.temporaryPaymentDetails.paymentDialogInitToken;
            }
        }

        else if (this.selectedPaymentProvider._type == 'StripePaymentProvider') {

            dialogSettings.bindings.formType = 'stripeForm';
            dialogSettings.bindings.providerTransactionToken = this.temporaryPaymentDetails?.additionalData?.providerTransactionToken;
            dialogSettings.bindings.stripePublishableKey = this.appConfig?.stripePublishableKey;
            dialogSettings.bindings.providerAccountId = giftCardConfig.configuration?.paymentMethods[0]?.providerAccountId;
            dialogSettings.bindings.giftCardServiceInstance = this;
            dialogSettings.disableClose = true;

        // generic credit card iframe form
        } else {
            dialogSettings.bindings.formType = 'genericIframeForm';
            dialogSettings.panelClasses.push('generic-iframe-dialog');
            dialogSettings.bindings.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
            dialogSettings.bindings.sandboxAttributes = 'allow-scripts';
            dialogSettings.bindings.selectedPaymentProviderType = this.selectedPaymentProvider._type || additionalData?.selectedPaymentProvider;
        }

        if (this.appService.isIOS) dialogSettings.panelClasses.push('is-ios');

        const dialogRef = this.dialog.open(dialogSettings.paymentDialogRef, {
            panelClass: dialogSettings.panelClasses,
            backdropClass: dialogSettings.backdropClass,
            disableClose: dialogSettings.disableClose,
            data: dialogSettings.bindings
        });
        this.manageCardCreationFormInput(true);

        dialogRef.afterClosed()
        .subscribe(paymentCallback => {
            this.manageCardCreationFormInput(false);
            // turn the stringified query params to an object
            const searchParams = new URLSearchParams(paymentCallback?.redirectUrl);
            let queryParams: any = {};
            const accountGuid = searchParams.get('accountGuid')

            // extract query params from the redirectUrl
            searchParams.forEach((val, key) => {
                if (key.includes('deposit-payment') || key.includes('deposit')) return;

                if ((!key.includes('gift-cards/create-card') || dialogSettings.bindings.formType == 'cardPointeForm') && !['accountGuid', 'step'].includes(key)) {
                    queryParams[key] = val;
                }
            });

            // if one of those is true, the transaction was successful
            const successfulTransactionTerms = [
                queryParams.ExpressResponseMessage == 'Approved', // 1. approved status
                (queryParams.pid == 3 && queryParams.cgUid), // 2. queryParams.pid == 3 id creditGuard, and the only if cgUid not empty
                paymentCallback?.tokenizedForm, // 3. payment confirmation (token) via iframe message event
                paymentCallback?.cibusForm, // 4. Cibus payment
                paymentCallback?.heartlandForm, // 4. Heartland payment
                paymentCallback?.stripeForm, // 4. Heartland payment
            ]

            // merge native non-iframe form properties
            if (paymentCallback?.cibusForm) {
                queryParams = extend(queryParams, paymentCallback.cibusForm);

            // merge native non-iframe form properties
            } else if (paymentCallback?.heartlandForm) {
                queryParams = extend(queryParams, paymentCallback.heartlandForm);
            
            } else if (paymentCallback?.stripeForm) {
                queryParams = paymentCallback?.stripeForm;
            }

            // check for any successful payment term
            if (successfulTransactionTerms.some(item => !!item)) {
                let data = {
                    accountGuid: accountGuid,
                    payParams: queryParams,
                };
                // Custom callback
                if (customSuccessCallback && typeof customSuccessCallback == 'function') return customSuccessCallback(data);
                // register the payment at the Loyalty api
                else this.buyGiftCard(data);
            // error in payment
            } else if (queryParams.done == '1') {
               // If the user chose "Cancel" from within the CreditGuard dialog (iframe) then we get back a response without cgUid but WITH "done: '1'"
               // In that case (because the user clicked "Cancel") we don't want to show him/her the "Error Dialog".
               // That's why we don't do anything here.
            } else if (paymentCallback) {
                this.handleErrorMessage();
            }
        })
    }

    updatePrivateStore(key: string, value: any): void {
        this.privateStore[key].next(cloneDeep(value));
    }

    initializeStorageData(): void {
        // initialize the user browser-storage data
        try {
            this.browserStorage.giftCardsViewed = JSON.parse(localStorage.getItem('giftCardsViewed')) || [];
        } catch (error) {
            this.browserStorage.giftCardsViewed = [];
        }
    }

    markGiftCardAsViewed(giftCardId: string): void {
        // save parsed data for the session
        this.browserStorage.giftCardsViewed.push(giftCardId);
        // save stringified data in browser storage
        localStorage.setItem('giftCardsViewed', JSON.stringify(this.browserStorage.giftCardsViewed));
    }

    // temporarily save the getPaymentLink response, to be added later to the buyGiftCard request
    setTempPaymentDetails(data): void {
        this.temporaryPaymentDetails = {
            providerTransactionToken: data?.providerTransactionToken,
            paymentId: data?.payment?.id || null,
            orderId: data?.orderId,
            paymentDialogInitToken: data.publicApiKey,
            paymentProcessId: data.paymentProcessId,
            additionalData: data
        }
    }

    // transform hex to slightly-transparent rgba
    hexToRgba(colorCode: string, opacity: number = 0.7): string {
        return colorCode.includes('rgba') ? colorCode : hexRgb(colorCode, {format: 'css', alpha: opacity});
    }

    // verify that value exists and is number / stringified number
    isNumeric(value): boolean {
        return !!_toString(value).match(/^[0-9]+$/);
    }

    setQueryParam(param: Params): void {
        // for accessibility
        if ((param.step || param.step == 0) && this.router['currentUrlTree'].queryParams.step != param.step) {
            this.focusTitle();
        }

        const navigationExtras: NavigationExtras = {
            relativeTo: this.route,
            queryParams: param,
            queryParamsHandling: 'merge',
        }

        if (param.result == 'new' && this.route?.snapshot?.queryParams) {
            let queryParams = { ...this.route.snapshot.queryParams };
            if (!queryParams.result) return
            delete queryParams.result;
            navigationExtras.queryParams = queryParams;
            delete navigationExtras.queryParamsHandling;
        }

        this.router.navigate([], navigationExtras);

    }

    focusTitle() {
        setTimeout(() => {
            const selectedListItem: HTMLElement = document.querySelector('page-header .header-title');
            if (selectedListItem) selectedListItem.focus();
        }, 800);
    }

    // pay via the configured store payments providers
    selectWalletPaymentProvider(wallet): any {
        this.selectedOrgWalletPaymentProviderType = cloneDeep(wallet);
    }

    get cardDeliveryDetailsControl(): AbstractControl {
        return this.giftCardForm.get('cardDeliveryDetails');
    }

    get cardCreationDetailsControl(): AbstractControl {
        return this.giftCardForm.get('cardCreationDetails');
    }

    get cardCheckoutControl(): AbstractControl {
        return this.giftCardForm.get('cardCheckout');
    }

    // user is logged in and sends a gift card to himself
    get isSignedUserSelfTopUp(): boolean {
        return this.userLoggedIn && !this.queryOverrides.sendToFriend && this.queryOverrides.topUp;
    }

    // user isn't logged in and sends a gift card to himself
    get isUnsignedUserSendToSelf(): boolean {
        return !this.userLoggedIn && !this.queryOverrides.sendToFriend;
    }

    // Prevent user input in the card creation form when the payment dialog is open,
    // as Chrome version 119 updates the first name during payment autocompletion and erases it.
    private manageCardCreationFormInput(disable: boolean): void {
        const recipientFirstNameControl = this.cardCreationDetailsControl.get('recipientFirstName');
        const recipientLastNameControl = this.cardCreationDetailsControl.get('recipientLastName');
    
        if (disable) {
            recipientFirstNameControl.disable();
            recipientLastNameControl.disable();
        } else {
            recipientFirstNameControl.enable();
            recipientLastNameControl.enable();
        }
    }

    displayAmountBonus(bonusPercent, baseAmount) {
        baseAmount = parseFloat(baseAmount);
        if (isNaN(baseAmount)) return 0; 
        const bonusAmount = bonusPercent ? (baseAmount * (bonusPercent / 100)) : 0;
        return baseAmount + bonusAmount;
    }

    mergeLoyaltyUserDetails(loyaltyCustomer) {
        if (!loyaltyCustomer || !this.appService.isApp) return;

        // send to friend
        if (this.queryOverrides.sendToFriend) {
            this.cardCreationDetailsControl.patchValue({
                senderFirstName: loyaltyCustomer.FirstName,
                senderLastName: loyaltyCustomer.LastName,
                recipientFirstName: '',
                recipientLastName: '',
            }, { emitEvent: false });

            this.cardDeliveryDetailsControl.patchValue({
                recipientPhone: '',
                recipientEmail: '',
                recipientState: '',
                recipientCity: '',
                recipientAddress: '',
                recipientZipCode: '',
            }, { emitEvent: false });

            // send to self
        } else {
            this.cardCreationDetailsControl.patchValue({
                recipientFirstName: loyaltyCustomer.FirstName,
                recipientLastName: loyaltyCustomer.LastName,
                senderFirstName: loyaltyCustomer.FirstName,
                senderLastName: loyaltyCustomer.LastName,
            }, { emitEvent: false });

            this.cardDeliveryDetailsControl.patchValue({
                recipientPhone: loyaltyCustomer.Mobile,
                recipientEmail: loyaltyCustomer.Email,
                recipientState: '',
                recipientCity: '',
                recipientAddress: '',
                recipientZipCode: '',
            }, { emitEvent: false });
        }

        // send payment approval to the signed-in user
        this.cardCheckoutControl.patchValue({
            receiptContactPhone: loyaltyCustomer.Mobile,
            receiptContactEmail: loyaltyCustomer.Email,
            receiptContactState: '',
            receiptContactCity: '',
            receiptContactAddress: '',
            receiptContactZipCode: '',
        }, { emitEvent: false })
    }

    showDetailsWithNewBalance() {

    }
    
    // check the payment option for idCard/CVV validity and prompt to fill missing details.
    //> mutates the ccinfo with "updatePaymentInfo" if found missing info.
    async addMissingWalletPaymentDetails(ccinfo): Promise<any> {

        let site = this.organizationsService.selectedOrganizationConfig;

        let requireIdCard: boolean = site?.config?.settings?.requireIdPhoneTrans && !ccinfo.idCard;
        let requireCVV: boolean = !ccinfo.cvv;

        // save details only for users with wallet, and payment-type of "creditCard"
        if (!ccinfo.id || ccinfo.paymentType !== "creditCard") return Promise.resolve(ccinfo);
        if (!requireCVV && !requireIdCard) return Promise.resolve(ccinfo);

        // site requires additional card details. user needs to fill missing required fields
        ccinfo.updatePaymentInfo = true;

        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                let dialogRef = this.dialog.open(ToPaymentDialogComponent, {
                    width: "300px",
                    backdropClass: 'dialog-backdrop-darker',
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    disableClose: true,
                    data: {
                        walletPayment: ccinfo,
                        enableSaveInWallet: false,
                        ccinfo: {
                            paymentMethod: "wallet",
                            requireCVV: requireCVV,
                            requireIdCard: requireIdCard
                        },
                        customTitle: this.translateService.instant('PAYMENT_INFO_MISSING', {cardLastDigits: ccinfo.displayPan})
                    },
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(response => {
                    response = get(response, 'ccinfo');

                    if (response) {
                        if (requireCVV) ccinfo.cvv = response["cvv"];
                        if (requireIdCard) ccinfo.idCard = response["idCard"];
                        resolve(ccinfo);
                        
                    // "missing details" dialog was dismissed
                    } else {
                        reject();
                    }
                });
            });
        });
    }

    // inject shop-specific styles into the DOM
    appendShopStyle(config) {

        const primaryColor = config.configuration?.colors?.primary;
        const secondaryColor = config.configuration?.colors?.secondary;
        const headerBackgroundColor = config.configuration?.colors?.headerBackground || '#000';
        const headerTitleColor = config.configuration?.colors?.headerTitle || '#fff';
        const style = document.createElement('style');
        if (primaryColor) {
            let filter = this.styleService.rgb2hex(primaryColor.trim());
            this.styleService.setSvgColorMatrix(filter);
        }
        style.innerHTML += `
            gift-cards mat-form-field.card-amount-container .mat-mdc-form-field-label {color: ${primaryColor} !important;}
            gift-cards mat-form-field.card-amount-container .mat-mdc-form-field-ripple {background-color: ${primaryColor} !important;}
            gift-cards mat-form-field .mat-mdc-input-element {caret-color: ${primaryColor};}
            gift-cards gift-cards-wallet-items .wallet-option.selected-wallet-option {border-color: ${primaryColor} !important;}

            gift-cards button.language {background-color: ${primaryColor} !important;}

            gift-cards choose-amount .mat-mdc-form-field-underline, gift-cards choose-amount .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {background-color: ${primaryColor} !important;}
            gift-cards choose-amount .amount-options-container .base-amount-with-bonus * {color: ${primaryColor} !important;}
            .gift-cards .mat-mdc-dialog-content .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label {color: ${primaryColor} !important;}
            gift-cards choose-amount .row-fields .wheel-selection-container .wheel-selection-header .wheel-selection-icon svg g .dynamic-stroke { stroke: ${primaryColor} !important; }
            .wheel-selection-list .amount-options-container .base-amount-with-bonus * {color: ${primaryColor} !important;}

            /* gift-cards mat-form-field.mat-mdc-focused .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick {color: ${primaryColor}; caret-color: ${primaryColor};}
            gift-cards mat-form-field.mat-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick {color: ${primaryColor} !important; caret-color: ${primaryColor};}
            app-payment-dialog mat-form-field.mat-mdc-focused .mat-mdc-form-field-outline.mat-mdc-form-field-outline-thick {color: ${primaryColor}; caret-color: ${primaryColor};} */
            gift-cards .mat-mdc-checkbox.mat-primary {
                --mdc-checkbox-selected-focus-icon-color: ${primaryColor};
                --mdc-checkbox-selected-hover-icon-color: ${primaryColor};
                --mdc-checkbox-selected-icon-color: ${primaryColor};
                --mdc-checkbox-selected-pressed-icon-color: ${primaryColor};
                --mdc-checkbox-selected-focus-state-layer-color: ${primaryColor};
                --mdc-checkbox-selected-hover-state-layer-color: ${primaryColor};
                --mdc-checkbox-selected-pressed-state-layer-color: ${primaryColor};
            }
            gift-cards, .gift-cards {
                --mdc-outlined-text-field-caret-color: ${primaryColor};
                --mdc-outlined-text-field-focus-outline-color: ${primaryColor};
                --mdc-outlined-text-field-focus-label-text-color: ${primaryColor};
                --mat-form-field-focus-select-arrow-color: ${primaryColor};
                --mdc-filled-text-field-caret-color: ${primaryColor};
                --mdc-filled-text-field-focus-active-indicator-color: ${primaryColor};
                --mdc-filled-text-field-focus-label-text-color: ${primaryColor};
            }
            gift-cards .row-fields mat-form-field .free-text-amount::placeholder {
                color: ${primaryColor}!important;
            }
            .gc-language-menu-selected {
                color: ${primaryColor}!important;
            }
            .gc-language-menu-selected svg {
                color: ${primaryColor}!important;
                fill: ${primaryColor}!important;
            }
            gift-cards .mat-mdc-checkbox-checked.mat-mdc-primary .mat-mdc-checkbox-background {background-color: ${primaryColor} !important;}
            gift-cards .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background { border-color: ${primaryColor} !important; }
            .gift-cards .page-actions .secondary-action-button { background-color: transparent !important; border: 1px solid ${primaryColor} !important; color: black !important; }
            .gift-cards .mat-mdc-dialog-container .mat-mdc-input-element {caret-color:${primaryColor} !important;}
            gift-cards .page-actions .enabled-button, .gift-cards .mat-mdc-dialog-actions [type="submit"], .gift-cards .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple, .gift-cards .page-actions .primary-action-button, app-payment-dialog .embedded-payment-form-button:not(.mat-mdc-button-disabled) {background-color: ${primaryColor} !important;}
            .gift-cards .mat-mdc-dialog-actions [type="submit"].disable-click { background-color: rgba(0, 0, 0, 0.5) !important; opacity: 100% !important; }
            gift-cards app-create-card .recipient-type .recipient-icon g[stroke] {stroke: ${primaryColor} !important;}
            gift-cards .continue-button-container > button {background-color: ${primaryColor} !important;}

            gift-cards .recipient-type {border-color: ${primaryColor};}
            gift-cards .recipient-type.selected {background-color: ${primaryColor};}
            gift-cards .recipient-type.selected .recipient-icon svg g .dynamic-fill { fill: transparent !important; }
            gift-cards .recipient-type.selected .recipient-icon svg g[stroke] { stroke: ${headerTitleColor} !important; }
            gift-cards page-header .header-divider {border-color: ${primaryColor};}

            main-message-dialog button mat-icon {color: ${primaryColor} !important;}
            main-message-dialog .primary-action-button {background-color: ${primaryColor} !important; color: ${headerTitleColor} !important;}
        `;
        
        // desktop/mobile mode - apply custom background to the :host component
        if (this.appService.isDesktop()) {
            style.innerHTML += `
                gift-cards {background-color: ${this.hexToRgba(secondaryColor)} !important;}
                gift-cards .gift-cards-page .gift-cards-header-area .restaurant-title {color: ${headerTitleColor} !important;}
            `;
        }

        if (this.appService.isMobile()) {
            style.innerHTML += `
                gift-cards {background-color: ${this.hexToRgba(secondaryColor)} !important;}
                /*gift-cards .gift-cards-page {background: ${headerBackgroundColor} !important;}*/
                /* gift-cards .gift-cards-page .gift-cards-header-area {background-color: ${headerBackgroundColor} !important;} */
                gift-cards .gift-cards-page .gift-cards-header-area .restaurant-title {color: ${headerTitleColor} !important;}
                gift-cards .gift-cards-page .gift-cards-header-area .divider {border-color: ${headerTitleColor} !important;}
            `
        }

        document.body.appendChild(style);
    }

    getPaymentProvider(giftCardConfig$: loyaltyGiftCardSettings): string {
        const provider = giftCardConfig$?.configuration?.paymentMethods[0]?._type;
        return provider;
    }

    logger(success, status, request?, additionalData?, isError?: boolean) {
        const customerData = this.getCustomerData();

        const customer = {
            ordererPhone: get(customerData, 'ordererPhone.phone'),
            recipientPhone: get(customerData, 'receiverPhone.phone'),
            email: customerData?.email,
            name: request?.sender ? request?.sender?.firstName + ' ' + request?.sender?.lastName : '',
        }

        const giftCardLogData: any = {
            additionalData,
            marketplace: this.privateStore._marketplace.value || additionalData?.marketplace,
            campaign: this.privateStore._campaign.value || additionalData?.campaign,
            sendToFriend: this.queryOverrides.sendToFriend,
            topUp: this.queryOverrides.topUp,
            paymentMethods: this.giftCardPaymentMethods,
            isWallet: this.giftCardIsWallet,
            orderID: this.temporaryPaymentDetails.orderId,
            isSuccess: success,
            statusDescription: status,
            amount: this.giftCardForm?.value?.cardCreationDetails?.cardLoadAmount,
            request: request ? JSON.stringify(request) : '',
            siteId: this.privateStore?._giftCardConfig?.value?.siteId,
            siteName: this.privateStore?._giftCardConfig?.value?.configuration?.restaurantName,
            customer
        };
        const message = `GiftCardsPayment status: ${status}`;

        const logObject = { message, giftCard: giftCardLogData, module: 'giftCard', auditData: null };
        if (isError && additionalData) logObject.auditData = additionalData;

        this.appService.logger(logObject);
    }

    // Get Anonymous Otp SMS Verification Code.
    getAnonymousOtpVerificationCode(mobile: string) {
        return new Promise((resolve, reject) => {
            if (!mobile) return reject('body is missing confirmOtp');
            let accountGuid: string, siteId: string;
            if (this.privateStore?._accountGuid?.value) accountGuid = this.privateStore._accountGuid.value;
            if (this.privateStore?._giftCardConfig?.value?.siteId) siteId = this.privateStore._giftCardConfig.value.siteId;
            // return subscription
            const payload = { mobile, accountGuid, siteId };
            this.loyaltyService.getAnonymousOtpVerificationCode(payload)
            .subscribe(
                result => {
                    if (result) return resolve(result);
                    return reject('error');
                }, error => {
                    reject(error);
                }
            );
        });
    }

    // Verification Otp SMS Code.
    confirmOtp(body: any) {
        return new Promise((resolve, reject) => {
            if (!body) return reject('body is missing confirmOtp');
            let accountGuid: string, siteId: string;
            if (this.privateStore?._accountGuid?.value) accountGuid = this.privateStore._accountGuid.value;
            if (this.privateStore?._giftCardConfig?.value?.siteId) siteId = this.privateStore._giftCardConfig.value.siteId;
            // return subscription
            this.loyaltyService.confirmOtp(body, accountGuid, siteId).subscribe(
                result => {
                    if (result) return resolve(result);
                    return reject('error');
                }, error => {
                    reject(error);
                }
            );
        });
    }

    showOTPDialog(args) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(SmsDialogComponent, {
                    width: "300px",
                    panelClass: ['gift-cards', 'wl-cards-background-color', 'rounded-dialog'],
                    direction: this.appService.direction,
                    data: args,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result != "") {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                });
            });
        });
    }

    isAVSHandledForProvider(config): boolean {
        const paymentProvider = this.getPaymentProvider(config);
        return !['StripePaymentProvider', 'AuthorizeNetPaymentProvider'].includes(paymentProvider);
    }

    handleAuthorizeNetPayment(args) {
        return new Promise((resolve, reject) => {
			const dialogRef = this.dialog.open(AuthorizenetPayDialogComponent, {
				direction: this.appService.direction,
				maxWidth: '90vw',
				data: {
					account: args?.paymentProvider,
					token: args.providerTransactionToken,
					total: args.amount,
                    iframeUrl: args.iframeUrl,
				},
				disableClose: true,
				closeOnNavigation: false,
				hasBackdrop: true,
				autoFocus: false
			});
			dialogRef.afterClosed()
            .subscribe(result => {
				const mode = get(result, 'mode');
                switch (mode) {
                    case "cancel":
                        resolve({ mode });
                        break;
                    case "success":
                        const data = {
                            providerTransactionToken: result.transactionId,
                            orderId: args.orderId,
                        }
                        this.setTempPaymentDetails(data);
                        return this.buyGiftCard({ paymentVerificationDetails: result?.billTo });
                    case "error":
                        reject({});
                        break;
                    default:
                        reject({});
                }
			});
		});
    }

    async stopOrderProc() {
        const customerData = this.getCustomerData();
        const payload = {
            contactData: {
                phone: customerData?.ordererPhone?.phone || customerData.receiverPhone?.phone,
                email: customerData?.email,
                deviceId: this.notificationsService.getDeviceId,
            }
        };
        const accountGuid = this.privateStore?._accountGuid?.value;
        const found = await this.loyaltyService.getOrderProcessValidation(payload, accountGuid);

        return !!found;
    }

    resetService(): void {
        // Reset private store
        this.privateStore._activeRouteId.next('create-card');
        this.privateStore._giftCardConfig.next({});
        this.privateStore._currentStepIndex.next(0);
        this.privateStore._accountGuid.next('');
        this.privateStore._giftCardId.next('');
        this.privateStore._giftCardData.next({});
        this.privateStore._chooseAmountInputState.next('');
        this.privateStore._campaign.next('');
        this.privateStore._marketplace.next(false);
	}

    public getCustomerData() {
        const locale = this.appService.appConfig.locale.slice(-2).toUpperCase();
        const giftCardFormValues = this.giftCardForm.value;

        // Orderer phone number
        const ordererPhone = get(giftCardFormValues, 'cardCheckout.receiptContactPhone', this.verifierPhone);
        const ordererParsedNumber = ordererPhone ? parsePhoneNumber(ordererPhone, locale) : null;

        // Receiver phone number
        const receiverPhone = get(giftCardFormValues, 'cardDeliveryDetails.recipientPhone');
        const receiverParsedNumber = receiverPhone ? parsePhoneNumber(receiverPhone, locale) : null;

        const customerEmail = get(giftCardFormValues, 'cardCheckout.receiptContactEmail', '') || get(giftCardFormValues, 'cardDeliveryDetails.recipientEmail', '');

        const customerData = {
            ordererPhone: {
                phone: ordererPhone,
                parsedPhone: ordererParsedNumber,
            },
            receiverPhone: {
                phone: receiverPhone,
                parsedPhone: receiverParsedNumber,
            },
            email: customerEmail
        };

        return customerData;
    }

    handleAdyenPayment(args) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(GCSAdyenPayDialogComponent, {
                direction: this.appService.direction,
                maxWidth: '90vw',
                data: {
                    args,
                    isProduction: /-(dmo|bta|prd)/.test(environment.env)
                },
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                autoFocus: false
            });
            dialogRef.afterClosed()
            .subscribe(result => {
                const mode = get(result, 'mode');
                switch (mode) {
                    case "cancel":
                    case "success":
                    case "error":
                    default:
                        console.debug('=== handleAdyenPayment | Payment result ===', mode);
                }
            });
        });
    }

    setToDefaultLang() {
        this.translationService.setToDefaultLang();
    }

    hasLanguageChanged() {
        return this.translationService.hasLanguageChanged();
    }

    changeUsedLanguage(locale?: string, firstLoad?: boolean) {
        if (locale) {
            if (firstLoad) {
                this.translationService.setToggledLang('giftCard');
            }
            this.translationService.changeUsedLanguage(locale);
        } else {
            this.translationService.setToggledLang('giftCard');
        }
    }

}
