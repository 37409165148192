<div *ngIf="domain?.signInBackgroundType == 'video' && !dialogMode" class="video_container">
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" preload="auto">
        <source [src]="getMediaSource(false)" type="video/mp4">
    </video>
    <div class="overlay"></div>
</div>

<!-- In case signInImageBackground is true, we must have a proper image in background-image path -->
<div
    *ngIf="domain?.signInBackgroundType == 'image' && !dialogMode"
    class="signin_image_container"
    [style.background-image]="getMediaSource(true) | safeStyle"
></div>

<div [@fadeOut]="fadeOut" [class.dark-theme]="!dialogMode" [class.app-sign-in]="!dialogMode">
    <mat-tab-group class="wiz-tabs full-height hide-headers" [class.not-dialog]="!dialogMode" [(selectedIndex)]="step" animationDuration="50ms">
        <!-- WELCOME -->
        <mat-tab label="Step 0">
            <div class="d-flex flex-column h-100">
                <div class="flex-grow-1 text-center logo-back" [style.background-image]="appService.images.tabit_logo_light | safeStyle"></div>
                <div class="p-4">
                    <div *ngIf="touchIDUsernameExists" class="mb-4">
                        <button *ngIf="!authenticating" class="login-home-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button" (click)="_authenticateByTouchID()">
                            {{'auth_touch' | translate}}
                        </button>
                        <button *ngIf="authenticating" class="login-home-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                            <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                        </button>
                    </div>
                    <div class="login-home-btn-container">
                        <button class="login-home-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button" [disabled]="authenticating" (click)="_setStep(1); appService.closeAllToasts()">
                            {{'sign_in' | translate}}
                        </button>
                    </div>
                    <!--<div>
                        <button class="login-home-btn btn btn-lg btn-trans btn-block" type="button" [disabled]="authenticating" (click)="_setStep(4)">
                            {{'sign_up' | translate}}
                        </button>
                    </div>-->
                </div>
            </div>
        </mat-tab>
        <!-- SIGN IN BY PHONE -->
        <mat-tab label="Step 1">
            <app-sign-in-by-phone-form
                [class.dark-theme]="!dialogMode"
                [disabled]="authenticating"
                [formModel]="formModel"
                [dialogMode]="dialogMode"
                (onSubmit)="_onSignInByPhoneSubmit($event)"
                (onResetPassword)="_onResetPassword($event)"
                (onSignup)="_setStep(4)"
                (onBack)="appService.goBack()"
                (onOpenLink)="openLink($event)"
                [local]="local"
            ></app-sign-in-by-phone-form>
        </mat-tab>
        <!-- VERIFY CODE -->
        <mat-tab label="Step 2">
            <app-sign-in-by-phone-verify-code-form
                [class.dark-theme]="!dialogMode"
                [thisStepCurrentlyVisible]="step == 2 ? true : false"
                [disabled]="authenticating || resending"
                [formModel]="formModel"
                [phone]="customer.phone"
                [dialogMode]="dialogMode"
                (onResend)="_onSignInByPhoneVerifyResend()"
                (onSubmit)="_onSignInByPhoneVerifyCodeSubmit($event)"
                (onBack)="_setStep(1)"
                (onOpenLink)="openLink($event)"
            ></app-sign-in-by-phone-verify-code-form>
        </mat-tab>
        <!-- SIGN IN BY EMAIL -->
        <mat-tab label="Step 3">
            <app-sign-in-by-email-form
                [class.dark-theme]="!dialogMode"
                [disabled]="authenticating"
                [formModel]="formModel"
                [dialogMode]="dialogMode"
                (onSubmit)="_onSignInByEmailSubmit($event)"
                (onResetPassword)="_onResetPassword($event)"
                (onSignup)="_onSignUp()"
                (onOpenLink)="openLink($event)"
            ></app-sign-in-by-email-form>
        </mat-tab>
        <!-- SIGN UP -->
        <mat-tab label="Step 4">
            <app-sign-up-form
                [class.dark-theme]="!dialogMode"
                [disabled]="authenticating"
                [dialogMode]="dialogMode"
                (onSubmit)="_onSignUpSubmit($event)"
                (onBack)="_setStep(1)"
                (onOpenLink)="openLink($event)"
            ></app-sign-up-form>
        </mat-tab>
        <!-- UPDATE CUSTOMER DETAILS -->
        <mat-tab label="Step 5">
            <app-update-form
                [class.dark-theme]="!dialogMode"
                [disabled]="authenticating"
                [formModel]="formModel"
                [dialogMode]="dialogMode"
                (onSubmit)="_onUpdateSubmit($event)"
                (onBack)="_setStep(1)"
                (onOpenLink)="openLink($event)"
            ></app-update-form>
        </mat-tab>
    </mat-tab-group>
</div>






