import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'splash-screen-dialog',
    templateUrl: './splash-screen-dialog.component.html',
    styleUrls: ['./splash-screen-dialog.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon]
})
export class SplashScreenDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<SplashScreenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        setTimeout(() => this.close(), 10 * 1000);
    }

    close() {
        this.dialogRef.close();
    }

}
