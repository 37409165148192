<div mat-dialog-title class="title-address">{{ (data.houseRequired ? 'please_type_delivery_address_short' : 'please_type_address_short') | translate }}</div>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{ (data.houseRequired ? 'please_type_delivery_address_long' : 'please_type_address_long') | translate }}</mat-label>
        <input matInput type="text" (input)="onFreeAddressTextChange($event)" />
        <mat-spinner [diameter]="20" strokeWidth="2" *ngIf="searchingAddress" class="waiting-animated" matSuffix color="accent"></mat-spinner>
        <mat-hint *ngIf="searchedAddressOnce && !googleAddressPredictions.length">{{ 'address_not_found' | translate }}</mat-hint>
    </mat-form-field>
    <mat-list class="suggested-addresses" *ngIf="googleAddressPredictions.length">
        <mat-radio-group name="picked-address" [(ngModel)]="googleAddressPickedIndex" labelPosition="after">
            <mat-list-item
                class="suggested-address"
                *ngFor="let address of googleAddressPredictions; let i = index"
                (click)="googleAddressPickedIndex = i; chooseAddress(address)"
                (keydown.enter)="googleAddressPickedIndex = i; chooseAddress(address)"
                (keydown.space)="googleAddressPickedIndex = i; chooseAddress(address)"
            >
                <mat-icon matListIcon><mat-radio-button [value]="i" labelPosition="after"></mat-radio-button></mat-icon>
                <div matLine>{{ address.formatted_address }}</div>
            </mat-list-item>
        </mat-radio-group>
    </mat-list>
</div>

<div mat-dialog-actions>
    <button
        type="button"
        mat-stroked-button
        color="accent"
        [mat-dialog-close]="null"
        class="rounded"
    >
        {{ 'Cancel' | translate}}
    </button>

    <button
        type="button"
        mat-flat-button
        color="primary"
        [disabled]="!googleAddressPicked"
        [mat-dialog-close]="googleAddressPicked"
        class="rounded"
    >
        <mat-spinner *ngIf="googleAddressPickedIndex !== undefined && !googleAddressPicked" diameter="20" strokeWidth="2" class="light-grey"></mat-spinner>
        <span *ngIf="googleAddressPickedIndex === undefined || googleAddressPicked">{{ 'OK' | translate}}</span>
    </button>
</div>
