<!-- MENU: SIMULATION for DESIGN PURPOSES ONLY -->
<!--
<div
    *ngIf="site._id === '57b010e4cb82f61e009fe53a'"
    class="link-row no-border"
    (click)="dialogsService.toggleActionFrame('link', null, null, null, 'https://www.revivaandcelia.co.il/ramat-hasharon/noon/')"
>
    <mat-icon class="link-icon">vertical_align_bottom</mat-icon>
    <h4>תפריט המסעדה</h4>
</div>
-->

<!-- ADDRESS & NAVIGATE: -->
<div
    role="link"
    class="link-row"
    [class.no-border]="site._id !== '57b010e4cb82f61e009fe53a'"
    (click)="dialogsService.toggleActionFrame('navigate', site)"
>
<mat-icon class="link-icon" svgIcon="navigate" aria-disabled="true" aria-label="place"></mat-icon>
    <h4 class="_address" role="link" accessible [attr.aria-label]="('site-links.address_screen_reader' | translate) + site.address + ('site-links.to_navigate_screen_reader' | translate)" >
        {{site.address}}
    </h4>
    <div class="_info navigate" *ngIf="showDistance && site.distance && site.formattedDistance === 'far_away'">{{site.formattedDistance | translate}}</div>
    <div class="_info navigate" *ngIf="showDistance && site.distance && site.formattedDistance !== 'far_away'">{{site.formattedDistance | translate}} {{unit | translate}}</div>
</div>

<!-- PHONE: -->
<div
    role="link"
    class="link-row"
    (click)="callPhone()"
    *ngIf="site.phone || (site.reservation && site.reservation.method === 'phone' && site.reservation.methodValue)"
>
<mat-icon class="link-icon" svgIcon="call" aria-label="Phone"></mat-icon>
    <h4 accessible [attr.aria-label]="('site-links.phone_number_screen_reader' | translate) + ' ' + (site.phone || (site.reservation.method === 'phone' && site.reservation.methodValue)) + ' ' + ('site-links.to_call_screen_reader' | translate)">
        {{ site.phone || (site.reservation.method === 'phone' && site.reservation.methodValue) }}
    </h4>
</div>

<!-- OPENED NOW: -->
<a
    accessible
    class="link-row expanded"
    *ngIf="site.activeSlot && site.activeSlot.slotFound"
    (click)="showTimeslots = !showTimeslots"
    [attr.aria-expanded]="showTimeslots"
>
    <h4 class="d-flex align-content-center align-items-center" [attr.aria-label]="('Open' | translate) +  ('site-links.show_work_hours_screen_reader' | translate)">
        <mat-icon class="link-icon" svgIcon="open" aria-label="Time"></mat-icon>
        <span>{{'Open' | translate}}</span>
        <mat-icon class="md clickable">{{showTimeslots ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </h4>
    <div class="_info">{{ activeSlotsTextParse(site.activeSlot.slotFound.text) }}</div>
</a>

<!-- CLOSED NOW: -->
<div
    accessible
    class="link-row"
    *ngIf="site.activeSlot && !site.activeSlot.slotFound"
    (click)="showTimeslots = !showTimeslots"
    [attr.aria-expanded]="showTimeslots"
>
    <h4 class="d-flex align-content-center align-items-center">
        <mat-icon class="link-icon" svgIcon="open" aria-label="Time"></mat-icon>
        <span>
            {{'Closed' | translate}}
        </span>
        <mat-icon class="md clickable">{{showTimeslots ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </h4>
    <div class="_info" *ngIf="site.activeSlot.slotNext">{{ activeSlotsTextParse(site.activeSlot.slotNext.text) }}</div>
</div>

<!-- OPENING HOURS: -->
<div *ngIf="site.workHours && site.workHours.length" [attr.aria-expanded]="showTimeslots" [ngxCollapse]="!showTimeslots">
    <table class="working-time-slots table table-sm table-striped table-borderless" style="font-size:12px;">
        <tbody>
            <tr *ngFor="let day of site.workHours; let $index = index" accessible [ngClass]="{'font-weight-bold': $index == currentDay}">
                <td>{{'DAYS.' + day.day | translate}}</td>
                <td *ngIf="day.active" class="bullet-separated-list">
                    <span *ngFor="let slot of day.timeSlots" [attr.aria-label]="('site-links.from_screen_reader' | translate) + (slot.from | AMPMConverter) + ('site-links.up_until_screen_reader' | translate) + (slot.to | AMPMConverter)">
                        {{formatHoursRangeByLocale(slot.from, slot.to)}}
                    </span>
                </td>
                <td *ngIf="!day.active">
                    {{'closed_for_day' | translate}}
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- ACCESSIBILITY: -->
<div *ngIf="accessibilityTags?.length > 0" class="link-row" role="region" aria-labelledby="accessibility-options-title" tabindex="0">
    <mat-icon class="link-icon" svgIcon="accessibility-new" aria-label="Accessibility options"></mat-icon>

    <h4 id="accessibility-options-title" aria-label="Accessibility Options">
        {{ 'accessibility' | translate }}
    </h4>
</div>

<div *ngIf="accessibilityTags?.length > 0" class="accessibility-options" role="region" tabindex="-1">
    <ng-container>
        <div class="accessibility-item-container">
            <span *ngFor="let tag of accessibilityTags; let i = index" class="accessibility-item" [attr.aria-label]="tag?.lang[appService.localeId.toLocaleLowerCase()]?.label" tabindex="0">
                {{ tag?.lang[appService.localeId.toLocaleLowerCase()]?.label }}
                <span *ngIf="i < accessibilityTags.length - 1" class="separator" aria-hidden="true">|</span>
            </span>
        </div>
    </ng-container>
</div>

<!-- WEBSITE: -->
<div class="link-row">
    <mat-icon class="link-icon" svgIcon="bill" aria-disabled="true" aria-label="place" *ngIf="site.website"></mat-icon>
    <h4 class="home-link clickable" role="link" accessible [attr.aria-label]="('site-links.to_web_site_screen_reader' | translate)" *ngIf="site.website" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.website)" >
        {{ 'website' | translate }}
    </h4>
    <mat-icon class="link-icon social-icon-link clickable" svgIcon="instagram_outline_dark_1" aria-label="link" *ngIf="site?.socialNetworkLinks?.instagramURL" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.socialNetworkLinks.instagramURL)"></mat-icon>
    <mat-icon class="link-icon social-icon-link clickable" svgIcon="facebook_outline_dark_1" aria-label="link" *ngIf="site?.socialNetworkLinks?.facebookURL" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.socialNetworkLinks.facebookURL)"></mat-icon>
</div>