import { Component, OnInit, Input } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { AppService } from '../../../app.service';
import { WebContainerService } from '../../web-container.service';
import { LocationLabeled, LocationService } from '../../../_core/location.service';
import { ConfigurationsService } from '../../../_core/configurations.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookService } from '../../../_core/book.service';
import { TranslateModule } from '@ngx-translate/core';
import { WebSelectionBarComponent } from '../../web-selection-bar/web-selection-bar.component';
import { AccessibleDirective } from '../../../accessability/accessible/accessible.directive';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'web-book-header',
    templateUrl: './web-book-header.component.html',
    styleUrls: ['./web-book-header.component.scss'],
    standalone: true,
    imports: [NgIf, AccessibleDirective, WebSelectionBarComponent, TranslateModule]
})
export class WebBookHeaderComponent implements OnInit {

    @Input() webSearchType: string = ''; // booking / order / search

    public locationLabeled: LocationLabeled;

    private locationSubscription: Subscription;
    private domainSubscription: Subscription;

    public selectedLocationType: string = 'actual';

    public domain: any;
    public webHomeServicesDomainConfig: any;

    public $storage: any;

    constructor(
        public appService: AppService,
        public webContainerService: WebContainerService,
        private locationService: LocationService,
        public configurationsService: ConfigurationsService,
        public bookService: BookService,
    ) {
        this.$storage = this.bookService.$storage;
    }

    ngOnInit() {
        // Location Subscription for the location-widget
        this.locationSubscription = combineLatest([
            this.locationService.location,
            this.locationService.actualLocationAvailable
        ]).subscribe(([ locationLabeled, available ] : [ LocationLabeled, boolean ]) => {
            this.locationLabeled = locationLabeled;
            if (locationLabeled.actual) this.selectedLocationType = 'actual';
            else if (locationLabeled.area) this.selectedLocationType = locationLabeled.area;
            else this.selectedLocationType = 'manual'; // It's different from the option value, by design!
        });

        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            this.domain = domain;
            this.webHomeServicesDomainConfig = this.appService.getServicesConfig(domain);
        });
    }

    ngOnDestory() {
        this.locationSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
    }
}
