import { MatFormFieldControl } from '@angular/material/form-field';
import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';

import { FormGroupDirective, NG_VALIDATORS, NgControl, NgForm } from '@angular/forms';
import { CountryCode, Examples } from './data/country-code';
import { phoneNumberValidator } from './intl-tel-input.validator';
import { Country } from './model/country.model';
import { PhoneNumberFormat } from './model/phone-number-format.model';
import { AsYouType, CountryCode as CC, getExampleNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { IntlTelCountryDialogComponent } from './intl-tel-country-dialog/intl-tel-country-dialog.component';
import { AppService } from '../app.service';

class NgxMatIntlTelInputBase {
    stateChanges = new Subject<void>();

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl
    ) {}
}

const _NgxMatIntlTelInputMixinBase = mixinErrorState(NgxMatIntlTelInputBase);

@Component({
    selector: 'app-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.scss'],
    providers: [
        CountryCode,
        { provide: MatFormFieldControl, useExisting: IntlTelInputComponent },
        {
            provide: NG_VALIDATORS,
            useValue: phoneNumberValidator,
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntlTelInputComponent extends _NgxMatIntlTelInputMixinBase
    implements OnInit, OnDestroy, DoCheck, CanUpdateErrorState, MatFormFieldControl<any> {
    static nextId = 0;

    @Input() preferredCountries: Array<string> = [];
    @Input() enablePlaceholder = true;
    @Input() inputPlaceholder: string;
    @Input() forceChangeEventOnFirstLoad: boolean = false;
    @Input() formClass: any;
    @Input() name: string;
    @Input() formPhone: any;
    @Input() onlyCountries: Array<string> = [];
    @Input() errorStateMatcher: ErrorStateMatcher;
    @Input() enableSearch = false;
    @Input() searchPlaceholder: string;
    @Input() direction: string;

    @Input()
    get format(): PhoneNumberFormat {
        return this._format;
    }

    set format(value: PhoneNumberFormat) {
        this._format = value;
        this.phoneNumber = this.formattedPhoneNumber;
        this.stateChanges.next();
    }

    @ViewChild('focusable') focusableInput: ElementRef;
    @ViewChild(MatMenu) matMenu: MatMenu;

    private _placeholder: string;
    private _required = false;
    private _disabled = false;
    stateChanges = new Subject<void>();
    focused = false;
    @HostBinding() id = `oao-mat-intl-tel-input-${IntlTelInputComponent.nextId++}`;
    describedBy = '';
    phoneNumber: any = '';
    allCountries: Array<Country> = [];
    preferredCountriesInDropDown: Array<Country> = [];
    selectedCountry: Country;
    numberInstance: PhoneNumber;
    value;
    searchCriteria: string;
    isSingleCountry: boolean = true;
    @Output()
    countryChanged: EventEmitter<Country> = new EventEmitter<Country>();

    private previousFormattedNumber: string;
    private _format: PhoneNumberFormat = 'default';

    static getPhoneNumberPlaceHolder(countryISOCode: any): string {
        try {
            return getExampleNumber(countryISOCode, Examples).number.toString();
        } catch (e) {
            return e;
        }
    }

    onTouched = () => {
    };

    propagateChange = (_: any) => {
    };

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private countryCodeData: CountryCode,
        private fm: FocusMonitor,
        public dialog: MatDialog,
        private elRef: ElementRef<HTMLElement>,
        private appService: AppService,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        fm.monitor(elRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this.fetchCountryData();
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        if (!this.searchPlaceholder) {
            this.searchPlaceholder = 'Search ...';
        }
        if (this.preferredCountries.length) {
            this.preferredCountries.forEach(iso2 => {
                const preferredCountry = this.allCountries.filter((c) => {
                    return c.iso2 === iso2;
                }).shift();
                this.preferredCountriesInDropDown.push(preferredCountry);
            });
        }

        if (this.onlyCountries.length) {
            this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
        }
        if (this.numberInstance && this.numberInstance.country) {
            this.selectedCountry = this.getCountry(this.numberInstance.country);
        } else {
            if (this.preferredCountriesInDropDown.length) {
                this.selectedCountry = this.preferredCountriesInDropDown[0];
            } else {
                this.selectedCountry = this.allCountries[0];
            }
        }
        if (this.allCountries.length > 1) this.isSingleCountry = false;
        this.countryChanged.emit(this.selectedCountry);
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
        if (this.forceChangeEventOnFirstLoad) {
            setTimeout(() => {
                if (this.phoneNumber) this.onPhoneNumberChange();
            }, 50);
        }
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
            this.checkUserConnection();
        }
    }

    public onPhoneNumberChange(): void {
        const that = this;
        try {
            const selectedCountryIso = this.selectedCountry.iso2.toUpperCase();
            this.numberInstance = parsePhoneNumberFromString(this.phoneNumber.toString(), selectedCountryIso as CC);
            this.formatAsYouTypeIfEnabled();
            this.value = this.numberInstance.number;
            if (this.numberInstance && this.numberInstance.isValid()) {
                if (this.phoneNumber !== this.formattedPhoneNumber) {
                    this.phoneNumber = this.formattedPhoneNumber;
                }
                if (hasError(selectedCountryIso)) {
                    throw ({});
                }
            }
        } catch (e) {
            this.value = this.phoneNumber.toString();
        }
        this.propagateChange(this.value);
        this._changeDetectorRef.markForCheck();

        function hasError(country) {
            const hasError = !isValidPhoneNumber(that.phoneNumber, country as CC) || (country !== that.numberInstance.country);
            return hasError;
        }
    }

    public onCountrySelect(country: Country, el): void {
        if (this.phoneNumber) {
            this.phoneNumber = this.numberInstance.nationalNumber;
        }
        this.selectedCountry = country;
        this.countryChanged.emit(this.selectedCountry);
        this.onPhoneNumberChange();
        el.focus();
    }

    public getCountry(code) {
        return this.allCountries.find(c => c.iso2 === code.toLowerCase()) || {
            name: 'UN',
            iso2: 'UN',
            dialCode: undefined,
            priority: 0,
            areaCodes: undefined,
            flagClass: 'UN',
            placeHolder: ''
        };
    }

    public onInputKeyPress(event): void {
        const pattern = /[0-9+\- ]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    protected fetchCountryData(): void {
        this.countryCodeData.allCountries.forEach(c => {
            const country: Country = {
                name: c[0].toString(),
                iso2: c[1].toString(),
                dialCode: c[2].toString(),
                priority: +c[3] || 0,
                areaCodes: c[4] as string[] || undefined,
                flagClass: c[1].toString().toUpperCase(),
                placeHolder: ''
            };

            if (this.enablePlaceholder) {
                country.placeHolder = IntlTelInputComponent.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
            }

            this.allCountries.push(country);
        });
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    writeValue(value: any): void {
        if (value) {
            this.numberInstance = parsePhoneNumberFromString(value);
            if (this.numberInstance) {
                const countryCode = this.numberInstance.country;
                this.phoneNumber = this.formattedPhoneNumber;
                if (!countryCode) {
                    return;
                }
                setTimeout(() => {
                    this.selectedCountry = this.getCountry(countryCode);
                    if (this.selectedCountry.dialCode && !this.preferredCountries.includes(this.selectedCountry.iso2)) {
                        this.preferredCountriesInDropDown.push(this.selectedCountry);
                    }
                    this.countryChanged.emit(this.selectedCountry);

                    this._changeDetectorRef.markForCheck();
                    this.stateChanges.next();
                }, 1);
            } else {
                this.phoneNumber = value;
            }
        }

        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    get empty() {
        return !this.phoneNumber;
    }

    @HostBinding('class.ngx-floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elRef.nativeElement.querySelector('input')!.focus();
        }
    }

    reset() {
        this.phoneNumber = '';
        this.propagateChange(null);

        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef);
    }

    private get formattedPhoneNumber(): string {
        if (!this.numberInstance) {
            return this.phoneNumber.toString();
        }
        switch (this.format) {
            case 'national':
                return this.numberInstance.formatNational();
            case 'international':
                return this.numberInstance.formatInternational();
            default:
                return this.numberInstance.nationalNumber.toString();
        }
    }

    private formatAsYouTypeIfEnabled(): void {
        if (this.format === 'default') {
            return;
        }
        const asYouType: AsYouType = new AsYouType(this.selectedCountry.iso2.toUpperCase() as CC);
        if (this.phoneNumber.toString().startsWith(this.previousFormattedNumber || '')) {
            this.phoneNumber = asYouType.input(this.phoneNumber.toString());
        }
        this.previousFormattedNumber = this.phoneNumber.toString();
    }

    private checkUserConnection() {
        if (this.formPhone === '') {
            this.phoneNumber = '';
        }

        // signed in
        if (this.appService.user?.phone === this.phoneNumber) {
            this.selectedCountry = this.isSingleCountry ? this.allCountries[0] : this.preferredCountriesInDropDown[0];
            this.onPhoneNumberChange();
        }
    }

    showCountryDialog(el) {
        if (!this.focusableInput.nativeElement.matches(':focus')) {
            return this.focusableInput.nativeElement.focus();
        }

        this.disabled = false;
        let dialogRef = this.dialog.open(IntlTelCountryDialogComponent, {
            width: '100vw',
            maxWidth: '530px',
            height: '100vh',
            direction: this.direction == 'rtl' ? 'rtl' : 'ltr',
            autoFocus: false,
            data: {
                preferredCountriesInDropDown: this.preferredCountriesInDropDown,
                allCountries: this.allCountries,
                searchPlaceholder: this.searchPlaceholder,
                enableSearch: this.enableSearch
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) this.onCountrySelect(result, el);
        });
    }
}
