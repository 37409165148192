import { Component, Inject, OnInit,  ElementRef, Renderer2 } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { OrganizationsService } from '../../_core/organizations.service';
import { TagsService } from '../../_core/tags.service';
import { MarketplaceService } from '../../_core/marketplace.service';
import { AppService } from '../../app.service';

import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { filter, forEach, concat } from 'lodash-es';
import { DialogsService } from '../../_core/dialogs.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { MarketplaceImageComponent } from '../../components/marketplace-card/marketplace-image/marketplace-image.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor, SlicePipe } from '@angular/common';

@Component({
    selector: 'site-more-info-dialog',
    templateUrl: './site-more-info-dialog.component.html',
    styleUrls: ['./site-more-info-dialog.component.scss'],
    animations: [
        trigger('sitesAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1 })),
            ]),
        ]),
        trigger('spinnerAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinner,
        MatButton,
        MatDialogClose,
        MatIcon,
        NgFor,
        MarketplaceImageComponent,
        SlicePipe,
        OrderModule,
        TranslateModule,
    ],
})
export class SiteMoreInfoDialogComponent implements OnInit {
    stylesAndKosherTags: any[] = [];
    accessTags: any[] = [];
    siteImages: any[] = [];
    siteFromBridge: any;

    moreInfoContainerElementHeight: number = 0;
    messageElementHeight: number = 0;
    calculateMessageHeight: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<SiteMoreInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public tagsService: TagsService,
        private organizationsService: OrganizationsService,
        public marketplaceService: MarketplaceService,
        public appService: AppService,
        public coreDialogsService: DialogsService,
        private elementRef: ElementRef
    ) { }

    async ngOnInit() {
        await this.loadSiteData(this.dialogData?.giftCardSite?.siteId);
    }

    private async loadSiteData(siteId) {
        try {
            const [siteFromBridge, tags] = await forkJoin([
                this.organizationsService.getFullOrganizationById(siteId).pipe(take(1)),
                this.tagsService.getTags().pipe(take(1)),
            ]).toPromise();

            this.siteFromBridge = siteFromBridge;
            const kosherTags = this.filterTagsByType(tags, 'kosher', this.siteFromBridge.tags);
            const stylesTags = this.filterTagsByType(tags, 'style', this.siteFromBridge.tags);
            this.stylesAndKosherTags = concat(stylesTags, kosherTags);

            this.accessTags = this.filterTagsByType(tags, 'accessibility', this.siteFromBridge.tags);
            this.getVisibleImages();
            this.setElementsHeight();
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
            this.dialogRef.close();
            this.marketplaceService.redirectToGiftCard(this.dialogData?.giftCardSite?.AccountGuid);
        }
    }

    public getVisibleImages() {
        let photos = [];
        // Images from sitePageDetails
        const siteDetailsPage = this.siteFromBridge?.siteDetailsPage;
        if (siteDetailsPage?.images?.length) {
            siteDetailsPage?.images.forEach((image, index) => {
                // Only if there is an actual URL, and not ''
                if (image.url) {
                    const tempImage = {
                        photo_url: image.url,
                        alt: image?.alt,
                        index,
                    }
                    photos.push(tempImage);
                }
            });
        }
        this.siteImages = photos.slice(0, 6);
    }

    private filterTagsByType(tags: any[], type: string, siteTags: any) {
        let tagsLabels = [];
        const filteredTags = filter(tags, { type });
        const filteredSiteTags = filter(filteredTags, (tag) => siteTags.includes(tag._id));
        forEach(filteredSiteTags, (tag) => tagsLabels.push(this.marketplaceService.getTagLabel(tag)));

        return tagsLabels;
    }

    redirectToGiftCard() {
        this.marketplaceService.redirectToGiftCard(this.dialogData?.giftCardSite?.AccountGuid);
    }

    phoneClick(phone) {
        if (!phone) return;

        if (window['cordova']) {
            window.open('tel:' + phone, '_system');
        } else {
            window.location.href = 'tel:' + phone;
        }
    }

    get imagesSliceNumber() {
        return this.appService.isDesktop() ? 4 : 3;
    }

    setElementsHeight() {
        // Get the body height
        const bodyHeight = document.body.offsetHeight;

        // Check if bodyHeight is a valid value
        if (!bodyHeight) {
            return;
        }

        // Calculate the height for moreInfoContainerElement
        this.moreInfoContainerElementHeight = (bodyHeight / 100) * 80 + 20;

        // Use setTimeout for non-blocking execution
        setTimeout(() => {
            const element = this.elementRef.nativeElement;

            // Check if element is available
            if (!element) {
                return;
            }

            // Get the height of the current element
            const height = element.offsetHeight;

            // Find moreInfoContainer element
            const moreInfoContainer = element.querySelector('.more-info-container');

            // Check if the necessary elements are available
            if (this.siteFromBridge?.siteDetailsPage?.about && height && moreInfoContainer) {
                // Calculate the messageElementHeight
                this.messageElementHeight = height - (this.appService.isDesktop() ? 150 : 120) - moreInfoContainer.offsetHeight;
            }
        }, 100);
    }

}