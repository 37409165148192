<div
    class="sign-in-container"
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    tabindex="-1"
>
    <div class="close-button">
        <button aria-label="close" class="" mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!-- <app-sign-in [dialogMode]="true" [joinChannelOrigin]="data?.joinChannelOrigin" (signInCompleted)="closeDialog()"></app-sign-in> -->
    <app-sign-in [dialogMode]="true" (signInCompleted)="closeDialog()" [mixpanelTrackingData]="mixpanelTrackingData"></app-sign-in>
</div>
