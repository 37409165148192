import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, merge, Observable, combineLatest } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { trigger, state, stagger, style, animate, transition, query, animateChild } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService, Domain } from '../app.service';
import { EntityService } from '../_core/entity.service';
import { EntityUtilsService } from '../_core/entity.utils.service';
import { DialogsService } from '../_core/dialogs.service';
import { Address, AddressesService } from '../_core/addresses.service';
import { LocationLabeled, LocationService } from '../_core/location.service';
import { OrganizationsService } from '../_core/organizations.service';
import { ORGANIZATION_BUCKET_TYPES } from '../_core/OrganizationBucket';
import { BookService } from '../_core/book.service';
import { TagsService, Tag } from '../_core/tags.service';
import { UserReviewsService } from '../_core/user.reviews.service';
import { OccasionsService } from '../_core/occasions.service';
import { OrderTrackerService } from '../_core/order-tracker.service';
import { LoyaltyService } from '../_core/loyalty.service';
import { StorageService } from '../_core/storage.service';
import { MarketplaceService } from '../_core/marketplace.service';

import moment from 'moment';
import { get, orderBy, isEmpty } from 'lodash-es';

declare const $: any;

@Component({
    selector: 'app-dashboard',
    changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('userPointsAnimation', [
            state('*', style({ opacity: 0 })),
			state('show', style({ opacity: 1 })),
			transition('* => show', [
				animate('0.4s 0s ease-in')
            ])
		]),
		trigger('reservationSectionAnimation', [
            state('*', style({ height: 0 })),
			state('show', style({ height: '9.5rem' })),
			state('visible', style({ height: '9.5rem' })),
			transition('* => show', [
				animate('0.3s 900ms ease-in'), // 0.9s delay = 100ms x 3 cards + 0.5s for the last card to finish animation + 100ms spare
				//query('@reservationCardsAnimation', [
					//animateChild()
				//])
            ])
        ]),
        trigger('recentOrdersSectionAnimation', [
            state('*', style({ height: 0 })),
			state('show', style({ height: '9.5rem' })),
			state('visible', style({ height: '9.5rem' })),
			transition('* => show', [
				animate('0.3s 900ms ease-in'), // 0.9s delay = 100ms x 3 cards + 0.5s for the last card to finish animation + 100ms spare
				//query('@recentOrdersCardsAnimation', [
					//animateChild()
				//])
            ])
        ]),
        trigger('trackedOrderAnimation', [
            state('*', style({ height: 0 })),
			state('show', style({ height: '9.5rem' })),
			state('visible', style({ height: '9.5rem' })),
			transition('* => show', [
				animate('0.3s 900ms ease-in'),
            ])
		]),
		/*
		trigger('reservationCardsAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.4s 0s ease-in', style({ opacity: 1 }))
			]),
		]),
        */
        trigger('clubCardsAnimation', [
            state('*', style({ opacity: 0 })),
			state('show', style({ opacity: 1 })),
			transition('* => show', [
				animate('0.4s 0s ease-in')
            ])
		]),
        trigger('eventCardsAnimation', [
            transition('* => *', [
                query('event-card', [
                    stagger(100, [
                        query('@eventCardAnimation', [
                            animateChild()
                        ], { optional: true })
                    ])
                ], { optional: true}),
            ])
        ]),
		trigger('siteCardsAnimation', [
            /*transition('* => *', [
				query('site-card', [
					stagger(100, [
						query('@siteCardAnimation.animate', [
							animateChild()
						], { optional: true })
					])
				], { optional: true}),
            ])*/
		]),
		trigger('categoriesCardsAnimation', [
            /*transition('* => *', [
				query('food-category-item', [
					stagger(100, [
						query('@categoryCardAnimation.animate', [
							animateChild()
						], { optional: true })
					])
				], { optional: true })
            ])*/
		]),
		trigger('animateList', [
            // 2020-02-15: Decided to remove for performance reasons
            /*
            state('hide', style({opacity:0})),
            state('show', style({opacity:1})),
            transition('hide => show', [
                animate('0.2s 0s ease-out')
            ])
            */
        ])
    ]
})

@UntilDestroy()
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    private orgsSubscription: Subscription = null;
    private tagsSubscription: Subscription;
    private locationSubscription: Subscription;
    private eventsSubscription: Subscription;
    private loadingMetaDataSubscription: Subscription;
    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;
    private coreSubscription: Subscription;
    private marketplaceSubscription: Subscription;

    public locationLabeled: LocationLabeled;

    public loadingMetaData: boolean = true;
    public showEvents: boolean = false;
    public hasBenefits: boolean = false;

    public homeOrderOrgs: any[] = [];
    public nearbyAllOrgs: any[] = [];
    public nearbyTabitOrgs: any[] = [];
    public nearbySubGroupOrgs: any[] = [];
    public newAtTabitOrgs: any[] = [];
    public favoriteOrgs: any[] = [];
    public futureReservations: any[] = [];
    public clubIds: any[] = [];
    public marketplaceOrgs: any[] = [];

    public recentOrders: any  = [];
    public trackedOrders: any = [];
    public ordersFromLocalStorage: any = [];
    public sitesEvents: any = [];

    public homeOrderOrgsLength: number;
    public nearbyAllOrgsLength: number;
    public nearbyTabitOrgsLength: number;
    public nearbySubGroupOrgsLength: number;
    public newAtTabitOrgsLength: number;
    public favoriteOrgsLength: number;
    public sitesEventsLength: number;
    public clubsDataLength: number;

    public nearbySubGroupOrgsMap: any;

    public animateList: String = 'hide';
    public userPointsAnimationTrigger: string = 'hide';
    private selectedLocationType: string = 'actual';
    public bookSelectionBarImageBackground: string;
    public userPointsTitle: string;
    public userPointsSubtitle: string;

    public reservationSectionAnimationTrigger: string = this.appService.dashboardInitialLoad ? '' : 'visible';
    public recentOrdersSectionAnimationTrigger: string = this.appService.dashboardInitialLoad ? '' : 'visible';
    public trackedOrderSectionAnimationTrigger: string = this.appService.dashboardInitialLoad ? '' : 'visible';

    public foodTags: Tag[];
    public occasionTags: Tag[];

    public sitePrefs: any;
    public $storage: any;
    public clubsData: any;
    public country: any = { country: this.appService.appConfig.locale == 'he-IL' ? 'Israel' : 'US' };

    public domain: Domain;
    // indicating the the domain api call is on-progress
    public domainDataLoaded: boolean = false;
    public giftCardsAccountGuid: string;
    public giftCardDomainConfig: any;
    public dashboardSectionsDomainConfig: any;

    // private timeFrom = moment().startOf('day');
    // private timeTo = moment(this.timeFrom).add(30, 'days');

	constructor(
		public appService: AppService,
		public entityService: EntityService,
		public utilsService: EntityUtilsService,
		public dialogsService: DialogsService,
        public locationService: LocationService,
        public addressesService: AddressesService,
		public organizationsService: OrganizationsService,
        public loyaltyService: LoyaltyService,
        public bookService: BookService,
        public occasionsService: OccasionsService,
        public marketplaceService: MarketplaceService,
		private router: Router,
        private tagsService: TagsService,
        private userReviewsService: UserReviewsService,
        private orderTrackerService: OrderTrackerService,
        private changeDetectorRef: ChangeDetectorRef,
        private storageService: StorageService,
	) {
        this.$storage = this.bookService.$storage;
    }

    ngOnInit() {
        this.initDashboard();
        
        // When the phone resumes, we want to reload the data
        this.appService.isPhoneResumed
        .pipe(untilDestroyed(this))
        .subscribe(isResumed => {
            if (isResumed) {
                console.debug('=== DASHBOARD === Init occurred after resume')
                this.initDashboard();
                this.appService.isPhoneResumed.next(false);
            }
        });
    }

    private takeDataFromLocalStorage() {

        // console.log('=== DASHBOARD === taking orgs from local storage (if any)');

        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.new);
        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.nearbyTabit);
        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.favorites);
        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.homeOrder);
        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.extra);
        this.organizationsService.initBucketOrgsFromLocalStorage(ORGANIZATION_BUCKET_TYPES.nearbySubGroup);

        try {
            this.clubsData = JSON.parse(`${this.appService.validateLocalStorageData('state__clubsData')}`);
            this.sitesEvents = JSON.parse(`${this.appService.validateLocalStorageData('state__sitesEvents')}`);
            this.recentOrders = JSON.parse(`${this.appService.validateLocalStorageData('state__recentOrders')}`);
            this.futureReservations = JSON.parse(`${this.appService.validateLocalStorageData('state__futureReservations')}`);
            this.ordersFromLocalStorage = JSON.parse(`${this.appService.validateLocalStorageData('state__customer_orders')}`);
        } catch(err) {
            console.error('Error in retrieving data from localStorage', err)
        }

        this.changeDetectorRef.detectChanges();

        if (this.ordersFromLocalStorage?.length) this.removeOrdersFromLocalStorage(this.ordersFromLocalStorage);

    }

    private removeOrdersFromLocalStorage(orders: any[]) {
        orders.forEach(order => {
            this.orderTrackerService.getOrderById(order.id, order.organization).subscribe(updatedOrder => {
                if ((
                    !updatedOrder ||
                    updatedOrder?.deliveryInfo?.ETA && moment().diff(updatedOrder?.deliveryInfo?.ETA, 'hours') > 2) ||
                    updatedOrder?.created && moment().diff(updatedOrder?.created, 'hours') > 4
                ) {
                    this.removeOrder(order);
                } else {
                    this.trackedOrders.push(order);
                }

                this.changeDetectorRef.detectChanges();

            }, error => {
                console.error('Error in getting your order:', error, order);
                if (error && error.status == 404) {
                    this.removeOrder(order);
                }

                this.changeDetectorRef.detectChanges();
            });
            // console.log(`=== DASHBOARD/ORDERS`, orders);
        }, err => {
            console.error('Error getting orders', err);
        });

        this.changeDetectorRef.detectChanges();
    }

    private removeOrder(order: any) {
        let ordersToSave = this.ordersFromLocalStorage.filter(orderFromStorage => orderFromStorage.id !== order.id);
        this.storageService.setItem('state__customer_orders', JSON.stringify(ordersToSave || []));
    }

    private getClubs() {
        // To prevent benefitDetails open on multiAccount
        this.appService.isWhiteLabel.next(true);

        const clubRequests = this.clubIds.map(id => this.loyaltyService.getCustomerClub(id));

        forkJoin(clubRequests).subscribe(clubsData => {
            // console.log('=== DASHBAORD/getClubs ===', clubsData);
            this.clubsData = clubsData.filter(clubData => (clubData?.points?.length || clubData?.benefits?.length));
            this.storageService.setItem('state__clubsData', JSON.stringify(this.clubsData || []));
            if (!this.clubsDataLength) this.clubsDataLength = this.clubsData?.length;

            this.changeDetectorRef.detectChanges();
        });

        this.changeDetectorRef.detectChanges();
    }

    private getMultiAccountData() {
        // To prevent benefitDetails open on multiAccount
        this.appService.isWhiteLabel.next(false);

        if (this.appService.isAuthUser()) { // We only load MultiAccountData if authorized
            this.loyaltyService.getMultiAccountData().subscribe(multiAccount => {
                // console.log('=== DASHBAORD/multiAccount ===', multiAccount);
                this.clubsData = multiAccount.filter(clubData => (clubData?.points?.length || clubData?.benefits?.length));
                this.storageService.setItem('state__clubsData', JSON.stringify(this.clubsData || []));
                if (!this.clubsDataLength) this.clubsDataLength = this.clubsData?.length;
                this.changeDetectorRef.detectChanges();
            }, (err: any) => {
                console.error('getMultiAccountData Error: ', err);
            });
        }

    }

    ngAfterViewInit() {

    }

	reservationSectionAnimationFinished() {
		this.reservationSectionAnimationTrigger = 'visible';
    }

    recentOrdersSectionAnimationFinished() {
		this.recentOrdersSectionAnimationTrigger = 'visible';
    }

    trackedOrderSectionAnimationFinished() {
		this.trackedOrderSectionAnimationTrigger = 'visible';
    }

	siteCardsAnimationFinished() {
        if (this.appService.dashboardInitialLoad) {
            this.appService.dashboardInitialLoad = false;
        }
        //setTimeout(() => {
            this.reservationSectionAnimationTrigger = 'show';
            this.recentOrdersSectionAnimationTrigger = 'show';
            this.trackedOrderSectionAnimationTrigger = 'show';
        //}, 0); // Unfortunately this is required in order to prevent ExpressionChangedAfterItHasBeenCheckedError
	}

	ngOnDestroy() {
        this.locationSubscription.unsubscribe();
        this.orgsSubscription.unsubscribe();
        this.tagsSubscription.unsubscribe();
        this.loadingMetaDataSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        if (this.marketplaceSubscription) this.marketplaceSubscription.unsubscribe();
        if (this.loadingCoreDataSubscription) this.loadingCoreDataSubscription.unsubscribe();
        if (this.eventsSubscription) this.eventsSubscription.unsubscribe();
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
	}

	scrollDirection: string = 'up';
	showScrollUp: boolean = false;
	showMoreSitesTimeout: any = null;

	public handleScroll(event: any) {
        // Since currently doing nothing about loading organizations, TODO: Check if there is a reason to handle scroll at all.
		let direction = event.isReachingTop || event.direction == 'up' ? 'up' : 'down';
		if (direction != this.scrollDirection) this.scrollDirection = direction;
		this.showScrollUp = !event.isReachingTop && event.scrollTop && event.scrollTop > 200;

        this.organizationsService.searchScreenScrollPosition = event.scrollTop;

    }

    model: any;

    onSearchSelect(ev) {
        this.appService.redirect(['/dashboard/' + ev.item._id]);
        ev.preventDefault();
        this.model = null;
    }

    formatter = (x: { name: string }) => x.name;

    private getReservations(reservations: any[]) {
        if(
            !this.appService.isAuthUser() ||
            !reservations?.length
        ) return [];

        //return reservations.filter(reservation => (this.appService.isReservationRelevant(reservation) && this.organizationsService.getOrganization(reservation.organization)));
        return reservations.filter(reservation => (this.appService.isReservationRelevant(reservation)));
    }

    siteClick(site: any) {
        this.organizationsService.searchScreenNeedsScroll = true;
        this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    sitePress(site, ev) {
        // 2019-09-23 - Deprecated per Barry's request
        // 2019-12-24 - Re-enabled because in Delivery the tap redirects directly to  Ordering, and we want to let the user the ability to go into the Site Details too.
        this.dialogsService.showSiteActionsDialog(site, ev, this.router);
    }

    /*
    businessInviteRedirect() {
        this.dialogsService.toggleActionFrame('link', null, null, window['cordova'], 'https://restaurants.tabitisrael.co.il/?utm_source=tabitapp&utm_medium=upper_button');
    }
    */

    searchDelivery(type: any) {
        if (type == 'takeaway') {
            this.appService.redirect(['/order'], { queryParams: { 'service-type': type } });
        } else if (this.selectedLocationType == 'manual' || this.selectedLocationType == 'actual') {
            this.appService.redirect(['/order'], { queryParams: { 'service-type': type } });
        } else {
            // Decided to not use houseRequired, and return premise addresses
            this.dialogsService.showAddressDialog({houseRequired: false}).subscribe((address: Address) => {
                if (!address) return;
                if (!address.location || !address.formatted_address) throw new Error('Cannot choose address as location');
                if (address.house) {
                    this.addressesService.saveAddress(address).subscribe(savedAddress => {
                        // console.debug('=== DASHBOARD === Going to set address default:', savedAddress);
                        // this.addressesService.setDefault(savedAddress); // Currently not waiting for
                    }, err => {
                        console.error('Error saving address:', err);
                    });
                }
                this.locationService.chooseSpecifiedLocation(address.location, address.formatted_address);
                this.appService.redirect(['/order'], { queryParams: { 'service-type': type } });
            });
        }
    }

    getTagParams(tagName) {
        const tagId = this.tagsService.getSubGroupTagId(tagName);
        const tagsArray = [tagId]
        return { tags: tagsArray };
    }

    private setUserPoints(isAuth: boolean) {
        if (isAuth && this.appService.userPoints > 0) {
            this.userPointsTitle = `${this.appService.translate('USER_POINTS.btw_you_have')} ${this.appService.userPoints} ${this.appService.translate('points')}!`;
            this.userPointsSubtitle = this.appService.translate('USER_POINTS.for_each_order_more_bonus');
        } else {
            this.userPointsTitle = this.appService.translate('USER_POINTS.btw_you_dont_have');
            this.userPointsSubtitle = this.appService.translate('USER_POINTS.for_each_order_bonus');
        }
        this.userPointsAnimationTrigger = 'show';
    }

    private loadDashboardData() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData.subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.domainSubscription = this.appService.domain
                .subscribe(domain => {
                    this.setUserData(domain);
                    this.bookSelectionBarImageBackground = this.setBackgroundImage(domain);
                    this.domainDataLoaded = true;
                    this.domain = domain;
                    this.giftCardDomainConfig = this.loyaltyService.getGiftCardDomainConfig(domain);
                    this.giftCardsAccountGuid = this.loyaltyService.getGiftCardAccountGuid(domain);
                    this.dashboardSectionsDomainConfig = this.getSectionsConfig(domain);
                    if (this.appService.isShowSplashScreen(domain)) this.appService.splashScreensDialog(domain.links?.splashScreenImageURL);
                    if (this.appService.isAuthUser()) {
                        this.clubIds = this.domain?.clubIds || [];
                        if (this.clubIds?.length) this.getClubs();
                        else this.getMultiAccountData();
                    }
                    if (this.dashboardSectionsDomainConfig?.marketplace?.visible) {
                        this.marketplaceSubscription = this.marketplaceService.getMarketplaceOrganizations()
                        .subscribe(orgs => this.marketplaceOrgs = orgs);
                    }

                    this.changeDetectorRef.detectChanges();
                });
            }
        });
    }

    private getSectionsConfig(domain) {
        const defaultSettings = {
            trackedOrders: {
                visible: true,
            },
            futureReservations: {
                visible: true,
            },
            recentOrders: {
                visible: true,
            },
            clubsData: {
                visible: true,
            },
            foodTags: {
                visible: true,
            },
            newAtTabitOrgs: {
                visible: true,
            },
            homeOrderOrgs: {
                visible: true,
            },
            favorites: {
                visible: true,
            },
            marketplace: {
                visible: false,
            },
        }

        const domainSettings = get(domain, 'defaults.serviceConfiguration.dashboardSections', {});

        const finalSettings = Object.assign(defaultSettings, domainSettings);

        return finalSettings;
    }

    private setBackgroundImage(domain): string {
        if (get(domain, 'dashboardImagePath', null)) return `url(${get(domain, `dashboardImagePath`)}) !important`;
        const imagePath = this.appService.isDesktop() ? 'desktop' : 'mobile' ;
        return `url(${this.appService.base(`assets/images/dashboard-${imagePath}-temp.jpg`)})`;
    }

    private setUserData(domain: any) {
        if (this.appService.isAuthUser()) {
            // Update Customer Reservations
            this.entityService.getCustomerReservations('dashboard').then(reservations => {
                this.futureReservations = this.getReservations(reservations);
                this.storageService.setItem('state__futureReservations', JSON.stringify(this.futureReservations || []));

                this.changeDetectorRef.detectChanges();
            });

            this.entityService.getUserHistory().then(response => {
                // We've done this to sort out performance issue for users with large amount of orders
                // I've not done this at entity.service because did not want to mutate the response
                // because eventually, the proper solution would be pagination/slice in the back-end
                let responseOrders = response.slice(0, 10);
                if (get(this.appService.user, 'loyaltyCustomer.CustomerId') && get(domain, 'defaults.reviewButtonEnabled', false) ) {
                    this.userReviewsService.getUserReviews(this.appService.user.phone).subscribe(reviews => {
                        this.recentOrders = this.entityService.checkIfHistoryItemValidForReview(reviews, responseOrders);
                        this.storageService.setItem('state__recentOrders', JSON.stringify(this.recentOrders || []));
                    })
                } else {
                    this.recentOrders = responseOrders;
                    this.storageService.setItem('state__recentOrders', JSON.stringify(this.recentOrders || []));
                }
    
                this.setUserPoints(true);
    
                this.changeDetectorRef.detectChanges();
            });
        } else this.setUserPoints(false);
    }

    private initDashboard() {
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) {
                this.entityService.subscribeToCoreData();
            }
        });

        // Layout Configurations
        this.showEvents = get(this.appService, 'appConfig.layout.dashboard.events.enabled');

        this.takeDataFromLocalStorage();

        this.sitePrefs = this.appService.account.sitePrefs;

        this.appService.setStatusBarStyle('dark');

        this.bookService.startWithoutSite();

        this.loadingMetaDataSubscription = this.appService.loadingMetaData.subscribe(loading => {
            this.loadingMetaData = loading;
            if (!loading) {
                this.loadDashboardData();

                this.changeDetectorRef.detectChanges();
            }
        });

        this.locationSubscription = combineLatest([
            this.locationService.location,
            this.locationService.actualLocationAvailable,
        ]).subscribe(([ locationLabeled, available ] : [ LocationLabeled, boolean ]) => {
            this.locationLabeled = locationLabeled;
            if (locationLabeled.actual) this.selectedLocationType = 'actual';
            else if (locationLabeled.area) this.selectedLocationType = locationLabeled.area;
            else this.selectedLocationType = 'manual'; // It's different from the option value, by design!

            this.changeDetectorRef.detectChanges();
        });

        // Tags Subscription
        this.tagsSubscription = this.tagsService.tagsData$.subscribe(tags => {
            this.foodTags = tags?.filter(tag => tag.type !== 'occasions' && tag.showOnHomeView === true);
            this.occasionTags = tags?.filter(tag => tag.type === 'occasions');
            this.tagsService.subGroupTags.map(tag => tag.lang[this.appService.localeId.toLowerCase()].label);

            this.changeDetectorRef.detectChanges();
        });

        // Loading Events (Occasions)
        // 22/07/2021 Decided to remove for now
        // if (this.showEvents) {
        //     this.eventsSubscription = this.occasionsService.getOccasions(this.timeFrom, this.timeTo, this.eventLimit).subscribe(occasions => {
        //         this.sitesEvents = occasions;
        //         this.storageService.setItem('state__sitesEvents', JSON.stringify(this.sitesEvents || []));
        //         this.sitesEventsLength = this.sitesEvents ? this.sitesEvents.length : null;
        //     });

        //     this.changeDetectorRef.detectChanges();
        // }

        let nearbyAllOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.nearbyAll];
        let nearbyTabitOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.nearbyTabit];
        let homeOrderOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.homeOrder];
        let newAtTabitOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.new];
        let favoriteOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.favorites];
        let nearbySubGroupOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.nearbySubGroup];

        // Merge and flag (to un-merge). That's all to have only one subscription, not 1000:
        this.orgsSubscription = merge(
            nearbyAllOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.nearbyAll, orgs }))),
            nearbyTabitOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.nearbyTabit, orgs }))),
            homeOrderOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.homeOrder, orgs }))),
            newAtTabitOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.new, orgs }))),
            favoriteOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.favorites, orgs }))),
            nearbySubGroupOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.nearbySubGroup, orgs }))),
        ).pipe(
            map(organizationsContainer => ({ ...organizationsContainer, orgs: orderBy(organizationsContainer.orgs, ['distance'], ['asc'])})),
        ).subscribe((organizationsContainer: any) => {
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.nearbyAll) {
                //setTimeout(() => {
                    this.nearbyAllOrgs = organizationsContainer.orgs;
                    this.nearbyAllOrgsLength = this.nearbyAllOrgs.length;
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.nearbyTabit) {
                //setTimeout(() => {
                    this.nearbyTabitOrgs = organizationsContainer.orgs;
                    this.nearbyTabitOrgsLength = this.nearbyTabitOrgs.length;
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.homeOrder) {
                //setTimeout(() => {
                    this.homeOrderOrgs = organizationsContainer.orgs;
                    this.homeOrderOrgsLength = this.homeOrderOrgs.length;
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.new) {
                //setTimeout(() => {
                    this.newAtTabitOrgs = organizationsContainer.orgs;
                    this.newAtTabitOrgsLength = this.newAtTabitOrgs.length;
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.favorites) {
                //setTimeout(() => {
                    this.favoriteOrgs = organizationsContainer.orgs;
                    this.favoriteOrgsLength = this.favoriteOrgs.length;
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.nearbySubGroup) {
                //setTimeout(() => {
                    this.nearbySubGroupOrgs = organizationsContainer.orgs;
                    this.nearbySubGroupOrgsLength = this.nearbySubGroupOrgs.length;

                    if (this.nearbySubGroupOrgsLength) {
                        this.tagsService.addOrgsToSubGroupTags(this.nearbySubGroupOrgs);
                        this.nearbySubGroupOrgsMap = orderBy(this.tagsService.subGroupTags, ['index'], ['asc']);
                    }
                //}, 0); // Unfortunately this is required in order to prevent a delay of the whole page (due to animations being heavy) and to prevent ExpressionChangedAfterItHasBeenCheckedError
            }

            // Scroll to saved position
            if (this.organizationsService.searchScreenNeedsScroll && this.organizationsService.searchScreenScrollPosition) {
                setTimeout(() => {
                    this.animateList = 'show';
                    $('#dashboard-module-content').scrollTop(this.organizationsService.searchScreenScrollPosition);
                    setTimeout(() => {
                        this.organizationsService.searchScreenNeedsScroll = false;
                    }, 300);
                }, 0);
            } else {
                setTimeout(() => {
                    this.animateList = 'show';
                }, 0);
            }

            this.changeDetectorRef.detectChanges();
        }, err => {
            console.error('error with incoming organizations:', err);
        });
    }
}



