import { Component, Inject, NgZone, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { distinctUntilChanged, take } from 'rxjs/operators';

declare const $: any;

import { AppService } from '../../../app.service';
import { GiftCardsService } from '../../gift-cards.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'gcs-adyen-dialog',
    templateUrl: './gcs-adyen-pay-dialog.component.html',
    styleUrls: ['./gcs-adyen-pay-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, BlockUIModule, TranslateModule]
})
export class GCSAdyenPayDialogComponent {
    @BlockUI() blockUI: NgBlockUI;
    @HostListener('window:message', ['$event'])
    onMessage(e) {
        if (e.data?.adyenPayload) this.applyPayment(e.data.adyenPayload);
    }

    constructor(
        public appService: AppService,
        public giftCardsService: GiftCardsService,
        private ngZone: NgZone,
        public dialogRef: MatDialogRef<GCSAdyenPayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        window["ADYENPARAMS"] = this.data;
    }

    async applyPayment(adyenPayload) {
        const that = this;
        this.appService.startBlock();

        const data = {
            ...this.data.args,
            ...{
                paymentProcessId: this.data.args.paymentProcessId,
                amount: this.data?.surcharge?.total || this.data?.args?.amount,
                providerInfo: adyenPayload,
            },
            returnUrl: `${document.location.origin}/assets/adyen/success.html`
        }

        this.ngZone.run(() => {
            this.giftCardsService.giftCardPurchaseResponse
            .pipe(
                distinctUntilChanged(),
                take(1)
            ).subscribe(response => {
                if (!response) return;
                if (response?.problems?.Key == 'PaymentActionRequired') return handle3DSPayment(response.problems);

                this.appService.stopBlock();

                const isSuccess = !!response?.IsSuccess || !!response?.problems?.IsSuccess;
                this.dialogRef.close({ mode: (isSuccess ? "success": "error") });
            });
        })

        try {
            this.giftCardsService.setTempPaymentDetails(data);

            const options = {
                payParams: {
                    paymentProcessId: data.paymentProcessId,
                    returnUrl: data.returnUrl,
                    providerInfo: data.providerInfo,
                    amount: data.amount,
                }
            }
            this.giftCardsService.buyGiftCard(options);
        } catch(err) {
            console.log('=== ERROR IN BUY ===', err);
        }

        function handle3DSPayment(responseData) {
            that.appService.stopBlock();

            const parsedData: any = getRosResponse(responseData);

            try {
                document.getElementById('add_payment')["contentWindow"].call3DSAction(parsedData?.action);
            } catch (e) {
                this.dialogRef.close({ mode: "error" });
            }

            setTimeout(() => { that.appService.stopBlock(); }, 500);
        }

        function getRosResponse(err) {
            const rosResponse = err?.ResponseData?.RosResponse || err?.RosResponse;
            if (!rosResponse.match(/^\{/)) return { action: 'ERROR' };
            try {
                const parsedResponse = JSON.parse(rosResponse);
                return { action: parsedResponse.action };
            } catch(err) {
                return { action: 'ERROR' };
            }
        }
    }

    onFrameLoaded(ev) {
        try {
            this.appService.stopAllBlocks();
            let _location = ev.currentTarget.contentWindow.location;
            if (_location.href) {
                console.log("Adyen frame loaded: " + _location.href);
            }
        } catch (err) {

        }
    }

}


