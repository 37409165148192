import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';

import { environment } from '../../environments/environment';
import { OrganizationsService } from './organizations.service';
import { ORGANIZATION_BUCKET_TYPES } from './OrganizationBucket';
import { LocationService, LocationLabeled } from './location.service';

import { map } from 'rxjs/operators';
import moment from 'moment';
import { assignIn } from 'lodash-es';
const DAY_KEY_FORMAT = 'YYYY-MM-DD';

@Injectable({
	providedIn: 'root',
})
export class OccasionsService {

    appConfig: any = environment.appConfig;

    private occasionsLimit = 24;

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private organizationsService: OrganizationsService,
        private locationService: LocationService,
    ) {}

    public occasions(organizationId): Observable<any[]> {
        return this.http.get<any[]>(`${this.appConfig.tabitBridge}/organizations/${organizationId}/occasions`);
    }

    public getOccasionsDateMapped(reservations): Map<string, []> {
        let occasionsDateMapped = new Map();
        reservations.forEach(reservation => {
            if (reservation.type !== "occasion") return;
            this.getReservationDaysKeys(reservation).forEach(dayKey => {
                let occasionsForDay = occasionsDateMapped.get(dayKey) || [];
                occasionsForDay.push(reservation);
                occasionsDateMapped.set(dayKey, occasionsForDay);
            });
        });
        return occasionsDateMapped;
    }

    public getOccasionsForTimeSlot(slotKey: string, slotLength: number, date, occasions): any[] {
        if (!slotKey.match(/^\d\d:\d\d$/)) throw new Error('time slot key has to have the padded form `HH:mm`');

        let slotBegin = moment(date).hours(parseInt(slotKey.split(':')[0])).minutes(parseInt(slotKey.split(':')[1])).startOf('minute');

        return occasions.filter(occasion => {
            return slotBegin.isBetween(occasion.reservation_details.reserved_from, occasion.reservation_details.reserved_until, null, '[)');
        });
    }

    public getReservationDaysKeys(reservation) {
        let dayKeys = [];
        let reservationDay = moment(reservation.reservation_details.reserved_from);

        while (reservationDay.isSameOrBefore(reservation.reservation_details.reserved_until)) {
            dayKeys.push(reservationDay.format(DAY_KEY_FORMAT));
            reservationDay.add(1, 'day');
        }

        return dayKeys;
    }

    public showFrameEdge(date, occasion: any, edge: string): boolean {
        if(edge != 'from' && edge != 'until') return false;
        if (!date) return false;
        if (!occasion || !occasion.reservation_details || !occasion.reservation_details['reserved_' + edge]) return false;
        if (occasion.occasion_details && occasion.occasion_details.all_day) return false;

        let dayBegin = moment(date).startOf('day').add(5, 'hours');
        let dayEnd = moment(date).endOf('day');

        let frameEdge = moment(occasion.reservation_details['reserved_' + edge]);

        return frameEdge.isBetween(dayBegin, dayEnd, null, '[]');
    }

    public getOccasions(timeFrom: any, timeTo: any, limit?: any): Observable<any> {
        if (!limit) limit = this.occasionsLimit;
        timeFrom = moment(timeFrom).toISOString();
        timeTo = moment(timeTo).toISOString();
        let location = this.getCurrentLocationFromState().location;
        let requestParams: any = assignIn({}, this.appService.appHttpOptions, {
            params: { ...location, timeFrom, timeTo, limit, published: true }
        });

        let url = `${this.appConfig.tabitBridge}/occasions`;

        return this.http.get(url, requestParams).pipe(
            map((response: any) => {
                this.organizationsService.publishOrganizations(response.organizations || [], ORGANIZATION_BUCKET_TYPES.extra, { clear: false, skip: -1});

                return response.occasions;
            })
        );
    }

    public setPriceLabel(org) {
        if (!org || !org.googlePlaceDetails || !org.googlePlaceDetails.price_level) return '';
        return new Array(org.googlePlaceDetails.price_level).fill(this.appService.currency).join('');
    }

    public bookClick(event: Event, timestamp: any, orgId: string) {
        event.stopPropagation();

        let queryParams: any = {
            orgId,
            timestamp,
            source: this.appService.skin ? this.appService.skin : 'tabit',
        };

        return this.appService.redirect([this.appService.getTranslatedRoute('reservation')], { queryParams });
    }

    public getOccasionFullTags(tagIds: string[], occasionsTags: any[]) {
        let filteredTags: any = [];
        let filteredTag;
        tagIds.forEach(tagId => {
            filteredTag = occasionsTags.find(tag => tag._id === tagId);
            if (filteredTag) filteredTags.push(filteredTag);
        });

        return filteredTags || [];
    }

    private getCurrentLocationFromState(): LocationLabeled {
        let labeledLocation: LocationLabeled = this.locationService.getChosenLocation();
        if (!labeledLocation || !labeledLocation.location) throw new Error('missing location, supposed to always be at state');
        return labeledLocation;
    }
}
