import { Component, Input } from '@angular/core';
import { WebArticle } from '../web-article/web-article';
import { NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'web-article-preview',
    templateUrl: './web-article-preview.component.html',
    styleUrls: ['./web-article-preview.component.scss'],
    standalone: true,
    imports: [RouterLink, NgStyle]
})
export class webArticlePreviewComponent {

  @Input() public articleLink: string;
  @Input() public articleId: string;

  constructor(public webArticle: WebArticle) { }

}
