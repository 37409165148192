<div *ngIf="site" id="site-detail-module-bar" class="module-bar splash" [class.inverse]="headerStyleInverse">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="navigateBack()">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{site.name}}</span>
        <div class="share" *ngIf="appService.shareWidgetExists() && !appService.skin" mat-icon-button (click)="shareSite()">
            <mat-icon>share</mat-icon>
        </div>
        <favorite-fab *ngIf="appService.isAuthUser() && !appService.skin" [white]="!headerStyleInverse" [site]="site"></favorite-fab>
        <notifications-widget-opener [isBright]="!headerStyleInverse"></notifications-widget-opener>
    </div>
</div>

<div *ngIf="site" class="module-splash" [style.background-image]="(site.largeImage || appService.images.default_site_image) | safeStyle" [style.height]="imageContainerHeight">
    <div @siteLogoAnimation *ngIf="showSiteLogo" class="_logo" [style.background-image]="site.logo | safeStyle"></div>
</div>
<div *ngIf="site" id="site-detail-content" class="module-content wl-app-primary-background-color" (scroll)="checkScroll($event)">
    <div class="spacer_top" (click)="openGallery(0, true)"></div>

    <div [ngClass]="afterMenuLoad ? 'after-menu-load _inner wl-app-primary-background-color' : '_inner wl-app-primary-background-color'">
        <!-- It's important that the image tag and the site name will be at the same line - to prevent white-spaces -->
        <div class="_title" *ngIf="site.name" [class.only]="!site.description && !site.tags?.length">{{ site.name | translate }}</div>

        <div #siteDescription
            *ngIf="site.description"
            class="_description"
            [class.long]="ifDescriptionLong(siteDescription)"
            [class.limited]="!descriptionExpanded"
            (click)="descriptionExpanded = !descriptionExpanded"
        >
            <div class="description-inner">{{site.description}}</div>
        </div>

        <div class="site-categories" *ngIf="site.tags?.length && !hideTags">
            <span class="category-name" *ngFor="let tag of getSiteTags(); let last = last;">
                {{tag}}{{ last ? '' : ' | ' }}
            </span>
        </div>

        <div class="rating" *ngIf="site?.googlePlaceDetails?.rating && (appService.googlePlaceDetailsSubject | async).showRating">
            <rating [site]="site"></rating>
            <div class="by-google" dir="ltr">by
                <img class="google-icon" [src]="appService.base('assets/images/google-icon-small.png')" />
            </div>
        </div>

        <div @siteSpinnerAnimation class="site-tabs-spinner" *ngIf="!showTabs">
            <mat-spinner diameter="80" strokeWidth="5" class="light-grey"></mat-spinner>
        </div>

        <!-- <div @sitePromoAnimation *ngIf="site?.bookingData?.intro_localized[appService.localeId] && showTabs" class="site-promo">
            <div>{{site.bookingData.intro_localized[appService.localeId]}}</div>
        </div> -->

        <div @siteBookingAnimation class="booking-container" *ngIf="bookService.orgTimeSlots[site._id] && showTabs">
            <span class="title flex-grow-1 text-truncate">{{ 'SERVICE_BUTTON.book' | translate }}</span>
            <time-slots class="time-slots-container d-flex" *ngIf="bookService.orgTimeSlots[site._id]" [timeSlots]="bookService.orgTimeSlots[site._id]" [siteId]="site._id" [areaDescriptions]="areaDescriptions"></time-slots>
        </div>

        <mat-tab-group @siteTabsAnimation *ngIf="showTabs" class="generic-tab-group" [(selectedIndex)]="tabsIndex" [disableRipple]="true" (selectedTabChange)="tabChanged($event)">
            <!-- Overview -->
            <mat-tab label="{{'site_details.tab_label_overview' | translate}}">
                <div class="overview-container">
                    <div *ngIf="site" class="services-buttons">
                        <site-actions [attr.aria-label]="'ACCESS.site_service' | translate" [site]="site"></site-actions>
                    </div>
                    <div class="_actions"> 
                        <ng-container *ngIf="site.timeSlots">
                            <div class="_row" (click)="dialogsService.toggleActionFrame('navigate', site)">
                                <h4 class="_address">{{site.address}}</h4>
                                <div class="_info navigate" *ngIf="locationService.hasLocation && site.distance && site.formattedDistance !== 'far_away'">{{site.formattedDistance | translate}} {{unit | translate}}</div>
                                <div class="_info navigate" *ngIf="locationService.hasLocation && site.distance && site.formattedDistance === 'far_away'">{{site.formattedDistance | translate}}</div>
                                <mat-icon class="_icon" svgIcon="navigate" aria-label="place"></mat-icon>
                            </div>
                            <div class="_row" (click)="callPhone()" *ngIf="site.phone || (site.reservation && site.reservation.method === 'phone' && site.reservation.methodValue)">
                                <h4>{{ site.phone || (site.reservation.method === 'phone' && site.reservation.methodValue) }}</h4>
                                <mat-icon class="_icon" svgIcon="call" aria-label="Phone"></mat-icon>
                            </div>
                            <div class="_row" *ngIf="site.activeSlot.slotFound" (click)="showTimeslots = !showTimeslots">
                                <h4 class="d-flex align-content-center align-items-center">
                                    <span>{{'Open' | translate}}</span>
                                    <mat-icon class="md">{{showTimeslots ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                                </h4>
                                <div class="_info">{{site.activeSlot.slotFound.text}}</div>
                                <mat-icon class="_icon" svgIcon="open" aria-label="Time"></mat-icon>
                            </div>
                            <div class="_row" *ngIf="!site.activeSlot.slotFound" (click)="showTimeslots = !showTimeslots">
                                <h4 class="d-flex align-content-center align-items-center">
                                    <span>
                                        {{'Closed' | translate}}
                                        <!--
                                        <small class="text-accent" *ngIf="site.tdSlot"><br/>{{'CAN_TD' | translate}}</small>
                                        <small class="text-accent" *ngIf="!site.tdSlot && utilsService.siteCanBook(site)">{{'book' | translate}}</small>
                                        -->
                                    </span>
                                    <mat-icon class="md">{{showTimeslots ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                                </h4>
                                <div class="_info" *ngIf="site.activeSlot.slotNext">{{site.activeSlot.slotNext.text}}</div>
                                <mat-icon class="_icon" svgIcon="open" aria-label="Time"></mat-icon>
                            </div>
                            <div [ngxCollapse]="!showTimeslots">
                                <table class="working-time-slots table table-sm table-striped table-borderless" style="font-size:12px;">
                                    <tbody>
                                        <tr *ngFor="let day of site.workHours; let $index = index" [ngClass]="{'font-weight-bold': $index == currentDay}">
                                            <td>{{'DAYS.' + day.day | translate}}</td>
                                            <td *ngIf="day.active" class="bullet-separated-list" aria-label="Time">
                                                <span *ngFor="let slot of day.timeSlots">
                                                    {{ slot.from | AMPMConverter }} - {{ slot.to | AMPMConverter }}
                                                </span>
                                            </td>
                                            <td *ngIf="!day.active"  aria-label="Time">
                                                {{'closed_for_day' | translate}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="accessibilityTags?.length > 0" class="_row" role="region" aria-labelledby="accessibility-options-title" tabindex="0">
                                <h4 id="accessibility-options-title" class="d-flex align-content-center align-items-center" aria-label="Accessibility Options">
                                    {{ 'accessibility' | translate }}
                                </h4>
                                <mat-icon class="_icon" svgIcon="accessibility-new" aria-label="Accessibility options"></mat-icon>
                            </div>
                            <div *ngIf="accessibilityTags?.length > 0" class="accessibility-options" role="region" tabindex="-1">
                                <ng-container>
                                    <div class="accessibility-item-container">
                                        <span *ngFor="let tag of accessibilityTags; let i = index" class="accessibility-item" [attr.aria-label]="tag?.lang[appService.localeId.toLocaleLowerCase()]?.label" tabindex="0">
                                            {{ tag?.lang[appService.localeId.toLocaleLowerCase()]?.label }}
                                            <span *ngIf="i < accessibilityTags.length - 1" class="separator" aria-hidden="true">|</span>
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <a class="_row wl-ignore-theme">
                            <ng-container *ngIf="site.website">
                                <h4 (click)="dialogsService.toggleActionFrame('link', null, null, null, site.website)">{{ 'website' | translate }}</h4>
                            </ng-container>
                            <div class="spacer" *ngIf="!site.website"></div>
                            <mat-icon class="_icon social-links" svgIcon="instagram_outline_dark_1" aria-label="link" *ngIf="site?.socialNetworkLinks?.instagramURL" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.socialNetworkLinks.instagramURL)"></mat-icon>
                            <mat-icon class="_icon social-links" svgIcon="facebook_outline_dark_1" aria-label="link" *ngIf="site?.socialNetworkLinks?.facebookURL" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.socialNetworkLinks.facebookURL)"></mat-icon>
                            <mat-icon class="_icon social-links" svgIcon="bill" aria-label="link" *ngIf="site.website" (click)="dialogsService.toggleActionFrame('link', null, null, null, site.website)"></mat-icon>
                        </a>
                    </div>
                    <div *ngIf="photos?.length" class="photo-gallery">
                        <ng-container *ngFor="let photo of photos">
                            <img [src]="photo.photo_url" (click)="openGallery(photo.index)" />
                        </ng-container>
                    </div>
                </div>
            </mat-tab>

            <!-- club.benefits -->
            <mat-tab [label]="'club_benefits' | translate" *ngIf="clubsData.length">
                <div class="menus-container">
                    <mat-accordion displayMode="flat" class="no-margins generic-list-accordion">
                        <mat-expansion-panel *ngFor="let club of clubsData; let i = index" [expanded]="i == 0">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{club.clubName}}</mat-panel-title>
                            </mat-expansion-panel-header>
    
                            <mat-list class="generic-list">
                                <benefit-card [showBenefits]="true" [club]="club" [points]="club.points[0].points" [benefit]="club.pointsBenefit" *ngIf="club.points[0] && club.points[0].points > 0"></benefit-card>
                                <benefit-card [showBenefits]="true" [club]="club" [prePaidPoints]="club.points[0].prePaid" [benefit]="club.pointsBenefit" *ngIf="loyaltyService.checkPrepaidFlow(club?.points[0]?.prePaid)"></benefit-card>
                                <benefit-card [showBenefits]="true" *ngFor="let benefit of club.benefits" [club]="club" [benefit]="benefit"></benefit-card>
                            </mat-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </mat-tab>

            <!-- future.reservations -->
            <mat-tab [label]="'future_reservations' | translate" *ngIf="showFutureReservationsTab()">
                <div class="menus-container">
                    <future-reservations [reservations]="getSiteReservations()"></future-reservations>
                </div>
            </mat-tab>

            <!-- Menus -->
            <mat-tab [label]="'site_details.tab_label_menu' | translate" *ngIf="hasMenus">
                <div class="menus-container">
                    <div *ngFor="let menu of menus">
                        <app-menu-item [menuItem]="menu"></app-menu-item>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="על המסעדה" *ngIf="siteDetailsPage?.about || siteDetailsPage?.videoLink">
                <div class="description-container">
                    <ng-container *ngTemplateOutlet="site._id == '57b010e4cb82f61e009fe53a' ? exampleTemplate : mainTemplate;"></ng-container>
                </div>
            </mat-tab>

            <!-- Reviews -->
            <mat-tab [label]="'site_details.tab_label_reviews' | translate" *ngIf="showReviews">
                <div class="reviews-container">
                    <div class="review" *ngFor="let tabitReview of (tabitReviews?.length ? tabitReviews : null) | orderBy:created">
                        <div class="author-details layout-row">
                            <div class="author-animation">
                                <ng-lottie [options]="{path: tabitReview.review.animationPath, autoplay: true, loop: true}"></ng-lottie>
                            </div>
                            <div>{{tabitReview.user.firstName}}</div>
                        </div>
                        <!-- <button *ngFor="let reason of reasons" [class.selected]="selectedReasons[reason._id]" (click)="reasonToggle(reason._id)">{{reason.caption[appService.localeId]}}</button> -->
                        <!-- <div class="button-container">
                            <button *ngFor="let reason of reviewReasons">{{reason.caption[appService.localeId]}}</button>
                        </div> -->
                        <div class="review-details layout-row">
                            <div class="review-stars" dir="ltr">
                                <mat-icon [svgIcon]="starIcon" *ngFor="let starIcon of utilsService.getRatingStars(tabitReview.review.rating)"></mat-icon>
                            </div>
                            <div class="review-time">({{tabitReview.created}})</div>
                        </div>
                        <div class="review_tags">
                            <div *ngFor="let reason of tabitReview.review.reasons" [class.low-rating]="tabitReview.review.rating <= 3" [class.high-rating]="tabitReview.review.rating > 3">{{reason.caption[appService.localeId] | translate}}</div>
                        </div>
                        <div class="review-text" *ngIf="tabitReview.review.comment.length">{{tabitReview.review.comment}}</div>
                    </div>
                    <div class="review" *ngFor="let review of site.googlePlaceDetails?.reviews || []">
                        <div class="author-details layout-row">
                            <div class="author-photo">
                                <img [src]="review.profile_photo_url" />
                            </div>
                            <div class="author-name">{{review.author_name}}</div>
                        </div>
                        <div class="review-details layout-row">
                            <div class="review-stars" dir="ltr">
                                <mat-icon [svgIcon]="starIcon" *ngFor="let starIcon of utilsService.getRatingStars(review.rating)"></mat-icon>
                            </div>
                            <div class="review-time">({{review.relative_time_description}})</div>
                        </div>
                        <div class="review-text">{{review.text}}</div>
                    </div>
                </div>
            </mat-tab>

            <!-- History -->
            <mat-tab [label]="'site_details.tab_label_history' | translate">
                <div class="history-container">
                    <mat-spinner @siteSpinnerAnimation *ngIf="historySpinnerEnabled && !recents?.length" diameter="80" strokeWidth="5" class="light-grey"></mat-spinner>
                    <div @siteTabsAnimation *ngFor="let trackedOrder of trackedOrders | orderBy: 'created': true">
                        <tracked-order-item [order]="trackedOrder"></tracked-order-item>
                    </div>
                    <div @siteTabsAnimation *ngFor="let row of recents | slice:0:(allHistoryResults ? recents?.length : 30)">
                        <app-history-item class="wl-cards-background-color" [type]="row.type" [historyItem]="row" [withBill]="true"></app-history-item>
                    </div>
                    <div @siteTabsAnimation *ngIf="recents?.length > 30 && !allHistoryResults" class="show-more">
                        <button type="button" class="button" (click)="allHistoryResults = true">{{'show_more' | translate}}</button>
                    </div>
                    <div @siteTabsAnimation *ngIf="!historySpinnerEnabled && !recents?.length" class="show-more">
                        <span class="no-results">{{ 'no_recents' | translate }}</span>
                    </div>
                </div>
            </mat-tab>

            <!-- Map -->
            <mat-tab label="{{'site_details.tab_label_map' | translate}}">
                <div class="map" *ngIf="site.location">
                    <google-map
                    width="100%"
                    [center]="{lat: site.location.lat, lng: site.location.lng}"
                    [zoom]="12.9"
                    [options]="{
                        zoomControl: true,
                        rotateControl: true,
                        mapTypeControl: false,
                        gestureHandling: 'cooperative',
                        streetViewControl: true
                    }">
                    <map-marker
                      [position]="{lat: site.location.lat, lng: site.location.lng}"
                      [options]="{
                        icon: mapIconObj,
                        animation: googleAnimation
                      }">
                    </map-marker>
                  </google-map>
                </div>
            </mat-tab>
        </mat-tab-group>

        <div class="spacer_btm"></div>
    </div>
</div>

<!-- Generic template -->
<ng-template #mainTemplate>
    <div class="description" *ngIf="siteDetailsPage?.about">
        {{ siteDetailsPage.about }}
    </div>
    <iframe
        *ngIf="siteDetailsPage?.videoLink"
        width="100%"
        height="190"
        [src]="trustedIframeUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>
</ng-template>