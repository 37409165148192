import { Component, OnInit } from '@angular/core';

import { AppService } from '../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { WebTilesNewRestaurantsComponent } from '../web-container/web-tiles-new-restaurants/web-tiles-new-restaurants.component';
import { WidgetOpenerComponent } from '../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-new-restaurants',
    templateUrl: './new-restaurants.component.html',
    styleUrls: ['./new-restaurants.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, WebTilesNewRestaurantsComponent, TranslateModule]
})
export class NewRestaurantsComponent implements OnInit {

    constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
