import { Component, OnInit, Input } from '@angular/core';

import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { SafeStylePipe } from '../../_core/pipes';
import { MatIcon } from '@angular/material/icon';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: true,
    imports: [NgIf, AccessibleDirective, MatIcon, SafeStylePipe]
})
export class MenuItemComponent implements OnInit {

    @Input() menuItem:any;

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
    ) { }

    ngOnInit(): void {
        if (!this.menuItem) throw new Error('Race condition detected! Menu Item component initialized without menu item!');
    }

    openMenu(menuItem) {
        if (!menuItem) return;
        if (menuItem.isTabitOrderMenu) return this.appService.redirect(['tabit-order'], { queryParams: { site: menuItem.siteId, service: 'VIEW_MENU', isFuture: false } });
        this.dialogsService.toggleActionFrame('menu', null, null, null, menuItem.methodValue);
    }

}
