import { Component, Input, OnInit, NgZone } from '@angular/core';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';

import { set } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NonemptyValidator, CreditCardValidator, CreditCardExpirationValidator } from '../../components/validators';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { NgIf, NgFor } from '@angular/common';
import { TabitbookSelectionBarComponent } from '../tabitbook-selection-bar/tabitbook-selection-bar.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-tabitbook-details',
    templateUrl: './tabitbook-details.component.html',
    styleUrls: ['./tabitbook-details.component.scss'],
    standalone: true,
    imports: [FormsModule, TabitbookSelectionBarComponent, NgIf, MatAccordion, MatExpansionPanel, MatFormField, MatInput, NonemptyValidator, MatSelect, NgFor, MatOption, MatLabel, CreditCardValidator, MatIcon, MatSuffix, MatError, CreditCardExpirationValidator, MatButton, TranslateModule]
})
export class TabitbookDetailsComponent implements OnInit {

    @Input() options;

    $storage: any;
    step: any = 'start';
    customerDetailsFormFields: any = {};

    @Input('go-to-start') gotoStart: Function;

    constructor(
        private ngZone: NgZone,
        public appService: AppService,
        public bookService: BookService,
    ) {
        this.$storage = this.bookService.$storage;
    }

    submit(form, ev?) {
        if (!form.valid) return;

        // Add deposit
        if (this.$storage.request_deposit && this.$storage.deposit && this.$storage.deposit.number) {
            let cc = this.$storage.deposit;
            set(cc, 'exp.month', cc.expMonth);
            set(cc, 'exp.year', cc.expYear);

            this.$storage.bookDetails.deposit = { cc };
        }

        this.options.startBlock();
        this.bookService.putCustomerDetails().then(res => {
            // Clear saved timeSlots
            this.bookService.orgTimeSlots = {};
            // Reset deposit
            this.$storage.deposit = { exp: {} };

            if (res.reservation_details) {
                this.$storage.reservation_details = res.reservation_details;

                this.bookService.putReservation(res);
                this.options.gotoEnd();
            } else {
                let desc = res.description_string;
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: this.bookService.translate(desc)
                    }).then(res => { });
                });
            }
        }).catch(e => {
            console.error(e);
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'error_client_credentials'
                });
            });
            this.options.stopBlock();
        }).then(res => {
            this.options.stopBlock();
        })
    }

    ngOnInit() {
        let customerDetailsForm = this.$storage.site[`customer_details_form_${this.$storage.site.default_locale}`] || this.$storage.site.customer_details_form;
        if (customerDetailsForm) this.customerDetailsFormFields = customerDetailsForm.fields;
    }

}
