import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

declare const $: any;
@Component({
    selector: 'web-seo-header',
    templateUrl: './web-seo-header.component.html',
    styleUrls: ['./web-seo-header.component.scss'],
    standalone: true,
    imports: [RouterLink, TranslateModule]
})
export class WebSeoHeaderComponent implements OnInit, OnDestroy {

    constructor(
        public appService: AppService,
        public liveAnnouncer: LiveAnnouncer,
        public titleService: Title,
    ) { }

    @Input() breadcrumbsHomeLink: string;
    @Input() breadcrumbsCurrentLink: string;
    @Input() breadcrumbsCurrentTitle: string;

    ngOnInit() {
        this.liveAnnouncer.announce(this.appService.translate('new_restaurant_page') + ' ' + this.breadcrumbsCurrentTitle);
    }

    businessInviteRedirect() {
        if (this.appService.appConfig.locale.toLocaleLowerCase() === 'he-il') {
            window.open('https://www.restaurants.tabitisrael.co.il/?utm_source=tabitisrael_website&utm_medium=upper_button');
        } else {
            window.open('https://www.tabit.cloud');
        }
    }

    ngOnDestroy() {
        this.appService.webSeoTitle.next(null);
    }

}
