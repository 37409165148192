import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../src/app/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'web-app-smart-banner',
    templateUrl: './web-app-smart-banner.component.html',
    styleUrls: ['./web-app-smart-banner.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, TranslateModule]
})
export class WebAppSmartBannerComponent implements OnInit {

    constructor(
        public appService: AppService
    ) { }

    displayBanner: boolean;
    storeIcon: string;
    storeLink: string;

    navigateToApp() {
        if (!this.appService.appConfig.tabitAppURL) return;

        // Since the App version uses '#' (due to Cordova) - we generate the relevant URL with the hash.
        // The app.component deepLinkRedirect() function converts it back to the relevant routing.
        let redirectToAppHost:string = this.appService.appConfig.tabitAppURL.replace(/\/$/, '') + '/#' + this.appService.currentUrl;
        let delimeter = '?';
        if (/\?/.test(redirectToAppHost)) delimeter = '&';
        if (!/bannerRedirect/.test(redirectToAppHost)) redirectToAppHost += (delimeter + 'bannerRedirect=true');

        console.debug('=== web-app-smart-banner > redirectToAppHost: ', redirectToAppHost);
        window.location.href = redirectToAppHost;

        // if (navigator && /android/i.test(navigator.userAgent)) {
        //     window.location.href = 'https://play.google.com/store/apps/details?id=app.tabit.il_prd';
        // } else {
        //     window.location.href = 'https://apps.apple.com/il/app/tabit-%D7%98%D7%90%D7%91%D7%99%D7%98/id1463575038';
        // }
    };

    closeAndMarkAsWatched() {
        this.appService.webAppSmartBannerOpened = false;
        this.appService.webAppSmartBannerWatched = true;
    };

    ngOnInit() {
        if (window['cordova']) this.displayBanner = false;

        if (navigator && /android/i.test(navigator.userAgent)) {
            this.storeIcon = this.appService.base('assets/images/google-play.svg');
        } else {
            this.storeIcon = this.appService.base('assets/images/apple-app-store.svg');
        }
    }

}
