import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../app.service';
import { get } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { UserReviewsComponent } from '../user-reviews.component';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-user-review',
    templateUrl: './app-user-review.component.html',
    styleUrls: ['./app-user-review.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, UserReviewsComponent, TranslateModule]
})
export class AppUserReviewComponent implements OnInit, OnDestroy {

    public step: number = 0;

    private urlSubscription: any;
    private fromOrder: boolean;
    private urlReferrer: any;

    constructor(
        public appService: AppService,
        public activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
    ) { }

    ngOnInit() {
        this.urlSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (!params) return;
            this.urlReferrer = params['referrer'];
            this.fromOrder = params['from-order'];
        });
    }

    ngOnDestroy() {
        this.urlSubscription.unsubscribe();
    }

    goBack() {
        if (this.step == 0) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'info',
                    dialogTitle: 'in_progress_message',
                    dialogText: 'confirm_exit',
                    primaryButtonText: 'cancel_order',
                    secondaryButtonText: 'continue'
                }).then(() => {
                    this.redirectBack();
                }).catch(err => {
                    if (!get(err, 'secondaryButtonClick')) console.error('Cannot redirect back', err);
                });
            });
        } else if (this.step == 1) {
            this.step = this.step - 1;
		} else {
            this.redirectBack();
        }
    }

    stepChanged(event) {
        this.step = event;
    }

    private redirectBack() {
        this.fromOrder ? this.appService.redirect([this.urlReferrer]) : this.appService.goBack();
    }

}
