import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { AMPMConverterPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'tabitbook-selection-bar',
    templateUrl: './tabitbook-selection-bar.component.html',
    styleUrls: ['./tabitbook-selection-bar.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, DatePipe, TranslateModule, AMPMConverterPipe]
})
export class TabitbookSelectionBarComponent {
	@Input() $storage;
	@Input() parent;
	@Output() onEditor = new EventEmitter<{}>();

	editorValue;
	@Input()
	get editor() {
		return this.editorValue;
	}
	set editor(val) {
		this.editorValue = val;
	}

    @Input('go-to-start') gotoStart: Function;
    @Input('set-when-no-editor') setWhenNoEditor: boolean = false;

    constructor(
        public appService: AppService,
        public bookService: BookService,
    ) { }

	setEditor(mode) {
        if (this.editor || this.setWhenNoEditor) {
            this.editor = mode;
			this.onEditor.emit(mode);

			// Mark all the previous fields as selected
			if (mode) ['date', 'time', 'diners', 'preference'].some(editorName => {
				if (editorName != mode) this.$storage.bookFormChanged[editorName] = true;
				// Stop when the current editor is found
				return editorName == mode;
			});
        } else {
            if (this.gotoStart) this.gotoStart(mode);
        }
	}

}

