import { Component, OnInit, Input } from '@angular/core';

import { BookService } from '../../_core/book.service';
import { AMPMConverterPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { NgFor, NgClass, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'time-slots',
    templateUrl: './time-slots.component.html',
    styleUrls: ['./time-slots.component.scss'],
    standalone: true,
    imports: [NgFor, AccessibleDirective, NgClass, NgIf, DatePipe, OrderModule, TranslateModule, AMPMConverterPipe]
})
export class TimeSlotsComponent implements OnInit {

    @Input() public siteId: any;
    @Input() public timeSlots: any;
    @Input() public areaDescriptions: any;

    constructor(
        public bookService: BookService
    ) { }

    ngOnInit() {
        if (this.timeSlots) {
            this.timeSlots = this.bookService.populateDisabledTimeSlots(this.timeSlots);
        }
    }
}
