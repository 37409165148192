import { Component, OnInit, OnDestroy, ViewChild, Inject, Renderer2, HostListener, NgZone } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationError, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslateService } from '@ngx-translate/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Subscription, fromEvent } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';

import { routeAnimation } from './_core/static/route-animations';
import { AppInitService } from './app-init.service';
import { AppService } from './app.service';
import { AuthService } from './_core/auth.service';
import { EntityService } from './_core/entity.service';
import { EntityUtilsService } from './_core/entity.utils.service';
import { CordovaPluginsService } from './_core/cordova-plugins.service';
import { OrganizationsService } from './_core/organizations.service';
import { SpecialMessagesService } from './_core/special-messages.service';
import { AccountService } from './_core/account.service';

import { LocationService } from './_core/location.service';
import { NotificationsService } from './_core/notifications.service';
import { StorageService } from './_core/storage.service';
import { TranslationService } from './_core/translation.service';
import { StyleService } from './_core/style.service';
import { TOBrandingService } from './tabit-order/services/to.branding.service';

import { CountryCode } from '../app/intl-tel-input/data/country-code';
import { CountriesService } from './_core/countries.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash-es';
import moment from 'moment';
import 'moment-duration-format'; // Moment duration format is now available app-wide
import { ViewportRuler } from '@angular/cdk/scrolling';
import { count } from 'console';

//import * as Hammer from 'hammerjs';
declare const $: any;
@UntilDestroy({ checkProperties: true })

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '[class.cordova]': 'cordova'
    },
    animations: [
        trigger('showNoLocationIndicator', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('250ms 5s linear', style({ opacity: 1 })),
            ])
        ]),
        trigger('showNoNotificationsIndicator', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('250ms 5s linear', style({ opacity: 1 })),
            ])
        ]),
        trigger('slideWidget', [
            state('open', style({transform: 'translateY(0)'})),
            state('close', style({ transform: 'translateY(110%)' })),

            transition('close => open', [
                animate('0.3s 0s ease-out')
            ]),
            transition('open => close', [
                animate('0.3s 0s ease-in')
            ]),
        ]),
        routeAnimation,
    ],
})

export class AppComponent implements OnInit, OnDestroy {
    title: string = 'tla';
    direction: string = 'rtl';
    bottomLinksVisible: boolean = false;
    showNoLocationIndicator: boolean = false;
    showNoNotificationsIndicator: boolean = false;
    userBlockedNotifications: boolean = false
    originalViewportHeight: number = 0;
    @ViewChild('appSidenav', {static: false}) public appSidenav: MatSidenav;
    @BlockUI() blockUI: NgBlockUI;

    touchTarget: any = $('app-site-details')[0];
    touchTargetStartPosX: number = 0;
    touchTargetEndPosX: number = 0;
    touchTargetisDragging: boolean = false;
    childHasToHandleAndroidBack: boolean = false;
    showWidget: 'open' | 'close' = 'close';
    domain: any;
    contrastFeature = {
        active: false,
        class: 'ac-contrast-dark',
    };
    brandingScript: any;

    allowedPathsWhileUnderConstruction: any = this.appService.appConfig.allowedPathsWhileUnderConstruction;
    excludedPathsFromSubscriptions: any = this.appService.appConfig.excludedPathsFromSubscriptions;

    private isPushNotificationsDisabledSubscription: Subscription;
    private serverTimeSubscription: Subscription;
    private directionSubscription: Subscription;
    private localeSubscription: Subscription;
    private domainSubscription: Subscription;
    private countriesSubscription: Subscription;

    public viewportChange: Subscription;

    @HostListener('touchstart', ['$event']) onTouchStart(ev: any) {
        //console.log('Touch start: ', ev);
        if (!(ev && ev.changedTouches && ev.changedTouches[0])) return;

        const touch = ev.changedTouches[0];
        this.touchTargetStartPosX = touch.clientX;
    }
    @HostListener('touchmove', ['$event']) onTouchMove(ev: any) {
        if (!(ev && ev.changedTouches && ev.changedTouches[0])) return;

        const touch = ev.changedTouches[0];
        if (this.touchTargetStartPosX < 20) {
            $('app-site-details').offset({ left: touch.clientX });
        }
    }
    @HostListener('touchend', ['$event']) onTouchEnd(ev: any) {
        //console.log('Touch end: ', ev);
        if (!(ev && ev.changedTouches && ev.changedTouches[0])) return;

        const touch = ev.changedTouches[0];
        this.touchTargetEndPosX = touch.clientX;
        //console.log((this.touchTargetEndPosX - this.touchTargetStartPosX), this.touchTargetStartPosX, this.touchTargetEndPosX);

        if (this.touchTargetStartPosX < 20 && (this.touchTargetEndPosX - this.touchTargetStartPosX) > 80) {
            if (this.appService.currentSiteID) {
                this.appService.lastSiteOpened.next(this.appService.currentSiteID);
            }

            $('app-site-details').animate({ left: '100vw' }, 100, () => {
                this.appService.goBack();

                // The following is required in order to make sure that the app-site-details DOM element goes back to it's original (left:0) position.
                // Otherwise, if (for example) we're on the site-details, and "landed" with a deep-link on another site-details (of another restaurant), so when the user will click (or swipe) "back" - since we remain on the same component - the new restaurant site-details won't appear, as the host DOM element has already been moved to the left.
                setTimeout(() => {
                    $('app-site-details').animate({ left: '0' }, 250);
                }, 400);
            });
        } else if (this.touchTargetStartPosX < 20) {
            $('app-site-details').animate({ left: 0 }, 250);
        }
    }

    constructor(
        public appService: AppService,
        public appInitService: AppInitService,
        public entityService: EntityService,
        public utilsService: EntityUtilsService,
        public locationService: LocationService,
        public organizationsService: OrganizationsService,
        public cordovaPluginsService: CordovaPluginsService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private renderer: Renderer2,
        public translateService: TranslateService,
        public storageService: StorageService,
        private specialMessagesService: SpecialMessagesService,
        private translationService: TranslationService,
        public accountService: AccountService,
        @Inject(PLATFORM_ID) private _platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        public styleService: StyleService,
        private brandingService: TOBrandingService,
        private matDialog: MatDialog,
        private readonly viewportRuler: ViewportRuler,
        private countryCodeData: CountryCode,
        private countriesService: CountriesService,
    ) {
        // It happens only once here.
        // Later on we use translateService.use function
        translationService.changeUsedLanguage(this.translationService.lastUsedLangForMainApp, true);

        isPlatformBrowser(this._platformId);

        this.handleRouterEvents();

        // it was decided to prevent any dark-theme style if custom-branding is applied in TO.
        // due to Material's CSS injections, we need to apply globally a "default" state to the DOM
        // For now we force light-mode in Android devices
        // UPDATE - It was decided to force light mode for the app (iOS/Android)
        if (!window['cordova']) $('html').attr('id', 'default-branding');
        if (window['cordova']) $('html').addClass('html-mobile-app');
    }

    ngOnInit() {
        this.popDisclaimerForSamasungBrowser();

        this.countriesSubscription = this.countriesService.getCountriesList()
        .subscribe(countriesList => {
            if (countriesList?.length) {
                this.countryCodeData.populateCountries(countriesList);
            }
        });
        // The direction must be observable so it could change dynamically according to translation
        this.directionSubscription = this.appService.directionSubject.subscribe(direction => {
            this.direction = direction;
            $('body').removeClass(['rtl', 'ltr']);
            $('body').addClass(direction || 'rtl').prop('dir', direction || 'rtl');
        });

        this.appService.setAppSidenav(this.appSidenav);
        this.appService.blockUI = this.blockUI;

        this.appService.startBlock({ class: 'white-block' });

        // Setting the title
        this.title = this.appService.appConfig.title;

        this.setWindowEvents();

        // track if OSK is open by watching the viewport size
        this.originalViewportHeight = this.viewportRuler?.getViewportSize()?.height;

        /* Cordova Specific Overrides */
        if (typeof window['cordova'] !== 'undefined') {
            this.cordovaPluginsService.init();
            // We must leave it here because the responsability to render an object is of the component, not the Service
            ////////////////////////////////
            this.handleStatusTap();
            // Android back button
            this.handleAndroidBackButton();
            ////////////////////////////////
        } else {
            console.debug('Cordova does NOT exist');
        }

        // Theme / Skin Override (White Label)
        this.appService.setSkin();
        if (this.appService.skin) this.appService.overrideMetaData();

        this.onLoad(window['cordova'] !== undefined);

        setTimeout(() => {
            // Deeplinking
            console.debug('=== appComponent > init > Deeplinking as part of the Init process');
            this.cordovaPluginsService.deepLinkRedirect();
        }, 0); // This is required in order to make sure that the subscription to the universalLinks plugin 'tabitCordovaWrapperDeepLinkEvent' event takes place BEFORE we trigger the deepLinks handling of ours.

        // Init moment locale here, instead of seperately in each component
        moment.locale(this.appService.appConfig.locale);

        this.notificationsService.initFireBaseMessaging();

        this.isPushNotificationsDisabledSubscription = this.appService.isPushNotificationsDisabled.subscribe(disabled => {
            this.userBlockedNotifications = disabled;
        })

        this.notificationsService.isOpened.subscribe(opened => {
            this.showWidget = opened ? 'open' : 'close';
        });

        // show the focus outline only when using the keyboard, to avoid input fields being highlighted on mouse click
        this.document.addEventListener('keydown', (event) => {
            if (event.key === 'Tab') this.document.body.classList.add('accessibility-focus-visible');
        })
    }

    ngOnDestroy() {
        this.localeSubscription.unsubscribe();
        this.directionSubscription.unsubscribe();
        this.isPushNotificationsDisabledSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
        this.countriesSubscription.unsubscribe();
        if (this.serverTimeSubscription) this.serverTimeSubscription.unsubscribe();
        if (window['universalLinks']) window['universalLinks'].unsubscribe('tabitCordovaWrapperDeepLinkEvent');
    }

    private setWindowEvents() {
        // On Orientation Change
        window.addEventListener('orientationchange', () => {
            this.appService.detectDevice();
            if (this.appService.isDesktop()) {
                this.appService.webAppSmartBannerOpened = false;
            } else {
                this.appService.webAppSmartBannerOpened = this.appService.showAppBanner;
            }
        });

        // On Resize
        this.viewportChange = this.viewportRuler
            .change(100)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.ngZone.run(() => {
                this.appService.detectDevice();
                if (this.appService.isDesktop()) {
                    this.appService.webAppSmartBannerOpened = false;
                } else {
                    this.appService.webAppSmartBannerOpened = this.appService.showAppBanner;

                    // if viewport is equal to original height, show the mobile basket (indicates that OSK is closed)
                    if (!this.appService.isApp) {
                        if (this.originalViewportHeight == this.viewportRuler?.getViewportSize()?.height) {
                            this.document.body.classList.remove('keyboardOpen');
                        }
                    }
                }
            }));

        // hide mobile basket on input focus. we want to listen to those events only on mobile WEB.
        // TODO: Move all this to tabit-order.component.ts
        if (!this.appService.isApp && this.appService.isMobile()) {

            // it appears that Material's mat-form-field occasionally hijacks the input's focus/click events. so we listen to it instead of <input>.
            const inputTypes = 'app-tabit-order mat-form-field.mat-mdc-form-field-type-mat-input, app-tabit-order :not(mat-form-field) input';

            // listen to 'click' instead of 'focus', because the OSK can be toggled without losing focus/blur on inputs
            $('body')
                .on('click', inputTypes, () => {
                    this.appService.inputFocusCallback();
                })

                .on('blur', inputTypes, () => {
                    this.appService.inputBlurCallback();
                });
        }
    }

    private handleRouterEvents() {
        this.router.events.pipe(filter((event) => event instanceof NavigationStart), debounceTime(100)).subscribe(
            (event: NavigationStart) => {

                // Somehow, when you navigate into gift-cards module
                // the NavigationEnd is not occuring
                if (/gift-cards/.test(event.url) ) this.handleDataFromRouter();

                // Handle special navigation events in the app in order to prevent the
                if (this.appService.isApp) {
                    // For Tabit Pay redirect
                    if (/tabit-order/.test(event.url) && /&oid/.test(event.url)) this.handleDataFromRouter();
                    // all TO components are handling the android back event themselves (can't use "handlesAndroidBack" since they have no routes)
                    if (this.appService.platformService.ANDROID && /tabit-order\?site/.test(event.url)) this.childHasToHandleAndroidBack = true;
                }
            })

        this.router.events.pipe(filter((event) => event instanceof NavigationError), debounceTime(100)).subscribe(
            (event: NavigationError) => {
                // Handle error
                console.error(event.error);
            })

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd), debounceTime(100)).subscribe(
            (event: NavigationEnd) => {
                this.handleDataFromRouter();
                // 2020-02-19: Due to a bug in Safari Mobile (iOS) - when moving between views, the Window scroll is not always maintain at the top (and that causes the whole view to have a top offset).
                // To workaround this issue, we force a window-scroll-to-top upon every navigation end.
                // We do this only on the Mobile version.
                // Note: this does not have an impact on the scrolls in the application, because they are all inner-scrolls of speicifc inner DIVs (and not done by the main body).
                if (!window['cordova'] && window.pageYOffset != 0) {
                    window.scrollTo(0, 0);
                }

                // remove all special-messages on route change
                this.specialMessagesService.removeAllMessages();

            })

        // simulate cordova env
        if (window.location.href?.includes('cordova=1')) {
            window['cordova'] = {plugins: {}};
            this.appService.isApp = true;
            this.appService.platformService.ANDROID = true;
        }
    }

    private handleDataFromRouter() {
        // It's here because it's temporary! We must remove it when US would be fully operational
        if (this.allowedPathsWhileUnderConstruction?.length && !this.allowedPathsWhileUnderConstruction.includes(this.activatedRoute.firstChild.snapshot?.url[0]?.path)) {
            this.appService.redirect(['under-construction']);
        }

        let bottomLinksVisible = true;
        let showNoLocationIndicator = false;
        let showNoNotificationsIndicator = false;
        let child = this.activatedRoute.firstChild;

        while (child) {
            // console.log('child.snapshot.data: ', child.snapshot.data);

            if (child?.snapshot?.data['showNoLocationIndicator']) {
                showNoLocationIndicator = true;
            }

            if (child?.snapshot?.data['showNoNotificationsIndicator']) {
                showNoNotificationsIndicator = true;
            }
            this.childHasToHandleAndroidBack = !!get(child.snapshot, 'data.handlesAndroidBack');

            if (child.firstChild) {
                child = child.firstChild;
            } else if (child?.snapshot?.data['hideNavBar']) {
                bottomLinksVisible = false;
                break;
            // Handle RSV Web issue
            } else if (child?.snapshot?.data['hideNavBarOnlyInWeb']) {
                bottomLinksVisible = window['cordova'];
                break;
            } else {
                break;
            }
        }

        this.bottomLinksVisible = bottomLinksVisible;
        this.showNoLocationIndicator = showNoLocationIndicator;
        //  on mobile-web (not cordova), show the menu only once
        this.showNoNotificationsIndicator = showNoNotificationsIndicator;
    }

    private handleStatusTap() {
        this.renderer.listen('window', 'statusTap', e => {
            e.preventDefault();
            e.stopPropagation();

            this.appService.scrollToTop('.module-content');
            return false;
        });
    }

    private handleAndroidBackButton() {
        if (this.appService.platformService.ANDROID) {
            fromEvent(document, 'backbutton')
                .pipe(untilDestroyed(this))
                .subscribe(event => this.androidBackButtonCallback(event));
        }
    }

    androidBackButtonCallback(event) {

        console.log(`androidBackButtonCallback event`, event);

        event.preventDefault();
        event.stopPropagation();

        // if any dialogs are open, the "back" URL event closes them
        if (this.matDialog.openDialogs?.length) {
            this.matDialog.closeAll();
        }

        // check against the route configuration
        if (this.childHasToHandleAndroidBack) {
            this.appService.androidBackButton.emit();
            return false;
        }

        if (this.authService.isAuthenticated) {
            this.appService.goBack();
        } else {
            this.appService.redirect(['/sign-in']);
        }
        return false;
    }

    onLoad(cordova: boolean = false, resume: boolean = false) {
        if (!resume) {
            this.appService.initStorage();
        }

        const authenticated = this.authService.checkToken();

        const beginLoadTime = Date.now();

        this.domainSubscriptionActions();

        // Order here is mandatory, because user data is mutating base data (food categories)
        // and user favorites is required before loading organizations (to load favorites, recents)
        // TODO: Improve to parallelable.
        (
            this.entityService.loadData()
                .then(() => {
                    authenticated && this.appService.isNeedToGetUserInfo() ?
                        this.accountService.getUserInfo(!resume)
                        :
                        this.entityService.initNotAuthenticated()
                })
        ).catch(err => {

            console.error('Error getting main data:', err);
            // if (err?.staus == 403) this.appService.signOut();

        }).then(() => {

            console.debug(
                `After load data by ${resume ? 'Resume' : 'Reload'}. ` +
                `Loading time${authenticated ? ' (including user data!): ' : ': '}` +
                `${((Date.now() - beginLoadTime) / 1000).toFixed(3)}s...`
            );

            // logger
            this.appService.sendLogger({message:`Active User - ${resume ? 'Resumed Session' : 'New Session'}`, durationInSeconds:((Date.now() - beginLoadTime) / 1000)});
        })
    }

    domainSubscriptionActions() {
        this.domainSubscription = this.appService.domain.subscribe(domain => {
            if (domain) {
                this.domain = domain;

                if (this.domain?.domainSettings?.active === false) {
                    this.redirect(['/out-of-use']);
                }

                // Init Google Tag Manager
                this.updateGTM(this.domain);

                // register custom brand service-icons
                if (this.appService.skin && this.domain?.theme?.useBrandCustomIcons) {
                    this.appService.registerBrandIcons();
                } else {
                    // wait for the domain config to load before showing icons
                    this.appService.defaultServiceButtonsLoaded = true;
                }
            }
        });
    }

    scrollToTop(htmlId) {
        return;
        $(htmlId).animate({ scrollTop: "0px" }, 300);
    }

    public getAnimationTag(outlet: RouterOutlet) {
        return outlet?.activatedRouteData['animationTag'];
    }

    public checkActiveRoute(verifyRoute: string, currentUrl: string) {
        // 2020-02-02: We had to switch to this approach, and NOT use routerActiveLink, since we need the redirect to be wrapped with ngZone. So we use the AppService.redirect() function.
        // The ngZone is required because sometimes we redirect to the Payment page (for example), and the QR Code scanner trigger the native camera, so when navigating away from there and back into other views of the App - the ngZone is required.
        //console.log('===', verifyRoute, currentUrl, RegExp(`^\/${verifyRoute}`).test(currentUrl));
        // For example, for 'Delivery' - We respect both /order and /tabit-order
        if (RegExp(`(^\/|-).*${verifyRoute}`).test(currentUrl)) {
            return true;
        } else {
            return false;
        }
    }

    public redirect(route:any) {
        if (this.appService.confirmBeforeRedirect) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                return this.appService.mainMessage({
                    dialogType: 'info',
                    dialogTitle: 'in_progress_message',
                    dialogText: 'confirm_exit',
                    primaryButtonText: 'cancel_order',
                    secondaryButtonText: 'continue'
                }).then(response => {
                    // Once we go out of a "user-flow" (e.g. User Review, Tabit Order) - we don't want the user to be able to click "back" and be redirected back to the start of that flow.
                    // We want the user to go "2 steps" back.
                    // so we use the replaceUrl to achieve that purpose.
                    this.appService.redirect(route, { replaceUrl: true });
                }).catch(err => {});
            });
        } else {
            this.appService.redirect(route);
        }
    }

    public getTop() {
        if (window['cordova']) return;
        if (/tabit-order/.test(location.href)) {
            if (this.appService.isMobile()) {
                if (/step=menu/.test(location.href)) return '104px !important'
                return '63px !important';
            }
            return '3.25rem !important';
        }
        return this.appService.webAppSmartBannerOpened ? '10rem !important' : '5rem !important';
    }

    // Adding Tabit GTM (Google Tag Manager)
    private updateGTM(domain) {
        const googleTagManagerId = get(domain, 'marketing.googleTagManagerId', this.appService.appConfig['googleTagManagerId']);
        this.appService.addSpecificSiteGTM(googleTagManagerId);
    }

    private popDisclaimerForSamasungBrowser() {
        // in dark mode, "Samsung Browser" applies its own color filters AFTER the CSS rendering stage, thus overriding all other CSS rules (see https://tabitros.atlassian.net/browse/TAB-21985?focusedCommentId=46920)
        if (navigator.userAgent.match(/samsung/i)) {
            // advice Samsung Browser users to switch browser
            this.ngZone.run(() => {
                return this.appService.mainMessage({
                    dialogType: 'error',
                    dialogTitle: 'notifications.unsupported_browser_title',
                    dialogText: 'notifications.unsupported_browser',
                    hideXIcon: true,
                    hideSecondaryButton: true,
                    primaryButtonText: 'notifications.unsupported_browser_confirm',
                });
            });
        }
    }

    public applyContrast() {
        const that = this;
        this.contrastFeature.active = !this.contrastFeature.active ? true : false;
        if (this.contrastFeature.active) {
            // Remove branding script if needed
            if (!window['cordova']) {
                if (!this.brandingScript) this.brandingScript = document.getElementById(this.brandingService.brandingScriptId);
                addAccessibilityScript();
                this.brandingService.removeBrandingScript();
            }
            $("body").addClass(this.contrastFeature.class)
        } else {
            if (this.brandingScript) document.head.appendChild(this.brandingScript);
            removeAccessibilityScript();
            $("body").removeClass(this.contrastFeature.class);
        }

        function addAccessibilityScript() {
            const style = document.createElement('style');
            style.id = that.appService.accessibilityScriptId;
            style.innerHTML = `
            web-selection-bar .vertical .mat-mdc-button.action-button {background: var(--primary-theme-500) !important;}
            .tiles-container-wrapper .tiles-container-content site-card .card .card-body .card-categories {color: var(--theme-600) !important;}
            .card > .card-footer .card-address {color: var(--theme-600) !important;}
            customer-details .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {color: var(--theme-1000) !important;}
            customer-details .form-timer {color: var(--theme-1000) !important;}
            `;

            document.head.appendChild(style);
        }

        function removeAccessibilityScript() {
            const script = document.getElementById(that.appService.accessibilityScriptId);
            if (script?.parentNode) script.parentNode.removeChild(script);
            that.brandingService.applyBrandAfterContrastRemoval();
        }
    }

    get showContrastButton() {
        return !window['cordova'] && !['en-US', 'en-AU'].includes(this.appService.appConfig.locale);
    }

}
