import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { AppService } from '../../app.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatLine } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { NgFor, NgIf } from '@angular/common';
import { MatNavList, MatListItem } from '@angular/material/list';

@Component({
    selector: 'app-bottom-sheet-dialog',
    templateUrl: './bottom-sheet-dialog.component.html',
    styleUrls: ['./bottom-sheet-dialog.component.scss'],
    standalone: true,
    imports: [MatNavList, NgFor, NgIf, MatDivider, MatListItem, MatIcon, MatLine, TranslateModule]
})
export class BottomSheetDialogComponent implements OnInit {

	constructor(
		public appService: AppService,
		private bottomSheetRef: MatBottomSheetRef<BottomSheetDialogComponent>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
	) {
		
	}

	openLink(act, event: MouseEvent ): void {
		this.bottomSheetRef.dismiss(act);
		event.preventDefault();
	}

	ngOnInit() {
	}

}
