export const orderingStrings_enUS = {
    "TO-ADD": "Add",
    "TO-UPDATE": "Update",
    "JUST-CHECKING": "Just checking",
    "ADD": "Add",
    "UPDATE": "Update",
    "ORDER-TOTAL": "Order total",
    "CASH": "Cash",
    "PAYUP": "Go to payment",
    "THANK_YOU": "Thank you",
    "AMOUNT_TO_PAY": "Amount to pay",
    //     "AMOUNT_TO_PAY": "Pay",
    "TIP": "Tip",
    "PAYED": "Paid",
    "CONTINUE": "Continue",
    "TOTAL_IN_TIP": "You pay",
    "PARTIAL_PAY_IN_TIP": "Partial payment",
    "CLEAR": "Clear",
    "CLEAR_SELECTION": "Clear selection",
    "APPROVE": "Approve",
    "PAY": "Pay",
    "CANCEL": "Cancel",
    "NO_THANKS":"No, thanks",
    "REMAINED_PAY": "Balance",
    "TABLE_BILL": "Table",
    "WAITER": "Server",
    "TO_PAY": "Proceed to payment",
    "SAVE_CARD_INFO": "Save card details",
    "TOTAL": "Total",
    "CARD_HOLDER_NAME": "Card holder name",
    "CARD_NUMBER": "Card number",
    "SIGNIN": "Sign in",
    "SIGNIN_INFO": "Enter your details",
    "VALIDATING_CREDENTIALS": "Validating...",
    "WELCOME_BACK": "Welcome back",
    "BACK_TO_MAIN_SCREEN": "Back to main menu​",
    "SELECT_EXISTING_CARD": "Select card",
    "ADD_NEW_CARD": "Save card for future use",
    "SAVE_CARD_AS_DEFAULT": "Save as default card",
    "ORDER_TOTAL": "Order total",
    "ORDER_TIP": "Tip",
    "PAID": "Paid",
    "PERFORMING_PAYMENT": "Performing payment...",
    "FINALIZING_ORDER": "We're working on it...\nPlease don't close the page",
    "ENTER_ORDER_N": "Enter order code",
    "REQUIERED": "Required",
    "REQUIERED_CHECK": "Required",
    "PASSWORDS_DONT_MATCH": "Passwords do not match",
    "PASSWORDS_MIN_LENGTH": "Password must have at least 6 characters",
    "INVALID_CREDIT_NUMBER": "Invalid card number",
    "INVALID_CVV": "Invalid CVV",
    "INVALID_CARD_EXPIRATION": "Invalid expiration date",
    "INVALID_EMAIL": "Invalid email",
    "EMAIL_FOR_INVOCE": "Email for invoice",
    "CARD_EXPIRATION": "Expiration date",
    "CARD_HOLDER_CELL": "Card holder phone number",
    "CARD_HOLDER_MAIL": "Card holder email",
    "Q_SHARING_BILL": "For partial payment",
    "Q_TIP_AMOUNT": "Enter tip amount",
    "Q_HOW_MUCH_PAY": "Set amount?",
    "Q_HOW_MUCH_SPLIT": "Split between",
    "Q_REGISTARED": "Already registered?",
    "Q_NEW_REGISTARED": "New user? Register...",
    "Q_INVOICE_BY_MAIL": "Receive receipt by email?",
    "Q_REMOVE_CARD": "Delete this card?",
    "Q_REMOVE_PAYMENT": "Delete this payment?",
    "SIGNIN_REGISTER": "Sign in / Register",
    "END": "Finish",
    "ENTER_TIP": "Tip",
    "ENTER_AMOUNT": "Custom amount",
    "SPLIT_AMOUNT": "Split",
    "YOUR_AMOUNT_TO_PAY": "You pay",
    "PRESS_HERE": "Tap here",
    "OTHER": "Other",
    "SENDING_INVOICE": "Sending invoice...",
    "CHALLENGE_CODE": "Enter code",
    "ENTER_CHALLENGE_CODE": "Enter confirmation code",
    "REMEMBER_ME_ON_PHONE": "Remember me on this device",
    "BILL_AMOUNT": "Check",
    "PAY_WITH_ONOTHER_CARD": "Pay with another card",
    "PAYMENT_METHOD": "Payment Method",
    "SELECT_PAYMENT_METHOD": "Choose Payment Method",
    "CREDIT_CARD": "Credit Card",
    "CREDIT": "Credit",
    "creditCard": "Credit",
    "CIBUS": "Cibus",
    "Cibus": "Cibus",
    "TENBIS": "10bis",
    "10bis": "10bis",
    "Credit": "Credit card",
    "Ten Bis": "10bis",
    "cash": "Cash",
    "EDIT_ACCOUNT_SETTINGS": "Edit account settings...",
    "MY_ACCOUNT": "My Account",
    "SIGN_OUT": "Sign Out",
    "MY_ACCOUNT_DETAILS": "My account",
    "EDIT_PAYMENT_INFO": "Edit payment options",
    "DELETE_CARD": "Delete card",
    "SET_DEFAULT_CARD": "Set as default",
    "YES_DELETE": "Yes, delete it",
    "OFFER_REMARKS": "Item Remarks",
    "ENTER_OFFER_REMARKS": "Add notes",
    "DELETE_PAYMENT": "Delete Payment",
    "CONFIRM_CELL": "Confirm Phone Number",
    "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your cellphone",
    "USE_ENTERED_PHONE": "Send to:",
    "ENTER_ALTERNATE_PHONE": "Use other:",
    "EMAIL_NOT_FOUND": "Email not found",
    "GRATUITY": "Gratuity",
    "WITHOUT_GRATUITY": "Without Gratuity",
    "ORDER_SCHEDULING": "Pickup Time",
    "ORDER_SCHEDULING_TA": "Pickup Time",
    "ORDER_SCHEDULING_DELIVERY": "Delivery Time",
    "THROTTLING_DISCLAIMER": "The earliest available times for this order are",
    "ORDER_SCHEDULING_SCREEN_ANNOUNCEMENT": "A pop up for selecting delivery or take away time has opened",
    "SELECT_SERVICE_TYPE": "Service Type",
    "CLOSE": "Close",
    "YES": "Yes",
    "NO": "No",
    "REMOVE": "Remove",
    "SEND_ORDER": "Send Order",
    "TRACK_YOUR_ORDER": "Track Your Order",
    "CONTINUE_ORDERING": "Continue Ordering",
    "ADD_ITEM": "Add Item",
    "SELECT_ITEM": "Select Item",
    "UPDATE_ITEM": "Update Item",
    "TOPPINGS": "Sides",
    "DECISIONS": "Decisions",
    "ITEM_CHANGES": "Item Changes",
    "SELECTION_GROUP_SELECT": "Select {{count}} items",
    "SELECTION_GROUP_SELECT_SINGLE": "Select {{count}} item",
    "SELECTION_GROUP_SELECT_MIN": "(Select at least {{count}} items)",
    "SELECTION_GROUP_SELECT_SINGLE_MIN": "(Select at least 1 item)",
    "GROUP_MOD_SELECT_ERROR": "Select at least {{count}} sides from: {{group}}",
    "GROUP_MOD_SELECT_SINGLE_ERROR": "Please make a selection from the list : {{group}}",
    "GROUP_SELECT_ERROR": "Select at least {{count}} items from: {{group}}",
    "GROUP_SELECT_SINGLE_ERROR": "Select at least one item from: {{group}}",
    "SELECTION_GROUP_OPTIONAL_MAX": "Optional, select up to {{count}} items",
    "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "Optional, select up to one item",
    "SELECTION_GROUP_DIALOG_ERROR": "An error occurred while loading the item\nplease try again or contact the restaurant.",
    "CHECKOUT": "Go to Checkout",
    "Q_REMOVE_OFFER_FROM_BASKET": "Remove from cart?",
    "ENTER_DELIVERY_ADDRESS": "Enter delivery address",
    "ENTER_DELIVERY_ADDRESS_ALT": "Service availability",
    "SELECT_ADDRESS": "Select address",
    "DELETE_ADDRESS_VALIDATE": "Remove address?",
    "BRANCH": "Location",
    "ADDRESS": "Address",
    "PHONE": "Phone",
    "ENTRANCE": "Entrance",
    "FLOOR": "Floor",
    "apartment": "Apartment",
    "ENTER_STREET_INCLUDING_NUMBER": "Enter full address",
    "MISSING_STREET_NUMBER": "Missing house number",
    "START_ORDER": "Proceed to order",
    "SELECT_CITY": "Select city",
    "SELECT_DELIVERY_CITY": "Select city for delivery",
    "SELECT_BRANCH": "Select location",
    "ORDER_MENU": "Menu",
    "takeaway": "Takeaway",
    "eatin": "Eat In",
    "delivery": "Delivery",
    "seated": "Seated",
    "RESET_ORDER": "Restart order",
    "ORDER_FROM": "Order from location",
    "ORDER_TO": "Order to address",
    "CONTACT_INFO": "Contact Info",
    "CONTACT_ALCOHOL_DECLARATION":"I am {{age}}+ years of age and will provide identification to verify alcohol purchase",
    "PAYMENT_INFO": "Payment Info",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "CELL": "Phone number",
    "EMAIL": "Email",
    "REVIEW_YOUR_ORDER": "Order Details",
    "SOME_OF_MULTIPLE_PAYMENTS_FAILURE": "One or more payments failed",
    "SOME_OF_MULTIPLE_PAYMENTS_FAILURE_CALL_REST": "Please call the restaurant {{phone}}",
    "EXTERNAL_DELIVERY_FAILURE": "Your order has been sent successfully.",
    "EXTERNAL_DELIVERY_FAILURE_CALL_REST": "Please contact the restaurant to coordinate delivery {{phone}}",
    "ADD_ITEMS": "Add Items",
    "UPDATE_ITEMS": "Back to Order",
    "ORDER_SENT_SUCCESS": "Order sent successfully",
    "ORDER_NEED_ATTENDANCE": "Order sent with errors",
    "CALL_RESTAURANT_TO_COMPLETE_ORDER": "Please call the restaurant to complete the order",
    "ORDER_NUMBER": "Order Number",
    "START_NEW_ORDER": "Start New Order",
    "ORDER_ITEMS": "Order Details",
    "ORDER_WILL_BE_SENT_TO": "Order will be sent to",
    "ESTIMATED_DELIVERY_TIME": "Estimated delivery time",
    "ESTIMATED_PREPARATION_TIME": "Estimated preparation time",
    "ESTIMATED_SUPPLY_TIME": "Estimated pickup time",
    "MINUTES": "minutes",
    "MINUTES_SHORT": "min.",
    "PREPARATION_TIME": "Preparation time",
    "ORDER_TYPE": "Order Type",
    "PLEASE_SELECT": "Please select",
    "PLACEMENT": "Placement",
    "NUMBER_OF_PEOPLE": "Number of diners",
    "READ_AND_APPROVE_CONTRACT": "I have read and approved the", "READ_AND_APPROVE_CONTRACT_C": "Terms and Conditions",
    "READ_AND_APPROVE_CONTRACT_D": "Privacy Notice",
    "READ_CONTRACT": "Terms and Conditions",
    "PLEASE_APPROVE_CONTRACT": "Please approve terms and conditions",
    "USAGE_CONRACT": "Terms and Conditions",
    "PRIVACY_CONRACT": "Privacy Notice",
    "ADDRESS_REMARKS": "Delivery Notes",
    "LOADING_MENU": "Loading Menu...",
    "MOD_WITH": "Include",
    "MOD_WITHOUT": "Without",
    "PASSWORD": "Password",
    "CONFIRM_PASSWORD": "Confirm Password",
    "FORGOT_PASSWORD": "Forgot Password...",
    "RESTORE_PASSWORD": "Restore Password",
    "INCLUDE_SILVERWARE": "Include Silverware",
    "CURBSIDE_PICKUP": "Curbside pickup",
    "CURBSIDE_PICKUP_DESC": "Please provide car make, model, and color",
    "AUTHENTICATING": "Authenticating",
    "SIGNED_IN_AS": "Signed in as",
    "PAY_WITH": "Pay with",
    "PAY_WITH_SAVED_PMS": "Saved payment methods",
    "SELECT_ALTERNATE_PYMENT": "Select another payment method",
    "SIGHN_IN_EMAIL": "Sign-in Email ",
    "SIGHN_IN_PASSWORD": "Sign-in password",
    "UPDATE_PAYMENT_INFO": "Update payment method",
    "SAVE_ACCOUNT_PAYMENT_INFO": "Save your card in our digital wallet for quicker payments on upcoming orders",
    "UPDATE_ACCOUNT": "Update account",
    "ADD_PAYMENT_INFO": "Add payment method",
    "UPDATING_ACCOUNT": "Updating your account...",
    "UPDATE_SIGNIN_INFO": "Update sign-in info",
    "PASS_MIN_LENGTH_ERROR": "Password must have at least 6 characters",
    "OLD_PASSWORD": "Old Password",
    "NEW_PASSWORD": "New Password",
    "BRANCH_TIME_0": "Service is available between {{t0}}",
    "BRANCH_TIME_1": "Service is available between {{t0}} and {{t1}}",
    "BRANCH_TIME_CONFIRM_0":"The restaurant is currently closed. This service is available between {{t0}}",
    "BRANCH_TIME_CONFIRM_1":"The restaurant is currently closed. This service is available between {{t0}} - {{t1}}",
    "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED":"This service has closed. It's available between {{t0}}",
    "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED":"This service has closed. It's available between {{t0}} - {{t1}}",
    "CONFIRM": "Confirm",
    "ORDER_SUCCESS": "Order Success",
    "MIN-ORDER-PRICE": "Min. Order Total",
    "DELIVERY-PRICE": "Delivery Fee",
    "TOTAL-ITEMS": "Item Total",
    "TOTAL-TO-PAY": "Total",
    "WITHOUT": "No",
    "CONTINUE_TO_PAYMENTINFO": "Go to Payment",
    "PAYMENT": "Payment",
    "BRANCHES": "Locations",
    "SERVER_ERROR": "Server Error",
    "PAYMENT_ERROR": "Payment Error",
    "ERROR": "Error",
    "PARTIAL_PAYMENT_ERROR": "Payment Error",
    "NAME": "Name",
    "REGISTERING": "Registering",
    "REGISTER": "Register",
    "RESET_PASSWORD": "Reset Password",
    "RESET_PASSWORD_INFO": "Enter new password and select reset",
    "ORDER_HISTORY": "Order History",
    "OFFERS_HISTORY": "Order again",
    "SELECT_ORDER_METHOD": "Select order method",
    "ORDER_METHOD": "Order Method",
    "ORDER_FIXED": "Order",
    "SET_QUANTITY": "Enter Quantity",
    "CELL_PATTERN": "Cellphone number must start with 05",
    "CELL_MINLENGTH": "Cellphone number must have 10 characters",
    "VIEW_MENU": "View Menu",
    "ORDER_SERVICE_CLOSED": "Order Service Is Closed",
    "ESTIMATED_TIME": "Estimated time",
    "SET_ORDER_DELAY": "Order supply time",
    "DELAY_TO": "Order time",
    "SET_ORDER_DELAY_TO_HOUR": "Set Order Delay",
    //     "SET_ORDER_DELAY_TO_HOUR": "Delay Order",
    "ASAP": "ASAP",
    "ENTER_CVV": "Enter CVV",
    "ENTER_CVV_MESSAGE": "Enter CVV for Card: {{card}}",
    "ENTER_IDCARD": "Enter ID Number",
    "ENTER_IDCARD_MESSAGE": "Enter ID Number for Card: {{card}}",
    "PRMARY_PAYMENT_METHOD": "Default",
    "ADD_PAYMENT": "Add Payment",
    "SPLIT_PAYMENT": "Split Payments",
    "WALLET_PAYMENT": "Wallet, {{type}}",
    "SPLIT_PAYMENTS_DIFF_ERROR": "Payments don't add up to {{total}}, there is a difference of{{diff}}",
    "BACK_TO_SITE": "Back to Site",
    "BACK_TO_APP": "Back to App",
    "FREE_DELIVERY_FROM": "Free delivery from",
    "ADD_REMAINNING_AMOUNT": "Add remaining amount",
    "PAYMENTS_EXCEED_TOTAL_BY": "Payments exceed total by",
    "PAYMENT_AMOUNT": "Payment Amount",
    "ENTER_FIRST_PAYMENT_AMOUNT": "Enter first payment amount",
    "EXCESS_PAYMENT": "Excess Payment",
    "CLICK_TO_REMOVE_EXCESS_AMOUNT": "Select to remove excess payment",
    "CANCEL_SPLIT_PAYMENT": "Cancel split payment",
    "APPLY_PAYMENTS": "Approve Payments",
    "SINGLE_PAYER": "Single Payer",
    "MULTIPLE_PAYERS": "Multiple Payers",
    "SPLIT_ORDER_BETWEEN_PAYERS": "Split between payers",
    "CALL_RESTAURANT": "Call Restaurant",
    "SELECT_TABLE": "Please enter your table number",
    "TABLE_NO": "Table Number",
    "TABLE_NO_ORDER": "Table number {{no}}",
    "ENTER_ORDER_NO": "Enter Order Number",
    "ADD_GRATUITY_TO_ORDER": "Add Tip",
    "ADD_GRATUITY_TO_COURIER": "Add Tip",
    "ORDER_TOTAL_WITHOUT_GRATUITY": "Total Without Tip",
    "ORDER_TOTAL_WITH_GRATUITY": "Total Including Tip: {{total}}",
    "GRATUITY_AMOUNT": "Tip Amount",
    "GRATUITY_PERCENT": "Tip Percent",
    "ENTER_GRATUITY_AMOUNT": "Enter tip amount",
    "CONTINUE_TO_GRATUITY": "Continue to Tip",
    "REFRESH_ORDER": "Refresh Order",
    "MONTH": "Month",
    "YEAR": "Year",
    "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "Enter amount to pay not including tip",
    "ENTER_AMOUNT_TO_PAY": "Enter amount to pay",
    "FULL_PAYMENT": "Full payment",
    "PARTIAL_PAYMENT": "Partial Payment",
    "AMOUNT_EXEEDS_BALANCE_DUE": "Amount exceeds balance due",
    "MAX_TO_USE":"You have {{amount}} in {{type}} available",
    "EXCEEDED_CASH_AMOUNT":"Cash payment is limited to NIS {{amount}}.",
    "POINTS": "Points",
    "CANNOT_PAY_TIP":"Cannot pay tip with this payment type",
    "MY_WALLET":"My wallet:",
    "OTHER_TENDERTYPES":"Other",
    "NEW_PAYMENT":"New payment",
    "REMAINDER_NOT_EXISTS": "REMAINDER_NOT_EXISTS",
    "BACK": "Back",
    "PASS_ORDER": "Sharing the bill ?",
    "PASS_ORDER_TITLE": "Share with a friend",
    "PASS_ORDER_BY_PHONE_MESSAGE": "Please enter phone number to receive link by SMS text",
    "PASS_ORDER_BY_PHONE_CAPTION": "Cellphone number",
    "PASS_ORDER_BY_PHONE_SEND": "Send order",
    "PASS_ORDER_BY_PHONE_QR_MESSAGE": "OR scan the QR code",
    "PASS_ORDER_SENT_SUCCESS": "Order sent successfully",
    "CANNOT_SEND_ORDER_BY_SMS": "The order cannot be sent at the moment",
    "WORK_HOURS": "Business Hours",
    "RESTAURANT_WEBSITE": "Restaurant Website",
    "SELECT_ONE": "Select one",
    "DELIVERY_ADDRESS": "Delivery Address",
    "DELIVERY_DETAILS": "Delivery Info",
    "ADDRESS_DETAILS": "Address Info",
    "BRANCH_DETAILS": "Location Details",
    "ADDITIONAL_OPTIONS": "Additional Options",
    "MINUTES_VAL": "{{m}} minutes",
    "ADDITIONAL_OFFERS": "Additional Items",
    "SELECT_ADDITIONAL_OFFERS": "Select additional items",
    "CONTINUE_TO_CONTACTINFO": "Continue to contact info",
    "CC_CUSTOMER_NAME": "Name",
    "CC_CUSTOMER_ID": "ID / BN Number",
    "ID_CARD": "ID Number",
    "ORDERER_NAME": "Customer Name",
    "ORDERER_PHONE": "Phone",
    "ORDER_NO": "Order Number",
    "ORDER_TAGS": {
        "BREAKFAST": "Breakfast",
        "LUNCH": "Lunch",
        "DINNER": "Dinner",
        "WORK": "Business"
    },
    "ENTER_LOCALITY": "Enter District",
    "LOCALITY_TITLE": "District",
    "STREET_TITLE": "Street",
    "HOUSE_TITLE": "House Number",
    "TITLE": "{{name}} - TabitOrder",
    "TITLE_1": "{{name}} TabitPay",
    "TITLE_2": "{{name}} - TabitOrder",
    "PAYMENT_IN_PROGRESS": "Loading...",
    "CREATING_ORDER": "Loading...",
    "LOADING_ORDER": "Loading Order...",
    "INVALID_AMOUNT": "Invalid Amount",
    "INVALID_AMOUNT_RANGE": "Valid amount between {{min}} to {{max}}",
    "INVALID_AMOUNT_MIN": "Invalid amount",
    "INVALID_AMOUNT_MAX": "Amount cannot exceed {{max}}",
    "BACK_TO_MENU": "Back to menu",
    "ORDER_NOTES": "Order Notes",
    "Q_SAVEPM_1": "Interested in a faster order process?",
    "Q_SAVEPM_2": "You can save your payment method in Tabit's digital wallet for quicker and faster payments",
    "Q_SAVEPM_3": "Pay and Save",
    "Q_SAVEPM_4": "Pay without saving",
    "DISH_CALCULATOR": "Dish Calculator",
    "MAKE_ADDITIONAL_PAYMENT": "Make additional payment",
    "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Make additional payment on this device",
    "DINER": "Diner",
    "GENERAL_DINER": "Center of Table",
    "ADDRESS_WITHOUT_HOUSE": "Address without house number",
    "TAX_AMOUNT": "Tax",
    "ADDRESS_TYPE": "Address Type",
    "VALID_UNTILL": "Valid Until",
    "COMMENT": "Comment",
    "LEAVE_ORDER_OUTSIDE": "Leave delivery at door",
    "LEAVE_ORDER_OUTSIDE_CASH": "Lets stay safe 😊 we accept digital payments only",
    "LEAVE_ORDER_OUTSIDE_COMMENT": "Leave delivery at door",
    "arr_join": " and ",
    "SIGN_OUT_MESSAGE": {
        "TITLE": "Before you go",
        "TEXT": "Please confirm that you want to sign out",
        "PRIMARY_BUTTON": "Yes",
        "SECONDARY_BUTTON": "Cancel"
    },
    "MESSAGES": {
        "PLACE-MAP-MARKER": "Please place the cursor as close as possible to your location so we can find you faster",
        "MAX_ORDER_OFFERS_CONT": "Can't order more than {{max}} offers per order",
        "MAX_CAT_OFFERS_CONT": "Can't order more than {{max}} offers of {{target}}",
        "MAX_SAME_DISH_COUNT": "Can't order more than {{max}} offers of {{target}}",
        "SIGNIN-EMAIL-EX": "Enter the email you registered with",
        "SIGNIN-EMAIL-EX-HELP": "Confirmation code will be sent to the phone associated with this email",
        "SIGNIN-CHALLENGE-EX": "Confirmation code sent",
        "SIGNIN-CHALLENGE-EX-HELP": "Didn't get the code? <b>Resend</b>",
        "SAVE_INFO": "Will be used for identification in future payments",
        "SAVE_INFO_END": "Register for quick and easy ordering",
        "SAVE_INFO_PAYMENT": "Save Payment Info",
        "SIGNIN_CHALLENGE": "The confirmation code has been sent to you via SMS. Please enter the code and click on ‘Approve’",
        "PAYMENT_CHALLENGE": "A confirmation code has been sent to \n{{phone}}\n by SMS text.",
        "PAYMENT_ENTER_PHONE": "Let's verify your phone number and we're good to go",
        "PAYMENT_OTP": "We have sent a verification code to {{phone}}",
        // "ROOM_CHARGE_CHALLENGE": "Confirmation code was sent to you by SMS text. Please enter it and select approve",
        "ROOM_CHARGE_CHALLENGE": "A confirmation code has been sent to a phone number ending with {{number}}",
        "TRANSACTION_SUCCESS": "Payment Authorized",
        "ORDER_CLOSED": "Paid",
        "INVALID_ORDER": "Order not found",
        "PAYMENT_FAILED": "Payment failed",
        "INVALID_PIN": "ID code did not match",
        "SIGN_FOR_PAYMENT_CONFIRM": "Please sign here",
        "SIGNATURE_MANDATORY": "Signature is mandatory for credit card confirmation",
        "WAITER_NOTIFIED": "Our staff has been notified. A server will be with you shortly",
        "HAPPY": "Hope to see you soon",
        "INVOICE_SENT": "Invoice sent",
        "ITEM_ADDED_TO_BASKET": "Item added to basket",
        "NO_BASKET_ITEMS": "No items in basket",
        "CONFIRM_ORDER_FROM_BRANCH": "You chose to order from: {{branch}}",
        "FINDING_THE_BRANCH_NEAREST_TO_YOU": "Searching for nearest location",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "We're sorry, no restaurant found serving this address: {{address}}",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "Deliveries to this address are currently available for future orders only",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "Delivery to this address for future orders is currently unavailable",
        "NO_BRANCH_SERVING_ADDRESS": "The restaurant does not deliver to this address",
        "CONFIRM_RESET_ORDER": "Restart the order?",
        "DO_YOU_APPROVE_CONTRACT": "Do you accept the Terms and Conditions?",
        "DO_YOU_APPROVE_CONTRACT_TITLE": "Before we go on",
        "CONDITIONS_PRIMARY_BUTTON_TEXT":"Yes",
        "CONDITIONS_SECONDARY_BUTTON_TEXT":"Exit",
        "RESTORE_PASSWORD_1": "Please enter the email you used for registration.",
        "SIGNIN_SUCCESS": "Welcome back {{name}}.",
        "FORGOTPASS_SUCCESS": "Sign in instructions sent to your enail",
        "ACCOUNT_UPDATE_SUCCESS": "Your account was successfully updated!",
        "TITLE_HUNGRY" : "We know you’re hungry",
        "BRANCH_DISABLED_NOW": "Service is closed right now. {{t}}.",
        "BRANCH_DISABLED_NOW_ERROR": "This service has closed. It's available between {{t}}.",
        "BRANCH_DISABLED_NOW_DELAYED_ENABLED": "The selected service is currently unavailable. Orders for future date are available.",
        "BRANCH_CLOSED_TODAY": "The location is closed today",
        "BRANCH_CLOSED_NOW": "The selected service is currently unavailable and will resume at {{t}}.\nYou can submit your order and it will be processed when the service resumes",
        "MIN-ORDER-PRICE": "Minimum order price is {{t}}. Please add items to the order",
        "SERVER_ERROR": "We're sorry, this service is temporarily unavailable. Please contact the restaurant to continue with your order",
        "PAYMENT_ERROR": "We were unable to process the payment, please make sure all the details are correct.",
        "PAYMENT_ERROR_TITLE": "Payment Error",
        "ERROR": "Error",
        "REGISTER_SUCCESS": "Registered Successfully",
        "PASSWORD_RESET_SUCCESS": "Password was reset successfully",
        "CUSTOMER_ENTITYALREADYEXISTS": "Customer with the same email already exists",
        "PHONE_VERIFICATION_FAILED": "The code appears to be incorrect or expired. Please try again.",
        "PHONE_VERIFICATION_FAILED_TITLE": "Hmm...",
        "TOO_MANY_CODE_REQUESTS": "Your account has been temporarily blocked due to too many failed attempts.\nPlease try again later.",
        "VOUCHER_ALREADY_USED": "Looks like the coupon has already been redeemed",
        "SERVICE_UNAVAILABLE": "The service is currently unavailable",
        "SERVICE_UNAVAILABLE_THROTTLING": "We are currently at full capacity. Please restart your order with another time slot.",
        "SERVICE_UNAVAILABLE_THROTTLING_WITH_PHONE": "We are currently at full capacity.\nPlease call the restaurant ({{phone}})",
        "IG_CANT_SELECT_MORE_THEN": "Please select up to {{max}} items",
        "MOD_CANT_SELECT_MORE_THEN": "Please select no more than {{max}} sides",
        "MOD_PLEASE_SELECT_AT_LEAST": "Please select at least {{min}} sides",
        "MOD_PLEASE_SELECT_AT_LEAST_ONE": "Please select at least one side",
        "CANNOT_PAY_TRAINING_MODE": "System in Training Mode. Cannot close transaction. Please contact the restaurant for more details",
        "ARE_YOU_SURE": "Are you sure?",
        "DISABLED_REGION_TITLE": "Delivery service unavailable",
        "DISABLED_REGION_MESSAGE": "We currently do not deliver to this address.\nPlease try again later",
        "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "Deliveries to this address for future orders is currently unavailable ",
        "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "Deliveries to this address are currently available for future orders only",
        "ENABLED_REGION_TIME_MESSAGE": "Deliveries will resume between {{t}}",
        "ENABLED_REGION_DAY_MESSAGE": "Deliveries will resume {{d}}, between {{t}}",
        "OUT_OF_REACH": "You’re out of reach",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "One or more payments failed. Please call the restaurant at {{phone}} to complete your order",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "One or more payments failed. Please ask your server for help",
        "ORDER_CHANGED_AFTER_HANDOFF": "Attention!\nOrder changed after handoff",
        "SELECTTABLE_INFO": "Please enter the table number you wish to order to",
        "ENTERORDER_INFO": "Please enter the order number you wish to pay for",
        "INVALID_TABLE": "Invalid Table Number",
        "ORDER_RELOAD_NEEDED": "Be advised. Order total to pay changed!",
        "ORDER_HELD_BY_SERVER": "Payment is not possible at the moment. Please try again in few minutes",
        "SESSION_VALIDATION_FAILED": "There was an error processing the request, please refresh the page and try again",
        "PAY_ORDER_CHANGED": "The order has changed. Please review your order",
        "PAYMENT_ACCEPTED_SUCCESSFULLY": "Payment accepted",
        "PAYMENT_ACCEPTED": "Payment accepted",
        "ITEM_OUTOF_STOCK": "This item is currently out of stock",
        "ITEM_OUTOF_STOCK_TITLE": "Sorry",
        "ITEM_OUTOF_STOCK_BUTTON": "Menu",
        "OUT_OF_STOCK": "The following items are no longer in stock:\n {{items}}\n Please update your order",
        "GRATUITY_CASH_DISABLE": "Cannot use cash when tip added",
        "ENABLE_GRATUITY_CREDIT_ONLY": "Can only pay with credit when tip added",
        "ORDER_ITEMS_UNAVALABLE": "The following items are currently unavailable:",
        "ORDER_TIMEOUT": "Your order has timed out, but you can easily start again",
        "CONFIRM_ADDRESS": "The address you have entered is:\n{{address}}\nIs this the correct address?",
        "CONFIRM_ADDRESS_SITE": "You're ordering from {{a}}\nto: {{b}}",
        "PROBLEM_IN_PAYMENT_INPUT": "There was a problem processing the payment information\nPlease enter the information again\nIf this problem persists, please contact the restaurant:\n {{phone}}",
        "MEAL_CALC_FACTOR_DESC": "An dish's amount is calculated relative to the total order price.",
        "MEAL_CALC_ACTION_DESC": "Select to add a dish",
        "MEAL_CALC_TOTAL_DESC": "Total dishes can't be larger then the balance due.",
        "EX_PAYMENT_ERROR": "Error redirecting to external payment provider",
        "EX_PAYMENT_AUTHRIZATION_ERROR": "Error authorizing external payment",
        "LOCALITY_WO_STREETS": "Location without streets", 
        "SERVICE_END_WARN": "This service is available for the next {{t}} minutes. Make sure to complete your order in time",
        "JUST_SO_YOU_KNOW": "Just so you know",
        "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT":"OK",
        "SUMMARY_SENT_BY_MAIL": "Order summary has been sent to your email",
        "CONTINUE_WITHOUT_GRATUITY": "Continue without gratuity",
        "LOYALTY_BENEFIT_TIMEOUT_ERROR": "It’s been a while…\nwe will need to recalculate your benefits",
        "LOYALTY_BENEFIT_HANDOFF_ERROR": "Card ending in {{num}} cannot be used in this transaction.\n For more information, please contact the restaurant.",
        "LOYALTY_BENEFIT_CONFLICT_ERROR": "Error executing promotion. Please try again",
        "CARD_VALIDATION_MISMATCH": "Card validation mismatch",
        "CARD_VALIDATION_ACTION_NEEDED": "Needed additional action",
        "ORDER_ITEMS_VALIDATION_ERROR": "Certain items you've selected cannot be ordered.\nPlease contact the restaurant to continue with your order.",
        "SIGNIN_SISABLED": "Sign in/Registration is temporarily disabled. Enter your contact information to continue with your order",
        "GRATUITY_NOT_ALLOWED_FOR_PM": "This payment method isn't available when adding gratuity"
    },
    "EX_PAYMENT": {
        "dialog_title": "Payment Verification",
        "description": "This page is used to verify the payment method used on the site. Actual payment will take place on the business' site once the verification process is complete. Please verify that all of the details on this page are correct and fill out the required credit card details",
        "popup-title": "Payment Verification Complete",
        "form-title": "Credit Card Details",
        "popup-description": "Complete the transaction on the business' site",
        "submit-btn": "Continue",
        "clear": "Clear",
        "credit_card_number": "Credit Card Number",
        "expiration":"Expiration",
        "cancel":"Cancel",
        "currency":"Currency:",
        "transaction_details":"Transaction Details",
        "total_amount":"Total Amount",
        "month":"Month",
        "year":"Year",
        "click_here":"OK"
        },
    "SERVER_MESSAGES": {
        "OrderLockedByAnotherUser": "Payment failed. Check is handled by another user",
        "INVALID_USER_ORPASS": "User name or password are incorrect",
        "UPDATE_ACCOUNT_ERROR": "We couldn't update your account",
        "EMAIL_NOT_FOUND": "Email not found",
        "EMAIL_IN_USE": "Email already exists. Please enter a different email",
        "INVALID_ORDER_ID": "Order with this number was not found",
        "FIREBASE_DOWN": "Firebase Down",
        "SHVA_OFFLINE": "There is a problem with credit card payments at the moment.\nPlease try another payment method or, if your hunger can wait, try again in a few minutes",
        "PAYMENT_QUOTA_EXCEEDED": "The card balance is insufficient ({{v}})",
        "SMS_PHONE_ERROR": "It is not possible to send a text message to the provided number {{phone}}.\nPlease contact the restaurant.",
        "CREDIT_GUARD_EX_ERROR": "There was an error processing the request. Please try again.",
    },
    "CREDIT_GUARD": {
        "ERROR_CODE_001": "The card is blocked, contact the credit card company (code 001).",
        "ERROR_CODE_002": "The card is stolen, contact the credit card company (code 002).",
        "ERROR_CODE_003": "An error occurred, please contact the credit company (code 003).",
        "ERROR_CODE_004": "A decline was received from the credit company, the transaction was not approved (code 004).",
        "ERROR_CODE_006": "CVV or ID number are wrong, please try again (code 006).",
        "ERROR_CODE_010": "The transaction is not approved for execution, enhanced verification is required. Please contact the credit card company (code 010).",
        "ERROR_CODE_033": "The card number entered is incorrect, please try again (code 033).",
        "ERROR_CODE_036": "Card is expired (code 036).",
        "ERROR_CODE_039": "The card number entered is incorrect, please try again (code 039).",
        "ERROR_CODE_173": "We were unable to process the payment, please make sure all the details are correct (code 173)",
        "ERROR_CODE_200": "We were unable to process the payment, please make sure all the details are correct (code 200)",
        "ERROR_CODE_316": "An external interface failure. Please contact the credit card company (code 316).",
        "ERROR_CODE_358": "Sensitive details were not found in the database (code 358).",
        "ERROR_CODE_401": "We were unable to process the payment, please make sure all the details are correct (code 401)",
        "ERROR_CODE_405": "We were unable to process the payment, please make sure all the details are correct (code 405)",
        "ERROR_CODE_414": "We were unable to process the payment, please make sure all the details are correct (code 414)",
        "ERROR_CODE_520": "There is no authorization to perform the transaction, please contact the credit company (code 520).",
        "ERROR_CODE_599": "The credit issuer's token service is not available, please contact the credit company (code 599).",
        "ERROR_CODE_695": "This type of credit card is not supported, please pay with another card (code 695).",
        "ERROR_CODE_900": "A transaction was blocked due to non-commitment to a secure transaction (code 900).",
    },
    "_LEUMIPAY": {
        "payment_error": "PAY. payment failed!\nPlease try again",
        "min_order_amount_error": "Pay is enabled for orders above NIS 50",
        "contrat": "to PAY's contract..."
    },
    "SLIP": {
        "OTH": "OTH",
        "ITEM_DISCOUNT": "Item discount",
        "TICKET_DISCOUNT": "Ticket discount",
        "TICKET_OTH": "OTH",
        "CHANGE": "Change"
    },
    "GROUP_VALIDATION": {
        "SELECT_ONE": "Please select one",
        "SELECT_SOME": "Please select {{min}}",
        "SELECT_ATLEAST_ONE": "Please select at least one",
        "SELECT_ATLEAST_SOME": "Please select at least {{min}}",
        "SELECT_UPTO_ONE": "Please select up to one",
        "SELECT_UPTO_SOME": "Please select up to {{max}}",
        "SELECT_BETWEEN": "Please select between {{min}} and {{max}}"
    },
    "ACCESS": {
        "ACCESSIBILITY": "Accessibility",
        "ACCESSIBILITY_IN_PROGRESS": "",
        "CLEAR_ACCESSIBILITY_FEATURES": "Clear accessibility features",
        "monochrom": "Monochrome",
        "light-contrast": "Light contrast",
        "dark-contrast": "Dark contrast",
        "enlarge-font": "Enlarge font",
        "reduce-font": "Reduce font",
        "readable-font": "Readable font",
        "big-white-curser": "Big black cursor",
        "big-black-curser": "Big white cursor",
        "enlarge-frame": "Enlarge",
        "highlight-links": "Highlight links",
        "highlight-headers": "Highlight headings",
        "image-info": "Image info"
    },
    "ADDRESS_TYPES": {
        "apartment": "Apartment",
        "office": "Office",
        "house": "House"
    },
    "OFFER_BADGES": {
        "new": "new",
        "sale": "sale"
    },
    "_WEIGHT": {
        "units": "Unit",
        "price_per_weight": "Price per {{unit}} = {{price}}",
        "item_weight": "Item's weight",
        "kg": "kilo (kg)",
        "gr": "gram (gr)",
        "lb": "pound (lb)",
        "oz": "ounce (oz)",
        "l": "liter (l)",
        "ml": "milliliter (ml)",
        "GRAM": "grams"
    },
    "_BENEFITS": {
        "BirthdayBenefit": "Birthday Benefit",
        "AnniversaryBenefit": "Anniversary Benefit",
        "JoinBenefit": "Join Benefit",
        "RenewBenefit": "Renew Benefit",
        "Voucher": "Voucher",
        "General": "General",
        "Other": "Other",
        "Points": "Points"
    },
    "_LOYALTY": {
        "cancel_benefits": "Cancel promo code",
        "card_balance": "Card balance",
        "connect": "Apply",
        "continue": "Continue",
        "expires": "Expires on",
        "member_not_found_title":"We couldn’t find you",
        "good_to_see_you_title":"Good to see you",
        "comps_extra_text": "The comps have been added to the order and can be redeemed",
        "member_not_found": "This number isn't connected to a membership",
        "member_not_found_PRIMARY_BUTTON_TEXT": "OK",
        "member_expired": "Club membership expired, benefits cannot be redeemed",
        "of": "Of",
        "points_balance": "Points balance",
        "points_balance_header": "Points Balance",
        "points_balance_cap": "יYou’ve got {{x}} points to redeem",
        "basket_compensation": "{{x}} as compensation",
        "punch_card_offer": "From {{from}} to {{to}}",
        "review_benefits": "Total benefits",
        "review_points": "Redeem points",
        "signout": "Sign out",
        "skip": "Skip",
        "cancel": "Cancel",
        "no_benefits_redeemed": "No promo code has been redeemed in this order",
        "redeemed_benefits_title": "The following promotions have been applied in this order",
        "total_benefits": "Total benefits",
        "voucher_gtreeting": "Hi! Do you have a promo code?",
        "voucher_inline_gtreeting": "Enter promo code",
        "voucher_gtreeting_info": "",
        "voucher_placeholder": "Enter promo code",
        "voucher_member_greeting": "My promotions",
        "voucher_benefits_title": "My promotions",
        "voucher_signin_error": "Sorry, promo code not found",
        "club_gtreeting": "Let’s find any codes or memberships you may have",
        "club_inline_gtreeting": "Enter promo code or loyalty membership",
        "club_gtreeting_info": "",
        "club_placeholder": "Phone/Promo code",
        "member_greeting": "Hi {{name}}",
        "club_benefits_title": "My Promotions",
        "club_signin_error": "No member or voucher found with this code",
        "signin_error_register": "We couldn't find your number on our friends list.<br/><br/><a target=_blank href=xxx>Join our loyalty program</a>",//friends list?
        "select_club": "Select a club",
        "benefits_title": "My Benefits",
        "no_benefits_title": "No available benefits",
        "register": "Join",
        "to_promotion_disclaimer": "Discount will be applied in the next step",
        "auth_title": "Confirmation code required",
        "auth_sub_title": "A confirmation code has been sent to your phone",
        "auth_code": "Enter code",
        "auth_resend": "Resend code",
        "benefit_types": "Available for {{val}} only",

        "max_redeemable_points": "Max redeemable",
        "my_benefits": "Benefits",
        "connect_info": "Phone NO. or Card NO.",
        "greeting": "Hi {{name}}!",
        "greeting_voucher": "Hi!",
        "balance": "Balance",
        "hey_greeting": "Hi {{name}}!",
        "hey_greeting_pointsStore": "Hi {{name}}, you have {{points}} {{pointName}}",
        "hey": "Hi!",
        "sum_to_use": "How much do you want to use?",
        "select_benefits_title": "Hi {{name}}\nSelect benefits",
        "select_benefits": "Select benefits",
        "select_benefits_max": "You can reddeem up to {{val}} benefits in this order",
        "benefits_max_in_this_order": "You have <b>{{val}}</b> rewards to choose from",
        "benefits_max_in_this_order_single": "There is 1 reward ready for you:",
        "select_benefits_desc": "Make sure benefit items are included in the order.\nBenefits will be redeemed before payment",
        "selected_not_redeemed": "The following were not redeemed",
        "selected_not_redeemed_desc": "** check order content or select another benefit",
        "CLUB_BENEFITS_ON_NEXT_STAGE": "Your rewards will be applied in the next step",
        "GETTING_BENEFITS": "Getting the benefits...",
        "POINTS_COMPS_PAY": "Money Voucher",
        "POINTS_AND_COMPS_PAY": "& Money Voucher",
        "POINTS_WAITING": "You have {{amount}} {{caption}} available 🎁",
        "POINTS_COMPS": "You have {{currency}} {{amount}} in Money Voucher available 🎁",
        "POINTS_AND_COMPS":"You have {{points}} points and {{currency}} {{amount}} in Money Voucher available 🎁",
        "voucher_entered":"Coupon Connected",
        "registration_terms": "By registering you agree to receive SMS texts about deals and offers.\nYou can cancel your registration at any time.",
        "regCostMessage": "Club Membership costs {{currency}}{{price}}. This will be added to your cart",
        "confirmDeleteOfferJoin": "There’s a fee to become a Loyalty Member.",
        "confirmDeleteOfferRenewal": "Cancel membership renewal?",
        "confirmDeleteOfferJoinDescription": "Removing it will prevent the receipt of benefits, would you like to remove it?",
        "confirmDeleteOfferRenewalDescription": "This will prevent you from receiving Loyalty benefits",
        "removeOfferJoinButton": "Yes, remove",
        "removeOfferRenewalButton": "Cancel renewal",
        "keepOfferJoinButton": "No, I want to join",
        "keepOfferRenewalButton": "Renew my membership",
        "regTitle": "Club Members have more fun!",
        "regDetailsDescription": "We couldn’t find your number {{phone}} in our system. Now’s the time to join!",
        "regCancel":"Cancel",
        "continueReg": "Join",
        "regRenewMessage": "Club Membership Renewal costs {{currency}}{{price}}. This will be added to your cart",
        "regPromptTitle": "We couldn’t find your number {{phone}} in our system.\nNow’s the time to join!!",
        "regPromptPrice": "It costs {{currency}}{{price}} to become a Club Member",
        "regUpdateNessage": "Please verify the details",
        "registerButton" : "Join",
        "continueWithoutRenewing": "Continue without Renewal",
        "continueWithoutReg": "Continue without Joining",
        "renewMembership" : "Membership Renewal",
        "phoneNumber" : "Phone Number",
        "textForConfirmSms" : "By registering you agree to receive SMS texts about deals and offers. You can cancel your registration at any time",
        "textForConfirmClubRules" : "I agree to the Club Terms and Conditions",
        "regRenewPromptPrice": "Membership renewal costs {{currency}}{{price}}",
        "regRenewTitle":"Time flies!",
        "regRenewBody":"It’s time to renew your club membership",
        "regSoonExpiredRenewBody": "Your membership expires on {{date}}.\You can pay membership renewal fee to continue and enjoy the rewards included in this loyalty program in the future.",
        "regSoonExpiredSignOut": "Your membership expires on {{date}}.",
        "termsContinue":"Continue",
        "termsBody":"It seems that you have not yet completed the registration for the club \n Just a few last details and we are good to go :)",
        "termsTitle": "Hi {{name}}",
        "termsFirstContinue":"Continue",
        "first_name":"First name",
        "last_name":"Last name",
        "email":"Email address",
        "zehut":"ID number",
        "phone":"Phone number",
        "anniversary":"Anniversary",
        "birth_date":"Date of birth",
        "regErrorTitle": "Error",
        "regError": "Unable to complete the registration process, please contact the restaurant",
    },
    "_GIFTCARD": {
        "title": "Have a prepaid card or gift card?",
        "gift_card": "Gift Card",
        "select_card": "Select a Card",
        "please_select_card": "Please select a card",
        "edit_card": "Edit gift card",
        "add_card": "Pay with Gift Card",
        "number": "Card number",
        "amount": "Amount to pay",
        "balance": "Card balance",
        "max": "Max amount",
        "cvv": "CVV",
        "cvv_required": "CVV required",
        "invalid_cvv": "Invalid CVV number",
        "inuse_error": "Can't use the same card more than once in the same order",
        "balance_error": "Card has no balance",
        "tip_not_allowed": "This card cannot be used to pay tip",
        "notfound_error": "Card not found!",
        "delete_prompt": "Delete?",
        "no_order_amount": "Order is fully covered, no need to add another payment",
        "reset_split_payments_prompt": "Adding or updating a gift card will reset split payments. Continue?"
    },
    "_DELAYED": {
        "title": "Order Schedule",
        "service_caption": "Future Date",
        "supply_time": "Supply time",
        "select_date": "Select Supply Date",
        "select_slot": "Select Supply Time",
        "service_today": "Today",
        "service_future": "Future date",
        "for_hour": "For {{val}}",
        "for_date": "For {{val}}",
        "between": "between",
        "at_hour": "at",
        "select_slot_prompt": "Please select supply time",
        "service_disabled_today": "This service is not available today"
    },
    "_OFFER_HISTORY": {
        "title": "How to prepare the order?",
        "description": "It seems you've picked before...",
        "continue": "Continue without selection",
        "select": "Select an offer"
    },
    "_EXTERNAL_DELIVERY": {
        "est_title": "Delivery Details",
        "est_apply": "Continue",
        "est_cancel": "Cancel",
        "delivery_time": "Estimated delivery time",
        "delivery_price": "Delivery price",
        "estimate_fail_message": "Delivery estimate failed",
        "validation_fail_message": "Delivery validation failed",
        "OUT_OF_DELIVERY_SERVICE_TIME": "Delivery is unavailable at this time",
        "ADDRESS_OUTSIDE_OF_DELIVERY_AREA": "We are sorry, delivery is not available for your address: {{address}}",
        "INVALID_PHONE": "Phone Number is invalid, please provide a valid phone number",
        "PHONE_NO_PSTN": "Phone Number is invalid, please provide a valid phone number",
        "ALCOHOL_NOT_DELIVERABLE": "Restaurant does not allow alcohol delivery. Remove alcohol from cart before proceeding to payment",
        "UNKNOWN_ERROR": "An error occured, please contact the restaurant"
    },
    "_EXTRA_INFO": {
        "delivery_ETA": "Estimated arrival",
        "takeaway_ETA": "Will be ready at",
        "min_order_price": "Min. order for delivery",
        "free_delivery_from": "Free delivery from-",
        "delivery_fee": "Delivery fee",
        "up_to": "Up to",
        "TAKEAWAY_FROM": "Pick up from",
        "asap_takeaway_ETA": "Preparation time:",
        "asap_delivery_ETA": "Delivery time:",
        "future_delivery_ETA": "Order will be ready at:",
        "delayed_delivery_ETA": "Order will be ready at:",
    },
    "SIGN_IN_REGISTER": "Sign in / Register",
    "SIGN_IN_REGISTER_TABIT": "Connect to Tabit",
    "SIGN_UP_TABIT": "Sign Up for Tabit",
    "BASKET": {
        "INTERIM_AMOUNT": "Subtotal",
        "TOTAL_ITEMS": "Total Items",
        "FREE_DELIVERY_FROM_PREFIX": "Free delivery from",
        "FREE_DELIVERY_FROM_SUFFIX": "!",
        "MINIMUM_ORDER": "Order minimum {{price}}",
        "TO_FREE_DELIVERY": "{{amount}}",
        "FREE_DELIVERY": "is missing for free delivery!",
        "CONGRATULATIONS": "Congratulations, ",
        "DELIVERY_ON_US": "Delivery is on us!",
        "TAKEAWAY_FROM": "Takeout from",
        "DELIVERY_TO": "Deliver to",
        "EDIT_ORDER": "Edit order",
        "DOES_NOT_INCLUDE_DELIVERY_FEE": "(Does not include delivery fee)",
        "DOES_NOT_INCLUDE_DELIVERY_FEE_AND_LOYALTY_ITEM": "(Does not include delivery fee and {{item}})",
    },
    "BOTTOM_SHEET": {
        "pay_with_point":"Pay With Point",
        "regular_payment":"Regular Payment",
    },
    "MENU_MESSAGES": "Messages",
    "AMOUNT_LEFT": "left {{amount}}",
    "ORDER_MINIMUM": "for minimum order",
    "REMINDER_AMOUNT": "Amount remaining",
    "MY_ORDER": "My Order",
    "YOUR_DELIVERY_ORDER_TO": "Your order to",
    "YOUR_TA_ORDER_FROM": "Your order from",
    "ORDER_SENT_SUCCESSFULLY": "Your order has been successfully sent!",
    "ORDER_SENT_INFO_TA": "Take away order number {{number}}, from {{site}} ({{address}})",
    "ORDER_SENT_INFO_DELIVERY": "Delivery order number {{number}}, to {{address}}",
    "DYNAMIC_ORDER_SUCCESS_MESSAGE": "{{service}} order number {{number}} processed successfully",
    "DYNAMIC_ORDER_FAIL_MESSAGE": "{{service}} order number {{number}} processed with a payment error",
    "EATIN_SUCCESS_MESSAGE":"Order number {{number}} processed successfully",
    "ORDER_WILL_BE_READY_AT": "will be ready at {{time}}.",
    "ORDER_WILL_BE_DELIVERED_AT": "will be ready at {{time}}.",
    "ENJOY_YOUR_MEAL": "Enjoy your meal!",
    "SLIDE_TO_PAY":"Slide to pay",
    "JUST_PAY":"Pay",
    "SLIDE_TO_SUBMIT":"Slide to send",
    "FUTURE_ORDER_WILL_BE_READY_AT": "scheduled {{time}}.",
    "FUTURE_ORDER_WILL_BE_READY": "scheduled -{{time}}.",
    "FUTURE_ORDER_WILL_BE_DELIVERED_AT": "will arrive by {{time}}.",
    "ASAP_FUTURE_ORDER_WILL_BE_DELIVERED_AT": "scheduled -{{time}}.",
    "NATIVE_PAYMENT": {
        "error_dialog_title": "We are unable to process your request at the moment.",
        "error_dialog_description": "Please select another payment method",
        "error_dialog_no_amount_description": "To use {{type}} Pay, tap the X next to the payment amount and try again",
    },
};
