import { Component, NgZone, Input, ChangeDetectionStrategy } from '@angular/core';
import { EntityService } from '../../_core/entity.service';
import { AppService } from '../../app.service';
import { AnimationItem } from 'lottie-web';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { LottieComponent } from 'ngx-lottie';
import { NgIf } from '@angular/common';

@Component({
    selector: 'favorite-fab',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './favorite-fab.component.html',
    styleUrls: ['./favorite-fab.component.scss'],
    host: {
        '(click)': 'toggleFavorite(site, $event)',
    },
    standalone: true,
    imports: [NgIf, LottieComponent, MatIconButton, MatIcon]
})
export class FavoriteFabComponent {
	@Input() site;

    showStatic: boolean = true;
    animating: string;
    animationItem: AnimationItem;
    onAnimation: string = this.appService.getAnimationPath('heart-on2');
    offAnimation: string = this.appService.getAnimationPath('heart-off');

    @Input() white: boolean;

    constructor(
        private entityService: EntityService,
        public appService: AppService,
        private ngZone: NgZone
	) { }

	toggleFavorite(site, event) {
		if (event) {
            event.stopPropagation();
        }
        this.animating = this.site.isFavorite ? 'to-off' : 'to-on';
        this.showStatic = false;
    }

    animationCreated(animationItem: AnimationItem, segmentFrames: any): void {
        this.animationItem = animationItem;
    }

    animationDomLoaded(segmentFrames: any): void {
        this.animationItem.playSegments(segmentFrames, true);
    }

	animationCompleted() {
        this.ngZone.run(() => {
            this.showStatic = true;
            this.entityService.toggleFavorites(this.site);
            this.site.isFavorite = this.site.isFavorite ? false : true; // Required for the icon to get updated on site-details
            setTimeout(() => {
                this.animating = null;
            });
        });
	}

}
