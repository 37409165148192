import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { AppService } from '../../../../app.service';

@Component({
    selector: 'order-tracker-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './order-tracker-footer.component.html',
    styleUrls: ['./order-tracker-footer.component.scss'],
    standalone: true
})
export class OrderTrackerFooterComponent implements OnInit {

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit(): void {
    }

}
