
<div class="_title">
	<input class="country-search" [(ngModel)]="searchCriteria" type="text" [placeholder]="data.searchPlaceholder + '...'" (click)="$event.stopPropagation()" />

	<button mat-icon-button (click)="cancel()" class="dialog-close-button">
		<mat-icon aria-label="Close Dialog">close</mat-icon>
	</button>
</div>

<div>
	<ng-container *ngIf="!searchCriteria?.length">
		<button type="button" mat-menu-item class="country-list-button" *ngFor="let country of data.preferredCountriesInDropDown" (click)="onCountrySelect(country)">
			<div class="icon-wrapper">
				<div class="flag" [ngClass]="country.flagClass"></div>
			</div>
			<div class="label-wrapper">
				{{ country.name }}
				<span *ngIf="country?.dialCode">+{{ country.dialCode }}</span>
			</div>
		</button>
	</ng-container>

	<mat-divider *ngIf="data.preferredCountriesInDropDown?.length && !searchCriteria?.length"></mat-divider>

	<ng-container *ngFor="let country of data.allCountries">
		<button type="button" mat-menu-item class="country-list-button" *ngIf="country | search: searchCriteria" (click)="onCountrySelect(country)">
			<div class="icon-wrapper">
				<div class="flag" [ngClass]="country.flagClass"></div>
			</div>
			<div class="label-wrapper">
				{{ country.name }} +{{ country.dialCode }}
			</div>
		</button>
	</ng-container>
</div>

