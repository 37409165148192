
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AppService } from '../app.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService  {
	constructor(
		public auth: AuthService,
		public router: Router,
		public appService: AppService
	) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// 2019-11-17: The authGuardService has been re-actived because now the frontend relies on two tokens - one from ROS and one from Loyalty, and in some screens a request to the backend is not always fired upon init.
		if (this.auth.isAuthenticated()) {
			return true;
		} else {
            this.appService.landingUrl = '/'+next.url[0].path;
            this.appService.landingUrlParams = {queryParams: next.queryParams};
            //console.log('=== LANDING URL: ', next, state, this.appService.landingUrl, this.appService.landingUrlParams);
            this.appService.stopBlock();
			this.appService.redirect(['/sign-in']);
			return false;
		}
	}
}

