import { Injectable, NgZone } from '@angular/core';
import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { DialogsService } from '../../_core/dialogs.service';

import { MatDialog } from '@angular/material/dialog';
import { PassOrderDialogComponent } from '../../order/tabit-pay/dialogs/pass-order-dialog/pass-order-dialog.component';

import { get, find, extend } from 'lodash-es';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class TabitpayUtilsService {

    CONSTS: any = {
        storageToken: "tabit_co",
        storageToken_keys: "tabit_co_keys",
        tdTypeMap: {
            "takeaway": "TA",
            "eatin": "OTC",
            "delivery": "Delivery"
        },
        tdServiceTypeMap: {
            "eatin": "seated"
        },
        tdOrderTypeMap: {
            "Seated": "eatin",
            "Delivery": "delivery",
            "TA": "takeaway"
        },
        tdTenderMap: {
            "CASH": "cash",
            "CREDIT": "creditCard",
            "CIBUS": "Cibus",
            "TENBIS": "10bis"
        },
        AUTH_EXP_THRESHOLD_MS: 60000
    }

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public dialogsService: DialogsService,
        public dialog: MatDialog,
        private ngZone: NgZone,
    ) {

    }

    getSMSverificationCode(args, isRetry, organization?) {
        let that = this;
        extend(args, { deviceId: this.appService.clientId, hasRetry: true });

        const verificationCodeRequest = () => {
            return this.entityService.post("/online-shopper/verification", args, { 'ros-organization': organization });
        };

        return verificationCodeRequest().then(() => {
            return this.dialogsService.showSMSDialog(extend(args, { isRetry, verificationCodeRequest })).then(response => {
                if (get(response, 'isRetry', false)) return that.getSMSverificationCode(args, isRetry, organization);
                return response;
            }).catch(() => {
                throw ({ type: 'CANCELED' });
            })
        }).catch(err => {
            throw (err);
        })
    }


    ccInfoToWalletPayment(ccinfo, withCVV?) {
        if (ccinfo && !ccinfo.$$isExternal) {
            var parsedPayment = this.getPreparedPayment(ccinfo);
            let res: any = {
                paymentType: parsedPayment.paymentType,
                pan: parsedPayment.cardNum.toString(),
                expMonth: parsedPayment.expMonth,
                expYear: parsedPayment.expYear,
                brand: ccinfo.type,
                ownerName: parsedPayment.name,
                comment: ccinfo.comment,
                idCard: ccinfo.idCard
            }
            if (withCVV && ccinfo.cvv) res.cvv = ccinfo.cvv
            return res;
        }
    };

    getCVV(card) {
        //return Promise.resolve(123);
        return this.appService.prompt({
            title: 'PLEASE_ENTER_CVV',
            content: '',
            label: 'CVV',
            numeric: true
        }).catch(err => {
            throw {
                type: 'CANCELED',
                message: "CANCELED",
            };
        });
    };

    serverErrorToAppError = function (serverError) {
        if (!serverError) return;
        if (serverError?.data?.code === 409001) {
            return {
                type: 'SERVER_ERROR',
                message: this.appService.translate("MESSAGES.ORDER_HELD_BY_SERVER"),
                serverError: serverError
            }
        }
    };

    restoreKEY(key) {
        return this.appService.restoreKEY(key);
    };

    cacheKEY(key, val) {
        return this.appService.cacheKEY(key, val);
    };

    getPreparedPayment(pinfo) {
        var amount = Math.round(pinfo.amount * 100);// || order.balanceDue || order.grandTotal) * 100);
        var tip = null;
        if (pinfo.tip) {
            tip = { amount: Math.round(pinfo.tip * 100) }
        }

        if (pinfo.walletPayment) {
            return {
                account: pinfo.account,
                paymentType: pinfo.paymentType,
                walletPayment: pinfo.walletPayment,
                amount: amount,
                tip: tip,
                customerDetails: pinfo.customerDetails,
                cvv: pinfo.cvv ? pinfo.cvv.toString() : (pinfo.paymentType === 'creditCard' ? 'MISSING' : null),
                idCard: pinfo.idCard ? pinfo.idCard.toString() : (pinfo.paymentType === 'creditCard' ? 'MISSING' : null),
                deviceId: this.appService.clientId,
                merchantNumber: pinfo.merchantNumber
            }
        } else {
            var base: any = {
                paymentType: pinfo.paymentMethod,
                cardNum: pinfo.pan,
                amount: amount,
                customerDetails: pinfo.customerDetails,
                tip: tip,
                $$isExternal: pinfo.$$isExternal,
                deviceId: this.appService.clientId,
                merchantNumber: pinfo.merchantNumber
            }
            if (pinfo.account && isNaN(pinfo.account)) {
                base.account = pinfo.account;
            }
            if (pinfo.$$isExternal) return base;

            switch (pinfo.paymentMethod) {
                case "creditCard":
                    return extend(base, {
                        expMonth: pinfo.expMonth + "",
                        expYear: pinfo.expYear + "",
                        name: pinfo.name,
                        cvv: pinfo.cvv.toString(),
                        idCard: pinfo.idCard && pinfo.idCard.toString(),
                    });
                case "cash":
                    return extend(base, {});
                default:
                    return extend(base, {});

            }
        }
    };


    getCustomer(args, includeOrderInstructions) {
        //var contact = args.contact;
        let contact = this.appService.user;
        var phoneNumber = contact.phone && contact.phone.trim();

        //fit phone for international US/IL use
        // - replace an actual international phone solution on the UI
        if (phoneNumber?.length > 10 && (phoneNumber[0] !== '+')) {
            if ((phoneNumber[0] === '1') || (phoneNumber.substr(0, 3) === '972'))
                phoneNumber = '+' + phoneNumber;
        }

        const customer: any = {
            name: get(contact, 'loyaltyCustomer.FullName', contact.name),
            phone: phoneNumber,
            email: contact.email
        };

        if (includeOrderInstructions) {
            customer.dinerCount = contact.dinerCount;
            customer.includeCutlery = contact.includeCutlery;
        }
        const address = args.address;
        if (address) {
            customer.deliveryAddress = {
                city: address.locality,
                street: address.street,
                house: address.streetNumber,
                floor: address.floor && address.floor.toString(),
                apartment: address.apartment && address.apartment.toString(),
                entrance: address.entrance,
                location: address.point,
                notes: address.remarks
            }
            var region = args.region;
            if (region) {
                customer.deliveryAddress.regionId = region.id;
                customer.deliveryAddress.regionName = region.name;
            }
        }
        return customer;
    };

    getPreparedOrder = function getPreparedOrder($storage, _clean, options) {
        const order = $storage.order;
        const ret: any = {
            orderType: this.CONSTS.tdTypeMap[order.mode],
            serviceType: this.CONSTS.tdServiceTypeMap[order.mode],
            orderer: this.getCustomer(order, true),
            balanceDue: order.grandTotal,
            balance: Math.round(order.grandTotal * 100),
            table: order.table
        };
        if ($storage.orderMode == 'fixedorder') {//fixed order should be processesed by order_id
            ret.order_id = $storage.order.order_id;
            ret.__v = $storage.order.__v;
            ret.balanceDue = $storage.order.balanceDue;
            ret.balance = Math.round($storage.order.balanceDue * 100);
        }
        return ret;
    };

    passOrder($storage) {
        this.appService.startBlock();
        this.entityService.get(`/online-shopper/orders/${$storage.order.order_id}/links`, { source: 'tabitPay' }, { 'ros-organization': $storage.organization }).then(links => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(PassOrderDialogComponent, {
                    //panelClass: 'fullscreen-dialog',
                    data: {
                        link: links[0],
                        forcePrices: $storage.forcePrices,
                        organization: $storage.organization,
                        orderId: $storage.order.order_id
                    }
                });
                dialogRef.afterClosed().subscribe(result => { if (result != undefined) { } });
            });
        }).catch(err => {
            this.appService.toastr('CANNOT_SEND_ORDER_BY_SMS', { type: 'error' });
        }).then(ret => {
            this.appService.stopBlock();
        });
    }

    isTimeslotsActive(timeslot) {
		let md = moment();
		let d = md.day();

		let dayM = 24 * 60;
		let startThreshhold = 5 * 60;
		let cmd = getMinutes(md);
		if (cmd < startThreshhold) {
			d -= 1;
			if (d < 0) d = 6;
			cmd += dayM;
		}

		let day: any = find(timeslot.days, { day: d });
		if (!day.active) return null;
		if (day.type == "allday") return {};
		if (day.type == "default") day = find(timeslot.days, { day: -1 });

		day.tslots = [];
		let slotFound;
		for (let i = 0; i < day.slots.length; i++) {
			var slot = day.slots[i];

			slot.from = moment(slot.from);
			slot.to = moment(slot.to);

			slot.tfrom = slot.from.format("HH:mm");
			slot.tto = slot.to.format("HH:mm");

			slot.mfrom = getMinutes(slot.from);
			if (slot.from.date() == 2) slot.mfrom += dayM;
			slot.mto = getMinutes(slot.to);
			if (slot.to.date() == 2) slot.mto += dayM;

			day.tslots.push(slot.tfrom + " - " + slot.tto);

			if (cmd >= slot.mfrom && cmd < slot.mto) {
				slotFound = slot;
				break;
			}
		}
		return slotFound;
		function getMinutes(m) {
			return (m.hours() * 60) + m.minutes();
		}
	}


}
