import { Component, OnInit, Input, OnDestroy, NgZone } from '@angular/core';

import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { OccasionsService } from '../../_core/occasions.service';
import { BookService } from '../../_core/book.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { LocationService } from '../../_core/location.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

import moment from 'moment';
import { max } from 'lodash-es';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from '../rating/rating.component';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, SlicePipe } from '@angular/common';

@Component({
    selector: 'event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss'],
    animations: [
        trigger('eventCardAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.7)' }),
                animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
                animate('0.2s 0s ease-in', style({ transform: 'scale(1)' }))
            ]),
            // transition(':leave', [
            //     style({ transform: 'scale(1)' }),
            //     animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
            //     animate('0.3s 0s ease-in', style({ opacity: 0, transform: 'scale(0.5)' })),
            //     animate('0.1s 0s ease-in', style({ width: 0 }))
            // ])
        ]),
        trigger('flipState', [
            state('active', style({
                transform: 'rotateY(180deg)'
            })),
            state('inactive', style({
                transform: 'rotateY(0)'
            })),
            transition('active => inactive', animate('300ms ease-in')),
            transition('inactive => active', animate('300ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [NgIf, MatIcon, NgFor, RatingComponent, SlicePipe, TranslateModule, SafeStylePipe]
})
export class EventCardComponent implements OnInit, OnDestroy {

    private _siteEvent: any;
    private timeSlotInterval: number = 30;
    private recurringDays: string = '';

    public org: any;
    public timestamp;
    public longDescription;
    public flip: string = 'inactive';
    public isReservationActive: boolean = false;
    public frontTime: string;
    public promoText: string;
    public backTime: any = {
        upperCaption: '',
        lowerCaption: '',
    };

    @Input() tags: any;
    @Input() index: number;
    @Input()
        set siteEvent(siteEvent: any) {
            let org = this.organizationsService.getOrganization(siteEvent.organization);
            this._siteEvent = siteEvent;
            this.org = org;
        }
        get siteEvent(): any { return this._siteEvent }

    constructor(
        public appService: AppService,
        public locationService: LocationService,
        public utilsService: EntityUtilsService,
        public bookService: BookService,
        private occasionsService: OccasionsService,
        private organizationsService: OrganizationsService,
        private ngZone: NgZone,
    ) { }

    ngOnInit() {
        // console.log('=== EVENT-CARD/siteEvent ===', this.siteEvent);
        if (this.siteEvent.recurrence_rule) this.setrecurringDays();
        this.checkIfReservationActive();
        this.setPromoText();
        this.setPriceLevel();
        this.setEventImage();
        this.setEventTimeAndBookingTimestamp();
        this.setEventTags();
        this.setEventLongDescription();
    }

    ngOnDestroy() {}

    checkIfReservationActive() {
        if (!this.org.bookingData && !this.org.bookingData.enabled) return;
        if ((this.org.bookingData && this.org.bookingData.future_reservation && this.org.bookingData && this.org.bookingData.future_reservation.enabled) ||
            (this.org.bookingData && this.org.bookingData.walked_in && this.org.bookingData && this.org.bookingData.walked_in.enabled)
        ) this.isReservationActive = true;
    }

    setPriceLevel() {
        if (this.org.googlePlaceDetails) {
            if (this.org.googlePlaceDetails.price_level) this.occasionsService.setPriceLabel(this.org);
            if (this.org.googlePlaceDetails.rating) this.utilsService.getRatingStars(this.org.googlePlaceDetails.rating);
        }
    }

    _redirectToSite() {
        let router = window['cordova'] ? '/app-site' : '/site';
        this.appService.redirect([router, this.org._id]);
    }

    toggleFlip() {
        this.ngZone.run(() => { 
            this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
        });
    }

    bookEvent(event: Event, timestamp: any, orgId: string) {
        this.occasionsService.bookClick(event, timestamp, orgId)
    }

    private setPromoText() {
        if (moment(this.siteEvent.reservation_details.reserved_from).diff(moment(), 'minutes') <= 120 && moment(this.siteEvent.reservation_details.reserved_from).diff(moment(), 'minutes') > 0) {
            this.promoText = 'occasions.starts_soon';
            return;
        }
        if ((this.isEventOccursToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until) || this.isEventEndsToday(this.siteEvent.reservation_details.reserved_until)) && moment(this.siteEvent.reservation_details.reserved_from).diff(moment(), 'minutes') <= 0) {
            this.promoText = 'occasions.now';
            return;
        }
    }

    private setEventImage() {
        if (this.siteEvent.occasion_details.publish_occasion && this.siteEvent.occasion_details.publish_occasion.image) {
            this.siteEvent.image = this.siteEvent.occasion_details.publish_occasion.image;
        }
    }

    private setTimestamp(eventTime: any) {
        let roundedTimestamp = this.roundTime(eventTime, moment.duration(this.timeSlotInterval, "minutes"), "ceil");

        return roundedTimestamp.toISOString();
    }

    private setEventTimeAndBookingTimestamp() {
        // Time and date that are being sent to Tabit order and RSV
        this.timestamp = this.setTimestamp(moment(this.siteEvent.reservation_details.reserved_from));

        if (!this.siteEvent.recurrence_rule) { // Not recurring event
            // TODO - Add logic for continous event for the last day (Should appear Today) //
            // Time and date that are being shown on the template
            // Whole day Event
            if (this.siteEvent.occasion_details && this.siteEvent.occasion_details.all_day) {
                // Current day
                if (this.isEventStartsToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = 'today_all_day';
                } else {
                    this.frontTime = `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${this.appService.translate('occasions.all_day')}`;
                }

                this.setEventBackTimeCaption('isEventToday');

                return;

            } else { // Part of the day Event
                if (this.isEventStartsToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${this.appService.translate('today')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventToday');
                    return;
                }
                if (this.isEventAtAnotherDay(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventAtAnotherDay');

                    return;
                }
                if (this.isEventTodayAndEndsAtAnotherDay(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${this.appService.translate('today')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_from).endOf('day').add(1, 'minutes').format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventTodayAndEndsAtAnotherDay');

                    return;
                };
                if (this.isEventAtAnotherDayAndEndsAtAnotherDay(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    if (this.isEventOccursToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                        this.frontTime = `${this.appService.translate('today')}, ${this.appService.translate('occasions.all_day')}`;
                    } else {
                        this.frontTime = `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_from).endOf('day').add(1, 'minutes').format('HH:mm')}`;
                    }

                    this.setEventBackTimeCaption('isEventAtAnotherDayAndEndsAtAnotherDay');
                    return;
                };

                if (this.isEventAtAnotherDayAndEndsToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${this.appService.translate('today')}, ${this.appService.translate('occasions.until')} ${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventAtAnotherDayAndEndsToday');

                    return;
                };
            }
        } else { // Recurring event
            if (this.siteEvent.occasion_details && this.siteEvent.occasion_details.all_day) {
                // Current day
                if (this.isEventStartsToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${this.appService.translate('today')}, ${this.appService.translate('occasions.all_day')}`;
                } else {
                    this.frontTime = `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${this.appService.translate('occasions.all_day')}`;
                }
                this.setEventBackTimeCaption('isEventToday');

                return;
            } else {
                if (this.isEventStartsToday(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${this.appService.translate('today')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventToday');

                    return;
                }
                if (this.isEventAtAnotherDay(this.siteEvent.reservation_details.reserved_from, this.siteEvent.reservation_details.reserved_until)) {
                    this.frontTime = `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    this.setEventBackTimeCaption('isEventAtAnotherDay');

                    return;
                }
            }
        }
    }

    private setEventBackTimeCaption(eventType: string) {
        if (this.siteEvent.recurrence_rule) { // Recurring event
            switch (eventType) {
                case "isEventToday":
                case "isEventAtAnotherDay":
                    let recurringRule = this.setRecurringRuleCaption();
                    let recurringDay = this.setRecurringDaysCaption();
                    this.backTime.upperCaption = `${this.appService.translate(`occasions.${recurringRule}`)} ${this.appService.translate(`occasions.${recurringDay}`)} ${this.recurringDays}`;
                    this.backTime.lowerCaption = `${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    break;
            }
        } else {
            switch (eventType) {
                case "isEventToday":
                case "isEventAtAnotherDay":
                    this.backTime.upperCaption = this.siteEvent.occasion_details && this.siteEvent.occasion_details.all_day ? `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd, D/M')}, ${this.appService.translate('occasions.all_day')}` : `${moment(this.siteEvent.reservation_details.reserved_from).format('dddd, D/M')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}-${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    break;
                case "isEventTodayAndEndsAtAnotherDay":
                case "isEventAtAnotherDayAndEndsAtAnotherDay":
                case "isEventAtAnotherDayAndEndsToday":
                    this.backTime.upperCaption = `${this.appService.translate('occasions.from_day')} ${moment(this.siteEvent.reservation_details.reserved_from).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_from).format('D/M')}, ${moment(this.siteEvent.reservation_details.reserved_from).format('HH:mm')}`;
                    this.backTime.lowerCaption = `${this.appService.translate('occasions.time_until')} ${moment(this.siteEvent.reservation_details.reserved_until).format('dddd')} ${moment(this.siteEvent.reservation_details.reserved_until).format('D/M')}, ${moment(this.siteEvent.reservation_details.reserved_until).format('HH:mm')}`;
                    break;
            }
        }
    }

    private setEventTags() {
        if (this.siteEvent.occasion_details.publish_occasion) {
            let tagsIds = this.siteEvent.occasion_details.publish_occasion.tags;
            if (tagsIds) this.siteEvent.tags = this.occasionsService.getOccasionFullTags(tagsIds, this.tags);
        } else this.siteEvent.tags = [];
    }

    private setEventLongDescription() {
        if (this.siteEvent.occasion_details.publish_occasion && this.siteEvent.occasion_details.publish_occasion.description) {
            this.longDescription = this.siteEvent.occasion_details.publish_occasion.description;
        } else this.longDescription = this.siteEvent.occasion_details.description;
    }

    private setrecurringDays() {
        let weekdays = this.siteEvent.recurrence_rule.weekdays;
        if (weekdays.length && weekdays.length > 1) {
            weekdays = this.sortFullWeek(weekdays);
        }
        this.recurringDays = weekdays.map(day => moment().isoWeekday(day).format('ddd')).join(', ');
    }

    private sortFullWeek(days: any) {
        let maxDay = days.splice(days.indexOf(max(days)), 1);
        days = days.filter(day => day != maxDay).sort();
        days.splice(0, 0, 7);

        return days;
    }

    private setRecurringRuleCaption() {
        let caption;
        if (this.siteEvent.recurrence_rule && this.siteEvent.recurrence_rule.interval) {
            if (this.siteEvent.recurrence_rule.interval.weeks === 2) caption = 'every_two_weeks';
            if (this.siteEvent.recurrence_rule.interval.weeks === 4) caption = 'every_month';
        };
        return caption || 'every_week';
    }

    private setRecurringDaysCaption() {
        let caption;
        if (this.siteEvent.recurrence_rule && this.siteEvent.recurrence_rule.weekdays) {
            if (this.siteEvent.recurrence_rule.weekdays && this.siteEvent.recurrence_rule.weekdays.length && this.siteEvent.recurrence_rule.weekdays.length === 1) caption = 'on_day';
        };
        return caption || 'on_days';
    }

    private roundTime(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    private isEventOccursToday(date_from: any, date_until: any): boolean {
        if (moment(date_from).isAfter(moment(), 'days')) return false;
        if (moment(date_from).isSame(moment(), 'days')) return true;
        if ((moment(date_from).isBefore(moment(), 'days')) && moment().isBefore(moment(date_until), 'days')) return true;
        return false;
    }

    private isEventEndsToday(date_until: any): boolean {
        if (moment(date_until).isSame(moment(), 'days')) return true;
        return false;
    }

    private isEventStartsToday(date_from: any, date_until: any): boolean {
        if (moment(date_from).isAfter(moment(), 'days')) return false;
        if ((moment(date_from).isoWeekday() === moment().isoWeekday()) && (moment(date_until).isoWeekday() === moment().isoWeekday())) return true;
        return false;
    }

    private isEventAtAnotherDay(date_from: any, date_until: any): boolean {
        if (moment(date_from).isAfter(moment(), 'days') && moment(date_until).isAfter(moment(), 'days') && (moment(date_from).isSame(date_until, 'days'))) return true;
        return false;
    }

    private isEventTodayAndEndsAtAnotherDay(date_from: any, date_until: any): boolean {
        if (moment(date_from).isSame(moment(), 'days') && moment(date_until).isAfter(moment(), 'days')) return true;
        return false;
    }

    private isEventAtAnotherDayAndEndsAtAnotherDay(date_from: any, date_until: any): boolean {
        if ((moment(date_from).isBefore(moment(), 'days') || moment(date_from).isAfter(moment(), 'days')) &&
            (moment(date_until).isAfter(moment(), 'days')) && 
            (!moment(date_from).isSame(date_until, 'days'))
        ) return true;

        return false;
    }

    private isEventAtAnotherDayAndEndsToday(date_from: any, date_until: any): boolean {
        if ((!moment(date_from).isSame(date_until, 'days')) && (!moment(date_from).isSame(moment(), 'days')) && (moment(date_until).isSame(moment(), 'days'))) return true;
        return false;
    }
}

