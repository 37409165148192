

<h1 mat-dialog-title class="d-flex align-items-center">
    <span class="flex-grow-1 dialog-header">{{data.text | translate}}</span>
    <button aria-label="close" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
        <mat-icon>close</mat-icon>
    </button>
</h1>

<div mat-dialog-content>
    <div class="list-group text-center mb-2">
        <button type="button" class="list-group-item list-group-item-action" *ngFor="let member of data.members" (click)="apply(member)">
            <div class="text-lg font-weight-bold py-2" [ngStyle]="{direction: (data.buttonTextDirection || data.direction || appService.direction) }">{{(member.text || member.name) | translate}}</div>
        </button>
    </div>
</div>


