import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AppService } from '../../app.service';
import { DialogsService } from "../../_core/dialogs.service";
import { EntityService } from '../../_core/entity.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { TranslationService } from '../../_core/translation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Subscription, combineLatest } from 'rxjs';
import { get } from 'lodash-es';

import { environment } from '../../../environments/environment';
import { SafeStylePipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgIf, NgFor } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@UntilDestroy()
@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        NgIf,
        MatMenuTrigger,
        MatMenu,
        NgFor,
        MatMenuItem,
        RouterLink,
        TranslateModule,
        SafeStylePipe,
    ],
})
export class MyProfileComponent implements OnInit, OnDestroy {

    private domainSubscription: Subscription;
    public domain: any;
    public isContactActive: boolean;
    public selectedLanguageValue: string;
    public filteredLanguages: any;

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public dialogService: DialogsService,
        public configurationsService: ConfigurationsService,
        public translationService: TranslationService,
        public ngZone: NgZone,
    ) {
        /*
        const hammertime = new Hammer(elementRef.nativeElement, {});
        hammertime.on('panleft', (ev) => {
            this.appService.toggleAppSidenav();
        });
        */

    }

    currentUser = {
        loyaltyCustomer: {}
    };

    ngOnInit() {

        combineLatest([
            this.appService.loadingMetaData,
            this.appService.loadingCoreData
        ]).subscribe(([loadingMeta, loadingCore]) => {
            if (!loadingMeta && this.appService.user) {
                this.currentUser = this.appService.user;
            }

            if (!loadingCore) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;
                    this.isContactActive = (!!get(this.domain, 'links.contactDetailsURL'));
                    this.selectedLanguageValue = this.appService.localeId;
                    this.filteredLanguages = this.translationService.getFilteredLanguages('tabit', true);
                });
            }
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/home'])
                    });
                });
        }
    }

    ngOnDestroy() {
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
    }

    openFeedbackLink(path: string) {
        if (!path) return;
        let url = get(this.domain, 'links.feedbackURL', 'https://docs.google.com/forms/d/e/1FAIpQLSc4ksTeIFgloG9Ii9lcO2Cf8k30VgAq7NT6HCqtCKHqyUiu3w/viewform?usp=sf_link&hl=en');

        this.dialogService.toggleActionFrame(path, null, null, null, url);
    }

    openAccessibilitySite() {
        const accessibleSite = get(this.domain, 'links.accessibleSiteURL','');
        if (accessibleSite) window.open(accessibleSite, '_system');
    }

    isAccessibilitySiteAvailable() {
        return !!get(this.domain, 'links.accessibleSiteURL');
    }

    openLink(path: string) {
        let url: string;
        switch (path) {
            case "privacy":
                url = get(this.domain, 'links.privacyPolicyURL');
                break;
            case "loyaltyTerms":
                url = get(this.domain, 'links.loyaltyTermsURL');
                break;
            case "contact":
                url = get(this.domain, 'links.contactDetailsURL');
                break;
            case "terms":
            default:
                url = get(this.domain, 'links.termsOfUseURL');
        }

        this.dialogService.toggleActionFrame('link', null, null, window['cordova'], url);
    }

    changeUsedLanguage(value) {
        this.translationService.setLastUsedLangForMainApp(value);
        this.translationService.changeUsedLanguage(value);
    }

}
