import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

export interface Country {
    code: string,
    name: string,
    phone_code: string,
    frequent_country: boolean
}

@Injectable({
    providedIn: 'root'
})
export class CountriesService {

    private countriesList = [];

    private appConfig: any = environment.appConfig;

    constructor(
        private http: HttpClient,
    ) { }

    public getCountriesList (): Observable<any> {
        if (this.countriesList?.length) return of(this.countriesList);

        return this.http.get<[]>(`${this.appConfig.localization?.path}/${this.appConfig.localization?.region}/en/countries.json`)
            .pipe(map(result => {
                this.countriesList = result;
                return this.countriesList;
        }));
    };

    public getCountryFlagIconSrc (countryCode) {
        return `${this.appConfig.localization?.path}/flags/4x3/${countryCode.toLowerCase()}.svg`;
    };
}