import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { get, each } from 'lodash-es';

import { AppService } from '../../../../app.service';
import { TabitpayService } from '../../tabit-pay.service';
import { TranslatePipe, DefaultCurrencyPipe } from '../../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { CreditCardValidator, CreditCardExpirationValidator, IdCardValidator } from '../../../../components/validators';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgFor, NgClass, NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'tabitpay-payment-dialog',
    templateUrl: './tabitpay-payment-dialog.component.html',
    styleUrls: ['./tabitpay-payment-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent, NgFor, NgClass, NgSwitch, NgSwitchCase, MatFormField, MatLabel, MatInput, CreditCardValidator, NgIf, MatSuffix, CreditCardExpirationValidator, IdCardValidator, MatCheckbox, MatDialogActions, MatButton, TranslateModule, TranslatePipe, DefaultCurrencyPipe]
})
export class TabitpayPaymentDialogComponent {

    ui: any = { updatePaymentInfo: true };
    $storage: any;
    ccinfo: any = { type: undefined };
    walletDisabled: boolean = false;
    enableSaveToWallet: boolean = false;

    constructor(
        private ngZone: NgZone,
        public appService: AppService,
        public orderService: TabitpayService,
        public dialogRef: MatDialogRef<TabitpayPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.$storage = this.orderService.$storage;

        var arr = [], arrEx = [];
        let storage = this.$storage;
        let order = storage.order;
        let disableGratuityCash = !storage.disableCashPayment && storage.enableGratuity && order.gratuityAmount && order.gratuityAmount != 0;
        let disableCash = storage.disableCashPayment || disableGratuityCash;

        this.walletDisabled = get(storage, 'rosConfig.payments.walletDisabled', false);

        each(storage.paymentAccounts, pm => {
            var sch = pm.schedule = null;
            if (!sch || storage.activeTimeslots[sch] !== false) {
                if (pm.paymentType != "cash" || !disableCash) {
                    if (pm.isExternal) arrEx.push(pm);
                    else {
                        arr.push(pm);
                        if (pm.paymentType == "cash") this.ui.cashMethod = pm;
                    }
                }
            };
        });

        this.ui.paymentMethods = arr;
        this.ui.exPaymentMethods = arrEx;

        if (this.ui.paymentMethods.length) {
            this.setPaymentMethod(this.ccinfo, this.ui.paymentMethods[0], true);
        } 
    }

    setPaymentMethod(ccinfo, pm, silent?) {
        if (!this.checkPayment(pm, silent)) return;

        ccinfo.paymentMethod = pm.paymentType;
        ccinfo.account = pm._id;
        ccinfo.merchantNumber = pm.merchantNumber;
        ccinfo.$$isExternal = pm.isExternal;
        ccinfo.requireIdPhoneTrans = pm.requireIdPhoneTrans;
        delete ccinfo.comment;

        if (this.walletDisabled) return;
        if (!ccinfo.$$isExternal && (["creditCard", "Cibus", "10bis"]).includes(pm.paymentType)) {
            //if (pm.paymentType == 'giftCard') this.checkGiftCardAccount(ccinfo);
            this.enableSaveToWallet = true;
        } else {
            this.enableSaveToWallet = false;
            this.ui.updatePaymentInfo = false;
        }
    }

    checkPayment(account, silent?) {
        if (!this.data.hasGratuity || account.enableGratuity) return true;
        if (!silent) {
            const message = this.appService.translate("MESSAGES.GRATUITY_NOT_ALLOWED_FOR_PM", { pm: account.name });
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: message,
                    primaryButtonText: 'got_it',
                    secondaryButtonHidden: true,
                }).then(res => { }).catch(err => { });
            });
        }
        return false;
    }

    apply(form) {
        if (!form.valid) return;
        this.dialogRef.close({
            ccinfo: this.ccinfo,
            updatePaymentInfo: this.ui.updatePaymentInfo
        });
    }

}
