import { Component, OnInit, ElementRef, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { BlockUI, NgBlockUI, BlockUIModule } from 'ng-block-ui';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';

import { assignIn, get, find } from 'lodash-es';
import moment from 'moment';
import { OrganizationsService } from '../../_core/organizations.service';
import { DefaultCurrencyPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { LottieComponent } from 'ngx-lottie';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf, NgFor } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton, MatFabButton } from '@angular/material/button';

@Component({
    selector: 'app-quick-pay',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quick-pay.component.html',
    styleUrls: ['./quick-pay.component.scss'],
    host: {
        'class': 'host-default',
    },
    standalone: true,
    imports: [
        BlockUIModule,
        MatIconButton,
        MatIcon,
        WidgetOpenerComponent,
        MatButton,
        NgIf,
        MatTabGroup,
        MatTab,
        NgFor,
        MatFabButton,
        MatNavList,
        MatListItem,
        LottieComponent,
        RouterLink,
        TranslateModule,
        DefaultCurrencyPipe,
    ],
})
export class QuickPayComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	loading: boolean = true;
	loadingStep: boolean = false;
	reffSite: any = {};
	site: any = {
		amountText: '',
		amountVal: 0,
		paymentIntent: {}
	};
	step: number = 0
	keys: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'back'];
	pms: any = [];
	wasFirstClick: boolean = false;
	date = new Date();

    paybackAnimation = this.appService.getAnimationPath('mobile-app');

	constructor(
		public appService: AppService,
		public router: Router,
		private route: ActivatedRoute,
        public entityService: EntityService,
        private organizationsService: OrganizationsService,
		public elementRef: ElementRef,
        private ngZone: NgZone,
	) {
		//this.elementRef.nativeElement.style.backgroundImage = "url('" + this.appService.images.pay_back + "')";
		this.route.queryParams.subscribe(params => {

			this.site = assignIn(this.site, params);
			this.reffSite = this.organizationsService.getOrganization(this.site._id);

			this.pms = get(this.appService, 'user.wallet.payments', []);
			if (!this.pms.length) {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'info',
                        dialogText: 'MESSAGES.NO_PMS_FOUND_ADD',
                        primaryButtonText: "add_pm"
                    }).then(response => {
                        this.appService.redirect(['/profile/my-wallet', { step: 'addpm' }]);
                    }).catch(err => {
                        this.goBack();
                    });
                })
			} else {
				let defPm = this.pms.length == 1 && this.pms[0] || find(this.pms, { isDefault: true });
				if (defPm) this.site.pm = defPm;
				this.loading = false;
			}
		});
	}

	ngOnInit() { }

	goBack() {
		if (this.loadingStep) return;
		if (this.step === 0 || this.step === 2) this.close();
		else this.setStep(this.step - 1);
	}
	close() {
		if (this.step == 2) {
			this.cancelPaymentIntent();
		} else {
			this.exit();
		}
	}
	exit() {
        this.appService.goBack();
	}

	goToStep(step) {
		if (this.loadingStep) return;
		switch (step) {
			case 1:
				if (!this.site.amount || isNaN(this.site.amount)) return;
				break;
			case 2:
				if (!this.site.pm) return;
				return this.getQuickPayCode();
				break;
			case 3:
				this.elementRef.nativeElement.style.backgroundImage = "url('" + this.appService.images.pay_back_success + "')";
				break;
		}
		this.setStep(step);
	}

	setStep(step) {
		this.wasFirstClick = false;
		if (step < 2) delete this.site.transactionCode;
		this.step = step;
	}

	setPM(pm) {
		if (this.loadingStep) return;
		this.site.pm = pm;
	}

	getQuickPayCode() {
		if (this.loadingStep) return;
		this.loadingStep = true;
		this.blockUI.start();
		this.entityService.getPaymentIntent(this.site).then(response => {
			this.site.paymentIntent = response;
			this.site.paymentIntentId = response._id;
			this.site.paymentIntentCode = response.code;
			this.setStep(2);
			this.needCheckPaymentIntent = true;
			this.checkPaymentIntent();
		}).catch(err => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: this.appService.translate("error_client_credentials")
                });
            });
			this.blockUI.stop();
		}).then(o => {
			this.loadingStep = false;
			this.blockUI.stop();
		})
	}

	needCheckPaymentIntent: boolean = false;
	checkPaymentIntent() {
		if (!this.needCheckPaymentIntent) return;
		let that = this;
		window.setTimeout(() => {
			this.entityService.getPaymentIntents(this.site).then(response => {
				let pi = find(response, { _id: this.site.paymentIntentId });
				that.checkPaymentIntentResponse(pi);
			}).catch(err => {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'error_client_credentials'
                    }).then(ret => {
                        this.exit();
                    });
                });
			});
		}, 5000);
	}
	checkPaymentIntentResponse(pi) {
		if (!pi) {

		} else {
			switch (pi.status) {
				case "approved":
					this.onSuccessfullPayment(pi);
					break;
				case "expired":
					this.onPaymentExpired(pi);
					break;
				default:
					if (moment().isAfter(moment(pi.expiresAt))) {
						this.onPaymentExpired(pi);
					} else {
						this.checkPaymentIntent()
					}
					break;
			}
		}
	}


	cancelPaymentIntent() {
		if (this.loadingStep) return;
		this.loadingStep = true;
		this.blockUI.start();
		this.entityService.deletePaymentIntent(this.site).then(response => {
			this.appService.animDialog({ title: 'MESSAGES.PAYMENT_DELETED_SUCCESSFULLY', animation: this.appService.base('/assets/animations/coin-pig.json'), loop:true }).then(ret => {
				this.exit();
			});
		}).catch(err => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: 'error_client_credentials'
                }).then(ret => {
                    this.exit();
                });
            });
			this.blockUI.stop();
		}).then(o => {
			this.loadingStep = false;
			this.blockUI.stop();
		})
	}

	onPaymentExpired(pi?) {
		this.appService.animDialog({ title: 'MESSAGES.PAYMENT_INTENT_EXPIRED', animation: this.appService.base('/assets/animations/expired.json') }).then(ret => {
			this.exit();
		});
	}

	onSuccessfullPayment(pi?) {
		this.appService.animDialog({ title: 'MESSAGES.PAYMENT_ACCEPTED_SUCCESSFULLY', animation: this.appService.base('/assets/animations/check.json') }).then(ret => {
			this.exit();
		});
	}


	setAmaountKey(key) {
		let newVal = this.site.amountText;
		let hasDecimal = newVal.indexOf(".") != -1;
		let precision = hasDecimal && newVal.split(".")[1].length || 0;
		switch (key) {
			case 'back':
				if (!newVal.length) return;
				newVal = newVal.slice(0, -1);
				break;
			case '.':
				if (hasDecimal) return;
				newVal += key;
				break;
			default:
				if (!this.wasFirstClick) newVal = key + "";
				else {
					if (precision == 2) return;
					if (!hasDecimal && newVal.length == 3) return;
					newVal += key;
				}
		}
		this.wasFirstClick = true;
		this.site.amountText = newVal;
		this.site.amount = Number(newVal);
	}

}
