import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
// Lottie
// [ ! ] IMPORTANT NOTE
// 2020-05-01: ngx-lottie v5.4.0 is broken on Safari / iOS and causes the animations to be rendered completely "black" (https://github.com/ngx-lottie/ngx-lottie/issues/24)
// So we had to revert to v4.1.0.
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { OrderModule } from 'ngx-order-pipe';
//import { SignaturePadModule } from 'angular2-signaturepad';
import { QRCodeModule } from 'angularx-qrcode';
//import { EllipsisModule } from 'ngx-ellipsis';
import { AUTO_SIZE_INPUT_OPTIONS, AutoSizeInputOptions, AutoSizeInputModule } from 'ngx-autosize-input';
import { Ng2FittextModule } from 'ng2-fittext';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { A11yModule } from '@angular/cdk/a11y';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
    return player;
}

const CUSTOM_AUTO_SIZE_INPUT_OPTIONS: AutoSizeInputOptions = {
    extraWidth: 0,
    includeBorders: false,
    includePadding: true,
    includePlaceholder: true,
    maxWidth: -1,
    minWidth: -1,
    setParentWidth: false,
    usePlaceHolderWhenEmpty: true, // Sets width to placeholder width, only when value is empty.
}

@NgModule({
    imports: [
        MatGridListModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatDatepickerModule,
        MatCardModule,
        MatListModule,
        MatTabsModule,
        MatToolbarModule,
        MatChipsModule,
        MatSidenavModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        A11yModule,
        MatDialogModule,
        MatSnackBarModule,
        MatRadioModule,
        MatBottomSheetModule,
        MatRippleModule,
        MatMenuModule,
        // ScrollDispatchModule,
        ToastrModule.forRoot(),
        // SignaturePadModule,
        QRCodeModule,
        OrderModule,
        BlockUIModule.forRoot({
			message: ''
        }),
        LottieModule.forRoot({ player: playerFactory}),
        Ng2FittextModule,
        AutoSizeInputModule
    ],
    exports: [
        MatGridListModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatDatepickerModule,
        MatCardModule,
        MatListModule,
        MatTabsModule,
        MatToolbarModule,
        MatChipsModule,
        MatSidenavModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        A11yModule,
        MatDialogModule,
        MatSnackBarModule,
        MatRadioModule,
        MatBottomSheetModule,
        MatRippleModule,
        MatMenuModule,
        OrderModule,
        // ScrollDispatchModule,
        ToastrModule,
        // SignaturePadModule,
        QRCodeModule,
        TranslateModule,
        BlockUIModule,
        LottieModule,
        Ng2FittextModule,
        AutoSizeInputModule,
    ],
    providers: [
        { provide: AUTO_SIZE_INPUT_OPTIONS, useValue: CUSTOM_AUTO_SIZE_INPUT_OPTIONS },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue:{} },
    ]
})
export class CoreModule { }
