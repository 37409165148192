export const environment = {
    production: false,
    env: 'au-int',
    appConfig: {
        "version": "4.14.4",
        "last_update": "2024-03-18 14:18",
		"title": "TA AU-INT",
		"direction": "ltr",
        "locale": "en-AU",
        "countryCode": "AU",
        "currencyCode": "AUD",
        "defaultArea": "sydney",
        "host": "https://au-app.tabit-int.com",
        "tabitAppURL": "https://au-app.tabit-int.com",
		"tabitWebURL": "https://au-tabit-web.tabit-int.com",
		"useHash": true,
		"tabitAPI": "https://au-int-ros.tabit-stage.com",
		"tabitLoyaltyAPI": "https://tabitloyaltyapi-dev.azurewebsites.net/api",
        "tabitBridge": "https://au-int-bridge.tabit-stage.com",
        "deliveryServiceURL": "",
		"bookingAPI": "https://au-tgm-api.tabit-stage.com",
		"bookingAPIBeta": "https://au-tgm-api.tabit-stage.com",
        "tabitOrderURL": "https://au-tabitorder.tabit-stage.com",
        "tabitOrder_tabitAPI": "",
		"tabitGuestURL": "https://au-tgm-api.tabit-stage.com",
		"tabitDocumentsURL": "https://au-documents.tabit-stage.com",
		"s3BucketURL": "https://ros-prd.s3.amazonaws.com",
        "threeDImagesAddress": "https://inpact-int.s3.eu-west-1.amazonaws.com/3d",
		"googleKey": "AIzaSyALjJAiHDt33ss1GfgTOlQIevVZq3c2lGk",
        "recaptchaSiteKey": "6LfZVAkpAAAAAI3GzKZMFIBwzVuh6qAQbKwc6TIQ",
		"tabitDefaultOrganizationId": "53eb1ee2e6c77111203d8501",
        "tabitClientID": "urfoxj8ymmz2bbfolofq4xsjr9psc3q7ii76b2n3",
        "loyaltyJoinChannelGUID": window['cordova'] ? "BF10B302-8152-4E78-B496-7AE7A7513983" : "CDA3B904-5FB5-472B-97D4-52B966F167A5",
        // Deprecated because of Deep links use
        // "appleID": "1463575038", null,
        "appURLScheme": "autabitapp",
        "googleTagManagerId": "GTM-NRSRVPD",
        "googleAnalyticsMeasurmentId": "G-Y4KX0GXS87",
        "blockGoogleImages": true,
        "isDev": false,
        "facebookURL": "https://www.facebook.com/tabit.cloud",
        "instagramURL": "https://www.instagram.com/tabit.cloud",
        "allowedPathsWhileUnderConstruction": [],
        "applePaySdkURL": "",
        "firebaseConfig": {
            "apiKey": "AIzaSyBPCFgEZRDlqTeqx3IeXfNxV5suLsrMMck",
            "authDomain": "tabit-app.firebaseapp.com",
            "databaseURL": "https://tabit-app.firebaseio.com",
            "projectId": "tabit-app",
            "storageBucket": "tabit-app.appspot.com",
            "messagingSenderId": "101429635305",
            "appId": "1:101429635305:web:f993e0a1f75086d0996878",
            "measurementId": "G-R9Q8YJNBGH",
            "vapidKey": "BF4Dk-aO2iwgAIZZlQMlwfyb30vpAgNYTN4KQvUiIuz9tn-4HISOJZdGkXBhZ5KXa_YKncW7ZS5h1tsWUSJjo1w"
        },
        "layout":{
            "dashboard":{
                "events": {
                    "enabled": true
                }
            },
            "showAppBanner": {
                "enabled": false
            },
            "articles":{
                "enabled": false
            }
        },
        "tabitPayURL": "https://au-pay.tabit-int.com",
        "apmServer": "https://c8783b894a2c41668ca2d7233e7bd375.apm.eu-west-1.aws.cloud.es.io:443",
        "localization": {
            "path": "https://localization.tabit-int.com",
            "region": "au"
        },
        "mixpanel": {
            "enabled": true,
            "projectToken": "de7f78244fad011cdffa957f610c28de"
        }
    }
};
