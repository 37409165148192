import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppService } from '../app.service';
import { UserReviewsService } from '../_core/user.reviews.service';
import { EntityService } from '../_core/entity.service';
import { EntityUtilsService } from '../_core/entity.utils.service';
import { AnimationItem } from 'lottie-web';

import { get } from 'lodash-es';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { LottieComponent } from 'ngx-lottie';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, DatePipe } from '@angular/common';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

@Component({
    selector: 'user-reviews',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './user-reviews.component.html',
    styleUrls: ['./user-reviews.component.scss'],
    standalone: true,
    imports: [
        MatTabGroup,
        MatTab,
        NgIf,
        MatProgressSpinner,
        NgFor,
        LottieComponent,
        NgSwitch,
        NgSwitchCase,
        MatButton,
        FormsModule,
        DatePipe,
        TranslateModule,
    ],
})

export class UserReviewsComponent implements OnInit {
    @Input() public step: number = 0;

    @Output() public stepFromChild: EventEmitter<number> = new EventEmitter();

    private reviewData = {
        user: {},
        review: {
            rating: '',
            reasons: [],
            comment: ''
        },
        tlogId: '',
        orgId: '',
        orderId: '',
    };

    private tlogId: string = '';
    private orderId: string = '';
    private serviceType: string = '';

    public user = {
        id: '',
        firstName: '',
        lastName: '',
        mobile: ''
    };

    public org = {
        id: '',
        name: ''
    };

    public date = new Date();

    public ratings = [
        { id: 5, caption: 'user_review_perfect', image: this.appService.base('assets/images/svg/perfect-smiley.svg'), animation: this.appService.base('/assets/animations/perfect-review.json') },
        { id: 4, caption: 'user_review_ok', image: this.appService.base('assets/images/svg/ok-smiley.svg'), animation: this.appService.base('/assets/animations/ok-review.json') },
        { id: 3, caption: 'user_review_plausible', image: this.appService.base('assets/images/svg/plausible-smiley.svg'), animation: this.appService.base('/assets/animations/plausible-review.json') },
        { id: 2, caption: 'user_review_expected_more', image: this.appService.base('assets/images/svg/expected-more-smiley.svg'), animation: this.appService.base('/assets/animations/expected-more-review.json') },
        { id: 1, caption: 'user_review_bad', image: this.appService.base('assets/images/svg/sad-smiley.svg'), animation: this.appService.base('/assets/animations/sad-review.json') },
    ]
    // Note: we generated the ID from MongoDB for uniquness of the options
    public loadingMetaData: boolean = true;
    public reasons = [];
    public ratingId: number = 0;
    public selectedRating: number = 0;
    public selectedAnimation: string = '';
    public selectedReasons: any = {};
    public sendButtonDisabled: boolean = true;
    public reviewText: string = '';
    public urlReferrer: string = '';
    private urlSubscription: Subscription;
    public animationItem: AnimationItem;

    constructor(
        public appService: AppService,
        public reviewsService: UserReviewsService,
        public entityService: EntityService,
        public entityUtilService: EntityUtilsService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.appService.loadingMetaData.subscribe(loading => {
            this.loadingMetaData = loading;
            if (!loading) {
                let paramValues;
                this.appService.confirmBeforeRedirect = true;
                this.urlSubscription = this.activatedRoute.pathFromRoot[0].queryParams.subscribe(params => {
                    paramValues = params;
                    if (!paramValues) return;
                    this.setDataForReview(paramValues);
                    this.changeDetectorRef.detectChanges();
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.urlSubscription) this.urlSubscription.unsubscribe();
        this.appService.confirmBeforeRedirect = false;
    }

    setStep(step: number, ratingId?: any) {
        this.step = step;
        if (ratingId) {
            this.ratingId = ratingId; // Public ratingId for template switch case
            this.reviewData.review.rating = ratingId;
            let rating = this.ratings.filter(rating => rating.id == ratingId);
            rating.map(r => {
                this.selectedRating = r.id;
                this.selectedAnimation = r.animation;
            })
        }
        this.stepFromChild.emit(this.step);
    }

    reasonToggle(reasonId: string) {
        if (this.selectedReasons[reasonId]) {
            delete this.selectedReasons[reasonId];
        } else {
            this.selectedReasons[reasonId] = true;
        }

        this.sendButtonDisabled = Object.keys(this.selectedReasons).length > 0 ? false : true;
    }

    inputReviewText() {
        if (this.reviewText && this.reviewText.length > 0) {
            this.reviewData.review.comment = this.reviewText;
            this.saveReview()
        }
    }

    setDataForReview(params) {
        if (params) {
            if (params['user-id']) {
                this.user.id = params['user-id'];
                if (get(this.appService, 'user.loyaltyCustomer.CustomerId') == this.user.id) {
                    this.user.firstName = this.appService.user.loyaltyCustomer.FirstName;
                    this.user.lastName = this.appService.user.loyaltyCustomer.LastName;
                    this.user.mobile = this.appService.user.loyaltyCustomer.Mobile;
                } else {
                    this.user.firstName = params['first-name'];
                    this.user.lastName = params['last-name'];
                    this.user.mobile = params['mobile'];
                }
            }
            if (params['from-order']) { // This is only for taking the user details from the order
                this.user.firstName = params['user-name'];
                this.user.mobile = params['mobile'];
            }
            this.tlogId = params['tlog-id'];
            this.date = params['date'];
            this.org.id = params['organization-id'];
            this.org.name = params['organization-name'];
            this.orderId = params['order-id'];
            this.urlReferrer = params['referrer'];
            this.serviceType = params['serviceType'];
            this.setReviewReasons(this.serviceType);
        }
    }

    setReviewReasons(serviceType: string) {
        this.reasons = serviceType == 'seated'  ?   this.reviewsService.reviewReasons.filter(review => review.onlySeated)
                                                :   this.reviewsService.reviewReasons.filter(review => review.caption[this.appService.localeId] != 'user_review_atmosphere');
    }

    saveReview() {
        this.prepareReviewData()
        this.entityService.saveUserReview(this.reviewData).then(() => {
            this.appService.confirmBeforeRedirect = false;
        }).catch(err => {
            console.error('Error saving user review:', err);
        });
    }

    animationCreated(animationItem: AnimationItem, segmentFrames: any): void {
        this.animationItem = animationItem;
    }

    animationDomLoaded(segmentFrames: any): void {
        this.animationItem.playSegments(segmentFrames, true);
    }

    prepareReviewData() {
        this.reviewData.review.reasons = this.reasons.filter(reason => this.selectedReasons[reason._id]);
        this.reviewData.user = this.user;
        this.reviewData.tlogId = this.tlogId;
        this.reviewData.orgId = this.org.id;
        this.reviewData.orderId = this.orderId;
    }

    redirectToReferrer() {
        this.appService.redirect([this.urlReferrer]);
    }
}
