import { Injectable, NgZone } from '@angular/core';

import { AppService } from '../app.service';
import { NotificationsService } from '../_core/notifications.service';
import { StorageService } from '../_core/storage.service';
import { EntityService } from '../_core/entity.service';

import { isEmpty } from 'lodash-es';

declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class CordovaPluginsService {

    constructor(
        private appService: AppService,
        private notificationsService: NotificationsService,
        private storageService: StorageService,
        private entityService: EntityService,
        private ngZone: NgZone,
    ) { }

    public init() {
        console.debug('Cordova exists');

        $("body").addClass("cordova");

        this.appService.cordovaPlatform = window['cordova'] && window['cordova'].platformId || 'none';
        if (this.appService.cordovaPlatform && /Android/i.test(this.appService.cordovaPlatform)) {
            $("body").addClass("android");
        }

        this.appService.isApp = true;

        // Hooking the window.open with the Cordova Plugin InAppBrowser
        if (window['cordova'].InAppBrowser) {
            window.open = window['cordova'].InAppBrowser.open;
        }

        if (window['StatusBar']) {
            this.handleStatusBar();
        }

        if (window['Keyboard']) {
            this.handleKeyboardPlugin();
        }

        // Enabling Safari WKWebView Back/Forward Gestures
        /*
        if (window['WkWebView']) {
            window['WkWebView'].allowsBackForwardNavigationGestures(true);
        }
        */

        // Android SMS Retriever
        this.handleSmsRetriever();

        // Handle pause/resume
        this.handlePauseAndResume(30 * 60 * 1000);
    }

    private handleKeyboardPlugin() {
        // Telling the Viewport to shrink when keyboard is open
        if (window['Keyboard'].setResizeMode) {
            window['Keyboard'].setResizeMode('native');
        }
        // Fix viewport shift bug in Cordova (introduced in iOS 12 / XCode 10)
        // https://github.com/apache/cordova-ios/issues/417
        window.addEventListener('keyboardDidHide', function () {
            setTimeout(() => {
                if (!window['Keyboard'].isVisible && window.pageYOffset != 0) window.scrollTo(0, 0);
            }, 100);
        });
        // Adding the Accessory Bar to the Keyboard
        if (window['Keyboard'].hideFormAccessoryBar) {
            window['Keyboard'].hideFormAccessoryBar(false);
        }

        // Toggling the Bottom Navbar when the keyboard is open
        window.addEventListener('keyboardWillShow', function () {
            try {
                $('body').addClass('keyboardOpen');
                setTimeout(() => {
                    $('to-mobile-basket-button').hide();
                }, 150);
                $('.app-bottom-links').hide();
                $('.host-default > .module-content').addClass('app-bottom-links-hidden');
                // It'll handle all focused components that'll be hidden by the keyboard
                // We need the timeout to let the viewport and DOM adjust after the cart display:none
                setTimeout(() => {
                    if ($(':focus')[0]) $(':focus')[0].scrollIntoViewIfNeeded(true);
                }, 800);
            } catch (error) {
                console.log('keyboardWillShow event error', error)
            }
        });

        window.addEventListener('keyboardWillHide', function () {
            $('body').removeClass('keyboardOpen');
            $('.app-bottom-links').show();
            $('.host-default > .module-content').removeClass('app-bottom-links-hidden');
            setTimeout(() => {
                $('to-mobile-basket-button').fadeTo("slow", 1);
            }, 150);
        });
    }

    private handleStatusBar() {
        window['StatusBar'].show();
        window['StatusBar'].overlaysWebView(true);
        window['StatusBar'].styleLightContent();

        this.appService.setStatusBarStyle('dark');
    }

    private handleSmsRetriever() {
        // We generate the app hash that is required to appear inside the SMS (so that Android will auto detect the verification code and fill-in the input-field)
        if (window['cordova'] && window['cordova']?.plugins?.smsRetriever) {
            window['cordova']['plugins']['smsRetriever']['getAppHash'](
                (result: any) => {
                    console.debug('SMSRetriever > getHashString > Success: ', result);
                    if (result) this.appService.SMSRetrieverHash = result;
                },
                (err: any) => {
                    console.error('SMSRetriever > getHashString > Error:', err);
                }
            );
        };
    }

    private handlePauseAndResume(ms) {
        let pauseTime;

        document.addEventListener('pause', o => {
            pauseTime = new Date().valueOf();
            this.storageService.setItem('pauseTime', pauseTime);
            this.appService.paused = true;
            this.appService.isPhoneResumed.next(false);
        }, false);

        document.addEventListener('resume', o => {
            console.debug('== appComponent > handlePauseAndResume > resume');
            this.appService.paused = false;
            this.appService.isPhoneResumed.next(true);
            this.appService.stopBlock();
            this.notificationsService.initFireBaseMessaging();

            // 2019-12-13: We noticed that in some cases the device doesn't detect the pause time upon resume, so we use local-storage for persistency.
            pauseTime = parseInt(this.storageService.getItem('pauseTime'));

            // Clearing the pause time from the local storage
            this.storageService.setItem('pauseTime', '');

            // 2020-11-06 - Decided this is not necessary anymore, as the device itself resets the app after a while. So decided to keep the logic simple, also for the sake of deep-linking.
            // [ ! ] If we ever decide to re-enable it, we need to make sure it works with the deep-link, because the commented-out code below IGNORES the deep-link, even through it seems like it should have worked.
            /*
            if (window['cordova'] && pauseTime && ((new Date().valueOf() - pauseTime) > ms) && !this.storageService.getItem('deepLink') && this.appService.currentUrl != '/home/dashboard') {
                // Only in Cordova - If the user returned after 30 minutes - we redirect to the home screen
                // We also make sure there is no Deep Link that has been triggered using the Custom URL Scheme Plugin
                this.ngZone.run(() => {
                    console.debug('=== appComponent > handlePauseAndResume > redirecting to home');
                    this.appService.redirect(['/home/dashboard']);
                });
            } else {
                // Deeplinking
                console.debug('=== appComponent > handlePauseAndResume > Deeplinking as part of the Resume process');
                this.deepLinkRedirect();
            }
            */

            // Deeplinking
            console.debug('=== appComponent > handlePauseAndResume > Deeplinking as part of the Resume process');
            this.deepLinkRedirect();

        }, false);
    }

    public deepLinkRedirect() {
        const that = this;
        // We use the localStorage since we need to trigger the deepLlinkRedirect only after the Splash is being removed (upon first launch, and after sign-in) or upon resume.
        // So although we are subscribed to the universal link event - we still need to wait for the whole loading process of the app to finish first.
        // Note: The authGuard is registering the "landingURL" just before sign-out, so that after the sign-in the user will be redirected back to where he/she left.
        let deepLink: any = this.storageService.getItem('deepLink');
        if (!deepLink) {
            // Default app for our domain was set to be browser
            // but we don't want the user to see the app's domain
            if (/bannerRedirect/.test(location.href) && !window['cordova']) {
                // For Android Store
                if (navigator && /android/i.test(navigator.userAgent)) {
                    window.location.href = 'https://play.google.com/store/apps/details?id=app.tabit.il_prd';
                // iOS
                } else {
                    window.location.href = 'https://apps.apple.com/il/app/tabit-%D7%98%D7%90%D7%91%D7%99%D7%98/id1463575038';
                }
            }
            return;
        }

        // Clearing the deeplink from localStorage
        this.storageService.removeItem('deepLink');

        deepLink = JSON.parse(deepLink);
        if (!deepLink.host) return;

        console.debug('=== Full deepLinkEvent ===', deepLink);

        // We've added tabitCordovaWrapperDeepLinkEvent for web domains also, and the hash there is different (no trailing one..)
        // this is a side effect because the redirect is not initialized from the smart app banner
        let deepLinkHash = deepLink.hash;
        if (!deepLinkHash && deepLink.path) deepLinkHash = deepLink.path;

        console.debug('=== Path from deepLinkEvent ===', deepLinkHash);

        // Preparing the query parameters
        deepLink.params = !isEmpty(deepLink.params) ? deepLink.params : this.appService.convertQueryStringToQueryParams(deepLink.url);

        // [ ! ] Important Note:
        // Notice that we use the deepLink.hash and not deepLink.path!
        // This is because the App version uses Hash '#' (due to Cordova).
        // So the redirect from the App Smart Banner to the App version Universal Link URL is with the hash.
        if (isReturn(deepLinkHash, deepLink)) {
            // If the URL contain a redirect to sign-in or home or if we're already on the target view => then we don't need to do anything.
            return;
        } else if (deepLinkHash) {
            deepLink.appRoute = deepLinkHash;
            this.ngZone.run(() => {
                if (/\/site\//.test(deepLinkHash)) {
                    deepLink.appRoute = deepLinkHash.replace('/site/', '/app-site/');
                } else if (/\/web-user-review/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/app-user-review';
                } else if (/\/web-order-tracker/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/app-order-tracker';
                } else if (/\/pay\/tabit-pay/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/pay/tabit-pay';
                } else if (/\/online-reservations|\/הזמנת-מקום/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/online-reservations';
                } else if (/\/tabit-order/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/tabit-order';
                } else if (/\/gift-it/.test(deepLinkHash)) { // Notice we don't have the trailing slash
                    deepLink.appRoute = '/app-gift-it';
                } else {
                    deepLink.appRoute = '/home/dashboard';
                }

                console.debug('deepLinkRedirect > deepLink Object:', deepLink);
                console.warn('deepLinkRedirect > deepLink hash:', deepLinkHash);

                if (!isEmpty(deepLink.params)) {
                    // In case no service in param for tabit-order link, redirect to site page
                    if (deepLink.appRoute == '/tabit-order' && !deepLink.params?.service) {
                        deepLink.appRoute = '/home/dashboard';
                        console.debug('deepLinkRedirect > redirecting to site page (No service provided)');

                        return this.redirectToSitePage(deepLink.params);
                    }

                    // Handle Tabit Pay Web redirect
                    if (deepLink.params?.oid && deepLink.appRoute !== '/pay/tabit-pay') {
                        deepLink.appRoute = '/pay/tabit-pay';
                        console.warn(`deepLinkRedirect > redirecting to tabit pay for order id: ${deepLink.params?.oid}`);
                    }

                    console.debug('deepLinkRedirect > redirecting with queryParams:', deepLink.appRoute, deepLink.params);
                    this.appService.redirect([deepLink.appRoute], { queryParams: deepLink.params });
                } else {
                    console.debug('deepLinkRedirect > redirecting to:', deepLink.appRoute);
                    this.appService.redirect([deepLink.appRoute]);
                }
            });
        }

        function isReturn(deepLinkHash, deepLink) {
            // In case we land from Tabit Pay
            if (deepLink.params?.oid) return false;
            return (/sign-in/.test(deepLinkHash) || deepLinkHash == '/' || that.appService.currentUrl == deepLinkHash);
        }
    }

    public locationServiceClick() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'error',
                dialogText: "MESSAGES.PLEASE_ENABLE_LOCATION",
                primaryButtonText: window['cordova'] ? "redirect_to_device_settings" : "understood",
                secondaryButtonText: "next_time"
            }).then(response => {
                if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                    window['cordova'].plugins.diagnostic.switchToSettings((success: any) => {
                        console.debug('location notch click > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable location - SUCCESS: ', success);
                    }, (error: any) => {
                        console.debug('location notch click > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable location - ERROR: ', error);
                    });
                }
            }).catch(err => {
                if (!err?.secondaryButtonClick) console.error('Error on popup:', err);
            });
        });
    }

    public notificationServiceClick() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'error',
                dialogText: "MESSAGES.PLEASE_ENABLE_NOTIFICATIONS",
                primaryButtonText: window['cordova'] ? "redirect_to_device_settings" : "understood",
                secondaryButtonText: "next_time"
            }).then(response => {
                if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                    window['cordova'].plugins.diagnostic.switchToSettings((success: any) => {
                        console.debug('notification notch click > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable notifications - SUCCESS: ', success);
                    }, (error: any) => {
                        console.debug('notification notch click > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable notifications - ERROR: ', error);
                    });
                }
            }).catch(err => {
                if (!err?.secondaryButtonClick) console.error('Error on popup:', err);
            });
        });
    }

    private redirectToSitePage(params) {
        console.debug('=== redirectToSitePage params ===', params);

        let path = '/app-site/';
        // Must support lowercase
        const siteName = params.siteName || params.sitename;
        if (params.site) {
            path += params.site;
            console.debug('=== redirectToSitePage by site param ===', path);

            this.appService.redirect([path]);
        } else if (siteName) {
            this.entityService.getSiteByPublicUrl(siteName)
            .then((site: any) => {
                if (!site?._id) {
                    path = '/home/dashboard';
                    this.appService.redirect([path]);
                } else {
                    path += site._id;
                    console.debug('=== redirectToSitePage by siteName param ===', path);

                    this.appService.redirect([path]);
                };
            });
        // Fallback
        } else {
            path = '/home/dashboard';
            console.debug('=== redirectToSitePage path ===', path);

            this.appService.redirect([path]);
        }
    }
}
