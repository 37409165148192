import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AppService } from '../../app.service';
import { TagsService } from '../../_core/tags.service';
import { filter } from 'lodash-es';
import { TranslateSiteTagsAsync } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { Dir } from '@angular/cdk/bidi';
import { RatingComponent } from '../../components/rating/rating.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'site-title-desc',
    templateUrl: './site-title-desc.component.html',
    styleUrls: ['./site-title-desc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RatingComponent,
        Dir,
        AsyncPipe,
        TranslateModule,
        TranslateSiteTagsAsync,
    ],
})
export class SiteTitleDescComponent implements OnInit {

    @Input() site: any;

    tabitBadgeSrc: string;
    googleIconSrc: string;

    descriptionExpanded: boolean = false;

    constructor(
        public appService: AppService,
        private tagsService: TagsService,
    ) {}

    ngOnInit() {
        this.tabitBadgeSrc = this.appService.base('assets/images/svg/tabit-badge.svg');
        this.googleIconSrc = this.appService.base('assets/images/google-icon-small.png');
    }

    ifDescriptionLong(descriptionEl: HTMLElement) {
        return descriptionEl.offsetHeight > 90;
    }
}
