import { Component, OnInit } from '@angular/core';
import { WebFooterComponent } from '../web-footer/web-footer.component';
import { MarketplaceComponent } from '../../marketplace/marketplace.component';

@Component({
    selector: 'app-web-marketplace',
    templateUrl: './web-marketplace.component.html',
    styleUrls: ['./web-marketplace.component.scss'],
    standalone: true,
    imports: [MarketplaceComponent, WebFooterComponent]
})
export class WebMarketplaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
