import { Pipe, PipeTransform } from '@angular/core';
import { Country } from './model/country.model';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    // country | search:'searchCriteria'
    transform(country: Country, searchCriteria?: string): boolean {
        if (!searchCriteria || searchCriteria === '') {
            return true;
        }
        searchCriteria = searchCriteria.toLowerCase();
        if (country.name.toLowerCase().indexOf(searchCriteria) === 0) return true;

        return country.dialCode.includes(searchCriteria);
    }

}
