import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { MatIcon } from '@angular/material/icon';
import { Dir } from '@angular/cdk/bidi';
import { LottieComponent } from 'ngx-lottie';
import { MenuItemComponent } from '../../components/menu-item/menu-item.component';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

@Component({
    selector: 'site-info-tabs',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './site-info-tabs.component.html',
    styleUrls: ['./site-info-tabs.component.scss'],
    standalone: true,
    imports: [MatTabGroup, NgIf, MatTab, NgFor, MenuItemComponent, NgTemplateOutlet, LottieComponent, Dir, MatIcon, AsyncPipe, OrderModule, TranslateModule]
})
export class SiteInfoTabsComponent implements OnInit {

    appConfig: any = environment.appConfig;

    @Input() site: any;
    @Input() sitePhotos: any[] = [];
    @Output() photoClicked: EventEmitter<number> = new EventEmitter();

    public siteDetailsPage;
    public trustedIframeUrl: SafeResourceUrl;

    constructor(
        public utilsService: EntityUtilsService,
        public appService: AppService,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {
        this.setAboutData();
    }

    setAboutData() {
        this.siteDetailsPage = this.site.siteDetailsPage;
        if (this.siteDetailsPage?.videoLink) {
            try {
                this.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.siteDetailsPage.videoLink);
            } catch (err) {
                // Not a proper url probably
                console.debug(err);
            }
        }
    }

}
