import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { SafeStylePipe } from '../../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'order-tracker-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './order-tracker-header.component.html',
    styleUrls: ['./order-tracker-header.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule, SafeStylePipe]
})
export class OrderTrackerHeaderComponent implements OnInit {

    @Input() public user: any;
    @Input() public org: any;
    @Input() public order: any;
    @Input() public tab: string;

    constructor() { }

    ngOnInit() { }

}
