
<ng-container *ngIf="!preventClose">
    <div class="close-button-container">
        <button aria-label="close" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-container>

<form (ngSubmit)="apply(challengeForm)" #challengeForm="ngForm">
    <h1 tabindex="0" mat-dialog-title class="d-flex align-items-center text-center">
        <span class="flex-grow-1 text-truncate">{{$storage.$captions.selectTableCaption | translate}}</span>
    </h1>

    <div mat-dialog-content>
        <mat-form-field color="accent" class="w-100">
            <input matInput [placeholder]="($storage.$captions.tableNumberCaption | translate)+' *'" type="tel" pattern="[0-9]*" [(ngModel)]="formModel.table" name="table" required>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="tex-center">
        <button type="submit" [disabled]="!challengeForm.valid" mat-flat-button color="primary" class="rounded lg block mb-3" [attr.aria-label]="continueScreenReaderText">
            {{('CONTINUE') | translate}}
        </button>
    </div>
</form>